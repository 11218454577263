import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProductRelatedsService, ProductsService } from 'src/app/services';

@Component({
  selector: 'app-add-related-product',
  templateUrl: './add-related-product.component.html',
  styleUrls: ['./add-related-product.component.scss']
})
export class AddRelatedProductComponent implements OnInit {
  @Input() listTargetId;

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private productsService: ProductsService,
    private productRelatedsService: ProductRelatedsService,
    private notification: NzNotificationService,
  ) {

    const optionList$: Observable<string> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500));
    optionList$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.isVisible) {

      }
    });
  }
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();
  loading = false;
  unsubscribe$ = new Subject<void>();
  products = [];
  searchChange$ = new BehaviorSubject('');
  isLoading = false;
  isVisible = false;


  form = this.fb.group({
    product: [[], [Validators.required]]
  });

  loadListRelatedProduct(listTargetId?) {


    const queryProduct = listTargetId.length ? {
      page: 1,
      pageSize: 100,
      excludes: listTargetId.join(','),
      hidden: false
    } : {
      page: 1,
      pageSize: 100,
      hidden: false
    };
    this.isLoading = true;
    this.products = [];
    this.productsService.getAll(queryProduct).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.code === 1) {
          const data = res.data.items;
          this.products = res.data;
          return;
        }
        this.products = [];
      },
      (error) => {
        this.isLoading = false;
        this.products = [];
      }
    );
  }

  showModal(listTargetId?: any) {
    this.listTargetId = listTargetId;
    this.loadListRelatedProduct(listTargetId)
    this.form.reset();
    this.isVisible = true;
    this.searchChange$.next('');
  }

  handleCancel() {
    this.isVisible = false;
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  searchProduct(event) {
    this.searchChange$.next(event);
  }

  onSubmit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    // this.loading = true;
    const ids = this.form.controls.product.value;
    const listProductSelected = []
    ids.map(id => {
      this.products.map(item => {
        if (item.id === id) {
          listProductSelected.push(item)
        }
      })
    })
    this.notification.success(
      'Thành công',
      'Thêm mới sản phẩm liên quan thành công'
    );
    this.emitData.emit(listProductSelected);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
