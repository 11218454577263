import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class MediasService extends BaseService {

  getAll(query?: any): Observable<any> {
    return this.get<any>('/campaign', { params: query });
  }
  getTotalNoti(): Observable<any> {
    return this.get<any>('/campaign/subscription/quota');
  }
  testCampaign(body?: any): Observable<any> {
    return this.post<any>('/campaign/test', body);
  }
  create(body?: any): Observable<any> {
    return this.post<any>('/campaign', body);
  }
  getCampaignById(id?: any): Observable<any> {
    return this.get<any>(`/campaign/${id}`);
  }
  update(id?: any, body?: any): Observable<any> {
    return this.put<any>(`/campaign/${id}`, body);
  }
  destroy(body: any): Observable<any> {
    return this.delete<any>(`/campaign`, body);
  }
  statistical(query?: any): Observable<any> {
    return this.get<any>('/campaign/stats', { params: query });
  }
}
