import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil, throwIfEmpty } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UploaderComponent } from 'src/app/shared';
import { NzFormatEmitEvent, NzModalService, NzNotificationService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {SurveyService, UploadService} from 'src/app/services';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { track } from 'src/app/helpers/track';
import {base64toBlob, configCommon, isIncludeBase64Image} from '../../../../helpers';
import {regexBase64Image} from "../../../../helpers/constant";
@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {

  get questions(): any {
    return (this.form.get('content') as any).controls[this.pageCurrent - 1].get('questions') || [];
  }

  get content(): any {
    return this.form.get('content');
  }


  constructor(private modal: NzModalService,
              private fb: FormBuilder,
              private surveyService: SurveyService,
              private notification: NzNotificationService,
              private uploadService: UploadService,
              private router: Router) { }


  form = this.fb.group({
    surveyTypeCode: ['BASIC'],
    templateCode: ['BASIC'],
    name: ['', [Validators.required]],
    category: [null],
    content: new FormArray([this.createPageFormArray()])
  });

  pageCurrent = 1;

  listSurveyGroup = [];

  settingActiveCollapse = {
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',

    },
  };

  unsubscribe$ = new Subject<void>();

  listTypeInput = [
    { title: 'Lựa chọn', colorCode: '#199ED8', backgroundCode: 'rgba(25, 158, 216, 0.1)', border: '1px solid #199ED8', code: 'DROPDOWN' },
    { title: 'Danh sách trổ xuống', colorCode: '#D88C19', backgroundCode: 'rgba(216, 140, 25, 0.1)', border: '1px solid #D88C19', code: 'OPTIONAL' },
    { title: 'Chọn hình ảnh', colorCode: '#FB2F91', backgroundCode: 'rgba(251, 47, 145, 0.1)', border: '1px solid #FB2F91', code: 'OPTIONAL_IMAGE' },
    { title: 'Thang đánh giá', colorCode: '#194ED8', backgroundCode: 'rgba(25, 78, 216, 0.1)', border: '1px solid #194ED8', code: 'REVIEW' },
    { title: 'Nhiều hộp văn bản', colorCode: '#32A40A', backgroundCode: 'rgba(50, 164, 10, 0.1)', border: '1px solid #32A40A', code: 'BOX' },
    { title: 'Thông tin liên hệ', colorCode: '#D81919', backgroundCode: 'rgba(216, 25, 25, 0.1)', border: '1px solid #D81919', code: 'CONTACT_INFO' },
    { title: 'Tải tệp tin lên', colorCode: '#834471', backgroundCode: 'rgba(131, 68, 113, 0.1)', border: '1px solid #834471', code: 'FILE' },
  ];

  listFormat = [
    { value: 'TEXT', label: 'Văn bản' },
    { value: 'DATETIME', label: 'Ngày tháng' },
    { value: 'EMAIL', label: 'Email' },
    { value: 'NUMBER', label: 'Số' },
  ];

  listInfo = [
    { id: 1, title: 'Tên', allowRemove: false, isCorrect: false, isShow: true, isRequire: false },
    { id: 2, title: 'Họ', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 3, title: 'Công ty', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 4, title: 'Địa chỉ 1', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 5, title: 'Địa chỉ 2', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 6, title: 'Thành phố', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 7, title: 'Mã Zip', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 8, title: 'Quốc gia', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 9, title: 'Điện thoại', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
    { id: 10, title: 'Email', allowRemove: false, isCorrect: true, isShow: true, isRequire: false },
  ];

  listPage = [];

  valueSlider = 100;

  valueRate = 3;

  listTextArea = [
    { id: 1, title: '', allowRemove: false, isCorrect: false },
    { id: 2, title: '', allowRemove: false, isCorrect: true },
  ];

  listQuestion = [];

  listAnswerSelect = [
    { id: 1, value: '', allowRemove: false, isCorrect: false },
    { id: 2, value: '', allowRemove: false, isCorrect: true },
  ];

  listAnswerMutipleSelect = [
    { id: 1, value: '', allowRemove: false, isCorrect: false },
    { id: 2, value: '', allowRemove: false, isCorrect: true },
  ];

  listAnswerImage = [
    { id: 1, value: '', description: '', allowRemove: false, isCorrect: false },
    { id: 2, value: '', description: '', allowRemove: false, isCorrect: true },
  ];

  listNumberVote = [
    { id: 1, from: 0, to: 50, description: '', allowRemove: false, isCorrect: false },
    { id: 2, from: 50, to: 100, description: '', allowRemove: false, isCorrect: false },
  ];

  listStarVote = [
    { id: 1, from: 0, to: 50, description: '', allowRemove: false, isCorrect: false },
    { id: 2, from: 50, to: 100, description: '', allowRemove: false, isCorrect: false },
  ];

  listEmotionVote = [
    { id: 1, img: '/assets/png/Group.png', description: '', allowRemove: false, isCorrect: false },
    { id: 2, img: '/assets/png/Group-1.png', description: '', allowRemove: false, isCorrect: false },
  ];


  optionSelect: any;
  optionAdd: any;

  voteType = 1;


  public config = configCommon;

  id: any;
  isUpdate = false;
  currentSurvey: any;


  // form = this.fb.group({
  //   surveyTypeCode: ['BASIC'],
  //   templateCode: ['BASIC'],
  //   name: ['', [Validators.required]],
  //   category: [null],
  //   content: new FormArray([this.createPageFormArray()])
  // })


  listQuestionType = [];

  listEmotion = [];


  listGroup = [];

  inputDrag: any;
  idxHover = -1;

  isSubmitted = false;
  loadingSubmit = false;

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_survey_form',
    //   contentType: 'product',
    // });
    this.id = window.location.href.split('/')[5];
    this.getListEmotion();
    this.getListSurveyGroups();
    this.getListSurveyGroup();
    this.getListQuestionType();

    if (this.id !== 'null') {
      this.isUpdate = true;
      this.surveyService.detail(this.id).pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res.code === 1) {
              this.currentSurvey = res.data;
              this.form.patchValue({
                surveyTypeCode: res.data.surveyTypeCode,
                templateCode: res.data.templateCode,
                name: res.data.name,
                category: res.data.category,
                content: res.data.surveyContent.content
              });

              const content = new FormArray([]);

              const key = `${res.data.surveyContent.content[0].questions[0].code}${this.pageCurrent}0`;
              this.settingActiveCollapse[key] = true;
              for (const property in this.settingActiveCollapse) {
                if (property !== key) {
                  this.settingActiveCollapse[property] = false;
                }
              }
              res.data.surveyContent.content.map((item, index) => {

                (content).push(this.createPageFormArray(index + 1));
                // (content.at(index) as FormGroup).controls["index"].patchValue(index + 1);
                // content.at(index)["controls"]["questions"].patchValue(item.questions);

                item.questions.map((it, i) => {

                  const data = {
                    ...it,
                    // tslint:disable-next-line:no-shadowed-variable
                    ...this.listTypeInput.find(item => item.code === it.code)
                  };

                  (content.at(index) as any).controls.questions.push(this.addQuestionForPage(data, i, 'update'));
                });
              });

              this.form.controls.content = content;
              this.listPage = new Array((this.form.controls as any).content.controls.length);

            }
          });
    }

  }
  getListQuestionType() {
    const query = {
      templateCode: 'BASIC',
      surveyTypeCode: 'BASIC'
    };

    this.surveyService
      .getListQuestionType(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listQuestionType = res.data;
        }
      });
  }
  getListEmotion() {
    const query = {
      templateCode: 'BASIC',
      surveyTypeCode: 'BASIC'
    };

    this.surveyService
      .getListEmotion(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listEmotion = res.data;
        }
      });
  }


  getListSurveyGroups() {
    const query = {
      parentId: '',
      page: 1,
      pageSize: 10,
    };
    this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listSurveyGroup = res.data
            ? res.data.map((gg: any) => {
              const tmp = { ...gg };
              tmp.title = gg.name;
              tmp.key = gg.id;
              return tmp;
            })
            : [];
        }
      });
  }
  getListSurveyGroup() {
    const query = {
      page: 1,
      pageSize: 100,
    };
    this.surveyService
      .getListSurveyGroup(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {


          this.listGroup = _.uniqBy([...res.data], 'id').map((item: any) => {
            item.display_name = item.parentList.map(anc => anc.name).length
              ? item.parentList.map(anc => anc.name).join(' > ') + ' > ' + item.name
              : item.name;
            return item;
          });



        }
      });
  }

  // tslint:disable-next-line:ban-types
  styleObject(data): Object {
    if (data) {
      const border = `1px solid ${data.colorCode}`;
      return { background: data.backgroundCode, border, color: data.colorCode };
    }
    return {};
  }


  // tslint:disable-next-line:ban-types
  styleCollapse(data): Object {
    if (data) {
      const border = `1px solid ${data.colorCode}`;
      return { background: data.backgroundCode, border, color: data.colorCode };
    }
    return {};
  }
  dragStart(event, item) {



    if (item) {
      this.inputDrag = item;
    }
  }

  drop(event) {
    if (event && this.inputDrag) {
      this.isSubmitted = false;
      const index = this.questions.length;

      const newObject = {
        ...this.inputDrag,
        // index: this.listQuestion.length
      };
      // this.listQuestion.push(newObject);

      const key = `${newObject.code}${this.pageCurrent}${index}`;
      this.settingActiveCollapse[key] = true;
      for (const property in this.settingActiveCollapse) {
        if (property !== key) {
          this.settingActiveCollapse[property] = false;
        }
      }
      // tslint:disable-next-line:max-line-length
      (this.form.get('content') as any).controls[this.pageCurrent - 1].get('questions').push(this.addQuestionForPage(newObject, this.questions.controls.length, 'create'));

      this.listPage = new Array((this.form.value as any).content.length);



    }
  }


  createPageFormArray(index?) {


    return this.fb.group({
      index: index || 1,
      questions: new FormArray([])
    });

  }


  addPageSurvey() {
    if (this.pageCurrent === this.listPage.length) {
      this.pageCurrent += 1;
    }

    (this.form.get('content') as FormArray).push(this.createPageFormArray((this.form.controls.content as any).length + 1));


    this.listPage = new Array((this.form.value as any).content.length);
  }

  removePageSurvey() {


    (this.form.get('content') as FormArray).removeAt(this.pageCurrent - 1);
    this.pageCurrent -= 1;
    if (this.pageCurrent < 1) { this.pageCurrent = 1; }
    this.listPage = new Array((this.form.get('content') as FormArray).controls.length);
  }

  addQuestionForPage(item, index, action?) {
    switch (item.code) {
      case 'DROPDOWN':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          border: item.border,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      case 'OPTIONAL':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      case 'BOX':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      case 'FILE':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      case 'REVIEW':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      case 'CONTACT_INFO':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      case 'OPTIONAL_IMAGE':
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode, backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
          isUpdate: action === 'update' ? true : false
        });
      default:
        break;
    }

  }


  initDataQuestion(item, index) {
    switch (item.code) {
      case 'DROPDOWN':
        let answersDropdown = new FormArray([]);
        if (item.question && item.question.id) {
          // answers =

          item.question.answers.map((it, i) => {

            it.code = item.code;


            (answersDropdown as FormArray).push(this.initQuestion(it, i));


          });
        } else {
          answersDropdown = new FormArray([
            this.fb.group({
              id: [''],
              content: ['', [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
            this.fb.group({
              id: [''],
              content: ['', [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            })
          ]);
        }


        return {
          id: [item.question ? item.question.id : ''],
          required: [item.question ? item.question.required : false],
          content: [item.question ? item.question.content : '', [Validators.required]],
          selectOne: [item.question ? item.question.selectOne : false],
          enableOtherField: [item.question ? item.question.enableOtherField : false],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          enableRecommendations: [item.question ? item.question.enableRecommendations : false],
          recommendations: [item.question ? item.question.recommendations : ''],
          answers: answersDropdown
        };
      case 'OPTIONAL':
        let answersOptional = new FormArray([]);
        if (item.question && item.question.id) {
          // answers =

          item.question.answers.map((it, i) => {

            it.code = item.code;


            (answersOptional as FormArray).push(this.initQuestion(it, i));


          });
        } else {
          answersOptional = new FormArray([
            this.fb.group({
              id: [''],
              content: ['', [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
            this.fb.group({
              id: [''],
              content: ['', [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            })
          ]);
        }

        return {
          id: [item.question ? item.question.id : ''],
          required: [item.question ? item.question.required : false],
          content: [item.question ? item.question.content : '', [Validators.required]],
          selectOne: [item.question ? item.question.selectOne : false],
          enableOtherField: [item.question ? item.question.enableOtherField : false],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          enableRecommendations: [item.question ? item.question.enableRecommendations : false],
          recommendations: [item.question ? item.question.recommendations : ''],
          answers: answersOptional
        };
      case 'BOX':
        let answersBox = new FormArray([]);
        if (item.question && item.question.id) {
          // answers =

          item.question.body.map((it, i) => {

            it.code = item.code;


            (answersBox as FormArray).push(this.initQuestion(it, i));


          });
        } else {
          answersBox =
            new FormArray([
              this.fb.group({
                id: [''],
                title: ['', [Validators.required]],
                format: [null, [Validators.required]],
                allowRemove: false,
                index: 1
              }),
            ]);
        }

        return {
          id: [item.question ? item.question.id : ''],
          content: [item.question ? item.question.content : '', [Validators.required]],
          required: [item.question ? item.question.required : false],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          enableRecommendations: [item.question ? item.question.enableRecommendations : false],
          recommendations: [item.question ? item.question.recommendations : ''],
          body: answersBox
        };

      case 'FILE':
        return {
          id: [item.question ? item.question.id : ''],
          content: [item.question ? item.question.content : '', [Validators.required]],
          required: [item.question ? item.question.required : false],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          recommendations: [item.recommendations ? item.question.enableCommentField : ''],
          format: [['jpg', 'png']],
        };
      case 'REVIEW':
        let answersReview: any;
        if (item.question && item.question.id) {
          answersReview = {
            config1: new FormArray([
              this.fb.group({
                id: [''],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [''],
                allowRemove: false,
                type: 1
              }),
              this.fb.group({
                id: [''],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [''],
                allowRemove: false,
                type: 1
              })
            ]),
            config2: new FormArray([
              this.fb.group({
                id: [''],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [''],
                allowRemove: false,
                type: 2
              }),
              this.fb.group({
                id: [''],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [''],
                allowRemove: false,
                type: 2
              })
            ]),
            config3: new FormArray([
              this.fb.group({
                id: [''],
                url: [this.listEmotion[2].image, [Validators.required]],
                description: [''],
                allowRemove: false,
                type: 3
              }),
              this.fb.group({
                id: [''],
                url: [this.listEmotion[3].image, [Validators.required]],
                description: [''],
                allowRemove: false,
                type: 3
              })
            ])

          };

          if (item.question.type === 1) {
            const configArr1 = new FormArray([]);
            item.question.configs.map(item => {
              (configArr1 as FormArray).push(this.fb.group({
                id: [item.id || ''],
                from: [item.from || 0, [Validators.required]],
                to: [item.to || 0, [Validators.required]],
                description: [item.description || ''],
                allowRemove: [item.allowRemove || false],
                type: [item.type || 1],
              }));
            });
            answersReview.config1 = configArr1;
          } else if (item.question.type === 2) {
            const configArr2 = new FormArray([]);
            item.question.configs.map((item) => {
              configArr2.push(this.fb.group({
                id: [item.id || ''],
                from: [item.from || 0, [Validators.required]],
                to: [item.to || 0, [Validators.required]],
                description: [item.description || ''],
                allowRemove: [item.allowRemove || false],
                type: [item.type || 2],
              }));
            });
            answersReview.config2 = configArr2;
          } else {
            const configArr3 = new FormArray([]);
            item.question.configs.map(item => {
              configArr3.push(this.fb.group({
                id: [item.id || ''],
                url: [item.url || 0, [Validators.required]],
                description: [item.description || ''],
                allowRemove: [item.allowRemove || false],
                type: [item.type || 3],
              }));
            });
            answersReview.config3 = configArr3;
          }



        } else {
          answersReview = {
            config1: new FormArray([
              this.fb.group({
                id: [''],
                from: ['', [Validators.required]],
                to: ['', [Validators.required]],
                description: [''],
                allowRemove: false,
                type: [1],
              }),
              this.fb.group({
                id: [''],
                from: ['', [Validators.required]],
                to: ['', [Validators.required]],
                description: [''],
                allowRemove: false,
                type: [1],
              })
            ]),
            config2: new FormArray([
              this.fb.group({
                id: [''],
                from: ['', [Validators.required]],
                to: ['', [Validators.required]],
                description: [''],
                allowRemove: false,
                type: [2],
              }),
              this.fb.group({
                id: [''],
                from: ['', [Validators.required]],
                to: ['', [Validators.required]],
                description: [''],
                allowRemove: false,
                type: [2]
              })
            ]),
            config3: new FormArray([
              // this.fb.group({
              //   id: [''],
              //   url: [this.listEmotion[2].image || '', [Validators.required]],
              //   description: [''],
              //   allowRemove: false,
              //   type: [3],
              // }),
              // this.fb.group({
              //   id: [''],
              //   url: [this.listEmotion[3].image || '', [Validators.required]],
              //   description: [''],
              //   allowRemove: false,
              //   type: [3],
              // })
            ])
          };

        }

        return {
          id: [item.question ? item.question.id : ''],
          required: [item.question ? item.question.required : false],
          content: [item.question ? item.question.content : '', [Validators.required]],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          enableOtherField: [item.question ? item.question.enableOtherField : false],
          type: [item.question ? item.question.type : 1, [Validators.required]],
          recommendations: [item.recommendations ? item.question.enableCommentField : ''],
          ...answersReview
        };
      case 'CONTACT_INFO':

        let answersInfo = new FormArray([]);
        if (item.question && item.question.id) {
          // answers =

          item.question.fields.map((it, i) => {

            it.code = item.code;

            if (i === 0 || i === 1) {
              it.notHidden = true;
              it.notRequire = false;
            } else {

              if (!it.show) {
                it.notRequire = true;
              }
              it.notHidden = false;

            }
            (answersInfo as FormArray).push(this.initQuestion(it, i));


          });
        } else {
          answersInfo =
            new FormArray([
              this.fb.group({
                id: [''],
                label: ['Họ và Tên', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [true],
                notRequire: [false],
                index: 1
              }),
              this.fb.group({
                id: [''],
                label: ['Điện thoại', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [true],
                notRequire: [false],
                index: 2
              }),
              this.fb.group({
                id: [''],
                label: ['Công ty', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 3
              }),
              this.fb.group({
                id: [''],
                label: ['Địa chỉ 1', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 4
              }),
              this.fb.group({
                id: [''],
                label: ['Địa chỉ 2', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 5
              }),
              this.fb.group({
                id: [''],
                label: ['Thành phố', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 6
              }),
              this.fb.group({
                id: [''],
                label: ['Mã Zip', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 7
              }),
              this.fb.group({
                id: [''],
                label: ['Quốc gia', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 8
              }),
              this.fb.group({
                id: [''],
                label: ['Email', [Validators.required]],
                show: [true],
                required: [false],
                allowRemove: false,
                notHidden: [false],
                notRequire: [false],
                index: 9
              })
            ]);
        }

        return {
          id: [item.question ? item.question.id : ''],
          required: [item.question ? item.question.required : false],
          content: [item.question ? item.question.content : '', [Validators.required]],
          enableRecommendations: [item.question ? item.question.enableRecommendations : false],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          recommendations: [item.recommendations ? item.question.enableCommentField : ''],
          body: answersInfo,
        };
      case 'OPTIONAL_IMAGE':

        let answersImage = new FormArray([]);

        if (item.question && item.question.id) {
          // answers =

          item.question.answers.map((it, i) => {

            const object = Object.assign({}, it);
            it.code = item.code;


            (answersImage as FormArray).push(this.initQuestion(it, i));


          });
        } else {
          answersImage = new FormArray([
            this.fb.group({
              id: [''],
              image: ['', [Validators.required]],
              trueOrFalse: [false],
              content: ['', [Validators.required]],
              allowRemove: false,
            }),
            this.fb.group({
              id: [''],
              image: ['', [Validators.required]],
              content: ['', [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            })

          ]);
        }


        return {
          id: [item.question ? item.question.id : ''],
          required: [item.question ? item.question.required : false],
          content: [item.question ? item.question.content : ''],
          selectOne: [item.question ? item.question.selectOne : false],
          enableOtherField: [item.question ? item.question.enableOtherField : false],
          enableCommentField: [item.question ? item.question.enableCommentField : false],
          enableRecommendations: [item.question ? item.question.enableRecommendations : false],
          recommendations: [item.question ? item.question.recommendations : ''],
          answers: answersImage
        };
      default:
        break;
    }


  }


  initQuestion(item, index) {
    switch (item.code) {
      case 'DROPDOWN':
        return (this.fb.group({
          id: [item.id || ''],
          content: [item.content || '', [Validators.required]],
          trueOrFalse: [item.trueOrFalse || false],
          allowRemove: [item.allowRemove || false],
        }) as any);
      case 'OPTIONAL':
        return this.fb.group({
          id: [item.id || ''],
          content: [item.content || '', [Validators.required]],
          trueOrFalse: [item.trueOrFalse || false],
          allowRemove: [item.allowRemove || false],
        });
      case 'BOX':


        return this.fb.group({
          id: [item.id || ''],
          title: [item.title || '', [Validators.required]],
          format: [item.format || null, [Validators.required]],
          allowRemove: [item.allowRemove || false],
          index: index + 1
        });

      case 'FILE':
        return {};
      case 'REVIEW':

        if (item.type === 1) {
          return this.fb.group({
            id: [item.id || ''],
            from: [item.from || '', [Validators.required]],
            to: [item.to || '', [Validators.required]],
            description: [item.description || ''],
            allowRemove: [item.allowRemove || false],
          });
        } else if (item.type === 2) {
          return this.fb.group({
            id: [item.id || ''],
            from: [item.from || '', [Validators.required]],
            to: [item.to || '', [Validators.required]],
            description: [item.description || ''],
            allowRemove: [item.allowRemove || false],
          });
        } else {
          return this.fb.group({
            id: [item.id || ''],
            url: [item.url || '', [Validators.required]],
            description: [item.description || ''],
            allowRemove: false,
          });
        }
      case 'CONTACT_INFO':


        return this.fb.group({
          id: [item.id || ''],
          label: [item.label || '', [Validators.required]],
          show: [item.show],
          required: [item.required || false],
          allowRemove: [item.allowRemove || false],
          notHidden: [item.notHidden || false],
          notRequire: [item.notRequire || false],
          index: [index || 0]
        });
      case 'OPTIONAL_IMAGE':

        return this.fb.group({
          id: [item.id || ''],
          image: [item.image || '', [Validators.required]],
          trueOrFalse: [item.trueOrFalse || false],
          content: [item.content || '', [Validators.required]],
          allowRemove: [item.allowRemove || false],
        });
      default:
        break;
    }
  }




  changePosition(index, type) {


    if (type === 'up') {

      if (index === 0) { return; }

      const stamp = this.questions.controls[index - 1];
      this.questions.controls[index - 1] = this.questions.controls[index];
      this.questions.controls[index] = stamp;
      this.genderTittle();

    } else {
      if (index === this.questions.controls.length - 1) { return; }
      const stamp = this.questions.controls[index + 1];
      this.questions.controls[index + 1] = this.questions.controls[index];
      this.questions.controls[index] = stamp;
      this.genderTittle();

    }





  }

  genderTittle() {


    this.questions.controls.map((item, index) => {
      this.questions.at(index).patchValue({
        name: `Câu ${index + 1}`,
        index: index + 1
      });
      // item.value.name = `Câu ${index + 1}`;
      // item.value.index = index + 1;
      // return item;
    });
  }

  keyActive(data, index) {


    return this.settingActiveCollapse[`${data.code}${this.pageCurrent}${index}`];
  }


  changeActive(data, index) {

    this.settingActiveCollapse[`${data.code}${this.pageCurrent}${index}`] = !this.settingActiveCollapse[`${data.code}${this.pageCurrent}${index}`];
    if (this.settingActiveCollapse[`${data.code}${this.pageCurrent}${index}`]) {
      for (const property in this.settingActiveCollapse) {
        if (property !== `${data.code}${this.pageCurrent}${index}`) {
          this.settingActiveCollapse[property] = false;
        }
      }
    }
  }

  allowDrop(event) {
    event.preventDefault();
  }

  removeQuestion(item, index) {


    this.questions.removeAt(index);


    delete this.settingActiveCollapse[`${item.value.code}${this.pageCurrent}${index}`];


    // this.listQuestion.splice(index, 1);
    this.genderTittle();
  }

  collapseStatusHidden(item, index) {


    return this.settingActiveCollapse[`${item.code}${this.pageCurrent}${index}`];
  }

  collapseStatusShow(item, index) {

    return !this.settingActiveCollapse[`${item.code}${this.pageCurrent}${index}`];
  }

  addAnswer(item, i) {


    switch (item.code) {
      case 'DROPDOWN':
        this.questions.controls[i].get('question').controls.answers.push(this.fb.group({
          content: ['', [Validators.required]],
          trueOrFalse: [false],
          allowRemove: true,
          index: this.questions.controls[i].get('question').controls.answers.length
        }));
        break;
      case 'OPTIONAL':
        this.questions.controls[i].get('question').controls.answers.push(this.fb.group({
          content: ['', [Validators.required]],
          trueOrFalse: [false],
          allowRemove: true,
          index: this.questions.controls[i].get('question').controls.answers.length
        }));
        break;
      case 'BOX':
        this.questions.controls[i].get('question').controls.body.push(this.fb.group({
          title: ['', [Validators.required]],
          format: [null, [Validators.required]],
          allowRemove: true,
          index: this.questions.controls[i].get('question').controls.body.controls.length + 1
        }));
        break;
      case 'REVIEW':
        if (this.questions.controls[i].get('question').controls.type.value === 1) {
          (this.questions.controls[i].get('question').controls.config1 as FormArray).push(this.fb.group({
            from: ['', [Validators.required]],
            to: ['', [Validators.required]],
            description: [''],
            allowRemove: true,
          }));
        } else if (this.questions.controls[i].get('question').controls.type.value === 2) {
          this.questions.controls[i].get('question').controls.config2.push(this.fb.group({
            from: ['', [Validators.required]],
            to: ['', [Validators.required]],
            description: [''],
            allowRemove: true,
          }));
        } else {
          this.questions.controls[i].get('question').controls.config3.push(this.fb.group({
            id: ['/assets/emotion/em-3.png', [Validators.required]],
            description: [''],
            allowRemove: true,
          }));
        }

        break;
      case 'FILE':
        // this.questions.controls[i].get('question').controls.answers.push(this.fb.group({
        //   content: ['', [Validators.required]],
        // }))
        break;
      case 'CONTACT_INFO':
        this.questions.controls[i].get('question').controls.body.push(this.fb.group({
          label: [''],
          show: [false],
          required: [false],
          allowRemove: true,
          index: this.questions.controls[i].get('question').controls.body.controls.length + 1
        }));
        break;
      case 'OPTIONAL_IMAGE':
        this.questions.controls[i].get('question').controls.answers.push(this.fb.group({
          image: ['', [Validators.required]],
          trueOrFalse: [false],
          content: ['', [Validators.required]],
          allowRemove: true,
        }));
        break;
      default:
        break;
    }


  }


  onExpandChange(e: NzFormatEmitEvent): void {
    const node = e.node;
    if (
      node &&
      node.getChildren().length === 0 &&
      node.isExpanded &&
      node.origin &&
      node.origin.id
    ) {
      this.loadChildSurveyGroup(node.origin.id).subscribe((res: any) => {
        if (res.code === 1) {
          const childData = res.data
            ? res.data.map((gg: any) => {
              const tmp = { ...gg };
              tmp.title = gg.name;
              tmp.key = gg.id;
              return tmp;
            })
            : [];
          if (childData && childData.length) {
            node.addChildren(childData);
          } else {
            node.isLeaf = true;
          }
        }
      });
    }
  }



  loadChildSurveyGroup(parentId: string): Observable<NzTreeNodeOptions[]> {
    const query = {
      parentId,
      page: 1,
      pageSize: 10,
    };
    return this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$));
  }

  changeShow(event, i, idx) {



    if (event) {
      this.questions.controls[i].controls.question.controls.body.controls[idx].get('notRequire').setValue(false);

      this.questions.controls[i].get('question').controls.body.controls[idx].controls.label.setValidators(Validators.required);
    } else {
      this.questions.controls[i].controls.question.controls.body.controls[idx].get('notRequire').setValue(true);
      this.questions.controls[i].controls.question.controls.body.controls[idx].get('required').setValue(false);
      this.questions.controls[i].get('question').controls.body.controls[idx].controls.label.clearValidators();
    }
    this.form.updateValueAndValidity();
  }

  removeAnswer(item, i, idx) {
    if (this.isUpdate) {
      return;
    }
    if (item.code === 'REVIEW') {
      if (this.questions.controls[i].get('question').controls.type.value === 1) {
        this.questions.controls[i].get('question').controls.config1.removeAt(idx);
      } else if (this.questions.controls[i].get('question').controls.type.value === 2) {
        this.questions.controls[i].get('question').controls.config2.removeAt(idx);
      } else {
        this.questions.controls[i].get('question').controls.config3.removeAt(idx);
      }

    } else if (item.code === 'BOX' || item.code === 'CONTACT_INFO') {
      this.questions.controls[i].get('question').controls.body.removeAt(idx);
    } else {
      this.questions.controls[i].get('question').controls.answers.removeAt(idx);
    }

  }

  radioChange(event, index) {

    this.isSubmitted = false;

    if (event === 1) {
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config1.controls as any)) {


        element.controls.from.setValidators([Validators.required]);
        element.controls.from.updateValueAndValidity();
        element.controls.to.setValidators([Validators.required]);
        element.controls.to.updateValueAndValidity();
      }
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config2.controls as any)) {


        element.controls.from.clearValidators();
        element.controls.from.updateValueAndValidity();
        element.controls.to.clearValidators();
        element.controls.to.updateValueAndValidity();
      }
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config3.controls as any)) {


        element.controls.url.clearValidators();
        element.controls.url.updateValueAndValidity();
        // element.controls.to.clearValidators()
      }
    } else if (event === 2) {
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config1.controls as any)) {


        element.controls.from.clearValidators();
        element.controls.from.updateValueAndValidity();
        element.controls.to.clearValidators();
        element.controls.to.updateValueAndValidity();
      }
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config2.controls as any)) {

        element.controls.from.setValidators([Validators.required]);
        element.controls.from.updateValueAndValidity();
        element.controls.to.setValidators([Validators.required]);
        element.controls.to.updateValueAndValidity();

      }
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config3.controls as any)) {


        element.controls.url.clearValidators();
        element.controls.url.updateValueAndValidity();
        // element.controls.to.clearValidators()
      }
    } else {
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config1.controls as any)) {


        element.controls.from.clearValidators();
        element.controls.from.updateValueAndValidity();
        element.controls.to.clearValidators();
        element.controls.to.updateValueAndValidity();
      }
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config2.controls as any)) {

        element.controls.from.setValidators([Validators.required]);
        element.controls.from.updateValueAndValidity();
        element.controls.to.setValidators([Validators.required]);
        element.controls.to.updateValueAndValidity();

      }
      for (const element of ((this.form.controls.content as any).controls[this.pageCurrent - 1].controls.questions.controls[index].controls.question.controls.config3.controls as any)) {


        element.controls.url.setValidators([Validators.required]);
        element.controls.url.updateValueAndValidity();
        // element.controls.to.clearValidators()
      }
    }


  }


  openUploadDialog(field: string, i, idx) {
    if (this.isUpdate) {
      return;
    }

    const fileType = 'jpg,jpeg,png,gif';

    const modal = this.modal.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {

          if (field === 'avatar') {


            this.questions.controls[i].get('question').controls.answers.controls[idx].get('image').setValue(changeParam.file.response.url);


            // this.listAnswerImage[i].value = changeParam.file.response.url;
            // this.avatar = [{ url: changeParam.file.response.url }];
            // this.avatarId = changeParam.file.response.id;
            // this.validAvatar = false;

            // // if (this.productImages.length === 0) {
            //   this.form
            //     .get("mainImage")
            //     .setValue(changeParam.file.response.url);
            // // }



          }

        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }
  genStyleImage(data) {


    if (data.image) {
      return { backgroundImage: `url(${data.image})`, border: 'none' };
    }
  }

  removeImage(event, index) {
    if (this.isUpdate) {
      return;
    }
    this.listAnswerImage[index].value = '';
  }

  addEmotion(item, index) {
    if (this.isUpdate) {
      return;
    }
    this.questions.controls[index].get('question').controls.config3.push(this.fb.group({
      url: [item.image, [Validators.required]],
      description: [''],
      allowRemove: true,
    }));
  }
  async submit() {
    this.isSubmitted = true;
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.indexOf('&nbsp;') !== -1) {
            const note = f.controls[i].value.split('&nbsp;').filter(item => item !== '&nbsp;').join('');


            if (note.trim().length === 0) {
              f.controls[i].value = null;
            }
          } else {
            if (f.controls[i].value.trim().length === 0) {
              f.controls[i].value = null;
            }
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };


    // (((this.form.controls.content as FormGroup).controls[this.pageCurrent -1] as FormGroup).controls.questions as FormArray).controls.map(element => {
    //   if (element.invalid) {
    //
    //     let key = `${element.value.code}${this.pageCurrent}${element.value.index - 1}`;
    //

    //     this.settingActiveCollapse[key] = true;
    //     for (const property in this.settingActiveCollapse) {
    //       if (property !== key) {
    //         this.settingActiveCollapse[property] = false;
    //       }
    //     }
    //     return;
    //   }
    // });
    (this.form.controls.content as FormArray).controls.map((item: any, index) => {
      if (item.invalid) {

        this.pageCurrent = index + 1;
        (item.controls.questions as FormArray).controls.map(element => {
            if (element.invalid) {

              const key = `${element.value.code}${this.pageCurrent}${element.value.index - 1}`;


              this.settingActiveCollapse[key] = true;
              for (const property in this.settingActiveCollapse) {
                if (property !== key) {
                  this.settingActiveCollapse[property] = false;
                }
              }
              return;
            }
          });
      }
    });
    const body = this.form.controls.content.value.map((item, index) => {

      item.index = index + 1;
      item.questions.map((it, i) => {


        if (it.question.type === 1 && (this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config1.controls) {
          it.question.configs = it.question.config1;

          for (const element of ((this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config2.controls as any)) {


            element.controls.from.clearValidators();
            element.controls.from.updateValueAndValidity();
            element.controls.to.clearValidators();
            element.controls.to.updateValueAndValidity();
          }
          for (const element of ((this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config3.controls as any)) {


            element.controls.id.clearValidators();
            element.controls.id.updateValueAndValidity();
            // element.controls.to.clearValidators()
          }
        } else if (it.question.type === 2 && (this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config2.controls) {
          it.question.configs = it.question.config2;
          for (const element of ((this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config1.controls as any)) {


            element.controls.from.clearValidators();
            element.controls.from.updateValueAndValidity();
            element.controls.to.clearValidators();
            element.controls.to.updateValueAndValidity();
          }
          for (const element of ((this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config3.controls as any)) {


            element.controls.id.clearValidators();
            element.controls.id.updateValueAndValidity();
          }
        } else if (it.question.type === 3 && (this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config3.controls) {
          it.question.configs = it.question.config3;
          for (const element of ((this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config1.controls as any)) {


            element.controls.from.clearValidators();
            element.controls.from.updateValueAndValidity();
            element.controls.to.clearValidators();
            element.controls.to.updateValueAndValidity();
          }
          for (const element of ((this.form.controls.content as any).controls[index].controls.questions.controls[i].controls.question.controls.config2.controls as any)) {


            element.controls.from.clearValidators();
            element.controls.from.updateValueAndValidity();
            element.controls.to.clearValidators();
            element.controls.to.updateValueAndValidity();
          }
        }
        return it;
      });


      return item;
    });

    this.loadingSubmit = true;

    for (let ii = 0; ii < body.length; ii++) {
      if (body[ii].questions && body[ii].questions.length) {
        for (let i = 0; i < body[ii].questions.length; i++) {
          let tmpValue = body[ii].questions[i].question.content;
          if (isIncludeBase64Image(body[ii].questions[i].question.content)) {
            const matchedValues = body[ii].questions[i].question.content.match(regexBase64Image);
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < matchedValues.length; j++) {
              try {
                const url = await this.convertBase64ToUrl(matchedValues[j]);
                tmpValue = tmpValue.replace(matchedValues[j], url);
              } catch (e) {
                tmpValue = tmpValue.replace(matchedValues[j], '');
              }
            }
            body[ii].questions[i].question.content = tmpValue;
          }
        }
      }
    }

    const newObject = {
      ...this.form.value,
      content: body
    };
    recursive(this.form);
    if (this.form.invalid) {
      this.loadingSubmit = false;
      return;
    }
    // track('manualTrackInteractionContent', {
    //   interaction: 'click',
    //   regionName: 'portal_survey_form',
    //   contentName: 'save',
    //   target: `${window.location.origin}/portal/survey`,
    //   payload: JSON.stringify(newObject)
    // });

    if (!this.isUpdate) {
      this.surveyService.create(newObject)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track('alert', {
            //   alertType: 'success',
            //   alertMessage: 'Thêm mới thành công',
            // });
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/survey']);
              return;
            }, 500);
          } else {
            // track('alert', {
            //   alertType: 'failed',
            //   alertMessage: res.message,
            // });
            // track('error', {
            //   apiCall: `${window.location.origin}/survey`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500,
            //   apiPayload: JSON.stringify(newObject)
            // });
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.loadingSubmit = false;
        }),
        // tslint:disable-next-line:no-unused-expression
        (err) => {
          // track('alert', {
          //   alertType: 'failed',
          //   alertMessage: err,
          // });
          // track('error', {
          //   apiCall: `${window.location.origin}/survey`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500,
          //   apiPayload: JSON.stringify(newObject)
          // });
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.loadingSubmit = false;
        };
    } else {
      this.surveyService.update(this.id, newObject)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          if (res.code === 1) {
            // track('alert', {
            //   alertType: 'success',
            //   alertMessage: 'Cập nhật thành công',
            // });
            this.openMessage('success', 'Thành công', 'Cập nhật thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/survey']);
              return;
            }, 500);
          } else {
            // track('alert', {
            //   alertType: 'failed',
            //   alertMessage: res.message,
            // });
            // track('error', {
            //   apiCall: `${window.location.origin}/survey/edit/${this.id}`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500,
            //   apiPayload: JSON.stringify(newObject)
            // });
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.loadingSubmit = false;
        }),
        // tslint:disable-next-line:no-unused-expression
        (err) => {
          // track('alert', {
          //   alertType: 'failed',
          //   alertMessage: err,
          // });
          // track('error', {
          //   apiCall: `${window.location.origin}/survey/edit/${this.id}`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500,
          //   apiPayload: JSON.stringify(newObject)
          // });
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.loadingSubmit = false;
        };
    }


  }


  changeRequireQs(index) {


    if (this.questions.controls[index].value.code === 'CONTACT_INFO') {
      this.questions.controls[index].controls.question.controls.body.controls[0].get('required').setValue(true);
      this.questions.controls[index].controls.question.controls.body.controls[0].get('notRequire').setValue(true);
      this.questions.controls[index].controls.question.controls.body.controls[1].get('required').setValue(true);
      this.questions.controls[index].controls.question.controls.body.controls[1].get('notRequire').setValue(true);
    }
  }


  changeOptionalCorrect(index, i) {


  }

  changeCorrect(event, index, i) {


    if (event) {
      for (const [idx, e] of this.questions.controls[index].get('question').controls.answers.controls.entries()) {
        if (idx !== i && this.questions.controls[index].get('question').controls.selectOne.value) {

          e.get('trueOrFalse').setValue(false);
        }
      }
    }




  }

  changeOptionSelect(event, index) {


    if (event) {
      for (const [idx, e] of this.questions.controls[index].get('question').controls.answers.controls.entries()) {
        e.get('trueOrFalse').setValue(false);
      }
    }




  }

  nextPage() {

    this.pageCurrent += 1;
    if (this.currentSurvey && this.currentSurvey.surveyContent.content[this.pageCurrent - 1]) {
      const key = `${this.currentSurvey.surveyContent.content[this.pageCurrent - 1].questions[0].code}${this.pageCurrent}0`;
      this.settingActiveCollapse[key] = true;
      for (const property in this.settingActiveCollapse) {
        if (property !== key) {
          this.settingActiveCollapse[property] = false;
        }
      }
    }
  }

  prevPage() {

    this.pageCurrent -= 1;
    const key = `${this.currentSurvey.surveyContent.content[this.pageCurrent - 1].questions[0].code}${this.pageCurrent}0`;
    this.settingActiveCollapse[key] = true;
    for (const property in this.settingActiveCollapse) {
      if (property !== key) {
        this.settingActiveCollapse[property] = false;
      }
    }
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  convertBase64ToUrl(base64): any {
    const formData = new FormData();
    formData.append('content', base64toBlob(base64), 'image-product-description.png');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.uploadService.uploadFile(formData, currentUser).toPromise().then(
      (result) => {
        if (result.status === 200 && result.data && result.data.url) {
          return result.data.url;
        }
      }
    );
  }
}
