import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { CompaniesService, RootService } from "src/app/services";

@Component({
  selector: 'app-companies-import',
  templateUrl: './companies-import.component.html',
  styleUrls: ['./companies-import.component.scss']
})
export class CompaniesImportComponent implements OnInit {
  isVisible: boolean = false;
  importData: any = null;
  importName: any = null;
  isLoading: boolean = false;
  unsubscribe$ = new Subject<void>();

  @Output() importCompany = new EventEmitter<string>();
  @ViewChild('fileInput', {static: true}) fileInput: any
  constructor(
    private companiesService: CompaniesService,
    private notification: NzNotificationService
  ) { }

  ngOnInit() {}

  openModal() {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true
    const formData = new FormData();
    formData.append("file", this.importData);
    this.companiesService.importCompanyFile(formData).subscribe((res: any) => {


      if(res.code === 1) {
        this.notification.success('Thành công', 'Import doanh nghiệp thành công')
        this.handleCancel()
        this.importCompany.emit()
      } else {
        this.notification.warning('Có lỗi xảy ra', res.message || 'Vui lòng thử lại')
      }
      this.isLoading = false
    }, err => {
      if(err == 'Unknown Error') {
        this.importName = null;
        this.importData = null;
        this.fileInput.nativeElement.value = '';
        this.isLoading = false
        return this.notification.warning('Có lỗi xảy ra', 'Vui lòng chọn lại file')
      }
      this.notification.warning('Có lỗi xảy ra', 'Vui lòng thử lại')
      this.isLoading = false
    })
  }

  handleCancel(): void {
    this.isVisible = false;
    this.importName = null
    this.importData = null
    this.fileInput.nativeElement.value = '';
  }

  importFile(data: any) {
    if (data) {
      this.importName = data.name;
      this.importData = data;
    } else {
      this.importName = null;
      this.importData = null;
      this.fileInput.nativeElement.value = '';
    }
  }

}
