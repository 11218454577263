import {NzNotificationService} from 'ng-zorro-antd';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {UploadLoyaltyService} from '../../services/loyalty/upload.service';

const IMG_REGEX = /(.*?)\.(jpg|png|jpeg)$/i;

@Component({
  selector: 'app-upload-img-button',
  templateUrl: './upload-img-button.component.html',
  styleUrls: ['./upload-img-button.component.scss']
})
export class UploadImgButtonComponent implements OnInit {
  @Input() banners: any;
  @Input() required = false;
  @Output() onReceive = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();

  constructor(private notificationService: NzNotificationService,
              private upload: UploadLoyaltyService) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
  }


  ngOnInit(): void {
  }


  onChangeBanner(files) {
    if (files.length > 0) {
      const images = [];
      Array.from(files).forEach(file => {
        if (+this.onGetSizeFile((file as any).size) > 5) {
          // this.notification('Dung lượng file vượt quá 5MB');
          this.createNotification('error', 'THÔNG BÁO', 'Dung lượng file vượt quá 5MB');
        } else {
          if (IMG_REGEX.test((file as any).name)) {
            const formData = new FormData();
            formData.append('file', file as Blob);
            const a = [];
            a.push(file);
            // tslint:disable-next-line:new-parens
            const reader = new FileReader;
            reader.onload = (e) => {
              const fileData = (e.target as any).result;
              return this.upload.postImg(formData).subscribe((res) => {
                  images.push(res.data);
                  // this.infoForm.get('image').setValue(res.fileId);
                  // this.srcBanner = res?.src;
                  this.banners = images.map(item => {
                    return {
                      ...item,
                      src: item.url
                    };
                  });
                  // this.isImage = true;
                  this.onReceive.emit({id: res.data.url || res.fileId, srcBanner: res.data.url || res.src});
                  this.required = false;

                }
              );
            };
            reader.readAsArrayBuffer(a[0]);
          } else {
            // this.notification('Vui lòng chọn định dạng ảnh JPG, PNG');
            this.createNotification('error', 'THÔNG BÁO', 'Vui lòng chọn định dạng ảnh JPG, PNG, JPEG');
          }
        }
      });

      // this.banners = images;
    }
  }

  onRemoveImage() {
    this.banners = [];
    // this.required = true;
    // this.infoForm.get('image').setValue(null);
    // this.isImage = false;
    this.onDelete.emit({id: null, isRequired: true});
  }

  onGetSizeFile = (size: string) => (+size / (1024 * 1024)).toFixed(2);

  createNotification(type, title, message) {
    this.notificationService.create(type, title, message);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
  }
}
