import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { ShopsService, ProductsStoresService } from 'src/app/services';
import { removeEmptyQuery } from 'src/app/helpers';

@Component({
  selector: 'app-assign-stores',
  templateUrl: './assign-stores.component.html',
  styleUrls: ['./assign-stores.component.scss']
})
export class AssignStoresComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject<void>();
  isShow: Boolean = false;

  form: FormGroup = this.fb.group({
    titleId: [null, [Validators.required]],
    shopIds: [null, [Validators.required]],
  });

  constructor(
    private notification: NzNotificationService,
    private fb: FormBuilder,
    private shopService: ShopsService,
    private shopService2: ProductsStoresService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }
 
  productId: string;
  ngOnInit() {
    this.productId = window.location.href.split('/')[5];
    this.loadData(null);
    this.loadTitle();
  }

  page = 1;
  pageSize = 10;
  delayTimer: any;
  listOfAllData: any[] = [];
  loadData(data: any) {
    const query: any = {
      keyword: data ? data.trim() : null,
      // product_id: this.productId,
      productId: this.productId,
      is_owner: 1,
      page_size: 50
    };

    removeEmptyQuery(query);

    this.shopService.getAll(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.listOfAllData = res.data.items;
      }
    );

    this.isShow = false;
  }

  searchShop(data: any) {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadData(data);
    }, 300);
  }

  titles: any = [];
  loadTitle() {
    this.shopService.getAllTitle().subscribe(
      (res) => {
        if (res.code === 1) {
          this.titles = res.data.items;
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  isLoadingBtn: Boolean = false;
  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }

    this.isLoadingBtn = true;
    this.shopService2.assign(this.productId, this.form.value).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.isLoadingBtn = false;
        if (res.code === 1) {
          this.openMessage('success', 'Thành công', `Thêm điểm bán thành công`);
          this.shopService2.getActive(true);
          this.form.reset();
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      },
      (err) => {
        this.notification.create(
          'error',
          'Có lỗi xảy ra !!!',
          `${err}`
        );
        this.isLoadingBtn = false;
        return;
      },
    );
  }

  success() {
    this.isLoadingBtn = false;
  }

  handleCancel() {
    this.shopService2.getActive(true);
    this.form.reset();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
