import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-qrcode-image-preview',
  templateUrl: './qrcode-image-preview.component.html',
  styleUrls: ['./qrcode-image-preview.component.scss']
})
export class QrcodeImagePreviewComponent implements OnInit {
  @Input() templateData: Observable<any>
  unsubscribe$ = new Subject<void>();
  data: any = null

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      this.data = { ...val }
    })
  }

}
