import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompaniesRoutingModule } from "./companies-routing.module";
import { CompaniesComponent } from "./companies.component";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { SharedModule } from "src/app/shared";
import { CreateUpdateComponent } from "./create-update/create-update.component";
import { ListProductsComponent } from "./list-products/list-products.component";
import { CreateUpdateCompanyProductsComponent } from "./list-products/create-update/create-update.component";
import { CompaniesImportComponent } from './companies-import/companies-import.component';
import { AgmCoreModule } from '@agm/core';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {NgxSummernoteModule} from 'ngx-summernote';
import {NgxSummernoteCustomModule} from '../../lib/ngx-summernote-custom.module';
@NgModule({
  declarations: [
    CompaniesComponent,
    CreateUpdateComponent,
    ListProductsComponent,
    CreateUpdateCompanyProductsComponent,
    CompaniesImportComponent,
  ],
  imports: [
    CommonModule,
    CompaniesRoutingModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBaNv-5HXYcq1DarkW1pmyP6wcYYZFJRdM',
      libraries: ['places']
    }),
    AngularEditorModule,
    NgxSummernoteCustomModule,
  ],
})
export class CompaniesModule {}
