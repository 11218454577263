import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-products-delete',
  templateUrl: './products-delete.component.html',
  styleUrls: ['./products-delete.component.scss']
})
export class ProductsDeleteComponent implements OnInit {
  isVisible = false;
  // tslint:disable-next-line:variable-name
  check_barcode = [];
  barcodes: string;
  isLoadingBtn = false;
  @Output() data = new EventEmitter<object>();
  constructor() { }

  ngOnInit() {
  }
  showModal() {
    this.isVisible = true;
    this.check_barcode = [];
    this.barcodes = undefined;
    this.isLoadingBtn = false;
  }
  handleCancel() {
    this.isVisible = false;
  }
  checkBarcode() {
    // this.check_barcode = [];
    // const barcode = this.barcodes.split(/\r?\n/);
    // for (let i = 0; i < barcode.length; i++) {
    //   const check = this.validateGtin(barcode[i].substr(0, barcode[i].length - 1));
    //   const result = barcode[i].substr(barcode[i].length - 1) === ('' + check);
    //   if (result === false) {
    //     const res = {
    //       index: i + 1,
    //       value: barcode[i]
    //     };
    //     this.check_barcode.push(res);
    //   }
    // }
  }
  // tslint:disable-next-line:variable-name
  validateGtin(number: string): number {
    if (isNaN(+number)) {
      return null;
    }
    const length = number.length;
    if ([7, 11, 12, 13, 16, 17].indexOf(length) === -1) {
      return null;
    }
    const num = length - 1;
    let isMultiplyBy3 = true;
    let sum = 0;
    for (let i = num; i >= 0; i--) {
      sum += (+number[i]) * (isMultiplyBy3 ? 3 : 1);
      isMultiplyBy3 =  !isMultiplyBy3;
    }
    const nextMultipleOfTen = Math.ceil(sum / 10) * 10;
    return nextMultipleOfTen - sum;

  }
  onSubmit() {
    if (this.check_barcode.length) {
      return;
    }
    if (this.barcodes === undefined) {
      return;
    }
    this.data.emit({value: this.barcodes.split(/\r?\n/)});
    this.isLoadingBtn = true;
  }
  cancelLoading() {
    this.isLoadingBtn = false;
  }
}
