import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompaniesComponent } from './companies.component';
import { ListProductsComponent } from './list-products/list-products.component';
import {CreateUpdateComponent} from './create-update/create-update.component';

const routes: Routes = [
  {
    path: '',
    component: CompaniesComponent,
  },
  // { path: ':idCompany', component: ListProductsComponent },
  {
    path: ':type/:idCompany',
    component: CreateUpdateComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
