import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ShopsService extends BaseService {
  shopType = new BehaviorSubject<any>("default");
  currentShopType = this.shopType.asObservable();

  getShopType(type: any) {
    this.shopType.next(type);
  }

  active = new BehaviorSubject<any>("default");
  currentActive = this.active.asObservable();

  getActive(active: any) {
    this.active.next(active);
  }

  getAll(query?: any): Observable<any> {
    return this.get<any>('/shop', { params: query });
  }

  getAllProductByStore(id: string, query?: any): Observable<any> {
    return this.get<any>(`/shop/${id}/product`, { params: query });
  }

  getAllReason(query?: any): Observable<any> {
    return this.get<any>('/shop/product/denied/reason', { params: query });
  }

  getAllTitle(query?: any): Observable<any> {
    return this.get<any>('/shop/define/title', { params: query });
  }

  getAllType(query?: any): Observable<any> {
    return this.get<any>('/shop/define/type', { params: query });
  }

  getById(id: string): Observable<any> {
    return this.get<any>(`/shop/${id}`);
  }

  add(id: string, body: any): Observable<any> {
    return this.post<any>(`shop/${id}/product`, body);
  }

  create(body: any): Observable<any> {
    return this.post<any>(`/shop`, body);
  }

  update(id: string, body: any): Observable<any> {
    return this.put<any>(`/shop/${id}`, body);
  }

  updateTitle(id: string, body: any): Observable<any> {
    return this.put<any>(`/shop/${id}/product`, body);
  }

  destroy(body: any): Observable<any> {
    return this.delete<any>(`/shop`, body);
  }

  destroyProductByStore(id: string, body: any): Observable<any> {
    return this.delete<any>(`/shop/${id}/product`, body);
  }

  block(id: string, body: any): Observable<any> {
    return this.post<any>(`/shop/${id}/product/denied`, body);
  }

  unblock(id: string, body: any): Observable<any> {
    return this.delete<any>(`/shop/${id}/product/denied`, body);
  }

  verify(id: string, body: any): Observable<any> {
    return this.post<any>(`/shop/${id}/product/verify`, body);
  }

  unverify(id: string, body: any): Observable<any> {
    return this.delete<any>(`/shop/${id}/product/verify`, body);
  }

  exportExcel(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>('/shop/export', httpOptions);
  }
  importExcel(query?: any, body?: any) {
    return this.post<any>('/shop/import/all', body, {params: query});
  }
}
