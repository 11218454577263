import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef, NzNotificationService} from 'ng-zorro-antd';
import {MenuService} from '../../services/business/menu/menu.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  category: string;

  constructor(public modalRef: NzModalRef<AddCategoryComponent>,
              private notiService: NzNotificationService,
              private menuService: MenuService) {}

  ngOnInit() {
  }

  onClose() {
    this.modalRef.close();
  }

  onConfirm() {
    if (!this.category) {
      this.notiService.error('Thông báo', 'Vui lòng nhập tên danh mục');
    } else {
      this.menuService.createFoodCategory({
        name: this.category
      }).subscribe((response) => {
        if (response && response.code === 1) {
          this.notiService.success('Thông báo', `Tạo danh mục ${this.category} thành công`);
          this.modalRef.close(response);
        } else {
          this.notiService.error('Thông báo', `Tạo danh mục ${this.category} thất bại`);
        }
      });
    }
  }

}
