import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {CompaniesService, ProductsService} from 'src/app/services';
import { embedYoutubeUrl, numberWithCommas, isValidYoutubeUrl, checkTypePDF } from 'src/app/utility/common/commonFunction';

import { iconLinkConvert } from 'src/app/utility/constance/productTemplate';

@Component({
  selector: 'app-businesses-slider-show',
  templateUrl: './businesses-slider-show.component.html',
  styleUrls: ['./businesses-slider-show.component.scss']
})
export class BusinessesSliderShowComponent implements OnInit, OnChanges {
  numberWithCommas = numberWithCommas;
  isValidYoutubeUrl = isValidYoutubeUrl;
  checkTypePDF = checkTypePDF;
  embedYoutubeUrl = embedYoutubeUrl;

  @Input() slides;
  @Input() type;
  typeShow: any = 'image';
  constructor(    private companiesService: CompaniesService,
                  private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.typeShow = this.type || 'image';
  }

  renderVideo(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  renderPDF(url: string) {
    return this.sanitizer.bypassSecurityTrustHtml(`<object
    data="${url}"
    style="width: 100%;height:100%;"
    ></object>`);
  }

}
