export function filterProductsConfig (item?) {
  const result = [
    {
      key: 'query',
      name: 'Tên hoặc mã sản phẩm',
      type: 'text',
    },
    {
      key: 'status',
      name: 'Trạng thái sản phẩm',
      type: 'select-box',
      settingComponentConfig: {
        options: [
        ]
      },
    },
  ];
    return result;
  }