export const iconConvert = [
  { new: 'icon-ic_Account_18px', old: '0' },
  { new: 'icon-Document', old: '1' },
  { new: 'icon-phone', old: '/assets/svg/icon-wallet.svg' },
  { new: 'icon-teacher', old: '/assets/svg/icon-education.svg' },
  { new: 'icon-ic_Heart_24px', old: '/assets/svg/icon-hobby.svg' },
  { new: 'icon-building', old: '/assets/svg/icon-company.svg' },
  { new: 'icon-Email', old: '3' },
  { new: 'icon-bag_24px', old: '/assets/svg/icon-job.svg' },
  { new: 'icon-ic_placeholder_24px', old: '/assets/svg/map-1.svg' },
]
export const iconLinkConvert = [
  { new: 'icon-ic_globe-alt_24px', old: '/assets/svg/icon-globe.svg', product: '' },
  { new: 'icon-facebook', old: '/assets/svg/icon-facebook.svg', product: '' },
  { new: 'icon-shopee', old: '/assets/svg/icon-shopee.svg', product: 'Shopee' },
  { new: 'icon-zalo', old: '/assets/svg/icon-zalo.svg', product: '' },
  { new: 'icon-instagram', old: '/assets/svg/icon-ig.svg', product: '' },
  { new: 'icon-tiktok', old: '/assets/svg/icon-tiktok.svg', product: '' },
  { new: 'icon-tiki', old: '/assets/svg/icon-tiki.svg', product: 'Tiki' },
  { new: 'icon-youtube', old: '/assets/svg/icon-youtube.svg', product: '' },
  { new: 'icon-linkedin', old: '/assets/svg/icon-linkedin.svg', product: '' },
  { new: 'icon-lazada', old: '/assets/svg/icon-lazada.svg', product: 'Lazada' },
  { new: 'icon-sendo', old: '/assets/svg/icon-sendo.svg', product: 'Sendo' },
]
