import { Component, OnInit } from "@angular/core";
import {
  NzFormatEmitEvent,
  NzNotificationService,
  NzTreeNodeOptions,
} from "ng-zorro-antd";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { convertDateToStringQuery, formatDate } from "src/app/helpers";
import { RootService, SurveyService } from "src/app/services";
import { track } from 'src/app/helpers/track';
@Component({
  selector: "app-survey-histories",
  templateUrl: "./survey-histories.component.html",
  styleUrls: ["./survey-histories.component.scss"],
})
export class SurveyHistoriesComponent implements OnInit {
  currentTimeType: string = "DAY";
  listSurveyGroup: Array<any> = [
    {
      value: 1,
      label: "Nhóm 1",
    },
    {
      value: 2,
      label: "Nhóm 2",
    },
  ];
  search: any = {
    surveyName: "",
    customerName: "",
    phone: "",
    groupId: undefined,
    provinceId: undefined,
    districtId: undefined,
    createdTime: [],
  };
  total: number = 0;
  loading: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  isIndeterminate: boolean = false;
  isAllDisplayDataChecked: boolean = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  listCheckedItem: any = [];
  ids: any[] = [];
  isShow: Boolean;
  listProvinces: Array<any> = [];
  listDistricts: Array<any> = [];
  listSurveyHistories: Array<any> = [];
  unsubscribe$ = new Subject<void>();
  constructor(
    private surveyService: SurveyService,
    private notification: NzNotificationService,
    private rootService: RootService
  ) {}

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_survey_histories',
    //   contentType: 'interaction',
    // })
    this.onChangeTime("MONTH");
    this.getListSurveyGroups();
    this.getListCities();
  }

  cities = [];
  getListCities() {
    let query = {
      type: 1,
      parentId: 0,
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.cities = res.data;
      },
      (error) => {
        this.cities = [];
      }
    );
  }

  districts = [];
  getListDistricts(id) {
    let query = {
      type: 2,
      parentId: id,
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.districts = res.data;
      },
      (error) => {
        this.districts = [];
      }
    );
  }

  selectedCity(event) {
    this.page = 1;
    this.getListSurveyHistory();
    this.getListDistricts(event);
  }

  listSurveyHistory = [];
  getListSurveyHistory() {


    let query = {
      customerName:
        this.search.customerName && this.search.customerName.trim() != ""
          ? this.search.customerName.trim()
          : "",
      surveyName:
        this.search.surveyName && this.search.surveyName.trim() != ""
          ? this.search.surveyName.trim()
          : "",
      phone:
        this.search.phone && this.search.phone.trim() != ""
          ? this.search.phone.trim()
          : "",
      groupId: this.search.groupId || "",
      provinceId: this.search.provinceId || "",
      districtId: this.search.districtId || "",
      from: "",
      to: "",
      page: this.page,
      pageSize: this.pageSize,
    };


    if (this.search.createdTime && this.search.createdTime.length) {
      let _from = this.search.createdTime[0].split("/");
      let _to = this.search.createdTime[1].split("/");
      query.from = [_from[1], _from[0], _from[2]].join("/");
      query.to = [_to[1], _to[0], _to[2]].join("/");
    }

    this.loading = true;

    // track('siteSearch', {
    //   keywords: "portal_survey_histories",
    //   params: JSON.stringify(query)
    // })

    this.surveyService
      .getListSurveyHistory(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listSurveyHistory = res && res.data ? [...res.data] : [];
            this.total = res.totalElement;
          } else {
            // track('error', {
            //   apiCall: `${window.location.origin}/survey/history`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500
            // })
            this.notification.warning(
              "Có lỗi xảy ra",
              res && res.message ? res.message : "Vui lòng thử lại"
            );
          }
          this.loading = false;
        },
        (err: any) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/survey/history`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.notification.error(
            "Có lỗi xảy ra",
            err && err.message ? err.message : "Vui lòng thử lại"
          );
          this.loading = false;
        }
      );
  }

  onChangeTime(timeType: string, startDay?: any, endDay?: any) {
    this.currentTimeType = timeType;
    this.createRangeDate(timeType, startDay, endDay);
    this.getListSurveyHistory();
  }

  onChangeFilterTime(e: any) {
    let timeType = "OTHER_WEEK";
    if (e && e.length) {
      this.search.createdTime[0] = new Date(e[0]).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      this.search.createdTime[1] = new Date(e[1]).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      if (this.search.createdTime[0] == this.search.createdTime[1]) {
        timeType = "OTHER_DAY";
      }
      this.onChangeTime(
        timeType,
        this.search.createdTime[0],
        this.search.createdTime[1]
      );
    } else {
      this.getListSurveyHistory();
    }
  }

  createRangeDate(timeType: string, startDay?: any, endDay?: any) {
    if (timeType === "DAY") {
      let day = formatDate(Date.now()).split(" ")[1].split("/");
      this.search.createdTime[0] = [day[1], day[0], day[2]].join("/");
      this.search.createdTime[1] = [day[1], day[0], day[2]].join("/");
      this.search.createdTime = [...this.search.createdTime];
    }
    if (timeType === "OTHER_WEEK") {
      this.search.createdTime[0] = [
        startDay.split("/")[0],
        startDay.split("/")[1],
        startDay.split("/")[2],
      ].join("/");
      this.search.createdTime[1] = [
        endDay.split("/")[0],
        endDay.split("/")[1],
        endDay.split("/")[2],
      ].join("/");
      this.search.createdTime = [...this.search.createdTime];
    }
    if (timeType === "WEEK") {
      let startDayInWeek =
        new Date().getDate() -
        new Date().getDay() +
        (new Date().getDay() === 0 ? -6 : 1);
      let startTime = formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeek)).setHours(0, 0, 0, 1)
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      let endTime = formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeek + 6)).setHours(
            23,
            59,
            59,
            999
          )
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      this.search.createdTime[0] = [
        startTime[1],
        startTime[0],
        startTime[2],
      ].join("/");
      this.search.createdTime[1] = [endTime[1], endTime[0], endTime[2]].join(
        "/"
      );
      this.search.createdTime = [...this.search.createdTime];
    }
    if (timeType === "MONTH") {
      let startTime = formatDate(
        new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
            0,
            0,
            0,
            1
          )
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      let endTime = formatDate(
        new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ).setHours(23, 59, 59, 999)
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      this.search.createdTime[0] = [
        startTime[1],
        startTime[0],
        startTime[2],
      ].join("/");
      this.search.createdTime[1] = [endTime[1], endTime[0], endTime[2]].join(
        "/"
      );
      this.search.createdTime = [...this.search.createdTime];
    }
  }

  getListSurveyGroups() {
    let query = {
      parentId: "",
      page: 1,
      pageSize: 10,
    };
    this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listSurveyGroup = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
        }
      });
  }

  resetSearch() {
    this.search.surveyName = "";
    this.search.customerName = "";
    this.search.phone = "";
    this.search.groupId = undefined;
    this.search.provinceId = undefined;
    this.search.districtId = undefined;
    this.onChangeTime('MONTH');
    // this.search.createdTime = [];
    this.getListSurveyHistory();
  }

  onExpandChange(e: NzFormatEmitEvent): void {
    const node = e.node;
    if (
      node &&
      node.getChildren().length === 0 &&
      node.isExpanded &&
      node.origin &&
      node.origin.id
    ) {
      this.loadChildSurveyGroup(node.origin.id).subscribe((res: any) => {
        if (res.code === 1) {
          let childData = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
          if (childData && childData.length) {
            node.addChildren(childData);
          } else {
            node.isLeaf = true;
          }
        }
      });
    }
  }

  loadChildSurveyGroup(parentId: string): Observable<NzTreeNodeOptions[]> {
    let query = {
      parentId,
      page: 1,
      pageSize: 10,
    };
    return this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$));
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.id] = value)
    );
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some((item) => this.mapOfCheckedId[item.id]) &&
      !this.isAllDisplayDataChecked;

    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
        let itemChecked = this.listOfDisplayData.find(
          (gg: any) => gg.id === key
        );
        if (
          itemChecked &&
          this.listCheckedItem.findIndex(
            (kk: any) => kk.id === itemChecked.id
          ) === -1
        ) {
          this.listCheckedItem.push(itemChecked);
        }
      } else {
        let indexRemoved = this.listCheckedItem.findIndex(
          (kk: any) => kk.id === key
        );
        if (indexRemoved > -1) {
          this.listCheckedItem.splice(indexRemoved, 1);
        }
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  // onShowModalFormSurveyGroup(type: string, listSurvey: any = null) {
  //   this.surveyGroupForm.showModal(type, listSurvey);
  // }

  // createSurveyStamp() {
  //   this.router.navigate(['/portal/stamp/null'], { queryParams: { type: 4 }})
  // }

  onReloadData(e: any) {
    if (e) {
      this.mapOfCheckedId = {};
      this.listCheckedItem = [];
      this.ids = [];
      this.getListSurveyHistory();
    }
  }

  onSearchFilter() {
    this.page = 1;
    this.getListSurveyHistory();
  }
}
