import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsDocumentsService extends BaseService {

  getAll(query?: any): Observable<any> {
    return this.get<any>('/product/document', { params: query });
  }

  create(product_id: string, body: any): Observable<any> {
    return this.post<any>(`/product/document?product_id=${product_id}`, body);
  }

  destroy(productDocument_id: any): Observable<any> {
    return this.delete<any>(`/product/document/${productDocument_id}`);
  }

  assign(body: any): Observable<any> {
    return this.post<any>(`/product/document/multi`, body);
  }

  hide_show(productDocument_id?: any, body?: any) {
    return this.put<any>(`/product/document/${productDocument_id}/show_hide`);
  }
  // tslint:disable-next-line:variable-name
  detail(productDocument_id?: any) {
    return this.get<any>(`/product/document/${productDocument_id}`);
  }
  update(productDocument_id?: any, body?: any) {
    return this.put<any>(`/product/document/${productDocument_id}`, body);
  }
}
