import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { AccountInfoService } from 'src/app/services/business/account-info/account-info.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  isVisible = false;
  message = '';
  form = this.fb.group({
    password: ['', [Validators.required]],
    newpass: ['', [Validators.required]],
    config_newpass: ['', [Validators.required]],
  });
  constructor(
    private fb: FormBuilder,
    private accountInfoService: AccountInfoService,
    private notification: NzNotificationService,
  ) { }

  ngOnInit() {
    this.message = '';
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }
  update() {
    this.message = '';
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    const oldPassword$ = this.form.get('password').value ? this.form.get('password').value.trim() : '';
    const newPassword$ = this.form.get('newpass').value ? this.form.get('newpass').value.trim() : '';
    const config$ = this.form.get('config_newpass').value ? this.form.get('config_newpass').value.trim() : '';
    if (newPassword$ === config$) {
      const body = {
        currentPassword: oldPassword$,
        password: newPassword$,
        confirmPassword: config$
      };
      this.accountInfoService.changePassword(body).subscribe(
        res => {
          if (res.code === 1) {
            this.notification.create(
              'success',
              'Thành công',
              'Thay đổi mật khẩu thành công'
            );
            this.isVisible = false;
            return;
          }
          this.notification.create(
            'error',
            'Thay đổi mật khẩu thất bại',
            res.message
          );
        },
          error => {
            this.notification.create(
              'error',
              'Thay đổi mật khẩu thất bại',
              'Có lỗi xảy ra'
            );
        }
      );
    } else {
      this.message = 'Mật khẩu xác nhận phải giống mật khẩu mới';
    }
  }
  show() {
    this.message = '';
    this.form.reset();
    this.isVisible = true;
  }
  hide() {
    this.isVisible = false;
  }
}
