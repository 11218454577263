import { Injectable } from '@angular/core';
import {BaseService} from '../../../../base.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsAnalyticsService extends BaseService  {

  listAnalytics(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/product/stats', httpOptions);
  }
}
