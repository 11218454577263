import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../auth';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(
            (mod) => mod.DashboardModule
          ),
      },
      {
        path: 'products',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./products/products.module').then(
            (mod) => mod.ProductsModule
          ),
      },
      {
        path: 'info',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./business-info/business-info.module').then(
            (mod) => mod.BusinessInfoModule
          ),
      },
      {
        path: 'companies',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./companies/companies.module').then(
            (mod) => mod.CompaniesModule
          ),
      },
      {
        path: 'stamp',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./stamp/stamp.module').then(
            (mod) => mod.StampModule
          ),
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./user/user.module').then(
            (mod) => mod.UserModule
          ),
      },
      {
        path: 'scan-history',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./scan-history/scan-history.module').then(
            (mod) => mod.ScanHistoryModule
          ),
      },
      {
        path: 'form-setting',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./form-setting/form-setting.module').then(
            (mod) => mod.FormSettingModule
          ),
      },
      {
        path: 'loyalty-setting',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./loyalty-setting/loyalty-setting.module').then(
            (mod) => mod.LoyaltySettingModule
          ),
      },
      {
        path: 'survey',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./survey/survey.module').then(
            (mod) => mod.SurveyModule
          ),
      },
      {
        path: 'package-history',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./package-history/package-history.module').then(
            (mod) => mod.PackageHistoryModule
          ),
      },
      {
        path: 'checkin-history',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./checkin-history/checkin-history.module').then(
            (mod) => mod.CheckinHistoryModule
          ),
      },
      {
        path: 'loyalty',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./loyalty/loyalty.module').then(
            (mod) => mod.LoyaltyModule
          ),
      },
      {
        path: 'landings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
      }
      // {
      //   path: "order-food",
      //   loadChildren: () =>
      //     import("./order-food/order-food.module").then(
      //       (mod) => mod.OrderFoodModule
      //     ),
      // },
    ]),
  ],
  exports: [RouterModule],
})
export class BusinessRoutingModule {}
