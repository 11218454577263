import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  ProductsStoresService,
  RootService,
  ProductsService,
} from "src/app/services";
import { removeEmptyQuery, sleep } from "src/app/helpers";

@Component({
  selector: "app-products-stores",
  templateUrl: "./products-stores.component.html",
  styleUrls: ["./products-stores.component.scss"],
})
export class ProductsStoresComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  page = 1;
  pageSize = 10;
  text: string = "productStores";

  constructor(
    private shopService: ProductsStoresService,
    private rootService: RootService,
    private notification: NzNotificationService,
    private productService: ProductsService
  ) {}

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  //================================list==================================//
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading: Boolean = true;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: Boolean;

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some((item) => this.mapOfCheckedId[item.id]) &&
      !this.isAllDisplayDataChecked;
    this.ids = [];
    for (let [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.id] = value)
    );
    this.refreshStatus();
  }

  productId: string;
  managementStatus: number;
  roleCode: string;
  ngOnInit() {
    this.productId = window.location.href.split("/")[5];
    this.productService
      .getById(this.productId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.managementStatus = res.data.managementStatus;
            this.roleCode = res.data.roleCode;
            let body: any = {
              managementStatus: this.managementStatus,
              roleCode: this.roleCode,
            };
            this.productService.getManagementStatus(body);
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
        }
      );
    this.notification.config({
      nzPlacement: "bottomRight",
    });
    // this.loadCities();
    this.loadShopType();
    // this.loadData();

    this.shopService.currentActive
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        if (status) {
          this.loadData(500);
          return;
        } else {
          this.loadData();
        }
      });
  }

  //============================search=============================//
  keyword: string;
  province_id: number;
  type_id: number;
  verify: number;
  status: number;
  cities: any[] = [];
  shopTypes: any[] = [];

  delayTimer: any;
  search() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadData();
    }, 300);
  }

  filterCities($event: number) {
    this.province_id = $event;
    this.page = 1;
    this.loadData();
  }

  loadCities() {
    this.rootService
      .listCities()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.cities = res.data.items;
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  filterShopType($event: number) {
    this.type_id = $event;
    this.page = 1;
    this.loadData();
  }

  loadShopType() {
    this.shopService
      .getAllType()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.shopTypes = res.data.items;
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  filterVerify($event: number) {
    this.verify = $event;
    this.page = 1;
    this.loadData();
  }

  verifies = [
    { id: 1, name: "Xác thực" },
    { id: 2, name: "Hủy xác thực" },
  ];

  filterStatus($event: number) {
    this.status = $event;
    this.page = 1;
    this.loadData();
  }

  statuses = [
    { id: 0, name: "Yêu cầu chặn" },
    { id: 1, name: "Cho phép bán" },
    { id: 3, name: "Đã chặn" },
  ];

  storesId: string;

  async loadData(ms = 0) {
    const query: any = {
      product_id: this.productId,
      shop_name: this.keyword ? this.keyword.trim() : null,
      province_id: this.province_id,
      shop_type: this.type_id,
      verify: this.verify,
      status: this.status,
      page: this.page,
      page_size: this.pageSize,
    };

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.shopService.getAll(query).subscribe(
      (res) => {
        this.loading = false;
        this.total = res.data.totalElement;
        this.listOfAllData = res.data.items;
        if (!res.data.items && this.page > 1) {
          this.page = this.page - 1;
          this.loadData();
          return;
        }
      },
      (err) => {
        this.openMessage("error", "Lỗi !!!", `${err}`);
        return;
      }
    );
  }

  /*Check link image error*/
  imageFalse: any[] = [];
  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, "_blank");
    }
  }

  resetData() {
    this.keyword = null;
    this.province_id = null;
    this.type_id = null;
    this.verify = null;
    this.status = null;
    this.page = 1;
    this.loadData();
  }

  //==============================output====================================//
  update(body: any) {
    this.shopService
      .update(this.productId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.updateStores.success();
          if (res.code === 1) {
            this.openMessage(
              "success",
              "Thành công",
              "Cập nhật điểm bán thành công"
            );
            this.updateStores.handleCancel();
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.updateStores.success();
          return;
        }
      );
  }

  dataDelete = {
    label: "Xác nhận xóa điểm bán",
    value: "<p>Bạn có chắc chắn muốn xoá điểm bán?</p>",
  };
  delete(id: any) {
    const body = {
      body: {
        productShopIds: id,
      },
    };
    this.shopService
      .destroy(this.productId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.deleteStores.success();
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              "success",
              "Thành công",
              "Xóa điểm bán thành công"
            );
            this.deleteStores.handleCancel();
            this.loadData(500);
          }
          if (res.code === 103 || res.code === 101) {
            this.openMessage(
              "warning",
              "Cảnh báo",
              `Doanh nghiệp không có quyền thực hiện tính năng này.`
            );
            return;
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.deleteStores.success();
        }
      );
  }

  dataVerify = {
    label: "Bỏ xác thực điểm bán",
    value: "<p>Bạn có chắc chắn muốn bỏ xác thực điểm bán?</p>",
  };
  str: string = "title";
  str2: string = "productStores";
  verify2(data: any) {
    let body: any = {
      titleId: data.type_id,
      isVerify: data.isVerify,
      productShopIds: this.ids,
    };
    this.shopService
      .verify(this.productId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.verifyStores.success();
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              "success",
              "Thành công",
              "Xác thực điểm bán thành công"
            );
            this.verifyStores.handleCancel();
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.verifyStores.success();
        }
      );
  }

  unVerify(id: any) {
    let body: any = {
      isVerify: false,
      productShopIds: id,
    };
    this.shopService
      .unverify(this.productId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              "success",
              "Thành công",
              "Bỏ xác thực điểm bán thành công"
            );
            this.unVerifyStores.handleCancel();
            this.loadData(500);
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
          this.unVerifyStores.success();
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.unVerifyStores.success();
          return;
        }
      );
  }

  dataBlock = {
    label: "Bỏ chặn điểm bán",
    value: "<p>Bạn có chắc chắn muốn bỏ chặn điểm bán?</p>",
  };
  block(data: any) {
    let body: any = {
      note: data.note,
      images: data.proof_images,
      reason_id: data.reason_id,
      productShopIds: this.ids,
    };
    this.shopService
      .block(this.productId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.blockStores.success();
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              "success",
              "Thành công",
              "Chặn điểm bán thành công"
            );
            this.blockStores.handleCancel();
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.blockStores.success();
          return;
        }
      );
  }

  unBlock(id: any) {
    let body: any = {
      reason_id: -1,
      productShopIds: id,
    };
    this.shopService
      .unblock(this.productId, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              "success",
              "Thành công",
              "Bỏ chặn điểm bán thành công"
            );
            this.unBlockStores.handleCancel();
            this.loadData(500);
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
          this.unBlockStores.success();
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.unBlockStores.success();
        }
      );
  }

  exportExcel() {
    const query: any = {
      export_all: this.ids.length === 0,
      product_id: this.productId,
      shop_name: this.keyword ? this.keyword.trim() : null,
      province_id: this.province_id,
      shop_type: this.type_id,
      verify: this.verify,
      status: this.status,
      ids: this.ids ? this.ids.toString() : null,
    };

    removeEmptyQuery(query);

    this.shopService
      .exportExcel(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.rootService.export(data, "Danh sách điểm bán.xlsx");
      });
  }

  //===============================actions=================================//
  @ViewChild("showForm", { static: true }) showForm;
  @ViewChild("updateStores", { static: true }) updateStores;
  @ViewChild("deleteStores", { static: true }) deleteStores;
  @ViewChild("verifyStores", { static: true }) verifyStores;
  @ViewChild("unVerifyStores", { static: true }) unVerifyStores;
  @ViewChild("blockStores", { static: true }) blockStores;
  @ViewChild("unBlockStores", { static: true }) unBlockStores;

  action: any;
  actionsButton() {
    if (this.action == 1) {
      this.showForm.showModal("createShopProduct");
    } else if (this.action == 3) {
      this.verifyStores.showModal("verifyProductStore");
    } else if (this.action == 4) {
      this.deleteStores.showModal(this.dataVerify, this.ids);
    } else if (this.action == 5) {
      this.blockStores.showModal();
    } else if (this.action == 6) {
      this.deleteStores.showModal(this.dataBlock, this.ids);
    } else if (this.action == 7) {
      this.deleteStores.showModal(this.dataDelete, this.ids);
    } else if (this.action == 8) {
      this.exportExcel();
    }
    this.action = null;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
