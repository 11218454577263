import {Injectable} from '@angular/core';
import {BaseService} from 'src/app/services/base.service';

@Injectable({providedIn: 'root'})
export class DocumentsService extends BaseService {
  list(query?: any) {
    return this.get<any>('/documents', { params: query });
  }

  listIds(body?: any) {
    return this.post<any>('/documents/info', body);
  }

  create(body?: any) {
    return this.post<any>('/documents', body);
  }
  getDocumentById(id?: any) {
    return this.get<any>(`/documents/info?document_id=${id}`);
  }
  updateDocument(id?: any, body?: any) {
    return this.put<any>(`/documents?document_id=${id}`, body);
  }
  remove(body?: any) {
    return this.delete<any>('/documents', body);
  }
}
