import { Component, OnInit } from '@angular/core';
import {UploaderComponent} from "../../uploader";
import {takeUntil} from "rxjs/operators";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
@Component({
  selector: 'app-info-user',
  templateUrl: './info-user.component.html',
  styleUrls: ['./info-user.component.scss']
})
export class InfoUserComponent implements OnInit {
  change = false;
  changePass = false;
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    phone: [{value: '', disabled: true}, [Validators.required]],
    email: ['', [Validators.required]],
    avatar: this.fb.control([]),
    password: ['', Validators.required],
    confirm_pass: ['', [Validators.required]],
  });
  images: any[] = [];
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
  }
  showModal() {
    this.change = true;
  }
  update() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    this.change = false;
  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this[field].push(data);
          this.form.get('avatar').value.push(changeParam.file.response.url);
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(changeParam => {
        modal.close();
      });
    });
  }

  onRemoveFileAll(field: string) {
    if (field === 'images') {
      this.images = [];
      this.form.get('avatar').setValue(null);
    }
  }

  onRemoveFile(field: string, index: number) {
    this[field].splice(index, 1);
    this.form.get(field).value.splice(index, 1);
  }


  setImage(listImage) {
    if (listImage !== null && listImage.length > 0) {
      listImage.forEach(file => {
        const data = {
          type: file.type,
          name: file.name,
          url: file.url,
        };

        if (this.images.length === 0) {
          this.images.push(data);
        }

        if (this.images.length !== 0 && !this.images.includes(data)) {
          this.images.push(data);

        }

        if (this.form.get('images').value === null) {
          this.form.get('avatar').setValue([file.path]);

        }

        if (this.form.get('avatar').value !== null && !this.form.get('avatar').value.includes(file.path)) {
          this.form.get('avatar').value.push(file.path);

        }
      });
    }
  }
}

