import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { track } from 'src/app/helpers/track';
import {StampV2Service} from "../../../../../services";
@Component({
  selector: 'app-enter-code-campaign',
  templateUrl: './enter-code-campaign.component.html',
  styleUrls: ['./enter-code-campaign.component.scss']
})
export class EnterCodeCampaignComponent implements OnInit {

  currentStamp;
  campaignCode: any;
  giftCode: any;

  constructor(private router: Router,
    private stampService: StampService,
    private notification: NzNotificationService, private stampV2Service: StampV2Service) { }

  ngOnInit() {
    const id = window.location.href.split("/")[5];
    if (id !== "null") {
      this.stampService
        .getStampById(id)
        .subscribe(
          (res) => {
            if (res.code === 1 && res.data) {
              this.currentStamp = res.data;
              if (res.data.loyaltyCampaign && res.data.loyaltyCampaign.type === 2) {
                if (res.data.loyaltyCampaign.code) {
                  this.campaignCode = res.data.loyaltyCampaign.code
                }

                if (res.data.loyaltyCampaign.giftCode) {
                  this.giftCode = res.data.loyaltyCampaign.giftCode
                }
              }
            }
          }
        )
    }

  }

  back() {
    this.router.navigate(["/portal/stamp"]);
  }

  submit() {
    const body = {
      ...this.currentStamp,
      data: this.currentStamp.stampData || {},
      loyaltyCampaignType: 2,
      loyaltyCampaignObject: this.campaignCode ? this.campaignCode : '',
      loyaltyGiftCode: this.giftCode ? this.giftCode.trim() : ''
    };



    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_stamp_loyalty_setting',
    //   contentName: 'confirm_setting',
    //   payload: JSON.stringify(body),
    //   target: `${window.location.origin}/portal/stamp`,
    // })
    this.stampService
        .update2(this.currentStamp.id, body)
        .subscribe((res) => {
          if (res.code === 1) {
            this.openMessage("success", "Thành công", "Cập nhật thành công");
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(["/portal/stamp"]);
              return;
            }, 500);
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
        }),
        (err) => {
          this.openMessage("err", "Lỗi !!!", `${err}`);
        };
  }



  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

}
