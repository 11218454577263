import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StampV2Service, SurveyService, UploadService} from 'src/app/services';
import {StampService} from 'src/app/services/business/stamp/stamp.service';
import {UploaderComponent} from 'src/app/shared';
import * as _ from 'lodash';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {environment} from '../../../../../environments/environment';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';

@Component({
  selector: 'app-assign-survey',
  templateUrl: './assign-survey.component.html',
  styleUrls: ['./assign-survey.component.scss']
})
export class AssignSurveyComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private stampService: StampService,
    private router: Router,
    private surveyService: SurveyService,
    private modalService: NzModalService,
    private stampV2Service: StampV2Service,
    private uploadService: UploadService,
  ) {
    // @ts-ignore
  }

    @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;
  qrcodeConfig = {
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 1000,
    height: 1000,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  };
  unsubscribe$ = new Subject<void>();
  qrcodeConfigPreview = null;
  form = this.fb.group({
    name: ['', [Validators.required]],
    targetId: [null, [Validators.required]],
    packageId: [null, [Validators.required]],
    domainId: [null],
    relatedTargetType: [null],
    loyaltyCampaignType: [''],
    loyaltyCampaignObject: [''],
    loyaltyGiftCode: [''],
    config: new FormGroup({
      logo: new FormControl(''),
      shape: new FormControl('classy'),
      color: new FormControl('#000000'),
      backgroundColor: new FormControl('#ffffff'),
    }),
    setting: new FormGroup({
      requiredCustomerInfo: new FormControl(false),
      allowedScan: new FormControl(true),
      customerInfoForm: new FormControl(false)
    }),
  });
  isValidFormSubmitted = null;
  settingActiveCollapse = {
    showHideInfo: true,
    showOther: true,
    showBusiness: true,
    showShape: true,
    showColor: true,
    showLogo: true,
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',
    },
  };

  listBtnColors: Array<string> = [
    '#000000',
    '#EF6D46',
    '#8FC743',
    '#F390BC',
    '#83D1D5',
    '#6CB5E1',
    '#415CA0',
    '#7E62A1',
  ];
  listEcommerceImage: Array<any> = [[]];
  listTargetProduct: any = [];
  // listPackage = [];
  @Input() listPackage = [];
  @Input() isChangeStampTypeQRCode = false;
  @ViewChild('addProductRelated', {static: false}) addProductRelated;
  @ViewChild('deleteProductRelateds', {static: false}) deleteProductRelateds;

  heightEl = 500;
  listBusinessProduct = [];
  hiddenProductDetail = false;

  public config = {
    placeholder: '',
    tabsize: 2,
    disableDragAndDrop: true,
    height: '150px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      // ["insert", ["link", "video"]],
      ['insert', ['link']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  controls: any = this.form.controls;

  listYear = [
    {value: 1, label: '1 năm'},
    {value: 2, label: '2 năm'},
    {value: 3, label: '3 năm'},
  ];

  listTypeEcommerce = [
    {
      value: 1,
      label: 'Tiki',
    },
    {
      value: 2,
      label: 'Shopee',
    },
    {
      value: 4,
      label: 'Sendo',
    },
    {
      value: 3,
      label: 'Lazada',
    },
    {
      value: 0,
      label: 'Khác',
    },
  ];

  listSetting = [
    {
      value: 1,
      label: 'Yêu cầu thông tin khách hàng',
      key: 'requiredCustomerInfo',
    },
    {value: 2, label: 'Cho App khác quét', key: 'allowedScan'},
    {value: 3, label: 'Ẩn chi tiết sản phẩm', key: 'hideProduct'},
    {value: 4, label: 'Ẩn DN phân phối', key: 'hideDistributor'},
    {value: 5, label: 'Ẩn DN sở hữu', key: 'hideOwner'},
    {value: 6, label: 'Ẩn điểm bán', key: 'hideShop'},
    {value: 7, label: 'Ẩn sản phẩm liên quan', key: 'hideRelatedProducts'},
    {value: 8, label: 'Ẩn thông báo', key: 'hideNotification'},
    {value: 9, label: 'Ẩn giá', key: 'hidePrice'},
    {value: 10, label: 'Ẩn giới thiệu sản phẩm', key: 'hideProductIntro'},
  ];

  listStamp = [];
  totalPage = 0;
  page = 1;
  pageSize = 3;
  currentPage;
  firstTempale: any;

  id;
  templateId;

  isUpdate = false;
  errorExpiredAt = false;

  indeterminateDistributor = false;

  listRelatedProduct = [];

  firstTimeLoadTemplate = false;

  templateData: any = {};
  templateDataSubject: any;
  surveyId: any;

  currentPackage: any;

  pageCurrent = 1;
  currentSurvey: any;
  listPage = [];


  logos = [];

  listSurveys = [];


  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  isShow;
  hideOwner = false;
  hideShop = false;
  hideDistributor = false;


  listDomain = [];

  isLoadingBtn = false;
  clickSubmit = false;
  isNewQr: true;
  loadingDrawQrcode;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const mapElement: any = document.querySelector(
      '.img-template'
    ) as HTMLElement;
    this.heightEl =
      mapElement && mapElement.offsetWidth ? mapElement.offsetWidth * 2 : 500;
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_form_survey',
    //   contentType: 'qrcode',
    // })
    this.templateDataSubject = new BehaviorSubject(this.templateData);
    this.getListSurvey();
    this.getListDomainByUser();
    this.getListPackageActive();
    this.id = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      if (params.surveyId) {
        this.form.patchValue({
          targetId: params.surveyId
        });
        this.getDetailSurvey(params.surveyId);
      }
    });

    if (this.id !== 'null') {
      this.isUpdate = true;
      this.getStampV2Detail(this.id);
    } else {
      this.isNewQr = true;
    }

    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    // setTimeout(() => {
    //   this.getListTemplate();
    // }, 800);
    this.changeValueForm();
    // this.getListPackageActive();
  }

  getStampV2Detail(id) {
    this.stampService.getStampById(id).subscribe(async res => {
      if (res.code === 1 && res.data) {
        // const data = res.data;
        const data = {
          ...res.data,
          newQr: true
        };
        this.isNewQr = data.newQr;
        this.form.patchValue({
          name: data.name,
          targetId: !this.isChangeStampTypeQRCode ? data.targetId : null,
          setting: data.setting,
          domainId: data.domainId,
        });
        if (data.packageInfo && data.packageInfo.id) {
          this.listPackage = [{...data.packageInfo}];
          this.form.patchValue({
            packageId: data.packageInfo.id
          });
        }
        if (data.targetId) {
          this.getDetailSurvey(data.targetId);
        }

        if (data.loyaltyCampaign) {
          this.form.patchValue({
            loyaltyCampaignType: data.loyaltyCampaign.type,
            loyaltyCampaignObject: data.loyaltyCampaign.code,
            loyaltyGiftCode: data.loyaltyCampaign.giftCode,
          });
        }
        if (data.newQr) {
          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType, data.config, data.frameConfig, data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(data.qrCode);
        } else {
          if (data.config && data.config.color) {
            this.form.get('config.color').setValue(data.config.color);
          }
          if (data.config && data.config.backgroundColor) {
            this.form
              .get('config.backgroundColor')
              .setValue(data.config.backgroundColor);
          }
          if (data.config && data.config.logo) {
            this.form.get('config.logo').setValue(data.config.logo);
            this.logos = [{url: data.config.logo}];
          }
          if (data.config && data.config.shape) {
            this.form.get('config.shape').setValue(data.config.shape);
          }
        }
      }
    });
  }

  changeValueForm() {
    this.form.get('config').valueChanges.subscribe((val: any) => {
      this.templateData = {
        ...this.form.value,
        config: {...this.form.get('config').value},
      };
      this.templateDataSubject.next(this.templateData);
    });
  }


  getListPackageActive() {
    // this.stampService.getListPackageActive({type: 1}).subscribe(res => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }

  selectedPackage(event) {
    if (event) {
      this.currentPackage = this.listPackage.find(item => item.id === event);
    }
  }

  getDetailSurvey(id) {
    this.surveyService.detail(id).pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.currentSurvey = res.data;
          this.listPage = new Array(res.data.surveyContent.content.length);
        }
      );
  }

  onRemoveFile(field: string) {
    this.logos = [];
    this.form.get('config.logo').setValue('');
  }

  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp,mp4,pdf,docx';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logos') {
            this.logos = [{url: changeParam.file.response.url}];
            this.form
              .get('config.logo')
              .setValue(this.logos[0].url);
            this.form.updateValueAndValidity();

          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  onSelectColor(color: string) {
    if (this.form.value.config.color !== color) {
      this.form.get('config.color').setValue(color);
    } else {
      this.form.get('config.color').setValue('');
    }

  }

  onSelectbackgroundColor(color: string) {
    if (this.form.value.config.backgroundColor !== color) {
      this.form.get('config.backgroundColor').setValue(color);
    } else {
      this.form.get('config.backgroundColor').setValue('');
    }
    // this.form.get("config.backgroundColor").setValue(color);
  }

  getListSurvey() {

    const query = {
      page: 1,
      pageSize: 200,
      type: 'BASIC'
    };

    this.surveyService
      .getListSurveys(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listSurveys = res && res.data ? [...res.data] : [];
          } else {
            this.notification.warning(
              'Có lỗi xảy ra',
              res && res.message ? res.message : 'Vui lòng thử lại'
            );
          }
        },
        (err: any) => {
          this.notification.error(
            'Có lỗi xảy ra',
            err && err.message ? err.message : 'Vui lòng thử lại'
          );
        }
      );
  }

  getDate(date, tmpNumber) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (tmpNumber === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute;
  }


  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  getListDomainByUser() {
    const query: any = {
      page: 1,
      pageSize: 100,
    };

    this.stampService
      .getListDomain(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  selectedQrCode(value) {
    if (this.form.get('config.shape').value !== value) {
      this.form.get('config.shape').setValue(value);
    } else {
      this.form.get('config.shape').setValue('');
    }
  }

  async submit() {
    this.isValidFormSubmitted = false;
    this.clickSubmit = true;
    // const recursive = (f: FormGroup | FormArray) => {
    //   for (const i in f.controls) {
    //     if (typeof f.controls[i].value === "string") {
    //       if (f.controls[i].value.trim().length === 0) {
    //         f.controls[i].value = null;
    //       }
    //     }
    //     if (f.controls[i] instanceof FormControl) {
    //       f.controls[i].markAsDirty();
    //       f.controls[i].updateValueAndValidity();
    //     } else {
    //       recursive(f.controls[i] as any);
    //     }
    //   }
    // };

    // recursive(this.form);
    if (this.form.invalid) {
      this.templateQRCodeViewRef.onChangeTabActive();
      return;
    }
    this.isValidFormSubmitted = true;
    this.isLoadingBtn = true;

    const newForm = {
      ...this.form.value,
      type: 4,
      expiredAt: this.form.value.expiredAt ? this.getDate(this.form.value.expiredAt, 1) : this.currentPackage.expiredAt,
    };
    if (this.isNewQr) {
      const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
      newForm.config = {...this.qrcodeDesignComponentRef.getQrcodeDesignConfig()};
      newForm.qrcodeFrameId = this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
      const formData = new FormData();
      formData.append('file', base64QrcodeSvg);
      const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
      if (qrImageResponse.status === 200) {
        newForm.qrImage = qrImageResponse.data || '';
      }
    }
    if (this.isUpdate) {
      if (this.isNewQr) {
        this.stampService
          .update(this.id, newForm)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            if (res.code === 1) {
              // track("alert", {
              //   alertType: "success",
              //   alertMessage: "Thêm mới QR Code thành công"
              // });
              this.openMessage('success', 'Thành công', 'Cập nhật thành công');
              let delayTimer: any;
              clearTimeout(delayTimer);
              delayTimer = setTimeout(() => {
                this.router.navigate(['/portal/stamp']);
                return;
              }, 500);
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
            this.isLoadingBtn = false;
          }, error => {
            this.isLoadingBtn = false;
            this.openMessage('error', 'Lỗi !!!', `${error}`);
          });
      } else {
        const qrcodeData = `${environment.redirectScanUrl}/${this.id}`;
        const tmpQrcodeConfig = {
          ...this.qrcodeConfig,
          backgroundOptions: {
            color: this.form.get('config.backgroundColor').value
          },
          dotsOptions: {
            type: this.form.get('config.shape').value,
            color: this.form.get('config.color').value,
          },
          image: this.form.get('config.logo').value,
          data: qrcodeData,
        };
        const qrcodeInstance = new QRCodeStyling(tmpQrcodeConfig as any);
        const rawQrcodeImage = await qrcodeInstance.getRawData();
        const formData = new FormData();
        formData.append('content', rawQrcodeImage, 'qrcode.png');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.uploadService.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (result) => {
            if (result.status === 200 && result.data && result.data.url) {
              newForm.qrImage = result.data.url;
              this.stampService
                .update(this.id, newForm)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  if (res.code === 1) {
                    this.openMessage('success', 'Thành công', 'Cập nhật thành công');
                    let delayTimer: any;
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(() => {
                      this.router.navigate(['/portal/stamp']);
                      return;
                    }, 500);
                  } else {
                    this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                  }
                  this.isLoadingBtn = false;
                }, error => {
                  this.isLoadingBtn = false;
                  this.openMessage('error', 'Lỗi !!!', `${error}`);
                });
            }
          },
          (err) => {
            this.openMessage('error', 'Cập nhật không thành công', `Vui lòng thử lại`);
          }
        );
      }
    } else {
      newForm.qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
      this.stampService
        .create(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track("alert", {
            //   alertType: "success",
            //   alertMessage: "Thêm mới QR Code thành công"
            // });
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/stamp']);
              return;
            }, 500);
          } else {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.isLoadingBtn = false;
        }, error => {
          this.isLoadingBtn = false;
          this.openMessage('error', 'Lỗi !!!', `${error}`);
        });
    }
  }

  back() {
    this.router.navigate(['/portal/stamp']);
  }

  onChangeInfoForm(e) {
    this.form.get('setting.requiredCustomerInfo').setValue(e);
  }

  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }

  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }

  ngOnDestroys() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
