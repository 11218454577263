import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { NgxBarcodeModule } from "ngx-barcode";
import { NgxSummernoteModule } from "node_modules/ngx-summernote";

import { ProductsRoutingModule } from "./products-routing.module";
import { SharedModule } from "src/app/shared";
import { ProductsListComponent } from "./products-list.component";
import { ProductsDetailComponent } from "./products-detail/products-detail.component";
import {
  ProductsAnalyticsComponent,
  ProductsDistributorsComponent,
  ProductsFormComponent,
  ProductsQuestionsComponent,
  ProductQuestionListComponent,
  ProductsReviewsComponent,
  ProductReviewHiddenComponent,
  ProductReviewListComponent,
  ProductsStoresComponent,
  ProductsDocumentsComponent,
  ProductDocumentAssignComponent,
  ProductsSettingComponent,
  SettingMessageComponent,
  SettingCreateMessageComponent,
  SettingRelatedComponent,
  SettingCreateRelatedComponent,
  AddProductDistributorsComponent,
  EditProductDistributorsComponent,
  ProductDocumentUpdateComponent,
} from "./products-detail";
import { ProductsAddComponent } from "./products-add/products-add.component";
import { ProductsUpdateComponent } from "./products-update/products-update.component";
import { ProductsDeleteComponent } from "./products-delete/products-delete.component";
import { ProductsImportComponent } from './products-import/products-import.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {NgxSummernoteCustomModule} from '../../lib/ngx-summernote-custom.module';
@NgModule({
  declarations: [
    ProductsDetailComponent,
    ProductsListComponent,
    ProductsAnalyticsComponent,
    ProductsDistributorsComponent,
    ProductsFormComponent,
    ProductsQuestionsComponent,
    ProductsReviewsComponent,
    ProductsStoresComponent,
    ProductsSettingComponent,
    ProductsAddComponent,
    SettingMessageComponent,
    SettingRelatedComponent,
    SettingCreateMessageComponent,
    SettingCreateRelatedComponent,
    ProductsDocumentsComponent,
    AddProductDistributorsComponent,
    EditProductDistributorsComponent,
    ProductDocumentAssignComponent,
    ProductReviewHiddenComponent,
    ProductReviewListComponent,
    ProductQuestionListComponent,
    ProductDocumentUpdateComponent,
    ProductsUpdateComponent,
    ProductsDeleteComponent,
    ProductsImportComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProductsRoutingModule,
        NgZorroAntdModule,
        SharedModule,
        NgxBarcodeModule,
        NgxSummernoteCustomModule,
        AngularEditorModule,
    ],
  entryComponents: [ProductsAddComponent],
  exports: [ProductsAddComponent, SettingCreateMessageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class ProductsModule {}
