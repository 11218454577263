import { Component, OnInit, isDevMode } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {AuthService} from '../../auth';

@Component({
  selector: 'app-redirect-free',
  templateUrl: './redirect-free.component.html',
  styleUrls: ['./redirect-free.component.scss']
})
export class RedirectFreeComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    const user = this.authService.currentUserValue;
    if (environment.isDev) {
      !!user ? this.onRedirectToPortalDashboard() : this.onRedirectToPortalLogin();
    } else {
      this.onRedirectToFree();
    }
  }

  onRedirectToFree() {
    const host = window.location.origin;
    const a = document.createElement('a');
    a.href = host + '/qrcode-free/';
    a.click();
  }

  onRedirectToPortalLogin() {
    this.router.navigate(['/auth/login']).then(r => {});
  }

  onRedirectToPortalDashboard() {
    this.router.navigate(['/portal/dashboard']).then(r => {});
  }
}
