import { Component, OnInit } from '@angular/core';
// import { environment } from '../environments/environment';
// import TekoMarket from 'teko-market/dist';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    // private renderer2: Renderer2
  ) {}

  ngOnInit() {
    // this.addTrackingId();
    // (window as any).track('hookMessage', (body) => {
    // });
    // this.initTekoMarket();
  }

  // addTrackingId() {
  //   const s = this.renderer2.createElement('script');
  //   s.type = 'text/javascript';
  //   s.text = `
  // tslint:disable-next-line:max-line-length
  //     !function(t,e,r,n,s,a,o,c){t[s]||(t.GlobalTrackerNamespace=t.GlobalTrackerNamespace||[],t.GlobalTrackerNamespace.push(s),t.GlobalTrackerNamespace.push("https://tracking.tekoapis.com"),t[s]=function(){(t[s].q=t[s].q||[]).push(arguments)},t[s].q=t[s].q||[],(o=e.createElement(r)).async=!0,o.src=n,(c=e.getElementsByTagName(r)[0]).parentNode.insertBefore(o,c),window.onerror=function(e,r,a,o,c){if(r===n)return t[s]("exception",{msg:e,error:c}),!1})}(window,document,"script","https://cdn.tekoapis.com/tracker/dist/v2/tracker.full.min.js","track");
  //     track("init", '${environment.tracking_id}' )
  //     track("enablePageView")
  //     track("enableLinkClick")
  //     track("enableTrackVisibleContentImpressions", true)
  //   `;
  //   document.getElementsByTagName('head')[0].appendChild(s);
  // }

  // async initTekoMarket() {
  //   const config = {
  //     clientId: environment.TEKO_MARKET_CLIENT_ID,
  //     redirectUri: `${environment.TEKO_MARKET_CALLBACK_URL}/qrcode-free/oauth-market`,
  //     oauthDomain: environment.TEKO_MARKET_OAUTH_DOMAIN,
  //     scopes: ['openid', 'profile']
  //   };
  //   try {
  //     await TekoMarket.init(config);
  //   } catch (e) {
  //   }
  // }
}
