import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreateUpdateCompanyProductsComponent } from './create-update/create-update.component';
import { CompaniesService, ProductsDistributorsService } from '../../../services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss'],
})
export class ListProductsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    private notification: NzNotificationService,
    private productsDistributorsService: ProductsDistributorsService
  ) {}
  @ViewChild('createUpdateCompanyProductsModal', { static: true })
  createUpdateCompanyProductsModal: CreateUpdateCompanyProductsComponent;
  @ViewChild('deleteProducts', { static: true })
  deleteProducts: any;
  @ViewChild('hideCompanies', { static: true })
  hideCompanies: any;
  @ViewChild('showCompanies', { static: true })
  showCompanies: any;
  dataShow = {
    label: 'Xác nhận hiện sản phẩm',
    value: '<p>Bạn có chắc chắn muốn hiện sản phẩm liên kết với doanh nghiệp này?</p>',
  };
  dataHide = {
    label: 'Xác nhận ẩn sản phẩm',
    value: '<p>Bạn có chắc chắn muốn ẩn sản phẩm liên kết với doanh nghiệp này?</p>',
  };
  unsubscribe$ = new Subject<void>();
  imageFalse: any[] = [];
  idCompany = '';
  total: any = 0;
  loading = false;
  page = 1;
  pageSize = 10;
  search: any = {
    query: '',
    role: undefined,
    title: undefined,
  };
  listRoles: any = [];
  listTitle: any = [];
  listProducts: any = [];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: Boolean;
  detailCompany: any = null;
  delayTimer: any;

  dataDelete = {
    label: 'Xác nhận xóa sản phẩm',
    value: '<p>Bạn có chắc chắn muốn xoá sản phẩm?</p>',
  };

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: any) => {
      this.idCompany = paramMap.params.idCompany;
    });
    if (this.idCompany) {
      this.getListProducts();
      this.getDetailCompany(this.idCompany);
    }
    this.getListRole();
  }

  getListProducts() {
    const query = {
      query: this.search.query ? this.search.query.trim() : '',
      role: this.search.role != undefined || this.search.role != null ? this.search.role : '',
      title: this.search.title != undefined || this.search.title != null ? this.search.title : '',
      page: this.page,
      pageSize: this.pageSize,
      businessId: this.idCompany,
    };
    this.loading = true;
    this.companiesService
      .getListProducts(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listProducts = [...res.data];
            this.total = res.totalElement;
          } else {
            this.notification.create(
              'error',
              'Có lỗi xảy ra',
              res.message || 'Lấy dữ liệu thất bại'
            );
          }
          this.loading = false;
        },
        (err: any) => {
          this.notification.create(
            'error',
            'Có lỗi xảy ra',
            err.message || 'Lấy dữ liệu thất bại'
          );
          this.loading = false;
        }
      );
  }

  getDetailCompany(id: any) {
    this.companiesService.getDetailCompanyById(id).subscribe(
      (res: any) => {
        if (res.code === 1) {
          this.detailCompany = { ...res.data };
        } else {
          this.notification.create(
            'error',
            'Có lỗi xảy ra',
            res.message || 'Lấy dữ liệu thất bại'
          );
        }
      },
      (err: any) => {
        this.notification.create(
          'error',
          'Có lỗi xảy ra',
          err.message || 'Lấy dữ liệu thất bại'
        );
      }
    );
  }

  getListRole() {
    this.companiesService.getListRole().subscribe((res: any) => {
      if (res.code === 1) {
        this.listRoles = [...res.data];
      }
    });
  }

  getListTitle(idRole: any) {
    const query = {
      roleId: idRole,
    };
    if (idRole) {
      this.companiesService.getListTitle(query).subscribe((res: any) => {
        if (res.code === 1) {
          this.listTitle = [...res.data];
        }
      });
    } else {
      this.listTitle = [];
      this.search.title = undefined;
    }
    this.search.title = undefined;
  }

  showModalAddProduct() {
    this.createUpdateCompanyProductsModal.openModal();
  }

  resetData() {
    this.search = {
      query: '',
      role: undefined,
      title: undefined,
    };
    this.getListProducts();
  }

  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, '_blank');
    }
  }

  delete(id: any) {
    const body = {
      body: {
        businessProductIds: id,
      },
    };
    this.companiesService.deleteProducts(body).subscribe(
      (res: any) => {
        if (res.code === 1) {
          // this.mapOfCheckedId = {};
          this.notification.create(
            'success',
            'Thành công',
            'Xóa sản phẩm thành công'
          );
          this.mapOfCheckedId = {};
          this.deleteProducts.handleCancel();
          this.getListProducts();
        }
        if (res.code !== 1) {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message || 'Có lỗi xảy ra'
          );
        }
        this.deleteProducts.success();
      },
      (err) => {
        this.notification.create(
          'error',
          'Lỗi !!!',
          err.message || 'Xóa sản phẩm thất bại'
        );
        this.deleteProducts.success();
        return;
      }
    );
  }

  changeHiddenStatus(id: any, status: number) {
    const body = {
      ids: id,
      status
    };
    const successMessage = status === 1 ? 'Hiện sản phẩm thành công' : 'Ẩn sản phẩm thành công';
    this.productsDistributorsService.updateStatus(body).subscribe(
      (res: any) => {
        if (res.code === 1) {
          // this.mapOfCheckedId = {};
          this.notification.create(
            'success',
            'Thành công',
            successMessage
          );
          this.mapOfCheckedId = {};
          this.showCompanies.handleCancel();
          this.hideCompanies.handleCancel();
          this.getListProducts();
        }
        if (res.code !== 1) {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message || 'Có lỗi xảy ra'
          );
        }
        this.showCompanies.success();
        this.hideCompanies.success();
      },
      (err) => {
        this.notification.create(
          'error',
          'Lỗi !!!',
          err.message || 'Có lỗi xảy ra, vui lòng thử lại'
        );
        this.showCompanies.success();
        this.hideCompanies.success();
        return;
      }
    );
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.businessProductId] = value)
    );
    this.refreshStatus();
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.businessProductId]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.businessProductId]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  editProduct(data: any) {
    this.createUpdateCompanyProductsModal.openModal('edit', data.businessProductId);
  }

  searchData() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.getListProducts();
    }, 800);
  }
}
