import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SurveyService, RootService } from 'src/app/services';
import {SurveyAnswerDetailComponent} from './survey-answer-detail/survey-answer-detail.component'
import { track } from 'src/app/helpers/track';
@Component({
  selector: 'app-survey-detail-results',
  templateUrl: './survey-detail-results.component.html',
  styleUrls: ['./survey-detail-results.component.scss']
})
export class SurveyDetailResultsComponent implements OnInit {
  listResults = [
    {
      id: 1,
      type: 1,
      title: 'Bạn có thực sự hài lòng về sản phẩm mèo méo meo mèo meo của chúng tôi không?',
      data: [
        {
          id: 1,
          answers: 'Có',
          value: '20%',
          image: "https://ruthamcauquan2.info/wp-content/uploads/2020/07/anh-gai-xinh-hap-dan-nhieu-nam-gioi-3.jpg"
        },
        {
          id: 2,
          answers: 'Không',
          value: '30%',
          image: "https://itcafe.vn/wp-content/uploads/2021/01/anh-gai-xinh-4.jpg"
        },
        {
          id: 3,
          answers: 'Trả lời khác',
          value: '50%',
          image: ""
        },
      ]
    },
    {
      id: 2,
      type: 2,
      title: 'Bạn có thực sự hài lòng về sản phẩm mèo méo meo mèo meo của chúng tôi không?',
      data: [
        {
          id: 1,
          range: "Từ 1 đến 25",
          text: 'Quá tệ',
          value: '20%'
        },
        {
          id: 2,
          range: "Từ 25 đến 50",
          text: 'Bình thường',
          value: '20%'
        },
        {
          id: 3,
          range: "Từ 50 đến 100",
          text: 'Rất tốt',
          value: '60%'
        },
      ]
    },
    {
      id: 3,
      title: 'Bạn có thực sự hài lòng về sản phẩm mèo méo meo mèo meo của chúng tôi không?',
      type: 3,
      data: [
        {
          id: 1,
          point: 1,
          text: 'Yêu thích',
          value: '20%'
        },
        {
          id: 2,
          point: 2,
          text: 'Tốt',
          value: '30%'
        },
        {
          id: 3,
          point: 3,
          text: 'Bình thường',
          value: '50%'
        },
      ]
    },
    {
      id: 4,
      type: 4,
      title: 'Bạn có thực sự hài lòng về sản phẩm mèo méo meo mèo meo của chúng tôi không?',
      data: [
        {
          id: 1,
          type: 'Văn bản',
          text: 'Nhập cảm nghĩ của bạn',
          value: '20%'
        },
        {
          id: 2,
          type: 'Ngày tháng',
          text: 'Nhập ngày sinh',
          value: '30%'
        },
        {
          id: 3,
          type: 'Số',
          text: 'Nhập số điện thoại',
          value: '10%'
        },
        {
          id: 4,
          type: 'Email',
          text: 'Nhập email',
          value: '40%'
        },
      ]
    },
    {
      id: 5,
      type: 5,
      title: 'Bạn có thực sự hài lòng về sản phẩm mèo méo meo mèo meo của chúng tôi không?',
      data: [
        {
          id: 1,
          question: "Tải lên tập tin 1",
          value: 1200
        },
        {
          id: 1,
          question: "Tải lên tập tin 2",
          value: 2200
        },
      ]
    }
  ]

  pageSurvey = []
  totalPage = 1;
  pageCurrent = 1;
  surveyName: string = ""

  @ViewChild("answerDetail", {static: true}) answerDetail: SurveyAnswerDetailComponent;

  unsubscribe$ = new Subject<void>();

  constructor(private surveyService: SurveyService, private rootService: RootService) { }

  id: any;
  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_survey_details_results',
    //   contentType: 'interaction',
    // })
    this.id = window.location.href.split("/")[6];
    if (this.id !== 'null') {
      // track('siteSearch', {
      //   keywords: "portal_scan_history",
      //   params: JSON.stringify({surveyId: this.id})
      // })
      this.surveyService.detailAnswer(this.id).pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res) {
              this.pageSurvey = res.data;
              this.totalPage = res.data.length;
              this.surveyName = res.surveyName

              const newArr = res.data.map(item => {
                item.items.map(it => {
                  if (it.type === 'DROPDOWN' || it.type === 'OPTIONAL'|| it.type === 'OPTIONAL_IMAGE') {
                    let totalPercent = 0;
                    if (it.answersReport) {
                      it.answersReport.map((val, index) => {

                        if (val.total > 0) {
                          const arrHasValue = it.answersReport.filter(i => i.total > 0);
                          if (val.id === arrHasValue[arrHasValue.length - 1].id){

                            val.percent = 100 - totalPercent
                          } else {
                              val.percent = Math.ceil((val.total / it.total)*100);
                              totalPercent += val.percent;
                          }
                        }
                        return val;
                      })
                    }
                  } else if (it.type === 'REVIEW') {
                    let totalPercent1 = 0;
                    if (it.answers) {
                      it.answers.map((val, index) => {
                        if (val.total > 0) {
                          const arrHasValue = it.answers.filter(i => i.total > 0);
                          if (val.id === arrHasValue[arrHasValue.length - 1].id){

                            val.percent = 100 - totalPercent1
                          } else {
                              val.percent = Math.ceil((val.total / it.total)*100);
                              totalPercent1 += val.percent;
                          }
                        }
                        return val;
                      })
                    }
                  }
                  return it;
                })
                return item;
              })


              // this.currentSurvey = res.data[this.pageCurrent - 1].items;
            }
          }, (err) => {
            // track('error', {
            //   apiCall: `${window.location.origin}/survey/question/report?id=${this.id}`,
            //   httpResponseCode: 500,
            //   errorMessage: err,
            //   errorCode: 500
            // })
          })
    }
  }

  prevPage() {
    if (this.pageCurrent === 1) return;
    this.pageCurrent -= 1;

  }


  nextPage() {
    if (this.pageCurrent === this.totalPage) return;
    this.pageCurrent += 1;

  }


  viewDetailAnswer(answerId) {
    // track('manualTrackInteractionContent', {
    //   regionName: 'survey_details_results',
    //   interaction: "click",
    //   contentName: 'answer_details',
    // })
    this.answerDetail.openModal(answerId);

  }

  exportExcel(name: string) {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'survey_details_results',
    //   contentName: 'export',
    // })
    const query: any = {
      id: this.id
    }

    this.surveyService.exportExcel(query).pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.rootService.export(data, name + '.xlsx' || 'Câu trả lời và thống kê khảo sát.xlsx');
    });
  }
}
