import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
declare var require: any
import QRCodeStyling from 'thanglv-qrcode-styling';
@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {

  constructor(private modalService: NzModalService) { }

  @Input() img = null;
  @Input() index = 0;
  @Input() data: any

  elementType = 'svg';
  value = 'someValue12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 2;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  templateDataSubject: any;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('canvas1', { static: true }) canvas1: ElementRef;
  qrCode = new QRCodeStyling({
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 60,
    height: 60,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded'
    },
    image: '',

    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  });

  qrCode2 = new QRCodeStyling({
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 250,
    height: 250,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded'
    },
    image: '',

    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  });

  isViewImage = false;

  tplModal: NzModalRef;

  ngOnInit() {
    if (!QRCodeStyling) {
      return;
    }


    this.qrCode.append(this.canvas.nativeElement);
    this.qrCode2.append(document.querySelector('.canvas1'));

    if (this.data && this.data) {
      this.testImage(this.data.logo, 5000)
    }

    this.qrCode.update(
      {
        width: this.data && this.data.width ? this.data.width : 100,
        height: this.data && this.data.height ? this.data.height : 100,
        // image: this.data.config && this.data.config.logo ? this.data.config.logo : "",
        dotsOptions: {
          color: this.data && this.data.color ? this.data.color : '#000000',
          type: this.data && this.data.shape ? this.data.shape : ''
        },
        backgroundOptions: {
          color: this.data && this.data.backgroundColor ? this.data.backgroundColor : '#ffffff'
        },
        data: this.data && this.data.data ? this.data.data : ''
      }
    )

    // get detail product
  }



  testImage(url, timeout) {
    const self = this;
    // if (!url) return;
    timeout = timeout || 15000;
    // tslint:disable-next-line:one-variable-per-declaration
    let timedOut = false, timer;
    const img = new Image();
    // tslint:disable-next-line:only-arrow-functions
    img.onerror = img.onabort = function () {
      if (!timedOut) {
        clearTimeout(timer);
        return false;
      }
    };
    // tslint:disable-next-line:only-arrow-functions
    img.onload = function () {
      if (!timedOut) {
        clearTimeout(timer);
        self.qrCode.update({
          image: url || '',
        })
        self.qrCode2.update({
          image: url || '',
        })
      }
    };
    img.src = url;
    // tslint:disable-next-line:only-arrow-functions
    timer = setTimeout(function () {
      timedOut = true;
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg';
      return false;
    }, timeout);
  }
  createTplModal() {
    this.qrCode2.update(
      {
        width: this.data && this.data.width ? this.data.width : 250,
        height: this.data && this.data.height ? this.data.height : 250,
        // image: this.data.config && this.data.config.logo ? this.data.config.logo : "",
        dotsOptions: {
          color: this.data && this.data.color ? this.data.color : '#000000',
          type: this.data && this.data.shape ? this.data.shape : ''
        },
        backgroundOptions: {
          color: this.data && this.data.backgroundColor ? this.data.backgroundColor : '#ffffff'
        },
        data: this.data && this.data.data ? this.data.data : '',
        // image: this.data && this.data.logo ? this.data.logo : "",
      }
    )
    if (this.data && this.data) {
      this.testImage(this.data.logo, 5000)
    }
    // this.qrCode2.append(this.canvas1.nativeElement);
    (document.querySelector('.modal') as any).style.display = 'flex';
    (document.querySelector('.modal') as any).style.alignItems = 'center'
  }


  closeModal() {
    (document.querySelector('.modal') as any).style.display = 'none';
    this.qrCode2.update(
      {
        width: this.data && this.data.width ? this.data.width : 250,
        height: this.data && this.data.height ? this.data.height : 250,
        // image: this.data.config && this.data.config.logo ? this.data.config.logo : "",
        dotsOptions: {
          color: this.data && this.data.color ? this.data.color : '',
          type: this.data && this.data.shape ? this.data.shape : ''
        },
        backgroundOptions: {
          color: this.data && this.data.backgroundColor ? this.data.backgroundColor : ''
        },
        data: this.data && this.data.data ? this.data.data : ''
      }
    )

  }

}
