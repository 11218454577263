import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-document-delete',
  templateUrl: './document-delete.component.html',
  styleUrls: ['./document-delete.component.scss']
})
export class DocumentDeleteComponent implements OnInit {
  isVisible = false;
  // tslint:disable-next-line:variable-name
  list_id;
  modal = {
    label: 'Xác nhận xóa giấy tờ',
    value: ' <p class="mrb-10">Hệ thống sẽ gỡ bỏ giấy tờ này.</p>\n' +
      '          <p>Bạn có chắc chắn muốn xoá giấy tờ?</p>'
  };
  isLoadingBtn = false;
  @Output() emitData = new EventEmitter();
  constructor() {
  }

  ngOnInit() {
  }

  show(ids) {
    this.isVisible = true;
    this.list_id = ids;
  }

  cancel() {
    this.isVisible = false;
  }

  delete() {
    this.isLoadingBtn = true;
    this.emitData.emit(this.list_id);
  }
  success() {
    this.isLoadingBtn  = false;
  }
}
