import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth';
import { FormBuilder } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  disabled = false;
  message;
  css: any;
  form = this.fb.group({
    username: '',
    password: '',
  });

  form2 = this.fb.group({
    username: '',
    password: '',
  });
  linkToken;
  validUsername = false;
  validPassword = false;

  isVisible = false;
  isVisible2 = false;

  @ViewChild('contactGroup', {static: true}) contactGroup;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // redirect to home if already logged in
    // this.contactGroup.showModal();
    // track('trackLoadPageView', {
    //   screenName: 'portal_login',
    //   contentType: 'homepage',
    // });
    this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.loginSso(params.token);
      }
    });
    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    const user = this.authService.currentUserValue;
    if (user) {
      this.router.navigate(['/portal/dashboard']).then(r => {});
    }

    if (sessionStorage.getItem('showBannerLogin') !== 'true') {
      this.isVisible2 = true;
    }
    sessionStorage.setItem('showBannerLogin', 'true');
  }

  loginSso(token) {
    const body = {token};
    this.authService.ssoLogin(body).subscribe(
      async (response) => {
        this.disabled = false;
        if (response.code === 1) {

          this.disabledMess();
          this.css = {
            'margin-top': '5px',
            color: '#01DF01',
            'font-weight': 500,
            'font-size': '13px',
          };
          this.notification.create(
            'success',
            'Thành công',
            'Đăng nhập thành công!',
            {nzDuration: 1000}
          );
          const currentUser = this.authService.currentUserValue;
          if (currentUser.userInfo.userType !== 5) {
            await this.router.navigate(['/portal/dashboard']);
          } else {
            await this.router.navigate(['/support-business']);
          }
          // this.router.navigate(['/portal/dashboard']);
          return;
        } else if (response.code === 5) {
          this.disabledMess();
          this.css = {
            'margin-top': '5px',
            color: '#01DF01',
            'font-weight': 500,
            'font-size': '13px',
          };
          this.contactGroup.showModal();
        } else {
          this.css = {
            'margin-top': '5px',
            color: 'red',
            'font-weight': 500,
            'font-size': '13px',
          };
          this.notification.create(
            'warning',
            'Có lỗi xảy ra',
            response.message,
            {nzDuration: 1000}
          );
          // this.message = response.message;
        }
      },
      (error) => {
        this.disabled = false;
        this.css = {
          'margin-top': '5px',
          color: 'red',
          'font-weight': 500,
          'font-size': '13px',
        };
        this.notification.create(
          'warning',
          'Có lỗi xảy ra',
          'Tài khoản chưa được đăng ký!',
          { nzDuration: 5000 }
        );
        // this.message = "Tên đăng nhập hoặc mật khẩu không đúng";
      }
    );
  }

  handleCancel2(): void {
    this.isVisible2 = false;
  }

  inputChange(event, input) {
    if (event.length) {
      this[input] = true;
    } else {
      this[input] = false;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  onSubmit() {
    const phone = this.form2.get('username').value;
    const password = this.form2.get('password').value;
    const body = {
      phone: phone.trim(),
      password: password.trim(),
    };

    this.authService.linkAcc(this.linkToken, body).subscribe(
      (res) => {
        if (res.code === 1) {
          this.notification.create(
            'success',
            'Thành công',
            'Mời bạn đăng nhập bằng tài khoản vừa liên kết',
            { nzDuration: 600000 }
          );
          this.isVisible = false;
        } else {
          this.notification.create('error', 'Thất bại', res.message);
        }
      },
      (err) => {
        this.notification.create('error', 'Thất bại', err);
      }
    );
  }

  disabledMess() {
    $('.ant-notification-notice-close-x').click();
  }

  login() {
    console.log(this.form.value)
    this.message = '';
    let phone = this.form.get('username').value;
    let password = this.form.get('password').value;
    if (!phone || !password) {
      return;
    }
    if (phone && password) {
      this.disabled = true;
      phone = phone.trim();
      password = password.trim();
      const body = {
        userName: phone,
        password
      };
      // track('init', `${environment.tracking_id}` );
      // track('manualTrackInteractionContent', {
      //   interaction: 'click',
      //   regionName: 'portal_login',
      //   contentName: 'login',
      //   target: `${window.location.origin}/portal/dashboard`,
      //   payload: JSON.stringify(body),
      // });
      this.authService.login(body).subscribe(
        async (response) => {
          this.disabled = false;
          if (response.code === 1) {
            this.disabledMess();
            this.css = {
              'margin-top': '5px',
              color: '#01DF01',
              'font-weight': 500,
              'font-size': '13px',
            };
            this.message = 'Đăng nhập thành công!';
            const currentUser = this.authService.currentUserValue;
            if (currentUser.userInfo.userType !== 5) {
              await this.router.navigate(['/portal/dashboard']);
            } else {
              await this.router.navigate(['/support-business']);
            }
            // setTimeout(() => {
            //   this.router.navigate(['/portal/dashboard']).then(r => {});
            // }, 1000);
            return;
          } else if (response.code === 5) {
            this.disabledMess();
            this.css = {
              'margin-top': '5px',
              color: '#01DF01',
              'font-weight': 500,
              'font-size': '13px',
            };
            this.contactGroup.showModal();
          } else {
            this.css = {
              'margin-top': '5px',
              color: 'red',
              'font-weight': 500,
              'font-size': '13px',
            };
            this.notification.create(
              'warning',
              'Có lỗi xảy ra',
              response.message,
              {nzDuration: 1000}
            );
          }
        },
        (error) => {
          console.log(error)
          // track('error', {
          //   apiCall: `${window.location.origin}/login`,
          //   apiPayload: body,
          //   httpResponseCode: 400,
          //   errorMessage: error,
          //   errorCode: 400
          // });
          this.disabled = false;
          this.css = {
            'margin-top': '5px',
            color: 'red',
            'font-weight': 500,
            'font-size': '13px',
          };
          this.message = 'Tên đăng nhập hoặc mật khẩu không đúng';
        }
      );
    }
  }
}
