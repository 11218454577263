import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ProductsReviewsService } from "src/app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router, Event, NavigationEnd } from "@angular/router";
import { NzNotificationService, NzModalService } from "ng-zorro-antd";
import { removeEmptyQuery, sleep } from "src/app/helpers";

@Component({
  selector: "app-products-reviews",
  templateUrl: "./products-reviews.component.html",
  styleUrls: ["./products-reviews.component.scss"],
})
export class ProductsReviewsComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  pageIndex = 1;
  pageSize = 10;

  constructor(
    private reviewService: ProductsReviewsService,
    private notification: NzNotificationService,
    private router: Router
  ) {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.productId = window.location.href.split("/")[5];
          this.average_point_from = null;
          this.average_point_to = null;
          // this.loadCriteria();
          this.loadData2();
        }
      });
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  filters = [
    { filter: "<6 Trung bình", value: 0 },
    { filter: "6-7 Trung bình", value: 1 },
    { filter: "7-8 Tốt", value: 2 },
    { filter: "8-9 Tuyệt vời", value: 3 },
    { filter: ">9 Trên cả tuyệt vời", value: 4 },
  ];

  sorts = [
    { sort: "Mới nhất", value: 0 },
    { sort: "Cũ nhất", value: 0 },
  ];

  average_point_from: number;
  average_point_to: number;
  filterReview(data: any) {
    this.pageIndex = 1;
    if (data === 0) {
      this.average_point_from = 0;
      this.average_point_to = 2.99;
      this.loadData();
      return;
    } else if (data === 1) {
      this.average_point_from = 3;
      this.average_point_to = 3.49;
      this.loadData();
      return;
    } else if (data === 2) {
      this.average_point_from = 3.5;
      this.average_point_to = 3.99;
      this.loadData();
      return;
    } else if (data === 3) {
      this.average_point_from = 4;
      this.average_point_to = 4.49;
      this.loadData();
      return;
    } else if (data === 4) {
      this.average_point_from = 4.5;
      this.average_point_to = 5;
      this.loadData();
      return;
    }
  }

  productId: string;
  ngOnInit() {
    this.productId = window.location.href.split("/")[5];
    this.average_point_from = null;
    this.average_point_to = null;
    // this.loadData();
    this.loadCriteria();

    this.reviewService.currentStatus
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        if (status) {
          this.pageIndex = 1;
          this.loadData();
          return;
        } else {
          this.loadData();
        }
      });
  }

  //================================list==================================//
  listOfAllData: any[] = [];
  total: number = 0;
  loading: Boolean = true;
  hasMore: any;
  hasCollapse: any;

  sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async loadData(ms = 0) {
    const query: any = {
      product_id: this.productId,
      average_point_from: this.average_point_from
        ? this.average_point_from
        : null,
      average_point_to: this.average_point_to ? this.average_point_to : null,
      offset: (this.pageIndex - 1) * this.pageSize,
      limit: this.pageSize,
    };

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.reviewService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.total = res.count;
          this.listOfAllData = res.rows;

          if (res.rows) {
            if (this.listOfAllData.length === this.total || this.total === 1) {
              this.hasMore = false;
            } else {
              this.hasMore = true;
            }

            if (this.listOfAllData.length <= 1) {
              this.hasCollapse = false;
            } else {
              this.hasCollapse = true;
            }
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  async loadData2(ms = 0) {
    const query: any = {
      product_id: this.productId,
      average_point_from: this.average_point_from
        ? this.average_point_from
        : null,
      average_point_to: this.average_point_to ? this.average_point_to : null,
      offset: (this.pageIndex - 1) * this.pageSize,
      limit: this.pageSize,
    };

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.reviewService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.total = res.count;
          this.listOfAllData = res.rows;

          if (res.rows) {
            if (this.listOfAllData.length === this.total || this.total === 1) {
              this.hasMore = false;
            } else {
              this.hasMore = true;
            }

            if (this.listOfAllData.length <= 1) {
              this.hasCollapse = false;
            } else {
              this.hasCollapse = true;
            }
          }

          if (res.code === 1008) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
        },
        (err) => {
          // this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  loadMore(type: string) {
    if (type === "more") {
      this.pageSize = this.pageSize + 10;
      this.loadData();
      return;
    }
    if (type === "collapse") {
      this.pageSize = this.pageSize - 10;
      if (this.pageSize < 1) {
        this.pageSize = 1;
      }
      this.loadData();
      return;
    }
  }

  criteria: any;
  average_point: number;
  product_evaluation_gather: any = [];
  loadCriteria() {
    const query: any = {
      product_id: this.productId,
      offset: (this.pageIndex - 1) * this.pageSize,
      limit: this.pageSize,
    };

    removeEmptyQuery(query);

    this.reviewService
      .getAllCriteria(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.criteria = res;
          if (res.product_evaluation) {
            this.average_point = res.product_evaluation.average_point;
          }
          this.product_evaluation_gather = res.product_evaluation_gather;
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
