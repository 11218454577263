import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({
  providedIn: "root",
})
export class SettingSubscriptionService extends BaseService {
  getAll(query?: any): Observable<any> {
    return this.get<any>("/configuration/service-pack", { params: query });
  }

  getAllPackage(query?: any): Observable<any> {
    return this.get<any>("/package", { params: query });
  }

  create(body: any): Observable<any> {
    return this.post<any>(`/configuration/service-pack`, body);
  }

  update(body: any): Observable<any> {
    return this.put<any>(`/configuration/service-pack`, body);
  }

  destroy(body: any): Observable<any> {
    return this.put<any>(`/configuration/service-pack`, body);
  }

  listSubscription(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/subscription", httpOptions);
  }

  createdSubscription(body: any) {
    return this.post<any>("/subscription", body);
  }

  editSubscription(id, body: any) {
    return this.put<any>(`/subscription?sub_id=${id}`, body);
  }

  upgradeSubscriptionDetail(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/subscription/plan/product/update", httpOptions);
  }

  upgradeSubscription(id, body: any) {
    return this.put<any>(`/subscription/upgrade?sub_id=${id}`, body);
  }

  listTime() {
    return this.get<any>("/subscription/product/plan?type=1");
  }

  listProduct() {
    return this.get<any>("/subscription/product/plan?type=2");
  }

  deleteSubscription(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
    };
    return this.delete<any>(`/subscription`, httpOptions);
  }

  planSubscription(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/subscription/plan", httpOptions);
  }
  listUpgradeById(query?: any) {
    return this.get<any>("/subscription/upgrade", { params: query });
  }
  getInfoUpgradeOldAndNew(id?: any) {
    return this.get<any>(`/subscription/upgrade/${id}`);
  }
  updateUpgradeDetail(body?: any, query?: any) {
    return this.put<any>("/subscription/upgrade/edit", body, { params: query });
  }
  getDetailUpgrade(id?: any) {
    return this.get<any>(`/subscription/detail?sub_id=${id}`);
  }
  upgradeSub(body?: any, query?: any) {
    return this.put<any>("/subscription/upgrade", body, { params: query });
  }

  // lấy danh sách plan
  loadListPlanType() {
    return this.get<any>("/subscription/media/plan-type");
  }

  // lấy danh sách item trong từng plan
  loadListPlanItems(query: any) {
    return this.get<any>("/subscription/media/plan", { params: query });
  }
}
