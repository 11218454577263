import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { BusinessComponent } from './business/business.component';
import { RedirectFreeComponent } from './business/redirect-free/redirect-free.component';
import {SupportBusinessComponent} from './support-business/support-business.component';

const routes = [
  {
    path: '',
    component: RedirectFreeComponent
    // loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule),
  },
  {
    path: 'support-business',
    canActivate: [AuthGuard],
    component: SupportBusinessComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule),
  },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./business/business.module').then(mod => mod.BusinessModule),
    component: BusinessComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
