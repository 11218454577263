import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  isVisible = false;
  index = 1;
  name: any;
  title: any;
  data: any;
  id: any;
  isLoadingBtn = false;

  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService
  ) {
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }


  handleCancel(): void {
    this.isVisible = false;
  }

  showModal(data: any, id?: any) {
    this.isVisible = true;
    this.data = data;
    if (typeof id === 'string') {
      const a = [];
      a.push(id);
      this.id = a;
    } else {
      this.id = id;
    }
  }

  onSubmit() {
    this.isLoadingBtn = true;
    if (this.data.type && this.data.type === 'confirm') {
      const output = {
        id: this.id,
        type : this.data.type
      }
      this.emitData.emit(output);
    } else {
      this.emitData.emit(this.id);
    }

  }

  success() {
    this.isLoadingBtn = false;
  }

}
