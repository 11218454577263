import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { UploaderComponent } from 'src/app/shared/uploader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShopsService } from 'src/app/services';

@Component({
  selector: 'app-block-stores',
  templateUrl: './block-stores.component.html',
  styleUrls: ['./block-stores.component.scss']
})
export class BlockStoresComponent implements OnInit, OnDestroy {

  isVisible = false;
  showModal() {
    this.isVisible = true;
    this.proof_images = [];
    this.form.reset();
    this.loadReason();
  }

  handleCancel() {
    this.isVisible = false;
  }

  @Output() blockStores = new EventEmitter<any>();

  unsubscribe$ = new Subject<void>();
  current = 0;
  pageIndex = 1;
  pageSize = 5;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private shopService: ShopsService,
    private notification: NzNotificationService,
  ) { }

  form = this.fb.group({
    note: [null],
    proof_images: [],
    reason_id: [null, [Validators.required]],
    product_ids: [null],
  })

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  ngOnInit() {
  }

  reasons: any[] = [];
  loadReason() {
    this.shopService.getAllReason().subscribe(
      (res: any) => {
        this.reasons = res.data.items;
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  isShowNote: Boolean = false;
  selectReason(data: any) {
    if (data == 1003) {
      this.isShowNote = true;
      this.form.get('note').setValidators([Validators.required]);
    } else {
      this.isShowNote = false;
      this.form.get('note').setValidators(null);
    }
  }

  proof_images = [];
  isShow: Boolean = false;
  openUploadDialog(field: string) {
    let fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          if (field === 'proof_images') {
            this.proof_images.push({
              id: changeParam.file.response.id,
              name: changeParam.file.name,
              type: changeParam.file.type,
              url: changeParam.file.response.url,
            });
            this.form.get('proof_images').setValue(this.proof_images.map(proof_image => proof_image.id));
            this.form.get(field).updateValueAndValidity();
            if (this.proof_images.length > 5) {
              this.isShow = true;
            }
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        modal.close();
      });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'proof_images') {
      this.proof_images.splice(index, 1);
      this.form.get('proof_images').setValue(this.proof_images.map(proof_image => proof_image.id));
      this.form.get(field).updateValueAndValidity();
    }
  }

  isLoadingBtn: Boolean = false;
  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }
    
    this.isLoadingBtn = true;
    
    this.blockStores.emit(this.form.value);
  }

  success() {
    this.isLoadingBtn = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
