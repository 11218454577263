import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  DistributorsService,
  ProductsDistributorsService,
  RootService,
  ProductsService,
} from "../../../../services";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ActivatedRoute, Params } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { publishReplay, takeUntil } from "rxjs/operators";
import { Location } from "@angular/common";

@Component({
  selector: "app-products-distributors",
  templateUrl: "./products-distributors.component.html",
  styleUrls: ["./products-distributors.component.scss"],
})
export class ProductsDistributorsComponent implements OnInit, OnDestroy {
  pageIndex = 1;
  pageSize = 10;
  loading = false;
  total: number;
  changeAction;
  actions = [
    { label: "Export", value: 0 },
    { label: "Export tất cả", value: 1 },
  ];
  // statusList = [
  //   { label: "Đã duyệt", value: 1 },
  //   { label: "Chờ duyệt", value: 2 },
  //   { label: "Từ chối", value: 3 },
  // ];
  statusList = [
    { label: "Ẩn", value: 1 },
    { label: "Hiện", value: 2 },
  ];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  imageFalse = [];
  productId;
  // @ts-ignore
  @ViewChild("modal") modal;
  // @ts-ignore
  @ViewChild("modalDelete") modalDelete;
  // @ts-ignore
  @ViewChild("addDistributors") addDistributors;
  ids: any[] = [];
  unsubscribe$ = new Subject<void>();
  businessProductId;
  query;
  phone;
  provinceId;
  role;
  status;
  city: any;
  listRole;
  searchQuery;
  title;
  listTitle;
  showPaging = true;

  constructor(
    private productsDistributorsService: ProductsDistributorsService,
    private notification: NzNotificationService,
    private distributorsService: DistributorsService,
    private rootService: RootService,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  managementStatus: number;
  roleCode: string = null;

  clearPosParam() {
    // @ts-ignore
    this.location.replaceState(this.location.path().split("?")[0], "");
  }
  ngOnInit() {
    this.productId = window.location.href.split("/")[5];

    let query = {
      type: 1, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : 0
    }
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.city = res.data;
      },
      (error) => {
        this.city = [];
      }
    );
    this.rootService
      .getRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.listRole = res.data;
      });

    // this.rootService
    // .getTitles({})
    // .pipe(takeUntil(this.unsubscribe$))
    // .subscribe((res: any) => {
    //   this.listTitle = res.data;
    // });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params.query !== undefined) {
          this.clearPosParam();
          this.query = params.query;
        }
        this.loadData();
      });

    // this.productService
    //   .getById(this.productId)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((res) => {
    //     if (res.code === 1) {
    //       this.managementStatus = res.data.managementStatus;
    //       this.roleCode = res.data.roleCode;
    //       let body: any = {
    //         managementStatus: this.managementStatus,
    //         roleCode: this.roleCode,
    //       };
    //       this.productService.getManagementStatus(body);
    //     }
    //   });
  }

  changeRole() {
    this.rootService.getTitles({ roleId: this.role})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: any) => {
      this.listTitle = res.data;
    })

    this.search();
  }

  async loadData(ms = 0) {
    this.loading = true;
    await this.sleep(ms);
    this.searchQuery = {
      provinceId: this.provinceId ? this.provinceId : "",
      query: this.query ? this.query.trim() : "",
      phone: this.phone ? this.phone.trim() : "",
      productId: this.productId,
      role: this.role ? this.role : "",
      title: this.title ? this.title : "",
      status: this.status ? this.status : "",
      page: this.pageIndex,
      pageSize: this.pageSize,
    };



    this.productsDistributorsService
      .listDistributor(this.searchQuery)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.total = res.totalElement;
          this.listOfAllData = res.data;

          if (this.total <= 10) {
            this.showPaging = false;
          } else {
            this.showPaging = true;
          }
          if (res.data.length === 0 && this.pageIndex > 1) {
            this.pageIndex = this.pageIndex - 1;
            this.loadData();
          }
        },
        (err) => {
          this.loading = false;
          this.notification.create("error", "Thất bại", err);
        }
      );
  }

  search() {
    this.pageIndex = 1;
    this.loadData();
  }

  reset() {
    this.pageIndex = 1;
    this.query = undefined;
    this.role = undefined;
    this.provinceId = undefined;
    this.status = undefined;
    this.title = undefined;
    this.loadData();
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    if (this.listOfDisplayData.length > 0) {
      this.isAllDisplayDataChecked = this.listOfDisplayData.every(
        (item) => this.mapOfCheckedId[item.businessProductId]
      );
    } else {
      this.isAllDisplayDataChecked = false;
    }
    this.isIndeterminate =
      this.listOfDisplayData.some((item) => this.mapOfCheckedId[item.businessProductId]) &&
      !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.businessProductId] = value)
    );
    this.refreshStatus();
  }

  loadDataChild($event: any) {
    this.loadData(1000);
  }

  editDistributor(id: any) {
    const query = {
      businessProductId: id,
    };
    this.productsDistributorsService
      .detailDistributor(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.modal.showModal(res.data, id, this.productId);
      });
  }

  actionsButton() {
    if (this.changeAction === "1") {
      this.addDistributors.showModal();
    }
    if (this.changeAction === "2") {
      this.deleteProduct();
    }
    if (this.changeAction === "3") {
      this.exportExcel();
    }
  }

  /*Check link image error*/
  checkImageErr(imageError) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, "_blank");
    }
  }

  /*End Check link image error*/

  /*Modal delete*/
  setName(data) {
    this.businessProductId = data.businessProductId;
    const text = {
      label: "Xóa đối tượng",
      value:"Bạn có chắc chắn muốn xoá đối tượng?",
    };
    this.modalDelete.showModal(text, data.businessProductId);
  }

  eventDelete(value) {
    const body = {
      body: {
        businessProductIds: value,
      },
    };
    this.productsDistributorsService
      .deleteDistributor(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.notification.create(
              "success",
              "Thành công",
              "Xóa đối tượng thành công"
            );
            this.mapOfCheckedId = {};
            this.ids = [];
            this.modalDelete.handleCancel();

            this.loadData(500);
          } else {
            this.notification.create("warning", "Cảnh báo", res.message);
          }
          this.modalDelete.success();
        },
        (err) => {
          this.notification.create("error", "Thất bại", err);
          this.modalDelete.success();
        }
      );
  }

  deleteProduct() {
    const text = {
      label: "Xóa đối tượng",
      value: "<p>Bạn có chắc chắn muốn xoá danh sách đối tượng</p>",
    };
    this.modalDelete.showModal(text, this.ids);
  }

  updateStatus(status) {
    const body = {
      ids: this.ids,
      status: status === 'hidden' ? 0 : 1
    }



    this.productsDistributorsService.updateStatus(body)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (res) => {
        if (res.code === 1) {
          this.notification.create(
            "success",
            "Thành công",
            status === 'hidden' ? "Ẩn liên kết của đối tượng và sản phẩm thành công" : "Hiện liên kết của đối tượng và sản phẩm thành công"
          );
          this.mapOfCheckedId = {};
          this.ids = [];
          this.loadData(500);
        } else {
          this.notification.create("warning", "Cảnh báo", res.message);
        }
        this.modalDelete.success();
      },
      (err) => {
        this.notification.create("error", "Thất bại", err);
        this.modalDelete.success();
      }
    )

  }

  /*End Modal delete*/

  exportExcel() {
    const query = {
      export_all: this.ids.length === 0,
      ids: this.ids.length !== 0 ? this.ids.toString() : "",
      provinceId: this.provinceId ? this.provinceId : "",
      query: this.query ? this.query.trim() : "",
      product_id: this.productId,
      role: this.role ? this.role : "",
      title: this.title ? this.title : "",
      status: this.status ? this.status : "",
      page: this.pageIndex,
      pageSize: this.total,
    };
    this.productsDistributorsService
      .exportExcel(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.rootService.export(
          data,
          "Danh sách Doanh nghiệp, cá nhân của sản phẩm.xlsx"
        );
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
