import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth";
import { FormBuilder } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { th } from "date-fns/locale";
@Component({
  selector: "app-forget",
  templateUrl: "./forget.component.html",
  styleUrls: ["./forget.component.scss"],
})
export class ForgetComponent implements OnInit {
  showPhone = true;
  showOtp = false;
  showChangePass = false;
  phone;
  // tslint:disable-next-line:variable-name
  phone_custom;
  otp;
  message;
  message2;
  message3;
  password;
  css;
  // tslint:disable-next-line:variable-name
  confirm_password;
  loadingStep1: boolean = false
  loadingStep2: boolean = false
  loadingStep3: boolean = false
  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {}
  getOtp() {
    const phone = this.phone ? this.phone.trim() : "";
    if (phone) {
      this.loadingStep1 = true
      this.authService.getOtp(phone).subscribe(
        (res) => {
          if (res.code === 1) {
            // custom phone show
            const length = phone.length;
            const c = length % 3;
            if (c) {
              const d = phone.slice(0, c);
              const d2 = phone.slice(c, length);
              let d3 = "";
              for (let i = 0; i < d2.length / 3; i++) {
                const p = d2.slice(3 * i, 3 * i + 3);
                d3 = d3 + "." + p;
              }
              this.phone_custom = d + d3.slice(1, d3.length);
            } else {
              let d = "";
              for (let i = 0; i < length / 3; i++) {
                const p = phone.slice(3 * i, 3 * i + 3);
                d = d + "." + p;
              }
              this.phone_custom = d.slice(1, d.length);
            }
            this.message = "";
            this.showPhone = false;
            this.showOtp = true;
            this.token = res.token;
          } else {
            this.message = res.message || "Không thể thực hiện liên tiếp trong 60s";
          }
          this.loadingStep1 = false;
        },
        (error) => {
          this.message = error.message || "Số điện thoại không hợp lệ";
          this.loadingStep1 = false;
        }
      );
    } else {
      this.message = "Số điện thoại không hợp lệ";
    }
  }

  token: string = "";
  sendOtp() {
    // const otp = this.otp ? this.otp.trim() : '';
    //   if (otp) {
    //   this.message2 = '';
    //   this.showChangePass = true;
    //   this.showOtp = false;
    // } else {
    //     this.message2 = 'Mã OTP không hợp lệ';
    //   }
    let body = {
      token: this.token,
      otp: this.otp ? this.otp.trim() : "",
    };
    this.loadingStep2 = true;
    this.authService.confirmOtp(body).subscribe(
      (res: any) => {
        if (res.code === 1) {
          this.message2 = "";
          this.showChangePass = true;
          this.showOtp = false;
          this.token2 = res.token;
        } else {
          this.message2 = res.message || "Không thể thực hiện liên tiếp trong 60s";
        }
        this.loadingStep2 = false;
      },
      (err: any) => {
        this.message2 = err.message || "Mã OTP không hợp lệ";
        this.loadingStep2 = false;
      }
    );
  }

  token2: string = "";
  changePass() {
    this.loadingStep3 = true;
    const password = this.password ? this.password.trim() : "";
    // tslint:disable-next-line:variable-name
    const confirm_password = this.confirm_password
      ? this.confirm_password.trim()
      : "";
    if (!password || !confirm_password) {
      return;
    }
    if (password != confirm_password) {
      this.message3 = "Mật khẩu xác nhận không giống mật khẩu.";
      this.css = {
        "font-style": "italic",
        position: "relative",
        color: "red",
      };
    } else {
      if (password.length < 6) {
        this.css = {
          "font-style": "italic",
          position: "relative",
          color: "#01DF01",
        };
        this.message3 = "Mật khẩu tối thiểu 6 ký tự";
        return;
      }
      const body = {
        token: this.token2,
        password: password,
        confirmPassword: confirm_password,
      };
      this.authService.sendOtp(body).subscribe(
        (res) => {
          if (res.code && res.code === 1) {
            this.message3 = "Thay đổi mật khẩu thành công";
            this.css = {
              "font-style": "italic",
              position: "relative",
              color: "#01DF01",
            };
            setTimeout(() => {
              this.router.navigate([""]);
            }, 1000);
          } else {
            this.css = {
              "font-style": "italic",
              position: "relative",
              color: "red",
            };
            this.message3 = res.message || "Mã OTP không hợp lệ hoặc đã hết hạn";
          }
          this.loadingStep3 = false;
        },
        (error) => {
          this.css = {
            "font-style": "italic",
            position: "relative",
            color: "red",
          };
          this.message3 = error.message || "Mã OTP không hợp lệ hoặc đã hết hạn";
          this.loadingStep3 = false;
        }
      );
    }
  }

  isNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
