import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-checkin',
  templateUrl: './preview-checkin.component.html',
  styleUrls: ['./preview-checkin.component.scss']
})
export class PreviewCheckinComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
