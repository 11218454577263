import { Component, OnInit, ViewChild } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { takeUntil, throwIfEmpty } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { UploaderComponent } from "src/app/shared";
import {
  NzFormatEmitEvent,
  NzModalService,
  NzNotificationService,
  NzTreeNodeOptions,
} from "ng-zorro-antd";
import { Timestamp } from "rxjs/internal/operators/timestamp";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SurveyService } from "src/app/services";
import { Router } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-survey-detail-personal",
  templateUrl: "./survey-detail-personal.component.html",
  styleUrls: ["./survey-detail-personal.component.scss"],
})
export class SurveyDetailPersonalComponent implements OnInit {
  form = this.fb.group({
    surveyTypeCode: ["BASIC"],
    templateCode: ["BASIC"],
    name: ["", [Validators.required]],
    category: [null],
    content: new FormArray([this.createPageFormArray()]),
  });
  pageCurrent = 1;
  listSurveyGroup = [];
  unsubscribe$ = new Subject<void>();
  listPage = [];
  valueSlider = 100;
  valueRate = 3;
  listTextArea = [
    { id: 1, title: "", allowRemove: false, isCorrect: false },
    { id: 2, title: "", allowRemove: false, isCorrect: true },
  ];
  listQuestion = [];
  listAnswerSelect = [
    { id: 1, value: "", allowRemove: false, isCorrect: false },
    { id: 2, value: "", allowRemove: false, isCorrect: true },
  ];
  listAnswerMutipleSelect = [
    { id: 1, value: "", allowRemove: false, isCorrect: false },
    { id: 2, value: "", allowRemove: false, isCorrect: true },
  ];
  listAnswerImage = [
    { id: 1, value: "", description: "", allowRemove: false, isCorrect: false },
    { id: 2, value: "", description: "", allowRemove: false, isCorrect: true },
  ];
  listNumberVote = [
    {
      id: 1,
      from: 0,
      to: 50,
      description: "",
      allowRemove: false,
      isCorrect: false,
    },
    {
      id: 2,
      from: 50,
      to: 100,
      description: "",
      allowRemove: false,
      isCorrect: false,
    },
  ];
  listStarVote = [
    {
      id: 1,
      from: 0,
      to: 50,
      description: "",
      allowRemove: false,
      isCorrect: false,
    },
    {
      id: 2,
      from: 50,
      to: 100,
      description: "",
      allowRemove: false,
      isCorrect: false,
    },
  ];
  listEmotionVote = [
    {
      id: 1,
      img: "/assets/png/Group.png",
      description: "",
      allowRemove: false,
      isCorrect: false,
    },
    {
      id: 2,
      img: "/assets/png/Group-1.png",
      description: "",
      allowRemove: false,
      isCorrect: false,
    },
  ];
  listTypeInput = [
    {
      title: "Lựa chọn",
      colorCode: "#199ED8",
      backgroundCode: "rgba(25, 158, 216, 0.1)",
      border: "1px solid #199ED8",
      code: "DROPDOWN",
    },
    {
      title: "Danh sách trổ xuống",
      colorCode: "#D88C19",
      backgroundCode: "rgba(216, 140, 25, 0.1)",
      border: "1px solid #D88C19",
      code: "OPTIONAL",
    },
    {
      title: "Chọn hình ảnh",
      colorCode: "#FB2F91",
      backgroundCode: "rgba(251, 47, 145, 0.1)",
      border: "1px solid #FB2F91",
      code: "OPTIONAL_IMAGE",
    },
    {
      title: "Thang đánh giá",
      colorCode: "#194ED8",
      backgroundCode: "rgba(25, 78, 216, 0.1)",
      border: "1px solid #194ED8",
      code: "REVIEW",
    },
    {
      title: "Nhiều hộp văn bản",
      colorCode: "#32A40A",
      backgroundCode: "rgba(50, 164, 10, 0.1)",
      border: "1px solid #32A40A",
      code: "BOX",
    },
    {
      title: "Thông tin liên hệ",
      colorCode: "#D81919",
      backgroundCode: "rgba(216, 25, 25, 0.1)",
      border: "1px solid #D81919",
      code: "CONTACT_INFO",
    },
    {
      title: "Tải tệp tin lên",
      colorCode: "#834471",
      backgroundCode: "rgba(131, 68, 113, 0.1)",
      border: "1px solid #834471",
      code: "FILE",
    },
  ];

  get questions(): any {
    return (
      this.form
        .get("content")
        ["controls"][this.pageCurrent - 1].get("questions") || []
    );
  }

  get content(): any {
    return this.form.get("content");
  }

  id: any;
  currentSurvey: any;
  listPersons: any = [];
  selectedIdPerson: any = null;

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private notification: NzNotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.id = window.location.href.split("/")[6];

    this.getListEmotion();
    this.getListSurveyGroups();
    this.getListSurveyGroup();
    this.getListQuestionType();

    if (this.id !== "null") {
      this.getListPersonAnswer(this.id);
    }
  }

  getListPersonAnswer(idSurvey: any) {
    this.surveyService
      .getListPersonsAnswer(idSurvey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code == 1) {
          this.listPersons = res.data && res.data.length ? [...res.data] : [];
          if (this.listPersons && this.listPersons.length) {
            this.getPersonDetailAnswer(this.listPersons[0].id);
          }
        }
      });
  }

  getPersonDetailAnswer(idPerson: any) {
    this.selectedIdPerson = idPerson;
    this.pageCurrent = 1
    this.surveyService
      .getPersonDetailAnswer(idPerson)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code == 1) {
          this.currentSurvey = res.data;
          this.form.patchValue({
            surveyTypeCode: res.data.surveyTypeCode,
            templateCode: res.data.templateCode,
            name: res.data.name,
            category: res.data.category,
            content: res.data.surveyContent.content,
          });
          const content = new FormArray([]);
          res.data.surveyContent.content.map((item, index) => {
            content.push(this.createPageFormArray(index + 1));
            item.questions.map((it, i) => {
              const data = {
                ...it,
                ...this.listTypeInput.find((item) => item.code === it.code),
              };
              content
                .at(index)
                ["controls"]["questions"].push(
                  this.addQuestionForPage(data, i)
                );
            });
          });
          this.form.controls.content = content;
          this.listPage = new Array(
            (this.form.controls as any).content.controls.length
          );
        }
      });
  }

  listQuestionType = [];
  getListQuestionType() {
    let query = {
      templateCode: "BASIC",
      surveyTypeCode: "BASIC",
    };

    this.surveyService
      .getListQuestionType(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listQuestionType = res.data;
        }
      });
  }

  listEmotion = [];
  getListEmotion() {
    let query = {
      templateCode: "BASIC",
      surveyTypeCode: "BASIC",
    };

    this.surveyService
      .getListEmotion(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listEmotion = res.data;
        }
      });
  }

  getListSurveyGroups() {
    let query = {
      parentId: "",
      page: 1,
      pageSize: 10,
    };
    this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listSurveyGroup = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
        }
      });
  }

  listGroup = [];
  getListSurveyGroup() {
    let query = {
      page: 1,
      pageSize: 100,
    };
    this.surveyService
      .getListSurveyGroup(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listGroup = _.uniqBy([...res.data], "id").map((item: any) => {
            item.display_name = item.parentList.map((anc) => anc.name).length
              ? item.parentList.map((anc) => anc.name).join(" > ") +
                " > " +
                item.name
              : item.name;
            return item;
          });
        }
      });
  }

  styleObject(data): Object {
    if (data) {
      const border = `1px solid ${data.colorCode}`;
      return {
        background: data.backgroundCode,
        border: border,
        color: data.colorCode,
      };
    }
    return {};
  }

  styleCollapse(data): Object {
    if (data) {
      const border = `1px solid ${data.colorCode}`;
      return {
        background: data.backgroundCode,
        border: border,
        color: data.colorCode,
      };
    }
    return {};
  }

  inputDrag: any;
  dragStart(event, item) {
    if (item) {
      this.inputDrag = item;
    }
  }

  drop(event) {
    if (event && this.inputDrag) {
      this.isSubmitted = false;
      const index = this.questions.length;
      const newObject = {
        ...this.inputDrag,
      };
      let key = `${newObject.code}${this.pageCurrent}${index}`;
      this.form
        .get("content")
        ["controls"][this.pageCurrent - 1].get("questions")
        .push(
          this.addQuestionForPage(newObject, this.questions.controls.length)
        );
      this.listPage = new Array((this.form.value as any).content.length);
    }
  }

  createPageFormArray(index?) {
    return this.fb.group({
      index: index || 1,
      questions: new FormArray([]),
    });
  }

  addPageSurvey() {
    if (this.pageCurrent === this.listPage.length) {
      this.pageCurrent += 1;
    }

    (this.form.get("content") as FormArray).push(
      this.createPageFormArray((this.form.controls.content as any).length + 1)
    );
    this.listPage = new Array((this.form.value as any).content.length);
  }

  removePageSurvey() {
    (this.form.get("content") as FormArray).removeAt(this.pageCurrent - 1);
    this.pageCurrent -= 1;
    if (this.pageCurrent < 1) this.pageCurrent = 1;
    this.listPage = new Array(
      (this.form.get("content") as FormArray).controls.length
    );
  }

  addQuestionForPage(item, index) {
    switch (item.code) {
      case "DROPDOWN":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          border: item.border,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      case "OPTIONAL":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      case "BOX":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      case "FILE":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      case "REVIEW":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      case "CONTACT_INFO":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      case "OPTIONAL_IMAGE":
        return this.fb.group({
          index: index + 1,
          code: item.code,
          border: item.border,
          name: `Câu ${index + 1}`,
          colorCode: item.colorCode,
          backgroundCode: item.backgroundCode,
          question: this.fb.group(this.initDataQuestion(item, index)),
        });
      default:
        break;
    }
  }

  initDataQuestion(item, index) {
    switch (item.code) {
      case "DROPDOWN":
        let answersDropdown = new FormArray([]);
        if (item.question && item.question.id) {
          item.question.answers.map((it, i) => {
            it.code = item.code;

            (answersDropdown as FormArray).push(this.initQuestion(it, i));
          });
        } else {
          answersDropdown = new FormArray([
            this.fb.group({
              id: [""],
              content: ["", [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
            this.fb.group({
              id: [""],
              content: ["", [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
          ]);
        }
        return {
          id: [item.question ? item.question.code : ""],
          required: [item.question ? item.question.required : false],
          content: [
            item.question ? item.question.content : "",
            [Validators.required],
          ],
          selectOne: [item.question ? item.question.selectOne : false],
          enableOtherField: [
            item.question ? item.question.enableOtherField : false,
          ],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          enableRecommendations: [
            item.question ? item.question.enableRecommendations : false,
          ],
          recommendations: [item.question ? item.question.recommendations : ""],
          answers: answersDropdown,
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
          existedOtherValue: [
            item.question ? item.question.existedOtherValue : false,
          ],
        };
      case "OPTIONAL":
        let answersOptional = new FormArray([]);
        if (item.question && item.question.id) {
          item.question.answers.map((it, i) => {
            it.code = item.code;


            (answersOptional as FormArray).push(this.initQuestion(it, i));
          });
        } else {
          answersOptional = new FormArray([
            this.fb.group({
              id: [""],
              content: ["", [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
            this.fb.group({
              id: [""],
              content: ["", [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
          ]);
        }
        return {
          id: [item.question ? item.question.id : ""],
          required: [item.question ? item.question.required : false],
          content: [
            item.question ? item.question.content : "",
            [Validators.required],
          ],
          selectOne: [item.question ? item.question.selectOne : false],
          enableOtherField: [
            item.question ? item.question.enableOtherField : false,
          ],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          enableRecommendations: [
            item.question ? item.question.enableRecommendations : false,
          ],
          recommendations: [item.question ? item.question.recommendations : ""],
          answers: answersOptional,
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
          existedOtherValue: [
            item.question ? item.question.existedOtherValue : false,
          ],
          otherValue: [item.question ? item.question.otherValue : ""],
        };
      case "BOX":
        let answersBox = new FormArray([]);
        if (item.question && item.question.id) {
          item.question.body.map((it, i) => {
            it.code = item.code;

            (answersBox as FormArray).push(this.initQuestion(it, i));
          });
        } else {
          answersBox = new FormArray([
            this.fb.group({
              id: [""],
              title: ["", [Validators.required]],
              format: [null, [Validators.required]],
              allowRemove: false,
              index: 1,
            }),
          ]);
        }
        return {
          id: [item.question ? item.question.id : ""],
          content: [
            item.question ? item.question.content : "",
            [Validators.required],
          ],
          required: [item.question ? item.question.required : false],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          enableRecommendations: [
            item.question ? item.question.enableRecommendations : false,
          ],
          recommendations: [item.question ? item.question.recommendations : ""],
          body: answersBox,
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
        };

      case "FILE":
        return {
          id: [item.question ? item.question.id : ""],
          content: [
            item.question ? item.question.content : "",
            [Validators.required],
          ],
          required: [item.question ? item.question.required : false],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          recommendations: [
            item.recommendations ? item.question.enableCommentField : "",
          ],
          format: [["jpg", "png"]],
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
          value: [
            item.question ? item.question.value : [],
          ],
        };
      case "REVIEW":
        let answersReview: any;
        if (item.question && item.question.id) {
          answersReview = {
            config1: new FormArray([
              this.fb.group({
                id: [""],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [""],
                allowRemove: false,
                type: 1,
              }),
              this.fb.group({
                id: [""],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [""],
                allowRemove: false,
                type: 1,
              }),
            ]),
            config2: new FormArray([
              this.fb.group({
                id: [""],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [""],
                allowRemove: false,
                type: 2,
              }),
              this.fb.group({
                id: [""],
                from: [0, [Validators.required]],
                to: [0, [Validators.required]],
                description: [""],
                allowRemove: false,
                type: 2,
              }),
            ]),
            config3: new FormArray([
              this.fb.group({
                id: [""],
                url: [this.listEmotion[2].image, [Validators.required]],
                description: [""],
                allowRemove: false,
                type: 3,
                trueOrFalse: false,
              }),
              this.fb.group({
                id: [""],
                url: [this.listEmotion[3].image, [Validators.required]],
                description: [""],
                allowRemove: false,
                type: 3,
              }),
            ]),
          };

          if (item.question.type === 1) {
            let configArr1 = new FormArray([]);
            item.question.configs.map((item) => {
              (configArr1 as FormArray).push(
                this.fb.group({
                  id: [item.id || ""],
                  from: [item.from || 0, [Validators.required]],
                  to: [item.to || 0, [Validators.required]],
                  description: [item.description || ""],
                  allowRemove: [item.allowRemove || false],
                  type: [item.type || 1],
                })
              );
            });
            answersReview.config1 = configArr1;
          } else if (item.question.type === 2) {
            let configArr2 = new FormArray([]);
            item.question.configs.map((item) => {
              configArr2.push(
                this.fb.group({
                  id: [item.id || ""],
                  from: [item.from || 0, [Validators.required]],
                  to: [item.to || 0, [Validators.required]],
                  description: [item.description || ""],
                  allowRemove: [item.allowRemove || false],
                  type: [item.type || 2],
                })
              );
            });
            answersReview.config2 = configArr2;
          } else {
            let configArr3 = new FormArray([]);
            item.question.configs.map((item) => {
              configArr3.push(
                this.fb.group({
                  id: [item.id || ""],
                  url: [item.url || 0, [Validators.required]],
                  description: [item.description || ""],
                  allowRemove: [item.allowRemove || false],
                  type: [item.type || 3],
                  trueOrFalse: [item.trueOrFalse || false],
                })
              );
            });
            answersReview.config3 = configArr3;
          }
        } else {
          answersReview = {
            config1: new FormArray([
              this.fb.group({
                id: [""],
                from: ["", [Validators.required]],
                to: ["", [Validators.required]],
                description: [""],
                allowRemove: false,
                type: [1],
              }),
              this.fb.group({
                id: [""],
                from: ["", [Validators.required]],
                to: ["", [Validators.required]],
                description: [""],
                allowRemove: false,
                type: [1],
              }),
            ]),
            config2: new FormArray([
              this.fb.group({
                id: [""],
                from: ["", [Validators.required]],
                to: ["", [Validators.required]],
                description: [""],
                allowRemove: false,
                type: [2],
              }),
              this.fb.group({
                id: [""],
                from: ["", [Validators.required]],
                to: ["", [Validators.required]],
                description: [""],
                allowRemove: false,
                type: [2],
              }),
            ]),
            config3: new FormArray([]),
          };
        }
        return {
          id: [item.question ? item.question.id : ""],
          required: [item.question ? item.question.required : false],
          content: [
            item.question ? item.question.content : "",
            [Validators.required],
          ],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          enableOtherField: [
            item.question ? item.question.enableOtherField : false,
          ],
          type: [item.question ? item.question.type : 1, [Validators.required]],
          recommendations: [
            item.recommendations ? item.question.enableCommentField : "",
          ],
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
          ...answersReview,
          numberValue: [item.question ? item.question.numberValue : ""],
          configs: [item.question ? item.question.configs : null],
          description: [item.question ? item.question.description : ""]
        };
      case "CONTACT_INFO":
        let answersInfo = new FormArray([]);
        if (item.question && item.question.id) {
          item.question.fields.map((it, i) => {
            it.code = item.code;

            if (i === 0 || i === 1) {
              it.notHidden = true;
              it.notRequire = false;
            } else {
              if (!it.show) {
                it.notRequire = true;
              }
              it.notHidden = false;
            }
            (answersInfo as FormArray).push(this.initQuestion(it, i));
          });
        } else {
          answersInfo = new FormArray([
            this.fb.group({
              id: [""],
              label: ["Họ và Tên", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [true],
              notRequire: [false],
              index: 1,
            }),
            this.fb.group({
              id: [""],
              label: ["Điện thoại", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [true],
              notRequire: [false],
              index: 2,
            }),
            this.fb.group({
              id: [""],
              label: ["Công ty", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 3,
            }),
            this.fb.group({
              id: [""],
              label: ["Địa chỉ 1", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 4,
            }),
            this.fb.group({
              id: [""],
              label: ["Địa chỉ 2", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 5,
            }),
            this.fb.group({
              id: [""],
              label: ["Thành phố", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 6,
            }),
            this.fb.group({
              id: [""],
              label: ["Mã Zip", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 7,
            }),
            this.fb.group({
              id: [""],
              label: ["Quốc gia", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 8,
            }),
            this.fb.group({
              id: [""],
              label: ["Email", [Validators.required]],
              show: [true],
              required: [false],
              allowRemove: false,
              notHidden: [false],
              notRequire: [false],
              index: 9,
            }),
          ]);
        }
        return {
          id: [item.question ? item.question.id : ""],
          required: [item.question ? item.question.required : false],
          content: [
            item.question ? item.question.content : "",
            [Validators.required],
          ],
          enableRecommendations: [
            item.question ? item.question.enableRecommendations : false,
          ],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          recommendations: [
            item.recommendations ? item.question.enableCommentField : "",
          ],
          body: answersInfo,
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
        };
      case "OPTIONAL_IMAGE":
        let answersImage = new FormArray([]);

        if (item.question && item.question.id) {
          item.question.answers.map((it, i) => {
            var object = Object.assign({}, it);
            it.code = item.code;

            (answersImage as FormArray).push(this.initQuestion(it, i));
          });
        } else {
          answersImage = new FormArray([
            this.fb.group({
              id: [""],
              image: ["", [Validators.required]],
              trueOrFalse: [false],
              content: ["", [Validators.required]],
              allowRemove: false,
            }),
            this.fb.group({
              id: [""],
              image: ["", [Validators.required]],
              content: ["", [Validators.required]],
              trueOrFalse: [false],
              allowRemove: false,
            }),
          ]);
        }
        return {
          id: [item.question ? item.question.id : ""],
          required: [item.question ? item.question.required : false],
          content: [item.question ? item.question.content : ""],
          selectOne: [item.question ? item.question.selectOne : false],
          enableOtherField: [
            item.question ? item.question.enableOtherField : false,
          ],
          enableCommentField: [
            item.question ? item.question.enableCommentField : false,
          ],
          enableRecommendations: [
            item.question ? item.question.enableRecommendations : false,
          ],
          recommendations: [item.question ? item.question.recommendations : ""],
          answers: answersImage,
          feedbackValue: [item.question ? item.question.feedbackValue : ""],
          existedOtherValue: [
            item.question ? item.question.existedOtherValue : false,
          ],
          otherValue: [item.question ? item.question.otherValue : ""],
        };
      default:
        break;
    }
  }

  initQuestion(item, index) {
    switch (item.code) {
      case "DROPDOWN":
        return this.fb.group({
          id: [item.id || ""],
          content: [item.content || "", [Validators.required]],
          trueOrFalse: [item.trueOrFalse || false],
          allowRemove: [item.allowRemove || false],
        }) as any;
      case "OPTIONAL":
        return this.fb.group({
          id: [item.id || ""],
          content: [item.content || "", [Validators.required]],
          trueOrFalse: [item.trueOrFalse || false],
          allowRemove: [item.allowRemove || false],
        });
      case "BOX":
        return this.fb.group({
          id: [item.id || ""],
          title: [item.title || "", [Validators.required]],
          format: [item.format || null, [Validators.required]],
          allowRemove: [item.allowRemove || false],
          index: index + 1,
          answer: [item.answer || ""],
        });

      case "FILE":
        return {};
      case "REVIEW":
        if (item.type === 1) {
          return this.fb.group({
            id: [item.id || ""],
            from: [item.from || "", [Validators.required]],
            to: [item.to || "", [Validators.required]],
            description: [item.description || ""],
            allowRemove: [item.allowRemove || false],
          });
        } else if (item.type === 2) {
          return this.fb.group({
            id: [item.id || ""],
            from: [item.from || "", [Validators.required]],
            to: [item.to || "", [Validators.required]],
            description: [item.description || ""],
            allowRemove: [item.allowRemove || false],
          });
        } else {
          return this.fb.group({
            id: [item.id || ""],
            url: [item.url || "", [Validators.required]],
            description: [item.description || ""],
            allowRemove: false,
          });
        }
      case "CONTACT_INFO":
        return this.fb.group({
          id: [item.id || ""],
          label: [item.label || "", [Validators.required]],
          show: [item.show],
          required: [item.required || false],
          allowRemove: [item.allowRemove || false],
          notHidden: [item.notHidden || false],
          notRequire: [item.notRequire || false],
          index: [index || 0],
          answer: [item.answer || ""],
        });
      case "OPTIONAL_IMAGE":
        return this.fb.group({
          id: [item.id || ""],
          image: [item.image || "", [Validators.required]],
          trueOrFalse: [item.trueOrFalse || false],
          content: [item.content || "", [Validators.required]],
          allowRemove: [item.allowRemove || false],
        });
      default:
        break;
    }
  }

  changePosition(index, type) {
    if (type === "up") {
      if (index === 0) return;

      const stamp = this.questions.controls[index - 1];
      this.questions.controls[index - 1] = this.questions.controls[index];
      this.questions.controls[index] = stamp;
      this.genderTittle();
    } else {
      if (index === this.questions.controls.length - 1) return;
      const stamp = this.questions.controls[index + 1];
      this.questions.controls[index + 1] = this.questions.controls[index];
      this.questions.controls[index] = stamp;
      this.genderTittle();
    }


  }

  genderTittle() {
    this.questions.controls.map((item, index) => {
      this.questions.at(index).patchValue({
        name: `Câu ${index + 1}`,
        index: index + 1,
      });
    });
  }

  allowDrop(event) {
    event.preventDefault();
  }

  removeQuestion(item, index) {
    this.questions.removeAt(index);
    this.genderTittle();
  }

  addAnswer(item, i) {
    switch (item.code) {
      case "DROPDOWN":
        this.questions.controls[i].get("question").controls.answers.push(
          this.fb.group({
            content: ["", [Validators.required]],
            trueOrFalse: [false],
            allowRemove: true,
            index:
              this.questions.controls[i].get("question").controls.answers
                .length,
          })
        );
        break;
      case "OPTIONAL":
        this.questions.controls[i].get("question").controls.answers.push(
          this.fb.group({
            content: ["", [Validators.required]],
            trueOrFalse: [false],
            allowRemove: true,
            index:
              this.questions.controls[i].get("question").controls.answers
                .length,
          })
        );
        break;
      case "BOX":
        this.questions.controls[i].get("question").controls.body.push(
          this.fb.group({
            title: ["", [Validators.required]],
            format: [null, [Validators.required]],
            allowRemove: true,
            index:
              this.questions.controls[i].get("question").controls.body.controls
                .length + 1,
          })
        );
        break;
      case "REVIEW":
        if (
          this.questions.controls[i].get("question").controls.type.value === 1
        ) {
          (
            this.questions.controls[i].get("question").controls
              .config1 as FormArray
          ).push(
            this.fb.group({
              from: ["", [Validators.required]],
              to: ["", [Validators.required]],
              description: [""],
              allowRemove: true,
            })
          );
        } else if (
          this.questions.controls[i].get("question").controls.type.value === 2
        ) {
          this.questions.controls[i].get("question").controls.config2.push(
            this.fb.group({
              from: ["", [Validators.required]],
              to: ["", [Validators.required]],
              description: [""],
              allowRemove: true,
            })
          );
        } else {
          this.questions.controls[i].get("question").controls.config3.push(
            this.fb.group({
              id: ["/assets/emotion/em-3.png", [Validators.required]],
              description: [""],
              allowRemove: true,
            })
          );
        }

        break;
      case "FILE":
        break;
      case "CONTACT_INFO":
        this.questions.controls[i].get("question").controls.body.push(
          this.fb.group({
            label: [""],
            show: [false],
            required: [false],
            allowRemove: true,
            index:
              this.questions.controls[i].get("question").controls.body.controls
                .length + 1,
          })
        );
        break;
      case "OPTIONAL_IMAGE":
        this.questions.controls[i].get("question").controls.answers.push(
          this.fb.group({
            image: ["", [Validators.required]],
            trueOrFalse: [false],
            content: ["", [Validators.required]],
            allowRemove: true,
          })
        );
        break;
      default:
        break;
    }
  }

  onExpandChange(e: NzFormatEmitEvent): void {
    const node = e.node;
    if (
      node &&
      node.getChildren().length === 0 &&
      node.isExpanded &&
      node.origin &&
      node.origin.id
    ) {
      this.loadChildSurveyGroup(node.origin.id).subscribe((res: any) => {
        if (res.code === 1) {
          let childData = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
          if (childData && childData.length) {
            node.addChildren(childData);
          } else {
            node.isLeaf = true;
          }
        }
      });
    }
  }

  loadChildSurveyGroup(parentId: string): Observable<NzTreeNodeOptions[]> {
    let query = {
      parentId,
      page: 1,
      pageSize: 10,
    };
    return this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$));
  }

  changeShow(event, i, idx) {
    if (event) {
      this.questions.controls[i].controls.question.controls.body.controls[idx]
        .get("notRequire")
        .setValue(false);

      this.questions.controls[i]
        .get("question")
        .controls.body.controls[idx].controls.label.setValidators(
          Validators.required
        );
    } else {
      this.questions.controls[i].controls.question.controls.body.controls[idx]
        .get("notRequire")
        .setValue(true);
      this.questions.controls[i].controls.question.controls.body.controls[idx]
        .get("required")
        .setValue(false);
      this.questions.controls[i]
        .get("question")
        .controls.body.controls[idx].controls.label.clearValidators();
    }
    this.form.updateValueAndValidity();
  }

  removeAnswer(item, i, idx) {
    if (item.code === "REVIEW") {
      if (
        this.questions.controls[i].get("question").controls.type.value === 1
      ) {
        this.questions.controls[i]
          .get("question")
          .controls.config1.removeAt(idx);
      } else if (
        this.questions.controls[i].get("question").controls.type.value === 2
      ) {
        this.questions.controls[i]
          .get("question")
          .controls.config2.removeAt(idx);
      } else {
        this.questions.controls[i]
          .get("question")
          .controls.config3.removeAt(idx);
      }
    } else if (item.code === "BOX" || item.code === "CONTACT_INFO") {
      this.questions.controls[i].get("question").controls.body.removeAt(idx);
    } else {
      this.questions.controls[i].get("question").controls.answers.removeAt(idx);
    }
  }

  radioChange(event, index) {
    this.isSubmitted = false;
    if (event === 1) {
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config1
        .controls as any) {
        element.controls.from.setValidators([Validators.required]);
        element.controls.from.updateValueAndValidity();
        element.controls.to.setValidators([Validators.required]);
        element.controls.to.updateValueAndValidity();
      }
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config2
        .controls as any) {
        element.controls.from.clearValidators();
        element.controls.from.updateValueAndValidity();
        element.controls.to.clearValidators();
        element.controls.to.updateValueAndValidity();
      }
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config3
        .controls as any) {
        element.controls.url.clearValidators();
        element.controls.url.updateValueAndValidity();
        // element.controls.to.clearValidators()
      }
    } else if (event === 2) {
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config1
        .controls as any) {
        element.controls.from.clearValidators();
        element.controls.from.updateValueAndValidity();
        element.controls.to.clearValidators();
        element.controls.to.updateValueAndValidity();
      }
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config2
        .controls as any) {
        element.controls.from.setValidators([Validators.required]);
        element.controls.from.updateValueAndValidity();
        element.controls.to.setValidators([Validators.required]);
        element.controls.to.updateValueAndValidity();
      }
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config3
        .controls as any) {
        element.controls.url.clearValidators();
        element.controls.url.updateValueAndValidity();
        // element.controls.to.clearValidators()
      }
    } else {
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config1
        .controls as any) {
        element.controls.from.clearValidators();
        element.controls.from.updateValueAndValidity();
        element.controls.to.clearValidators();
        element.controls.to.updateValueAndValidity();
      }
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config2
        .controls as any) {
        element.controls.from.setValidators([Validators.required]);
        element.controls.from.updateValueAndValidity();
        element.controls.to.setValidators([Validators.required]);
        element.controls.to.updateValueAndValidity();
      }
      for (const element of (this.form.controls.content as any).controls[
        this.pageCurrent - 1
      ].controls.questions.controls[index].controls.question.controls.config3
        .controls as any) {
        element.controls.url.setValidators([Validators.required]);
        element.controls.url.updateValueAndValidity();
        // element.controls.to.clearValidators()
      }
    }
  }

  openUploadDialog(field: string, i, idx) {
    const fileType = "jpg,jpeg,png,gif";
    const modal = this.modal.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === "success") {
          if (field === "avatar") {

            console.log(
              this.questions.controls[i].get("question").controls.answers
                .controls[idx]
            );
            this.questions.controls[i]
              .get("question")
              .controls.answers.controls[idx].get("image")
              .setValue(changeParam.file.response.url);
          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }
  idxHover = -1;
  genStyleImage(data) {


    if (data.image) {
      return { backgroundImage: `url(${data.image})`, border: "none" };
    }
  }

  removeImage(event, index) {

    this.listAnswerImage[index].value = "";
  }

  addEmotion(item, index) {
    this.questions.controls[index].get("question").controls.config3.push(
      this.fb.group({
        url: [item.image, [Validators.required]],
        description: [""],
        allowRemove: true,
      })
    );
  }

  isSubmitted = false;
  loadingSubmit: boolean = false;

  changeRequireQs(index) {
    if (this.questions.controls[index].value.code === "CONTACT_INFO") {
      this.questions.controls[index].controls.question.controls.body.controls[0]
        .get("required")
        .setValue(true);
      this.questions.controls[index].controls.question.controls.body.controls[0]
        .get("notRequire")
        .setValue(true);
      this.questions.controls[index].controls.question.controls.body.controls[1]
        .get("required")
        .setValue(true);
      this.questions.controls[index].controls.question.controls.body.controls[1]
        .get("notRequire")
        .setValue(true);
    }
  }

  changeOptionalCorrect(index, i) {}

  changeCorrect(event, index, i) {
    if (event) {
      for (const [idx, e] of this.questions.controls[index]
        .get("question")
        .controls.answers.controls.entries()) {
        if (
          idx !== i &&
          this.questions.controls[index].get("question").controls.selectOne
            .value
        ) {

          e.get("trueOrFalse").setValue(false);
        }
      }
    }
  }

  changeOptionSelect(event, index) {
    if (event) {
      for (const [idx, e] of this.questions.controls[index]
        .get("question")
        .controls.answers.controls.entries()) {
        e.get("trueOrFalse").setValue(false);
      }
    }
  }

  nextPage() {
    this.pageCurrent += 1;
  }

  prevPage() {
    this.pageCurrent -= 1;
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  changeCurrentPage(val: any) {


    if (this.pageCurrent === 1 && val < 0) {
      return;
    }
    this.pageCurrent += val;

  }
}
