import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  base64toBlob,
  configCommon,
  convertDateToStringQuery,
  convertTimeToStringQuery,
  isIncludeBase64Image
} from 'src/app/helpers';
import {SurveyService, UploadService} from 'src/app/services';
import { UploaderComponent } from 'src/app/shared';
import {regexBase64Image} from "../../../../helpers/constant";

@Component({
  selector: 'app-survey-setting',
  templateUrl: './survey-setting.component.html',
  styleUrls: ['./survey-setting.component.scss'],
})
export class SurveySettingComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  listBtnColors: Array<string> = [
    '#000000',
    '#EF6D46',
    '#8FC743',
    '#F390BC',
    '#83D1D5',
    '#6CB5E1',
    '#415CA0',
    '#7E62A1',
  ];
  form: FormGroup = this.fb.group({
    introducePage: this.fb.group({
      description: [''],
      image: [''],
      buttonName: [''],
      imageCode: [this.listBtnColors[0] || ''],
    }),
    theEndPage: this.fb.group({
      description: [''],
      image: [''],
      buttonName: [''],
      url: [''],
      imageCode: [this.listBtnColors[0] || ''],
    }),
    commonConfig: this.fb.group({
      startTime: [null],
      endTime: [null],
      startDate: [null],
      endDate: [null],
      limitSurvey: [null],
      limitType: [0],
    }),
  });
  config = configCommon;

  startImages: Array<any> = [];
  endImages: Array<any> = [];
  listSetting: Array<any> = [
    {
      id: 1,
      label: 'Trang giới thiệu',
      key: 'introducePage'
    },
    {
      id: 2,
      label: 'Trang kết thúc',
      key: 'theEndPage'
    },
    {
      id: 3,
      label: 'Cấu hình chung',
      key: 'commonConfig'
    },
  ];
  currentSetting: any = null;
  dateFormat: 'dd-MM-yyyy';
  id: any;

  constructor(private fb: FormBuilder, private modalService: NzModalService,
              private surveyService: SurveyService,
              private notification: NzNotificationService,
              private uploadService: UploadService,
              private sanitizer: DomSanitizer,
              private router: Router) { }

  ngOnInit() {

    this.id = window.location.href.split('/')[5];


    if (this.id !== 'null') {
      this.surveyService.detailSurveyConfig(this.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {

            if (res) {
              let startDate, endDate, startDateConverted, endDateConverted;
              if (res.commonConfig && res.commonConfig.startDate) {
                startDate = res.commonConfig.startDate.split('/');
                startDateConverted = [
                  startDate[1],
                  startDate[0],
                  startDate[2],
                ].join('/');
              }

              if (res.commonConfig && res.commonConfig.endDate) {
                endDate = res.commonConfig.endDate.split('/');
                endDateConverted = [
                  endDate[1],
                  endDate[0],
                  endDate[2],
                ].join('/');
              }


              this.form.patchValue({
                commonConfig: res.commonConfig ? {
                  ...res.commonConfig,
                  startDate: startDateConverted || '',
                  endDate: endDateConverted || ''
                } : {},
                introducePage: res.introducePage ? {
                  ...res.introducePage,
                  imageCode: res.introducePage.imageCode || this.listBtnColors[1]
                } : {},
                theEndPage: res.theEndPage ? {
                  ...res.theEndPage,
                  imageCode: res.theEndPage.imageCode || this.listBtnColors[1]
                } : {},
              });

              if (res.introducePage && res.introducePage.image) {
                this.startImages = [{url: res.introducePage.image}];


              }
              if (res.theEndPage && res.theEndPage.image) {
                this.endImages = [{url: res.theEndPage.image}];

              }
            }
          }
        );
    } else {
      this.form.patchValue({
        introducePage: {
          ...this.form.value.introducePage,
          imageCode: this.listBtnColors[1]
        },
        theEndPage: {
          ...this.form.value.theEndPage,
          imageCode: this.listBtnColors[1]
        },
      });
    }




    this.currentSetting = { ...this.listSetting[0] };
  }

  createButtonStart(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      color: ['', [Validators.required]],
    });
  }

  addButtonStart() {
    const buttonStartFormArray = this.form.get('introducePage.buttons') as FormArray;
    buttonStartFormArray.push(this.createButtonStart());
  }

  removeButtonStart(index: number) {
    const buttonStartFormArray = this.form.get('introducePage.buttons') as FormArray;
    buttonStartFormArray.removeAt(index);
  }

  createButtonEnd(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      url: ['', [Validators.required]],
      color: ['', [Validators.required]],
    });
  }

  addButtonEnd() {
    const buttonEndFormArray = this.form.get('theEndPage.buttons') as FormArray;
    buttonEndFormArray.push(this.createButtonEnd());
  }

  removeButtonEnd(index: number) {
    const buttonEndFormArray = this.form.get('theEndPage.buttons') as FormArray;
    buttonEndFormArray.removeAt(index);
  }

  onSelectColorBtnStart(color: string) {
    this.form.get('introducePage.imageCode').setValue(color);
  }

  onSelectColorBtnEnd(color: string) {
    this.form.get('theEndPage.imageCode').setValue(color);
  }

  openUploadDialogStartImages(title: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${title}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam: any) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.form.get('introducePage.image').setValue(data.url);
          this.startImages[0] = { ...data };
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe((changeParam) => {
          modal.close();
        });
    });
  }

  openUploadDialogEndImages(title: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${title}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam: any) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.form.get('theEndPage.image').setValue(data.url);
          this.endImages[0] = { ...data };
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe((changeParam) => {
          modal.close();
        });
    });
  }

  onRemoveFileStartImages() {
    this.startImages = [];
    this.form.get('introducePage.image').setValue('');
  }

  onRemoveFileEndImages() {
    this.endImages = [];
    this.form.get('theEndPage.image').setValue('');
  }

  onSelectSetting(item: any) {
    this.currentSetting = item;
  }

  getDate(date, number) {
    const today = new Date(date);


    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    const ss = today.getMilliseconds();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (number === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute + ':' + ss;
  }

  async submitForm() {


    for (const [key, value] of Object.entries(this.form.value)) {


      if (this.form.controls[key].status === 'INVALID') {


        this.currentSetting = this.listSetting.find(item => item.key === key);
        break;
      }
    }
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);



    const commonConfig = {
      ...this.form.value.commonConfig,
      startDate: this.form.value.commonConfig.startDate ? this.getDate(this.form.value.commonConfig.startDate, 1) : '',
      endDate: this.form.value.commonConfig.endDate ? this.getDate(this.form.value.commonConfig.endDate, 1) : '',
    };
    const body = {
      // ...this.form.value,
      introducePage: {...this.form.get('introducePage').value, description: this.form.get('introducePage.description').value},
      theEndPage: {...this.form.get('theEndPage').value, description: this.form.get('theEndPage.description').value},
      commonConfig
    };
    if (this.form.invalid) { return; }

    if (isIncludeBase64Image(body.introducePage.description)) {
      let tmpIntroducePageDescription = body.introducePage.description
      const matchedValues = tmpIntroducePageDescription.match(regexBase64Image);
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < matchedValues.length; j++) {
        try {
          const url = await this.convertBase64ToUrl(matchedValues[j]);
          tmpIntroducePageDescription = tmpIntroducePageDescription.replace(matchedValues[j], url);
        } catch (e) {
          tmpIntroducePageDescription = tmpIntroducePageDescription.replace(matchedValues[j], '');
        }
      }
      body.introducePage.description = tmpIntroducePageDescription
    }
    if (isIncludeBase64Image(body.theEndPage.description)) {
      let tmpEndPageDescription = body.theEndPage.description
      const matchedValues = tmpEndPageDescription.match(regexBase64Image);
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < matchedValues.length; j++) {
        try {
          const url = await this.convertBase64ToUrl(matchedValues[j]);
          tmpEndPageDescription = tmpEndPageDescription.replace(matchedValues[j], url);
        } catch (e) {
          tmpEndPageDescription = tmpEndPageDescription.replace(matchedValues[j], '');
        }
      }
      body.theEndPage.description = tmpEndPageDescription
    }

    this.surveyService
      .updateSurveyConfig(this.id, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          this.openMessage('success', 'Thành công', 'Cập nhật cấu hình khảo sát thành công');
          let delayTimer: any;
          clearTimeout(delayTimer);
          delayTimer = setTimeout(() => {
            this.router.navigate(['/portal/survey']);
            return;
          }, 500);
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      }),
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      };
  }


  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  back() { }

  convertBase64ToUrl(base64): any {
    const formData = new FormData();
    formData.append('content', base64toBlob(base64), 'image-product-description.png');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.uploadService.uploadFile(formData, currentUser).toPromise().then(
      (result) => {
        if (result.status === 200 && result.data && result.data.url) {
          return result.data.url;
        }
      }
    );
  }
}
