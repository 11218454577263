export const environment = {
  production: true,
  isDev: true,
  firebase: {
    apiKey: 'AIzaSyAU5JdhI3Ogje76O8jqRmTRUuY3rz1YQig',
    authDomain: 'icheck-dev-d9f70.firebaseapp.com',
    databaseURL: 'https://icheck-dev-d9f70.firebaseio.com',
    projectId: 'icheck-dev-d9f70',
    storageBucket: 'icheck-dev-d9f70.appspot.com',
    messagingSenderId: '738064619121',
    appId: '1:738064619121:web:89cb54bce980de57',
  },
  oidc: {
    authority: 'https://accounts.icheck.vn',
    clientId: '8406fbfa-babd-4ba5-abd1-8209e9d10a1a',
    responseType: 'id_token token',
    scope: 'openid offline',
  },
  uploadStream: 'https://upload.icheck.com.vn/upload/stream',
  // uploadStream: 'https://up.icheck.com.vn/upload/stream',
  // apiBaseUrl: "https://marketing.icheck.vn/qr-mkt/api/v1",
  apiBaseUrl: 'https://qrmkt-api.dev.icheck.vn/qr-mkt/api/v1',
  apiBase2Url: 'https://api.dev.icheck.vn/api/v1',
  loyaltyUrl: 'https://loyalty.dev.icheck.vn/loyalty/campaigns',
  // apiBase2Url: "https://api.icheck.com.vn/api/v1",
  uploadBaseUrl: 'https://upload.icheck.vn',
  uploadBaseUrl2: 'https://apis.icheck.vn/upload/image/v1',
  uploadImageBaseUrl: 'https://apis.icheck.vn/upload/image/v1/raw',
  url_image: 'http://ucontent.icheck.vn',
  url_chat: 'https://chatapi.dev.icheck.vn',
  url_image_old: 'https://static.icheck.com.vn',
  url_socketio: 'https://realtime-logging.dev.icheck.vn',
  goong: 'https://rsapi.goong.io',
  api_key_goong: 'vMM2QATcZwsZVUtu0UM201n4RR6qVo58w4QjXFMq',
  url_socketio_qrmkt: 'ws://qrmkt-ws.dev.icheck.vn/qr-mkt',
  tracking_id: '0be73ec5-10d4-4be6-a5ab-86ec39bb25db',
  callback_url_payment: 'https://qrmkt.dev.icheck.vn/portal/package-history',
  TEKO_MARKET_CLIENT_ID: '595cf988557f47eba66f8ed420179616',
  TEKO_MARKET_CALLBACK_URL: 'http://localhost:3000',
  TEKO_MARKET_OAUTH_DOMAIN: 'https://oauth-marketplace.dev.tekoapis.net',
  TEKO_MARKET_URL: 'https://saas-marketplace.dev.teko.vn',
  apiAppBase: 'https://appbase-api-v2.dev.icheck.vn',
  redirectScanUrl: 'http://test-redirect.dev.icheck.vn/n',
  redirectScanUrlVietThai: 'http://ld.vietthaichocolate.com.vn/n',
  endUser: 'https://appbase-user.dev.icheck.vn',
  // start api loyalty
  apiLoyalty: 'https://qrmkt-api.dev.icheck.vn/qr-mkt/api/v2/loyalty/cms',
  apiPublicLoyalty: 'https://loyalty-api.icheck.com.vn/api/business/public/loyalty',
  urlWebapp: 'http://webapp-loyalty.dev.icheck.vn/',
  cmsLoyalty: 'http://loyalty-cms-v2.dev.icheck.vn',
  loyaltyCampaignUrlScan: 'https://webapp-loyalty.dev.icheck.vn/campaign',
  googleAnalyticId: 'G-C9SJWXELV6'
  // end api loyalty
};
