import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductRelatedsService, ProductsService } from 'src/app/services';

@Component({
  selector: 'app-list-related-product',
  templateUrl: './list-related-product.component.html',
  styleUrls: ['./list-related-product.component.scss']
})
export class ListRelatedProductComponent implements OnInit {



  @Input() currentProductId;
  @Output() listRelatedProduct = new EventEmitter<any>();

  productId;
  // tslint:disable-next-line:variable-name
  related_type: number;
  unsubscribe$ = new Subject<void>();
  radioValue = '';
  products = [];
  loading = false;
  totalProducts: number;
  name = '';
  barcode = '';
  pageIndex = 1;
  pageSize = 4;
  query;
  // tslint:disable-next-line:variable-name
  list_id = [];
  changeAction;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  // @ts-ignore
  @ViewChild('deleteProductRelateds') deleteProductRelateds;
  // @ts-ignore
  @ViewChild('addProductRelated') addProductRelated;
  imageFalse = [];
  showProductRelated: boolean;
  constructor(
    private notification: NzNotificationService,
    private productRelatedsService: ProductRelatedsService,
    private productService: ProductsService
  ) {
  }

  ngOnInit() {
    this.showProductRelated = false;
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
    // tslint:disable-next-line:variable-name
    this.productId = window.location.href.split('/')[5];
    this.loadData();
    // this.productRelatedsService.getType(this.productId).pipe(takeUntil(this.unsubscribe$)).subscribe(
    //   res => {
    //     if (res.code === 1) {
    //       const type = res.data.extraInfo.relatedType;
    //       if (type === 1) {
    //         this.radioValue = 'A';
    //       }
    //       if (type === 2) {
    //         this.radioValue = 'B';
    //       }
    //       if (type === 3) {
    //         this.radioValue = 'C';

    //       }
    //     } else {
    //       this.notification.error(
    //         'Lấy thông tin sản phẩm liên quan thất bại',
    //         res.message
    //       );
    //     }
    //   },
    //   error => {
    //     this.notification.error(
    //       'Lấy thông tin sản phẩm liên quan thất bại',
    //       'Có lỗi xảy ra'
    //     );
    //   }
    // );
  }

  checked = false;
  indeterminate = false;
  listOfData: [];
  listOfCurrentPageData:  [];
  setOfCheckedId = new Set<number>();
  onCurrentPageDataChange(listOfCurrentPageData): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
    // this.refreshStatus();
  }


  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
    this.list_id = []
    for (let item of this.setOfCheckedId) {
      this.list_id.push(item);
    }
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.filter(({ disabled }) => !disabled).forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }


  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }


  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);


    this.refreshCheckedStatus();
  }

  loadData() {
    this.loading = true;
    this.query = {
      page: this.pageIndex,
      pageSize: 100,
      // name: this.name,
      // barcode: this.barcode,
      // product_id: this.productId
    };
    this.productService.getAll(this.query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.showProductRelated = true;
        this.loading = false;
        if (res.code === 1) {
          this.products = res.data;
          this.totalProducts = res.totalElement;
          this.listRelatedProduct.emit(this.products)
          // this.refreshStatus();
        } else {
          this.notification.error(
            'Lấy danh sách sản phẩm liên quan thất bại',
            res.message
          );
        }
      },
      error => {
        this.loading = false;
        this.notification.error(
          'Lấy danh sách sản phẩm liên quan thất bại',
          'Hệ thống đang nâng cấp.Vui lòng thử lại sau'
        );
      }
    );
  }

  search() {
    this.pageIndex = 1;
    this.loadData();
  }

  reset() {
    this.pageIndex = 1;
    this.name = '';
    this.barcode = '';
    this.loadData();
  }
  showAddProductRelated() {
    this.addProductRelated.showModal(this.productId);
  }
  successAddProductRelated(event) {
    if (event === 1) {
      this.reset();
      this.addProductRelated.handleCancel();
    }
  }
  deleteSetting(id) {
    const data = {
      label: 'Xác nhận xóa sản phẩm liên quan',
      value: ' <p class="mrb-10">Hệ thống xóa sản phẩm liên quan này.</p>\n' +
        '          <p>Bạn có chắc chắn muốn xoá?</p>'
    };
    this.deleteProductRelateds.showModal(data, id);
  }

  handleDeleteRelateds(event) {
    const body = {
      body:
        event
    };
    this.productRelatedsService.deleteProductRelated(this.productId, body).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.deleteProductRelateds.success();
        if (res.code === 1) {
         this.notification.success(
           'Thành công',
           'Xóa sản phẩm liên quan thành công'
         );
         this.mapOfCheckedId = {};
         this.list_id = [];
         this.deleteProductRelateds.handleCancel();
         this.loadData();
         return;
       }
       this.notification.error(
         'Xóa sản phẩm liên quan thất bại',
         res.message
       );
      },
      error => {
        this.deleteProductRelateds.success();
        this.notification.error(
          'Xóa sản phẩm liên quan thất bại',
          'Hệ thống đang nâng cấp.Vui lòng thử lại sau'
        );
      }
    );
  }

  buttonSubmit() {
    const data = {
      label: 'Xác nhận xóa các sản phẩm liên quan',
      value: ' <p class="mrb-10">Hệ thống xóa các sản  phẩm liên quan này.</p>\n' +
        '          <p>Bạn có chắc chắn muốn xoá?</p>'
    };
    this.deleteProductRelateds.showModal(data, this.list_id);
  }

  checkAll(value: boolean) {
    this.products.forEach(item => (this.mapOfCheckedId[item.productId] = value));
    this.refreshStatus();
  }

  refreshStatus() {
    if (this.products.length > 0) {
      this.isAllDisplayDataChecked = this.products.every(item => this.mapOfCheckedId[item.productId]);
    } else {
      this.isAllDisplayDataChecked = false;
    }
    this.isIndeterminate = this.products.some(item => this.mapOfCheckedId[item.productId]) && !this.isAllDisplayDataChecked;
    for (const key in this.mapOfCheckedId) {
      if (this.mapOfCheckedId[key] === true) {
        if (!this.list_id.includes(key)) {
          this.list_id.push(key);
        }
      } else {
        if (this.list_id.includes(key)) {
          this.list_id.splice(this.list_id.indexOf(key), 1);
        }
      }
    }
  }
  /*Check link image error*/
  checkImageErr(imageError) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink) {
    if (!this.imageFalse.includes(id)) {
      // @ts-ignore
      window.open(imageLink, '_blank');
    }
  }

}
