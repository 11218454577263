import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductsReviewsService, ProductsService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { UploaderComponent } from 'src/app/shared/uploader';
import { removeEmptyQuery, sleep } from 'src/app/helpers';

@Component({
  selector: 'app-product-review-list',
  templateUrl: './product-review-list.component.html',
  styleUrls: ['./product-review-list.component.scss']
})
export class ProductReviewListComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject<void>();

  constructor(
    private reviewService: ProductsReviewsService,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private productService: ProductsService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  @Input() data: any;
  productId: string;
  managementStatus: number;
  roleCode: string = null;
  ngOnInit() {
    this.productId = window.location.href.split('/')[5];
    this.reviewId = this.data.id;
    this.loadComment();

    this.productService.getById(this.productId).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        if (res.code === 1) {
          this.managementStatus = res.data.managementStatus;
          this.roleCode = res.data.roleCode;
          let body: any = {
            managementStatus: this.managementStatus,
            roleCode: this.roleCode
          }
          this.productService.getManagementStatus(body);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  pageIndexComment = 1;
  pageSizeComment = 1;
  totalComment: any;
  loading: Boolean = true;
  hasMoreComment: any;
  hasCollapseComment: any;
  comments: any[] = [];
  reviewId: any;
  loadComment() {
    const query: any = {
      offset: (this.pageIndexComment - 1) * this.pageSizeComment,
      limit: this.pageSizeComment,
    }

    removeEmptyQuery(query);

    this.loading = true;
    this.reviewService.getCommentById(this.reviewId, query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.loading = false;
        this.totalComment = res.count;
        this.comments = res.rows;

        if (this.comments.length === this.totalComment || this.totalComment === 1) {
          this.hasMoreComment = false;
        } else {
          this.hasMoreComment = true;
        }

        if (this.comments.length <= 1) {
          this.hasCollapseComment = false;
        } else {
          this.hasCollapseComment = true;
        }

      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        return;
      },
    );
  }

  loadMore(type: string) {
    if (type === 'more') {
      this.pageSizeComment = this.pageSizeComment + 5;
      this.loadComment();
      return;
    }
    if (type === 'collapse') {
      this.pageSizeComment = this.pageSizeComment - 5;
      if (this.pageSizeComment < 1) {
        this.pageSizeComment = 1;
      };
      this.loadComment();
      return;
    }
  }

  isLoadingBtn: Boolean = false;

  @ViewChild('hiddenReview', { static: true }) hiddenReview;
  hidden(data: any) {
    this.isLoadingBtn = true;
    this.reviewService.hidden(data.review_id, data.is_hidden, this.productId, data.description).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.isLoadingBtn = false;
        if (res.code === 1 && data.is_hidden == true) {
          this.openMessage('success', 'Thành công', 'Gửi yêu cầu ẩn đánh giá thành công');
          this.loadData();
        }
        if (res.code === 1 && data.is_hidden == false) {
          this.openMessage('success', 'Thành công', 'Hiện đánh giá thành công');
          this.loadData();
        }
        this.hiddenReview.handleCancel();

        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          return;
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        this.isLoadingBtn = false;
        return;
      },
    );
  }

  status: Boolean = true;
  isLoadingBtn1: Boolean = false;
  pinned(id: any, isPinned: Boolean) {
    this.isLoadingBtn1 = true;
    this.reviewService.pinned(id, isPinned, this.productId).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.isLoadingBtn1 = false;
        if (res.code === 1 && isPinned == true) {
          this.openMessage('success', 'Thành công', 'Ghim đánh giá thành công');
          this.reviewService.getStatus(this.status);
          return;
        }
        if (res.code === 1 && isPinned == false) {
          this.openMessage('success', 'Thành công', 'Bỏ ghim đánh giá thành công');
          this.reviewService.getStatus(this.status);
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          return;
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        this.isLoadingBtn1 = false;
      },
    );
  }

  isShow: Boolean = false;
  isScroll: Boolean = false;
  cmt: string;
  comment(id: any) {
    let body: any = {
      message: this.cmt ? this.cmt.trim() : null,
      images: this.images.map(image => image.id),
      reviewId: id
    };
    this.reviewService.comment(this.productId, body).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        if (res.code === 1) {
          this.cmt = null;
          this.images = [];
          this.isShow = false;
          this.loadComment();
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          return;
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        return;
      },
    );
  }

  images = [];
  openUploadDialog(field: string) {
    let fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modal.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          if (field === 'images') {
            this.images.push({
              id: changeParam.file.response.id,
              name: changeParam.file.name,
              type: changeParam.file.type,
              url: changeParam.file.response.url,
            });
            if (this.images.length > 5) {
              this.isScroll = true;
            }
            this.images.map(image => image.id);
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        modal.close();
      });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'images') {
      this.images.splice(index, 1);
      this.images.map(image => image.id);
    }
  }

  pageIndex = 1;
  pageSize = 100;
  @Input() average_point_from: number;
  @Input() average_point_to: number;
  sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async loadData(ms = 0) {
    const query: any = {
      product_id: this.productId,
      average_point_from: this.average_point_from ? this.average_point_from : null,
      average_point_to: this.average_point_to ? this.average_point_to : null,
      offset: (this.pageIndex - 1) * this.pageSize,
      limit: this.pageSize,
    }

    removeEmptyQuery(query);
    await sleep(ms);

    this.reviewService.getAll(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        let newReview = res.rows.filter((data) => {
          if (data.id === this.data.id) {
            return data;
          }
        });
        this.data = newReview[0];
        
        if (res.code === 1008) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        return;
      },
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
