import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/services';

@Component({
  selector: 'app-template-eight',
  templateUrl: './template-eight.component.html',
  styleUrls: ['./template-eight.component.scss']
})
export class TemplateEightComponent implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  targetDetail: any = null;
  listProduct: any = [];
  listRelatedTarget: any = [];

  constructor(private companiesService: CompaniesService) {}

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      this.data = { ...val };
      if (val.targetId) {
        this.getDetailTargetById(val.targetId);
        this.getListProduct(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 1) {
        this.getRelatedTarget(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 2) {
        this.listRelatedTarget = val.listRelatedTarget ? [...val.listRelatedTarget] : [];
      }
    });
  }

  getDetailTargetById(id: any) {
    this.companiesService
      .getDetailCompanyById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.targetDetail = res.data ? { ...res.data } : null;
          this.targetDetail.fullAddress = [
            res.data.address,
            res.data.wardName,
            res.data.districtName,
            res.data.provinceName,
            res.data.countryName,
          ]
            .filter((e) => typeof e === 'string' && e !== '')
            .join(', ');
        }
      });
  }

  getListProduct(id: any) {
    const query = {
      businessId: id,
      page: 1,
      pageSize: 4,
    };
    this.companiesService
      .getListProducts(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listProduct = res.data
            ? res.data.map((gg: any) => {
                const tmp = { ...gg };
                tmp._price = this.numberWithCommas(gg.price);
                tmp._toPrice = this.numberWithCommas(gg.toPrice);
                return tmp;
              })
            : [];
        }
      });
  }

  getRelatedTarget(id: any) {
    const query = {
      page: 1,
      page_size: 7,
    };
    this.companiesService
      .getList(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          if (res.data) {
            res.data.forEach((gg: any) => {
              if (gg.businessId !== id) {
                gg.fullAddress = [
                  gg.address,
                  gg.provinceName
                ]
                  .filter((e) => typeof e === 'string' && e !== '')
                  .join(', ');

                this.listRelatedTarget.push(gg);
              }
            });
          }
        }
      });
  }

  numberWithCommas(x: any) {
    if (x) {
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }
}
