import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
  ) { }

  canActivate() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser) {
      this.goToQrCodeFree();
      return false;
    }
    return true;
  }

  goToQrCodeFree() {
    const originalHost = location.origin;
    const a = document.createElement('a');
    a.href = originalHost + '/qrcode-free/generate/TEXT';
    a.click();
  }
}
