import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-businesses-button-promotion',
  templateUrl: './businesses-button-promotion.component.html',
  styleUrls: ['./businesses-button-promotion.component.scss']
})
export class BusinessesButtonPromotionComponent implements OnInit, OnChanges {

  @Input() stampSetting;
  @Input() background;
  @Input() borderRadius;
  @Input() color;
  @Input() trans;

  setting;
  backgroundBtn;
  colorBtn;
  borderRadiusBtn;

  constructor() {
  }

  ngOnInit() {
  }

  onOpenPromotion(link) {
    window.open(link, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setting = this.stampSetting;
    this.backgroundBtn = this.background || '#4265ED';
    this.colorBtn = this.color || '#FFFFFF';
    this.borderRadiusBtn = (this.borderRadius || '16px') + ' !important';
  }

}
