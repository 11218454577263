import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService extends BaseService {
  getListSurveys(query?: any): Observable<any> {
    return this.get<any>('/survey', { params: query });
  }

  getListSurveyHistory(query?: any): Observable<any> {
    return this.get<any>('/survey/history', { params: query });
  }


  getListSurveyGroups(query: any): Observable<any> {
    return this.get('/survey/group/search', {params: query})
  }

  getListSurveyGroup(query: any): Observable<any> {
    return this.get('/survey/group', {params: query})
  }

  create(body?: any): Observable<any> {
    return this.post<any>('/survey', body);
  }

  update(id, body?: any): Observable<any> {
    return this.put<any>(`/survey/edit/${id}`, body);
  }

  getListQuestionType(query: any): Observable<any> {
    return this.get('/survey/question/type', {params: query})
  }


  getListEmotion(query: any): Observable<any> {
    return this.get('/survey/question/icon', {params: query})
  }

  createSurveyGroup(body: any): Observable<any> {
    return this.post('/survey/group', body)
  }

  updateSurveyConfig(id, body): Observable<any> {
    return this.put(`/survey/config/${id}`, body)
  }

  detailSurveyConfig(id): Observable<any> {
    return this.get(`/survey/config/${id}`)
  }

  detail(id): Observable<any> {
    return this.get(`/survey/detail/${id}`)
  }

  detailAnswer(id): Observable<any> {
    return this.get(`survey/question/report?id=${id}`)
  }
  
  dashboardReportOverview(body: any): Observable<any> {
    return this.post(`/dashboard/report/overview`, body)
  }

  dashboardReportByTime(body: any): Observable<any> {
    return this.post(`/dashboard/report/time/survey_answer`, body)
  }

  dashboardReportByLocation(body: any): Observable<any> {
    return this.post(`/dashboard/report/location/survey_answer`, body)
  }

  dashboardReportByDevice(body: any): Observable<any> {
    return this.post(`survey/report/device`, body)
  }

  detailReportOverview(body: any): Observable<any> {
    return this.post(`/survey/report/overview`, body)
  }

  getDetailOrderHistory(id: any): Observable<any> {
    return this.get(`/survey/order/${id}`)
  }

  changeStatusOrderHistory(body: any): Observable<any> {
    return this.patch(`/survey/order/activate`, body)
  }

  deleteSurvey(id: any): Observable<any> {
    return this.delete(`/survey/delete/${id}`)
  }

  exportExcel(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>('/survey/question/export', httpOptions);
  }

  getDetailAnswer(query?: any): Observable<any>  {
    return this.get(`/survey/question`, {
      params: query
    })
  }

  getListPersonsAnswer(idSurvey: any) : Observable<any>{
    return this.get(`/survey/persons/${idSurvey}`)
  }

  getPersonDetailAnswer(id: any): Observable<any> {
    return this.get(`/survey/persons/detail/${id}`)
  }

}
