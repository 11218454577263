import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as _ from "lodash";
import { CoreService } from "src/app/services";
import { CoreDatahubService } from "src/app/services";

var that;

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  constructor(
    private coreService: CoreService,
    private coreDatahubService: CoreDatahubService
  ) {
    that = this;
  }

  options: any = [];
  optionsLevel1: any = [];
  allItem: any = [];

  @Input() categoryStr: string;
  @Input() categoryValue: any;
  @Output() valueChange = new EventEmitter();

  ngOnInit() {
    this.coreDatahubService.listCategoryNoParent({}).subscribe((data) => {
      this.options = data.data.map((item: any) => {
        this.dequy(item);
        if (item.children) {
          return {
            label: item.name,
            value: item.id,
            children: item.children.map((item: any) => {
              if (item.children) {
                return {
                  label: item.name,
                  value: item.id,
                };
              } else {
                return {
                  label: item.name,
                  value: item.id,
                  isLeaf: true,
                };
              }
            }),
          };
        } else {
          return {
            label: item.name,
            value: item.id,
            isLeaf: true,
          };
        }
      });
      this.allItem = data.rows;
    });
  }

  dequy(item: any) {
    if (item.children === null) {
      this.optionsLevel1.push({
        label: item.name,
        value: item.id,
        isLeaf: true,
      });
      return;
    } else {
      item.children.forEach((value: any) => {
        this.optionsLevel1.push({
          label: value.name,
          value: value.id,
          children: value.children,
        });
        if (value.children != null) {
          this.dequy(value);
        }
        return;
      });
    }
  }

  loadData1(node: any, index: number): PromiseLike<any> {
    return new Promise((resolve) => {
      let tmpChild: any = [];
      let nodeId = node.value;
      node.loading = false;
      let foundObj = that.optionsLevel1.find((gg: any) => gg.value == nodeId);
      if (foundObj) {
        tmpChild = foundObj.children.map((item: any) => {
          if (item.children) {
            return {
              label: item.name,
              value: item.id,
            };
          } else {
            return {
              label: item.name,
              value: item.id,
              isLeaf: true,
            };
          }
        });

        node.children = tmpChild;
      } else {
      }
      resolve();
    });
  }

  loadData(node: any, index: number): PromiseLike<any> {
    return new Promise((resolve) => {
      if (index < 0) {
        // if index less than 0 it is root node
        that.coreDatahubService.listCategoryNoParent().subscribe((data) => {
          if (data.rows.length > 0) {
            let childrens = _.map(data.rows, (e) => {
              if (e.children.length > 0) {
                return {
                  label: e.name,
                  value: e.id,
                };
              } else {
                return {
                  label: e.name,
                  value: e.id,
                  isLeaf: true,
                };
              }
            });
            node.children = childrens;
          } else {
            node.isLeaf = true;
          }
          resolve();
        });
      } else {
        that.coreDatahubService
          .listCategoryOfParent(node.value)
          .subscribe((data) => {
            if (data.rows.length > 0) {
              let childrens = _.map(data.rows, (e) => {
                if (e.children.length > 0) {
                  return {
                    label: e.name,
                    value: e.id,
                  };
                } else {
                  return {
                    label: e.name,
                    value: e.id,
                    isLeaf: true,
                  };
                }
              });
              node.children = childrens;
            } else {
              node.isLeaf = true;
            }
            resolve();
          });
      }
    });
  }

  onChanges(values: any) {
    this.valueChange.emit(values);
  }
}
