import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-survey-tab',
  templateUrl: './survey-tab.component.html',
  styleUrls: ['./survey-tab.component.scss']
})
export class SurveyTabComponent implements OnInit {
  unsubscribe$ = new Subject<void>();

  constructor() {}

  isDisabled: Boolean;
  isShow = 1;
  ngOnInit() {
    if (window.location.href.split("/")[5] !== 'null') {
      this.isShow = 2;
    } else this.isShow = 1;
    
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
