import {formatDate} from '@angular/common';
import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  ProductsDistributorsService,
  RootService,
  StampV2Service,
  SurveyService,
  UploadService,
} from 'src/app/services';
import {StampService} from 'src/app/services/business/stamp/stamp.service';
import {UploaderComponent} from 'src/app/shared';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {environment} from '../../../../../environments/environment';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';

@Component({
  selector: 'app-assign-store',
  templateUrl: './assign-store.component.html',
  styleUrls: ['./assign-store.component.scss'],
})
export class AssignStoreComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private stampService: StampService,
    private productsDistributorsService: ProductsDistributorsService,
    private rootService: RootService,
    private surveyService: SurveyService,
    private modalService: NzModalService,
    private stampV2Service: StampV2Service,
    private uploadService: UploadService,
  ) {
    // @ts-ignore
  }

  @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    roleId: [null, [Validators.required]],
    targetId: [[], [Validators.required]],
    message: [''],
    domainId: [null],
    activatedAt: [''],
    expiredAt: [''],
    relatedTargetType: [null],
    listRelatedBusiness: [[]],
    templateId: ['', [Validators.required]],
    loyaltyCampaignType: [''],
    loyaltyCampaignObject: [''],
    packageId: [null, [Validators.required]],
    surveyId: [null],
    loyaltyGiftCode: [''],
    config: new FormGroup({
      logo: new FormControl(''),
      shape: new FormControl('classy'),
      color: new FormControl('#000000'),
      backgroundColor: new FormControl('#ffffff'),
    }),
    setting: new FormGroup({
      requiredCustomerInfo: new FormControl(false),
      allowedScan: new FormControl(true),
      hideNotification: new FormControl(false),
      hideRelatedShops: new FormControl(false),
      customerInfoForm: new FormControl(false),
      showButtonSave: new FormControl(false),
      showButtonCall: new FormControl(false),
      showButtonPromotion: new FormControl(false),
      linkPromotion: new FormControl(null),
    }),
    contactPhone: [''],
  });
  settingActiveCollapse = {
    showShape: true,
    showColor: true,
    showLogo: true,
    showHideInfo: true,
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',
    },
  };
  isValidFormSubmitted = null;
  listBtnColors: Array<string> = [
    '#000000',
    '#EF6D46',
    '#8FC743',
    '#F390BC',
    '#83D1D5',
    '#6CB5E1',
    '#415CA0',
    '#7E62A1',
  ];
  // listPackage = [];
  @Input() listPackage = [];
  @Input() isChangeStampTypeQRCode = false;
  public config = {
    placeholder: '',
    disableDragAndDrop: true,
    tabsize: 2,
    height: '150px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      // ["insert", ["link", "video"]],
      ['insert', ['link']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };
  listYear = [
    {value: 1, label: '1 năm'},
    {value: 2, label: '2 năm'},
    {value: 3, label: '3 năm'},
  ];
  listSetting = [
    {value: 1, label: 'Mẫu thông tin khách hàng', key: 'customerInfoForm'},
    // {value: 2, label: 'Cho App khác quét', key: 'allowedScan'},
    // {value: 3, label: 'Ẩn thông báo', key: 'hideNotification'},
    {value: 5, label: 'Ẩn DN liên quan', key: 'hideRelatedShops'},
  ];
  id;
  isUpdate = false;
  listRole = [];
  qrcodeConfigPreview = null;
  @ViewChild('addBusinessRelated', {static: false}) addBusinessRelated;
  @ViewChild('deleteBusinessRelateds', {static: false})
  deleteBusinessRelateds;
  heightEl = 500;
  listRelatedBusiness = [];
  templateData: any = {};
  templateDataSubject: any = new BehaviorSubject(this.templateData);
  configData: any = {};
  templateConfigSubject: any;
  listSurveys = [];
  listDomain = [];
  page = 1;
  listStamp = [];
  firstTempale: any;
  totalPage = 0;
  currentPage = 1;
  pageSize = 3;
  validExpiredAt = true;
  showListRelatedBusiness = false;
  logos = [];
  listBusiness = [];
  currentPackage: any;
  clickSubmit = false;
  isLoadingBtn = false;
  templateId;
  viewCurrentTemp;
  isNewQr: true;
  loadingDrawQrcode;
  firstTimeLoadTemplate = false;
  qrcodeConfig = {
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 1000,
    height: 1000,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const mapElement: any = document.querySelector(
      '.img-template'
    ) as HTMLElement;
    this.heightEl = mapElement.offsetWidth ? mapElement.offsetWidth * 2 : 500;
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_form_company',
    //   contentType: 'qrcode',
    // })
    this.templateConfigSubject = new BehaviorSubject(this.configData);
    this.getListSurvey();
    this.getListPackageActive();
    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    this.getListDomainByUser();
    this.getListBusiness();
    this.route.queryParams.subscribe((params) => {
      if (params.objectId) {
        this.form.patchValue({
          targetId: [...params.objectId.split(',')],
        });
      }
    });
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== 'null') {
      this.isUpdate = true;
      this.getStampV2Detail(this.id);
    } else {
      this.isNewQr = true;
    }
    this.getListRole();
    this.changeValueForm();
    setTimeout(() => {
      this.getListTemplate();
    }, 300);

    this.form.get('setting.showButtonPromotion').valueChanges.subscribe(val => {
      // console.log("val", val)
      if (val) {
        this.form.get('setting.linkPromotion').setValidators([Validators.required, Validators.pattern(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i)]);
        this.form.get('setting.linkPromotion').updateValueAndValidity();
        // tslint:disable-next-line:forin
      } else {
        this.form.get('setting.linkPromotion').clearValidators();
        this.form.get('setting.linkPromotion').updateValueAndValidity();
      }
    });
  }

  getStampV2Detail(id) {
    this.stampService.getStampById(id).subscribe(async (res) => {
      if (res.code === 1 && res.data) {
        // const data = res.data;
        const data = {
          ...res.data,
          newQr: true
        };
        this.isNewQr = data.newQr;
        const activatedAt = data.activatedAt.split('/');
        // tslint:disable-next-line:variable-name
        const activatedAtConverted = [
          activatedAt[1],
          activatedAt[0],
          activatedAt[2],
        ].join('/');
        const expiredAt = data.expiredAt.split('/');
        // tslint:disable-next-line:variable-name
        const expiredAtConverted = [
          expiredAt[1],
          expiredAt[0],
          expiredAt[2],
        ].join('/');
        if (data.loyaltyCampaign) {
          this.form.patchValue({
            loyaltyCampaignType: data.loyaltyCampaign.type,
            loyaltyCampaignObject: data.loyaltyCampaign.code,
            loyaltyGiftCode: data.loyaltyCampaign.giftCode,
          });
        }

        if (data.packageInfo && data.packageInfo.id) {
          this.listPackage = [{...data.packageInfo}];
          this.form.patchValue({
            packageId: data.packageInfo.id,
          });
        }

        this.form.patchValue({
          name: data.name,
          roleId: data.roleId,
          targetId: !this.isChangeStampTypeQRCode ? data.targetId : null,
          message: data.message,
          activatedAt: activatedAtConverted,
          expiredAt: expiredAtConverted,
          setting: data.setting,
          relatedTargetType: data.relatedTargetType,
          templateId: !this.isChangeStampTypeQRCode ? data.templateId : null,
          domainId: data.domainId,
          surveyId: data.surveyId || null,
          packageId: data.packageInfo.id,
          listRelatedBusiness: data.relatedTargetData
            ? data.relatedTargetData.map((item) => {
              return {
                ...item,
                businessId: item.id,
                taxCode: item.code,
              };
            })
            : [],
        });
        this.templateId = data.templateId;
        this.firstTempale = data.templateId;
        this.listRelatedBusiness = data.relatedTargetData
          ? data.relatedTargetData.map((item) => {
            return {
              ...item,
              businessId: item.id,
              taxCode: item.code,
            };
          })
          : [];
        if (data.setting.showButtonCall) {
          this.form.patchValue({
            contactPhone: data.setting.phoneNumber,
            setting: {
              showButtonCall: true,
            },
          });
          this.form.controls.contactPhone.setValidators([Validators.required]);
          this.form.updateValueAndValidity();
        } else {
          this.form.patchValue({
            contactPhone: '',
            setting: {
              showButtonCall: false,
            },
          });
        }
        if (data.newQr) {
          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType,
            data.config, data.frameConfig,
            data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(data.qrCode);
        } else {
          if (data.config && data.config.color) {
            this.form.get('config.color').setValue(data.config.color);
          }
          if (data.config && data.config.backgroundColor) {
            this.form
              .get('config.backgroundColor')
              .setValue(data.config.backgroundColor);
          }
          if (data.config && data.config.logo) {
            this.form.get('config.logo').setValue(data.config.logo);
            this.logos = [{url: data.config.logo}];
          }
          if (data.config && data.config.shape) {
            this.form.get('config.shape').setValue(data.config.shape);
          }
        }
      }
    });
  }

  getListSurvey() {
    const query = {
      page: 1,
      pageSize: 200,
      type: 'BASIC',
    };

    this.surveyService
      .getListSurveys(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listSurveys = res && res.data ? [...res.data] : [];
          } else {
            this.notification.warning(
              'Có lỗi xảy ra',
              res && res.message ? res.message : 'Vui lòng thử lại'
            );
          }
        },
        (err: any) => {
          this.notification.error(
            'Có lỗi xảy ra',
            err && err.message ? err.message : 'Vui lòng thử lại'
          );
        }
      );
  }

  getListPackageActive() {
    // this.stampService.getListPackageActive({type: 1}).subscribe((res) => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }

  getListDomainByUser() {
    const query: any = {
      page: 1,
      pageSize: 100,
    };

    this.stampService
      .getListDomain(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  nextPage() {
    if (this.page === this.totalPage) {
      return;
    }
    this.page += 1;
    this.getListTemplate();
  }

  getListRole() {
    this.rootService
      .getRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.listRole = res.data;
      });
  }

  getListTemplate() {
    const body =
      this.templateId && this.isUpdate
        ? {
          page: this.page,
          pageSize: this.pageSize,
          type: 2,
          toTop: this.firstTempale,
        }
        : {
          page: this.page,
          pageSize: this.pageSize,
          type: 2,
        };
    this.stampService.getAllTemplate(body).subscribe((res) => {
      if (res.code === 1) {
        this.listStamp = res.data && res.data.length ? res.data.sort() : [];
        this.totalPage = Math.ceil(res.totalElement / res.pageSize);
        this.currentPage = res.currentPage;
        if (!this.firstTimeLoadTemplate) {
          this.firstTimeLoadTemplate = true;
        }
        if (!this.viewCurrentTemp) {
          this.viewCurrentTemp = this.listStamp.filter(
            (item) => item.id === this.templateId
          )[0];
        }
      }
    });
  }

  prevPage() {
    if (this.page === 1) {
      return;
    }
    this.page -= 1;
    this.getListTemplate();
  }

  deleteSetting(id) {
    const data = {
      label: 'Xác nhận xóa doanh nghiệp liên quan',
      value:
        ' <p class="mrb-10">Hệ thống xóa doanh nghiệp liên quan này.</p>\n' +
        '          <p>Bạn có chắc chắn muốn xoá?</p>',
    };
    this.deleteBusinessRelateds.showModal(data, id);
  }

  handleDeleteRelateds(event) {
    this.listRelatedBusiness = this.listRelatedBusiness.filter(
      (item) => item.businessId !== event[0]
    );
    this.deleteBusinessRelateds.success();
    this.deleteBusinessRelateds.handleCancel();
    this.templateData.listRelatedTarget = [...this.listRelatedBusiness];
    this.templateDataSubject.next(this.templateData);
    this.notification.success(
      'Thành công',
      'Xóa doanh nghiệp liên quan thành công'
    );
  }

  showAddBusinessRelated() {
    let listTargetId = this.listRelatedBusiness.map(
      (item) => item.businessId
    );
    const formTargetId = this.form.value.targetId;
    if (this.form.value.targetId) {
      listTargetId.push(this.form.value.targetId);
    }
    if (formTargetId) {
      if (Array.isArray(formTargetId)) {
        listTargetId = listTargetId.concat([...formTargetId]);
      } else {
        listTargetId.push(this.form.value.targetId);
      }
    }
    this.addBusinessRelated.showModal(listTargetId);
  }

  successAddBusinessRelated(event) {
    this.listRelatedBusiness = [
      ...this.listRelatedBusiness,
      ...event.map((item) => {
        return {
          ...item,
          image: item.avatar,
        };
      }),
    ];
    this.templateData.listRelatedTarget = [...this.listRelatedBusiness];
    this.templateDataSubject.next(this.templateData);
    this.addBusinessRelated.handleCancel();
  }

  changeValueForm() {
    this.form.get('expiredAt').valueChanges.subscribe((val) => {
      // tslint:disable-next-line:max-line-length
      if (
        this.form.value.activatedAt &&
        formatDate(val, 'yyyy-MM-dd', 'en_US') <=
        formatDate(this.form.value.activatedAt, 'yyyy-MM-dd', 'en_US')
      ) {
        this.validExpiredAt = false;
      } else {
        this.validExpiredAt = true;
      }
    });

    this.form.get('relatedTargetType').valueChanges.subscribe((val) => {
      this.listRelatedBusiness = [];
      if (val === 2) {
        this.showListRelatedBusiness = true;
      } else {
        this.showListRelatedBusiness = false;
      }
    });

    this.form.valueChanges.subscribe((val: any) => {
      this.templateData = {
        ...this.form.value,
        setting: {...val.setting},
        relatedTargetType: val.relatedTargetType || null,
        listRelatedTarget: val.listRelatedBusiness || [],
        // listRelatedTarget: this.listRelatedBusiness ? [...this.listRelatedBusiness] : [],
        roleId: val.roleId,
        targetId: Array.isArray(val.targetId) ? val.targetId[0] : val.targetId,
      };
      this.templateDataSubject.next(this.templateData);
    });

    this.form.get('config').valueChanges.subscribe((val: any) => {
      this.configData = {
        ...this.form.value,
        setting: {...val.setting},
        relatedTargetType: val.relatedTargetType || null,
        listRelatedTarget: this.listRelatedBusiness
          ? [...this.listRelatedBusiness]
          : [],
        roleId: val.roleId,
        targetId: Array.isArray(val.targetId) ? val.targetId[0] : val.targetId,
      };
      this.templateConfigSubject.next(this.configData);
    });
    this.form
      .get('setting')
      .get('showButtonCall')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        if (val) {
          this.form.controls.contactPhone.setValidators([Validators.required]);
          this.form.updateValueAndValidity();
        } else {
          this.form.get('contactPhone').clearValidators();
          this.form.get('contactPhone').updateValueAndValidity();
        }
      });
  }

  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp,mp4,pdf,docx';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logos') {
            this.logos = [{url: changeParam.file.response.url}];
            this.form.get('config.logo').setValue(this.logos[0].url);
            this.form.updateValueAndValidity();
          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  onSelectColor(color: string) {
    if (this.form.value.config.color !== color) {
      this.form.get('config.color').setValue(color);
    } else {
      this.form.get('config.color').setValue('');
    }
  }

  selectedQrCode(value) {
    if (this.form.get('config.shape').value !== value) {
      this.form.get('config.shape').setValue(value);
    } else {
      this.form.get('config.shape').setValue('');
    }
  }

  onSelectbackgroundColor(color: string) {
    if (this.form.value.config.backgroundColor !== color) {
      this.form.get('config.backgroundColor').setValue(color);
    } else {
      this.form.get('config.backgroundColor').setValue('');
    }
    // this.form.get("config.backgroundColor").setValue(color);
  }

  onRemoveFile(field: string) {
    this.logos = [];
    this.form.get('config.logo').setValue('');
  }

  getListBusiness() {
    const body = {
      page: 1,
      pageSize: 2000,
      hidden: false,
    };
    this.productsDistributorsService
      .listBusiness(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.listBusiness = res.data;
      });
  }

  getDate(date, tmpNumber) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (tmpNumber === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute;
  }

  selectedPackage(event) {
    if (event) {
      this.currentPackage = this.listPackage.find((item) => item.id === event);
    }
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  async submit() {
    this.isValidFormSubmitted = false;
    this.clickSubmit = true;
    // const recursive = (f: FormGroup | FormArray) => {
    //   for (const i in f.controls) {
    //     if (typeof f.controls[i].value === "string") {
    //       if (f.controls[i].value.trim().length === 0) {
    //         f.controls[i].value = null;
    //       }
    //     }
    //     if (f.controls[i] instanceof FormControl) {
    //       f.controls[i].markAsDirty();
    //       f.controls[i].updateValueAndValidity();
    //     } else {
    //       recursive(f.controls[i] as any);
    //     }
    //   }
    // };
    // recursive(this.form);
    // for (const i in this.form.controls) {
    //   this.form.controls[i].markAsDirty();
    //   this.form.controls[i].updateValueAndValidity();
    // }
    if (this.form.invalid) {
      this.templateQRCodeViewRef.onChangeTabActive();
      return;
    }
    this.isValidFormSubmitted = true;
    this.isLoadingBtn = true;
    const newForm = {
      ...this.form.value,
      activatedAt: this.form.value.activatedAt
        ? this.getDate(this.form.value.activatedAt, 1)
        : '',
      expiredAt: this.form.value.expiredAt
        ? this.getDate(this.form.value.expiredAt, 1)
        : this.currentPackage.expiredAt,
      type: 3,
      relatedTargetId:
        this.form.value.relatedTargetType === 2
          ? this.listRelatedBusiness.map((item) => item.businessId)
          : [],
    };
    newForm.setting.phoneNumber = this.form.value.contactPhone;
    if (this.isNewQr) {
      newForm.config = {
        ...this.qrcodeDesignComponentRef.getQrcodeDesignConfig(),
      };
      newForm.qrcodeFrameId =
        this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
    }
    // track("manualTrackInteractionContent", {
    //   interaction: "click",
    //   contentName: "save",
    //   regionName: "portal_stamp_form_company",
    //   target: `${window.location.origin}/portal/stamp`,
    //   payload: JSON.stringify(newForm),
    // })
    if (this.isUpdate) {
      if (this.isNewQr) {
        const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
        const formData = new FormData();
        formData.append('file', base64QrcodeSvg);
        const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
        if (qrImageResponse.status === 200) {
          newForm.qrImage = qrImageResponse.data || '';
        }
        this.stampService
          .update(this.id, newForm)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res) => {
              if (res.code === 1) {
                this.openMessage(
                  'success',
                  'Thành công',
                  'Cập nhật thành công'
                );
                let delayTimer: any;
                clearTimeout(delayTimer);
                delayTimer = setTimeout(() => {
                  this.router.navigate(['/portal/stamp']);
                  return;
                }, 500);
              } else {
                this.openMessage('warning', 'Cảnh báo', `${res.message}`);
              }
              this.isLoadingBtn = false;
            },
            (error) => {
              this.isLoadingBtn = false;
              this.openMessage('error', 'Lỗi !!!', `${error}`);
            }
          );
      } else {
        const qrcodeData = `${environment.redirectScanUrl}/${this.id}`;
        const tmpQrcodeConfig = {
          ...this.qrcodeConfig,
          backgroundOptions: {
            color: this.form.get('config.backgroundColor').value
          },
          dotsOptions: {
            type: this.form.get('config.shape').value,
            color: this.form.get('config.color').value,
          },
          image: this.form.get('config.logo').value,
          data: qrcodeData,
        };
        const qrcodeInstance = new QRCodeStyling(tmpQrcodeConfig as any);
        const rawQrcodeImage = await qrcodeInstance.getRawData();
        const formData = new FormData();
        formData.append('content', rawQrcodeImage, 'qrcode.png');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.uploadService.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (result) => {
            if (result.status === 200 && result.data && result.data.url) {
              newForm.qrImage = result.data.url;
              this.stampService
                .update(this.id, newForm)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  if (res.code === 1) {
                    this.openMessage('success', 'Thành công', 'Cập nhật thành công');
                    let delayTimer: any;
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(() => {
                      this.router.navigate(['/portal/stamp']);
                      return;
                    }, 500);
                  } else {
                    this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                  }
                  this.isLoadingBtn = false;
                }, error => {
                  this.isLoadingBtn = false;
                  this.openMessage('error', 'Lỗi !!!', `${error}`);
                });
            }
          },
          (err) => {
            this.openMessage('error', 'Cập nhật không thành công', `Vui lòng thử lại`);
          }
        );
      }
    } else {
      if (newForm.targetId && newForm.targetId.length > 5) {
        this.isLoadingBtn = false;
        return this.notification.warning('Vui lòng thử lại', 'Chỉ được tạo tối đa 5 QR Code');
      }
      newForm.businessData = newForm.targetId.map(item => ({
        businessId: item,
        qrImage: '',
        qrCode: '',
      }));
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < newForm.businessData.length; i++) {
        const formData = new FormData();
        const base64QrcodeSvg = await this.qrcodeDesignComponentRef.drawAndSvgToDataUrl();
        newForm.businessData[i].qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
        formData.append('file', base64QrcodeSvg);
        const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
        if (qrImageResponse.status === 200) {
          newForm.businessData[i].qrImage = qrImageResponse.data || '';
        }
      }
      delete newForm.targetId;
      this.stampService
        .createMulti(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res.code === 1) {
              this.openMessage('success', 'Thành công', res.data || 'Thêm mới thành công');
              let delayTimer: any;
              clearTimeout(delayTimer);
              delayTimer = setTimeout(() => {
                this.router.navigate(['/portal/stamp']);
                return;
              }, 500);
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
            this.isLoadingBtn = false;
          },
          (error) => {
            this.isLoadingBtn = false;
            this.openMessage('error', 'Lỗi !!!', `${error}`);
          }
        );
    }
  }

  selectTemplate(stamp) {
    this.templateId = stamp.id;
    this.form.controls.templateId.setValue(stamp.id);
    this.templateDataSubject.next(this.templateData);
  }

  viewTemplate(stamp) {
    this.viewCurrentTemp = stamp;
  }

  onChangeInfoForm(e, key: string) {
    if (key === 'customerInfoForm') {
      this.form.get('setting.requiredCustomerInfo').setValue(e);
    }
  }

  back() {
    this.router.navigate(['/portal/stamp']);
  }

  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }

  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
