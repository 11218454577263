import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompaniesService, RootService } from 'src/app/services';
import { UserService } from 'src/app/services/business/user/user.service';
import { UploaderComponent } from 'src/app/shared';

@Component({
  selector: 'app-user-detail-modal',
  templateUrl: './user-detail-modal.component.html',
  styleUrls: ['./user-detail-modal.component.scss'],
})
export class UserDetailModalComponent implements OnInit {
  isVisible: boolean = false;
  unsubscribe$ = new Subject<void>();
  title: string = "";
  loading: boolean = false;
  userInfo: any;

  constructor(private userService: UserService) {}

  ngOnInit() {}

  showModal(item) {
    this.userService.getDetail(item.id).subscribe(res =>{
      this.userInfo = res.data;
      this.userInfo.fields = this.userInfo.fields.map(item => {
        if (item.type === 'select' || item.type === 'multiSelect') {
          item.displayValue = item.selectedOptionValues ? item.selectedOptionValues.map(item => item.value).join(',') : ''
        }
        if (item.type === 'image') {
          item.listImage = item.textValue ? item.textValue.split(',') : [];
        }
        return {...item}
      })
      console.log(this.userInfo.fields)
    })
    // this.userInfo = item;
    this.isVisible = true;
    this.title = "Chi tiết khách hàng";
  }


  handleCancel() {
    this.changePositionBody();
    this.isVisible = false;
  }

  changePositionBody() {
    document.body.style.position = 'unset';
  }

  openImage(src: string) {
    if (!src) return
    return window.open(src, '_blank');
  }

  ngOnDestroy(){
    // prevent memory leak when component destroyed
    document.body.style.position = 'unset';

  }

}
