import { Component, OnInit, ViewChild } from "@angular/core";
import { SurveyGroupFormComponent } from "./survey-group-form/survey-group-form.component";

@Component({
  selector: "app-servey-group-list",
  templateUrl: "./servey-group-list.component.html",
  styleUrls: ["./servey-group-list.component.scss"],
})
export class ServeyGroupListComponent implements OnInit {
  @ViewChild('surveyGroupForm', {static: true}) surveyGroupForm: SurveyGroupFormComponent;
  listSurveyGroup: Array<any> = [
    {
      value: 1,
      label: "Nhóm 1",
    },
    {
      value: 2,
      label: "Nhóm 2",
    },
  ];
  listStatus: Array<any> = [
    {
      value: 1,
      label: "Kích hoạt",
    },
    {
      value: 2,
      label: "Bị chặn",
    },
  ];
  listActions: Array<any> = [
    {
      value: 1,
      label: "Ẩn",
    },
    {
      value: 2,
      label: "Hiện",
    },
  ];
  listGroups: Array<any> = [
    {
      id: 1,
      active: true,
      name: "Mỹ phẩm",
    },
    {
      id: 2,
      active: false,
      name: "Hàng tiêu dùng Vjp Pro",
    },
  ];
  listGroup = [
    { title: 'Expand to load', key: '0' },
    { title: 'Expand to load', key: '1' },
    { title: 'Tree Node', key: '2', isLeaf: true }
  ];
  listSurveys: Array<any> = [
    {
      id: 1,
      show: 1,
      name: 'Ten khao sat Thang test',
      status: 1,
      countQuestions: 100,
      countAnswer: 200,
      createdAt: '18/03/2021',
      endAt: '18/03/2021',
      reason: ""
    },
    {
      id: 2,
      show: 0,
      name: 'Ten khao sat Thang Pro',
      status: 0,
      countQuestions: 200,
      countAnswer: 300,
      createdAt: '18/03/2021',
      endAt: '18/03/2021',
      reason: "thich thi huy ne"
    },
    {
      id: 3,
      show: 0,
      name: 'Ten khao sat Thang Vjp',
      status: 3,
      countQuestions: 200,
      countAnswer: 300,
      createdAt: '18/03/2021',
      endAt: '18/03/2021',
      reason: ""
    }
  ]
  constructor() {}

  ngOnInit() {}

  loadChildNode(event: any) {
    if (event.eventName === 'expand') {
      const node = event.node;
      if (node && node.getChildren().length === 0 && node.isExpanded) {
        this.loadNode().then(data => {
          node.addChildren(data);
        });
      }
    }
  }

  loadNode(): Promise<any[]> {
    return new Promise(resolve => {
      setTimeout(
        () =>
          resolve([
            { title: 'Child Node', key: `${new Date().getTime()}-0`, isLeaf: true },
            { title: 'Child Node', key: `${new Date().getTime()}-1` }
          ]),
        1000
      );
    });
  }

  onShowModalFormSurveyGroup(type: string) {
    this.surveyGroupForm.showModal(type)
  }
}
