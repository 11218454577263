import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ShopsService } from 'src/app/services';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-add-stores',
  templateUrl: './add-stores.component.html',
  styleUrls: ['./add-stores.component.scss']
})
export class AddStoresComponent implements OnInit, OnDestroy {

  isVisible = false;
  storesId: string;
  showModal(id?: any) {
    this.isVisible = true;
    this.titleId = null;
    this.isShow = false;
    this.form.reset();
    this.loadTitle();
    if (id) {
      this.storesId = id;
    }
  }

  handleCancel() {
    this.isVisible = false;
  }

  @Output() addStores = new EventEmitter<any>();

  unsubscribe$ = new Subject<void>();
  pageIndex = 1;
  pageSize = 5;

  constructor(
    private fb: FormBuilder,
    private shopService: ShopsService,
    private notification: NzNotificationService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  form = this.fb.group({
    type_id: [null, [Validators.required]],
    product_ids: [null]
  })

  ngOnInit() {
  }

  titleId: number;
  isShow: Boolean = false;
  showProduct(data: any) {
    if (data) {
      this.isShow = true;
      this.titleId = data;
    } else {
      this.isShow = false;
    }
  }

  titles: any = [];
  loadTitle() {
    this.shopService.getAllTitle().subscribe(
      (res) => {
        this.titles = res.data.items;
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  listProducts(data: any) {
    this.form.get('product_ids').setValue(data);
    if (data.length > 0) {
      this.isRequired = false;
    } else {
      this.isRequired = true;
    }
  }

  isLoadingBtn: Boolean = false;
  isRequired: Boolean;
  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }


    if (!this.form.value.product_ids) {
      this.isRequired = true;
      return;
    }
    this.isLoadingBtn = true;
    if (this.isRequired == false) {
      let body: any = {
        id: this.storesId,
        type_id: this.form.value.type_id,
        product_ids: this.form.value.product_ids
      }
      this.addStores.emit(body);
    }
  }

  success() {
    this.isLoadingBtn = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
