import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StampService} from '../../../../../services/business/stamp/stamp.service';
import {NzNotificationService} from 'ng-zorro-antd';
import {StampV2Service} from '../../../../../services';
import moment from 'moment';

@Component({
  selector: 'app-select-campaign-icheck',
  templateUrl: './select-campaign-icheck.component.html',
  styleUrls: ['./select-campaign-icheck.component.scss']
})
export class SelectCampaignIcheckComponent implements OnInit {
  currentStamp: any;
  selectedItem: any;
  giftCode: any;
  @Input() loyaltyCampaignAll: any;

  constructor(    private router: Router,
                  private stampService: StampService,
                  private notification: NzNotificationService,
                  private stampV2Service: StampV2Service) { }

  ngOnInit() {
    const id = window.location.href.split('/')[5];
    if (id !== 'null') {
      this.stampService.getStampById(id).subscribe((res) => {
        if (res.code === 1 && res.data) {
          this.currentStamp = res.data;
          if (res.data.loyaltyCampaignAll && res.data.loyaltyCampaignAll.campaignAllStatus) {
            if (res.data.loyaltyCampaignAll.campaignAllStatus) {
              this.selectedItem = res.data.loyaltyCampaignAll.code;
            }
            // if (res.data.loyaltyCampaign.giftCode) {
            //   this.giftCode = res.data.loyaltyCampaign.giftCode;
            // }
          }
        }
      });
    }
  }

  // campaignAllStatus nếu loại 3 alf true : false
  submit() {
    const body = {
      ...this.currentStamp,
      loyaltyCampaignType: 3,
      campaignAllStatus: true,
      loyaltyCampaignObject: this.selectedItem ? this.selectedItem : '',
      loyaltyGiftCode: this.giftCode ? this.giftCode.trim() : '',
      data: this.currentStamp.stampData,
    };
    this.stampService.update2(this.currentStamp.id, body).subscribe((res) => {
      if (res.code === 1) {
        this.openMessage('success', 'Thành công', 'Cập nhật thành công');
        let delayTimer: any;
        delayTimer = setTimeout(() => {
          this.router.navigate(['/portal/stamp']);
          return;
        }, 500);
      }
      if (res.code !== 1) {
        this.openMessage('warning', 'Cảnh báo', `${res.message}`);
      }
    }),
      // tslint:disable-next-line:no-unused-expression
      (err) => {
        // track("alert", {
        //   alertType: "error",
        //   alertMessage: err
        // });
        // track('error', {
        //   apiCall: `${window.location.origin}/stamp/${this.currentStamp.id}`,
        //   httpResponseCode: 500,
        //   errorMessage: err,
        //   errorCode: 500
        // })
        this.openMessage('err', 'Lỗi !!!', `${err}`);
      };
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  formatDate(date) {
    return moment(date).format('DD/MM/yyyy');
  }

  listClick(newValue) {
    if (this.selectedItem === newValue) {
      this.selectedItem = '';
    } else {
      this.selectedItem = newValue;
    }
  }

  getStatus(start, end) {
    const current = moment();
    if (current.isBefore(moment(start))) {
      return 'Chưa bắt đầu';
    }

    if (current.isAfter(moment(end))) {
      return 'Đã kết thúc';
    }

    return 'Đang diễn ra';
  }

}
