import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-food-sales',
  templateUrl: './food-sales.component.html',
  styleUrls: ['./food-sales.component.scss']
})
export class FoodSalesComponent implements OnInit, OnChanges {

  @Input() foodSales;
  @Input() menuData;
  hours: any;
  minutes: any;
  seconds: any;
  isShow: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.menuData.timeSale && this.menuData.timeSale.length) {
      if (moment().isSameOrAfter(moment(this.menuData.timeSale[0])) && moment().isSameOrBefore(moment(this.menuData.timeSale[1]))) {
        this.isShow = true;
        const countDown = setInterval(() => {
          const {days, hours, minutes, seconds, distance} = this.getDayHourMinuteSecond(moment(this.menuData.timeSale[1]).toDate());
          // tslint:disable-next-line:radix
          this.hours = parseInt(hours) + parseInt(days) * 24;
          this.minutes = minutes;
          this.seconds = seconds;
          if (distance <= 0) {
            this.isShow = false;
            clearInterval(countDown);
          }
        }, 1000);
      }
    }
  }

  getDayHourMinuteSecond = (endDate: any) => {
    const now = new Date().getTime();
    const end = new Date(endDate).getTime();
    const distance = end - now;
    let days: any = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours: any = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);

    if (days < 10) {
      days = `0${days}`;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return {days, hours, minutes, seconds, distance}
  }

}
