import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { AuthService } from "../../auth.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Router } from "@angular/router";
import { th } from "date-fns/locale";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  signupForm: FormGroup;
  invalidConfirmPassword: boolean = false;
  otpForm = this.fb.group({
    otp: ["", [Validators.required]],
  });
  loadingStep1: boolean = false;
  loadingStep2: boolean = false;
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.signupForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  tokenOtp: string = "";
  showConfirmOtp: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notification: NzNotificationService,
    private router: Router
  ) {
    this.signupForm = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      password: ["", [Validators.required]],
      confirm: ["", [this.confirmValidator]],
    });
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: "bottomRight",
    });
  }

  isNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  submitForm(data: any) {
    for (const i in this.signupForm.controls) {
      this.signupForm.controls[i].markAsDirty();
      this.signupForm.controls[i].updateValueAndValidity();
    }

    if (this.signupForm.invalid) {
      return;
    }

    let body = {
      phoneNumber: this.signupForm.get("phoneNumber").value,
      password: this.signupForm.get("password").value,
      confirmPassword: this.signupForm.get("confirm").value,
      firstName: this.signupForm.get("firstName").value,
      lastName: this.signupForm.get("lastName").value,
    };

    this.loadingStep1 = true;

    this.authService.signUpStep1(body).subscribe(
      (res: any) => {
        if (res.code === 1) {
          this.tokenOtp = res.token;
          this.showConfirmOtp = true;
        } else {
          this.tokenOtp = res.token || "";
          this.showConfirmOtp = false;
          this.notification.create(
            "error",
            "Đăng ký thất bại",
            res.message || "Có lỗi xảy ra"
          );
        }
        this.loadingStep1 = false;
      },
      (error: any) => {
        this.tokenOtp = error.token || "";
        this.showConfirmOtp = false;
        this.notification.create(
          "error",
          "Đăng ký thất bại",
          error.message || "Có lỗi xảy ra"
        );
        this.loadingStep1 = false;
      }
    );
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.signupForm.controls.confirm.updateValueAndValidity());
  }

  submitOtpForm(data: any) {
    for (const i in this.otpForm.controls) {
      this.otpForm.controls[i].markAsDirty();
      this.otpForm.controls[i].updateValueAndValidity();
    }
    if (this.otpForm.invalid) {
      return;
    }

    let body = {
      token: this.tokenOtp,
      otp: data.otp,
    };

    this.loadingStep2 = true;

    this.authService.signUpStep2(body).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res: any) => {
        if (res.code === 1) {
          this.notification.create(
            "success",
            "Thành công",
            "Đăng ký thành công",
            { nzDuration: 600000 }
          );
          this.router.navigate(["/"]);
        } else {
          this.notification.create(
            "error",
            "Thất bại",
            res.message || "Có lỗi xảy ra!",
            { nzDuration: 600000 }
          );
        }
        this.loadingStep2 = false;
      },
      (err: any) => {
        this.notification.create(
          "error",
          "Thất bại",
          err.message || "Có lỗi xảy ra!",
          { nzDuration: 600000 }
        );
        this.loadingStep2 = false;
      }
    );
  }

  resendOtp() {
    if(!this.tokenOtp) {
      this.notification.create(
        "warning",
        "Không thành công",
        "Vui lòng nhập số điện thoại!"
      );
      return;
    }
    let query = {
      token: this.tokenOtp,
    };
    this.authService
      .resendOtp(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.notification.create(
              "success",
              "Gửi lại OTP thành công",
              "Vui lòng kiểm tra lại tin nhắn"
            );
          } else {
            this.notification.create(
              "error",
              "Có lỗi xảy ra",
              res.message || "Vui lòng thử lại sau!"
            );
          }
        },
        (err: any) => {
          this.notification.create(
            "error",
            "Có lỗi xảy ra",
            err.message || "Vui lòng thử lại sau!"
          );
        }
      );
  }
}
