import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-qrcode-pdf-preview',
  templateUrl: './qrcode-pdf-preview.component.html',
  styleUrls: ['./qrcode-pdf-preview.component.scss']
})
export class QrcodePdfPreviewComponent implements OnInit {

  @Input() templateData: Observable<any>
  unsubscribe$ = new Subject<void>();
  data: any = null;
  timeout: any;
  loading = false;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loading = true
        setTimeout(() => {
          if (val.pdf) {
            this.data = { ...val, pdf: this._sanitizer.bypassSecurityTrustResourceUrl(val.pdf + '?page=hsn#toolbar=0') }
          } else {
            this.data = { ...val }
          }
          this.loading = false
        }, 800)
      }, 800);
      // get detail product
    })
  }

}
