import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessInfoRoutingModule } from './business-info-routing.module';
import { BusinessInfoComponent } from './business-info.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { SharedModule } from 'src/app/shared';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PackageListComponent } from './package-list/package-list.component';

@NgModule({
  declarations: [
    BusinessInfoComponent,
    PackageListComponent,
  ],
  imports: [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  BusinessInfoRoutingModule,
  NgZorroAntdModule,
  SharedModule,
    NzTabsModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class BusinessInfoModule { }
