import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class StampService extends BaseService {

  getAll(query?: any): Observable<any> {
    return this.get<any>('/stamp', { params: {...query, sortBy: 1} });
  }

  getAllOrderStamp(query?: any): Observable<any> {
    return this.get<any>('/stamp-order', { params: query });
  }
  create(body?: any): Observable<any> {
    return this.post<any>('/stamp', body);
  }

  createMulti(body?: any): Observable<any> {
    return this.post<any>('/stamp/multi', body);
  }

  createStampOrder(body?: any): Observable<any> {
    return this.post<any>('/stamp-order', body);
  }

  getStampById(id?: any): Observable<any> {
    return this.get<any>(`/stamp/${id}`);
  }

  getStampOrderById(id?: any): Observable<any> {
    return this.get<any>(`/stamp-order/${id}`);
  }

  update(id?: any, body?: any): Observable<any> {
    return this.put<any>(`/stamp/${id}`, body);
  }

  update2(id?: any, body?: any): Observable<any> {
    return this.put<any>(`/stamp/loyalty/${id}`, body);
  }

  destroy(body: any): Observable<any> {
    return this.delete<any>(`/stamp`, body);
  }

  getAllTemplate(query?: any): Observable<any> {
    return this.get<any>('/stamp-template', { params: query });
  }

  toggleStatusStamp(body): Observable<any> {
    return this.patch<any>('/stamp/activate', body);
  }

  getListScanStamp(query?: any): Observable<any> {
    return this.get<any>('/scan-history', { params: query });
  }


  getListScanStampByStampId(id, query?: any): Observable<any> {
    return this.get<any>(`/scan-history/stamp/${id}`, { params: query });
  }

  exportExcel(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.post<any>('/scan-history/export', {}, httpOptions);
  }

  getListCustomer(query?: any): Observable<any> {
    return this.get<any>('/customer', { params: query });
  }


  getListCustomerByStampId(id, query?: any): Observable<any> {
    return this.get<any>(`/customer/stamp/${id}`, { params: query });
  }

  getListBusinessType(): Observable<any> {
    return this.get<any>(`/business-area`);
  }

  getListDomain(query?: any): Observable<any> {
    return this.get<any>('/user/domain', { params: query });
  }

  getListTargetProduct(query: any): Observable<any> {
    return this.get<any>(`/product/business/search`, {
      params: query
    });
  }


  getListLoyalty(query: any): Observable<any> {
    return this.get<any>(`/loyalty`, {
      params: query
    });
  }

  getListPublicLoyalty(query: any): Observable<any> {
    return this.get<any>(`/loyalty/public`, {
      params: query
    });
  }

  getListLoyaltyCampaign(query: any): Observable<any> {
    return this.get<any>(`/loyalty/stamp`, {
      params: query
    });
  }

  getListPackageActive(query): Observable<any> {
    return this.get<any>(`/package/active`, { params: query });
  }


  export(params): Observable<any> {
    const httpOptions = {
      params,
      responseType: 'blob'
    };
    return this.get<any>(`/stamp/export`, httpOptions);
  }

  moveStamp(body): Observable<any> {
    return this.post<any>(`/stamp/move`, body);
  }

  getListQrcodeDesignExist(): Observable<any> {
    return this.get<any>(`/config/qrcode-configs`);
  }

  getListQrcodeDesignFrame(): Observable<any> {
    return this.get<any>(`/config/qrcode-frames`);
  }
}
