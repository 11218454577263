import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(body: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/login`, body).pipe(
      map((user) => {
        if (user && user.data && user.data.token) {
          localStorage.setItem('currentUser', JSON.stringify(user.data));
          localStorage.setItem('Access-Token', user.data.token.replaceAll('"', ''));
          this.currentUserSubject.next(user.data);
        }
        return user;
      })
    );
  }

  ssoLogin(body: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/sso`, body).pipe(
      map((user) => {
        if (user && user.data && user.data.token) {
          localStorage.setItem('currentUser', JSON.stringify(user.data));
          localStorage.setItem('Access-Token', user.data.token.replaceAll('"', ''));
          this.currentUserSubject.next(user.data);
        }
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('Access-Token');
    this.currentUserSubject.next(null);
  }

  getOtp(phone: any) {
    return this.http.get<any>(
      `${environment.apiBaseUrl}/forgot-password/request?phone=` + phone
    );
  }

  confirmOtp(body: any) {
    return this.http.post<any>(
      `${environment.apiBaseUrl}/forgot-password/confirm`,
      body
    );
  }

  sendOtp(body: any) {
    return this.http.post<any>(
      `${environment.apiBaseUrl}/forgot-password/change`,
      body
    );
  }

  linkAcc(tokenLink: any, body: any) {
    return this.http.put<any>(
      `${environment.apiBaseUrl}/user/link?token=${tokenLink}`,
      body
    );
  }

  signUpStep1(body: any) {
    return this.http.post(`${environment.apiBaseUrl}/register/request`, body);
  }

  signUpStep2(body: any) {
    return this.http.post(`${environment.apiBaseUrl}/register/confirm`, body);
  }

  resendOtp(query?: any) {
    return this.http.put(
      `${environment.apiBaseUrl}/register/resend-otp`,
      {},
      {
        params: query,
      }
    );
  }
}
