import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-template-three-v2',
  templateUrl: './template-three-v2.component.html',
  styleUrls: ['./template-three-v2.component.scss'],
})
export class TemplateThreeV2Component implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  targetDetail: any = null;
  listProduct: any = [];
  listRelatedTarget: any = [];
  limitDescription = 150;

  constructor(
    private companiesService: CompaniesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      this.data = { ...val };
      if (val.targetId) {
        this.getDetailTargetById(val.targetId);
        this.getListProduct(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 1) {
        this.getRelatedTarget(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 2) {
        this.listRelatedTarget = val.listRelatedTarget
          ? [...val.listRelatedTarget]
          : [];
      }
    });
  }

  getDetailTargetById(id: any) {
    this.companiesService
      .getDetailCompanyById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.targetDetail = res.data ? { ...res.data } : null;
          this.targetDetail.fullAddress = [
            res.data.address,
            res.data.wardName,
            res.data.districtName,
            res.data.provinceName,
            res.data.countryName,
          ]
            .filter((e) => typeof e === 'string' && e !== '')
            .join(', ');
        }
      });
  }

  getListProduct(id: any) {
    const query = {
      businessId: id,
      page: 1,
      pageSize: 4,
    };
    this.companiesService
      .getListProducts(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listProduct = res.data
            ? res.data.map((gg: any) => {
                const tmp = { ...gg };
                tmp._price = this.numberWithCommas(gg.price);
                return tmp;
              })
            : [];
        }
      });
  }

  getRelatedTarget(id: any) {
    const query = {
      page: 1,
      page_size: 7,
    };
    this.companiesService
      .getList(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          if (res.data) {
            res.data.forEach((gg: any) => {
              if (gg.businessId !== id) {
                gg.fullAddress = [gg.address, gg.provinceName]
                  .filter((e) => typeof e === 'string' && e !== '')
                  .join(', ');

                this.listRelatedTarget.push(gg);
              }
            });
          }
        }
      });
  }

  numberWithCommas(x: any) {
    if (x) {
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }
  renderVideo(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  checkTypePDF(type: string) {
    if (type && type.includes('pdf')) { return true; }
    else { return false; }
  }
  onOpenDNSHSocial(key: number, url: string) {
    if (key === 1) {
      return window.open(`mailTo:${url}`);
    }
    if (key === 2) {
      return window.open(url, '_blank');
    }
  }
  goToSocialLink(link: string) {
    window.open(link, '_blank');
  }
  embedYoutubeUrl = (url: string) => {
    if (!url || !url.trim()) { return; }
    return `https://www.youtube.com/embed/${this.idYoutubeUrl(url)}`;
  }
  isValidYoutubeUrl = (input: string) => {
    if (!input || !input.trim()) { return; }
    return /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
      input
    );
  }
  idYoutubeUrl = (url: string) => {
    if (!url || !url.trim()) { return; }
    const rx =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
    return url.match(rx)[1];
  }
}
