import {NzModalRef, NzNotificationService} from 'ng-zorro-antd';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {CampaignSharedService} from '../../services/loyalty/campaign-share.service';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.scss']
})
export class ExportFileComponent implements OnInit {

  @Input() campaign;

  frmAddBonusCode: FormGroup;
  width = '100%';
  Index: any;

  constructor(
    private _formBuilder: FormBuilder,
    public modalRef: NzModalRef<ExportFileComponent>,
    public commonService: CommonService,
    public notification: NzNotificationService,
    public campaignSharedService: CampaignSharedService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getList(this.campaign.id);
  }

  createForm() {
    this.frmAddBonusCode = this._formBuilder.group({
      activities: [null, [
        Validators.maxLength(255)
      ]],
      winners: [null, Validators.maxLength(255)]
    });
  }

  submitForm() {
    const body = {
      activities: this.frmAddBonusCode.get('activities').value,
      winners: this.frmAddBonusCode.get('winners').value
    };
    this.campaignSharedService.postGoogleSheet(this.campaign.id, body).subscribe(res => {
      if (res.statusCode === 200) {
        this.noti('Lưu thành công');
        this.closeModal();
      }
    });
  }

  getList(id) {
    this.campaignSharedService.getGoogleSheet(id).subscribe(res => {

      this.frmAddBonusCode.get('activities').setValue(res.data.activities.link);
      this.frmAddBonusCode.get('winners').setValue(res.data.winners.link);
    });
  }

  checkInput(e) {

  }

  noti(message: string) {
    this.notification.create(
      'success',
      `THÔNG BÁO`,
      `${message}`
    );
  }

  closeModal() {
    this.modalRef.close();
  }

  copyLanding(idx) {
    this.Index = idx;
    const id = JSON.parse(localStorage.getItem('loyaltyUser'));
    this.commonService.copyText(`loyalty-bot@icheck-loyalty-dev.iam.gserviceaccount.com `);
  }

}
