import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/services';
import {
  embedYoutubeUrl,
  numberWithCommas,
  isValidYoutubeUrl,
  checkTypePDF,
  goToSocialLink,
  onOpenDNSHSocial,
} from 'src/app/utility/common/commonFunction';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-template-nine-v1',
  templateUrl: './template-nine-v1.component.html',
  styleUrls: ['./template-nine-v1.component.scss'],
})
export class TemplateNineV1Component implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  constructor(
  ) {}

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      this.data = { ...val };
      console.log('chh_log ---> this.templateData.subscribe ---> this.data', this.data);
    });
  }

}
