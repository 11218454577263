import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class CoreDatahubService extends BaseService {
  listCategoryNoParent(query): Observable<any> {
    return this.get<any>("/categories/root", {params: query});
  }

  listCategoryOfParent(idParent): Observable<any> {
    return this.get<any>(`/categories?parent_id=${idParent}&include=children`);
  }

  listCities(): Observable<any> {
    return this.get<any>(`/cities?limit=100`);
  }

  getCityById(id: number): Observable<any> {
    return this.get<any>(`/cities/${id}`);
  }

  listDistricts(id: number): Observable<any> {
    return this.get<any>(`/districts?city_id=${id}`);
  }

  getDistrictById(id: number): Observable<any> {
    return this.get<any>(`/districts/${id}`);
  }

  listWards(id: number): Observable<any> {
    return this.get<any>(`/wards?district_id=${id}`);
  }

  getWardById(id: number): Observable<any> {
    return this.get<any>(`/wards/${id}`);
  }

  getDetailCategory(id: number): Observable<any> {
    return this.get<any>(`/categories/${id}`);
  }
}
