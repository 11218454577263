import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingQuestionService extends BaseService {

  // ======== setting theo account ======== //
  questionsByAccount(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
    };
    return this.get<any>('/configuration/question-answer', httpOptions);
  }
  // ======== setting theo business ======== //
  questionsByBusiness(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
    };
    return this.get<any>('/configuration/question-answer', httpOptions);
  }
  // tslint:disable-next-line:variable-name
  createQuestionByBusiness(business_id?: any, body?: any): Observable<any> {
    return this.post<any>(`/configuration/question-answer?business_id=${business_id}`, body);
  }
}
