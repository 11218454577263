import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { NzModalService, TransferItem } from 'ng-zorro-antd';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { $e } from 'codelyzer/angular/styles/chars';
import {
  DistributorsService,
  ProductsDistributorsService,
  RootService,
} from '../../../../../services';
import { UploaderComponent } from '../../../../../shared/uploader';
import { DocumentsService } from '../../../../../services/business/documents/documents.service';

@Component({
  selector: 'add-product-distributors',
  templateUrl: './add-product-distributors.component.html',
  styleUrls: ['./add-product-distributors.component.scss'],
})
export class AddProductDistributorsComponent implements OnInit, OnDestroy {
  isVisible = false;
  actions = [
    { label: 'Gán sản phẩm', value: 0 },
    { label: 'Gỡ sản phẩm', value: 1 },
    { label: 'Export', value: 2 },
    { label: 'Export tất cả', value: 3 },
  ];
  current = 0;
  index = 1;
  title;
  list: TransferItem[] = [];
  searchPlaceholder = 'Tên hoặc mã sản phẩm';
  itemsUnit = 'Sản phẩm';
  pageIndex = 1;
  pageSize = 5;
  changeTabEvent = 1;
  city = null;
  districts = null;
  street = null;
  unsubscribe$ = new Subject<void>();
  avatar: any[] = [];
  background: any[] = [];
  images: any[] = [];
  // tslint:disable-next-line:variable-name
  form_step: FormGroup = this.fb.group({
    businessId: [null, [Validators.required]],
    title: [null, [Validators.required]],
    role: [null, [Validators.required]],
  });
  isLoadingBtn = false;

  // tslint:disable-next-line:variable-name
  form_step1: FormGroup = this.fb.group({
    name: [null, [Validators.required, Validators.maxLength(255)]],
    taxCode: [
      null,
      [
        Validators.maxLength(255),
        // Validators.pattern('^[0-9]*$')
        // Validators.pattern('^([0-9]{10})(|)(-[0-9]{3}|)$')
      ],
    ],
    countryId: [null, [Validators.required]],
    address: [null, [Validators.required, Validators.maxLength(255)]],
    provinceId: [null, [Validators.required]],
    districtId: [null, [Validators.required]],
    wardId: [null, [Validators.required]],
    phone: [null, [Validators.pattern('^[0-9]*$')]],
    // phone: [null, [Validators.pattern('^0(1\\d{9}|9\\d{8})$')]],
    email: [
      null,
      [
        Validators.pattern(
          '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
        ),
      ],
    ],
    website: [
      null,
      [Validators.pattern(
        /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
      ), ],
    ],
    avatar: [null],
    background: [null],
    title: [null, [Validators.required]],
    role: [null, [Validators.required]],
  });

  notDocuments = false;
  files: any[] = [];
  listTitle;
  listRole;

  // @ts-ignore
  @ViewChild('selectDocument') selectDocument;
  // @ts-ignore
  @ViewChild('create') create;
  @Output() loadDataParent = new EventEmitter();
  @Input() productId: string;
  optionList: any;
  isLoading = false;
  searchChange$ = new BehaviorSubject('');
  viewDetail;
  countries: any;
  listBusiness: any;
  // tslint:disable-next-line:variable-name
  country_code: any;
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private distributorsService: DistributorsService,
    private rootService: RootService,
    private productsDistributorsService: ProductsDistributorsService,
    private docService: DocumentsService
  ) {
    const optionList$: Observable<string> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500));

    optionList$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      if (value && value.trim().length > 0) {
        const query = {
          pageSize: 1000,
        };
        this.productsDistributorsService
          .listBusiness(query)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            this.optionList = res.data;
            this.isLoading = false;
          });
      } else {
        this.optionList = [];
        this.isLoading = false;
      }
    });
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight',
    });

    this.rootService
      .getRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.listRole = res.data;
      });



    // this.distributorsService.listTitle({roleCode: data.roleCode}).subscribe((res) => {
    //   this.listTitle = res.data;
    // });
  }

  roleChange($event: any) {


    this.listTitle = null;
    this.form_step.get('title').setValue(null);
    this.form_step1.get('title').setValue(null);
    if ($event) {
      this.rootService
        .getTitles({roleId: $event})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.listTitle = res.data;
        });
    }
  }

  onSearch(value: string): void {
    this.isLoading = true;
    this.searchChange$.next(value);
  }

  selectId($id) {
    if ($id) {
      this.distributorsService
        .detailBusiness($id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          this.viewDetail = res.data;
        });
    }
  }

  // searchDistributors($event: any) {
  //
  //
  // }

  // convertItems(items: TransferItem[]): TransferItem[] {
  //   return items.filter(i => !i.hide);
  // }

  select(ret: {}): void {

  }

  change(ret: {}): void {

  }

  changePositionBody() {
    document.body.style.position = 'unset';
  }

  handleCancel(): void {
    this.changePositionBody();
    this.isVisible = false;
    this.avatar = [];
    this.background = [];
    this.viewDetail = null;
    this.files = [];
    this.notDocuments = false;
    this.form_step.reset();
    this.form_step1.reset();
  }

  showModal(): void {
    document.body.style.position = 'fixed';
    this.isVisible = true;
    this.changeTabEvent = 1;
    const query = {
      type: 0, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : 0
    };
    this.rootService
      .getLocations(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.countries = res.data;
      });
    this.getCities();


    this.form_step1.controls.provinceId.setValidators(Validators.required);
    this.form_step1.controls.provinceId.enable();
    this.form_step1.controls.districtId.setValidators(Validators.required);
    this.form_step1.controls.districtId.enable();
    this.form_step1.controls.wardId.setValidators(Validators.required);
    this.form_step1.controls.wardId.enable();
    this.distributorsService.listBusiness({pageSize: 100}).subscribe(res => {
      this.listBusiness = res.data;
    });
  }

  changeTab(event) {
    this.changeTabEvent = event;
    if (this.changeTabEvent === 1) {
      this.notDocuments = false;
      this.files = [];
      this.form_step.reset();
    } else {
      this.notDocuments = false;
      this.files = [];
      this.viewDetail = null;
      this.form_step1.reset();
    }
  }
  getCities() {
    const query = {
      type: 1, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : 0
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.city = res.data;
      },
      (error) => {
        this.city = [];
      }
    );
  }
  countryChange(event) {


    if (event) {
      // this.country_id = event.split('-')[0];
      if (event !== 241) {
        this.form_step1.controls.provinceId.clearValidators();
        this.form_step1.controls.provinceId.disable();
        this.form_step1.controls.districtId.clearValidators();
        this.form_step1.controls.districtId.disable();
        this.form_step1.controls.wardId.clearValidators();
        this.form_step1.controls.wardId.disable();
        return;
      }
      this.form_step1.controls.provinceId.setValidators(Validators.required);
      this.form_step1.controls.provinceId.enable();
      this.form_step1.controls.districtId.setValidators(Validators.required);
      this.form_step1.controls.districtId.enable();
      this.form_step1.controls.wardId.setValidators(Validators.required);
      this.form_step1.controls.wardId.enable();
    }
  }
  onSubmit() {



    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };
    if (this.changeTabEvent === 1) {
      const form = this.form_step;
      recursive(form);

      // if (this.files.length === 0) {
      //   this.notDocuments = true;
      //   return;
      // }




      if (form.invalid) {
        return;
      }

      // const docs = [];
      // this.files.map((item) => {
      //   docs.push(item.documentId);
      // });

      const body = {
        title: this.form_step.get('title').value,
        businessId: this.form_step.get('businessId').value,
        productId: this.productId,
        role: this.form_step.get('role').value,
      };




      this.isLoadingBtn = true;
      this.productsDistributorsService
        .addIdDistributor(body)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res.code === 1) {
              this.notification.create(
                'success',
                'Thành công',
                'Tạo đối tượng thành công'
              );
              this.loadDataParent.emit(true);
              this.handleCancel();
              this.changePositionBody();
              this.isVisible = false;
            } else {
              this.notification.create('warning', 'Cảnh báo', res.message);
            }
            this.isLoadingBtn = false;
          },
          (err) => {
            this.notification.create('error', 'Thất bại', err);
            this.isLoadingBtn = false;
          }
        );
    } else {
      const form = this.form_step1;
      recursive(form);


      if (form.invalid) {
        return;
      }
      const body = {
        productId: this.productId,
        name: this.form_step1.get('name').value,
        countryId: this.form_step1.get('countryId').value ? this.form_step1.get('countryId').value : '',
        address: this.form_step1.get('address').value,
        provinceId: this.form_step1.get('provinceId').value ? this.form_step1.get('provinceId').value : '',
        districtId: this.form_step1.get('districtId').value ? this.form_step1.get('districtId').value : '',
        taxCode: this.form_step1.get('taxCode').value,
        wardId: this.form_step1.get('wardId').value ? this.form_step1.get('wardId').value : '',
        email: this.form_step1.get('email').value,
        website: this.form_step1.get('website').value,
        phone: this.form_step1.get('phone').value,
        avatar: this.form_step1.get('avatar').value,
        background: this.form_step1.get('background').value,
        role: this.form_step1.get('role').value,
        title: this.form_step1.get('title').value,
      };




      // this.isLoadingBtn = true;
      this.productsDistributorsService
        .addDistributor(body)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res.code === 1) {
              this.notification.create(
                'success',
                'Thành công',
                'Tạo đối tượng thành công'
              );
              this.loadDataParent.emit(true);
              this.handleCancel();
              this.changePositionBody();
              this.isVisible = false;
            } else {
              this.notification.create('warning', 'Cảnh báo', res.message);
            }
            this.isLoadingBtn = false;
          },
          (err) => {
            this.notification.create('error', 'Thất bại', err);
            this.isLoadingBtn = false;
          }
        );
    }
  }

  getDistricts($event: any) {
    this.districts = null;
    this.street = null;
    this.form_step1.get('districtId').setValue(null);
    this.form_step1.get('wardId').setValue(null);
    const query = {
      type: 2, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : $event
    };
    if ($event !== null) {
      this.rootService
        .getLocations(query)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.districts = res.data;
        });
    }
  }

  getStreet($event: any) {
    this.street = null;
    this.form_step1.get('wardId').setValue(null);
    const query = {
      type: 3, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : $event
    };
    if ($event !== null) {
      this.rootService
        .getLocations(query)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.street = res.data;
        });
    }
  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          // this[field].push(data);
          // this.form_step.get('avatar').value.push(changeParam.file.response.id);
          if (field === 'avatar') {
            this.form_step1
              .get('avatar')
              .setValue(changeParam.file.response.id);
            this.avatar = [{ url: changeParam.file.response.url }];
          } else if (field === 'background') {
            this.form_step1
              .get('background')
              .setValue(changeParam.file.response.id);
            this.background = [{ url: changeParam.file.response.url }];
          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe((changeParam) => {
          modal.close();
        });
    });
  }

  onRemoveFileAll(field: string) {
    // dùng cho 1 ảnh
    if (field === 'avatar') {
      this.avatar = [];
      this.form_step1.get('avatar').setValue(null);
    } else if (field === 'background') {
      this.background = [];
      this.form_step1.get('background').setValue(null);
    }
  }

  onRemoveFile(field: string, index: number) {
    // dùng cho nhiều ảnh
    this[field].splice(index, 1);
    this.form_step1.get(field).value.splice(index, 1);
  }

  /*document*/
  newDocument(event) {
    this.docService.create(event).subscribe(
      (res) => {
        if (res.code === 1) {
          this.create.hide();
          this.files.push(res.data);
          this.notDocuments = false;
          this.files.map((item) => {
            this.selectDocument.setId(item.documentId, true);
          });
          this.notification.create(
            'success',
            'Thêm giấy tờ thành công',
            res.message
          );
        } else {
          this.notification.create(
            'error',
            'Thêm giấy tờ thất bại',
            res.message
          );
        }
      },
      (err) => {
        this.notification.create('error', 'Thêm giấy tờ thất bại', err);
      }
    );
  }

  delete(file, index) {
    this.selectDocument.setId(this.files[index].documentId, false);
    this.files.splice(index, 1);
    if (!this.files.length) {
      this.notDocuments = true;
    }
  }

  modalDocument() {
    this.selectDocument.setCheckboxAll();
    this.files.map((res) => {
      this.selectDocument.setId(res.documentId, true);
    });
    this.selectDocument.showModal();
  }

  selectDocuments(event) {
    this.notDocuments = event.ids.length === 0;
    const newList = [];
    event.ids.map((item, key) => {
      const data = {
        documentId: item,
        title: event.titles[key],
      };
      newList.push(data);
    });
    this.files = newList;
    this.selectDocument.handleCancel();
  }

  /*End document*/
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
