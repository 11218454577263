import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductsService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-products-setting',
  templateUrl: './products-setting.component.html',
  styleUrls: ['./products-setting.component.scss']
})
export class ProductsSettingComponent implements OnInit {

  constructor(
    private notification: NzNotificationService,
    private productService: ProductsService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  unsubscribe$ = new Subject<void>();
  productId: string;
  managementStatus: number;
  roleCode: string = null;
  ngOnInit() {
    this.productId = window.location.href.split('/')[5];
    this.productService.getById(this.productId).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        if (res.code === 1) {
          this.managementStatus = res.data.managementStatus;
          this.roleCode = res.data.roleCode;
          let body: any = {
            managementStatus: this.managementStatus,
            roleCode: this.roleCode
          }
          this.productService.getManagementStatus(body);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

}
