import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-qrcode-social-preview',
  templateUrl: './qrcode-social-preview.component.html',
  styleUrls: ['./qrcode-social-preview.component.scss']
})
export class QrcodeSocialPreviewComponent implements OnInit {

  @Input() templateData: Observable<any>
  unsubscribe$ = new Subject<void>();
  data: any = null

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      if (val.pdf) {
        this.data = { ...val, pdf: this._sanitizer.bypassSecurityTrustResourceUrl(val.pdf) }
      } else {
        this.data = { ...val }
      }
    })
  }

}
