import { formatDate } from '@angular/common';
import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ProductsService, StampV2Service, SurveyService, UploadService} from 'src/app/services';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { UploaderComponent } from 'src/app/shared';
import { track } from 'src/app/helpers/track';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {environment} from '../../../../../environments/environment';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {URL_LINK} from '../../../../helpers/constant';
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';
@Component({
  selector: 'app-qrcode-social',
  templateUrl: './qrcode-social.component.html',
  styleUrls: ['./qrcode-social.component.scss']
})
export class QrcodeSocialComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private stampService: StampService,
    private router: Router,
    private productService: ProductsService,
    private modalService: NzModalService,
    private surveyService: SurveyService,
    private stampV2Service: StampV2Service,
    private uploadService: UploadService,
  ) {
    // @ts-ignore
  }
  qrcodeConfig = {
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 1000,
    height: 1000,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  };
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    message: [''],
    domainId: [null],
    stampCode: ['SOCIAL_NETWORK'],
    activatedAt: [''],
    coverImage: [''],
    expiredAt: [''],
    surveyId: [null],
    loyaltyCampaignType: [''],
    loyaltyCampaignObject: [''],
    loyaltyGiftCode: [''],
    packageId: [null, [Validators.required]],
    body: new FormArray([]),
    config: new FormGroup({
      logo: new FormControl(''),
      shape: new FormControl('classy'),
      color: new FormControl('#000000'),
      backgroundColor: new FormControl('#ffffff'),
    }),
    setting: new FormGroup({
      requiredCustomerInfo: new FormControl(false),
      allowedScan: new FormControl(true),
      customerInfoForm: new FormControl(false)
    }),
  });
  isValidFormSubmitted = null;
  settingActiveCollapse = {
    showHideInfo: true,
    showOther: true,
    showBusiness: true,
    showLogo: true,
    showColor: true,
    showShape: true,
    showConfig: true,
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',
    },
  };
  listEcommerceImage: Array<any> = [[]];
  listTargetProduct: any = [];
  // listPackage = [];
  @Input() listPackage = [];
  @Input() isChangeStampTypeQRCode = false;
  tabs = [{ id: 1, label: 'Preview' }, { id: 2, label: 'QR Code' }];

  @ViewChild('addProductRelated', { static: false }) addProductRelated;
  @ViewChild('deleteProductRelateds', { static: false }) deleteProductRelateds;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;

  heightEl = 500;
  listBusinessProduct = [];
  hiddenProductDetail = false;

  public config = {
    placeholder: '',
    disableDragAndDrop: true,
    tabsize: 2,
    height: '150px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      // ["insert", ["link", "video"]],
      ['insert', ['link']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  controls: any = this.form.controls;

  listYear = [
    { value: 1, label: '1 năm' },
    { value: 2, label: '2 năm' },
    { value: 3, label: '3 năm' },
  ];

  listTypeEcommerce = [
    {
      value: 1,
      label: 'Tiki',
    },
    {
      value: 2,
      label: 'Shopee',
    },
    {
      value: 4,
      label: 'Sendo',
    },
    {
      value: 3,
      label: 'Lazada',
    },
    {
      value: 0,
      label: 'Khác',
    },
  ];

  regUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  listSetting = [
    {
      value: 1,
      label: 'Yêu cầu thông tin khách hàng',
      key: 'requiredCustomerInfo',
    },
    { value: 2, label: 'Cho App khác quét', key: 'allowedScan' },
    { value: 3, label: 'Ẩn chi tiết sản phẩm', key: 'hideProduct' },
    { value: 4, label: 'Ẩn DN phân phối', key: 'hideDistributor' },
    { value: 5, label: 'Ẩn DN sở hữu', key: 'hideOwner' },
    { value: 6, label: 'Ẩn điểm bán', key: 'hideShop' },
    { value: 7, label: 'Ẩn sản phẩm liên quan', key: 'hideRelatedProducts' },
    { value: 8, label: 'Ẩn thông báo', key: 'hideNotification' },
    { value: 9, label: 'Ẩn giá', key: 'hidePrice' },
    { value: 10, label: 'Ẩn giới thiệu sản phẩm', key: 'hideProductIntro' },
  ];

  listStamp = [];
  totalPage = 0;
  page = 1;
  pageSize = 3;
  currentPage;
  firstTempale: any;

  id;
  templateId;

  isUpdate = false;
  errorExpiredAt = false;

  indeterminateDistributor = false;

  listRelatedProduct = [];

  firstTimeLoadTemplate = false;

  templateData: any = {};
  templateDataSubject: any;
  configData: any = {};
  templateConfigSubject: any;
  qrcodeConfigPreview = null;
    @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;

  listSurveys = [];

  listDomain = [];

  validExpiredAt = true;
  showListRelatedProduct = false;

  indeterminateProductDetail = false;


  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  isShow;
  hideOwner = false;
  hideShop = false;
  hideDistributor = false;

  listProduct = [];


  currentPackage: any;


  isLoadingBtn = false;
  clickSubmit = false;

  viewCurrentTemp;

  files = [];
  logos = [];
  backgrounds = [];

  totalTargetProduct = 0;
  isNewQr: true;
  loadingDrawQrcode;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const mapElement: any = document.querySelector(
      '.img-template'
    ) as HTMLElement;
    this.heightEl =
      mapElement && mapElement.offsetWidth ? mapElement.offsetWidth * 2 : 500;
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_form_social',
    //   contentType: 'qrcode',
    // })
    this.templateDataSubject = new BehaviorSubject(this.templateData);
    this.templateConfigSubject = new BehaviorSubject(this.configData);
    this.getListSurvey();
    this.getListDomainByUser();
    this.getListProduct();
    // this.temps = this.listTemp;
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== 'null') {
      this.isUpdate = true;
      this.getStampV2Detail(this.id);
      if (this.isChangeStampTypeQRCode) {
        this.addBodyLink();
      }
    } else {
      this.addBodyLink();
      this.isNewQr = true;
    }

    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    this.changeValueForm();
    this.getListPackageActive();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges() {
    this.isValidFormSubmitted = this.isChangeStampTypeQRCode ? false : null;
  }

  getStampV2Detail(id) {
    this.stampService.getStampById(id).subscribe(async res => {
      if (res.code === 1 && res.data) {
        // const data = res.data;
        const data = {
          ...res.data,
          newQr: true
        };
        this.isNewQr = data.newQr;
        let activatedAtConverted = '';
        if (data.activatedAt) {
          const activatedAt = data.activatedAt.split('/');
          // tslint:disable-next-line:variable-name
          activatedAtConverted = [
            activatedAt[1],
            activatedAt[0],
            activatedAt[2],
          ].join('/');
        }

        const expiredAt = data.expiredAt.split('/');
        // tslint:disable-next-line:variable-name
        const expiredAtConverted = [
          expiredAt[1],
          expiredAt[0],
          expiredAt[2],
        ].join('/');
        if (data.packageInfo && data.packageInfo.id) {
          this.listPackage = [{...data.packageInfo}];
          this.form.patchValue({
            packageId: data.packageInfo.id
          });
        }
        if (data.stampData && data.stampData && data.stampData.coverImage) {
          this.backgrounds = [{url: data.stampData.coverImage}];
        }

        if (data.loyaltyCampaign) {
          this.form.patchValue({
            loyaltyCampaignType: data.loyaltyCampaign.type,
            loyaltyCampaignObject: data.loyaltyCampaign.code,
            loyaltyGiftCode: data.loyaltyCampaign.giftCode,
          });
        }
        if (data.stampData && data.stampData.body && data.stampData.body.length) {
          data.stampData.body.forEach(element => {
            let placeholder = 'Ghé thăm Website của tôi';
            let label = 'Website';
            switch (element.key) {
              case 'website':
                placeholder = 'Ghé thăm Website của tôi';
                label = 'Website';
                break;
              case 'linked-in':
                placeholder = 'Ghé thăm LinkedIn của tôi';
                label = 'LinkedIn';
                break;
              case 'twitter':
                placeholder = 'Ghé thăm Twitter của tôi';
                label = 'Twitter';
                break;
              case 'facebook':
                placeholder = 'Ghé thăm Facebook của tôi';
                label = 'Facebook';
                break;
              case 'ecommerce':
                placeholder = 'Ghé thăm trang bán hàng của tôi';
                label = 'Thương mại điện tử';
                break;
              case 'youtube':
                placeholder = 'Ghé thăm Youtube của tôi';
                label = 'Youtube';
                break;
              default:
                break;
            }
            // tslint:disable-next-line:no-shadowed-variable
            const data = {
              ...element,
              label,
              placeholder
            };
            this.addBodyLink(data);
          });
        }
        this.form.patchValue({
          name: data.name,
          targetId: !this.isChangeStampTypeQRCode ? data.targetId : null,
          activatedAt: activatedAtConverted,
          expiredAt: expiredAtConverted,
          coverImage: data.stampData && data.stampData.data && data.stampData.data.coverImage ? data.stampData.data.coverImage : '',
          setting: {
            requiredCustomerInfo: data.setting.requiredCustomerInfo,
            allowedScan: data.setting.allowedScan,
            customerInfoForm: data.setting.customerInfoForm
          },
          domainId: data.domainId ? data.domainId : null,
        });
        if (data.setting.customerInfoForm && !data.setting.requiredCustomerInfo) {
          this.form.get('setting.requiredCustomerInfo').setValue(data.setting.requiredCustomerInfo);
        }
        if (data.newQr) {
          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType, data.config, data.frameConfig, data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(data.qrCode);
        } else {
          if (data.config && data.config.color) {
            this.form.get('config.color').setValue(data.config.color);
          }
          if (data.config && data.config.backgroundColor) {
            this.form
              .get('config.backgroundColor')
              .setValue(data.config.backgroundColor);
          }
          if (data.config && data.config.logo) {
            this.form.get('config.logo').setValue(data.config.logo);
            this.logos = [{ url: data.config.logo }];
          }
          if (data.config && data.config.shape) {
            this.form.get('config.shape').setValue(data.config.shape);
          }
        }
      }
    });
  }

  addBodyLink(item?) {
    (this.form.get('body') as FormArray).push(
      this.initBodyItem(item)
    );
  }


  selectedQrCode(value) {
    if (this.form.get('config.shape').value !== value) {
      this.form.get('config.shape').setValue(value);
    } else {
      this.form.get('config.shape').setValue('');
    }
  }


  addFastLink(type) {
    const linkExist = this.form.value.body.map(item => item.key).findIndex(val => val === type);
    if (linkExist >= 0) {
      this.notification.warning(
        'Có lỗi xảy ra',
        'Liên kết đã tồn tại'
      );
      return;
    }
    switch (type) {
      case 'website':
        this.addBodyLink({
          key: 'website',
          label: 'Website',
          placeholder: 'Ghé thăm Website của tôi'
        });
        break;
      case 'linked-in':
        this.addBodyLink({
          key: 'linked-in',
          label: 'LinkedIn',
          placeholder: 'Ghé thăm LinkedIn của tôi'
        });
        break;
      case 'twitter':
        this.addBodyLink({
          key: 'twitter',
          label: 'Twitter',
          placeholder: 'Ghé thăm Twitter của tôi'
        });
        break;
      case 'fb':
        this.addBodyLink({
          key: 'facebook',
          label: 'Facebook',
          placeholder: 'Ghé thăm Facebook của tôi'
        });
        break;
      case 'ecommerce':
        this.addBodyLink({
          key: 'ecommerce',
          label: 'Thương mại điện tử',
          placeholder: 'Ghé thăm trang bán hàng của tôi'
        });
        break;
      case 'youtube':
        this.addBodyLink({
          key: 'youtube',
          label: 'Youtube',
          placeholder: 'Ghé thăm Youtube của tôi'
        });
        break;
      default:
        break;
    }
  }

  initBodyItem(item?) {
    if (item) {
      return this.fb.group({
        key: [item.key || 'website'],
        label: [item.label || 'Website'],
        title: [item.title || ''],
        link: [item.link || '', [Validators.required, Validators.pattern(this.regUrl)]],
        placeholder: [item.placeholder || 'Ghé thăm website của tôi'],
        allowRemove: [true],
      });
    } else {
      return this.fb.group({
        key: ['website'],
        label: ['Website'],
        title: [''],
        link: ['', [Validators.required, Validators.pattern(this.regUrl)]],
        placeholder: ['Ghé thăm website của tôi'],
        allowRemove: [true],
      });
    }

  }
  getListSurvey() {

    const query = {
      page: 1,
      pageSize: 200,
      type: 'BASIC'
    };

    this.surveyService
      .getListSurveys(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listSurveys = res && res.data ? [...res.data] : [];
          } else {
            this.notification.warning(
              'Có lỗi xảy ra',
              res && res.message ? res.message : 'Vui lòng thử lại'
            );
          }
        },
        (err: any) => {
          this.notification.error(
            'Có lỗi xảy ra',
            err && err.message ? err.message : 'Vui lòng thử lại'
          );
        }
      );
  }


  getListPackageActive() {
    // this.stampService.getListPackageActive({type: 1}).subscribe(res => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }
  getListDomainByUser() {
    const query: any = {
      page: 1,
      pageSize: 100,
    };

    this.stampService
      .getListDomain(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  changeListRelatedProduct(list) {
  }

  deleteSetting(id) {
    const data = {
      label: 'Xác nhận xóa sản phẩm liên quan',
      value:
        ' <p class="mrb-10">Hệ thống xóa sản phẩm liên quan này.</p>\n' +
        '          <p>Bạn có chắc chắn muốn xoá?</p>',
    };
    this.deleteProductRelateds.showModal(data, id);
  }

  handleDeleteRelateds(event) {
    this.listRelatedProduct = this.listRelatedProduct.filter(
      (item) => item.id !== event[0]
    );
    this.templateData.customRelatedProducts = [...this.listRelatedProduct];
    this.templateDataSubject.next(this.templateData);
    this.deleteProductRelateds.success();
    this.deleteProductRelateds.handleCancel();
    this.notification.success(
      'Thành công',
      'Xóa sản phẩm liên quan thành công'
    );
  }
  changeValueForm() {
    this.form.valueChanges.subscribe((val: any) => {
      this.templateData = this.form.value;
      this.templateDataSubject.next(this.templateData);
    });

    this.form.get('config').valueChanges.subscribe((val: any) => {
      this.configData = {
        ...this.form.value,
        config: { ...this.form.get('config').value },
      };
      this.templateConfigSubject.next(this.configData);
    });
  }

  changeStatusHiddenBusiness() {
    if (
      this.form.controls.setting.value.hideDistributor &&
      this.form.controls.setting.value.hideOwner &&
      this.form.controls.setting.value.hideShop
    ) {
      this.form.patchValue({
        setting: {
          hiddenBusiness: true,
        },
      });
      this.indeterminateDistributor = false;
    } else if (
      this.form.controls.setting.value.hideDistributor ||
      this.form.controls.setting.value.hideOwner ||
      this.form.controls.setting.value.hideShop
    ) {
      this.indeterminateDistributor = true;
    } else {
      this.indeterminateDistributor = false;
      this.form.patchValue({
        setting: {
          hiddenBusiness: false,
        },
      });
    }
  }
  changeStatusHiddenProductDetail() {
    if (
      this.form.controls.setting.value.hidePrice &&
      this.form.controls.setting.value.hideVideo &&
      // this.form.controls.setting.value.hideCategories &&
      this.form.controls.setting.value.hideProductDescription
    ) {
      this.form.patchValue({
        setting: {
          hiddenProductDetail: true,
        },
      });
      this.indeterminateProductDetail = false;
    } else if (
      this.form.controls.setting.value.hidePrice ||
      this.form.controls.setting.value.hideVideo ||
      // this.form.controls.setting.value.hideCategories ||
      this.form.controls.setting.value.hideProductDescription
    ) {
      this.indeterminateProductDetail = true;
    } else {
      this.indeterminateProductDetail = false;
      this.form.patchValue({
        setting: {
          hiddenProductDetail: false,
        },
      });
    }
  }

  checkAllHiddenBusiness() {
    if (
      this.form.controls.setting.value.hideDistributor &&
      this.form.controls.setting.value.hideOwner &&
      this.form.controls.setting.value.hideShop
    ) {
      this.form.patchValue({
        setting: {
          hiddenBusiness: false,
          hideDistributor: false,
          hideOwner: false,
          hideShop: false,
        },
      });
      this.indeterminateDistributor = false;
    } else {
      this.form.patchValue({
        setting: {
          hiddenBusiness: true,
          hideDistributor: true,
          hideOwner: true,
          hideShop: true,
        },
      });
      this.indeterminateDistributor = false;
    }
  }

  checkAllHiddenProduct() {
    if (
      this.form.controls.setting.value.hidePrice &&
      this.form.controls.setting.value.hideVideo &&
      // this.form.controls.setting.value.hideCategories &&
      this.form.controls.setting.value.hideProductDescription
    ) {
      this.form.patchValue({
        setting: {
          hiddenProductDetail: false,
          // hideSku: false,
          hidePrice: false,
          hideVideo: false,
          // hideCategories: false,
          hideProductDescription: false,
        },
      });
      this.indeterminateProductDetail = false;
    } else {
      this.form.patchValue({
        setting: {
          hiddenProductDetail: true,
          // hideSku: true,
          hidePrice: true,
          hideVideo: true,
          // hideCategories: true,
          hideProductDescription: true,
        },
      });
      this.indeterminateProductDetail = false;
    }
  }

  getDate(date, tmpNumber) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (tmpNumber === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.businessProductId] = value)
    );
    this.refreshStatus();
  }



  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.businessProductId]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.businessProductId]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value === true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  updateStatus(type) {
    if (type === 'hidden') {
      this.listTargetProduct.map(item => {
        if (this.ids.includes(item.businessProductId)) {
          item.isShow = false;
        }
        return item;
      });
    } else {
      this.listTargetProduct.map(item => {
        if (this.ids.includes(item.businessProductId)) {
          item.isShow = true;
        }
        return item;
      });
    }
    this.mapOfCheckedId = {};
  }
  getListProduct() {
    const query: any = {
      page: 1,
      pageSize: 100,
      hidden: false
    };

    this.productService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listProduct = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }


  removeLink(index) {
    (this.form.get('body') as FormArray).removeAt(index);
  }


  getListTemplate() {
    const body =
      this.templateId && this.isUpdate
        ? {
          page: this.page,
          pageSize: this.pageSize,
          type: 1,
          toTop: this.firstTempale,
        }
        : {
          page: this.page,
          pageSize: this.pageSize,
          type: 1,
        };
    this.stampService.getAllTemplate(body).subscribe((res) => {
      if (res.code === 1) {
        this.listStamp = res.data;
        this.totalPage = Math.ceil(res.totalElement / res.pageSize);
        this.currentPage = res.currentPage;
        if (!this.firstTimeLoadTemplate) { this.firstTimeLoadTemplate = true; }
        this.viewCurrentTemp = this.listStamp.filter(
          (item) => item.id === this.templateId
        )[0];
      }
    });
  }

  showAddProductRelated() {
    const listTargetId = this.listRelatedProduct.map((item) => item.id);

    if (this.form.value.targetId) {
      listTargetId.push(this.form.value.targetId);
    }
    this.addProductRelated.showModal(listTargetId);
  }

  successAddProductRelated(event) {
    this.listRelatedProduct = [...this.listRelatedProduct, ...event];
    this.templateData.customRelatedProducts = [...this.listRelatedProduct];
    this.templateDataSubject.next(this.templateData);
    this.addProductRelated.handleCancel();
    // if (event === 1) {
    //   this.addProductRelated.handleCancel();
    // }
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  nextPage() {
    if (this.page === this.totalPage) { return; }
    this.page += 1;
    this.getListTemplate();
  }

  prevPage() {
    if (this.page === 1) { return; }
    this.page -= 1;
    this.getListTemplate();
  }
  selectedPackage(event) {
    if (event) {
      this.currentPackage = this.listPackage.find(item => item.id === event);
    }
  }
  async submit() {
    this.isValidFormSubmitted = false;
    this.clickSubmit = true;
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };


    recursive(this.form);
    if (this.form.invalid) {
      this.templateQRCodeViewRef.onChangeTabActive();
      return;
    }
    this.isValidFormSubmitted = true;
    this.isLoadingBtn = true;
    const newForm = {
      ...this.form.value,
      activatedAt: this.form.value.activatedAt
        ? this.getDate(this.form.value.activatedAt, 1)
        : '',
      data: { body: this.form.value.body, coverImage: this.form.value.coverImage },
      expiredAt: this.form.value.expiredAt ? this.getDate(this.form.value.expiredAt, 1) : this.currentPackage.expiredAt,
      type: 6,
    };
    if (this.isNewQr) {
      const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
      newForm.config = {...this.qrcodeDesignComponentRef.getQrcodeDesignConfig()};
      newForm.qrcodeFrameId = this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
      const formData = new FormData();
      formData.append('file', base64QrcodeSvg);
      const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
      if (qrImageResponse.status === 200) {
        newForm.qrImage = qrImageResponse.data || '';
      }
    }
    // track("manualTrackInteractionContent", {
    //   interaction: "click",
    //   contentName: "save",
    //   regionName: "portal_stamp_form_social",
    //   target: `${window.location.origin}/portal/stamp`,
    //   payload: JSON.stringify(newForm),
    // })

    if (this.isUpdate) {
      if (this.isNewQr) {
        this.stampService
          .update(this.id, newForm)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            if (res.code === 1) {
              // track("alert", {
              //   alertType: "success",
              //   alertMessage: "Thêm mới QR Code thành công"
              // });
              this.openMessage('success', 'Thành công', 'Cập nhật thành công');
              let delayTimer: any;
              clearTimeout(delayTimer);
              delayTimer = setTimeout(() => {
                this.router.navigate(['/portal/stamp']);
                return;
              }, 500);
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
            this.isLoadingBtn = false;
          }, error => {
            this.isLoadingBtn = false;
            this.openMessage('error', 'Lỗi !!!', `${error}`);
          });
      } else {
        const qrcodeData = `${environment.redirectScanUrl}/${this.id}`;
        const tmpQrcodeConfig = {
          ...this.qrcodeConfig,
          backgroundOptions: {
            color: this.form.get('config.backgroundColor').value
          },
          dotsOptions: {
            type: this.form.get('config.shape').value,
            color: this.form.get('config.color').value,
          },
          image: this.form.get('config.logo').value,
          data: qrcodeData,
        };
        const qrcodeInstance = new QRCodeStyling(tmpQrcodeConfig as any);
        const rawQrcodeImage = await qrcodeInstance.getRawData();
        const formData = new FormData();
        formData.append('content', rawQrcodeImage, 'qrcode.png');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.uploadService.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (result) => {
            if (result.status === 200 && result.data && result.data.url) {
              newForm.qrImage = result.data.url;
              this.stampService
                .update(this.id, newForm)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  if (res.code === 1) {
                    this.openMessage('success', 'Thành công', 'Cập nhật thành công');
                    let delayTimer: any;
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(() => {
                      this.router.navigate(['/portal/stamp']);
                      return;
                    }, 500);
                  } else {
                    this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                  }
                  this.isLoadingBtn = false;
                }, error => {
                  this.isLoadingBtn = false;
                  this.openMessage('error', 'Lỗi !!!', `${error}`);
                });
            }
          },
          (err) => {
            this.openMessage('error', 'Cập nhật không thành công', `Vui lòng thử lại`);
          }
        );
      }
    } else {
      newForm.qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
      this.stampService
        .create(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track("alert", {
            //   alertType: "success",
            //   alertMessage: "Thêm mới QR Code thành công"
            // });
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/stamp']);
              return;
            }, 500);
          } else {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.isLoadingBtn = false;
        }, error => {
          this.isLoadingBtn = false;
          this.openMessage('error', 'Lỗi !!!', `${error}`);
        });
    }
  }

  changePage(event) {
  }

  selectTemplate(stamp) {
    this.templateId = stamp.id;
    this.form.controls.templateId.setValue(stamp.id);
    this.templateDataSubject.next(this.templateData);
  }
  viewTemplate(stamp) {
    this.viewCurrentTemp = stamp;

  }

  back() {
    this.router.navigate(['/portal/stamp']);
  }

  initFormEcommerceItem() {
    return this.fb.group({
      logo: [''],
      name: ['', [Validators.required]],
      link: ['', [Validators.required]],
      price: ['', [Validators.required]],
      priceDiscount: [''],
      type: [0],
    });
  }
  updateFormEcommerceItem(data: any) {
    return this.fb.group({
      logo: [data.logo],
      name: [data.name, [Validators.required]],
      link: [data.link, [Validators.required]],
      price: [data.price, [Validators.required]],
      priceDiscount: [data.priceDiscount],
      type: [data.type],
    });
  }
  addFormEcommerceItem() {
    (this.form.get('ecommerceList') as FormArray).push(
      this.initFormEcommerceItem()
    );
    this.listEcommerceImage.push([]);
  }
  removeFormEcommerceItem(index: number) {
    (this.form.get('ecommerceList') as FormArray).removeAt(index);
    this.listEcommerceImage.splice(index, 1);
  }
  openUploadDialog(field: string, index?) {


    const fileType = 'jpg,jpeg,png,gif,bmp,mp4,pdf,docx';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logos') {
            this.logos = [{ url: changeParam.file.response.url }];


            this.form
              .get('config.logo')
              .setValue(this.logos[0].url);
            this.form.updateValueAndValidity();

          }
          if (field === 'backgrounds') {
            this.backgrounds = [{ url: changeParam.file.response.url }];

            this.form
              .get('coverImage')
              .setValue(this.backgrounds[0].url);
            this.form.updateValueAndValidity();

          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  onRemoveFile(field: string) {
    this[field] = [];
    if (field === 'logos') {
      this.form
              .get('config.logo')
              .setValue('');
    } else if (field === 'backgrounds') {
      this.form
              .get('coverImage')
              .setValue('');
    }
  }

  onChangeTypeEcommerce(value: FormControl, index: number) {
    const type = value.get('type').value;

    switch (type) {
      case 1:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/tiki.png',
        };
        value.get('name').setValue('Tiki');
        break;
      case 2:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/shopee.png',
        };
        value.get('name').setValue('Shopee');
        break;
      case 3:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/lazada.png',
        };
        value.get('name').setValue('Lazada');
        break;
      case 4:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/sendo.png',
        };
        value.get('name').setValue('Sendo');
        break;
      case 0:
        this.listEcommerceImage[index] = [];
        value.get('name').setValue('');
        break;

      default:
        break;
    }
  }
  getListTargetProduct(
    productId: string,
    hiddenOwnerList: any,
    hiddenDistributorList: any,
    hiddenShopList: any
  ) {
    const query = {
      status: 1,
      page: 1,
      pageSize: 100,
      productId,
    };
    this.stampService
      .getListTargetProduct(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listTargetProduct = res.data ? [...res.data] : [];
          this.totalTargetProduct = res.totalElement || 0;
          if (res.data) {
            this.listTargetProduct = res.data.map((gg: any) => {
              const tmp = Object.assign({}, gg);
              tmp.isShow = true;
              if (
                (hiddenOwnerList &&
                  hiddenOwnerList.includes(gg.businessProductId) &&
                  gg.roleCode === 'OWNER') ||
                (hiddenDistributorList &&
                  hiddenDistributorList.includes(gg.businessProductId) &&
                  gg.roleCode === 'DISTRIBUTOR') ||
                (hiddenShopList &&
                  hiddenShopList.includes(gg.businessProductId) &&
                  gg.roleCode === 'SHOP')
              ) {
                tmp.isShow = false;
              }
              return tmp;
            });
            this.templateData.listTargetProduct = [...this.listTargetProduct];
            this.templateDataSubject.next(this.templateData);
          }
        }
      });
  }

  onChangeTargetId(e: any) {
    if (e) {
      this.getListTargetProduct(e, null, null, null);
    } else {
      this.listTargetProduct = [];
      this.templateData.listTargetProduct = [...this.listTargetProduct];
      this.templateDataSubject.next(this.templateData);
    }
  }

  changeShowTargetProduct(data: any) {
    data.isShow = !data.isShow;
    this.templateData.listTargetProduct = [...this.listTargetProduct];
    this.templateDataSubject.next(this.templateData);
  }

  onChangeInfoForm(e) {
    this.form.get('setting.requiredCustomerInfo').setValue(e);
  }

  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }

  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }

  ngOnDestroys() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
