
export function goToSocialLink(link: string) {
  return window.open(link, '_blank');
}
export function embedYoutubeUrl(url: string) {
  if (!url || !url.trim()) { return; }
  console.log('https://www.youtube.com/embed/${idYoutubeUrl(url)}', `https://www.youtube.com/embed/${idYoutubeUrl(url)}`);
  return `https://www.youtube.com/embed/${idYoutubeUrl(url)}`;
}
export function isValidYoutubeUrl(input: string) {
  if (!input || !input.trim()) { return; }
  return /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
    input
  );
}
export function idYoutubeUrl(url: string) {
  if (!url || !url.trim()) { return; }
  const rx =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  return url.match(rx)[1];
}

export function numberWithCommas(x: any) {
  if (x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } else {
    return '';
  }
}
export function checkTypePDF(type: string) {
  if (type && type.includes('pdf')) { return true; } else { return false; }
}

export function onOpenDNSHSocial(key: number, url: string) {
  if (key === 1) {
    return window.open(`mailTo:${url}`);
  }
  if (key === 2) {
    return window.open(url, '_blank');
  }
}
