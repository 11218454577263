import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {NzModalService, TransferItem} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {DistributorsService, ProductsDistributorsService, RootService} from "../../../../../services";
import {DocumentsService} from "../../../../../services/business/documents/documents.service";

// import {DistributorsService} from "../../../services";

@Component({
  selector: 'edit-title-distributors',
  templateUrl: './edit-product-distributors.component.html',
  styleUrls: ['./edit-product-distributors.component.scss']
})
export class EditProductDistributorsComponent implements OnInit, OnDestroy {
  isVisible = false;
  actions = [
    {label: 'Gán sản phẩm', value: 0},
    {label: 'Gỡ sản phẩm', value: 1},
    {label: 'Export', value: 2},
    {label: 'Export tất cả', value: 3},
  ];
  // tslint:disable-next-line:variable-name
  // form_step1: FormGroup = this.fb.group({
  //   name: [null, [Validators.required]],
  //   title: [null, [Validators.required]],
  // });
  current = 0;
  index = 1;
  name;
  title;
  radioValue = 'A';
  confirmType = 1;
  list: TransferItem[] = [];
  // disabled = false;
  showSearch = true;
  searchPlaceholder = 'Tên hoặc mã sản phẩm';
  itemsUnit = 'Sản phẩm';
  pageIndex = 1;
  pageSize = 5;
  typeSubmit = false;
  document = null;
  // tslint:disable-next-line:variable-name
  form_step: FormGroup = this.fb.group({
    title: [null, [Validators.required]],
    role: [null, [Validators.required]],
  });
  detailProduct;
  listRole;
  listTitle;
  businessProductId;
  @Output() loadDataParent = new EventEmitter();
  notDocuments = false;
  files: any[] = [];
  // @ts-ignore
  @ViewChild('selectDocument') selectDocument;
  // @ts-ignore
  @ViewChild('create') create;
  unsubscribe$ = new Subject<void>();
  productId;
  isLoadingBtn = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private distributorsService: DistributorsService,
    private productsDistributorsService: ProductsDistributorsService,
    private docService: DocumentsService,
    private rootService: RootService
  ) {
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });

  }

  select(ret: {}): void {

  }

  change(ret: {}): void {

  }

  handleCancel(): void {
    this.isVisible = false;
    this.typeSubmit = false;
    this.files = null;
    this.form_step.reset();
  }

  showModal(data, businessProductId, productId): void {
    this.isVisible = true;

    this.businessProductId = businessProductId;
    this.productId = productId;
    // this.detailProduct = data;
    this.form_step.get('title').setValue(data.title);
    this.form_step.get('role').setValue(data.role);
    // const listDocument = [];
    // data.documents.map((res) => {
    //   listDocument.push({
    //     documentId: res.id,
    //     title: res.title
    //   });
    // });


    // this.files = listDocument;

    this.rootService.getRoles().subscribe((res:any) => {
      this.listRole = res.data;
    });
    this.rootService.getTitles({roleId: data.role}).subscribe((res: any) => {
      this.listTitle = res.data;
    });
  }

  onSubmit() {
    this.typeSubmit = true;
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    const form = this.form_step;
    recursive(form);

    if (form.invalid) {
      return;
    }

    const body = {
      title: this.form_step.get('title').value,
      businessProductId: this.businessProductId,
      role: this.form_step.get('role').value
    };
    this.isLoadingBtn = true;

    this.productsDistributorsService.editDistributor(body).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {

      if (res.code === 1) {
        this.notification.create(
          'success',
          'Thành công',
          'Sửa sản phẩm thành công'
        );
        this.loadDataParent.emit(true);
        this.handleCancel();
      } else {
        this.notification.create(
          'warning',
          'Cảnh báo',
          res.message
        );
      }
      this.isLoadingBtn = false;

    }, (err) => {
      this.notification.create(
        'error',
        'Thất bại',
        err
      );
      this.isLoadingBtn = false;

    });
  }


  roleChange($event: any) {
    this.listTitle = null;
    this.form_step.get('title').setValue(null);
    if ($event) {
      this.rootService
        .getTitles({roleId: $event})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.listTitle = res.data;
        });
    }
  }


  /*document*/
  newDocument(event) {

    this.docService.create(event).subscribe(
      res => {
        if (res.code === 1) {
          this.create.hide();
          this.files.push(res.data);
          this.notDocuments = false;
          this.files.map((item) => {
            this.selectDocument.setId(item.documentId, true);
          });
          this.notification.create(
            'success',
            'Thêm giấy tờ thành công',
            res.message
          );
        } else {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message
          );
        }
      },
      err => {
        this.notification.create(
          'error',
          'Thêm giấy tờ thất bại',
          err
        );
      }
    );
  }

  delete(file, index) {
    this.selectDocument.setId(this.files[index].documentId, false);
    this.files.splice(index, 1);
    if (!this.files.length) {
      this.notDocuments = true;
    }
  }

  modalDocument() {
    this.selectDocument.setCheckboxAll();
    this.files.map((res) => {
      this.selectDocument.setId(res.documentId, true);
    });
    this.selectDocument.showModal();
  }

  selectDocuments(event) {
    this.notDocuments = event.ids.length === 0;
    const newList = [];
    event.ids.map((item, key) => {
      const data = {
        documentId: item,
        title: event.titles[key]
      };
      newList.push(data);
    });
    this.files = newList;
    this.selectDocument.handleCancel();

  }

  /*End document*/
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
