import { Component, OnInit, OnDestroy } from "@angular/core";
import { ProductsService } from "src/app/services";
import { publishReplay, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-products-detail",
  templateUrl: "./products-detail.component.html",
  styleUrls: ["./products-detail.component.scss"],
})
export class ProductsDetailComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  constructor(private productService: ProductsService) {}

  isDisabled: Boolean;
  isShow = 1;
  ngOnInit() {
    if (window.location.href.split("/")[5] !== 'null') {
      this.isShow = 2;
    } else this.isShow = 1;
    
    this.productService.currentManagementStatus
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        if (status.managementStatus < 0) {
          this.isDisabled = true;
          return;
        }
        if (
          !status.roleCode &&
          (status.managementStatus === 0 ||
            status.managementStatus === 1 ||
            status.managementStatus === 2 ||
            status.managementStatus === 3)
        ) {
          this.isDisabled = true;
          return;
        }
        if (
          status.roleCode &&
          status.roleCode === "PRODUCT_MASTER" &&
          status.managementStatus > 0
        ) {
          this.isDisabled = false;
          return;
        }
        if (
          status.roleCode &&
          status.roleCode === "PRODUCT_DISTRIBUTOR" &&
          status.managementStatus > 0
        ) {
          this.isDisabled = true;
          return;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
