import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyFormComponent } from './survey-tab/survey-form/survey-form.component';
import { SurveySettingComponent } from './survey-tab/survey-setting/survey-setting.component';
import { SurveyTabComponent } from './survey-tab/survey-tab.component';
import { SurveyComponent } from './survey.component';
import {ServeyListComponent} from './servey-list/servey-list.component'
import {SurveyDashboardComponent} from './survey-dashboard/survey-dashboard.component'
import {SurveyHistoriesComponent} from './survey-histories/survey-histories.component'
import {ServeyGroupListComponent} from './servey-group-list/servey-group-list.component'
import {SurveyDetailComponent} from './survey-detail/survey-detail.component'
import {SurveyDetailDashboardComponent} from './survey-detail/survey-detail-dashboard/survey-detail-dashboard.component'
import {SurveyDetailResultsComponent} from './survey-detail/survey-detail-results/survey-detail-results.component'
import {SurveyDetailPersonalComponent} from './survey-detail/survey-detail-personal/survey-detail-personal.component'

const routes: Routes = [
  {
    path: '',
    component: SurveyComponent,
    children: [
      {
        path: "",
        component: ServeyListComponent
      },
      {
        path: "dashboard",
        component: SurveyDashboardComponent,
      },
      {
        path: "histories",
        component: SurveyHistoriesComponent,
      },
      // {
      //   path: "groups",
      //   component: ServeyGroupListComponent,
      // },
    ]
  },
  {
    path: ':id',
    component: SurveyTabComponent,
    children: [
      {
        path: 'form',
        component: SurveyFormComponent
      },
      {
        path: 'setting',
        component: SurveySettingComponent
      },
    ]
  },
  {
    path: 'detail/:id',
    component: SurveyDetailComponent,
    children: [
      {
        path: "dashboard",
        component: SurveyDetailDashboardComponent
      },
      {
        path: "results",
        component: SurveyDetailResultsComponent
      },
      {
        path: "personal",
        component: SurveyDetailPersonalComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyRoutingModule { }
