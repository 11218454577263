import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsQuestionsService extends BaseService {
  status = new Subject<any>();
  currentStatus = this.status.asObservable();

  getStatus(status: any) {
    this.status.next(status);
  }

  getAll(query?: any): Observable<any> {
    return this.get<any>('product/questions', { params: query });
  }

  getCommentById(id: any,query?: any): Observable<any> {
    return this.get<any>(`product/question/${id}/answers`, { params: query });
  }

  hidden(id: any, is_hidden: Boolean, product_id: string): Observable<any> {
    return this.put<any>(`product/question/hidden?question_id=${id}&is_hidden=${is_hidden}&product_id=${product_id}`);
  }

  pinned(id: any, product_id: string): Observable<any> {
    return this.put<any>(`product/question/pin?question_id=${id}&product_id=${product_id}`);
  }

  unpinned(id: any, product_id: string): Observable<any> {
    return this.put<any>(`product/question/unpin?question_id=${id}&product_id=${product_id}`);
  }

  comment(product_id: string, body: any): Observable<any> {
    return this.post<any>(`product/question/reply?product_id=${product_id}`, body);
  }
}
