import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingNotifyService extends BaseService {

  getAll(query?: any): Observable<any> {
    return this.get<any>('/configuration/notification', {params: query});
  }

  updateNotification(body): Observable<any> {
    return this.put<any>(`/configuration/notification`, body);
  }

  change(body: any): Observable<any> {
    return this.post<any>(`/configuration/service-pack`, body);
  }


}


