import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
declare var $: any;
import { NzModalService } from 'ng-zorro-antd';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../auth';
import {NavigationEnd, Router} from '@angular/router';
import {
  BusinessInfoService,
  RootService,
} from '../services';
import { AccountInfoService } from 'src/app/services/business/account-info/account-info.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {GoogleAnalyticService} from "./google-analytic.service";
@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnInit, OnDestroy {
  business;
  account;
  nameAccount;
  userType;
  isCollapsed = true;
  isVisible = false;
  changeTab = true;
  unsubscribe$ = new Subject<void>();
  socket: any;
  pageIndex = 1;
  pageSize = 50;
  list = [];
  total = 0;
  query;
  // @ts-ignore
  @ViewChild('template') template;
  // @ts-ignore
  @ViewChild('message') message;
  constructor(
    private modalService: NzModalService,
    private authService: AuthService,
    private router: Router,
    private rootService: RootService,
    private accountService: AccountInfoService,
    private notification: NzNotificationService,
    private businessInfoService: BusinessInfoService,
    private googleAnalyticService: GoogleAnalyticService,
  ) {
    this.googleAnalyticService.initialize();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
          console.log("event", event)
          this.googleAnalyticService.event('page_view', {
            url_page: event && event.url ? event.url : '',
            company_name: currentUser.userInfo.userName,
            id_company: currentUser.userInfo.userId
          });
        }
      }
    });
  }
  currentUser: any;
  ngOnInit() {
    this.accountService.currentName
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((name) => {
        this.loadInfoAccount();
      });
    this.loadInfoBusiness();

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    this.$jquery();
    this.listenChange();
  }

  changeMenu() {
    this.changeTab = !this.changeTab;
    this.listenChange();
  }

  listenChange() {
    if (this.changeTab === true) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
      this.rootService.currentMessage
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((message) => {
          if (this.changeTab === true) {
            this.isCollapsed = false;
          } else {
            // this.isCollapsed = message;
          }
        });
    }
  }

  $jquery() {
    $('.selected-user').click((event) => {
      event.stopPropagation();
      $('.icon-down-user').toggleClass('flip');
      $('.box-user').toggleClass('opened');
    });

    $('.box-user').click(() => {
      $('.icon-down-user').removeClass('flip');
      $('.box-user').removeClass('opened');
    });

    $(window).click((event) => {
      $('.icon-down-user').removeClass('flip');
      if (
        !$(event.target).closest($('.box-user')).length &&
        !$(event.target).closest('.selected-user').length
      ) {
        $('.box-user').removeClass('opened');
      }
    });
  }

  goToQrCodeFree() {
    const originalHost = location.origin;
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = originalHost + '/qrcode-free/generate/TEXT';
    a.click();
  }

  loadInfoAccount() {
    this.accountService
      .info()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          this.userType = res.data.userType;
          this.nameAccount = res.data.userName;
        }
      });
  }

  loadInfoBusiness() {
    this.businessInfoService
      .getInfoBusiness()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.business = res.data;
      });
  }

  logout() {
    this.authService.logout();
    const originalHost = location.origin;
    const a = document.createElement('a');
    a.href = originalHost + '/qrcode-free/generate/TEXT';
    a.click();
    this.notification.create('success', 'Thành công', 'Đăng xuất thành công');
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
