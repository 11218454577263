import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseService {

  coordinates: any;
  getList(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>('/business', httpOptions);
  }

  createCompany(body: any) {
    return this.post<any>('/business', body);
  }

  editCompany(id: string, body: any) {
    return this.put<any>('/business/' + id, body);
  }

  getListProducts(query?: any) {
    return this.get('/business/product/search', {
      params: query,
    });
  }

  getListTitle(query?: any) {
    return this.get('/title/business', {
      params: query,
    });
  }

  getListRole() {
    return this.get('/role/business');
  }

  getListProductsForCreate(query?: any) {
    return this.get('/product', {
      params: query
    });
  }

  createNewProduct(body?: any) {
    return this.post('/business/product', body);
  }

  editProduct(body?: any) {
    return this.put<any>('/business/product', body);
  }

  deleteProducts(body?: any) {
    return this.delete('/business/product', body);
  }

  deleteCompanies(body?: any) {
    return this.delete('/business', body);
  }

  getDetailCompanyById(id: any) {
    return this.get(`/business/${id}`);
  }

  getDetailProductById(id: any) {
    return this.get(`/business/product/detail/${id}`);
  }

  showHiddenStatus(body?: any) {
    return this.patch(`/business/status`, body);
  }

  importCompanyFile(body: any) {
    return this.post<any>('/business/import', body);
  }
  getPosition(): Observable<any> {
    return Observable.create(
      (observer) => {
      navigator.geolocation.watchPosition((pos: any) => {
        observer.next(pos);
      }),
        // tslint:disable-next-line:no-unused-expression
      () => {

      },
        // tslint:disable-next-line:no-unused-expression
      {
        enableHighAccuracy: true
      };
    });
  }
}
