import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScanHistoryComponent } from './scan-history.component';


const routes: Routes = [
  {
    path: '',
    component: ScanHistoryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScanHistoryRoutingModule { }
