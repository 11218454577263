import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ProductsService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-products-add',
  templateUrl: './products-add.component.html',
  styleUrls: ['./products-add.component.scss']
})
export class ProductsAddComponent implements OnInit, OnDestroy {


  constructor(
    private productService: ProductsService,
    private notification: NzNotificationService,
  ) { }

  unsubscribe$ = new Subject<void>();

  isVisible = false;

  // import
  isRequired: Boolean = false;
  subscriptions: any = [];
  subscriptionId: string;

  @Output() import = new EventEmitter<string>();
  importData: any = null;
  importName: any = null;
  // tslint:disable-next-line:variable-name
  is_check_gln = false;
  // tslint:disable-next-line:variable-name
  is_validate_gtin = false;
  // add barcode
  checked = true;
  check_barcode: any[] = [];
  barcodes: string;
  isLoadingBtn: Boolean = false;
  @Output() addBarcodes = new EventEmitter<string>();
  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }
  showModal() {
    this.is_check_gln = false;
    this.is_validate_gtin = false;
    this.isVisible = true;
    this.barcodes = null;
    this.subscriptionId = null;
    this.isRequired = false;
    this.check_barcode = [];
    this.importName = null;
    this.importData = null;
    this.loadDataSubscription();
  }

  handleCancel() {
    this.isLoadingBtn = false;
    this.isVisible = false;
  }
  loadDataSubscription() {
    this.productService.getAllSubscription().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        const data = [];
        res.data.map(item => {
          if (item.status === 0) {
            item.status = 'Hủy kích hoạt';
          }
          if (item.status === 1) {
            item.status = 'Kích hoạt';
          }
          if (item.status === 2) {
            item.status = 'Chờ kích hoạt';
          }
          if (item.status === 3) {
            item.status = 'Hết hạn';
          }
          if (item.status === 4) {
            item.status = 'Hết hạn';
          }
          item.expirationAt = item.expirationAt.length !== null ?  item.expirationAt.substr(0, 10) : item.expirationAt;
          item.trialExpirationAt = item.trialExpirationAt !== null ?  item.trialExpirationAt.substr(0, 10) : item.trialExpirationAt;
          data.push(item);
        });
        this.subscriptions = data;
        if (this.subscriptions.length === 1) {
          this.sub(res.data[0].id);
        }
      }
    );
  }
  sub(data: any) {
    this.subscriptionId = data;
    if (data) {
      this.isRequired = false;
    } else {
      this.isRequired = true;
    }
  }
  importFile(data: any) {
    if (data) {
      this.importName = data.name;
      this.importData = data;
    } else {
      this.importName = null;
      this.importData = null;
    }
  }
  onSubmit() {
    if (!this.subscriptionId) {
      this.isRequired = true;
      return;
    }

    this.isLoadingBtn = true;

    if (this.importData == null && this.barcodes == null) {
      this.openMessage('warning', 'Cảnh báo', `Bạn chưa chọn thêm bằng barcode hoặc import`);
      this.isLoadingBtn = false;
      return;
    }
    if (this.importData) {
      const body: any = {
        subId: this.subscriptionId,
        body: this.importData,
        is_check_gln: this.is_check_gln,
        is_validate_gtin: this.is_validate_gtin
      };
      this.import.emit(body);
      return;
    }

    if (this.barcodes) {
      const newBarcodes = this.barcodes.split(/\r?\n/).filter(data => {
        return data.trim().length > 0;
      });
      if (newBarcodes.length == 0) {
        this.openMessage('warning', 'Cảnh báo', `Barcode không được để trống`);
        this.isLoadingBtn = false;
        return;
      }
      const body: any = {
        barcodes: newBarcodes,
        subId: this.subscriptionId
      };
      this.addBarcodes.emit(body);
      this.barcodes = null;
    }
  }

  success() {
    this.isLoadingBtn = false;
  }

  checkBarcode() {
    this.check_barcode = [];
    const barcode = this.barcodes.split(/\r?\n/);
    for (let i = 0; i < barcode.length; i++) {
      const check = this.validateGtin(barcode[i].substr(0, barcode[i].length - 1));
      const result = barcode[i].substr(barcode[i].length - 1) === ('' + check);
      if (result === false) {
        const res = {
          index: i + 1,
          value: barcode[i]
        };
        this.check_barcode.push(res);
      }
    }
  }
  // tslint:disable-next-line:variable-name
  validateGtin(number: string): number {
    if (isNaN(+number)) {
      return null;
    }
    const length = number.length;
    if ([7, 11, 12, 13, 16, 17].indexOf(length) === -1) {
      return null;
    }
    const num = length - 1;
    let isMultiplyBy3 = true;
    let sum = 0;
    for (let i = num; i >= 0; i--) {
      sum += (+number[i]) * (isMultiplyBy3 ? 3 : 1);
      isMultiplyBy3 =  !isMultiplyBy3;
    }
    const nextMultipleOfTen = Math.ceil(sum / 10) * 10;
    return nextMultipleOfTen - sum;
  }
  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
