import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UploaderComponent} from '../../uploader';
import {takeUntil} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {DistributorsService, RootService} from '../../../services';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {capitalizeFirstLetter, removeAllSpacingBetweenWords} from '../../../helpers';

@Component({
  selector: 'create-distributors',
  templateUrl: './create-distributors.component.html',
  styleUrls: ['./create-distributors.component.scss']
})
export class CreateDistributorsComponent implements OnInit, OnDestroy {

  // @Input() isVisible: boolean;
  // @ts-ignore
  // @ViewChild('lgModal') lgModal;
  unsubscribe$ = new Subject<void>();
  avatar: any[] = [];
  background: any[] = [];
  isVisible = false;
  countries: any;
  country_code: any;
  // tslint:disable-next-line:variable-name
  form_step: FormGroup = this.fb.group({
    name: [null, [Validators.required, Validators.maxLength(255)]],
    tax_code: [null, [Validators.required, Validators.maxLength(255)]],
    address: [null, [Validators.required, Validators.maxLength(255)]],
    country_id: [null, [Validators.required]],
    province_id: [null, [Validators.required]],
    district_id: [null, [Validators.required]],
    streetId: [null, [Validators.required]],
    phone: [null, [Validators.pattern('^[0-9]*$')]],
    email: [null, [Validators.pattern('^[a-z0-9_\\.]{1,32}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$')]],
    website: [null],
    avatar: [null],
    background: [null],
    // background: this.fb.control([]),
    // documents: [null, [Validators.required]],
    // distributeType: [null, [Validators.required]],
    // distributeTitle: [null, [Validators.required]],
    // assignAll: [null, [Validators.required]],
    // assignProducts: [null, [Validators.required]],

  });
  idItem;
  actions = [
    {label: 'Gán sản phẩm', value: 0},
    {label: 'Gỡ sản phẩm', value: 1},
    {label: 'Export', value: 2},
    {label: 'Export tất cả', value: 3},
  ];
  city = null;
  districts = null;
  street = null;
  idDistributor;
  @Output() loadDataParent = new EventEmitter();
  isLoadingBtn = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private distributorsService: DistributorsService,
    private rootService: RootService,
    private notification: NzNotificationService
  ) {
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
    this.loadData();
    this.rootService.listCountries().pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        if (res.code === 1) {
          this.countries = res.data.items;
        }
      }
    );
  }

  loadData() {
    this.rootService.listCities().pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.city = res.data.items;
    });

  }

  getDistricts($event: any) {
    this.districts = null;
    this.street = null;
    this.form_step.get('district_id').setValue(null);
    this.form_step.get('streetId').setValue(null);
    if ($event !== null) {
      this.rootService.listDistricts($event).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        this.districts = res.data.items;
      });
    }

  }

  getStreet($event: any) {

    this.street = null;
    this.form_step.get('streetId').setValue(null);
    if ($event !== null) {
      this.rootService.listWards($event).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        this.street = res.data.items;
      });
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.avatar = [];
    this.background = [];
    this.form_step.reset();

  }

  citiChange(event) {
    if (event) {
      this.country_code = this.country_code = event.split('-')[1];
      if (this.country_code !== 'VN') {
        this.form_step.controls.province_id.clearValidators();
        this.form_step.controls.province_id.disable();
        this.form_step.controls.district_id.clearValidators();
        this.form_step.controls.district_id.disable();
        this.form_step.controls.streetId.clearValidators();
        this.form_step.controls.streetId.disable();
        return;
      }
      this.form_step.controls.province_id.setValidators(Validators.required);
      this.form_step.controls.province_id.enable();
      this.form_step.controls.district_id.setValidators(Validators.required);
      this.form_step.controls.district_id.enable();
      this.form_step.controls.streetId.setValidators(Validators.required);
      this.form_step.controls.streetId.enable();
    }
  }

  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    const form = this.form_step;
    recursive(form);

    if (form.invalid) {
      return;
    }
    // this.isLoadingBtn = true;
    let newForm;
    newForm = {...this.form_step.value};
    if (this.form_step.value.country_id.split('-')[1] !== 'VN') {
      newForm.province_id = 0;
      newForm.district_id = 0;
      newForm.streetId = 0;
    }
    newForm.name = capitalizeFirstLetter(removeAllSpacingBetweenWords(this.form_step.value.name));
    newForm.country_id = undefined;
    // tslint:disable-next-line:radix
    newForm.countryId = parseInt(this.form_step.value.country_id.split('-')[0]);
    // tslint:disable-next-line:max-line-length
    this.distributorsService.editDistributor(this.idDistributor, newForm).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res.code === 1) {
        this.notification.create(
          'success',
          'Thành công',
          'Cập nhật nhà phân phối thành công'
        );
        this.form_step.reset();
        this.avatar = [];
        this.background = [];
        this.isVisible = false;
        this.loadDataParent.emit(true);
      } else {
        this.notification.create(
          'warning',
          'Cảnh báo',
          res.message
        );
      }
      this.isLoadingBtn = false;

    }, (err) => {
      this.notification.create(
        'error',
        'Thất bại',
        err
      );
      this.isLoadingBtn = false;

    });


  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          // this[field].push(data);
          // this.form_step.get('avatar').value.push(changeParam.file.response.id);
          if (field === 'avatar') {
            this.form_step.get('avatar').setValue(changeParam.file.response.id);
            this.avatar = [{url: changeParam.file.response.url}];
          } else if (field === 'background') {
            this.form_step.get('background').setValue(changeParam.file.response.id);
            this.background = [{url: changeParam.file.response.url}];
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(changeParam => {
        modal.close();
      });
    });

  }


  onRemoveFileAll(field: string) {
    // dùng cho 1 ảnh
    if (field === 'avatar') {
      this.avatar = [];
      this.form_step.get('avatar').setValue(null);
    } else if (field === 'background') {
      this.background = [];
      this.form_step.get('background').setValue(null);
    }
  }

  onRemoveFile(field: string, index: number) {

    // dùng cho nhiều ảnh
    this[field].splice(index, 1);
    this.form_step.get(field).value.splice(index, 1);
  }

  showModal(id: any): void {
    this.idDistributor = id;
    this.isVisible = true;
    if (this.idDistributor) {
      // this.idItem = id;

      this.distributorsService.detailDistributor(this.idDistributor).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        this.form_step.get('name').setValue(res.data.name);
        this.form_step.get('tax_code').setValue(res.data.tax_code);
        this.form_step.get('address').setValue(res.data.address);
        this.countries.map(c => {
          if (c.id === res.data.countryId) {
            this.form_step.get('country_id').setValue(c.id + '-' + c.code);
          }
        });
        this.form_step.get('province_id').setValue(res.data.area_Id);
        this.rootService.listDistricts(res.data.area_Id).pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
          this.districts = value.data.items;
          this.form_step.get('district_id').setValue(res.data.district_id);
          this.rootService.listWards(res.data.district_id).pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            this.street = value.data.items;
            this.form_step.get('streetId').setValue(res.data.streetId);
          });
        });
        this.form_step.get('phone').setValue(res.data.phone);
        this.form_step.get('email').setValue(res.data.email);
        this.form_step.get('website').setValue(res.data.website);


        this.avatar = [{url: res.data.image_org}];
        this.background = [{url: res.data.background_org}];
        this.form_step.get('avatar').setValue(res.data.image);
        this.form_step.get('background').setValue(res.data.background);

      });


    }


  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
