import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stamp-delete',
  templateUrl: './stamp-delete.component.html',
  styleUrls: ['./stamp-delete.component.scss']
})
export class StampDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
