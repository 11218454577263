import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StampRoutingModule} from './stamp-routing.module';
import {StampListComponent} from './stamp-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {SharedModule} from 'src/app/shared';
import {NgxBarcodeModule} from 'ngx-barcode';
import {NgxSummernoteModule} from 'ngx-summernote';
import {StampDeleteComponent} from './stamp-delete/stamp-delete.component';
import {AssignProductComponent} from './stamp-add/assign-product/assign-product.component';
import {AssignLinkComponent} from './stamp-add/assign-link/assign-link.component';
import {AssignStoreComponent} from './stamp-add/assign-store/assign-store.component';
import {AssignAppComponent} from './stamp-add/assign-app/assign-app.component';
import {StampModalComponent} from './stamp-add/stamp-modal.component';
import {NgxQRCodeModule} from 'ngx-qrcode2';
import {
  ListRelatedProductComponent
} from './stamp-add/assign-product/list-related-product/list-related-product.component';
import {AddRelatedProductComponent} from './stamp-add/assign-product/add-related-product/add-related-product.component';
import {
  AddRelatedBusinessComponent
} from './stamp-add/assign-store/add-related-business/add-related-business.component';
import {StampDetailComponent} from './stamp-detail/stamp-detail.component';
import {StampCustomerComponent} from './stamp-detail/stamp-customer/stamp-customer.component';
import {
  StampInteractiveHistoryComponent
} from './stamp-detail/stamp-interactive-history/stamp-interactive-history.component';
import {StampStatisticComponent} from './stamp-detail/stamp-statistic/stamp-statistic.component';
import {LoyaltyCampaignComponent} from './stamp-detail/loyalty-campaign/loyalty-campaign.component';
import {SelectCampaignComponent} from './stamp-detail/loyalty-campaign/select-campaign/select-campaign.component';
import {
  EnterCodeCampaignComponent
} from './stamp-detail/loyalty-campaign/enter-code-campaign/enter-code-campaign.component';
import {AssignSurveyComponent} from './stamp-add/assign-survey/assign-survey.component';
import {QrcodeVideoComponent} from './stamp-add/qrcode-video/qrcode-video.component';
import {QrcodeMp3Component} from './stamp-add/qrcode-mp3/qrcode-mp3.component';
import {QrcodeSocialComponent} from './stamp-add/qrcode-social/qrcode-social.component';
import {QrcodeEcommerceComponent} from './stamp-add/qrcode-ecommerce/qrcode-ecommerce.component';
import {QrcodePdfComponent} from './stamp-add/qrcode-pdf/qrcode-pdf.component';
import {QrcodeImageComponent} from './stamp-add/qrcode-image/qrcode-image.component';
import {QrcodePdfPreviewComponent} from './stamp-add/qrcode-pdf/qrcode-pdf-preview/qrcode-pdf-preview.component';
import {
  QrcodeImagePreviewComponent
} from './stamp-add/qrcode-image/qrcode-image-preview/qrcode-image-preview.component';
import {
  QrcodeVideoPreviewComponent
} from './stamp-add/qrcode-video/qrcode-video-preview/qrcode-video-preview.component';
import {QrcodeMp3PreviewComponent} from './stamp-add/qrcode-mp3/qrcode-mp3-preview/qrcode-mp3-preview.component';
import {
  QrcodeSocialPreviewComponent
} from './stamp-add/qrcode-social/qrcode-social-preview/qrcode-social-preview.component';
import {
  QrcodeEcommercePreviewComponent
} from './stamp-add/qrcode-ecommerce/qrcode-ecommerce-preview/qrcode-ecommerce-preview.component';
// import { ClipboardModule } from 'ngx-clipboard';
import {AssignCheckinComponent} from './stamp-add/assign-checkin/assign-checkin.component';
import {
  AssignCheckinPreviewComponent
} from './stamp-add/assign-checkin/assign-checkin-preview/assign-checkin-preview.component';
import {PublicLoyaltyComponent} from './stamp-detail/loyalty-campaign/public-loyalty/public-loyalty.component';
import {PopupGuideComponent} from './popup-guide/popup-guide.component';
import {ConvertStampPopupComponent} from './convert-stamp-popup/convert-stamp-popup.component';
import {QrCodeMenuComponent} from './stamp-add/qr-code-menu/qr-code-menu.component';
import {
  TemplateMenuOneComponent
} from './stamp-add/qr-code-menu/menu-preview/template-menu-one/template-menu-one.component';
import {
  TemplateMenuTwoComponent
} from './stamp-add/qr-code-menu/menu-preview/template-menu-two/template-menu-two.component';
import {FoodSalesComponent} from './stamp-add/qr-code-menu/menu-preview/food-sales/food-sales.component';
import {PhoneCallComponent} from './stamp-add/qr-code-menu/menu-preview/phone-call/phone-call.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {AssignLoyaltyComponent} from './stamp-add/assign-loyalty/assign-loyalty.component';
import { PreviewCheckinComponent } from './popup-preview/preview-checkin/preview-checkin.component';
import { TemplateQrcodeViewComponent } from './template-qrcode-view/template-qrcode-view.component';
import {NgxSummernoteCustomModule} from '../../lib/ngx-summernote-custom.module';
import { SelectCampaignIcheckComponent } from './stamp-detail/loyalty-campaign/select-campaign-icheck/select-campaign-icheck.component';
import {NumberDirective} from "./directive/number-only.directive";

@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [NumberDirective, StampListComponent, StampModalComponent, StampDeleteComponent, AssignProductComponent, QrcodeImageComponent, AssignLinkComponent, AssignStoreComponent, ListRelatedProductComponent, AddRelatedProductComponent, AddRelatedBusinessComponent, StampDetailComponent, StampCustomerComponent, StampInteractiveHistoryComponent, StampStatisticComponent, LoyaltyCampaignComponent, SelectCampaignComponent, EnterCodeCampaignComponent, AssignAppComponent, AssignSurveyComponent, AssignCheckinComponent, AssignCheckinPreviewComponent, PublicLoyaltyComponent, QrcodePdfComponent, QrcodeVideoComponent, QrcodeMp3Component, QrcodeSocialComponent, QrcodeEcommerceComponent, QrcodePdfPreviewComponent, QrcodeImagePreviewComponent, QrcodeVideoPreviewComponent, QrcodeMp3PreviewComponent, QrcodeSocialPreviewComponent, QrcodeEcommercePreviewComponent, PopupGuideComponent, ConvertStampPopupComponent, QrCodeMenuComponent, TemplateMenuOneComponent, TemplateMenuTwoComponent, FoodSalesComponent, PhoneCallComponent, AssignLoyaltyComponent, PreviewCheckinComponent, TemplateQrcodeViewComponent, SelectCampaignIcheckComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    SharedModule,
    NgxBarcodeModule,
    NgxQRCodeModule,
    // QRCodeSVGModule,
    // ClipboardModule,
    NgxSummernoteCustomModule,
    StampRoutingModule,
    AngularEditorModule
  ]
})
export class StampModule {
}
