import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { BusinessInfoService } from "src/app/services/business/business-info/business-info.service";
import { RootService } from "src/app/services/root.service";
import { PackageListComponent } from "./package-list/package-list.component";
import { Router } from "@angular/router";
import { UploaderComponent } from "src/app/shared";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: "app-business-info",
  templateUrl: "./business-info.component.html",
  styleUrls: ["./business-info.component.scss"],
})
export class BusinessInfoComponent implements OnInit {
  @ViewChild(PackageListComponent, { static: true })
  packageListModal!: PackageListComponent;
  loading: boolean = false;
  busi_info = this.fb.group({
    businessName: ["", [Validators.required]],
    taxCode: [""],
    documents: [[]],
    address: ["", [Validators.required]],
    provinceId: [undefined, [Validators.required]],
    districtId: [undefined, [Validators.required]],
    phone: ["", [Validators.required, Validators.pattern("[0-9]*")]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(
          "^[a-z0-9_\\.]{1,32}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$"
        ),
      ],
    ],
  });
  cities = [];
  districts = [];
  unsubscribe$ = new Subject<void>();
  isVisiblePackageDetail: boolean = false;
  isVisiblePackageList: boolean = false;
  userPackage: any = null

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private businessInfoService: BusinessInfoService,
    private rootService: RootService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCities();
    this.getInfoBusiness();
    this.notification.config({
      nzPlacement: "bottomRight",
    });
  }

  getCities() {
    let query = {
      type: 1, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : 0
    }
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.cities = res.data;
      },
      (error) => {
        this.cities = [];
      }
    );
  }

  getDistricts(event: number) {
    let query = {
      type: 2,
      parentId: event
    }
    if (event) {
      const response = this.rootService.getLocations(query);
      response.subscribe(
        (res: any) => {
          this.districts = res.data;
        },
        (error) => {
          this.districts = [];
        }
      );
    } else {
      this.districts = []


    }
    this.busi_info.get("districtId").clearValidators();
    this.busi_info.get("districtId").setValue(undefined)
  }


  files = []
  notDocuments = false
  type: any;
  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp,pdf';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      // nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {


          this.files.push(changeParam.file.response.url);
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(changeParam => {
        modal.close();
      });
    });
  }

  delete(file, i) {
    this.files.splice(i, 1);
  }

  getInfoBusiness() {
    this.businessInfoService.getInfoBusiness().subscribe(
      (res) => {
        if (res.code === 1) {
          const data = res.data;
          if (data) {
            this.busi_info.get("businessName").setValue(data.userName);
            if (data.businessName != null) {
              this.busi_info.controls.businessName.disable();
            }
            if (data.documents && data.documents.length) {
              this.files = data.documents;
            }
            this.busi_info.get("address").setValue(data.address);
            this.busi_info.get("provinceId").setValue(data.provinceId);
            this.busi_info.get("districtId").setValue(data.districtId);
            this.busi_info.get("phone").setValue(data.phoneNumber);
            this.busi_info.get("email").setValue(data.email);
            this.busi_info.get("taxCode").setValue(data.taxCode);
            this.userPackage = data.userPackage || null
          }
        } else {
          this.notification.create(
            "error",
            "Lấy thông tin doanh nghiệp thất bại",
            res.message
          );
        }
      },
      (error) => {
        this.notification.create(
          "error",
          "Lấy thông tin doanh nghiệp thất bại",
          "Hệ thống đang nâng cấp.Vui lòng thử lại sau"
        );
      }
    );
  }

  updateInfoBusiness() {
    this.busi_info.get("districtId").setValidators([Validators.required])
    for (const i in this.busi_info.controls) {
      this.busi_info.controls[i].markAsDirty();
      this.busi_info.controls[i].updateValueAndValidity();
    }
    if (this.busi_info.invalid) {
      return;
    }
    const businessName$ = this.busi_info.get("businessName").value
      ? this.busi_info.get("businessName").value.trim()
      : "";
    const address$ = this.busi_info.get("address").value
      ? this.busi_info.get("address").value.trim()
      : "";
    const provinceId$ = this.busi_info.get("provinceId").value;
    const districtId$ = this.busi_info.get("districtId").value;
    const phone$ = this.busi_info.get("phone").value
      ? this.busi_info.get("phone").value.trim()
      : "";
    const email$ = this.busi_info.get("email").value
      ? this.busi_info.get("email").value.trim()
      : "";


    const body = {
      userName: businessName$,
      address: address$,
      provinceId: provinceId$,
      districtId: districtId$,
      phoneNumber: phone$,
      email: email$,
      documents: this.files && this.files.length ? this.files : [],
    };
    this.loading = true;
    this.businessInfoService.updateInfoBusiness(body).subscribe(
      (res) => {
        this.loading = false;
        if (res.code === 1) {
          this.notification.create(
            "success",
            "Thành công",
            "Cập nhập thông tin doanh nghiệp thành công"
          );
          // const currentUser = localStorage.getItem('currentUser');
          // localStorage.setItem('currentUser', JSON.stringify({
          //   currentUser,
          //   userInfo: {
          //     ...body
          //   }
          // }))
          this.router.navigate(['/portal/dashboard']);
        } else {
          this.notification.create(
            "error",
            "Thất bại",
            res.message || "Cập nhập thông tin doanh nghiệp thất bại"
          );
        }
      },
      (error) => {
        this.loading = false;
        this.notification.create(
          "error",
          "Thất bại",
          error.message || "Hệ thống đang nâng cấp.Vui lòng thử lại sau"
        );
      }
    );
  }

  cancel() {
    this.router.navigate([''])
  }

  showModalPackageDetail() {
    if(!this.userPackage || this.userPackage.name) {
      return;
    }
    this.isVisiblePackageDetail = true;
  }

  handleCancelPackageDetail() {
    this.isVisiblePackageDetail = false;
  }

  showModalListPackage() {
    this.packageListModal.open();
  }
}
