import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProductsService, RootService } from 'src/app/services';
import { removeEmptyQuery, sleep } from 'src/app/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { filterProductsConfig } from 'src/app/shared/config-table/filterProductsConfig';
import {ProductsImportComponent} from './products-import/products-import.component';
import { track } from 'src/app/helpers/track';
@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent implements OnInit, OnDestroy {
  constructor(
    private productService: ProductsService,
    private rootService: RootService,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  unsubscribe$ = new Subject<void>();
  isAdmin = false;
  // @ts-ignore
  @ViewChild('updateProduct') updateProduct;
  // @ts-ignore
  @ViewChild('deleteFlashProducts') deleteFlashProducts;
  @ViewChild('hiddenProducts', {static: true}) hiddenProducts;
  @ViewChild('showProducts', {static: true}) showProducts;
  @ViewChild('modalImportProduct', {static: true}) modalImportProduct: ProductsImportComponent;

  subscriptions: any = [];

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading = true;
  page = 1;
  pageSize = 10;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  selectedProducts = [];
  ids: any[] = [];
  isShow;

  query: string;
  sku: string;
  subscriptionId: number;
  infoStatus: number;
  status: number;
  managementStatus: number;
  delayTimer: any;
  owner: string;

  managementStatuses: any[] = [];
  showPaging = true;
  configTable: any;

  statuses = [
    { id: 1, label: 'Kích hoạt' },
    { id: 2, label: 'Hủy kích hoạt' },
  ];

  /*Check link image error*/
  imageFalse: any[] = [];

  dataDelete = {
    label: 'Xác nhận xóa sản phẩm',
    value: '<p>Bạn có chắc chắn muốn xoá sản phẩm?</p>',
  };

  confirmDelete = {
    label: 'Xác nhận xóa sản phẩm',
    value: '<p>Xóa sản phẩm sẽ đồng thời gỡ bỏ sản phẩm này khỏi các doanh nghiệp liên quan, bạn có muốn tiếp tục?</p>',
    type: 'confirm'
  };

  confirmHidden = {
    label: 'Xác nhận ẩn sản phẩm',
    value: '<p>Bạn có chắc chắn muốn ẩn sản phẩm này không?</p>',
  };

  confirmShow = {
    label: 'Xác nhận hiện sản phẩm',
    value: '<p>Bạn có chắc chắn muốn hiện sản phẩm này không?</p>',
  };

  isLoadingBtn = false;

  @ViewChild('addProduct', { static: true }) addProduct;
  @ViewChild('deleteProducts', { static: true }) deleteProducts;
  action: any;

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }

  clearPosParam() {
    this.router.navigate(['.'], { relativeTo: this.route });
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_products',
    //   contentType: 'product',
    // })
    this.configTable = filterProductsConfig();


    this.route.queryParams.subscribe((params) => {
      if (params.query !== undefined) {
        this.clearPosParam();
        this.query = params.query;
      }
      this.loadData();
    });
    // this.loadDataSubscription();
    // this.loadManagementStatus();
    const user = localStorage.getItem('currentUser');
    // @ts-ignore
    // this.isAdmin = JSON.parse(user).isAdmin;
    // @ts-ignore
    this.loadData();
  }

  loadDataSubscription() {
    this.productService.getAllSubscription2().subscribe((res) => {
      this.subscriptions = res.data;
    });
  }


  updateStatus(ids, status) {
    const body = {
      ids: this.ids,
      status: status === 'hidden' ? 0 : 1
    };



    this.productService.updateStatus(body)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (res) => {
        if (res.code === 1) {
          this.notification.create(
            'success',
            'Thành công',
            status === 'hidden' ? 'Ẩn sản phẩm thành công' : 'Hiện thị sản phẩm thành công'
          );
          this.mapOfCheckedId = {};
          this.ids = [];
          this.hiddenProducts.handleCancel();
          this.showProducts.handleCancel();
          this.loadData(500);
        } else {
          this.notification.create('warning', 'Cảnh báo', res.message);
        }
        this.hiddenProducts.success();
        this.showProducts.success();
      },
      (err) => {
        this.notification.create('error', 'Thất bại', err);
        this.hiddenProducts.success();
        this.showProducts.success();
      }
    );

  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  onChangeItemCheckbox(e, data) {
    this.mapOfCheckedId[data.id] = e;
    this.mapOfCheckedId = {...this.mapOfCheckedId};
    const indexItem = this.selectedProducts.findIndex(item => item.id === data.id);
    if (e) {
      if (indexItem === -1) {
        this.selectedProducts.push({...data});
      }
    } else {
      if (indexItem !== -1) {
        this.selectedProducts.splice(indexItem, 1);
      }
    }
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.id]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value) {
        this.ids.push(key);
      }
    }
    this.isShow = this.ids.length <= 0;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => {
        this.mapOfCheckedId[item.id] = value;
        const indexItem = this.selectedProducts.findIndex(gg => gg.id === item.id);
        if (value) {
          if (indexItem === -1) {
            this.selectedProducts.push({...item});
          }
        } else {
          if (indexItem !== -1) {
            this.selectedProducts.splice(indexItem, 1);
          }
        }
      }
    );
    this.refreshStatus();
  }
  search() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadData();
    }, 300);
  }

  filterSubscriptions($event: number) {
    this.subscriptionId = $event;
    this.page = 1;
    this.loadData();
  }

  changePagination(event) {
    this.page = event;
    this.loadData();

  }

  loadManagementStatus() {
    this.productService
      .getAllManagementStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.managementStatuses = res.data;
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  filterManagementStatus($event: any) {
    this.managementStatus = $event;
    this.page = 1;
    this.loadData();
  }

  filterStatus($event: any) {
    this.status = $event;
    this.page = 1;
    this.loadData();
  }

  filterInfoStatus($event: any) {
    this.infoStatus = $event;
    this.page = 1;
    this.loadData();
  }

  async loadData(ms = 0) {


    const query: any = {
      sku: this.sku ? this.sku.trim() : null,
      query: this.query ? this.query.trim() : null,
      status: this.status,
      page: this.page,
      pageSize: this.pageSize
    };

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.productService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.total = res.totalElement;
          this.listOfAllData = res.data;

          if (this.total <= 10) {
            this.showPaging = false;
          } else {
            this.showPaging = true;
          }

          if (!res.data && this.page > 1) {
            this.page = this.page - 1;
            this.loadData();
            return;
          }
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/product`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  resetData() {
    this.query = null;
    this.sku = null;
    this.status = null;
    this.loadData();
  }

  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, '_blank');
    }
  }

  delete(output: any) {


    let body = {};
    if (!Array.isArray(output)) {


      body = {
        body: {
          ids: output.id,
          force: true
        },
      };
    } else {

      body = {
        body: {
          ids: output
        },
      };
    }

    this.productService
      .destroy(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              'success',
              'Thành công',
              'Xóa sản phẩm thành công'
            );
            this.deleteProducts.handleCancel();
            this.loadData(500);
          } else if (res.code === 107) {
            this.deleteProducts.showModal(this.confirmDelete, output);
          } else {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.deleteProducts.success();
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/product`,
          //   apiPayload: JSON.stringify(body),
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.deleteProducts.success();
          return;
        }
      );
  }

  hidden(id: any, isHidden) {
    this.isLoadingBtn = true;
    this.productService
      .hidden(isHidden, id.toString())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.isLoadingBtn = false;
          if (res.code === 1 && isHidden) {
            this.mapOfCheckedId = {};
            this.openMessage('success', 'Thành công', 'Ẩn sản phẩm thành công');
            this.loadData(500);
            return;
          }
          if (res.code === 1 && !isHidden) {
            this.mapOfCheckedId = {};
            this.openMessage(
              'success',
              'Thành công',
              'Hiện sản phẩm thành công'
            );
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            return;
          }
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/product/hidden`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.isLoadingBtn = false;
          return;
        }
      );
  }

  import(data: any) {
    const is_check_gln = data.is_check_gln;
    const is_validate_gtin = data.is_validate_gtin;
    delete data.is_check_gln;
    delete data.is_validate_gtin;
    const formData = new FormData();
    formData.append('file', data.body);
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_products',
    //   contentName: 'product',
    //   target: `${window.location.origin}/portal/products`,
    //   payload: formData,
    // })
    this.productService
      .importFile(data.subId, formData, is_check_gln, is_validate_gtin)
      .subscribe(
        (res) => {
          this.addProduct.success();
          if (res.code === 1) {
            if (res.data) {
              const arrReason = res.data.filter((item) => {
                if (!item.success) {
                  return item;
                }
              });
              if (arrReason.length > 0) {
                const barcodes: any[] = [];
                for (const item of arrReason) {
                  barcodes.push(item.barcode);
                }
                this.openMessage(
                  'warning',
                  'Cảnh báo',
                  `<p>Đã tồn tại một yêu cầu quản lý đối với sản phẩm này, vui lòng liên hệ admin để được hỗ trợ</p>
                            <span>Barcode: ${barcodes.join(
                    ', '
                  )}</span>`
                );
              }
              const arrSuccess = res.data.filter((item) => {
                if (item.success) {
                  return item;
                }
              });
              if (arrSuccess.length > 0) {
                const barcodes: any[] = [];
                for (const item of arrSuccess) {
                  barcodes.push(item.barcode);
                }
                this.openMessage(
                  'success',
                  'Thành công',
                  `<p>Import sản phẩm thành công</p> <span>Barcode: ${barcodes.join(
                    ', '
                  )}</span>`
                );
              }
            } else {
              this.openMessage(
                'success',
                'Thành công',
                'Import sản phẩm thành công'
              );
            }
            this.loadData(1000);
            this.addProduct.handleCancel();
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            return;
          }
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/product/add/upload`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.addProduct.success();
          return;
        }
      );
  }

  addBarcodes(data: any) {
    const body: any = {
      barcodeList: data.barcodes,
      subId: data.subId,
    };
    this.productService
      .create(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.addProduct.success();
          if (res.code === 1) {
            const arrReason = res.data.filter((item) => {
              if (!item.success) {
                return item;
              }
            });
            if (arrReason.length > 0) {
              const barcodes: any[] = [];
              const tmpReason: any = [
                {
                  message: arrReason[0].reason,
                  barcode: [arrReason[0].barcode],
                },
              ];
              for (let i = 1; i < arrReason.length; i++) {
                const gg = arrReason[i];
                tmpReason.forEach((tmp) => {
                  if (tmp.message === gg.reason) {
                    if (tmp.barcode.indexOf(gg.barcode) === -1) {
                      tmp.barcode.push(gg.barcode);
                    }
                  } else {
                    tmpReason.push({
                      message: gg.reason,
                      barcode: [...gg.barcode],
                    });
                  }
                });
              }
              for (const item of tmpReason) {
                // barcodes.push(data.barcode);
                this.openMessage(
                  'warning',
                  'Cảnh báo',
                  `<p>${
                    item.message
                  }</p> <span><b>Barcode</b>: ${item.barcode.join(', ')}</span>`
                );
              }
              // this.openMessage('warning', 'Cảnh báo', `<p>${data}</p> <span>Barcode: ${barcodes.join(', ')}</span>`);
            }
            const arrSuccess = res.data.filter((item) => {
              if (item.success) {
                return item;
              }
            });
            if (arrSuccess.length > 0) {
              const barcodes: any[] = [];
              for (const item of arrSuccess) {
                barcodes.push(item.barcode);
              }
              this.openMessage(
                'success',
                'Thành công',
                `<p>Tạo sản phẩm thành công</p> <span>Barcode: ${barcodes.join(
                  ', '
                )}</span>`
              );
            }
            this.loadData(1000);
            this.addProduct.handleCancel();
            return;
          }
          if (res.code === 1007) {
            this.openMessage('warning', 'Cảnh báo', `Gói đã hết sản phẩm`);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            return;
          }
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/product`,
          //   apiPayload: JSON.stringify(body),
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.addProduct.success();
          return;
        }
      );
  }

  viewDetail(id) {

    this.router.navigate([`/portal/products/${id}`]);
  }

  createStamp(data) {
    this.router.navigate(['/portal/stamp/null'], { queryParams: { type: 1, productId: data.id }});
  }

  exportExcel() {
    const query: any = {
      export_all: this.ids.length === 0,
      product_name: this.sku ? this.sku.trim() : null,
      query: this.query ? this.query.trim() : null,
      subscription_id: this.subscriptionId ? this.subscriptionId : null,
      management_status: this.managementStatus,
      status: this.status,
      info_status: this.infoStatus,
      ids: this.ids ? this.ids.toString() : null,
    };

    removeEmptyQuery(query);

    this.productService
      .exportExcel(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.rootService.export(data, 'Danh sách sản phẩm.xlsx');
      });
  }

  actionsButton() {
    if (this.action === 1) {
      this.addProduct.showModal();
    } else if (this.action === 2) {
      this.deleteProducts.showModal(this.dataDelete, this.ids);
    } else if (this.action === 3) {
      this.hidden(this.ids, true);
    } else if (this.action === 4) {
      this.hidden(this.ids, false);
    } else if (this.action === 5) {
      this.exportExcel();
    }
    this.action = null;
  }
  updateProductFromExcel() {
    this.updateProduct.showModal();
  }
  handleupdateProductFromExcel(event) {
    const formData = new FormData();
    formData.append('file', event.file);
    const query = event.params;
    this.productService
      .updateProductFromExcel(query, formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.notification.success(
              'Thành công',
              'Cập nhật thông tin sản phẩm thành công'
            );
            this.updateProduct.cancelLoadingBtn();
            this.updateProduct.handleCancel();
            this.loadData(1000);
            return;
          }
          this.notification.error('Có lỗi xảy r', res.message);
          this.updateProduct.cancelLoadingBtn();
        },
        (error) => {
          this.notification.error('Có lỗi xảy r', error.message);
          this.updateProduct.cancelLoadingBtn();
        }
      );
  }
  deleteFlashProduct() {
    this.deleteFlashProducts.showModal();
  }
  handleDeleteFlashProducts(data) {
    const body = data.value;
    this.productService
      .deleteFlashProductByBarcode({ body })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.deleteFlashProducts.cancelLoading();
          if (res.code === 1) {
            this.notification.success('Thành công', 'Xóa sản phẩm thành công');
            this.deleteFlashProducts.handleCancel();
            this.loadData(500);
            return;
          }
          this.notification.error('Có lỗi xảy ra', res.message);
        },
        (error) => {
          this.notification.error('Có lỗi xảy ra', error.message);
          this.deleteFlashProducts.cancelLoading();
        }
      );
    setTimeout(() => {
      this.deleteFlashProducts.cancelLoading();
    }, 2000);
  }


  createProduct() {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_products',
    //   contentName: 'product',
    //   target: `${window.location.origin}/portal/products/null`,
    // })
    this.router.navigate(['/portal/products/null']);
  }

  showModalImport() {
    this.modalImportProduct.openModal();
  }

  onCreateMultiQrcode() {
    if (this.selectedProducts.length > 5) {
      return this.notification.warning('Vui lòng thử lại', 'Chỉ được tạo tối đa 5 QR Code');
    }
    this.router.navigate(['/portal/stamp/null'], {
      queryParams: { type: 1, productId: this.selectedProducts.map(item => item.id).join(',') }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
