import { Injectable } from '@angular/core';
import {BaseService} from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends BaseService {
  getListTicket(query?: any) {
    return this.get<any>('/stringee/ticket', {params: query});
  }
  detailTicket(id?: any) {
    return this.get<any>(`/stringee/ticket/${id}`);
  }
  updateTicket(body?: any) {
    return this.put<any>('/stringee/ticket', body);
  }
  deleteTicket(query?: any) {
    return this.delete<any>('/stringee/ticket',  {params: query});
  }
}
