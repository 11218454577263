import { Injectable } from '@angular/core';
import {BaseService} from 'src/app/services/base.service';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessInfoService extends BaseService {
  business = new BehaviorSubject<string>('');
  message = new Subject();
  currentName = this.business.asObservable();
  currentMessage = this.message.asObservable();
  changeName(business) {
    this.business.next(business);
  }
  changeMessage(message) {
    this.message.next(message);
  }
  getInfoBusiness() {
    return this.get<any>('/user/info');
  }
  updateInfoBusiness(body?: any) {
    return this.put<any>('/user/info', body);
  }
  validateMobio() {
    return this.get<any>('/icheck-mobio/validate');
  }
  addDocumentToProduct(body?: any) {
    return this.post<any>('/product/document/multi', body);
  }
  getHistoryPackages(body) {
    return this.get<any>('/package/histories', {params: body});
  }

  getDetailPackages(id) {
    return this.get<any>(`/package/${id}`);
  }

  getPlans(body) {
    return this.get<any>(`/package/plans`, {params: body});
  }

  extendPackage(body) {
    return this.put<any>(`/package/extend/${body.id}`, body);
  }
  paymentPackage(body) {
    return this.post<any>(`payment/payment/package`, body);
  }
}
