import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductsService } from 'src/app/services';

@Component({
  selector: 'app-template-one',
  templateUrl: './template-one.component.html',
  styleUrls: ['./template-one.component.scss'],
})
export class TemplateOneComponent implements OnInit {

  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  productDetail: any = null;
  listOwners: any = [];
  listDistributors: any = [];
  listShops: any = [];
  listRelatedProducts: any = [];

  constructor(private productService: ProductsService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      this.data = {...val};
      this.data._message = this.sanitizer.bypassSecurityTrustHtml(this.data.message);
      // get detail product
      if (val.productId) {
        this.getDetailProductById(val.productId);
      } else {
        this.productDetail = null;
      }

      // get list related product
      if (val.relatedProductType === 1) {
        this.getRelatedProducts(val.productId);
      }
      if (val.relatedProductType === 2) {
        this.listRelatedProducts = val.customRelatedProducts ? [...val.customRelatedProducts] : [];
      }

      // get list target
      if (val.listTargetProduct) {
        this.listDistributors = val.listTargetProduct.filter((gg: any) => {
          if (gg.isShow && gg.roleCode === 'DISTRIBUTOR') {
            // tslint:disable-next-line:max-line-length
            gg.fullAddress = [gg.address, gg.ward, gg.district, gg.provinceName, gg.country].filter(e => typeof e === 'string' && e !== '').join(', ');
            return gg;
          }
        });
        if (this.listDistributors && this.listDistributors.length > 5) {
          this.listDistributors.length = 5;
        }
        this.listOwners = val.listTargetProduct.filter((gg: any) => {
          if (gg.isShow && gg.roleCode === 'OWNER') {
            // tslint:disable-next-line:max-line-length
            gg.fullAddress = [gg.address, gg.ward, gg.district, gg.provinceName, gg.country].filter(e => typeof e === 'string' && e !== '').join(', ');
            return gg;
          }
        });
        if (this.listOwners && this.listOwners.length > 5) {
          this.listOwners.length = 5;
        }
        this.listShops = val.listTargetProduct.filter((gg: any) => {
          if (gg.isShow && gg.roleCode === 'SHOP') {
            // tslint:disable-next-line:max-line-length
            gg.fullAddress = [gg.address, gg.ward, gg.district, gg.provinceName, gg.country].filter(e => typeof e === 'string' && e !== '').join(', ');
            return gg;
          }
        });
        if (this.listShops && this.listShops.length > 5) {
          this.listShops.length = 5;
        }
      }
    });
  }

  getDetailProductById(id: any) {
    if (this.productDetail && id === this.productDetail.id) { return; }
    this.productService.getDetailById(id).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.code === 1) {
        this.productDetail = res.data ? {...res.data} : null;
        this.productDetail.listMedia = res.data && res.data.mainImage ? [res.data.mainImage] : [];
        if (res.data && res.data.productImages) {
          this.productDetail.listMedia = [
            ...this.productDetail.listMedia,
            ...res.data.productImages,
          ];
        }
        if (res.data && res.data.video) {
          this.productDetail.listMedia  = [...this.productDetail.listMedia , res.data.video];
        }
        this.productDetail._price = this.numberWithCommas(this.productDetail.price);
        this.productDetail._toPrice = this.numberWithCommas(this.productDetail.toPrice);
        this.productDetail.attributes = this.productDetail.attributes ? this.productDetail.attributes.map((gg: any) => {
          const tmp = {...gg};
          if (gg.value) {
            tmp.value = this.sanitizer.bypassSecurityTrustHtml(gg.value);
          }
          return tmp;
        }) : [];
      }
    });
  }

  getRelatedProducts(productId: any) {
    const query = {
      page: 1,
      page_size: 100,
    };
    this.listRelatedProducts = [];
    this.productService.getAll(query).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res.code === 1) {
        if (res.data) {
          res.data.forEach((gg: any) => {
            if (gg.id !== productId) {
              gg._price = this.numberWithCommas(gg.price);
              this.listRelatedProducts.push(gg);
            }
          });
        }
        if (this.listRelatedProducts && this.listRelatedProducts.length > 5) {
          this.listRelatedProducts.length = 5;
        }
      }
    });
  }

  numberWithCommas(x: any) {
    if (x) {
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }
}
