import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BusinessInfoService } from "src/app/services/business/business-info/business-info.service";
@Component({
  selector: "app-package-list",
  templateUrl: "./package-list.component.html",
  styleUrls: ["./package-list.component.scss"],
})
export class PackageListComponent implements OnInit {
  isVisiblePackageList: boolean = false;
  unsubscribe$ = new Subject<void>();
  listPackages: any = [];
  total: any = 0;
  loading: boolean = false;
  page: number = 1;
  pageSize: number = 2;

  constructor(
    private businessInfoService: BusinessInfoService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {}

  open() {
    this.isVisiblePackageList = true;
    this.getListPackages();
  }

  handleCancelPackageList() {
    this.isVisiblePackageList = false;
  }

  getListPackages() {
    this.loading = true;
    this.businessInfoService
      .getHistoryPackages({})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            if (res.data) {
              this.listPackages = [...res.data];
            }
          } else {
            this.notification.create(
              "error",
              "Có lỗi xảy ra",
              res.message || "Lấy dữ liệu thất bại"
            );
          }
          this.loading = false;
        },
        (err: any) => {
          this.notification.create(
            "error",
            "Có lỗi xảy ra",
            err.message || "Lấy dữ liệu thất bại"
          );
          this.loading = false;
        }
      );
  }
}
