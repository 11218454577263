import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SurveyRoutingModule } from "./survey-routing.module";
import { SurveyComponent } from "./survey.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SurveyTabComponent } from "./survey-tab/survey-tab.component";
import { SurveySettingComponent } from "./survey-tab/survey-setting/survey-setting.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { SharedModule } from "src/app/shared";
import { SurveyFormComponent } from "./survey-tab/survey-form/survey-form.component";
import { ServeyListComponent } from "./servey-list/servey-list.component";
import { SurveyDashboardComponent } from "./survey-dashboard/survey-dashboard.component";
import { SurveyHistoriesComponent } from "./survey-histories/survey-histories.component";
import { ServeyGroupListComponent } from "./servey-group-list/servey-group-list.component";
import { NgxSummernoteModule } from "ngx-summernote";
import { SurveyGroupFormComponent } from "./servey-group-list/survey-group-form/survey-group-form.component";
import { SurveyCopyComponent } from "./servey-list/survey-copy/survey-copy.component";
import { SurveyDetailComponent } from './survey-detail/survey-detail.component';
import { SurveyDetailDashboardComponent } from './survey-detail/survey-detail-dashboard/survey-detail-dashboard.component';
import { SurveyDetailResultsComponent } from './survey-detail/survey-detail-results/survey-detail-results.component';
import { SurveyPreviewComponent } from './survey-tab/survey-form/survey-preview/survey-preview.component';
import { SurveySettingPreviewComponent } from './survey-tab/survey-setting/survey-setting-preview/survey-setting-preview.component';
import { SurveyAnswerDetailComponent } from "./survey-detail/survey-detail-results/survey-answer-detail/survey-answer-detail.component";
import { SurveyDetailPersonalComponent } from './survey-detail/survey-detail-personal/survey-detail-personal.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {NgxSummernoteCustomModule} from '../../lib/ngx-summernote-custom.module';

@NgModule({
  declarations: [
    SurveyComponent,
    SurveyTabComponent,
    SurveySettingComponent,
    SurveyFormComponent,
    ServeyListComponent,
    SurveyDashboardComponent,
    SurveyHistoriesComponent,
    ServeyGroupListComponent,
    SurveyGroupFormComponent,
    SurveyCopyComponent,
    SurveyDetailComponent,
    SurveyDetailDashboardComponent,
    SurveyDetailResultsComponent,
    SurveyPreviewComponent,
    SurveySettingPreviewComponent,
    SurveyAnswerDetailComponent,
    SurveyDetailPersonalComponent
  ],
    imports: [
        SurveyRoutingModule,
        DragDropModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        SharedModule,
        NgxSummernoteCustomModule,
        AngularEditorModule,
    ],
})
export class SurveyModule {}
