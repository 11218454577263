import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NzNotificationService} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {convertDateToStringQuery, removeEmptyQuery, sleep} from 'src/app/helpers';
import {BusinessInfoService, ProductsService, RootService} from 'src/app/services';
import {StampService} from 'src/app/services/business/stamp/stamp.service';
import {PopupGuideComponent} from './popup-guide/popup-guide.component';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-stamp-list',
  templateUrl: './stamp-list.component.html',
  styleUrls: ['./stamp-list.component.scss']
})
export class StampListComponent implements OnInit {

  constructor(
    private productService: ProductsService,
    private rootService: RootService,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private stampService: StampService,
    private businessInfoService: BusinessInfoService,
    private modal: NzModalService
  ) {
  }


  unsubscribe$ = new Subject<void>();
  isAdmin = false;
  @ViewChild('appPopupGuide', {static: true})
  appPopupGuide: PopupGuideComponent;
  // @ts-ignore
  @ViewChild('updateProduct') updateProduct;
  // @ts-ignore
  @ViewChild('deleteFlashProducts') deleteFlashProducts;

  @ViewChild('typeImageDownload', {static: true}) typeImageDownload;

  @ViewChild('convertStampPopup', {static: true}) convertStampPopup;

  subscriptions: any = [];

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading = true;
  page = 1;
  pageSize = 10;
  packageId: string;
  listPackage = [];
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  // tslint:disable-next-line:ban-types
  isShow: Boolean;

  name: string;
  productId;
  sku: string;
  subscriptionId: number;
  infoStatus: number;
  status: number;
  managementStatus: number;
  delayTimer: any;
  owner: string;
  switchValue = false;

  managementStatuses: any[] = [];
  showPaging = true;

  statuses = [
    {id: 1, label: 'Kích hoạt'},
    {id: 0, label: 'Hủy kích hoạt'},
    {id: 2, label: 'Admin hủy duyệt'},
    {id: 3, label: 'Chờ duyệt'},
    {id: 4, label: 'QR Code hết hạn'},
    // { id: 5, label: "Gói hết hạn" },
    {id: 5, label: 'Hết hạn hợp đồng'},
    {id: 6, label: 'Hết hạn demo'},
    {id: 10, label: 'Tem chuyển đổi gói'},
    // { id: 8, label: "Gói bị khóa" },
    // { id: 9, label: "Hết hạn demo" }
  ];

  /*Check link image error*/
  imageFalse: any[] = [];

  ranges = {Today: [new Date(), new Date()]};
  // tslint:disable-next-line:variable-name
  created_at: Date[] = [];
  // tslint:disable-next-line:variable-name
  expiry_date: Date[] = [];

  createdFrom;
  createdTo;
  expiredFrom;
  expiredTo;

  type;
  stampCode;

  // QRCode = require("qrcode-svg");


  isVisibleQrcodeImage = false;
  selectedQrcodeImage = '';
  listTemp = [
    {id: 1, label: 'Gắn Sản phẩm', stampCode: 'PRODUCT'},
    {id: 2, label: 'Gắn Địa chỉ link', stampCode: 'LINK'},
    {id: 3, label: 'Gắn Doanh nghiệp, cá nhân', stampCode: 'OBJECT'},
    {id: 4, label: 'Gắn Khảo sát', stampCode: 'SURVEY'},
    {id: 6, label: 'Gắn Hình ảnh', stampCode: 'IMAGE'},
    {id: 6, label: 'Gắn PDF', stampCode: 'PDF'},
    {id: 6, label: 'Gắn Video', stampCode: 'VIDEO'},
    {id: 6, label: 'Gắn Mp3', stampCode: 'MP3'},
    {id: 6, label: 'Gắn Mạng xã hội', stampCode: 'SOCIAL_NETWORK'},
    {id: 6, label: 'Gắn Trang bán hàng', stampCode: 'ECOMMERCE'},
    {id: 9, label: 'Gắn Thực đơn', stampCode: 'MENU'},
    {id: 10, label: 'Gắn Loyalty', stampCode: 'LOYALTY'},
    {id: 11, label: 'Gắn Ứng dụng', stampCode: 'APP'},
    {id: 8, label: 'QR Code Checkin', stampCode: 'CHECKIN'},
  ];

  dataDelete = {
    label: 'Xác nhận xóa QR Code',
    value: '<p>Bạn có chắc chắn muốn xoá QR Code?</p>',
  };

  isLoadingBtn = false;
  selectedStamps = [];

  @ViewChild('addProduct', {static: true}) addProduct;
  @ViewChild('deleteProducts', {static: true}) deleteProducts;
  action: any;

  dataQrcode: any = {};
  listProduct = [];


  elementType = 'canvas';
  href: string;

  //   copyElementText() {
  //     const imgStamp : any = document.querySelector('.qrcode img');
  //     const href = imgStamp.src;
  //     this._clipboardService.copyFromContent(href)
  // }


  isCopyStamp = false;

  stampSelected = [];


  @HostListener('copy', ['$event'])
  copyStamp(e) {

    if (this.isCopyStamp) {
      // const imgStamp : any = document.querySelector('.qrcode img');
      // const href = imgStamp.src;
      // e.clipboardData.setData('text/plain', href);
    }
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }

  clearPosParam() {
    this.router.navigate(['.'], {relativeTo: this.route});
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamps',
    //   contentType: 'qrcode',
    // })
    this.getListPackages();
    this.loadInfoBusiness();
    this.getListProduct();
    this.route.queryParams.subscribe((params) => {
      if (params.query !== undefined) {
        this.clearPosParam();
        this.name = params.query;
      }
      this.loadData();
    });
    // this.loadDataSubscription();
    // this.loadManagementStatus();
    const user = localStorage.getItem('currentUser');
  }

  handleRefresh() {
    setTimeout(() => {
      this.page = 1;
      this.loadData();
      this.mapOfCheckedId = {};
    }, 300);
  }

  getListPackages() {
    this.loading = true;
    const body = {
      page: this.page,
      pageSize: this.pageSize
    };
    this.businessInfoService
      .getHistoryPackages(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            if (res.data) {
              this.listPackage = [...res.data];
            }
          } else {
            this.notification.create(
              'error',
              'Có lỗi xảy ra',
              res.message || 'Lấy dữ liệu thất bại'
            );
          }
          this.loading = false;
        },
        (err: any) => {
          this.notification.create(
            'error',
            'Có lỗi xảy ra',
            err.message || 'Lấy dữ liệu thất bại'
          );
          this.loading = false;
        }
      );
  }


  convertStamp() {
    this.convertStampPopup.showModal(this.stampSelected);
  }

  loadInfoBusiness() {
    this.businessInfoService
      .getInfoBusiness()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.data.countPackage === 0) {
          this.appPopupGuide.showModal();
        }
      });
  }

  genQrCode(data) {
    // var svg = new this.QRCode({
    //   content: data,
    //   padding: 4,
    //   width: 50,
    //   height: 50,
    //   color: "#000000",
    //   background: "#ffffff",
    //   ecl: "M",
    // }).svg();
    // return svg
  }

  getListProduct() {

    const query: any = {
      page: 1,
      pageSize: 100
    };

    this.productService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listProduct = res.data;
          // this.dataQrcode = {
          //   config: {
          //     width: 60,
          //     height: 60,
          //     color: '#000000',
          //     backgroundColor: '#ffffff',
          //   }
          // }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', '');
          return;
        }
      );
  }

  downloadImage(name, index) {
    this.typeImageDownload.showModal(this.listOfAllData[index]);
  }

  downloadStamps() {
    const listIndex = [];
    this.listOfAllData.map((item, index) => {
      this.ids.map(id => {
        if (item.id === id) {
          listIndex.push(index);
        }
      });
    });
    const listSvg = [];

    listIndex.map((item, index) => {
      listSvg.push({svg: document.querySelector(`.qrcode-${index + 1} svg`), index: item});
    });
    this.typeImageDownload.showModal(listSvg, this.listOfAllData);
  }


  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], {type: imageType});
  }

  copyLink(url) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openMessage('success', 'Thành công', 'Sao chép liên kết QR Code thành công');
  }

  changeSwitch(data) {
    if (data.status === 3) {
      this.openMessage('warning', 'Cảnh báo', 'Không thể kích hoạt khi QR Code đang ở trạng thái chờ duyệt');
      return;
    }

    const body = {
      ids: [data.id],
      status: data.status === 1 ? 0 : 1
    };

    this.stampService.toggleStatusStamp(body).subscribe((res) => {
      if (res.code === 1) {

        if (data.status === 1) {
          this.openMessage('success', 'Thành công', 'Hủy kích hoạt thành công');
        } else {
          this.openMessage('success', 'Thành công', 'Kích hoạt thành công');
        }
        this.page = 1;
        this.loadData();

        // let delayTimer: any;
        // clearTimeout(delayTimer);
        // delayTimer = setTimeout(() => {

        //   return;
        // }, 500);
      }
      if (res.code !== 1) {
        this.openMessage('warning', 'Cảnh báo', `${res.message}`);
      }
    }),
      // tslint:disable-next-line:no-unused-expression
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      };

  }


  changeStatus(type) {
    const body = {
      ids: this.ids,
      status: type === 'active' ? 1 : 0
    };

    this.stampService.toggleStatusStamp(body).subscribe((res: any) => {
      if (res.code === 1) {

        if (type === 'inactive') {
          this.openMessage('success', 'Thành công', 'Hủy kích hoạt thành công');
        } else {
          this.openMessage('success', 'Thành công', 'Kích hoạt thành công');
        }
        this.mapOfCheckedId = {};
        this.page = 1;
        this.loadData();

        // let delayTimer: any;
        // clearTimeout(delayTimer);
        // delayTimer = setTimeout(() => {

        //   return;
        // }, 500);
      }
      if (res.code !== 1) {
        this.openMessage('warning', 'Cảnh báo', `${res.message}`);
      }
    }),
      // tslint:disable-next-line:no-unused-expression
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      };
  }


  loadDataSubscription() {
    this.productService.getAllSubscription2().subscribe((res) => {
      this.subscriptions = res.data;
    });
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  onChangeCurrentPage(event) {
    this.page = event;
    this.loadData();
  }

  onChangeItemCheckbox(e, data) {
    this.mapOfCheckedId[data.id] = e;
    this.mapOfCheckedId = {...this.mapOfCheckedId};
    const indexItem = this.selectedStamps.findIndex(item => item.id === data.id);
    if (e) {
      if (indexItem === -1) {
        this.selectedStamps.push({...data});
      }
    } else {
      if (indexItem !== -1) {
        this.selectedStamps.splice(indexItem, 1);
      }
    }
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.id]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value) {
        this.ids.push(key);
      }
    }
    this.isShow = this.ids.length <= 0;
  }

  disbaleConvertStamp() {
    const keys = Object.keys(this.mapOfCheckedId);
    this.stampSelected = keys.filter((key) => this.mapOfCheckedId[key] === true);
    return !this.stampSelected.length;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => {
        this.mapOfCheckedId[item.id] = value;
        const indexItem = this.selectedStamps.findIndex(gg => gg.id === item.id);
        if (value) {
          if (indexItem === -1) {
            this.selectedStamps.push({...item});
          }
        } else {
          if (indexItem !== -1) {
            this.selectedStamps.splice(indexItem, 1);
          }
        }
      });
    this.refreshStatus();
  }

  search() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadData();
    }, 300);
  }

  filterSubscriptions($event: number) {
    this.subscriptionId = $event;
    this.page = 1;
    this.loadData();
  }

  filterManagementStatus($event: any) {
    this.managementStatus = $event;
    this.page = 1;
    this.loadData();
  }

  filterStatus($event: any) {
    this.status = $event;
    this.page = 1;
    this.loadData();
  }

  filterInfoStatus($event: any) {
    this.infoStatus = $event;
    this.page = 1;
    this.loadData();
  }


  viewDetailStamp(id) {
    this.router.navigate([`/portal/stamp/${id}`]);
  }

  filterCreateDate(event: Date[]): void {
    this.created_at = event;
    // tslint:disable-next-line:triple-equals
    if (this.created_at.length == 0) {
      this.createdFrom = null;
      this.createdTo = null;
      this.page = 1;
      this.loadData();
    } else {
      this.createdFrom = this.created_at[0];
      this.createdTo = this.created_at[1];
      this.page = 1;
      this.loadData();
    }
  }

  filterExpireDate(event: Date[]): void {
    this.expiry_date = event;
    // tslint:disable-next-line:triple-equals
    if (this.expiry_date.length == 0) {
      this.expiredFrom = null;
      this.expiredTo = null;
      this.page = 1;
      this.loadData();
    } else {
      this.expiredFrom = this.expiry_date[0];
      this.expiredTo = this.expiry_date[1];
      this.page = 1;
      this.loadData();
    }
  }

  async loadData(ms = 0) {
    let type = 0;
    switch (this.stampCode) {
      case 'PRODUCT':
        type = 1;
        break;
      case 'LINK':
        type = 2;
        break;
      case 'OBJECT':
        type = 3;
        break;
      case 'SURVEY':
        type = 4;
        break;
      case 'MP3':
        type = 6;
        break;
      case 'IMAGE':
        type = 6;
        break;
      case 'PDF':
        type = 6;
        break;
      case 'VIDEO':
        type = 6;
        break;
      case 'SOCIAL_NETWORK':
        type = 6;
        break;
      case 'ECOMMERCE':
        type = 6;
        break;
      case 'CHECKIN':
        type = 8;
        break;
      case 'MENU':
        type = 9;
        break;
      case 'LOYALTY':
        type = 10;
        break;
      case 'APP':
        type = 11;
        break;
      default:
        break;
    }
    const query: any = {
      productId: this.productId ? this.productId : '',
      packageId: this.packageId ? this.packageId : '',
      name: this.name ? this.name.trim() : null,
      status: this.status,
      type: type ? type : '',
      stampCode: this.stampCode,
      createdFrom: this.createdFrom ? convertDateToStringQuery(this.createdFrom) : null,
      createdTo: this.createdTo ? convertDateToStringQuery(this.createdTo) : null,
      expiredFrom: this.expiredFrom ? convertDateToStringQuery(this.expiredFrom) : null,
      expiredTo: this.expiredTo ? convertDateToStringQuery(this.expiredTo) : null,
      page: this.page,
      pageSize: this.pageSize,
    };

    // track('siteSearch', {
    //   keywords: "portal_stamps",
    //   params: JSON.stringify(query)
    // })

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.stampService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.total = res.totalElement;
          if (res.data && res.data.length) {
            this.listOfAllData = res.data.map((item, index) => {
              item.config = {
                ...item.config,
                // width: 80,
                // height: 80,
                data: item.url,
                id: index,
              };
              return item;
            });
          } else {
            this.listOfAllData = [];
          }


          if (this.total <= 10) {
            this.showPaging = false;
          } else {
            this.showPaging = true;
          }

          if (!res.data && this.page > 1) {
            this.page = this.page - 1;
            this.loadData();
            return;
          }
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/stamp`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }


  export() {
    let type = 0;
    switch (this.stampCode) {
      case 'PRODUCT':
        type = 1;
        break;
      case 'LINK':
        type = 2;
        break;
      case 'OBJECT':
        type = 3;
        break;
      case 'SURVEY':
        type = 4;
        break;
      case 'MP3':
        type = 6;
        break;
      case 'IMAGE':
        type = 6;
        break;
      case 'PDF':
        type = 6;
        break;
      case 'VIDEO':
        type = 6;
        break;
      case 'SOCIAL_NETWORK':
        type = 6;
        break;
      case 'ECOMMERCE':
        type = 6;
        break;
      case 'CHECKIN':
        type = 8;
        break;

      default:
        break;
    }
    const query: any = {
      productId: this.productId ? this.productId : '',
      name: this.name ? this.name.trim() : '',
      status: this.status ? this.status : '',
      type: type ? type : '',
      stampCode: this.stampCode ? this.stampCode : '',
      createdFrom: this.createdFrom ? convertDateToStringQuery(this.createdFrom) : '',
      createdTo: this.createdTo ? convertDateToStringQuery(this.createdTo) : '',
      expiredFrom: this.expiredFrom ? convertDateToStringQuery(this.expiredFrom) : '',
      expiredTo: this.expiredTo ? convertDateToStringQuery(this.expiredTo) : '',
      stampIds: this.ids || []
    };
    this.stampService.export(query).subscribe((x: any) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([x], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'});

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = 'danh_sach_qrcode' + new Date().getTime();
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

      // tslint:disable-next-line:only-arrow-functions
      setTimeout(function() {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);

    }, err => {
      this.openMessage('error', 'Thao tác không hợp lệ !!!', `${err}`);
    });
  }

  resetData() {
    this.productId = null;
    this.packageId = null;
    this.createdFrom = null;
    this.createdTo = null;
    this.expiredFrom = null;
    this.expiredTo = null;
    this.created_at = null;
    this.expiry_date = null;
    this.type = null;
    this.stampCode = undefined;
    this.name = null;
    this.sku = null;
    this.status = null;
    this.loadData();
  }

  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, '_blank');
    }
  }

  onShowQrcodeImage(image: string) {
    this.selectedQrcodeImage = image;
    this.showModalQrcodeImage();
  }

  delete(id: any) {
    const body = {
      body: {
        ids: id,
      },
    };
    this.stampService
      .destroy(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              'success',
              'Thành công',
              'Xóa sản phẩm thành công'
            );
            this.deleteProducts.handleCancel();
            this.loadData(500);
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.deleteProducts.success();
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.deleteProducts.success();
          return;
        }
      );
  }

  createStamp() {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_stamps',
    //   contentName: 'create',
    //   target: `${window.location.origin}/portal/stamp/null`,
    // })
    this.router.navigate(['/portal/stamp/null']);
  }


  actionsButton() {
    // tslint:disable-next-line:triple-equals
    if (this.action == 1) {
      this.addProduct.showModal();
      // tslint:disable-next-line:triple-equals
    } else if (this.action == 2) {
      this.deleteProducts.showModal(this.dataDelete, this.ids);
    }
    this.action = null;
  }

  createProduct() {
    this.router.navigate(['/portal/products/null']);
  }

  openLink(link) {
    window.open(link, '_blank');
  }

  viewDetail(data) {
    if (data.type === 1) {
      this.router.navigate([`/portal/products/${data.targetId}`]);
    }
    if (data.type === 4) {
      this.router.navigate([`/portal/survey/detail/${data.targetId}/dashboard`]);
    }
  }

  showModalQrcodeImage() {
    this.isVisibleQrcodeImage = true;
  }

  handleCancelQrcodeImage() {
    this.selectedQrcodeImage = '';
    this.isVisibleQrcodeImage = false;
  }

  downloadMultiQrcode() {
    if (this.selectedStamps.length > 10) {
      return this.notification.warning('Vui lòng thử lại', 'Chỉ được tải tối đa 10 tem');
    }
    this.typeImageDownload.showModal(this.selectedStamps);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
