import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-preview',
  templateUrl: './survey-preview.component.html',
  styleUrls: ['./survey-preview.component.scss']
})
export class SurveyPreviewComponent implements OnInit {

  @Input() data;

  listPage = [];
  constructor() { }

  ngOnInit() {

    if (this.data.content[0].length) {
      this.listPage = new Array(this.data.content.length);


    }
  }

}
