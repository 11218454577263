import { formatDate } from '@angular/common';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { convertDateToStringQuery } from 'src/app/helpers';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import * as _ from 'lodash';
import { UploaderComponent } from 'src/app/shared';
import { track } from 'src/app/helpers/track';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {StampV2Service, UploadService} from '../../../../services';
import {environment} from '../../../../../environments/environment';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {URL_LINK} from '../../../../helpers/constant';
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';
@Component({
  selector: 'app-assign-link',
  templateUrl: './assign-link.component.html',
  styleUrls: ['./assign-link.component.scss']
})
export class AssignLinkComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private stampService: StampService,
    private router: Router,
    private modalService: NzModalService,
    private stampV2Service: StampV2Service,
    private uploadService: UploadService,
  ) { }
    @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;
  unsubscribe$ = new Subject<void>();
  qrcodeConfigPreview = null;
  regUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  form = this.fb.group({
    name: ['', [Validators.required]],
    url: ['', [Validators.required, Validators.pattern(this.regUrl)]],
    domainId: [null],
    packageId: [null, [Validators.required]],
    activatedAt: [''],
    expiredAt: [''],
    config: new FormGroup({
      logo: new FormControl(''),
      shape: new FormControl('classy'),
      color: new FormControl('#000000'),
      backgroundColor: new FormControl('#ffffff'),
    }),
    setting: new FormGroup({
      requiredCustomerInfo: new FormControl(false),
      allowedScan: new FormControl(true),
      customerInfoForm: new FormControl(false)
    }),
  });
  // listPackage = [];
  @Input() listPackage = [];
  @Input() isChangeStampTypeQRCode = false;
  settingActiveCollapse = {
    showShape: true,
    showColor: true,
    showLogo: true,
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',
    },
  };
  listYear = [
    {value: 1, label: '1 năm'},
    {value: 2, label: '2 năm'},
    {value: 3, label: '3 năm'},
  ];
  id;
  isUpdate = false;
  listSetting = [
    {value: 1, label: 'Mẫu thông tin khách hàng', key: 'customerInfoForm'},
    // {value: 2, label: 'Cho App khác quét', key: 'allowedScan'},
  ];
  listBtnColors: Array<string> = [
    '#000000',
    '#EF6D46',
    '#8FC743',
    '#F390BC',
    '#83D1D5',
    '#6CB5E1',
    '#415CA0',
    '#7E62A1',
  ];
  logos = [];
  listDomain = [];
  templateData: any = {};
  templateDataSubject: any;
  validExpiredAt = true;
  currentPackage: any;
  isLoadingBtn = false;
  isNewQr: true;
  loadingDrawQrcode;
  qrcodeConfig = {
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 1000,
    height: 1000,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  };

  getListPackageActive() {
    // this.stampService.getListPackageActive({type: 1}).subscribe(res => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }

  ngOnInit() {
    this.templateDataSubject = new BehaviorSubject(this.templateData);
    this.getListPackageActive();
    this.getListDomainByUser();
    this.route.queryParams.subscribe((params) => {
      if (params.url) {
        this.form.patchValue({
          url: params.url,
        });
      }
    });
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== 'null') {
      this.isUpdate = true;
      this.getStampV2Detail(this.id);
    } else {
      this.isNewQr = true;
    }

    this.changeValueForm();
  }

  getStampV2Detail(id) {
    this.stampService.getStampById(id).subscribe(async res => {
      if (res.code === 1 && res.data) {
        // const data = res.data;
        const data = {
          ...res.data,
          newQr: true
        };
        this.isNewQr = data.newQr || false;
        const activatedAt = data.activatedAt.split('/');
        // tslint:disable-next-line:variable-name
        const activatedAtConverted = [activatedAt[1], activatedAt[0], activatedAt[2]].join(
          '/'
        );
        const expiredAt = data.expiredAt.split('/');
        // tslint:disable-next-line:variable-name
        const expiredAtConverted = [expiredAt[1], expiredAt[0], expiredAt[2]].join(
          '/'
        );

        if (data.packageInfo && data.packageInfo.id) {
          this.listPackage = [{...data.packageInfo}];
          this.form.patchValue({
            packageId: data.packageInfo.id
          });
        }
        this.form.patchValue({
          name: data.name,
          activatedAt: activatedAtConverted,
          expiredAt: expiredAtConverted,
          url: data.url,
          setting: data.setting,
          domainId: data.domainId,
          // packageId: res.packageInfo.id
        });
        if (data.newQr) {
          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType, data.config, data.frameConfig, data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(data.qrCode);
        } else {
          if (data.config && data.config.color) {
            this.form.get('config.color').setValue(data.config.color);
          }
          if (data.config && data.config.backgroundColor) {
            this.form
              .get('config.backgroundColor')
              .setValue(data.config.backgroundColor);
          }
          if (data.config && data.config.logo) {
            this.form.get('config.logo').setValue(data.config.logo);
            this.logos = [{ url: data.config.logo }];
          }
          if (data.config && data.config.shape) {
            this.form.get('config.shape').setValue(data.config.shape);
          }
        }
      }
    });
  }

  selectedQrCode(value) {
    if (this.form.get('config.shape').value !== value) {
      this.form.get('config.shape').setValue(value);
    } else {
      this.form.get('config.shape').setValue('');
    }
  }
  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp,mp4,pdf,docx';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logos') {
            this.logos = [{ url: changeParam.file.response.url }];
            this.form
              .get('config.logo')
              .setValue(this.logos[0].url);
            this.form.updateValueAndValidity();

          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  onSelectColor(color: string) {
    if (this.form.value.config.color !== color) {
      this.form.get('config.color').setValue(color);
    } else {
      this.form.get('config.color').setValue('');
    }
  }

  onSelectbackgroundColor(color: string) {
    if (this.form.value.config.backgroundColor !== color) {
      this.form.get('config.backgroundColor').setValue(color);
    } else {
      this.form.get('config.backgroundColor').setValue('');
    }
    // this.form.get("config.backgroundColor").setValue(color);
  }

  onRemoveFile(field: string) {
    this.logos = [];
    this.form.get('config.logo').setValue('');
  }
  getListDomainByUser() {

    const query: any = {
      page: 1,
      pageSize: 100
    };

    this.stampService
      .getListDomain(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }
  changeValueForm() {
    this.form.get('config').valueChanges.subscribe((val: any) => {
      this.templateData = {
        ...this.form.value,
        config: { ...this.form.get('config').value },
      };
      this.templateDataSubject.next(this.templateData);
    });
    this.form.get('expiredAt').valueChanges.subscribe(val => {
      // tslint:disable-next-line:max-line-length
      if (this.form.value.activatedAt && formatDate(val, 'yyyy-MM-dd', 'en_US') <= formatDate(this.form.value.activatedAt, 'yyyy-MM-dd', 'en_US')) {
        this.validExpiredAt = false;
      } else {
        this.validExpiredAt = true;
      }
    });
  }

  getDate(date, tmpNumber) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (tmpNumber === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute;
  }


  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }
  selectedPackage(event) {
    if (event) {
      this.currentPackage = this.listPackage.find(item => item.id === event);
    }
  }

  async submit() {
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);
    if (this.form.invalid) {
      this.templateQRCodeViewRef.onChangeTabActive();
      return;
    }
    this.isLoadingBtn = true;
    const newForm = {
      ...this.form.value,
      activatedAt: this.form.value.activatedAt ? this.getDate(this.form.value.activatedAt, 1) : '',
      expiredAt: this.form.value.expiredAt ? this.getDate(this.form.value.expiredAt, 1) : this.currentPackage.expiredAt,
      type: 2
    };
    if (this.isNewQr) {
      const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
      newForm.config = {...this.qrcodeDesignComponentRef.getQrcodeDesignConfig()};
      newForm.qrcodeFrameId = this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
      const formData = new FormData();
      formData.append('file', base64QrcodeSvg);
      const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
      if (qrImageResponse.status === 200) {
        newForm.qrImage = qrImageResponse.data || '';
      }
    }
    if (this.isUpdate) {
      if (this.isNewQr) {
        this.stampService
          .update(this.id, newForm)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            if (res.code === 1) {
              // track("alert", {
              //   alertType: "success",
              //   alertMessage: "Thêm mới QR Code thành công"
              // });
              this.openMessage('success', 'Thành công', 'Cập nhật thành công');
              let delayTimer: any;
              clearTimeout(delayTimer);
              delayTimer = setTimeout(() => {
                this.router.navigate(['/portal/stamp']);
                return;
              }, 500);
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
            this.isLoadingBtn = false;
          }, error => {
            this.isLoadingBtn = false;
            this.openMessage('error', 'Lỗi !!!', `${error}`);
          });
      } else {
        const qrcodeData = `${environment.redirectScanUrl}/${this.id}`;
        const tmpQrcodeConfig = {
          ...this.qrcodeConfig,
          backgroundOptions: {
            color: this.form.get('config.backgroundColor').value
          },
          dotsOptions: {
            type: this.form.get('config.shape').value,
            color: this.form.get('config.color').value,
          },
          image: this.form.get('config.logo').value,
          data: qrcodeData,
        };
        const qrcodeInstance = new QRCodeStyling(tmpQrcodeConfig as any);
        const rawQrcodeImage = await qrcodeInstance.getRawData();
        const formData = new FormData();
        formData.append('content', rawQrcodeImage, 'qrcode.png');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.uploadService.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (result) => {
            if (result.status === 200 && result.data && result.data.url) {
              newForm.qrImage = result.data.url;
              this.stampService
                .update(this.id, newForm)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  if (res.code === 1) {
                    this.openMessage('success', 'Thành công', 'Cập nhật thành công');
                    let delayTimer: any;
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(() => {
                      this.router.navigate(['/portal/stamp']);
                      return;
                    }, 500);
                  } else {
                    this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                  }
                  this.isLoadingBtn = false;
                }, error => {
                  this.isLoadingBtn = false;
                  this.openMessage('error', 'Lỗi !!!', `${error}`);
                });
            }
          },
          (err) => {
            this.openMessage('error', 'Cập nhật không thành công', `Vui lòng thử lại`);
          }
        );
      }
    } else {
      newForm.qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
      this.stampService
        .create(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track("alert", {
            //   alertType: "success",
            //   alertMessage: "Thêm mới QR Code thành công"
            // });
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/stamp']);
              return;
            }, 500);
          } else {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.isLoadingBtn = false;
        }, error => {
          this.isLoadingBtn = false;
          this.openMessage('error', 'Lỗi !!!', `${error}`);
        });
    }
  }

  onChangeInfoForm(e, key: string) {
    if (key === 'customerInfoForm') {
      this.form.get('setting.requiredCustomerInfo').setValue(e);
    }
  }

  back() {
    this.router.navigate(['/portal/stamp']);
  }

  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }

  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }
}
