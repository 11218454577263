import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {BusinessInfoService} from '../..';
@Injectable({
  providedIn: 'root'
})
export class BusinessResolver implements Resolve<any> {
  private messageSource = new BehaviorSubject('Default message');
  currentMessage = this.messageSource.asObservable();

  public numberSource = new BehaviorSubject(0);
  numberSource$ = this.numberSource.asObservable();

  constructor(
    private  businessInfoService: BusinessInfoService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.businessInfoService.getInfoBusiness()
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // tslint:disable-next-line:variable-name
  setUnread(number) {
    this.numberSource.next(number);
  }
  changeMessage(message: string) {
    this.messageSource.next(message);
  }
}
