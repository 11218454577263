import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsListComponent } from './products-list.component';
import { ProductsDetailComponent } from './products-detail/products-detail.component';
import {
  ProductsAnalyticsComponent,
  ProductsFormComponent,
  ProductsReviewsComponent,
  ProductsQuestionsComponent,
  ProductsStoresComponent,
  ProductsDocumentsComponent,
  ProductsDistributorsComponent,
  ProductsSettingComponent, SettingMessageComponent, SettingRelatedComponent
} from 'src/app/business/products/products-detail';

const routes: Routes = [
  {
    path: '',
    component: ProductsListComponent,
  },
  {
    path: ':id',
    component: ProductsDetailComponent,
    children: [
      {
        path: '',
        component: ProductsFormComponent
      },
      {
        path: 'reviews',
        component: ProductsReviewsComponent
      },
      {
        path: 'questions',
        component: ProductsQuestionsComponent
      },
      {
        path: 'stores',
        component: ProductsStoresComponent
      },
      {
        path: 'documents',
        component: ProductsDocumentsComponent
      },
      {
        path: 'distributors',
        component: ProductsDistributorsComponent
      },
      {
        path: 'analytics',
        component: ProductsAnalyticsComponent
      },
      {
        path: 'setting',
        component: ProductsSettingComponent,
        children: [
          {
            path: '',
            component: SettingMessageComponent
          },
          {
            path: 'relateds',
            component: SettingRelatedComponent
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }

