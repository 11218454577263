import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NzFormatEmitEvent,
  NzNotificationService,
  NzTreeNodeOptions,
} from "ng-zorro-antd";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { SurveyService } from "src/app/services";

@Component({
  selector: "app-survey-group-form",
  templateUrl: "./survey-group-form.component.html",
  styleUrls: ["./survey-group-form.component.scss"],
})
export class SurveyGroupFormComponent implements OnInit {
  @Output() reloadData = new EventEmitter<boolean>()
  unsubscribe$ = new Subject<void>();
  isVisible: boolean = false;
  title: string = "Tạo nhóm khảo sát";
  form: FormGroup = this.fb.group({
    addExist: [true],
    groupId: [null, [Validators.required]],
    name: [null],
  });
  value: "";
  listSurveyGroup: Array<any> = [];
  loading: boolean = false;
  listSurvey: any = []

  constructor(
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {}

  handleCancel() {
    this.isVisible = false;
    this.form.reset()
  }

  handleOk() {
    let formValue = this.form.value
    let body: any = {
      addToGroupOnly: formValue.addExist
    }
    let successMess = ""
    if(formValue.groupId) {
      body.groupId = formValue.groupId
    }
    if(formValue.name && formValue.name.trim() != '') {
      body.name = formValue.name
    }
    if(this.listSurvey && this.listSurvey.length) {
      body.surveyIds = this.listSurvey.map(gg => gg.id)
      successMess = "Gộp nhóm khảo sát thành công"
    } else {
      successMess = "Thêm nhóm khảo sát mới thành công"
    }
    this.loading = true
    this.surveyService.createSurveyGroup(body).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if(res.code === 1) {
        this.notification.success('Thành công', successMess)
        this.isVisible = false
        this.form.reset()
        this.reloadData.emit(true)
      } else {
        this.notification.warning('Có lỗi xảy ra', res.message || 'Vui lòng thử lại')
      }
      this.loading = false
    }, (err: any) => {
      this.notification.error('Có lỗi xảy ra', err.message || 'Vui lòng thử lại')
      this.loading = false
    })
  }

  showModal(type: string, listSurvey?: any) {
    this.isVisible = true;
    this.getListSurveyGroups()
    if(listSurvey && listSurvey.length) {
      this.listSurvey = [...listSurvey]
      this.title = 'Gộp khảo sát thành nhóm'
    } else {
      this.listSurvey = []
      this.title = "Tạo nhóm khảo sát";
    }
  }

  getListSurveyGroups() {
    let query = {
      parentId: "",
      page: 1,
      pageSize: 10,
    };
    this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listSurveyGroup = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
        }
      });
  }

  onExpandChange(e: NzFormatEmitEvent): void {
    const node = e.node;
    if (
      node &&
      node.getChildren().length === 0 &&
      node.isExpanded &&
      node.origin &&
      node.origin.id
    ) {
      this.loadChildSurveyGroup(node.origin.id).subscribe((res: any) => {
        if (res.code === 1) {
          let childData = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
          if (childData && childData.length) {
            node.addChildren(childData);
          } else {
            node.isLeaf = true;
          }
        }
      });
    }
  }

  loadChildSurveyGroup(parentId: string): Observable<NzTreeNodeOptions[]> {
    let query = {
      parentId,
      page: 1,
      pageSize: 10,
    };
    return this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$));
  }

  onChangeAddExist(e: any) {
    if(!e) {
      this.form.controls.groupId.setValidators(null)
      this.form.controls.name.setValidators([Validators.required])
      this.form.controls.name.updateValueAndValidity()
      
    } else {
      this.form.controls.groupId.setValidators([Validators.required])
      this.form.controls.name.setValidators(null)
      this.form.controls.name.updateValueAndValidity()
    }
    if(this.form.controls.groupId) {
      this.form.controls.groupId.reset()
      this.form.controls.name.reset()
    }
  }
}
