import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UploaderComponent} from '../../uploader';
import {takeUntil} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {DocumentsService} from 'src/app/services/business/documents/documents.service';

@Component({
  selector: 'app-document-add',
  templateUrl: './document-add.component.html',
  styleUrls: ['./document-add.component.scss']
})
export class DocumentAddComponent implements OnInit {
  isVisible = false;
  loading = false;
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    documents: [this.fb.control([]), [Validators.required]],
    date: [''],
  });
  documents: any[] = [];
  files: any[] = [];
  type;
  notDocuments = false;
  validName = false;
  message = '';
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();

  constructor(
    private  fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private docService: DocumentsService,
  ) {
  }

  ngOnInit() {
    this.validName = false;
    this.message = '';
    this.notDocuments  = false;
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  getDate(date) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy + ' ' + '23:59:59';
  }

  show() {
    this.isVisible = true;
    this.notDocuments = false;
    this.validName = false;
    this.message = '';
    this.form.reset();
    this.documents = [];
    this.files = [];
  }

  hide() {
    this.loading = false;
    this.isVisible = false;
    this.form.reset();
    this.documents = [];
    this.files = [];
    this.notDocuments = false;
  }
  inputChange(event) {
    this[event] = false;
  }
  add() {
    if (this.files.length === 0) {
      this.notDocuments = true;
    } else {
      this.notDocuments = false;
    }
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    // tslint:disable-next-line:one-variable-per-declaration
    const title$ = this.form.get('name').value;
    if (title$.trim().length === 0) {
      this.validName = true;
      this.message = 'Tên giấy tờ không hợp lệ';
      return;
    }
    if (title$.trim().length > 255) {
      this.validName = true;
      this.message = 'Tên giấy tờ không quá 255 ký tự';
      return;
    }
    this.loading = true;
    const listUrl$ = this.form.get('documents').value;
    const expiredAt$ = this.form.get('date').value ? this.getDate(this.form.get('date').value) : '';
    const type$ = this.type;
    const body = {
      title: title$,
      listUrl: listUrl$,
      expiredAt: expiredAt$,
      type: type$
    };
    this.emitData.emit(body);
  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp,pdf';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      // nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name.length >= 50 ? changeParam.file.name.slice(0, 50) : changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.type =  changeParam.file.type;
          this.files.push(data);
          if (this.files.length === 0) {
            this.notDocuments = true;
          } else {
            this.notDocuments = false;
          }
          if (this.form.get('documents').value == null) {
            this.form.get('documents').setValue([]);
          }
          this.form.get('documents').value.push(changeParam.file.response.url);
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(changeParam => {
        modal.close();
      });
    });
  }

  onRemoveFile(field: string, index: number) {
    this[field].splice(index, 1);
    this.form.get('documents').value.splice(index, 1);
  }
  delete(file, i) {
    if (this.form.get('documents').value !== null && this.form.get('documents').value.includes(file)) {
      this.form.get('documents').value.splice(this.form.get('documents').value.indexOf(file), 1);
      this.files.splice(i, 1);
    }
    if (this.files.length === 0) {
      this.notDocuments = true;
    } else {
      this.notDocuments = false;
    }
  }
  success() {
    this.loading = false;
  }
}
