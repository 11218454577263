import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { StampService } from 'src/app/services/business/stamp/stamp.service';

@Component({
  selector: 'app-public-loyalty',
  templateUrl: './public-loyalty.component.html',
  styleUrls: ['./public-loyalty.component.scss']
})
export class PublicLoyaltyComponent implements OnInit {

  currentStamp;
  giftCode: any;
  page = 1;
  pageSize = 9;
  totalElement: any;

  listCampaign = []
  constructor(private router: Router,
    private stampService: StampService,
    private notification: NzNotificationService) { }

  ngOnInit() {
    const id = window.location.href.split("/")[5];
    if (id !== "null") {
      this.stampService
        .getStampById(id)
        .subscribe(
          (res) => {

            this.currentStamp = res;

            if (res.loyaltyCampaign && res.loyaltyCampaign.type === 1) {
              if (res.loyaltyCampaign && res.loyaltyCampaign.code) {
                this.selectedItem = res.loyaltyCampaign.code
              }

              if (res.loyaltyCampaign && res.loyaltyCampaign.giftCode) {
                this.giftCode = res.loyaltyCampaign.giftCode
              }
            }

          }
        )
    }
    this.loadListPublicCampaign();
  }

  loadListPublicCampaign() {
    const body = {
      page: this.page,
      page_size: this.pageSize
    }
    this.stampService.getListPublicLoyalty(body).subscribe(
      (res) => {

        this.listCampaign = res.items;
        this.totalElement = res.totalElement;
      }
    )
  }


  selectedItem: any;
  listClick(event, newValue) {

    if (this.selectedItem === newValue) {
      this.selectedItem = ""
    } else {
      this.selectedItem = newValue;
    }
  }

  back() {
    this.router.navigate(["/portal/stamp"]);
  }

  submit() {

    const body = {
      ...this.currentStamp,
      loyaltyCampaignType: 1,
      loyaltyCampaignObject: this.selectedItem ? this.selectedItem : '',
      loyaltyGiftCode: this.giftCode ? this.giftCode.trim() : ''
    }




    this.stampService
        .update(this.currentStamp.id, body)
        .subscribe((res) => {
          if (res.code === 1) {
            this.openMessage("success", "Thành công", "Cập nhật thành công");
            let delayTimer: any;
            delayTimer = setTimeout(() => {
              this.router.navigate(["/portal/stamp"]);
              return;
            }, 500);
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
        }),
        (err) => {
          this.openMessage("err", "Lỗi !!!", `${err}`);
        };
  }


  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  changePagination(event) {


    this.page = event;
    this.loadListPublicCampaign();

  }

}
