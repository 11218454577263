import { Component, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import { SurveyService } from "src/app/services/business/survey/survey.service";
import { formatDate } from "src/app/helpers";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import {
  NzFormatEmitEvent,
  NzNotificationService,
  NzTreeNodeOptions,
} from "ng-zorro-antd";
import moment from "moment";
import { track } from 'src/app/helpers/track';
@Component({
  selector: "app-survey-dashboard",
  templateUrl: "./survey-dashboard.component.html",
  styleUrls: ["./survey-dashboard.component.scss"],
})
export class SurveyDashboardComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  listSurveyGroup: Array<any> = [];
  listSurvey: Array<any> = [];
  currentTimeType: string = "DAY";
  query: any = {
    time: [],
    surveyIdHash: undefined,
    surveyGroupIdHash: undefined,
    timeType: "",
  };
  reportOverview: any = {};
  chartLineX: any = [];
  chartLineData: any = [];
  chartPieData: any = [];
  constructor(
    private surveyService: SurveyService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_survey_dashboard',
    //   contentType: 'stastic',
    // })
    this.onChangeTime("MONTH");
    this.getListSurveyGroups();
    this.getListSurveys();
  }

  onChangeTime(timeType: string, startDay?: any, endDay?: any) {
    this.currentTimeType = timeType;
    this.query.timeType = timeType;
    this.createChartTimeline(timeType, startDay, endDay);
    this.chartLineData = [];

    this.createRangeDate(timeType, startDay, endDay);
    this.searchDashboard(timeType);
  }

  onChageGroupSurvey(e: string) {
    this.query.surveyIdHash = undefined;
    this.getListSurveys("", e || "");
    this.searchDashboard(this.currentTimeType);
  }

  createRangeDate(timeType: string, startDay?: any, endDay?: any) {
    if (timeType === "DAY") {
      let day = formatDate(Date.now()).split(" ")[1].split("/");
      this.query.time[0] = [day[1], day[0], day[2]].join("/");
      this.query.time[1] = [day[1], day[0], day[2]].join("/");
      this.query.time = [...this.query.time];
    }
    if (timeType === "OTHER_WEEK") {
      this.query.time[0] = [
        startDay.split("/")[0],
        startDay.split("/")[1],
        startDay.split("/")[2],
      ].join("/");
      this.query.time[1] = [
        endDay.split("/")[0],
        endDay.split("/")[1],
        endDay.split("/")[2],
      ].join("/");
      this.query.time = [...this.query.time];
    }
    if (timeType === "WEEK") {
      let startDayInWeek =
        new Date().getDate() -
        new Date().getDay() +
        (new Date().getDay() === 0 ? -6 : 1);
      let startTime = formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeek)).setHours(0, 0, 0, 1)
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      let endTime = formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeek + 6)).setHours(
            23,
            59,
            59,
            999
          )
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      this.query.time[0] = [startTime[1], startTime[0], startTime[2]].join("/");
      this.query.time[1] = [endTime[1], endTime[0], endTime[2]].join("/");
      this.query.time = [...this.query.time];
    }
    if (timeType === "MONTH") {
      let startTime = formatDate(
        new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
            0,
            0,
            0,
            1
          )
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      let endTime = formatDate(
        new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ).setHours(23, 59, 59, 999)
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      this.query.time[0] = [startTime[1], startTime[0], startTime[2]].join("/");
      this.query.time[1] = [endTime[1], endTime[0], endTime[2]].join("/");
      this.query.time = [...this.query.time];
    }
  }

  onChangeFilterTime(e: any) {
    let timeType = "OTHER_WEEK";
    this.query.time[0] = new Date(e[0]).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    this.query.time[1] = new Date(e[1]).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    if (this.query.time[0] == this.query.time[1]) {
      timeType = "OTHER_DAY";
    }
    this.onChangeTime(timeType, this.query.time[0], this.query.time[1]);
    // this.searchDashboard(timeType);
  }

  getDashboardReportOverview(timeType: string) {
    let body = { ...this.query };
    let _from = this.query.time[0].split("/");
    let _to = this.query.time[1].split("/");
    body.from = [_from[1], _from[0], _from[2]].join("/");
    body.to = [_to[1], _to[0], _to[2]].join("/");
    if (timeType === "OTHER_WEEK") {
      body.timeType = "WEEK";
    }
    if (timeType === "OTHER_DAY") {
      body.timeType = "DAY";
    }
    // track('siteSearch', {
    //   keywords: "portal_survey_dashboard",
    //   params: JSON.stringify(body)
    // })
    this.surveyService
      .dashboardReportOverview(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res && res.code === 1) {
            this.reportOverview = res.data ? { ...res.data } : {};
          } else {
            this.notification.warning(
              "Có lỗi xảy ra",
              res && res.message ? res.massage : "Vui lòng thử lại"
            );
          }
        },
        (err) => {
          this.notification.warning(
            "Có lỗi xảy ra",
            err && err.message ? err.massage : "Vui lòng thử lại"
          );
        }
      );
  }

  getDashboardReportByTime(timeType: string) {
    let body = { ...this.query };
    let _from = this.query.time[0].split("/");
    let _to = this.query.time[1].split("/");
    body.from = [_from[1], _from[0], _from[2]].join("/");
    body.to = [_to[1], _to[0], _to[2]].join("/");
    if (timeType === "OTHER_WEEK") {
      body.timeType = "WEEK";
    }
    if (timeType === "OTHER_DAY") {
      body.timeType = "DAY";
    }
    // track('siteSearch', {
    //   keywords: "portal_survey_dashboard",
    //   params: JSON.stringify(body)
    // })
    this.surveyService
      .dashboardReportByTime(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res && res.code === 1) {
          let items = res.data && res.data.items ? res.data.items : [];
          if (items && items.length) {
            this.chartLineData = this.chartLineX.map((gg: any) => {
              let findItem = items.find((hichic: any) => {
                if (timeType === "DAY" || timeType === 'OTHER_DAY') {
                  let valueHour = gg.slice(0, -1);
                  return hichic.title === valueHour;
                }
                return hichic.title === gg;
              });
              if (findItem) {
                return {
                  y: findItem.value || 0,
                };
              } else {
                return {
                  y: 0,
                };
              }
            });
          } else {
            this.chartLineData = this.chartLineX.map((_) => ({ y: 0 }));
          }
          if (this.chartLineData && this.chartLineData.length) {
            setTimeout(() => {
              this.loadChartLine();
            }, 300);
          }
        }
      });
  }

  getDashboardReportByLocation(timeType: string) {
    let body = { ...this.query };
    let _from = this.query.time[0].split("/");
    let _to = this.query.time[1].split("/");
    body.from = [_from[1], _from[0], _from[2]].join("/");
    body.to = [_to[1], _to[0], _to[2]].join("/");
    if (timeType === "OTHER_WEEK") {
      body.timeType = "WEEK";
    }
    if (timeType === "OTHER_DAY") {
      body.timeType = "DAY";
    }
    // track('siteSearch', {
    //   keywords: "portal_survey_dashboard",
    //   params: JSON.stringify(body)
    // })
    this.surveyService
      .dashboardReportByLocation(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res && res.code === 1) {
          this.chartPieData =
            res.data && res.data.items
              ? res.data.items.map((gg: any) => {
                  return [gg.province || "Chưa xác định", gg.value || 0];
                })
              : [];
        }
        if (this.chartPieData && this.chartPieData.length) {
          setTimeout(() => {
            this.loadChartPie();
          }, 300);
        }
      });
  }

  loadChartPie() {
    Highcharts.chart("pie-chart", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        type: "pie",
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: `Tỉ lệ khảo sát: <b>{point.percentage:.1f}%</b>`,
      },
      series: [
        {
          type: "pie",
          allowPointSelect: true,
          keys: ["name", "y"],
          data: this.chartPieData,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      ],
    });
  }

  loadChartLine() {
    Highcharts.chart("line-chart", {
      chart: {
        type: "area",
      },
      title: {
        align: "left",
        text: "",
      },
      xAxis: {
        categories: this.chartLineX,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: `Số lượng khảo sát`,
          colorByPoint: true,
          data: this.chartLineData,
          type: undefined,
        },
      ],
    });
  }

  createChartTimeline(timeType: string, startDay?: any, endDay?: any) {
    this.chartLineX = [];
    if (timeType === "DAY" || timeType === 'OTHER_DAY') {
      this.chartLineX = Array.from({ length: 24 }, (_, i) => i + 1).map(
        (gg) => gg + "h"
      );
    }
    if (timeType === "MONTH") {
      let today = new Date();
      let endDay = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).getDate();

      this.chartLineX = Array.from({ length: endDay }, (_, i) => i + 1).map(
        (gg) => {
          return (
            (gg < 10 ? "0" + gg : gg) +
            "/" +
            (today.getMonth() + 1 < 10
              ? "0" + (today.getMonth() + 1)
              : today.getMonth() + 1) +
            "/" +
            today.getFullYear()
          );
        }
      );
    }
    if (timeType === "WEEK") {
      let today = new Date();
      for (let i = 1; i <= 7; i++) {
        let first = today.getDate() - today.getDay() + i;
        let day = new Date(today.setDate(first))
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");

        this.chartLineX.push(day);
      }
    }
    if (timeType === "OTHER_WEEK") {
      let formatStart = startDay.split("/")[2] + '-' + startDay.split("/")[0] + '-' + startDay.split("/")[1]
      let formatEnd = endDay.split("/")[2] + '-' + endDay.split("/")[0] + '-' + endDay.split("/")[1]
      while(moment(formatStart) <= moment(formatEnd)){
        let arr = [formatStart.split("-")[2], formatStart.split("-")[1], formatStart.split("-")[0]]
        this.chartLineX.push(arr.join("/"));
        formatStart = moment(formatStart).add(1, 'days').format("YYYY-MM-DD");
      }
    }
    this.chartLineX = [...this.chartLineX];
  }

  searchDashboard(timeType: string) {
    this.getDashboardReportOverview(timeType);
    this.getDashboardReportByTime(timeType);
    this.getDashboardReportByLocation(timeType);
  }

  resetSearch() {
    this.query.time = [];
    this.query.surveyIdHash = undefined;
    this.query.surveyGroupIdHash = undefined;
    this.query.timeType = "";
    this.onChangeTime("MONTH");
  }

  onExpandChange(e: NzFormatEmitEvent): void {
    const node = e.node;
    if (
      node &&
      node.getChildren().length === 0 &&
      node.isExpanded &&
      node.origin &&
      node.origin.id
    ) {
      this.loadChildSurveyGroup(node.origin.id).subscribe((res: any) => {
        if (res.code === 1) {
          let childData = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
          if (childData && childData.length) {
            node.addChildren(childData);
          } else {
            node.isLeaf = true;
          }
        }
      });
    }
  }

  loadChildSurveyGroup(parentId: string): Observable<NzTreeNodeOptions[]> {
    let query = {
      parentId,
      page: 1,
      pageSize: 10,
    };
    return this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$));
  }

  getListSurveyGroups() {
    let query = {
      parentId: "",
      page: 1,
      pageSize: 10,
    };
    this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listSurveyGroup = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
        }
      });
  }

  getListSurveys(name: string = "", groupId: string = "") {
    let query = {
      name,
      groupId,
      page: 1,
      pageSize: 20,
    };
    // track("siteSearch", {
    //   payload: JSON.stringify(query)
    // })
    this.surveyService
      .getListSurveys(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res && res.code === 1) {
            this.listSurvey = res && res.data ? [...res.data] : [];
          } else {
            // track('error', {
            //   apiCall: `${window.location.origin}/survey`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500
            // })
            this.notification.warning(
              "Có lỗi xảy ra",
              res && res.message ? res.message : "Vui lòng thử lại"
            );
          }
        },
        (err: any) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/survey`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.notification.error(
            "Có lỗi xảy ra",
            err && err.message ? err.message : "Vui lòng thử lại"
          );
        }
      );
  }
}
