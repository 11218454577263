import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { ProductsDocumentsService, ProductsService } from "src/app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NzNotificationService } from "ng-zorro-antd";
import { removeEmptyQuery, sleep } from "src/app/helpers";

@Component({
  selector: "app-products-documents",
  templateUrl: "./products-documents.component.html",
  styleUrls: ["./products-documents.component.scss"],
})
export class ProductsDocumentsComponent implements OnInit, OnDestroy {
  constructor(
    private productDocument: ProductsDocumentsService,
    private notification: NzNotificationService,
    private productService: ProductsService
  ) {}

  unsubscribe$ = new Subject<void>();
  page = 1;
  pageSize = 10;

  productId: string;
  managementStatus: number;
  roleCode: string = null;

  // ================================list==================================//
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading: Boolean = true;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: Boolean;

  dataDelete = {
    label: "Xác nhận xóa điểm bán",
    value: "<p>Bạn có chắc chắn muốn xoá giấy tờ?</p>",
  };
  delayTimer: any;
  @ViewChild("createDocument", { static: true }) createDocument;
  @ViewChild("assignDocument", { static: true }) assignDocument;
  @ViewChild("deleteDocuments", { static: true }) deleteDocuments;
  action: any;

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  ngOnInit() {
    this.productId = window.location.href.split("/")[5];
    this.loadData();

    this.productService
      .getById(this.productId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.managementStatus = res.data.managementStatus;
            this.roleCode = res.data.roleCode;
            const body: any = {
              managementStatus: this.managementStatus,
              roleCode: this.roleCode,
            };
            this.productService.getManagementStatus(body);
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
        }
      );
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.documentId]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.documentId]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.documentId] = value)
    );
    this.refreshStatus();
  }

  sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async loadData(ms = 0) {
    const query: any = {
      product_id: this.productId,
      page: this.page,
      page_size: this.pageSize,
    };

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.productDocument
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listOfAllData = [];
          this.loading = false;
          this.total = res.data.totalElement;
          if (res.data.items !== null) {
            res.data.items.map((item) => {
              item.show_hide = true;
              const listUrl = item.listUrl;
              const totalPdf = [];
              listUrl.map((url) => {
                if (url.indexOf(".pdf") >= 0) {
                  totalPdf.push(url);
                }
              });
              if (totalPdf.length === listUrl.length) {
                item.show_hide = false;
              }
              this.listOfAllData.push(item);
            });
          }
          if (!res.data.items && this.page > 1) {
            this.page = this.page - 1;
            this.loadData();
            return;
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  showDocument(imageLink: any) {
    window.open(imageLink, "_blank");
  }

  hide(id?: any) {
    this.productDocument.hide_show(id).subscribe(
      (res) => {
        if (res.code === 1) {
          this.notification.success(
            "Thành công",
            "Ẩn giấy tờ sản phẩm thành công"
          );
          this.loadData();
          return;
        }
        this.notification.error("Ẩn giấy tờ sản phẩm thất bại", res.message);
      },
      (error) => {
        this.notification.error("Ẩn giấy tờ sản phẩm thất bại", error.message);
      }
    );
  }

  show(id?: any) {
    this.productDocument.hide_show(id).subscribe(
      (res) => {
        if (res.code === 1) {
          this.notification.success(
            "Thành công",
            "Hiện giấy tờ sản phẩm thành công"
          );
          this.loadData();
          return;
        }
        this.notification.error("Hiện giấy tờ sản phẩm thất bại", res.message);
      },
      (error) => {
        this.notification.error(
          "Hiện giấy tờ sản phẩm thất bại",
          error.message
        );
      }
    );
  }

  delete(id: any) {
    this.productDocument
      .destroy(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage("success", "Thành công", "Xóa giấy tờ thành công");
            this.deleteDocuments.handleCancel();
            this.loadData(500);
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
          this.deleteDocuments.success();
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.deleteDocuments.success();
          return;
        }
      );
  }

  create(data: any) {
    this.productDocument
      .create(this.productId, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.openMessage(
              "success",
              "Thành công",
              "Thêm giấy tờ thành công"
            );
            this.createDocument.hide();
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  updateDocumentSuccess(event) {
    this.loadData();
  }

  assign(data: any) {
    this.productDocument
      .assign(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.assignDocument.success();
          if (res.code === 1) {
            this.openMessage(
              "success",
              "Thành công",
              "Thêm giấy tờ thành công"
            );
            this.assignDocument.handleCancel();
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.assignDocument.success();
          return;
        }
      );
  }

  actionsButton() {
    if (this.action == 1) {
      this.createDocument.show();
    } else if (this.action == 2) {
      this.assignDocument.showModal();
    } else if (this.action == 3) {
      this.deleteDocuments.showModal(this.dataDelete, this.ids);
    }
    this.action = null;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
