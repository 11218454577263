import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-template-menu-two',
  templateUrl: './template-menu-two.component.html',
  styleUrls: ['./template-menu-two.component.scss']
})
export class TemplateMenuTwoComponent implements OnInit, OnChanges {
  @Input() menuData;
  @Input() foodCategories;
  category: any = [];
  foodSales: any = [];
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.menuData.foods && this.menuData.foods.length && this.foodCategories && this.foodCategories.length) {
      // tslint:disable-next-line:only-arrow-functions
      const foods = this.menuData.foods.filter(item => item.categoryId);
      const result = foods.reduce((r, c) => {
        r[c.categoryId] = r[c.categoryId] || {
          categoryId: c.categoryId,
          name: this.foodCategories.filter(item => item.id === c.categoryId)[0].name,
          price: c.price,
          ccy: c.ccy,
          priceSale: c.priceSale,
          foods: [],
        };
        r[c.categoryId].foods.push(c);
        return r;
      }, {});
      this.category = ([...Object.values(result)] as any).filter(item => item.categoryId !== null);
    }

    // if (this.menuData.foodSales && this.menuData.foodSales.length) {
      const foodSales = [];
      if (this.menuData.foodSales && this.menuData.foodSales.length) {
        this.menuData.foodSales.forEach(id => {
          const foodSale = this.menuData.foods.find(f => f.id === id);
          if (foodSale && foodSale.id) {
            foodSales.push(foodSale);
          }
        });
      }
      this.foodSales = foodSales;
    // }

    if (this.menuData.description && this.menuData.description.length && this.menuData.description.length > 200) {
      this.isShowDescription = true;
      this.limitDescription = 200;
    } else  {
      this.isShowDescription = false;
      this.limitDescription = 500000;
    }
  }

  isShowDescription: boolean = false;
  limitDescription = 170;

  getTime(time) {
    if (!time) { return; }
    return moment(time).format('HH:mm');
  }

  isCheckFB() {
    return this.menuData.linkPages && this.menuData.linkPages.length ? !!this.menuData.linkPages.filter(item => item.type === 'facebook').length : false;
  }

  isCheckWeb() {
    return this.menuData.linkPages && this.menuData.linkPages.length ? !!this.menuData.linkPages.filter(item => item.type === 'website').length : false;
  }

  isCheckZalo() {
    return this.menuData.linkPages && this.menuData.linkPages.length ? !!this.menuData.linkPages.filter(item => item.type === 'zalo').length : false;
  }

  isCheckInstagram() {
    return this.menuData.linkPages && this.menuData.linkPages.length ? !!this.menuData.linkPages.filter(item => item.type === 'instagram').length : false;
  }

  isCheckTiktok() {
    return this.menuData.linkPages && this.menuData.linkPages.length ? !!this.menuData.linkPages.filter(item => item.type === 'tiktok').length : false;
  }

}
