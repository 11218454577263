import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-template-qrcode-view',
  templateUrl: './template-qrcode-view.component.html',
  styleUrls: ['./template-qrcode-view.component.scss']
})
export class TemplateQrcodeViewComponent implements OnInit, OnChanges {

  @Input() isTab1 = true;
  @Input() isTab2 = true;
  @Input() isTab3 = true;
  activeTabIndex: any = 0;
  // @Input() activeTabIndex;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("xxxxxxx", this.activeTabIndex);
    // console.log("changes", changes);
  }

  onChangeTabActive() {
    this.activeTabIndex = 0;
  }

}
