import { Directive, ElementRef, HostListener, Input, Renderer, Renderer2 } from '@angular/core';
import { RootService } from '../services';

@Directive({
  selector: '[appShowMenu]',
})
export class BusinesstDirective {

  constructor(
    private rootService: RootService,
  ) { }

  @Input('appShowMenu') isCollapsed = true;

  @HostListener('mouseenter') onMouseEnter() {
    this.isShow(false);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isShow(true);
  }

  private isShow(isCollapsed: boolean) {
    this.rootService.changeMessage(isCollapsed);
  }

}
