import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiBaseUrl) || request.url.startsWith(environment.apiAppBase);

    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request).pipe(catchError(err => {
      // logout
      if ([401, 403].includes(err.status)) {
        this.authService.logout();
        window.location.href = window.location.origin + '/qrcode-free/generate/TEXT';
      } else {
        const error = err.error.message || err.statusText;
        return throwError(error);
      }
      //   const error = err.error.message || err.statusText;
      // return throwError(error);
    }));
  }
}
