import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { DocumentsService } from 'src/app/services/business/documents/documents.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { removeEmptyQuery } from 'src/app/helpers';

@Component({
  selector: 'app-document-select',
  templateUrl: './document-select.component.html',
  styleUrls: ['./document-select.component.scss']
})
export class DocumentSelectComponent implements OnInit, OnDestroy {

  constructor(
    private documentsService: DocumentsService,
    private notification: NzNotificationService,
  ) {
  }

  unsubscribe$ = new Subject<void>();
  @Input() str: string;
  str2 = 'isShowProduct';

  page = 1;
  pageSize = 10;

  isVisible = false;

  @Output() addDocument2 = new EventEmitter<any>();

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading: Boolean = true;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: Boolean;

  @Output() addDocument = new EventEmitter<any>();
  isRequired: Boolean = false;

  showModal() {
    this.isVisible = true;
    this.loadData();
  }
  handleCancel() {
    this.isVisible = false;
    if (this.str2 === 'isShowProduct') {
      this.addDocument2.emit(this.str2);
      return;
    }
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate = this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
    this.ids = [];

    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  ngOnInit() {
    if (this.str === 'isShow') {
      this.isVisible = true;
      this.loadData();
    }
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  loadData() {
    const query: any = {
      page: this.page,
      page_size: this.pageSize,
    };

    removeEmptyQuery(query);

    this.loading = true;
    this.documentsService.list(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.loading = false;
        this.total = res.data.totalElement;
        this.listOfAllData = res.data.items;
      },
      (err) => {
        this.notification.create(
          'error',
          'Có lỗi xảy ra !!!',
          `${err}`
        );
        return;
      },
    );
  }

  showDocument(imageLink: any) {
    window.open(imageLink, '_blank');
  }
  onSubmit() {
    if (this.ids.length < 1) {
      this.isRequired = true;
      return;
    }
    // product
    if (this.str === 'isShow') {
      const data: any = {
        current: 2,
        ids: this.ids
      };
      this.addDocument2.emit(data);
      return;
    }
    // ?????
    const titles = [];
    const bodyIds = this.ids;
    this.documentsService.listIds(bodyIds).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      response.data.map(value => {
        // if (this.ids.includes(value.id)) {
          titles.push(value.title);
        // }
      });
      const data: any = {
        titles,
        ids: this.ids
      };
      this.addDocument.emit(data);
    });
  }

  // tslint:disable-next-line:variable-name
  setId(id: any, boolean: any) {
    this.mapOfCheckedId[id] = boolean;
  }

  setCheckboxAll() {
    this.mapOfCheckedId = {};
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
