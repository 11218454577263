import { Component, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import { NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formatDate } from "src/app/helpers";
import { SurveyService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { track } from 'src/app/helpers/track';
import moment from 'moment';

@Component({
  selector: 'app-survey-detail-dashboard',
  templateUrl: './survey-detail-dashboard.component.html',
  styleUrls: ['./survey-detail-dashboard.component.scss']
})
export class SurveyDetailDashboardComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  listSurveyGroup: Array<any> = [];
  query: any = {
    time: [],
    timeType: "",
    surveyIdHash: ""
  };
  reportOverview: any = {};
  reportDevice: any = {}
  chartLineX: any = [];
  chartLineData: any = [];
  chartPieData: any = [];
  currentTimeType: string = "DAY";
  idSurvey: string = ""
  detailSurvey: any = {}
  constructor( private surveyService: SurveyService,
    private notification: NzNotificationService,  private route: ActivatedRoute ) { }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_survey_details_dashboad',
    //   contentType: 'stastic',
    // })
    this.idSurvey = window.location.href.split("/")[6];
    if(this.idSurvey) {
      this.query.surveyIdHash = this.idSurvey
      this.getDetailSurvey(this.idSurvey)
    }
    this.onChangeTime("MONTH");
  }

  onChangeTime(timeType: string, startDay?: any, endDay?: any) {

    // track('siteSearch', {
    //   keywords: "portal_survey_details_dashboad",
    //   params: JSON.stringify({time: timeType})
    // })
    this.currentTimeType = timeType;
    this.query.timeType = timeType;
    this.createChartTimeline(timeType, startDay, endDay);
    this.chartLineData = [];

    this.createRangeDate(timeType, startDay, endDay);
    this.searchDashboard(timeType);
  }

  createRangeDate(timeType: string, startDay?: any, endDay?: any) {


    if (timeType === "DAY") {
      let day = formatDate(Date.now()).split(" ")[1].split("/");
      this.query.time[0] = [day[1], day[0], day[2]].join("/");
      this.query.time[1] = [day[1], day[0], day[2]].join("/");
      this.query.time = [...this.query.time];
    }
    // if (timeType === "OTHER_DAY") {
    //   let day = formatDate(startDay).split(" ")[1].split("/");
    //

    //   this.query.time[0] = [day[1], day[0], day[2]].join("/");
    //   this.query.time[1] = [day[1], day[0], day[2]].join("/");
    //   this.query.time = [...this.query.time];
    // }
    if (timeType === "OTHER_WEEK") {
      this.query.time[0] = [
        startDay.split("/")[0],
        startDay.split("/")[1],
        startDay.split("/")[2],
      ].join("/");
      this.query.time[1] = [
        endDay.split("/")[0],
        endDay.split("/")[1],
        endDay.split("/")[2],
      ].join("/");
      this.query.time = [...this.query.time];
    }
    if (timeType === "WEEK") {
      let startDayInWeek =
        new Date().getDate() -
        new Date().getDay() +
        (new Date().getDay() === 0 ? -6 : 1);
      let startTime = formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeek)).setHours(0, 0, 0, 1)
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      let endTime = formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeek + 6)).setHours(
            23,
            59,
            59,
            999
          )
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      this.query.time[0] = [startTime[1], startTime[0], startTime[2]].join("/");
      this.query.time[1] = [endTime[1], endTime[0], endTime[2]].join("/");
      this.query.time = [...this.query.time];
    }
    if (timeType === "MONTH") {
      let startTime = formatDate(
        new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
            0,
            0,
            0,
            1
          )
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      let endTime = formatDate(
        new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ).setHours(23, 59, 59, 999)
        ).getTime()
      )
        .split(" ")[1]
        .split("/");
      this.query.time[0] = [startTime[1], startTime[0], startTime[2]].join("/");
      this.query.time[1] = [endTime[1], endTime[0], endTime[2]].join("/");
      this.query.time = [...this.query.time];
    }
  }

  searchDashboard(timeType: string) {
    this.getDetailReportOverview(timeType);
    this.getDashboardReportByTime(timeType);
    this.getDashboardReportByLocation(timeType);
  }

  createChartTimeline(timeType: string, startDay?: any, endDay?: any) {
    this.chartLineX = [];
    if (timeType === "DAY" || timeType === 'OTHER_DAY') {
      this.chartLineX = Array.from({ length: 24 }, (_, i) => i + 1).map(
        (gg) => gg + "h"
      );
    }
    if (timeType === "MONTH") {
      let today = new Date();
      let endDay = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).getDate();

      this.chartLineX = Array.from({ length: endDay }, (_, i) => i + 1).map(
        (gg) => {
          return (
            (gg < 10 ? "0" + gg : gg) +
            "/" +
            (today.getMonth() + 1 < 10
              ? "0" + (today.getMonth() + 1)
              : today.getMonth() + 1) +
            "/" +
            today.getFullYear()
          );
        }
      );
    }
    if (timeType === "WEEK") {
      let today = new Date();
      for (let i = 1; i <= 7; i++) {
        let first = today.getDate() - today.getDay() + i;
        let day = new Date(today.setDate(first))
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");

        this.chartLineX.push(day);
      }
    }
    if (timeType === "OTHER_WEEK") {
      let formatStart = startDay.split("/")[2] + '-' + startDay.split("/")[0] + '-' + startDay.split("/")[1]
      let formatEnd = endDay.split("/")[2] + '-' + endDay.split("/")[0] + '-' + endDay.split("/")[1]
      while(moment(formatStart) <= moment(formatEnd)){
        let arr = [formatStart.split("-")[2], formatStart.split("-")[1], formatStart.split("-")[0]]
        this.chartLineX.push(arr.join("/"));
        formatStart = moment(formatStart).add(1, 'days').format("YYYY-MM-DD");
      }
    }

    this.chartLineX = [...this.chartLineX];
  }

  loadChartPie() {
    Highcharts.chart("pie-chart", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        type: "pie",
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: `Tỉ lệ khảo sát: <b>{point.percentage:.1f}%</b>`,
      },
      series: [
        {
          type: "pie",
          allowPointSelect: true,
          keys: ["name", "y"],
          data: this.chartPieData,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      ],
    });
  }

  loadChartLine() {
    Highcharts.chart("line-chart", {
      chart: {
        type: "area",
      },
      title: {
        align: "left",
        text: "",
      },
      xAxis: {
        categories: this.chartLineX,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: `Số lượng khảo sát`,
          colorByPoint: true,
          data: this.chartLineData,
          type: undefined,
        },
      ],
    });
  }

  resetSearch() {
    this.query.time = []
    this.query.timeType = ""
    this.onChangeTime("MONTH");
  }

  getDetailReportOverview( timeType: string ) {
    let body = { ...this.query };
    let _from = this.query.time[0].split("/");
    let _to = this.query.time[1].split("/");
    body.from = [_from[1], _from[0], _from[2]].join("/");
    body.to = [_to[1], _to[0], _to[2]].join("/");
    if (timeType === "OTHER_WEEK") {
      body.timeType = "WEEK";
    }
    if (timeType === "OTHER_DAY") {
      body.timeType = "DAY";
    }
    // track("siteSearch", {
    //   payload: JSON.stringify(body)
    // })
    this.surveyService
      .detailReportOverview(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res && res.code === 1) {
            this.reportOverview = res.data ? { ...res.data } : {};
          } else {
            // track('error', {
            //   apiCall: `${window.location.origin}/survey/report/overview`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500
            // })
            this.notification.warning(
              "Có lỗi xảy ra",
              res && res.message ? res.massage : "Vui lòng thử lại"
            );
          }
        },
        (err) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/survey/report/overview`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.notification.warning(
            "Có lỗi xảy ra",
            err && err.message ? err.massage : "Vui lòng thử lại"
          );
        }
      );
  }

  getDashboardReportByTime(timeType: string) {
    let body = { ...this.query };
    let _from = this.query.time[0].split("/");
    let _to = this.query.time[1].split("/");
    body.from = [_from[1], _from[0], _from[2]].join("/");
    body.to = [_to[1], _to[0], _to[2]].join("/");
    if (timeType === "OTHER_WEEK") {
      body.timeType = "WEEK";
    }
    if (timeType === "OTHER_DAY") {
      body.timeType = "DAY";
    }
    // track("siteSearch", {
    //   payload: JSON.stringify(body)
    // })
    this.surveyService
      .dashboardReportByTime(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res && res.code === 1) {
          let items = res.data && res.data.items ? res.data.items : [];
          if (items && items.length) {


            this.chartLineData = this.chartLineX.map((gg: any) => {
              let findItem = items.find((hichic: any) => {
                const newTittle = `${hichic.title}h`;
                if(timeType === "OTHER_DAY" || timeType === "DAY") {
                  return newTittle === gg
                } else {
                  return hichic.title === gg
                }

              });
              if (findItem) {
                return {
                  y: findItem.value || 0,
                };
              } else {
                return {
                  y: 0,
                };
              }
            });
          } else {
            this.chartLineData = this.chartLineX.map((_) => ({ y: 0 }));
          }
          if(this.chartLineData && this.chartLineData.length) {
            setTimeout(() => {
              this.loadChartLine();
            }, 300);
          }
        }
      });
  }

  getDashboardReportByLocation(timeType: string) {
    let body = { ...this.query };
    let _from = this.query.time[0].split("/");
    let _to = this.query.time[1].split("/");
    body.from = [_from[1], _from[0], _from[2]].join("/");
    body.to = [_to[1], _to[0], _to[2]].join("/");
    if (timeType === "OTHER_WEEK") {
      body.timeType = "WEEK";
    }
    if (timeType === "OTHER_DAY") {
      body.timeType = "DAY";
    }
    // track("siteSearch", {
    //   payload: JSON.stringify(body)
    // })
    this.surveyService
      .dashboardReportByLocation(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if(res && res.code === 1) {
          this.chartPieData = res.data && res.data.items ? res.data.items.map((gg: any) => {
            return [gg.province || 'Chưa xác định', gg.value || 0]
          }) : []
        }
        if(this.chartPieData && this.chartPieData.length) {
          setTimeout(() => {
            this.loadChartPie();
          }, 300);
        }

      });
  }

  getDetailSurvey(id: string) {
    this.surveyService.detail(id).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if(res.code === 1) {
        this.detailSurvey = res.data ? {...res.data} : {}
        if(this.detailSurvey.startTime) {
          let now = this.detailSurvey.statusByTime === 1 ? moment(new Date()) : moment(this.detailSurvey.endTime, "DD/MM/YYYY hh:mm:ss")


          let start = moment(this.detailSurvey.startTime, "DD/MM/YYYY hh:mm:ss")
          let duration = moment.duration(now.diff(start))
          let _fromDay = Math.floor(duration.asDays()) ?  Math.floor(duration.asDays()) + 'ngày' : ""
          let _fromHour = duration.hours() ?  duration.hours() + 'giờ' : ""
          let _fromMinute = duration.minutes() ?  duration.minutes() + 'phút' : ""
          this.detailSurvey.fromNow = [_fromDay, _fromHour, _fromMinute].join(' ')
        }
      }
    })
  }

  onChangeFilterTime(e: any) {
    let timeType = "OTHER_WEEK";
    this.query.time[0] = new Date(e[0]).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    this.query.time[1] = new Date(e[1]).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    if (this.query.time[0] == this.query.time[1]) {
      timeType = "OTHER_DAY";
    }
    this.onChangeTime(timeType, this.query.time[0], this.query.time[1]);
    // this.searchDashboard(timeType);
  }
}
