import { StampService } from './../../../../../services/business/stamp/stamp.service';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-qrcode-ecommerce-preview',
  templateUrl: './qrcode-ecommerce-preview.component.html',
  styleUrls: ['./qrcode-ecommerce-preview.component.scss']
})
export class QrcodeEcommercePreviewComponent implements OnInit {


  @Input() templateData: Observable<any>
  unsubscribe$ = new Subject<void>();
  data: any = null
  listDay = [
    { value: 1, key: "Thứ 2" },
    { value: 2, key: "Thứ 3" },
    { value: 3, key: "Thứ 4" },
    { value: 4, key: "Thứ 5" },
    { value: 5, key: "Thứ 6" },
    { value: 6, key: "Thứ 7" },
    { value: 7, key: "Chủ nhật" },
  ]

  constructor(private _sanitizer: DomSanitizer,
    private stampService: StampService) { }

  ngOnInit() {
    this.getListBusinessType();
    this.templateData.subscribe((val: any) => {
      if (val) {
        let listWorkingDay = [];
        const businessName = this.listBusinessType.find(item => item.id === val.businessAreas);
        if (val.workingDay) {
          listWorkingDay = this.listDay.filter(item => val.workingDay.indexOf(item.value) !== -1);
        }

        let listButton = [];
        if (val.buttons) {
          listButton = val.buttons.filter(item => item.buttonName)
        }
        this.data = {
          ...val,
          buttons: listButton,
          businessName: businessName ? businessName.value : "",
          listWorkingDay: listWorkingDay,
        }
      }
    })
  }

  listBusinessType = []
  getListBusinessType() {
    this.stampService
      .getListBusinessType()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listBusinessType = res && res.data ? [...res.data] : [];
          }
        },
        (err: any) => {
        }
      );
  }
}
