import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  Input, OnDestroy,
} from '@angular/core';
import {
  UploadXHRArgs,
  UploadChangeParam,
  UploadFile,
  InputBoolean,
  InputNumber,
  NzMessageService,
  NzNotificationService,
} from 'ng-zorro-antd';
import { of, timer, Observable, Observer, from, Subject } from 'rxjs';
import { UploadService } from '../../services';
import {
  map,
  take,
  tap,
  switchMap,
  debounceTime,
  catchError,
  takeUntil,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth';
import { resolveSanitizationFn } from '@angular/compiler/src/render3/view/template';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'con-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit, OnDestroy {
  // baseUrl = environment.apiBaseUrl;
  baseUrl = 'https://upload.icheck.com.vn/upload/url';
  // baseUrl = "https://apis.icheck.vn/upload/image/v1/url";
  @Input() @InputNumber() maxSize = 0;
  @Input() fileType: string;
  @Input() fileExt: string;
  @Input() @InputBoolean() multiple = false;
  // tslint:disable-next-line:no-output-native
  @Output() change = new EventEmitter<UploadChangeParam>();
  @Output() done = new EventEmitter<void>();

  @Input() sizes: any[] = [];
  @Output() returnSize = new EventEmitter<any>();
  size: string;

  showUploadButton = true;
  uploadError: string;
  remoteUrlCtrl: FormControl = new FormControl(null, CustomValidators.url);
  errorUrl = false;
  unsubscribe$ = new Subject<void>();
  constructor(
    private notification: NzNotificationService,
    private upload: UploadService,
    private message: NzMessageService,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  token = '';

  loadFileAsText() {
    const fileToLoad = document.getElementById(
      'fileToLoad'
    ) as HTMLInputElement;
    // tslint:disable-next-line:no-unused-expression
    fileToLoad.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const textFromFileLoaded = fileReader.result;
      const inputElement = document.getElementById(
        'inputTextToSave'
      ) as HTMLInputElement;
      inputElement.value = textFromFileLoaded.toString();
      this.remoteUrlCtrl.setValue(textFromFileLoaded.toString());
    };
    fileReader.readAsText(fileToLoad.files[0], 'UTF-8');
  }
  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    const user = this.authService.currentUserValue;
    this.token = user.token;
    this.remoteUrlCtrl.statusChanges
      .pipe(
        debounceTime(300),
        switchMap((status) => {
          if (status === 'INVALID' || !this.remoteUrlCtrl.value) {
            return of(null);
          }
          this.errorUrl = false;
          const url = this.remoteUrlCtrl.value;
          if (url.indexOf('youtube.com') !== -1) {
            const newUrl = `https://www.youtube.com/embed/${url.split('=')[1]}`;

            const urlFile = {
              url: newUrl,
            };
            const file: any = {
              response: urlFile,
            };
            this.change.emit({
              file,
              fileList: [file],
              type: 'success',
            });

            return;
          }
          this.remoteUrlCtrl.disable({ emitEvent: false });

          const httpOptions = {
            headers: new HttpHeaders({
              Authorization: 'Bearer ' + this.token,
            }),
          };

          return this.http.post(`${this.baseUrl}`, { url }, httpOptions).pipe(
            catchError((err) => {
              this.remoteUrlCtrl.enable({ emitEvent: false });

              return of(null);
            })
          );
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            const data = res;
            if (!data.src) {
              data.src = data.link;
            }
            const url = {
              id: data.data.fileId,
              url: data.data.url,
            };
            const file = {
              uid: data.data.fileId,
              name: data.data.fileId,
              size: 0,
              type: 'upload',
              response: url,
            };
            this.change.emit({
              file,
              fileList: [file],
              type: 'success',
            });
            this.done.emit();
          } else {
            this.errorUrl = true;
          }
        },
        (error) => {
          this.errorUrl = true;
        }
      );
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  beforeUpload = (file: UploadFile) => {
    if (this.fileExt) {
      const promise = new Promise<UploadFile>((resolve, reject) => {
        if (!this.isImage(file.type)) {
          resolve(file);
        }
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        file.dimensions = {
          width,
          height,
        };
        resolve(file);
        img.onload = () => {

        };
      });

      return from(promise).pipe(
        map((file) => {
          if (this.fileType) {
            const type = file.type;
            if (!type || this.fileType.indexOf(type) < 0) {
              this.openMessage('error', 'Lỗi !!!', `Định dạng file không được hỗ trợ`);
              return false;
            }
          }

          if (this.fileExt) {
            const ext = file.name.split('.').pop().toLowerCase();
            if (!ext || this.fileExt.toLowerCase().indexOf(ext) < 0) {
              this.openMessage('error', 'Lỗi !!!', `Định dạng file không được hỗ trợ`);
              return false;
            }
          }

          if (file.size) {
            if (file.size > 10000000 && this.fileExt !== 'pdf' && this.fileExt !== 'mp4') {
              this.openMessage('error', 'Lỗi !!!', `Định dạng file vượt quá 5MB`);
              return false;
            }
          }

          if (this.sizes.length) {
            if (
              !(
                file.dimensions.width === this.sizes[0].width &&
                file.dimensions.height === this.sizes[0].height
              )
            ) {
              this.openMessage('error', 'Lỗi !!!', `Kích cỡ hình ảnh không phù hợp ( ${this.sizes[0].width} x ${this.sizes[0].height} )`);
              return false;
            }
          }
          return true;
        })
      );
    }
  }

  tusUpload = (item) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const a = [];
    if (item.file) {
      const fileType = item.file.type.split('/')[0];
      a.push(item.file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const formData = new FormData();
        // formData.append('my_field', 'file');
        // formData.append('my_file', item.file, item.file.name);
        formData.append('file', item.file);
        return this.upload.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (res) => {
            item.onSuccess!(
              {
                id: res.data.fileId,
                url: res.data.url,
                fileType,
                fileName: res.data.fileName
              },
              item.file!,
              null
            );
          },

          (err) => {
            this.openMessage('error', 'Lỗi !!!', `Có lỗi xảy ra.`);
          }
        );
      };
      reader.readAsArrayBuffer(a[0]);
    }
  }

  onChange($event: UploadChangeParam) {
    if ($event.fileList.filter((file) => file.status !== 'done').length > 0) {
      this.showUploadButton = false;
    } else {
      this.showUploadButton = true;
      this.done.emit();
    }
    this.change.emit($event);
  }

  protected isImage(type: string) {
    return type && type.split('/')[0] === 'image';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
