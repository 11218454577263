import { Component, OnInit } from "@angular/core";


@Component({
  selector: 'app-popup-guide',
  templateUrl: './popup-guide.component.html',
  styleUrls: ['./popup-guide.component.scss']
})
export class PopupGuideComponent implements OnInit {

  isVisible = false;
  listNote = [
    "Tạo 10+ loại QR Code",
    "Thiết kế QR Code của bạn",
    "Quản lý QR Code",
    "Thống kê lượt quét tem",
    "Tích hợp Loyalty, khảo sát cho QR Code",
  ]
  constructor() { }

  ngOnInit() {
  }

  showModal(type?: string, businessId?: string) {
    document.body.style.position = 'fixed'
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  call() {
    window.location.href = 'tel://0902195488';
  }

  
}
