import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/services';

@Component({
  selector: 'app-assign-checkin-preview',
  templateUrl: './assign-checkin-preview.component.html',
  styleUrls: ['./assign-checkin-preview.component.scss']
})
export class AssignCheckinPreviewComponent implements OnInit {
  @Input() storeDetail: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  targetDetail: any = null;
  listProduct: any = [];
  listRelatedTarget: any = [];

  constructor() {}

  ngOnInit() {
    this.storeDetail.subscribe((val: any) => {
      this.data = { ...val };
    });
  }

  numberWithCommas(x: any) {
    if (x) {
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    } else {
      return '';
    }
  }

}
