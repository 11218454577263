import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UploaderComponent} from '../../uploader';
import {takeUntil} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {DocumentsService} from 'src/app/services/business/documents/documents.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {capitalizeFirstLetter, removeAllSpacingBetweenWords} from '../../../helpers';

@Component({
  selector: 'app-document-update',
  templateUrl: './document-update.component.html',
  styleUrls: ['./document-update.component.scss']
})
export class DocumentUpdateComponent implements OnInit {

  isVisible = false;
  loading = false;
  id;
  notDocuments = false;
  validName = false;
  message = '';
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    document: this.fb.control([], [Validators.required]),
    date: [''],
  });
  documents: any[] = [];
  type;

  @Output() emitData = new EventEmitter();

  constructor(
    private  fb: FormBuilder,
    private modalService: NzModalService,
    private docService: DocumentsService,
    private notification: NzNotificationService,
  ) {
  }

  ngOnInit() {
    this.notDocuments = false;
    this.validName = false;
    this.message = '';
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  loadData() {
    this.docService.getDocumentById(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        if (res.code === 1) {
          this.isVisible = true;
          const data = res.data;
          this.form.get('name').setValue(data.title);
          data.value.map(item => {
            const value = {
              name: item.length >= 50 ? item.slice(0, 50) : item,
              url: item,
            };
            this.documents.push(value);
          });
          if (data.expireAt) {
            const expireAt = data.expireAt.split('-');
            // tslint:disable-next-line:variable-name
            const expireAt_convert = [expireAt[1], expireAt[0], expireAt[2]].join('-');
            this.form.get('date').setValue(expireAt_convert);
          }
          this.type = data.type;
        } else {
          this.notification.create(
            'error',
            'Lấy thông tin giấy tờ thất bại',
            res.message
          );
        }
      },
      error => {
        this.notification.create(
          'error',
          'Lấy thông tin giấy tờ thất bại',
          'Có lỗi xảy ra'
        );
      }
    );
  }

  getDate(date) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy + ' ' + '23:59:59';
  }

  show(id) {
    this.form.reset();
    this.documents = [];
    this.validName = false;
    this.message = '';
    this.id = id;
    this.loadData();
  }

  hide() {
    this.loading = false;
    this.isVisible = false;
    this.form.reset();
    this.documents = [];
    this.notDocuments = false;
  }

  update() {
    if (this.documents.length) {
      const documents$ = [];
      this.documents.map(item => {
        documents$.push(item.url);
      });
      if (documents$.length) {
        this.form.get('document').setValue(documents$);
      }
    }
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    const title$ = this.form.get('name').value;
    if (title$.trim().length === 0) {
      this.validName = true;
      this.message = 'Tên giấy tờ không hợp lệ';
      return;
    }
    if (title$.trim().length > 255) {
      this.validName = true;
      this.message = 'Tên giấy tờ không quá 255 ký tự';
      return;
    }
    this.loading = true;
    const listUrl$ = this.form.get('document').value;
    const expiredAt$ = this.form.get('date').value ? this.getDate(this.form.get('date').value) : '';
    const type$ = this.type;
    const body = {
      title: capitalizeFirstLetter(removeAllSpacingBetweenWords(title$)),
      listUrl: listUrl$,
      expiredAt: expiredAt$,
      type: type$
    };
    this.docService.updateDocument(this.id, body).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        if (res.code === 1) {
          this.notification.create(
            'success',
            'Thành công',
            'Cập nhật giấy tờ thành công'
          );
          this.hide();
          setTimeout(() => {
            this.emitData.emit(res.code);
          }, 1000);
        } else {
          this.loading = false;
          this.notification.create(
            'error',
            'Cập nhật giấy tờ thất bại',
            res.message
          );
        }
      },
      error => {
        this.loading = false;
        this.notification.create(
          'error',
          'Cập nhật giấy tờ thất bại',
          'Có lỗi xảy ra'
        );
      }
    );
  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp,pdf';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.type = changeParam.file.type;
          this.notDocuments = false;
          this.documents.push(data);
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(changeParam => {
        modal.close();
      });
    });
  }

  delete(file, index) {
    this.documents.splice(index, 1);
    if (!this.documents.length) {
      this.notDocuments = true;
    }
    // if (this.form.get('document').value !== null && this.form.get('document').value.includes(file)) {
    //   this.form.get('document').value.splice(this.form.get('document').value.indexOf(file), 1);
    //   this.files.splice(index, 1);
    // }
  }
}
