import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { AgmCoreModule } from '@agm/core';

import { NotFoundComponent } from './not-found/not-found.component';
import { FileListComponent } from './file-list';
import { UploaderComponent } from './uploader';
import { InfoBusinessComponent } from './account/info-business/info-business.component';
import { InfoUserComponent } from './account/info-user/info-user.component';
import {
  AddDistributorsComponent,
  CreateDistributorsComponent
} from './distributors';
import { CategoryComponent } from './category/category.component';
import {
  DocumentAddComponent,
  DocumentUpdateComponent,
  DocumentDeleteComponent,
  DocumentSelectComponent
} from './documents';
import {
  AddStoresComponent,
  CreateStoresComponent,
  VerifyStoresComponent,
  BlockStoresComponent,
  FormStoresComponent,
  AssignStoresComponent
} from './stores';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { ListProductComponent } from './list-product/list-product.component';
import { TranferItemComponent } from './tranfer-item/tranfer-item.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import {ContactPopupComponent} from './contact-popup/contact-popup.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { ImageViewComponent } from './image-view/image-view.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxBarcodeModule } from 'ngx-barcode';
import { SafeHtmlPipe } from '../business/stamp/safe-html.pipe';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { TemplateOneComponent } from './preview-template/product/template-one/template-one.component';
import { TemplateTwoComponent } from './preview-template/product/template-two/template-two.component';
import { TemplateThreeComponent } from './preview-template/product/template-three/template-three.component';
import { TemplateFiveComponent } from './preview-template/product/template-five/template-five.component';
import { TemplateSixComponent } from './preview-template/product/template-six/template-six.component';
import { TemplateFourComponent } from './preview-template/store/template-four/template-four.component';
import { TemplateOneV2Component } from './preview-template-v2/store/template-one-v2/template-one-v2.component';
import { TemplateTwoV2Component } from './preview-template-v2/store/template-two-v2/template-two-v2.component';
import { TemplateNineV1Component } from './preview-template-v2/store/template-nine-v1/template-nine-v1.component';
import { TemplateThreeV2Component } from './preview-template-v2/store/template-three-v2/template-three-v2.component';
import { TemplateFourV2Component } from './preview-template-v2/store/template-four-v2/template-four-v2.component';
import { TemplateFiveV2Component } from './preview-template-v2/store/template-five-v2/template-five-v2.component';
import { TemplateSixV2Component } from './preview-template-v2/store/template-six-v2/template-six-v2.component';
import { TemplateSevenV2Component } from './preview-template-v2/store/template-seven-v2/template-seven-v2.component';
import { TypeImageModalComponent } from '../business/stamp/type-image-modal/type-image-modal.component';
import { NzEmptyCustomizeComponent } from './nz-empty-customize/nz-empty-customize.component';
import { QrCodeScanComponent } from './qr-code-scan/qr-code-scan.component';
import { TemplateEightComponent } from './preview-template/store/template-eight/template-eight.component';
import { NoContentProductComponent } from './preview-template/product/no-content-product/no-content-product.component';
import { NoContentStoreComponent } from './preview-template/store/no-content-store/no-content-store.component';
import { NoContentSurveyComponent } from './preview-template/survey/no-content-survey/no-content-survey.component';
import { QrcodeDesignComponent } from './qrcode-design/qrcode-design.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import {UserExportShareComponent} from './user-export-share/user-export-share.component';
import {ListComponentComponent} from './list-component/list-component.component';
import {WarningPermissionComponent} from './warning-permission/warning-permission.component';
import {ExportFileComponent} from './export-file/export-file.component';
import {DeletePopupLoyaltyComponent} from './core/delete-popup/delete-popup.component';
import {GiftAddComponent} from './gift-add/gift-add.component';
import {ImageComponent} from './core/image/image.component';
import {NgxSummernoteModule} from 'ngx-summernote';
import { DragDropDirective } from './directive/drag-drop.directive';
import { WarningLeaveComponent } from './warning-leave/warning-leave.component';
import {UploadImgComponent} from './upload-img/upload-img.component';
import {UIModalComponent} from './modal/modal.component';
import {LimitDirectiveDirective} from './directive/limit-directive.directive';
import {UploadImgButtonComponent} from './upload-img-button/upload-img-button.component';
import { BusinessesButtonPromotionComponent } from './preview-template-v2/store/component/businesses-button-promotion/businesses-button-promotion.component';
import { BusinessExtraInfoComponent } from './preview-template-v2/store/component/business-extra-info/business-extra-info.component';
import { TemplateTwoEnV2Component } from './preview-template-v2/store/template-two-en-v2/template-two-en-v2.component';
import { TemplateTwentyV2Component } from './preview-template-v2/store/template-twenty-v2/template-twenty-v2.component';
import { BusinessesFeatureProductComponent } from './preview-template-v2/store/component/businesses-feature-product/businesses-feature-product.component';
import { BusinessesSliderShowComponent } from './preview-template-v2/store/component/businesses-slider-show/businesses-slider-show.component';
import {NgxSummernoteCustomModule} from '../lib/ngx-summernote-custom.module';
import {IvyCarouselModule} from './carousel/carousel.module';

@NgModule({
  declarations: [
    FileListComponent,
    UploaderComponent,
    NotFoundComponent,
    InfoBusinessComponent,
    InfoUserComponent,
    CreateDistributorsComponent,
    AddDistributorsComponent,
    CategoryComponent,
    DocumentAddComponent,
    DocumentUpdateComponent,
    AddStoresComponent,
    CreateStoresComponent,
    VerifyStoresComponent,
    BlockStoresComponent,
    DeletePopupComponent,
    ContactPopupComponent,
    DocumentDeleteComponent,
    ListProductComponent,
    DocumentSelectComponent,
    TranferItemComponent,
    ChangePasswordComponent,
    FormStoresComponent,
    AssignStoresComponent,
    FilterBarComponent,
    ImageViewComponent,
    SafeHtmlPipe,
    ImageModalComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    TemplateFiveComponent,
    TemplateSixComponent,
    TemplateFourComponent,
    TypeImageModalComponent,
    NzEmptyCustomizeComponent,
    QrCodeScanComponent,
    TemplateEightComponent,
    NoContentProductComponent,
    NoContentStoreComponent,
    NoContentSurveyComponent,
    QrcodeDesignComponent,
    TemplateOneV2Component,
    TemplateTwoV2Component,
    TemplateThreeV2Component,
    TemplateFourV2Component,
    TemplateFiveV2Component,
    TemplateSixV2Component,
    TemplateSevenV2Component,
    TemplateNineV1Component,
    AddCategoryComponent,
    UserExportShareComponent,
    ListComponentComponent,
    WarningPermissionComponent,
    ExportFileComponent,
    DeletePopupLoyaltyComponent,
    GiftAddComponent,
    ImageComponent,
    DragDropDirective,
    WarningLeaveComponent,
    UploadImgComponent,
    UIModalComponent,
    LimitDirectiveDirective,
    UploadImgButtonComponent,
    BusinessesButtonPromotionComponent,
    BusinessExtraInfoComponent,
    TemplateTwoEnV2Component,
    TemplateTwentyV2Component,
    BusinessesFeatureProductComponent,
    BusinessesSliderShowComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgZorroAntdModule,
    NgxBarcodeModule,
    NgxQRCodeModule,
    //map
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBaNv-5HXYcq1DarkW1pmyP6wcYYZFJRdM',
      libraries: ['places']
    }),
    NgxSummernoteCustomModule,
    IvyCarouselModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    UploaderComponent,
    AddCategoryComponent,
    GiftAddComponent,
    ExportFileComponent,
    WarningLeaveComponent,
    UIModalComponent
  ],
  exports: [
    FileListComponent,
    UploaderComponent,
    InfoUserComponent,
    CreateDistributorsComponent,
    AddDistributorsComponent,
    CategoryComponent,
    DocumentAddComponent,
    DocumentUpdateComponent,
    DocumentDeleteComponent,
    AddStoresComponent,
    CreateStoresComponent,
    VerifyStoresComponent,
    BlockStoresComponent,
    DeletePopupComponent,
    ContactPopupComponent,
    ListProductComponent,
    DocumentSelectComponent,
    TranferItemComponent,
    ChangePasswordComponent,
    FormStoresComponent,
    AssignStoresComponent,
    ImageViewComponent,
    ImageModalComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    TemplateFiveComponent,
    TemplateSixComponent,
    TemplateFourComponent,
    TypeImageModalComponent,
    QrCodeScanComponent,
    TemplateEightComponent,
    NoContentProductComponent,
    NoContentStoreComponent,
    NoContentSurveyComponent,
    QrcodeDesignComponent,
    TemplateOneV2Component,
    TemplateTwoV2Component,
    TemplateThreeV2Component,
    TemplateFourV2Component,
    TemplateFiveV2Component,
    TemplateSixV2Component,
    TemplateSevenV2Component,
    TemplateNineV1Component,
    AddCategoryComponent,
    UserExportShareComponent,
    DeletePopupLoyaltyComponent,
    GiftAddComponent,
    DragDropDirective,
    UploadImgComponent,
    UIModalComponent,
    LimitDirectiveDirective,
    UploadImgButtonComponent,
    TemplateTwoEnV2Component,
    TemplateTwentyV2Component
  ]
})
export class SharedModule { }
