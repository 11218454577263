import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UploaderComponent} from 'src/app/shared/uploader';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {ProductsService, SettingMessageService} from 'src/app/services';
import {configCommon, removeEmptyQuery} from 'src/app/helpers';

@Component({
  selector: 'app-setting-create-message',
  templateUrl: './setting-create-message.component.html',
  styleUrls: ['./setting-create-message.component.scss']
})
export class SettingCreateMessageComponent implements OnInit, OnDestroy {

  constructor(
    private modal: NzModalService,
    private notification: NzNotificationService,
    private fb: FormBuilder,
    private productService: ProductsService,
    private settingMessage: SettingMessageService,
  ) {
  }

  unsubscribe$ = new Subject<void>();

  // isNotify: Boolean = false;
  // isPopup: Boolean = false;

  isVisible = false;
  // tslint:disable-next-line:ban-types
  isShow: Boolean = false;
  check = 'product';
  type: string;
  messageId: string;
  productId: string;
  radioValue: any = '1';
  radioPopup = '1';

  form: FormGroup = this.fb.group({
    imageId: [null],
    notificationType: [null],
    content: [null, [Validators.required]],
    productAttId: [null],
    customerGroups: [null, [Validators.required]]
  });
  formBanner: FormGroup = this.fb.group({
    imageId: [null],
    notificationType: [null],
    content: [null],
    productAttId: [null],
    customerGroups: [null, [Validators.required]],
  });

  formHTML: FormGroup = this.fb.group({
    imageId: [null],
    notificationType: [null],
    content: [null, [Validators.required]],
    productAttId: [null],
    customerGroups: [null, [Validators.required]],
  });
  page = 1;
  pageSize = 10;
  delayTimer: any;
  listOfAllDataCustomer: any[] = [];

  image = [];
  content: any[] = [];

  listOfAllData: any[] = [];
  notificationType;
  popupType;
  @Output() createMessage = new EventEmitter<any>();
  @Output() updateMessage = new EventEmitter<any>();
  // tslint:disable-next-line:ban-types
  isLoadingBtn: Boolean = false;
  // tslint:disable-next-line:ban-types
  // isRequired: Boolean;
  public config = configCommon;

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  showModal(type: any, messageId?: any, productId?: string) {
    this.isVisible = true;
    this.image = [];
    this.messageId = null;
    this.productId = null;
    // this.isNotify = false;
    // this.isPopup = false;
    this.form.reset();
    this.formBanner.reset();
    this.formHTML.reset();
    this.type = type;
    this.loadCustomer(null);
    this.loadProduct(null);
    this.productId = productId;
    if (messageId && messageId !== 0) {
      this.messageId = messageId;
      this.settingMessage.getById(messageId).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {



          if (res.data) {
            this.notificationType = res.data.notificationType[0];
            this.popupType = res.data.popupType;
            if (this.notificationType === 3 && this.popupType === 1) {
              this.radioValue = '3';
              this.radioPopup = '1';
              this.formBanner.get('notificationType').setValue(res.data.notificationType);

              if (res.data.customerGroups) {
                let arr: any[] = [];
                arr = res.data.customerGroups.map(data => {
                  return data.customerGroupId;
                });
                this.formBanner.get('customerGroups').setValue(arr);
              }
              this.content = [{url: res.data.contentUrl}];
              this.formBanner.get('content').setValue(res.data.content);


              if (res.data.productAtt) {
                this.formBanner.get('productAttId').setValue(res.data.productAtt.productAttId);
              }
            }

            if (this.notificationType === 3 && this.popupType === 2) {
              this.radioValue = '3';
              this.radioPopup = '2';
              this.formHTML.get('notificationType').setValue(res.data.notificationType);

              if (res.data.customerGroups) {
                let arr: any[] = [];
                arr = res.data.customerGroups.map(data => {
                  return data.customerGroupId;
                });
                this.formHTML.get('customerGroups').setValue(arr);
              }
              this.formHTML.get('content').setValue(res.data.content);
              if (res.data.productAtt) {
                this.formHTML.get('productAttId').setValue(res.data.productAtt.productAttId);
              }
            }

            if (this.notificationType === 1) {
              if (res.data.image) {
                this.image = [{url: res.data.image}];
                this.form.get('imageId').setValue(res.data.imageId);
              }
              this.radioValue = '1';
              this.form.get('notificationType').setValue(res.data.notificationType);

              if (res.data.customerGroups) {
                let arr: any[] = [];
                arr = res.data.customerGroups.map(data => {
                  return data.customerGroupId;
                });
                this.form.get('customerGroups').setValue(arr);
              }
              this.form.get('content').setValue(res.data.content);
              if (res.data.productAtt) {
                this.form.get('productAttId').setValue(res.data.productAtt.productAttId);
              }
            }


          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
        }
      );
    }
  }

  handleCancel() {
    this.isVisible = false;

  }

  handleCancelBanner() {
    this.isVisible = false;
    this.content = [];

  }

  // setNotification(data: any) {
  //   const newData = data.map(data2 => {
  //     if (typeof data2 === 'string') {
  //       // tslint:disable-next-line:radix
  //       return parseInt(data2);
  //     }
  //   });
  //   this.form.get('notificationType').setValue(newData);
  //   // if (data.length > 0) {
  //   //   this.isRequired = false;
  //   // } else {
  //   //   this.isRequired = true;
  //   // }
  // }

  ngOnInit() {
  }

  loadCustomer(data: any) {
    const query: any = {
      keyword: data ? data.trim() : null,
      page: this.page,
      page_size: this.pageSize,
    };

    removeEmptyQuery(query);

    this.settingMessage.getAllCustomer(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.listOfAllDataCustomer = res.data.items;
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        return;
      },
    );
  }

  searchCustomer(data: any) {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadCustomer(data);
    }, 300);
  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modal.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          if (field === 'image') {
            this.form.get('imageId').setValue(changeParam.file.response.id);
            this.image = [{url: changeParam.file.response.url}];
          } else if (field === 'content') {
            this.formBanner.get('content').setValue(changeParam.file.response.id);
            this.content = [{url: changeParam.file.response.url}];
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        modal.close();
      });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'image') {
      this.image = [];
      this.form.get('imageId').setValue(null);
    } else if (field === 'content') {
      this.content = [];
      this.formBanner.get('content').setValue(null);
    }
  }

  loadProduct(data: any) {
    const query: any = {
      product_name: data ? data.trim() : null,
      page_size: 50
    };

    removeEmptyQuery(query);

    this.productService.getAllProductManaged(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.listOfAllData = res.data.items;
      }
    );

    this.isShow = false;
  }

  searchProductAttr(data: any) {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadProduct(data);
    }, 300);
  }

  unSelectProduct() {
    this.form.get('productId').setValue(null);
    this.listOfAllData = [];
    this.isShow = false;
  }

  onSubmit() {
    // tslint:disable-next-line:radix
    this.form.get('notificationType').setValue([parseInt(this.radioValue)]);
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    // if (!this.form.value.notificationType || this.form.value.notificationType.length == 0) {
    //   this.isRequired = true;
    // }

    // if (this.form.invalid || this.isRequired == true) {
    //   return;
    // }
    if (this.form.invalid) {
      return;
    }

    this.isLoadingBtn = true;

    if (this.type === 'business') {
      this.form.addControl('type', this.fb.control(1));
      this.createMessage.emit(this.form.value);
      this.form.removeControl('type');
      return;
    }
    if (this.type === 'businessUpdate') {
      this.form.addControl('type', this.fb.control(1));
      const body: any = {
        messageId: this.messageId,
        form: this.form.value,
      };
      this.updateMessage.emit(body);
      this.form.removeControl('type');
      return;
    }
    if (this.type === 'product') {
      this.form.addControl('type', this.fb.control(0));
      this.form.addControl('productId', this.fb.control(this.productId));
      this.createMessage.emit(this.form.value);
      this.form.removeControl('type');
      this.form.removeControl('productId');
      return;
    }
    if (this.type === 'productUpdate') {
      this.form.addControl('type', this.fb.control(0));
      this.form.addControl('productId', this.fb.control(this.productId));
      const body: any = {
        messageId: this.messageId,
        form: this.form.value,
      };
      this.updateMessage.emit(body);
      this.form.removeControl('type');
      this.form.removeControl('productId');
      return;
    }
  }

  onSubmitBanner() {
    // tslint:disable-next-line:radix
    this.formBanner.get('notificationType').setValue([parseInt(this.radioValue)]);
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.formBanner);

    if (this.formBanner.invalid) {
      return;
    }
    if (this.formBanner.get('content').value === null) {
      this.notification.create(
        'warning',
        'Cảnh báo',
        'Bạn chưa chọn Banner'
      );
      return;

    }
    this.isLoadingBtn = true;
    if (this.type === 'product') {
      this.formBanner.addControl('type', this.fb.control(0));
      this.formBanner.addControl('popupType', this.fb.control(1));
      this.formBanner.addControl('productId', this.fb.control(this.productId));
      this.createMessage.emit(this.formBanner.value);
      this.formBanner.removeControl('type');
      this.formBanner.removeControl('popupType');
      this.formBanner.removeControl('productId');
      return;
    }
    if (this.type === 'productUpdate') {
      this.formBanner.addControl('type', this.fb.control(0));
      this.formBanner.addControl('popupType', this.fb.control(1));
      this.formBanner.addControl('productId', this.fb.control(this.productId));
      const body: any = {
        messageId: this.messageId,
        form: this.formBanner.value,
      };
      this.updateMessage.emit(body);
      this.formBanner.removeControl('type');
      this.formBanner.removeControl('popupType');
      this.formBanner.removeControl('productId');
      return;
    }
    if (this.type === 'business') {
      this.formBanner.addControl('type', this.fb.control(1));
      this.formBanner.addControl('popupType', this.fb.control(1));

      this.createMessage.emit(this.formBanner.value);
      this.formBanner.removeControl('popupType');

      this.formBanner.removeControl('type');
      return;
    }
    if (this.type === 'businessUpdate') {
      this.formBanner.addControl('type', this.fb.control(1));
      this.formBanner.addControl('popupType', this.fb.control(1));

      const body: any = {
        messageId: this.messageId,
        form: this.formBanner.value,
      };
      this.updateMessage.emit(body);
      this.formBanner.removeControl('popupType');
      this.formBanner.removeControl('type');
      return;
    }
  }

  onSubmitHTML() {

    // tslint:disable-next-line:radix
    this.formHTML.get('notificationType').setValue([parseInt(this.radioValue)]);
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.formHTML);

    if (this.formHTML.invalid) {
      return;
    }
    this.isLoadingBtn = true;
    if (this.type === 'product') {
      this.formHTML.addControl('type', this.fb.control(0));
      this.formHTML.addControl('popupType', this.fb.control(2));

      this.formHTML.addControl('productId', this.fb.control(this.productId));

      this.createMessage.emit(this.formHTML.value);
      this.formHTML.removeControl('type');
      this.formHTML.removeControl('popupType');
      this.formHTML.removeControl('productId');
      return;
    }
    if (this.type === 'productUpdate') {
      this.formHTML.addControl('type', this.fb.control(0));
      this.formHTML.addControl('popupType', this.fb.control(2));
      this.formHTML.addControl('productId', this.fb.control(this.productId));
      const body: any = {
        messageId: this.messageId,
        form: this.formHTML.value,
      };
      this.updateMessage.emit(body);
      this.formHTML.removeControl('type');
      this.formHTML.removeControl('popupType');
      this.formHTML.removeControl('productId');
      return;
    }
    if (this.type === 'business') {
      this.formHTML.addControl('type', this.fb.control(1));
      this.formHTML.addControl('popupType', this.fb.control(2));

      this.createMessage.emit(this.formHTML.value);
      this.formHTML.removeControl('popupType');

      this.formHTML.removeControl('type');
      return;
    }
    if (this.type === 'businessUpdate') {
      this.formHTML.addControl('type', this.fb.control(1));
      this.formHTML.addControl('popupType', this.fb.control(2));

      const body: any = {
        messageId: this.messageId,
        form: this.formHTML.value,
      };
      this.updateMessage.emit(body);
      this.formHTML.removeControl('popupType');
      this.formHTML.removeControl('type');
      return;
    }
  }

  success() {
    this.isLoadingBtn = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
