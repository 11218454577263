import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd';
import { UploaderComponent } from 'src/app/shared/uploader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-review-hidden',
  templateUrl: './product-review-hidden.component.html',
  styleUrls: ['./product-review-hidden.component.scss']
})
export class ProductReviewHiddenComponent implements OnInit, OnDestroy {

  isVisible = false;
  reviewId: any;
  isHidden: Boolean;
  showModal(reviewId, isHidden) {
    this.isVisible = true;
    this.isShowNote = false;
    this.reviewId = reviewId;
    this.isHidden = isHidden;
    this.setNull();
  }

  handleCancel() {
    this.isVisible = false;
  }

  unsubscribe$ = new Subject<void>();
  current = 0;
  pageIndex = 1;
  pageSize = 5;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
  ) { }

  form = this.fb.group({
    note: [null],
    description: [null, [Validators.required]],
  })

  ngOnInit() {
  }

  reasons: any[] = [
    { "id": 1, "value": "Nội dung phản cảm" },
    { "id": 2, "value": "Có khuynh hướng bạo lực" },
    { "id": 3, "value": "Spam" },
    { "id": 4, "value": "Lý do khác" },
  ];

  isShowNote: Boolean = false;
  selectReason(data: any) {
    if (data === 'Lý do khác') {
      this.isShowNote = true;
      this.form.get('note').setValidators([Validators.required]);
    } else {
      this.isShowNote = false;
      this.form.get('note').setValidators(null);
    }
  }

  proof_images = [];
  isShow: Boolean = false;
  openUploadDialog(field: string) {
    let fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          if (field === 'proof_images') {
            this.proof_images.push({
              id: changeParam.file.response.id,
              name: changeParam.file.name,
              type: changeParam.file.type,
              url: changeParam.file.response.url,
            });
            this.form.get('proof_images').setValue(this.proof_images.map(proof_image => proof_image.id));
            this.form.get(field).updateValueAndValidity();
            if (this.proof_images.length > 5) {
              this.isShow = true;
            }
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        modal.close();
      });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'proof_images') {
      this.proof_images.splice(index, 1);
      this.form.get('proof_images').setValue(this.proof_images.map(proof_image => proof_image.id));
      this.form.get(field).updateValueAndValidity();
    }
  }

  @Output() hiddenReview = new EventEmitter<any>();
  createSuccess: Boolean = true;
  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }

    if (this.createSuccess == true) {
      this.createSuccess == false;
      if (this.form.value.note) {
        let body: any = {
          review_id: this.reviewId,
          is_hidden: this.isHidden,
          description: this.form.value.note,
        }
        this.hiddenReview.emit(body);
      } else {
        let body: any = {
          review_id: this.reviewId,
          is_hidden: this.isHidden,
          description: this.form.value.description,
        }
        this.hiddenReview.emit(body);
      }
    }
  }

  setNull() {
    this.form.get('note').setValue(null);
    this.form.get('description').setValue(null);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}