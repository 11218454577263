import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  HostBinding,
} from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "con-file-list",
  templateUrl: "./file-list.component.html",
  styleUrls: ["./file-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileListComponent implements OnInit {
  @HostBinding("class") class = "con-file-list";

  @Input() files: any[] = [];
  @Input() listType: string = "image";
  @Input() multiple: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() size: string = "100px"
  @Input() disable: boolean = false;
  @Input() borderRadius: string = "10px"
  @Output() addFile = new EventEmitter<void>();
  @Output() removeFile = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges() {
  }

  onEmitAddFile() {
    if(this.disable) {
      return;
    }
    this.addFile.emit()
  }
}
