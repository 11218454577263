import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({providedIn: 'root'})
export class ProductRelatedsService extends BaseService {
  getType(productId?: any): Observable<any> {
    return this.get<any>(`/product/related?product_id=${productId}`);
  }
  // tslint:disable-next-line:variable-name
  selectType(related_type?: number, product_id?: any ): Observable<any> {
    return this.put<any>(`/product/related?related_type=${related_type}&product_id=${product_id}`);
  }
  listProduct(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/product/related', httpOptions);
  }
  // tslint:disable-next-line:variable-name
  addProductRelated(product_id?: any , body?: any) {
    return this.post<any>(`/product/related?product_id=${product_id}`, body);
  }
  // tslint:disable-next-line:variable-name
  deleteProductRelated(product_id?: any , body?: any) {
    return this.delete<any>(`/product/related?product_id=${product_id}`, body);
  }
}
