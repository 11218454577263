import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appLimitDirective]'
})
export class LimitDirectiveDirective {
  @Input() decimal = 2;
  private regex: RegExp = new RegExp(/^\d*\.?\d$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'a', 'c', 'v', 'Ctrl', 'x'];
  private el1: NgControl;
  constructor(private el: ElementRef, private ngControl: NgControl) {
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Use NgControl patchValue to prevent the issue on validation
    // this.el1.control.patchValue(value.replace(/[^0-9]/g, ''));
    const valueNumber = value.replace(/[^0-9\.]/g, '');
    this.ngControl.control.patchValue(valueNumber.length ? valueNumber : null);
    // this.el.nativeElement.value = valueNumber?.length ? valueNumber : null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let regex;
    if (Number(this.decimal) === 0) {
      regex = '/^\\d*\\.?\\d$/g';
    } else {
      regex =
          '^\\s*((\\d+(\\.\\d{0,' +
          this.decimal +
          '})?)|((\\d*(\\.\\d{1,' +
          this.decimal +
          '}))))\\s*$';
    }
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(Number(this.decimal) === 0 ? this.regex : regex)) {
      event.preventDefault();
    }
  }
}
