import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd';
import {ProductsService, ProductRelatedsService} from '../../../../../services';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-setting-create-related',
  templateUrl: './setting-create-related.component.html',
  styleUrls: ['./setting-create-related.component.scss']
})
export class SettingCreateRelatedComponent implements OnInit, OnDestroy {
  productId;

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private  productsService: ProductsService,
    private productRelatedsService: ProductRelatedsService,
    private notification: NzNotificationService,
  ) {
    const optionList$: Observable<string> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500));
    optionList$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.isVisible) {
        const queryProduct = {
          product_name: value.trim(),
          page: 1,
          page_size: 50
        };
        this.isLoading = true;
        this.products = [];
        this.productsService.getProductRelated(queryProduct).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (res) => {
            this.isLoading = false;
            if (res.code === 1) {
              const data = res.data.items;
              if (data != null) {
                data.map(item => {
                  if (item.id !== this.productId) {
                    const product = {
                      id: undefined,
                      name: undefined
                    };
                    if (item.name) {
                      product.id = item.id;
                      product.name = item.name + ' ' + '(' + item.barCode + ')';
                    } else {
                      product.id = item.id;
                      product.name = '(' + item.barCode + ')';
                    }
                    this.products.push(product);
                  }
                });
              }
              return;
            }
            this.products = [];
          },
          (error) => {
            this.isLoading = false;
            this.products = [];
          }
        );
      }
    });
  }
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();
  loading = false;
  unsubscribe$ = new Subject<void>();
  products = [];
  searchChange$ = new BehaviorSubject('');
  isLoading = false;
  isVisible = false;


  form = this.fb.group({
    product: [[], [Validators.required]]
  });

  showModal(productId?: any) {
    this.productId = productId;
    this.form.reset();
    this.isVisible = true;
    this.searchChange$.next('');
  }

  handleCancel() {
    this.isVisible = false;
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  searchProduct(event) {
    this.searchChange$.next(event);
  }

  onSubmit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    const ids = this.form.controls.product.value;
    this.productRelatedsService.addProductRelated(this.productId, ids).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.loading = false;
        if (res.code === 1) {
          this.notification.success(
            'Thành công',
            'Thêm mối sản phẩm liên quan thành công'
          );
          this.emitData.emit(res.code);
        } else {
          if (res.message === 'USER PERMISSION_DENIED WITH PRODUCT') {
            this.notification.error(
              'Thêm mới sản phẩm liên quan thất bại',
              'Bạn chưa quản lý sản phẩm này'
            );
            return;
          }
          this.notification.error(
            'Thêm mới sản phẩm liên quan thất bại',
            res.message
          );
        }
      },
      error => {
        this.loading = false;
        this.notification.error(
          'Thêm mới sản phẩm liên quan thất bại',
          'Hệ thống đang nâng cấp.Vui lòng thử lại sau'
        );
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
