import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({providedIn: 'root'})
export class BusinessCodeService extends BaseService {
  list(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/enterprise/gln', httpOptions);
  }
  create(body?: any) {
    return this.post<any>('/enterprise/gln', body);
  }
  remove(body?: any) {
    return this.delete<any>('/enterprise/gln', body);
  }
  one(id) {
    return this.get<any>(`/enterprise/gln/${id}`);
  }
  update(id, body?: any) {
    return this.put(`/enterprise/gln/${id}`, body);
  }
  exportGLN(query?: any) {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>('/enterprise/gln/export', httpOptions);
  }
  getGlnOfChat(query?: any) {
    const httpParamsOptions = {
      params: query
    };
    return this.get<any>('/user/chat/gln-info', httpParamsOptions);
  }
}
