import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScanHistoryRoutingModule } from './scan-history-routing.module';
import { ScanHistoryComponent } from './scan-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { SharedModule } from 'src/app/shared';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxSummernoteModule } from 'ngx-summernote';
import {NgxSummernoteCustomModule} from '../../lib/ngx-summernote-custom.module';


@NgModule({
  declarations: [ScanHistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    SharedModule,
    NgxBarcodeModule,
    NgxSummernoteCustomModule,
    ScanHistoryRoutingModule
  ]
})
export class ScanHistoryModule { }
