import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BusinessComponent } from './business.component';
import { BusinessRoutingModule } from './business-routing.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { SharedModule } from 'src/app/shared';
import { BusinesstDirective } from 'src/app/business/business.directive';
import { RedirectFreeComponent } from './redirect-free/redirect-free.component';

import {
  BusinessInfoModule,
  DashboardModule,
  ProductsModule,
  CompaniesModule,
  StampModule,
  UserModule,
  ScanHistoryModule,
  CheckinHistoryModule
} from './';

// @ts-ignore
@NgModule({
  declarations: [BusinessComponent, BusinesstDirective, RedirectFreeComponent],
  imports: [
    CommonModule,
    BusinessRoutingModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    BusinessInfoModule,
    StampModule,
    UserModule,
    ScanHistoryModule,
    DashboardModule,
    ProductsModule,
    CheckinHistoryModule,
    NzMenuModule,
    NzModalModule,
    NzCardModule,
    NzUploadModule,
    ReactiveFormsModule,
    SharedModule,
    CompaniesModule,
  ],
})
export class BusinessModule {}
