import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {NzModalService} from 'ng-zorro-antd';
import {UploaderComponent} from '../uploader';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {StampV2Service, UploadService} from '../../services';
import {DomSanitizer} from '@angular/platform-browser';
import {randomStringWithLength} from '../../helpers';
import {environment} from '../../../environments/environment';
import {StampService} from '../../services/business/stamp/stamp.service';

export enum EQrcodeShape {
  circle = 'circle',
  square = 'square',
}

export enum EQrcodeDesignWay {
  existed = 'existed',
  bySelf = 'bySelf',
}

@Component({
  selector: 'app-qrcode-design',
  templateUrl: './qrcode-design.component.html',
  styleUrls: ['./qrcode-design.component.scss']
})

export class QrcodeDesignComponent implements OnInit {
  constructor(
    private modalService: NzModalService,
    private stampV2Service: StampV2Service,
    private stampService: StampService,
    private uploadService: UploadService,
    private domSanitizer: DomSanitizer
  ) {
  }
  isShowLogo = true;
  isShowSample = true;

  @Output() changeQrcodeConfigPreview = new EventEmitter();
  @Output() changeLoadingDrawQrcode = new EventEmitter();
  @Input() stampCode = '';
  isUpdate = false;
  unsubscribe$ = new Subject<void>();
  activeCollapse = true;
  collapseStyle = {
    background: '#fff',
    borderRadius: '0px',
    marginBottom: '20px',
  };
  eQrcodeShape = EQrcodeShape;
  eQrcodeDesignWay = EQrcodeDesignWay;
  // chuyển mặc định từ tròn sang vuông
  // selectedQrcodeShape: EQrcodeShape = EQrcodeShape.circle;
  selectedQrcodeShape: EQrcodeShape = EQrcodeShape.square;
  // chuyển mặc định từ từ mẫu có sẵn sang tự thiết kế
  // selectedQrcodeDesignWay: EQrcodeDesignWay = EQrcodeDesignWay.existed;
  selectedQrcodeDesignWay: EQrcodeDesignWay = EQrcodeDesignWay.bySelf;
  selectedQrcodeDesignExistId = null;
  selectedQrcodeFrame = null;
  timer = null;
  qrcodeData = environment.redirectScanUrl;
  // qrcodeData = environment.redirectScanUrlVietThai;
  // qrcodeData = environment.redirectScanUrlVietThai;
  qrcodeCode = '';
  qrcodeDesignConfig = {
    width: 304,
    height: 304,
    type: 'svg',
    data: this.qrcodeData,
    dotsOptions: {
      color: '#4267b2',
      type: 'classy'
    },
    backgroundOptions: {
      color: '#fff',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 0,
      imageSize: 0.45,
    },
  };
  listQrcodeDesignExist = [];
  listQrcodeDesignExistCircle = [];
  listQrcodeDesignExistSquare = [];
  listQrcodeDesignFrame = [];
  listQrcodeDesignFrameCircle = [];
  listQrcodeDesignFrameSquare = [];
  frameConfig = {
    title: '',
    content: '',
  };
  styleFrameTitle = null;
  styleFrameContent = null;
  styleFrameQrcode = null;
  styleFrameQrcodeWrap = null;
  stylePreviewFrameTitle = null;
  stylePreviewFrameContent = null;
  stylePreviewFrameQrcode = null;
  stylePreviewFrameQrcodeWrap = null;
  base64QrcodeCoreSvg: any = '';
  listQrcodeLogoExist = [
    'https://assets.icheck.vn/image/2022/icheck/3/c65533cee273366ded2b048234f3d198.png',
    'https://assets.icheck.vn/image/2022/icheck/3/3740af16c3d78f69ff1ad64f008210c5.png',
    'https://assets.icheck.vn/image/2022/icheck/3/05848b43e609c3af26d6b5dae3c1c6ea.png',
    'https://assets.icheck.vn/image/2022/icheck/3/36e7fbf2f3662027a2a7c686650c8717.png',
  ];
  qrcodeConfigPreview = {
    selectedQrcodeFrame: null,
    stylePreviewFrameTitle: null,
    stylePreviewFrameContent: null,
    stylePreviewFrameQrcodeWrap: null,
    stylePreviewFrameQrcode: null,
    base64QrcodeCoreSvg: '',
    qrcodeLogo: '',
    title: '',
    content: ''
  };

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && user.userInfo && user.userInfo.userId === 'pBV460kJ') {
      this.qrcodeData = environment.redirectScanUrlVietThai;
    }
    this.getListQrcodeDesignExist();
    this.getListQrcodeDesignFrame();
  }

  onChangeIsUpdate() {
    this.isUpdate = !this.isUpdate;
  }

  generateQrcodeCode() {
    this.qrcodeCode = this.isUpdate ? this.stampCode : randomStringWithLength(12).toLowerCase();
  }

  getQrcodeCode() {
    return this.qrcodeCode;
  }

  updateStampCode(code) {
    this.stampCode = code;
  }

  drawQrcodeSquare() {
    this.changeLoadingDrawQrcode.emit(true);
    this.generateQrcodeCode();
    document.getElementById('qrcode-canvas-square').innerHTML = '';
    document.getElementById('qrcode-canvas-square-1').innerHTML = '';
    document.getElementById('tmpCoreQrcode').innerHTML = '';
    document.getElementById('coreQrcode').innerHTML = '';
    const qrcode = new QRCodeStyling({
      ...this.qrcodeDesignConfig,
      width: this.selectedQrcodeShape === this.eQrcodeShape.circle ? 1018 : 1000,
      height: this.selectedQrcodeShape === this.eQrcodeShape.circle ? 1018 : 1000,
      data: `${this.qrcodeData}/${this.qrcodeCode}`
    } as any);
    const qrcode1 = new QRCodeStyling({
      ...this.qrcodeDesignConfig,
      width: this.selectedQrcodeShape === this.eQrcodeShape.circle ? 1018 : 1000,
      height: this.selectedQrcodeShape === this.eQrcodeShape.circle ? 1018 : 1000,
      data: `${this.qrcodeData}/${this.qrcodeCode}`,
      image: ''
    } as any);
    qrcode.append(document.getElementById('qrcode-canvas-square'));
    qrcode1.append(document.getElementById('qrcode-canvas-square-1'));
    const svgQrcode1 = document.querySelector('#qrcode-canvas-square-1 svg');
    const svgQrcode = document.querySelector('#qrcode-canvas-square svg');
    const clipPath = document.getElementById('clipQrcodeDef');
    // const useClipPath = document.getElementById('useClipQrcode');
    svgQrcode.append(clipPath);
    // svgQrcode1.append(useClipPath);
    const patternTmpCore = document.getElementById('tmpCoreQrcode');
    const patternCore = document.getElementById('coreQrcode');
    patternTmpCore.append(svgQrcode1);
    patternCore.append(svgQrcode);
    setTimeout(() => {
      const svgQrcode1Ele = document.querySelector('#tmpCoreQrcode svg');
      const svgQrcodeCoreEle = document.querySelector('#coreQrcode svg');
      svgQrcode1Ele.setAttribute('x', '-240');
      if (this.selectedQrcodeShape === this.eQrcodeShape.circle) {
        svgQrcodeCoreEle.setAttribute('x', '211');
        svgQrcodeCoreEle.setAttribute('y', '211');
      } else {
        svgQrcodeCoreEle.setAttribute('x', '0');
        svgQrcodeCoreEle.setAttribute('y', '0');
      }
      this.base64QrcodeCoreSvg = this.domSanitizer.bypassSecurityTrustResourceUrl(this.svgToDataUrl('#qrcode-core'));
      this.qrcodeConfigPreview = {
        ...this.qrcodeConfigPreview,
        base64QrcodeCoreSvg: this.base64QrcodeCoreSvg,
        qrcodeLogo: this.qrcodeDesignConfig.image,
      };
      this.changeQrcodeConfigPreview.emit(this.qrcodeConfigPreview);
      this.changeLoadingDrawQrcode.emit(false);
    }, 500);
  }

  getListQrcodeDesignExist() {
    if (this.listQrcodeDesignExist.length) {
      return;
    }
    this.stampService.getListQrcodeDesignExist().subscribe(res => {
      this.listQrcodeDesignExist = res.data.rows || [];
      this.listQrcodeDesignExistCircle = res.data.rows.filter(item => item.code === 'circle') || [];
      this.listQrcodeDesignExistSquare = res.data.rows.filter(item => item.code === 'square') || [];
      if (this.stampCode === 'null') {
        this.initFirstExistQrcodeDesign();
      }
    });
  }

  async onGetListQrcodeDesignExist() {
    if (this.listQrcodeDesignExist && this.listQrcodeDesignExist.length) { return; }
    return await this.stampService.getListQrcodeDesignExist().toPromise().then(res => {
      this.listQrcodeDesignExist = res.data.rows || [];
      this.listQrcodeDesignExistCircle = res.data.rows.filter(item => item.code === 'circle') || [];
      this.listQrcodeDesignExistSquare = res.data.rows.filter(item => item.code === 'square') || [];
    });
  }

  async onGetListQrcodeDesignFrame() {
    if (this.listQrcodeDesignFrame && this.listQrcodeDesignFrame.length) { return; }
    return await this.stampService.getListQrcodeDesignFrame().toPromise().then(res => {
      this.listQrcodeDesignFrame = res.data.rows || [];
      this.listQrcodeDesignFrameCircle = res.data.rows.filter(item => item.code === 'circle') || [];
      this.listQrcodeDesignFrameSquare = res.data.rows.filter(item => item.code === 'square') || [];
      console.log("listQrcodeDesignFrameSquare", this.listQrcodeDesignFrameSquare)
    });
  }

  initFirstExistQrcodeDesign() {
    if (this.selectedQrcodeShape === EQrcodeShape.circle) {
      this.onSelectExistQrcodeDesign(this.listQrcodeDesignExistCircle[0]);
    } else {
      this.onSelectExistQrcodeDesign(this.listQrcodeDesignExistSquare[0]);
    }
  }

  initFirstQrcodeFrame() {
    console.log("this.listQrcodeDesignFrameSquare[0]", this.listQrcodeDesignFrameSquare[0])
    console.log("this.listQrcodeDesignFrameCircle[0]", this.listQrcodeDesignFrameCircle[0])
    if (this.selectedQrcodeShape === EQrcodeShape.circle) {
      this.onSelectQrcodeFrame(this.listQrcodeDesignFrameCircle[0]);
    } else {
      this.onSelectQrcodeFrame(this.listQrcodeDesignFrameSquare[0]);
    }
  }

  getListQrcodeDesignFrame() {
    if (this.listQrcodeDesignFrame.length) {
      return;
    }
    this.stampService.getListQrcodeDesignFrame().subscribe(res => {
      this.listQrcodeDesignFrame = res.data.rows || [];
      this.listQrcodeDesignFrameCircle = res.data.rows.filter(item => item.code === 'circle') || [];
      this.listQrcodeDesignFrameSquare = res.data.rows.filter(item => item.code === 'square') || [];
    });
  }

  onSelectExistQrcodeDesign(config) {
    this.selectedQrcodeDesignExistId = config.id;
    this.qrcodeDesignConfig.dotsOptions = {...config.optionsConfig.dotsOptions};

    if (this.selectedQrcodeDesignWay === this.eQrcodeDesignWay.bySelf && this.selectedQrcodeShape === this.eQrcodeShape.square) {
      this.qrcodeDesignConfig.dotsOptions.type = 'classy';
    } else {
      this.qrcodeDesignConfig.dotsOptions = {...config.optionsConfig.dotsOptions};
    }
    this.qrcodeDesignConfig.backgroundOptions = {...config.optionsConfig.backgroundOptions};
    this.delayDrawQrcode();
  }

  onChangeFrameConfig() {
    this.qrcodeConfigPreview = {
      ...this.qrcodeConfigPreview,
      title: this.frameConfig.title,
      content: this.frameConfig.content
    };
    this.changeQrcodeConfigPreview.emit(this.qrcodeConfigPreview);
  }

  onDeselectQrcodeFrame() {
    this.selectedQrcodeFrame = null;
    this.stylePreviewFrameTitle = null;
    this.styleFrameTitle = null;
    this.stylePreviewFrameContent = null;
    this.styleFrameContent = null;
    this.stylePreviewFrameQrcode = null;
    this.stylePreviewFrameQrcodeWrap = null;
    this.frameConfig.content = '';
    this.frameConfig.title = '';
    this.qrcodeConfigPreview = {
      ...this.qrcodeConfigPreview,
      selectedQrcodeFrame: this.selectedQrcodeFrame,
      stylePreviewFrameTitle: this.stylePreviewFrameTitle,
      stylePreviewFrameContent: this.stylePreviewFrameContent,
      stylePreviewFrameQrcodeWrap: this.stylePreviewFrameQrcodeWrap,
      stylePreviewFrameQrcode: this.stylePreviewFrameQrcode,
      content: this.frameConfig.content,
      title: this.frameConfig.title,
    };
    this.delayDrawQrcode();
    this.changeQrcodeConfigPreview.emit(this.qrcodeConfigPreview);
  }

  onSelectQrcodeFrame(config) {
    this.selectedQrcodeFrame = {...config};
    console.log("select qr code", config);
    if (config.metadata && config.metadata.disableDescription) {
      this.frameConfig.content = '';
    }
    if (this.selectedQrcodeFrame) {
      this.stylePreviewFrameTitle = {
        color: this.selectedQrcodeFrame.metadata.title.textColor,
        fontSize: this.selectedQrcodeFrame.metadata.title.fontSize / 10 + 'px',
        height: this.selectedQrcodeFrame.metadata.title.height / 10 + 'px',
        lineHeight: this.selectedQrcodeFrame.metadata.title.height / 10 + 'px',
        fontWeight: this.selectedQrcodeFrame.metadata.title.fontWeight,
        top: this.selectedQrcodeFrame.metadata.title.y / 10 + 'px',
        overflow: 'hidden',
      };
      this.styleFrameTitle = {
        color: this.selectedQrcodeFrame.metadata.title.textColor,
        fontSize: this.selectedQrcodeFrame.metadata.title.fontSize + 'px',
        lineHeight: this.selectedQrcodeFrame.metadata.title.height + 'px',
        fontWeight: this.selectedQrcodeFrame.metadata.title.fontWeight,
        textAlign: 'center',
        fontFamily: 'sans-serif',
      };
      this.stylePreviewFrameContent = {
        color: this.selectedQrcodeFrame.metadata.content.textColor,
        fontSize: this.selectedQrcodeFrame.metadata.content.fontSize / 10 + 'px',
        fontWeight: this.selectedQrcodeFrame.metadata.content.fontWeight,
        top: this.selectedQrcodeFrame.metadata.content.y / 10 + 'px',
        overflow: 'hidden',
        lineHeight: '18px',
        padding: '0 6px'
      };
      this.styleFrameContent = {
        color: this.selectedQrcodeFrame.metadata.content.textColor,
        fontSize: this.selectedQrcodeFrame.metadata.content.fontSize + 'px',
        lineHeight: this.selectedQrcodeFrame.metadata.content.height / 2 + 'px',
        fontWeight: this.selectedQrcodeFrame.metadata.content.fontWeight,
        textAlign: 'center',
        fontFamily: 'sans-serif',
      };
      this.stylePreviewFrameQrcode = {
        // width: this.selectedQrcodeFrame.code === 'circle' ? this.selectedQrcodeFrame.metadata.qrcode.w / 10 + 'px' : '100px',
        width: this.selectedQrcodeFrame.code === 'circle' ? this.selectedQrcodeFrame.metadata.qrcode.width / 10 + 'px'
          : this.selectedQrcodeFrame.metadata.qrcode.w ? this.selectedQrcodeFrame.metadata.qrcode.w + 'px' : '100px',
        // height: this.selectedQrcodeFrame.code === 'circle' ? this.selectedQrcodeFrame.metadata.qrcode.h / 10 + 'px' : '100px',
        height: this.selectedQrcodeFrame.code === 'circle' ? this.selectedQrcodeFrame.metadata.qrcode.width / 10 + 'px'
          : this.selectedQrcodeFrame.metadata.qrcode.h ? this.selectedQrcodeFrame.metadata.qrcode.h + 'px' : '100px',
      };
      this.stylePreviewFrameQrcodeWrap = {
        top: this.selectedQrcodeFrame.metadata && this.selectedQrcodeFrame.metadata.qrcode && this.selectedQrcodeFrame.metadata.qrcode.top ?
          this.selectedQrcodeFrame.metadata.qrcode.top / 10 + 'px' : this.selectedQrcodeFrame.metadata.qrcode.y / 10 + 'px',
      };
      this.qrcodeConfigPreview = {
        ...this.qrcodeConfigPreview,
        title: this.frameConfig.title,
        content: this.frameConfig.content,
        selectedQrcodeFrame: this.selectedQrcodeFrame,
        stylePreviewFrameTitle: this.stylePreviewFrameTitle,
        stylePreviewFrameContent: this.stylePreviewFrameContent,
        stylePreviewFrameQrcodeWrap: this.stylePreviewFrameQrcodeWrap,
        stylePreviewFrameQrcode: this.stylePreviewFrameQrcode,
      };
      this.changeQrcodeConfigPreview.emit(this.qrcodeConfigPreview);
      this.delayDrawQrcode();
    }
  }

  unescapeUri(str: string) {
    return unescape(encodeURIComponent(str));
  }

  onChangeQrcodeShape(e) {
    this.changeLoadingDrawQrcode.emit(true);
    this.selectedQrcodeShape = e;
    this.onChangeQrcodeDesignWay(this.eQrcodeDesignWay.existed);
    if (this.selectedQrcodeFrame) {
      this.initFirstQrcodeFrame();
    }
  }

  onChangeQrcodeDesignWay(e) {
    this.selectedQrcodeDesignWay = e;
    // if (e === this.eQrcodeDesignWay.existed) {
    this.initFirstExistQrcodeDesign();
    // }
  }

  svgToDataUrl(idSelector = '#qrcode-image') {
    const svg = document.querySelector(this.selectedQrcodeFrame ? idSelector : '#qrcode-core');
    const xml = new XMLSerializer().serializeToString(svg);
    const svg64 = btoa((xml));
    const b64start = 'data:image/svg+xml;base64,';
    return b64start + svg64;
  }

  async drawAndSvgToDataUrl(idSelector = '#qrcode-image') {
    await this.promiseDrawQrcode();
    const svg = document.querySelector(this.selectedQrcodeFrame ? idSelector : '#qrcode-core');
    const xml = new XMLSerializer().serializeToString(svg);
    const svg64 = btoa((xml));
    const b64start = 'data:image/svg+xml;base64,';
    return b64start + svg64;
  }

  downloadCoreQrcodeSvg(name) {
    const svgData: any = document.querySelector('#qrcode-core');
    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svgData);
    source = source.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=');
    source = source.replace(/ns\d+:href/g, 'xlink:href');
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';
    const svgBlob = new Blob([preface, source], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  getBlobUrlCoreQrcodeSvg(name) {
    const svgData: any = document.querySelector('#qrcode-core');
    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svgData);
    source = source.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=');
    source = source.replace(/ns\d+:href/g, 'xlink:href');
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';
    const svgBlob = new Blob([preface, source], { type: 'image/svg+xml;charset=utf-8' });
    return URL.createObjectURL(svgBlob);
  }

  getQrcodeDesignConfig() {
    return {
      logo: this.qrcodeDesignConfig.image || '',
      shape: this.qrcodeDesignConfig.dotsOptions.type || 'classy',
      color: this.qrcodeDesignConfig.dotsOptions.color || '#000000',
      backgroundColor: this.qrcodeDesignConfig.backgroundOptions.color || '#ffffff',
    };
  }

  getQrcodeFrameConfig() {
    return {
      id: this.selectedQrcodeFrame && this.selectedQrcodeFrame.id ? this.selectedQrcodeFrame.id : null,
      title: this.frameConfig.title || '',
      content: this.frameConfig.content || '',
    };
  }

  getQrcodeShape() {
    return this.selectedQrcodeShape;
  }

  onSelectQrcodeDotShape(value: string) {
    this.qrcodeDesignConfig.dotsOptions.type = value;
    this.delayDrawQrcode();
  }

  delayDrawQrcode() {
    this.changeLoadingDrawQrcode.emit(false);
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.drawQrcodeSquare();
    }, 800);
  }

  promiseDrawQrcode() {
    return new Promise((resolve) => {
      this.drawQrcodeSquare();
      setTimeout(() => {
        resolve();
      }, 800);
    });
  }

  onSelectExistLogo(value: string) {
    this.qrcodeDesignConfig.image = value;
    this.delayDrawQrcode();
  }

  onRemoveLogo() {
    this.qrcodeDesignConfig.image = '';
    this.delayDrawQrcode();
  }

  updateQrcodeConfig(qrcodeShape, qrcodeConfig, frameConfig, frameId?) {
    this.isUpdate = true;
    this.selectedQrcodeShape = qrcodeShape || this.eQrcodeShape.square;
    // tslint:disable-next-line:max-line-length
    const first6ExistQrcodeConfig = this.selectedQrcodeShape === this.eQrcodeShape.circle ? this.listQrcodeDesignExistCircle.slice(0, 6) : this.listQrcodeDesignExistSquare.slice(0, 6);
    this.qrcodeDesignConfig.dotsOptions.color = qrcodeConfig.color;
    this.qrcodeDesignConfig.dotsOptions.type = qrcodeConfig.shape;
    this.qrcodeDesignConfig.backgroundOptions.color = qrcodeConfig.backgroundColor;
    this.qrcodeDesignConfig.image = qrcodeConfig.logo || '';
    if (qrcodeConfig.logo && !this.listQrcodeLogoExist.includes(qrcodeConfig.logo)) {
      this.listQrcodeLogoExist.unshift(qrcodeConfig.logo);
    }
    first6ExistQrcodeConfig.forEach(config => {
      if (
        config.optionsConfig.dotsOptions.color === qrcodeConfig.color &&
        config.optionsConfig.dotsOptions.type === qrcodeConfig.shape &&
        config.optionsConfig.backgroundOptions.color === qrcodeConfig.backgroundColor
      ) {
        this.selectedQrcodeDesignWay = this.eQrcodeDesignWay.existed;
        this.selectedQrcodeDesignExistId = config.id;
      }
    });
    if (!this.selectedQrcodeDesignExistId) {
      this.selectedQrcodeDesignWay = this.eQrcodeDesignWay.bySelf;
    }
    if (frameId && !!this.listQrcodeDesignFrame.find(item => item.id === frameId)) {
      this.onSelectQrcodeFrame(this.listQrcodeDesignFrame.find(item => item.id === frameId));
    } else {
      this.delayDrawQrcode();
    }
    this.frameConfig.title = frameConfig && frameConfig.title ? frameConfig.title : '';
    this.frameConfig.content = frameConfig && frameConfig.content ? frameConfig.content : '';
    this.onChangeFrameConfig();

  }

  openUploadDialogQrcodeLogo() {
    const fileType = 'jpg,jpeg,png,gif,bmp';
    const modal = this.modalService.create({
      nzTitle: `Upload logo`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.listQrcodeLogoExist.unshift(data.url);
          this.qrcodeDesignConfig = {
            ...this.qrcodeDesignConfig,
            image: data.url
          };
          this.delayDrawQrcode();
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe((changeParam) => {
          modal.close();
        });
    });
  }
}
