import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RootService extends BaseService {
  messageSource = new BehaviorSubject<boolean>(true);
  currentMessage = this.messageSource.asObservable();

  /*Socket*/
  scanMessageSource = new BehaviorSubject<boolean>(false);
  scanCurrentMessage = this.scanMessageSource.asObservable();

  disconnetStatusSource = new BehaviorSubject<any>("none");
  disconnetStatusMessage = this.disconnetStatusSource.asObservable();

  connectIoSource = new BehaviorSubject<boolean>(false);
  connectIoMessage = this.connectIoSource.asObservable();

  scanChangeMessage(message) {
    this.scanMessageSource.next(message);
  }

  disconnetStatus(message) {
    this.disconnetStatusSource.next(message);
  }

  connectIo(message) {
    this.connectIoSource.next(message);
  }

  /*End Socket*/
  changeMessage(message) {
    this.messageSource.next(message);
  }

  export(data, nameFile) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = nameFile;
    link.click();
  }

  listCountries(): Observable<any> {
    return this.get<any>(`/location/countries`);
  }

  listCities(): Observable<any> {
    return this.get<any>(`/location/provinces`);
  }

  getCityById(id: number): Observable<any> {
    return this.get<any>(`/location/name?type=province&id=${id}`);
  }

  listDistricts(id: number): Observable<any> {
    return this.get<any>(`/location/districts?province_id=${id}`);
  }

  getDistrictById(id: number): Observable<any> {
    return this.get<any>(`/location/name?type=district&id=${id}`);
  }

  listWards(id: number): Observable<any> {
    return this.get<any>(`/location/wards?district_id=${id}`);
  }

  getWardById(id: number): Observable<any> {
    return this.get<any>(`/location/name?type=wards&id=${id}`);
  }

  getLocations(query: any) {
    return this.get(`/location`, { params: query });
  }

  getTitles(query: any) {
    return this.get(`/title/business`, { params: query });
  }

  getRoles() {
    return this.get(`/role/business`);
  }
}
