import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsReviewsService extends BaseService {
  status = new Subject<any>();
  currentStatus = this.status.asObservable();

  getStatus(status: any) {
    this.status.next(status);
  }

  getAll(query?: any): Observable<any> {
    return this.get<any>('product/reviews', { params: query });
  }

  getCommentById(id: any, query?: any): Observable<any> {
    return this.get<any>(`product/review/${id}`, { params: query });
  }

  getAllCriteria(query?: any): Observable<any> {
    return this.get<any>('product/overview', { params: query });
  }

  hidden(id: any, is_hidden: Boolean, product_id: string, reason?: string): Observable<any> {
    return this.put<any>(`product/hidden/request?review_id=${id}&is_hidden=${is_hidden}&product_id=${product_id}&description=${reason}`);
  }

  pinned(id: any, is_pinned: Boolean, product_id: string): Observable<any> {
    return this.put<any>(`product/pinning?review_id=${id}&is_pinned=${is_pinned}&product_id=${product_id}`);
  }

  comment(product_id: string, body: any): Observable<any> {
    return this.post<any>(`product/review/reply?product_id=${product_id}`, body);
  }

}
