import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent, ForgetComponent, SignUpComponent } from './containers';
@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forget',
        component: ForgetComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
