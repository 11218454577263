import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompaniesService } from 'src/app/services';
import { DomSanitizer } from '@angular/platform-browser';
import {
  embedYoutubeUrl,
  numberWithCommas,
  isValidYoutubeUrl,
  checkTypePDF,
  onOpenDNSHSocial,
  goToSocialLink,
} from 'src/app/utility/common/commonFunction';

@Component({
  selector: 'app-template-two-en-v2',
  templateUrl: './template-two-en-v2.component.html',
  styleUrls: ['./template-two-en-v2.component.scss']
})
export class TemplateTwoEnV2Component implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  targetDetail: any = null;
  listProduct: any = [];
  listRelatedTarget: any = [];
  limitDescription = 150;
  settingStamp: any = null;

  embedYoutubeUrl = embedYoutubeUrl;
  numberWithCommas = numberWithCommas;
  isValidYoutubeUrl = isValidYoutubeUrl;
  checkTypePDF = checkTypePDF;
  onOpenDNSHSocial = onOpenDNSHSocial;
  goToSocialLink = goToSocialLink;
  roleTitle = '';

  constructor(
    private companiesService: CompaniesService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      console.log('chh_log ---> this.templateData.subscribe ---> val', val);
      this.data = { ...val };
      this.settingStamp = { ...val.setting };
      if (val.targetId) {
        this.getDetailTargetById(val.targetId);
        this.getListProduct(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 1) {
        this.getRelatedTarget(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 2) {
        this.listRelatedTarget = val.listRelatedTarget
          ? [...val.listRelatedTarget]
          : [];
      }
      console.log("valxxxxxxxxxx", val)
      if (val.roleId === 3) { this.roleTitle = 'Điểm bán'; }
      if (val.roleId === 2) { this.roleTitle = 'Doanh nghiệp sở hữu'; }
      if (val.roleId === 1) { this.roleTitle = 'Nhà Phân Phối'; }
    });
  }

  getDetailTargetById(id: any) {
    this.companiesService
      .getDetailCompanyById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.targetDetail = res.data ? { ...res.data } : null;
          this.targetDetail.fullAddress = [
            res.data.address,
            res.data.wardName,
            res.data.districtName,
            res.data.provinceName,
            res.data.countryName,
          ]
            .filter((e) => typeof e === 'string' && e !== '')
            .join(', ');
        }
        console.log("targetDetail", this.targetDetail);
      });
  }

  getJob() {
    const career = this.targetDetail && this.targetDetail.career ? this.targetDetail.career : [];
    if (!career.length) { return ''; }
    const job = career.find(item => item.type === 'job');
    console.log("job", job)
    return job && job.value ? job.value : '';
  }

  getListProduct(id: any) {
    const query = {
      businessId: id,
      page: 1,
      pageSize: 4,
    };
    this.companiesService
      .getListProducts(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listProduct = res.data
            ? res.data.map((gg: any) => {
              const tmp = { ...gg };
              tmp._price = this.numberWithCommas(gg.price);
              return tmp;
            })
            : [];
        }
      });
  }

  getRelatedTarget(id: any) {
    const query = {
      page: 1,
      page_size: 7,
    };
    this.companiesService
      .getList(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          if (res.data) {
            res.data.forEach((gg: any) => {
              if (gg.businessId !== id) {
                gg.fullAddress = [gg.address, gg.provinceName]
                  .filter((e) => typeof e === 'string' && e !== '')
                  .join(', ');

                this.listRelatedTarget.push(gg);
              }
            });
          }
        }
      });
  }
  renderVideo(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
