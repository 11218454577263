import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  
  league: any = {};
  predictions: any[] = [];
  teams: any[] = [];
  
  constructor() {
  }

  ngOnInit(): void {
  }
}
