import { Injectable } from '@angular/core';
import {BaseService} from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {
  getRepost(query?: any) {
    return this.get<any>('/stringee/statistics', {params: query});
  }
}
