import {Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import {ProductsService} from "../../services";
import {BehaviorSubject, Observable} from "rxjs";
import {debounceTime} from "rxjs/operators";

export interface Data {
  id: number;
  images: string;
  productName: number;
  barcode: string;
  disabled: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tranfer-item-component',
  templateUrl: './tranfer-item.component.html',
  styleUrls: ['./tranfer-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TranferItemComponent implements OnInit {
  // Left
  pageIndex = 1;
  pageSize = 5;
  total;
  // allTotalLeft = ;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  @Input() arrData: any;
  // Right
  totalRight;
  pageIndexRight = 1;
  pageSizeRight = 5;
  isAllDisplayDataCheckedRight = false;
  isIndeterminateRight = false;
  listOfDisplayDataRight: any[] = [];
  listOfAllDataRight: any[] = [];
  mapOfCheckedIdRight: { [key: string]: boolean } = {};
  idsRight: any[] = [];
  dataLeft = [];
  @Output() emitDataLeft = new EventEmitter();
  @Output() paginateLeft = new EventEmitter();
  @Output() searchLeftEmit = new EventEmitter();
  @Output() searchRightEmit = new EventEmitter();

  @Output() emitDataRight = new EventEmitter();
  @Output() paginateRight = new EventEmitter();
  searchNameLeft;
  searchNameRight;

  // tslint:disable-next-line:ban-types

  constructor(
    private productService: ProductsService,
  ) {


  }

  ngOnInit() {
  }

// Left
  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    if (this.listOfDisplayData.length > 0) {
      this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item]);

      this.isIndeterminate = this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;

      this.ids = [];

      for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
        // tslint:disable-next-line:triple-equals
        if (value == true) {
          this.ids.push(key);
        }
      }
      this.emitDataLeft.emit(this.ids);
    } else {
      this.isAllDisplayDataChecked = false;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }


  listLeft(data) {
    if (data === 'null') {
      this.total = 0;
      this.listOfAllData = [];
    } else {
      this.total = data.totalElement;
      this.listOfAllData = data.items;

    }
  }

  changePageIndexLeft(i) {
    this.pageIndex = i;
  }

  paginateLeftFun() {
    this.paginateLeft.emit({
      page: this.pageIndex,
      page_size: this.pageSize
    });

  }

  searchLeft() {
    this.searchLeftEmit.emit({
      event: this.searchNameLeft,
    });
  }


// Right


  listRight(data) {
    if (data === 'null') {
      this.totalRight = 0;
      this.listOfAllDataRight = [];
    } else {
      this.totalRight = data.totalElement;
      this.listOfAllDataRight = data.items;
    }
  }

  paginateRightFun() {
    this.paginateRight.emit({
      page: this.pageIndexRight,
      page_size: this.pageSizeRight
    });

  }

  changePageIndexRight(i) {
    this.pageIndexRight = i;
  }

  searchRight() {
    this.searchRightEmit.emit({
      event: this.searchNameRight,
    });
  }
}
