import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsStoresService extends BaseService {

  active = new BehaviorSubject<any>("default");
  currentActive = this.active.asObservable();

  getActive(active: any) {
    this.active.next(active);
  }

  getAll(query?: any): Observable<any> {
    return this.get<any>('/product/shop', { params: query });
  }

  getAllReason(query?: any): Observable<any> {
    return this.get<any>('/shop/product/denied/reason', { params: query });
  }

  getAllTitle(query?: any): Observable<any> {
    return this.get<any>('/shop/define/title', { params: query });
  }

  getAllType(query?: any): Observable<any> {
    return this.get<any>('/shop/define/type', { params: query });
  }

  getById(id: string, product_id: string): Observable<any> {
    return this.get<any>(`/shop/${id}?product_id=${product_id}`);
  }

  create(productId: string, body: any): Observable<any> {
    return this.post<any>(`/product/shop/create?product_id=${productId}`, body);
  }

  assign(productId: string, body: any): Observable<any> {
    return this.post<any>(`/product/${productId}/shop`, body);
  }

  update(productId: string, body: any): Observable<any> {
    return this.post<any>(`/product/shop/update?product_id=${productId}`, body);
  }

  destroy(productId: string, body: any): Observable<any> {
    return this.delete<any>(`/product/shop?product_id=${productId}`, body);
  }

  block(productId: string, body: any): Observable<any> {
    return this.put<any>(`/product/shop/block?product_id=${productId}`, body);
  }

  unblock(productId: string, body: any): Observable<any> {
    return this.put<any>(`/product/shop/unblock?product_id=${productId}`, body);
  }

  verify(productId: string, body: any): Observable<any> {
    return this.put<any>(`/product/shop/verify?product_id=${productId}`, body);
  }

  unverify(productId: string, body: any): Observable<any> {
    return this.put<any>(`/product/shop/verify?product_id=${productId}`, body);
  }

  exportExcel(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>('/product/shop/export', httpOptions);
  }
}
