import { Injectable } from '@angular/core';
import {BaseService} from "../../base.service";

@Injectable({
  providedIn: 'root'
})
export class InteractionsService extends BaseService {

  list(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/histories', httpOptions);
  }

  exportExcel(query?: any) {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>(`/histories/export`, httpOptions);
  }

  searchProduct(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/product/search', httpOptions);
  }
}
