import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShopsService } from 'src/app/services';

@Component({
  selector: 'app-verify-stores',
  templateUrl: './verify-stores.component.html',
  styleUrls: ['./verify-stores.component.scss']
})
export class VerifyStoresComponent implements OnInit, OnDestroy {

  isVisible = false;
  type: string;
  showModal(type: string) {
    this.isVisible = true;
    this.type = type;
    this.loadTitle();
    this.form.reset();
  }

  handleCancel() {
    this.isVisible = false;
  }

  @Output() verifyStores = new EventEmitter<any>();
  @Output() titleStores = new EventEmitter<any>();

  unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private shopService: ShopsService,
    private notification: NzNotificationService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  form = this.fb.group({
    product_ids: [null],
    type_id: [null, [Validators.required]],
  })

  ngOnInit() {
  }

  titles: any = [];
  loadTitle() {
    this.shopService.getAllTitle().subscribe(
      (res) => {
        this.titles = res.data.items;;
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  isLoadingBtn: Boolean = false;
  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }

    this.isLoadingBtn = true;

    if (this.type === 'verifyProductStore') {
      this.form.addControl('isVerify', this.fb.control(true));
      this.verifyStores.emit(this.form.value);
      this.form.removeControl('isVerify');
      return;
    }

    if (this.type === 'updateTitleStore') {
      let body: any = {
        title: 'title',
        body: this.form.value,
      }
      this.verifyStores.emit(body);
      return;
    }

    if (this.type === 'verifyStore') {
      this.verifyStores.emit(this.form.value);
    }
  }

  success() {
    this.isLoadingBtn = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
