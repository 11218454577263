import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductsService } from 'src/app/services';
import { embedYoutubeUrl, numberWithCommas, isValidYoutubeUrl, checkTypePDF } from 'src/app/utility/common/commonFunction';

import { iconLinkConvert } from 'src/app/utility/constance/productTemplate';
@Component({
  selector: 'app-template-seven-v2',
  templateUrl: './template-seven-v2.component.html',
  styleUrls: ['./template-seven-v2.component.scss'],
})
export class TemplateSevenV2Component implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  productDetail: any = null;
  listOwners: any = [];
  listDistributors: any = [];
  listShops: any = [];
  firstTargetProduct: any = null;
  listTargetProduct: any = [];
  listRelatedProducts: any = [];
  listEcommerces: any = [];
  limitDescription = 150;
  sliceDescription = '';
  listAttributeSub: any = [];
  settingStamp: any = null;

  numberWithCommas = numberWithCommas;
  isValidYoutubeUrl = isValidYoutubeUrl;
  checkTypePDF = checkTypePDF;
  constructor(
    private productService: ProductsService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      this.data = { ...val };
      this.settingStamp = { ...val.setting };
      this.data._message = this._sanitizer.bypassSecurityTrustHtml(
        this.data.message
      );
      // get detail product
      if (val.productId) {
        this.getDetailProductById(val.productId);
      } else {
        this.productDetail = null;
      }

      // get list related product
      if (val.relatedProductType === 1) {
        this.getRelatedProducts(val.productId);
      }
      if (val.relatedProductType === 2) {
        this.listRelatedProducts = val.customRelatedProducts
          ? [...val.customRelatedProducts]
          : [];
      }

      // ecommerces
      if (val.listEcommerces) {
        this.listEcommerces = val.listEcommerces.map((gg: any) => {
          const tmp = { ...gg };
          tmp._price = this.numberWithCommas(gg.price);
          tmp._priceDiscount = this.numberWithCommas(gg.priceDiscount);
          tmp._icon = gg.logo && !!!gg.type ? gg.logo : iconLinkConvert.find((el) => el.product === gg.name)
            ? iconLinkConvert.find((el) => el.product === gg.name).old
            : '/assets/svg/icon-globe.svg';
          return tmp;
        });
        console.log('chh_log ---> this.listEcommerces=val.listEcommerces.map ---> this.listEcommerces', this.listEcommerces);
      }

      // get list target
      if (val.listTargetProduct) {
        if (val.listTargetProduct && val.listTargetProduct.length > 0) {
          let removeListHideOptionTarget = val.listTargetProduct.filter(
            (el) => el.isShow === true
          );
          if (val.setting.hideDistributor) {
            removeListHideOptionTarget = removeListHideOptionTarget.filter(
              (el) => el.roleCode !== 'DISTRIBUTOR'
            );
          }
          if (val.setting.hideOwner) {
            removeListHideOptionTarget = removeListHideOptionTarget.filter(
              (el) => el.roleCode !== 'OWNER'
            );
          }
          if (val.setting.hideShop) {
            removeListHideOptionTarget = removeListHideOptionTarget.filter(
              (el) => el.roleCode !== 'SHOP'
            );
          }
          this.firstTargetProduct = removeListHideOptionTarget[0];
          this.listTargetProduct = removeListHideOptionTarget.filter(
            (el, index) => index > 0
          );
        }
      }
    });
  }

  getDetailProductById(id: any) {
    if (this.productDetail && id === this.productDetail.id) { return; }
    this.productService
      .getDetailById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.productDetail = res.data ? { ...res.data } : null;
          this.productDetail._price = this.numberWithCommas(
            this.productDetail.price
          );
          this.productDetail.attributes = this.productDetail.attributes
            ? this.productDetail.attributes.map((gg: any) => {
                const tmp = { ...gg };
                if (gg.value) {
                  tmp.value = this._sanitizer.bypassSecurityTrustHtml(gg.value);
                }
                return tmp;
              })
            : [];
          this.listAttributeSub = this.productDetail.attributes.filter(
            (el, index) => index > 0
          );
          this.sliceDescription =
            this.productDetail.attributes[0] &&
            this.productDetail.attributes[0].value
              ? this.productDetail.attributes[0].value.changingThisBreaksApplicationSecurity.slice(
                  0,
                  this.limitDescription
                )
              : '';
        }
      });
  }

  getRelatedProducts(productId: any) {
    const query = {
      page: 1,
      page_size: 100,
    };
    this.productService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          if (res.data) {
            res.data.forEach((gg: any) => {
              if (gg.id !== productId) {
                gg._price = this.numberWithCommas(gg.price);
                this.listRelatedProducts.push(gg);
              }
            });
          }
          if (this.listRelatedProducts && this.listRelatedProducts.length > 5) {
            this.listRelatedProducts.length = 5;
          }
        }
      });
  }

  renderPDF(url: string) {
    return this._sanitizer.bypassSecurityTrustHtml(`<object
    data="${url}"
    style="width: 100%;height:100%;"
    ></object>`);
  }
  renderVideo(url: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
