import {formatDate} from '@angular/common';
import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProductsService, StampV2Service, SurveyService, UploadService} from 'src/app/services';
import {UploaderComponent} from '../../../../shared';
import {StampService} from 'src/app/services/business/stamp/stamp.service';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {environment} from '../../../../../environments/environment';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {base64toBlob, configCommon, isIncludeBase64Image} from '../../../../helpers';
import {regexBase64Image, URL_LINK} from '../../../../helpers/constant';
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';

@Component({
  selector: 'app-assign-product',
  templateUrl: './assign-product.component.html',
  styleUrls: ['./assign-product.component.scss'],
})
export class AssignProductComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private stampService: StampService,
    private router: Router,
    private productService: ProductsService,
    private modalService: NzModalService,
    private surveyService: SurveyService,
    private stampV2Service: StampV2Service,
    private uploadService: UploadService,
  ) {
    // @ts-ignore
  }

  @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    targetId: [[], [Validators.required]],
    message: [''],
    domainId: [null],
    activatedAt: [''],
    expiredAt: [''],
    templateId: ['', [Validators.required]],
    surveyId: [null],
    contactPhone: [''],
    ecommerceList: new FormArray([]),
    loyaltyCampaignType: [''],
    loyaltyCampaignObject: [''],
    loyaltyGiftCode: [''],
    packageId: [null, [Validators.required]],
    config: new FormGroup({
      logo: new FormControl(''),
      shape: new FormControl('classy'),
      color: new FormControl('#000000'),
      backgroundColor: new FormControl('#ffffff'),
    }),
    relatedTargetType: [''],
    setting: new FormGroup({
      requiredCustomerInfo: new FormControl(false),
      allowedScan: new FormControl(true),
      hideProduct: new FormControl(false),
      hideDistributor: new FormControl(false),
      hideOwner: new FormControl(false),
      hideRelatedShops: new FormControl(false),
      hideRelatedProducts: new FormControl(false),
      hideNotification: new FormControl(false),
      hidePrice: new FormControl(false),
      hideProductIntro: new FormControl(false),
      hideSku: new FormControl(false),
      hideVideo: new FormControl(false),
      hideCategory: new FormControl(false),
      hideDescription: new FormControl(false),
      hiddenBusiness: new FormControl(false),
      hiddenProductDetail: new FormControl(false),
      hideShop: new FormControl(false),
      hideCategories: new FormControl(false),
      hideProductDescription: new FormControl(false),
      hideContactButton: new FormControl(false),
      hiddenOwnerList: new FormControl([]),
      hiddenDistributorList: new FormControl([]),
      hiddenShopList: new FormControl([]),
      customerInfoForm: new FormControl(false),
    }),
  });
  isValidFormSubmitted = null;
  settingActiveCollapse = {
    showShape: true,
    showHideInfo: true,
    showOther: true,
    showBusiness: true,
    showColor: true,
    showLogo: true,
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',
    },
  };
  listBtnColors: Array<string> = [
    '#000000',
    '#EF6D46',
    '#8FC743',
    '#F390BC',
    '#83D1D5',
    '#6CB5E1',
    '#415CA0',
    '#7E62A1',
  ];
  listEcommerceImage: Array<any> = [[]];
  listTargetProduct: any = [];
  // listPackage = [];
  @Input() listPackage = [];
  @Input() isChangeStampTypeQRCode = false;
  tabs = [
    {id: 1, label: 'Preview'},
    {id: 2, label: 'QR Code'},
  ];
  isNewQr: true;
  @ViewChild('addProductRelated', {static: false}) addProductRelated;
  @ViewChild('deleteProductRelateds', {static: false}) deleteProductRelateds;
  heightEl = 500;
  // tslint:disable-next-line:max-line-length
  regUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  public config = configCommon;

  controls: any = this.form.controls;

  listYear = [
    {value: 1, label: '1 năm'},
    {value: 2, label: '2 năm'},
    {value: 3, label: '3 năm'},
  ];

  listTypeEcommerce = [
    {
      value: 1,
      label: 'Tiki',
    },
    {
      value: 2,
      label: 'Shopee',
    },
    {
      value: 4,
      label: 'Sendo',
    },
    {
      value: 3,
      label: 'Lazada',
    },
    {
      value: 0,
      label: 'Khác',
    },
  ];

  listSetting = [
    {
      value: 1,
      label: 'Yêu cầu thông tin khách hàng',
      key: 'requiredCustomerInfo',
    },
    {value: 2, label: 'Cho App khác quét', key: 'allowedScan'},
    {value: 3, label: 'Ẩn chi tiết sản phẩm', key: 'hideProduct'},
    {value: 4, label: 'Ẩn DN phân phối', key: 'hideDistributor'},
    {value: 5, label: 'Ẩn DN sở hữu', key: 'hideOwner'},
    {value: 6, label: 'Ẩn điểm bán', key: 'hideShop'},
    {value: 7, label: 'Ẩn sản phẩm liên quan', key: 'hideRelatedProducts'},
    {value: 8, label: 'Ẩn thông báo', key: 'hideNotification'},
    {value: 9, label: 'Ẩn giá', key: 'hidePrice'},
    {value: 10, label: 'Ẩn giới thiệu sản phẩm', key: 'hideProductIntro'},
  ];

  listStamp = [];
  totalPage = 0;
  page = 1;
  pageSize = 3;
  currentPage;
  firstTempale: any;

  id;
  templateId;

  isUpdate = false;
  errorExpiredAt = false;

  indeterminateDistributor = false;

  listRelatedProduct = [];

  firstTimeLoadTemplate = false;

  templateData: any = {};
  templateDataSubject: any;
  configData: any = {};
  templateConfigSubject: any;

  listSurveys = [];

  listDomain = [];

  validExpiredAt = true;
  showListRelatedProduct = false;

  indeterminateProductDetail = false;

  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  isShow: boolean;
  hideOwner = false;
  hideShop = false;
  hideDistributor = false;

  listProduct = [];

  currentPackage: any;

  isLoadingBtn = false;
  clickSubmit = false;

  shape: any;

  viewCurrentTemp;

  logos = [];

  totalTargetProduct = 0;
  qrcodeConfigPreview = null;
  loadingDrawQrcode;
  qrcodeConfig = {
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 1000,
    height: 1000,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const mapElement: any = document.querySelector(
      '.img-template'
    ) as HTMLElement;
    this.heightEl =
      mapElement && mapElement.offsetWidth ? mapElement.offsetWidth * 2 : 500;
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_form_product',
    //   contentType: 'qrcode',
    // })
    this.templateDataSubject = new BehaviorSubject(this.templateData);
    this.templateConfigSubject = new BehaviorSubject(this.configData);
    this.getListSurvey();
    this.getListDomainByUser();
    this.getListProduct();
    this.route.queryParams.subscribe((params) => {
      if (params.productId) {
        this.form.patchValue({
          targetId: [...params.productId.split(',')],
        });
      }
    });
    // this.temps = this.listTemp;
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== 'null') {
      this.isUpdate = true;
      this.getStampV2Detail(this.id);
    } else {
      // this.addFormEcommerceItem();
      this.isNewQr = true;
    }

    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    setTimeout(() => {
      this.getListTemplate();
    }, 800);
    this.changeValueForm();
    this.getListPackageActive();

    this.form.get('ecommerceList').valueChanges.subscribe(val => {
      console.log("val", val);
      this.isValidFormSubmitted = false;
    });
  }

  getStampV2Detail(id) {
    this.stampService.getStampById(id).subscribe(async res => {
      if (res.code === 1 && res.data) {
        const data = {
          ...res.data,
          newQr: true
        };
        this.isNewQr = data.newQr;
        let activatedAtConverted = '';
        if (data.activatedAt) {
          const activatedAt = data.activatedAt.split('/');
          // tslint:disable-next-line:variable-name
          activatedAtConverted = [
            activatedAt[1],
            activatedAt[0],
            activatedAt[2],
          ].join('/');
        }
        const expiredAt = data.expiredAt.split('/');
        // tslint:disable-next-line:variable-name
        const expiredAtConverted = [
          expiredAt[1],
          expiredAt[0],
          expiredAt[2],
        ].join('/');
        if (data.packageInfo && data.packageInfo.id) {
          this.listPackage = [{...data.packageInfo}];
          this.form.patchValue({
            packageId: data.packageInfo.id,
          });
        }
        this.form.patchValue({
          name: data.name,
          targetId: !this.isChangeStampTypeQRCode ? data.targetId : null,
          message: data.message,
          activatedAt: activatedAtConverted,
          expiredAt: expiredAtConverted,
          templateId: !this.isChangeStampTypeQRCode ? data.templateId : null,
          setting: data.setting,
          relatedTargetType: data.relatedTargetType,
          domainId: data.domainId,
          surveyId: data.surveyId || null,
        });
        // tslint:disable-next-line:no-unused-expression
        this.form.controls.setting.value.hideDistributor &&
        this.form.controls.setting.value.hideOwner &&
        this.form.controls.setting.value.hideShop;
        if (
          data.setting.hideDistributor &&
          data.setting.hideOwner &&
          data.setting.hideShop
        ) {
          this.form.patchValue({
            setting: {
              hiddenBusiness: true,
            },
          });
        } else if (
          data.setting.hideDistributor ||
          data.setting.hideOwner ||
          data.setting.hideShop
        ) {
          this.indeterminateDistributor = true;
        }
        if (
          data.setting.hideSku &&
          data.setting.hidePrice &&
          data.setting.hideVideo &&
          data.setting.hideCategories &&
          data.setting.hideProductDescription
        ) {
          this.form.patchValue({
            setting: {
              hiddenProductDetail: true,
            },
          });
        } else if (
          data.setting.hideSku ||
          data.setting.hidePrice ||
          data.setting.hideVideo ||
          data.setting.hideCategories ||
          data.setting.hideProductDescription
        ) {
          this.indeterminateProductDetail = true;
        }
        this.listRelatedProduct = data.relatedTargetData
          ? data.relatedTargetData.map((item) => {
            return {
              ...item,
              mainImage: item.image,
            };
          })
          : [];
        this.templateId = data.templateId;
        this.firstTempale = data.templateId;
        if (data.setting.buttonContact && data.setting.buttonContact.show) {
          this.form.patchValue({
            contactPhone: data.setting.buttonContact.url,
            setting: {
              hideContactButton: true,
            },
          });
          this.form.controls.contactPhone.setValidators([
            Validators.required,
          ]);
          this.form.updateValueAndValidity();
        } else {
          this.form.patchValue({
            contactPhone: '',
            setting: {
              hideContactButton: false,
            },
          });
        }
        if (data.targetId) {
          this.getListTargetProduct(
            data.targetId,
            data.setting.hiddenOwnerList,
            data.setting.hiddenDistributorList,
            data.setting.hiddenShopList
          );
        }
        if (data.loyaltyCampaign) {
          this.form.patchValue({
            loyaltyCampaignType: data.loyaltyCampaign.type,
            loyaltyCampaignObject: data.loyaltyCampaign.code,
            loyaltyGiftCode: data.loyaltyCampaign.giftCode,
          });
        }
        if (data.setting.ecommerceList) {
          data.setting.ecommerceList.forEach(
            (ecomItem: any, index: any) => {
              (this.form.get('ecommerceList') as FormArray).push(
                this.updateFormEcommerceItem(ecomItem)
              );
              const imgObj: any = {
                name: ecomItem.logo || 'image',
                type: 'image',
                url: '',
              };

              switch (ecomItem.type) {
                case 1:
                  imgObj.url = '../../../../../assets/tiki.png';
                  break;
                case 2:
                  imgObj.url = '../../../../../assets/shopee.png';
                  break;
                case 3:
                  imgObj.url = '../../../../../assets/lazada.png';
                  break;
                case 4:
                  imgObj.url = '../../../../../assets/sendo.png';
                  break;
                case 0:
                  imgObj.url = ecomItem.logo;
                  break;

                default:
                  break;
              }
              this.listEcommerceImage[index] = [imgObj];
            }
          );
        }
        if (data.newQr) {
          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType,
            data.config, data.frameConfig,
            data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(data.qrCode);
        } else {
          if (data.config && data.config.color) {
            this.form.get('config.color').setValue(data.config.color);
          }
          if (data.config && data.config.backgroundColor) {
            this.form
              .get('config.backgroundColor')
              .setValue(data.config.backgroundColor);
          }
          if (data.config && data.config.logo) {
            this.form.get('config.logo').setValue(data.config.logo);
            this.logos = [{url: data.config.logo}];
          }
          if (data.config && data.config.shape) {
            this.form.get('config.shape').setValue(data.config.shape);
          }
        }
      }
    });
  }

  getListSurvey() {
    const query = {
      page: 1,
      pageSize: 200,
      type: 'BASIC',
    };

    this.surveyService
      .getListSurveys(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listSurveys = res && res.data ? [...res.data] : [];
          } else {
            this.notification.warning(
              'Có lỗi xảy ra',
              res && res.message ? res.message : 'Vui lòng thử lại'
            );
          }
        },
        (err: any) => {
          this.notification.error(
            'Có lỗi xảy ra',
            err && err.message ? err.message : 'Vui lòng thử lại'
          );
        }
      );
  }

  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }

  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }

  getListPackageActive() {
    // this.stampService.getListPackageActive({type: 1, }).subscribe((res) => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }

  getListDomainByUser() {
    const query: any = {
      page: 1,
      pageSize: 100,
    };

    this.stampService
      .getListDomain(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  changeListRelatedProduct(list) {
  }

  deleteSetting(id) {
    const data = {
      label: 'Xác nhận xóa sản phẩm liên quan',
      value:
        ' <p class="mrb-10">Hệ thống xóa sản phẩm liên quan này.</p>\n' +
        '          <p>Bạn có chắc chắn muốn xoá?</p>',
    };
    this.deleteProductRelateds.showModal(data, id);
  }

  handleDeleteRelateds(event) {
    this.listRelatedProduct = this.listRelatedProduct.filter(
      (item) => item.id !== event[0]
    );
    this.templateData.customRelatedProducts = [...this.listRelatedProduct];
    this.templateDataSubject.next(this.templateData);
    this.deleteProductRelateds.success();
    this.deleteProductRelateds.handleCancel();
    this.notification.success(
      'Thành công',
      'Xóa sản phẩm liên quan thành công'
    );
  }

  changeValueForm() {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
      });
    this.form
      .get('expiredAt')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.validExpiredAt = !(this.form.value.activatedAt &&
          formatDate(val, 'yyyy-MM-dd', 'en_US') <=
          formatDate(this.form.value.activatedAt, 'yyyy-MM-dd', 'en_US'));
      });
    this.form
      .get('relatedTargetType')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.showListRelatedProduct = val === 2;
      });
    this.form
      .get('setting')
      .get('hideContactButton')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        if (val) {
          this.form.controls.contactPhone.setValidators([Validators.required]);
          this.form.updateValueAndValidity();
        } else {
          this.form.get('contactPhone').clearValidators();
          this.form.get('contactPhone').updateValueAndValidity();
        }
      });

    this.form.valueChanges.subscribe((val: any) => {
      this.templateData = {
        ...this.form.value,
        setting: {...val.setting},
        productId: Array.isArray(val.targetId) ? val.targetId[0] : val.targetId,
        relatedProductType: val.relatedTargetType || null,
        customRelatedProducts: this.listRelatedProduct
          ? [...this.listRelatedProduct]
          : [],
        listTargetProduct: this.listTargetProduct
          ? [...this.listTargetProduct]
          : [],
        listEcommerces: val.ecommerceList || [],
        message: val.message,
      };
      this.templateDataSubject.next(this.templateData);
    });

    this.form.get('config').valueChanges.subscribe((val: any) => {
      this.configData = {
        ...this.form.value,
        setting: {...val.setting},
        productId: Array.isArray(val.targetId) ? val.targetId[0] : val.targetId,
        relatedProductType: val.relatedTargetType || null,
        customRelatedProducts: this.listRelatedProduct
          ? [...this.listRelatedProduct]
          : [],
        listTargetProduct: this.listTargetProduct
          ? [...this.listTargetProduct]
          : [],
        listEcommerces: val.ecommerceList || [],
        message: val.message,
        config: {...this.form.get('config').value},
      };
      this.templateConfigSubject.next(this.configData);
    });

    // this.form.controls.setting.get('hiddenBusiness').valueChanges.subscribe(val => {
    //   if (val === true) {
    //     this.form.patchValue({
    //       setting: {
    //       hideDistributor: true,
    //       hideOwner: true,
    //       hideShop: true
    //     }})
    //   } else {
    //     this.form.patchValue({
    //       setting: {
    //       hideDistributor: false,
    //       hideOwner: false,
    //       hideShop: false
    //     }})
    //     this.indeterminateDistributor = false;
    //   }
    // });
    // this.form.controls.setting.get('hiddenProductDetail').valueChanges.subscribe(val => {
    //   if (val === true) {
    //     this.form.patchValue({
    //       setting: {
    //         hideSku: true,
    //         hidePrice: true,
    //         hideVideo: true,
    //         hideCategories: true,
    //         hideProductDescription: true
    //     }})
    //   } else {
    //     this.form.patchValue({
    //       setting: {
    //         hideSku: false,
    //         hidePrice: false,
    //         hideVideo: false,
    //         hideCategories: false,
    //         hideProductDescription: false
    //     }})
    //   }
    // });

    // this.form.controls.setting.valueChanges.subscribe(val => {
    //   if (val.hideDistributor ||
    //     val.hideOwner ||
    //     val.hideShop ) {
    //       this.form.patchValue({
    //         setting: {
    //         hiddenBusiness: true,
    //       }})
    //       this.indeterminateDistributor = false;
    //   } else if (val.hideDistributor &&
    //     val.hideOwner &&
    //     val.hideShop) {
    //       this.indeterminateDistributor = true;
    //   } else {
    //     this.indeterminateDistributor = false;
    //     this.form.patchValue({
    //       setting: {
    //       hiddenBusiness: false,
    //     }})
    //   }
    // })
  }

  changeStatusHiddenBusiness() {
    if (
      this.form.controls.setting.value.hideDistributor &&
      this.form.controls.setting.value.hideOwner &&
      this.form.controls.setting.value.hideShop
    ) {
      this.form.patchValue({
        setting: {
          hiddenBusiness: true,
        },
      });
      this.indeterminateDistributor = false;
    } else if (
      this.form.controls.setting.value.hideDistributor ||
      this.form.controls.setting.value.hideOwner ||
      this.form.controls.setting.value.hideShop
    ) {
      this.indeterminateDistributor = true;
    } else {
      this.indeterminateDistributor = false;
      this.form.patchValue({
        setting: {
          hiddenBusiness: false,
        },
      });
    }
  }

  changeStatusHiddenProductDetail() {
    if (
      this.form.controls.setting.value.hidePrice &&
      this.form.controls.setting.value.hideVideo &&
      // this.form.controls.setting.value.hideCategories &&
      this.form.controls.setting.value.hideProductDescription
    ) {
      this.form.patchValue({
        setting: {
          hiddenProductDetail: true,
        },
      });
      this.indeterminateProductDetail = false;
    } else if (
      this.form.controls.setting.value.hidePrice ||
      this.form.controls.setting.value.hideVideo ||
      // this.form.controls.setting.value.hideCategories ||
      this.form.controls.setting.value.hideProductDescription
    ) {
      this.indeterminateProductDetail = true;
    } else {
      this.indeterminateProductDetail = false;
      this.form.patchValue({
        setting: {
          hiddenProductDetail: false,
        },
      });
    }
  }

  checkAllHiddenBusiness() {
    if (
      this.form.controls.setting.value.hideDistributor &&
      this.form.controls.setting.value.hideOwner &&
      this.form.controls.setting.value.hideShop
    ) {
      this.form.patchValue({
        setting: {
          hiddenBusiness: false,
          hideDistributor: false,
          hideOwner: false,
          hideShop: false,
        },
      });
      this.indeterminateDistributor = false;
    } else {
      this.form.patchValue({
        setting: {
          hiddenBusiness: true,
          hideDistributor: true,
          hideOwner: true,
          hideShop: true,
        },
      });
      this.indeterminateDistributor = false;
    }
  }

  checkAllHiddenProduct() {
    if (
      this.form.controls.setting.value.hidePrice &&
      this.form.controls.setting.value.hideVideo &&
      // this.form.controls.setting.value.hideCategories &&
      this.form.controls.setting.value.hideProductDescription
    ) {
      this.form.patchValue({
        setting: {
          hiddenProductDetail: false,
          // hideSku: false,
          hidePrice: false,
          hideVideo: false,
          // hideCategories: false,
          hideProductDescription: false,
        },
      });
      this.indeterminateProductDetail = false;
    } else {
      this.form.patchValue({
        setting: {
          hiddenProductDetail: true,
          // hideSku: true,
          hidePrice: true,
          hideVideo: true,
          // hideCategories: true,
          hideProductDescription: true,
        },
      });
      this.indeterminateProductDetail = false;
    }
  }

  getDate(date, numberInput) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (numberInput === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.businessProductId] = value)
    );
    this.refreshStatus();
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.businessProductId]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.businessProductId]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value === true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  updateStatus(type) {
    if (type === 'hidden') {
      this.listTargetProduct.map((item) => {
        if (this.ids.includes(item.businessProductId)) {
          item.isShow = false;
        }
        return item;
      });
    } else {
      this.listTargetProduct.map((item) => {
        if (this.ids.includes(item.businessProductId)) {
          item.isShow = true;
        }
        return item;
      });
    }
    this.mapOfCheckedId = {};
  }

  getListProduct() {
    const query: any = {
      page: 1,
      pageSize: 2000,
      hidden: false,
    };

    this.productService
      .getAll(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listProduct = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  getListTemplate() {
    const body =
      this.templateId && this.isUpdate
        ? {
          page: this.page,
          pageSize: this.pageSize,
          type: 1,
          toTop: this.firstTempale,
        }
        : {
          page: this.page,
          pageSize: this.pageSize,
          type: 1,
        };
    this.stampService.getAllTemplate(body).subscribe((res) => {
      if (res.code === 1) {
        this.listStamp = res.data && res.data.length ? res.data.sort() : [];
        this.totalPage = Math.ceil(res.totalElement / res.pageSize);
        this.currentPage = res.currentPage;
        if (!this.firstTimeLoadTemplate) {
          this.firstTimeLoadTemplate = true;
        }
        if (!this.viewCurrentTemp) {
          this.viewCurrentTemp = this.listStamp.filter(
            (item) => item.id === this.templateId
          )[0];
        }
      }
    });
  }

  showAddProductRelated() {
    let listTargetId = this.listRelatedProduct.map((item) => item.id);
    const formTargetId = this.form.value.targetId;
    if (formTargetId) {
      if (Array.isArray(formTargetId)) {
        listTargetId = listTargetId.concat([...formTargetId]);
      } else {
        listTargetId.push(this.form.value.targetId);
      }
    }
    this.addProductRelated.showModal(listTargetId);
  }

  successAddProductRelated(event) {
    this.listRelatedProduct = [...this.listRelatedProduct, ...event];
    this.templateData.customRelatedProducts = [...this.listRelatedProduct];
    this.templateDataSubject.next(this.templateData);
    this.addProductRelated.handleCancel();
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  nextPage() {
    if (this.page === this.totalPage) {
      return;
    }
    this.page += 1;
    this.getListTemplate();
  }

  prevPage() {
    if (this.page === 1) {
      return;
    }
    this.page -= 1;
    this.getListTemplate();
  }

  selectedPackage(event) {
    if (event) {
      this.currentPackage = this.listPackage.find((item) => item.id === event);
    }
  }

  convertBase64ToUrl(base64): any {
    const formData = new FormData();
    formData.append('content', base64toBlob(base64), 'image-product-description.png');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.uploadService.uploadFile(formData, currentUser).toPromise().then(
      (result) => {
        if (result.status === 200 && result.data && result.data.url) {
          return result.data.url;
        }
      }
    );
  }

  async submit() {
    this.isValidFormSubmitted = false;
    this.clickSubmit = true;
    if (this.form.invalid) {
      this.templateQRCodeViewRef.onChangeTabActive();
      return;
    }
    this.isValidFormSubmitted = true;
    this.isLoadingBtn = true;
    const newForm = {
      ...this.form.value,
      activatedAt: this.form.value.activatedAt
        ? this.getDate(this.form.value.activatedAt, 1)
        : '',
      expiredAt: this.form.value.expiredAt
        ? this.getDate(this.form.value.expiredAt, 1)
        : this.currentPackage.expiredAt,
      type: 1,
      relatedTargetId:
        this.form.value.relatedTargetType === 2
          ? this.listRelatedProduct.map((item) => item.id)
          : [],
      relatedTargetType: this.form.value.relatedTargetType ? this.form.value.relatedTargetType : null
    };
    newForm.setting.hiddenDistributorList = [];
    newForm.setting.hiddenOwnerList = [];
    newForm.setting.hiddenShopList = [];
    newForm.setting.buttonContact = {
      show: this.form.value.setting.hideContactButton,
      url: this.form.value.contactPhone || '',
    };
    newForm.setting.ecommerceList = this.form.value.ecommerceList;
    newForm.setting.phoneNumber = this.form.value.contactPhone;
    if (newForm.message) {
      if (isIncludeBase64Image(newForm.message)) {
        const matchedValues = newForm.message.match(regexBase64Image);
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < matchedValues.length; j++) {
          try {
            const url = await this.convertBase64ToUrl(matchedValues[j]);
            newForm.message = newForm.message.replace(matchedValues[j], url);
          } catch (e) {
            newForm.message = newForm.message.replace(matchedValues[j], '');
          }
        }
      }
    }
    if (this.isNewQr) {
      newForm.config = {...this.qrcodeDesignComponentRef.getQrcodeDesignConfig()};
      newForm.qrcodeFrameId = this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
    }
    if (this.listTargetProduct) {
      this.listTargetProduct.forEach((gg: any) => {
        if (!gg.isShow) {
          if (gg.roleCode === 'DISTRIBUTOR') {
            newForm.setting.hiddenDistributorList.push(gg.businessProductId);
          }
          if (gg.roleCode === 'OWNER') {
            newForm.setting.hiddenOwnerList.push(gg.businessProductId);
          }
          if (gg.roleCode === 'SHOP') {
            newForm.setting.hiddenShopList.push(gg.businessProductId);
          }
        }
      });
    }
    if (this.isUpdate) {
      if (this.isNewQr) {
        const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
        const formData = new FormData();
        formData.append('file', base64QrcodeSvg);
        const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
        if (qrImageResponse.status === 200) {
          newForm.qrImage = qrImageResponse.data || '';
        }
        this.stampService
          .update(this.id, newForm)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            if (res.code === 1) {
              this.openMessage('success', 'Thành công', 'Cập nhật thành công');
              let delayTimer: any;
              clearTimeout(delayTimer);
              delayTimer = setTimeout(() => {
                this.router.navigate(['/portal/stamp']);
                return;
              }, 500);
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
            this.isLoadingBtn = false;
          }, error => {
            this.isLoadingBtn = false;
            this.openMessage('error', 'Lỗi !!!', `${error}`);
          });
      } else {
        const qrcodeData = `${environment.redirectScanUrl}/${this.id}`;
        const tmpQrcodeConfig = {
          ...this.qrcodeConfig,
          backgroundOptions: {
            color: this.form.get('config.backgroundColor').value
          },
          dotsOptions: {
            type: this.form.get('config.shape').value,
            color: this.form.get('config.color').value,
          },
          image: this.form.get('config.logo').value,
          data: qrcodeData,
        };
        const qrcodeInstance = new QRCodeStyling(tmpQrcodeConfig as any);
        const rawQrcodeImage = await qrcodeInstance.getRawData();
        const formData = new FormData();
        formData.append('content', rawQrcodeImage, 'qrcode.png');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.uploadService.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (result) => {
            if (result.status === 200 && result.data && result.data.url) {
              newForm.qrImage = result.data.url;
              this.stampService
                .update(this.id, newForm)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  if (res.code === 1) {
                    this.openMessage('success', 'Thành công', 'Cập nhật thành công');
                    let delayTimer: any;
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(() => {
                      this.router.navigate(['/portal/stamp']);
                      return;
                    }, 500);
                  } else {
                    this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                  }
                  this.isLoadingBtn = false;
                }, error => {
                  this.isLoadingBtn = false;
                  this.openMessage('error', 'Lỗi !!!', `${error}`);
                });
            }
          },
          (err) => {
            this.openMessage('error', 'Cập nhật không thành công', `Vui lòng thử lại`);
          }
        );
      }
    } else {
      if (newForm.targetId && newForm.targetId.length > 5) {
        this.isLoadingBtn = false;
        return this.notification.warning('Vui lòng thử lại', 'Chỉ được tạo tối đa 5 QR Code');
      }
      newForm.productData = newForm.targetId.map(item => ({
        productId: item,
        qrImage: '',
        qrCode: '',
      }));
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < newForm.productData.length; i++) {
        const formData = new FormData();
        const base64QrcodeSvg = await this.qrcodeDesignComponentRef.drawAndSvgToDataUrl();
        newForm.productData[i].qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
        formData.append('file', base64QrcodeSvg);
        const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
        if (qrImageResponse.status === 200) {
          newForm.productData[i].qrImage = qrImageResponse.data || '';
        }
      }
      delete newForm.targetId;
      this.stampService
        .createMulti(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track("alert", {
            //   alertType: "success",
            //   alertMessage: "Thêm mới QR Code thành công"
            // });
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/stamp']);
              return;
            }, 500);
          } else {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.isLoadingBtn = false;
        }, error => {
          this.isLoadingBtn = false;
          this.openMessage('error', 'Lỗi !!!', `${error}`);
        });
    }
  }

  resetAndGetQrcodeCode() {
    this.qrcodeDesignComponentRef.generateQrcodeCode();
    return this.qrcodeDesignComponentRef.getQrcodeCode();
  }

  selectedQrCode(value) {
    if (this.form.get('config.shape').value !== value) {
      this.form.get('config.shape').setValue(value);
    } else {
      this.form.get('config.shape').setValue('');
    }
  }

  changePage(event) {
  }

  selectTemplate(stamp) {
    this.templateId = stamp.id;
    this.form.controls.templateId.setValue(stamp.id);
    this.templateDataSubject.next(this.templateData);
  }

  viewTemplate(stamp) {
    this.viewCurrentTemp = stamp;
  }

  back() {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_stamp_form_product',
    //   contentName: 'cancel',
    //   target: `${window.location.origin}/portal/stamp`,
    // })
    this.router.navigate(['/portal/stamp']);
  }

  initFormEcommerceItem() {
    return this.fb.group({
      logo: [''],
      name: ['', [Validators.required]],
      link: ['', [Validators.required, Validators.pattern(this.regUrl)]],
      price: [''],
      priceDiscount: [''],
      type: [0],
    });
  }

  updateFormEcommerceItem(data: any) {
    return this.fb.group({
      logo: [data.logo],
      name: [data.name, [Validators.required]],
      link: [data.link, [Validators.required, Validators.pattern(this.regUrl)]],
      price: [data.price],
      priceDiscount: [data.priceDiscount],
      type: [data.type],
    });
  }

  addFormEcommerceItem() {
    (this.form.get('ecommerceList') as FormArray).push(
      this.initFormEcommerceItem()
    );
    this.listEcommerceImage.push([]);
  }

  removeFormEcommerceItem(index: number) {
    (this.form.get('ecommerceList') as FormArray).removeAt(index);
    this.listEcommerceImage.splice(index, 1);
  }

  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp,mp4,pdf,docx';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logos') {
            this.logos = [{url: changeParam.file.response.url}];
            this.form.get('config.logo').setValue(this.logos[0].url);
            this.form.updateValueAndValidity();
          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  onSelectColor(color: string) {
    if (this.form.value.config.color !== color) {
      this.form.get('config.color').setValue(color);
    } else {
      this.form.get('config.color').setValue('');
    }
  }

  onSelectbackgroundColor(color: string) {
    if (this.form.value.config.backgroundColor !== color) {
      this.form.get('config.backgroundColor').setValue(color);
    } else {
      this.form.get('config.backgroundColor').setValue('');
    }
    // this.form.get("config.backgroundColor").setValue(color);
  }

  openUploadDialogEcomImage(index: number, field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          (this.form.controls.ecommerceList as FormArray).controls[index]
            .get('logo')
            .setValue(data.url);
          this.listEcommerceImage[index][0] = data;
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe((changeParam) => {
          modal.close();
        });
    });
  }

  onRemoveFileEcomImage(field: string) {
    this.listEcommerceImage[field] = [];
  }

  onRemoveFile(field: string) {
    this.logos = [];
    this.form.get('config.logo').setValue('');
  }

  onChangeInfoForm(e) {
    this.form.get('setting.requiredCustomerInfo').setValue(e);
  }

  onChangeTypeEcommerce(value: FormControl, index: number) {
    const type = value.get('type').value;

    switch (type) {
      case 1:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/tiki.png',
        };
        value.get('name').setValue('Tiki');
        break;
      case 2:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/shopee.png',
        };
        value.get('name').setValue('Shopee');
        break;
      case 3:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/lazada.png',
        };
        value.get('name').setValue('Lazada');
        break;
      case 4:
        this.listEcommerceImage[index][0] = {
          name: 'image',
          type: 'image',
          url: '../../../../../assets/sendo.png',
        };
        value.get('name').setValue('Sendo');
        break;
      case 0:
        this.listEcommerceImage[index] = [];
        value.get('name').setValue('');
        value.get('logo').setValue('');
        break;

      default:
        break;
    }
  }

  getListTargetProduct(
    productId: string,
    hiddenOwnerList: any,
    hiddenDistributorList: any,
    hiddenShopList: any
  ) {
    const query = {
      status: 1,
      page: 1,
      pageSize: 100,
      productId,
    };
    this.stampService
      .getListTargetProduct(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listTargetProduct = res.data ? [...res.data] : [];
          this.totalTargetProduct = res.totalElement || 0;
          if (res.data) {
            this.listTargetProduct = res.data.map((gg: any) => {
              const tmp = Object.assign({}, gg);
              tmp.isShow = !((hiddenOwnerList &&
                  hiddenOwnerList.includes(gg.businessProductId) &&
                  gg.roleCode === 'OWNER') ||
                (hiddenDistributorList &&
                  hiddenDistributorList.includes(gg.businessProductId) &&
                  gg.roleCode === 'DISTRIBUTOR') ||
                (hiddenShopList &&
                  hiddenShopList.includes(gg.businessProductId) &&
                  gg.roleCode === 'SHOP'));
              return tmp;
            });
            this.templateData.listTargetProduct = [...this.listTargetProduct];
            this.templateDataSubject.next(this.templateData);
          }
        }
      });
  }

  onChangeTargetId(e: any) {
    if (e) {
      this.getListTargetProduct(Array.isArray(e) ? e[0] : e, null, null, null);
    } else {
      this.listTargetProduct = [];
      this.templateData.listTargetProduct = [...this.listTargetProduct];
      this.templateDataSubject.next(this.templateData);
    }
  }

  changeShowTargetProduct(data: any) {
    data.isShow = !data.isShow;
    this.templateData.listTargetProduct = [...this.listTargetProduct];
    this.templateDataSubject.next(this.templateData);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isShowLink: boolean = false;
  isShowBusiness: boolean = false;

  onToggleConfig(type: string) {
    if (type === 'config') {
      this.isShowLink = !this.isShowLink;
      this.isShowBusiness = this.isShowLink ? false : true;
    } else {
      this.isShowBusiness = !this.isShowBusiness;
      this.isShowLink = this.isShowBusiness ? false : true;
    }
  }
}
