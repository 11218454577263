import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingMessageService extends BaseService {

  getAll(query?: any): Observable<any> {
    return this.get<any>('/configuration/message', { params: query });
  }

  getAllCustomer(query?: any): Observable<any> {
    return this.get<any>('/customer/group', { params: query });
  }

  getById(id: string): Observable<any> {
    return this.get<any>(`/configuration/message/${id}`);
  }

  create(body: any): Observable<any> {
    return this.post<any>(`/configuration/message`, body);
  }

  update(id: string, body: any): Observable<any> {
    return this.put<any>(`/configuration/message/${id}`, body);
  }

  destroy(id: string): Observable<any> {
    return this.delete<any>(`/configuration/message/${id}`);
  }

}
