import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {convertDateToStringQuery, removeEmptyQuery, sleep} from 'src/app/helpers';
import { ProductsService, RootService } from 'src/app/services';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { UserDetailModalComponent } from './user-detail-modal/user-detail-modal.component';
import { UserExportComponent } from './user-export/user-export.component';
import { formatDate } from '@angular/common';
import { track } from 'src/app/helpers/track';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  constructor(
    private productService: ProductsService,
    private rootService: RootService,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private stampService: StampService
  ) {}

  unsubscribe$ = new Subject<void>();
  isAdmin = false;
  // @ts-ignore
  @ViewChild('updateProduct') updateProduct;
  // @ts-ignore
  @ViewChild('deleteFlashProducts') deleteFlashProducts;
  @ViewChild('appUserDetailModal', { static: true })
  appUserDetailModal: UserDetailModalComponent;
  @ViewChild('appUserExport', { static: true })
  appUserExport: UserExportComponent;
  subscriptions: any = [];

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading: Boolean = true;
  page = 1;
  pageSize = 10;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: Boolean;
  products = [];

  query: string;
  name: string;
  phone: string;
  email: string;
  user_source;
  provinceId;
  district;
  productId;
  sku: string;
  subscriptionId: number;
  infoStatus: number;
  status: number;
  managementStatus: number;
  delayTimer: any;
  owner: string;
  date;
  actionTime;
  formatDate = formatDate;
  managementStatuses: any[] = [];
  showPaging = true;

  statuses = [
    { id: 1, label: 'Kích hoạt' },
    { id: 2, label: 'Hủy kích hoạt' },
  ];

  /*Check link image error*/
  imageFalse: any[] = [];

  dataDelete = {
    label: 'Xác nhận xóa QR Code',
    value: '<p>Bạn có chắc chắn muốn xoá QR Code?</p>',
  };

  isLoadingBtn: Boolean = false;

  @ViewChild('addProduct', { static: true }) addProduct;
  @ViewChild('deleteProducts', { static: true }) deleteProducts;
  action: any;

  listCity = [];

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }

  clearPosParam() {
    this.router.navigate(['.'], { relativeTo: this.route });
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_customer',
    //   contentType: 'others',
    // })
    this.getCities();
    this.route.queryParams.subscribe((params) => {
      if (params.query !== undefined) {
        this.clearPosParam();
        this.query = params.query;
      }
      this.loadData();
    });
    // this.loadDataSubscription();
    // this.loadManagementStatus();
    const user = localStorage.getItem('currentUser');
    // @ts-ignore
    // this.isAdmin = JSON.parse(user).isAdmin;
    // @ts-ignore
  }
  getCities() {
    const query = {
      type: 1, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : 0
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.listCity = res.data;
      },
      (error) => {
        this.listCity = [];
      }
    );
  }

  loadDataSubscription() {
    this.productService.getAllSubscription2().subscribe((res) => {
      this.subscriptions = res.data;
    });
  }

  transformEntry(item, type) {
    switch (type) {
        case 'email':
            const parts = item.split('@'), len = parts[0].length;
            return item.replace(parts[0].slice(1, -1), '*'.repeat(len - 2));
        case 'phone':
            return item[0] + '*'.repeat(item.length - 4) + item.slice(-3);
       default:
            throw new Error('Undefined type: ' + type);
    }
}

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.id]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.id] = value)
    );
    this.refreshStatus();
  }
  search() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.loadData();
    }, 300);
  }

  filterSubscriptions($event: number) {
    this.subscriptionId = $event;
    this.page = 1;
    this.loadData();
  }

  loadManagementStatus() {
    this.productService
      .getAllManagementStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.managementStatuses = res.data;
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  filterManagementStatus($event: any) {
    this.managementStatus = $event;
    this.page = 1;
    this.loadData();
  }

  filterStatus($event: any) {
    this.status = $event;
    this.page = 1;
    this.loadData();
  }

  filterInfoStatus($event: any) {
    this.infoStatus = $event;
    this.page = 1;
    this.loadData();
  }

  async loadData(ms = 0) {
    const query: any = {
      name: this.name ? this.name.trim() : null,
      phone: this.phone ? this.phone.trim() : null,
      email: this.email ? this.email.trim() : null,
      actionTimeFrom: this.actionTime && this.actionTime[0] ? convertDateToStringQuery(this.actionTime[0]) : '',
      actionTimeTo: this.actionTime && this.actionTime[1] ? convertDateToStringQuery(this.actionTime[1]) : '',
      provinceId: this.provinceId,
      page: this.page,
      pageSize: this.pageSize
    };



    removeEmptyQuery(query);
    await sleep(ms);
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_customer',
    //   contentName: 'search',
    //   target: `${window.location.origin}/portal/customer`,
    //   payload: JSON.stringify(query)
    // })
    // track('siteSearch', {
    //   keywords: "portal_customer",
    //   params: JSON.stringify(query)
    // })
    // track('siteSearch', JSON.stringify(query));
    this.loading = true;
    this.stampService
      .getListCustomer(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false;
          this.total = res.totalElement;
          this.listOfAllData = res.data.map(item => {
            item.fullAdress = [item.address, item.ward, item.district, item.province, item.country].filter(e => typeof e === 'string' && e !== '').join(', ');
            return item;
          });

          if (this.total <= 10) {
            this.showPaging = false;
          } else {
            this.showPaging = true;
          }

          if (!res.data && this.page > 1) {
            this.page = this.page - 1;
            this.loadData();
            return;
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  resetData() {
    this.name = null;
    this.phone = null;
    this.email = null;
    this.provinceId = null;
    this.actionTime = null;
    this.loadData();
  }

  showDetail(item) {
    this.appUserDetailModal.showModal(item);
  }

  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, '_blank');
    }
  }

  exportGLN() {
    const query: any = {
      name: this.name ? this.name.trim() : '',
      phone: this.phone ? this.phone.trim() : '',
      email: this.email ? this.email.trim() : '',
      provinceId: this.provinceId ? this.provinceId : ''
    };
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_customer',
    //   contentName: 'export',
    //   target: `${window.location.origin}/portal/customer`,
    //   payload: JSON.stringify(query),
    // })
    this.appUserExport.showModal(query);
  }

  delete(id: any) {
    const body = {
      body: {
        ids: id,
      },
    };
    this.productService
      .destroy(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              'success',
              'Thành công',
              'Xóa sản phẩm thành công'
            );
            this.deleteProducts.handleCancel();
            this.loadData(500);
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.deleteProducts.success();
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.deleteProducts.success();
          return;
        }
      );
  }

  hidden(id: any, isHidden: Boolean) {
    this.isLoadingBtn = true;
    this.productService
      .hidden(isHidden, id.toString())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.isLoadingBtn = false;
          if (res.code === 1 && isHidden == true) {
            this.mapOfCheckedId = {};
            this.openMessage('success', 'Thành công', 'Ẩn sản phẩm thành công');
            this.loadData(500);
            return;
          }
          if (res.code === 1 && isHidden == false) {
            this.mapOfCheckedId = {};
            this.openMessage(
              'success',
              'Thành công',
              'Hiện sản phẩm thành công'
            );
            this.loadData(500);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.isLoadingBtn = false;
          return;
        }
      );
  }

  import(data: any) {
    const is_check_gln = data.is_check_gln;
    const is_validate_gtin = data.is_validate_gtin;
    delete data.is_check_gln;
    delete data.is_validate_gtin;
    const formData = new FormData();
    formData.append('file', data.body);
    this.productService
      .importFile(data.subId, formData, is_check_gln, is_validate_gtin)
      .subscribe(
        (res) => {
          this.addProduct.success();
          if (res.code === 1) {
            if (res.data) {
              const arrReason = res.data.filter((data) => {
                if (data.success == false) {
                  return data;
                }
              });
              if (arrReason.length > 0) {
                const barcodes: any[] = [];
                for (const data of arrReason) {
                  barcodes.push(data.barcode);
                }
                this.openMessage(
                  'warning',
                  'Cảnh báo',
                  `<p>Đã tồn tại một yêu cầu quản lý đối với sản phẩm này, vui lòng liên hệ admin để được hỗ trợ</p> <span>Barcode: ${barcodes.join(
                    ', '
                  )}</span>`
                );
              }
              const arrSuccess = res.data.filter((data) => {
                if (data.success == true) {
                  return data;
                }
              });
              if (arrSuccess.length > 0) {
                const barcodes: any[] = [];
                for (const data of arrSuccess) {
                  barcodes.push(data.barcode);
                }
                this.openMessage(
                  'success',
                  'Thành công',
                  `<p>Import sản phẩm thành công</p> <span>Barcode: ${barcodes.join(
                    ', '
                  )}</span>`
                );
              }
            } else {
              this.openMessage(
                'success',
                'Thành công',
                'Import sản phẩm thành công'
              );
            }
            this.loadData(1000);
            this.addProduct.handleCancel();
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.addProduct.success();
          return;
        }
      );
  }

  addBarcodes(data: any) {
    const body: any = {
      barcodeList: data.barcodes,
      subId: data.subId,
    };
    this.productService
      .create(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.addProduct.success();
          if (res.code === 1) {
            const arrReason = res.data.filter((data) => {
              if (data.success == false) {
                return data;
              }
            });
            if (arrReason.length > 0) {
              const barcodes: any[] = [];
              const tmpReason: any = [
                {
                  message: arrReason[0].reason,
                  barcode: [arrReason[0].barcode],
                },
              ];
              for (let i = 1; i < arrReason.length; i++) {
                const gg = arrReason[i];
                tmpReason.forEach((tmp) => {
                  if (tmp.message == gg.reason) {
                    if (tmp.barcode.indexOf(gg.barcode) == -1) {
                      tmp.barcode.push(gg.barcode);
                    }
                  } else {
                    tmpReason.push({
                      message: gg.reason,
                      barcode: [...gg.barcode],
                    });
                  }
                });
              }
              for (const data of tmpReason) {
                // barcodes.push(data.barcode);
                this.openMessage(
                  'warning',
                  'Cảnh báo',
                  `<p>${
                    data.message
                  }</p> <span><b>Barcode</b>: ${data.barcode.join(', ')}</span>`
                );
              }
              // this.openMessage('warning', 'Cảnh báo', `<p>${data}</p> <span>Barcode: ${barcodes.join(', ')}</span>`);
            }
            const arrSuccess = res.data.filter((data) => {
              if (data.success == true) {
                return data;
              }
            });
            if (arrSuccess.length > 0) {
              const barcodes: any[] = [];
              for (const data of arrSuccess) {
                barcodes.push(data.barcode);
              }
              this.openMessage(
                'success',
                'Thành công',
                `<p>Tạo sản phẩm thành công</p> <span>Barcode: ${barcodes.join(
                  ', '
                )}</span>`
              );
            }
            this.loadData(1000);
            this.addProduct.handleCancel();
            return;
          }
          if (res.code === 1007) {
            this.openMessage('warning', 'Cảnh báo', `Gói đã hết sản phẩm`);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            return;
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.addProduct.success();
          return;
        }
      );
  }

  exportExcel() {
    const query: any = {
      export_all: this.ids.length === 0,
      product_name: this.sku ? this.sku.trim() : null,
      query: this.query ? this.query.trim() : null,
      subscription_id: this.subscriptionId ? this.subscriptionId : null,
      management_status: this.managementStatus,
      status: this.status,
      info_status: this.infoStatus,
      ids: this.ids ? this.ids.toString() : null,
    };

    removeEmptyQuery(query);

    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_customer',
    //   contentName: 'export',
    //   target: `${window.location.origin}/portal/customer`,
    //   payload: JSON.stringify(query)
    // })

    this.productService
      .exportExcel(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.rootService.export(data, 'Danh sách sản phẩm.xlsx');
      });
  }

  actionsButton() {
    if (this.action == 1) {
      this.addProduct.showModal();
    } else if (this.action == 2) {
      this.deleteProducts.showModal(this.dataDelete, this.ids);
    } else if (this.action == 3) {
      this.hidden(this.ids, true);
    } else if (this.action == 4) {
      this.hidden(this.ids, false);
    } else if (this.action == 5) {
      this.exportExcel();
    }
    this.action = null;
  }
  updateProductFromExcel() {
    this.updateProduct.showModal();
  }
  handleupdateProductFromExcel(event) {
    const formData = new FormData();
    formData.append('file', event.file);
    const query = event.params;
    this.productService
      .updateProductFromExcel(query, formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            this.notification.success(
              'Thành công',
              'Cập nhật thông tin sản phẩm thành công'
            );
            this.updateProduct.cancelLoadingBtn();
            this.updateProduct.handleCancel();
            this.loadData(1000);
            return;
          }
          this.notification.error('Có lỗi xảy r', res.message);
          this.updateProduct.cancelLoadingBtn();
        },
        (error) => {
          this.notification.error('Có lỗi xảy r', error.message);
          this.updateProduct.cancelLoadingBtn();
        }
      );
  }
  deleteFlashProduct() {
    this.deleteFlashProducts.showModal();
  }
  handleDeleteFlashProducts(data) {
    const body = data.value;
    this.productService
      .deleteFlashProductByBarcode({ body })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.deleteFlashProducts.cancelLoading();
          if (res.code === 1) {
            this.notification.success('Thành công', 'Xóa sản phẩm thành công');
            this.deleteFlashProducts.handleCancel();
            this.loadData(500);
            return;
          }
          this.notification.error('Có lỗi xảy ra', res.message);
        },
        (error) => {
          this.notification.error('Có lỗi xảy ra', error.message);
          this.deleteFlashProducts.cancelLoading();
        }
      );
    setTimeout(() => {
      this.deleteFlashProducts.cancelLoading();
    }, 2000);
  }


  createProduct() {
    this.router.navigate(['/portal/products/null']);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
