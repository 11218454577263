import { formatDate } from '@angular/common';
import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { convertDateToStringQuery } from 'src/app/helpers';
import {ProductsDistributorsService, RootService, SurveyService, CompaniesService, StampV2Service, UploadService} from 'src/app/services';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import * as _ from 'lodash';
import { UploaderComponent } from 'src/app/shared';
import { track } from 'src/app/helpers/track';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {environment} from '../../../../../environments/environment';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';
@Component({
  selector: 'app-assign-checkin',
  templateUrl: './assign-checkin.component.html',
  styleUrls: ['./assign-checkin.component.scss']
})
export class AssignCheckinComponent implements OnInit {


  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private stampService: StampService,
    private productsDistributorsService: ProductsDistributorsService,
    private rootService: RootService,
    private surveyService: SurveyService,
    private companiesService: CompaniesService,
    private modalService: NzModalService,
    private stampV2Service: StampV2Service,
    private uploadService: UploadService,
  ) {
    // @ts-ignore
  }
  qrcodeConfig = {
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 1000,
    height: 1000,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  };
  qrcodeConfigPreview = null;
    @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;
  unsubscribe$ = new Subject<void>();
  form = this.fb.group({
    name: ['', [Validators.required]],
    targetId: [null, [Validators.required]],
    message: [''],
    activatedAt: [''],
    expiredAt: [''],
    relatedTargetType: [''],
    loyaltyCampaignType: [''],
    loyaltyCampaignObject: [''],
    packageId: [null, [Validators.required]],
    surveyId: [null],
    loyaltyGiftCode: [''],
    config: new FormGroup({
      logo: new FormControl(''),
      shape: new FormControl('classy'),
      color: new FormControl('#000000'),
      backgroundColor: new FormControl('#ffffff'),
    }),
    setting: new FormGroup({
      requiredCustomerInfo: new FormControl(false),
      allowedScan: new FormControl(true),
      hideNotification: new FormControl(false),
        hideRelatedShops: new FormControl(false),
    }),
  });

  // listPackage = [];
  @Input() listPackage = [];
  @Input() isChangeStampTypeQRCode = false;
  public config = {
    placeholder: '',
    disableDragAndDrop: true,
    tabsize: 2,
    height: '150px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      // ["insert", ["link", "video"]],
      ['insert', ['link']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  settingActiveCollapse = {
    showHideInfo: true,
    showOther: true,
    showBusiness: true,
    showShape: true,
    showColor: true,
    showLogo: true,
    customStyle: {
      background: '#fff',
      'border-radius': '0px',
      'margin-bottom': '20px',
    },
  };

  listYear = [
    {value: 1, label: '1 năm'},
    {value: 2, label: '2 năm'},
    {value: 3, label: '3 năm'},
  ];

  listSetting = [
    {value: 1, label: 'Yêu cầu thông tin khách hàng', key: 'requiredCustomerInfo'},
    {value: 2, label: 'Cho App khác quét', key: 'allowedScan'},
    // {value: 3, label: 'Ẩn thông báo', key: 'hideNotification'},
    {value: 5, label: 'Ẩn DN liên quan', key: 'hideRelatedShops'},
  ];

  id;
  isUpdate = false;

  listRole = [];

  @ViewChild('addBusinessRelated', {static: false}) addBusinessRelated;
  @ViewChild('deleteBusinessRelateds', {static: false}) deleteBusinessRelateds;

  loadingDrawQrcode;
  heightEl = 500;
  listRelatedBusiness = [];

  templateData: any = {};
  templateConfigSubject: any = new BehaviorSubject(this.templateData);
  listSurveys = [];

  listDomain = [];

  storeDetail: any = {};
  storeDetailSubject: any = new BehaviorSubject(this.storeDetail);

  page = 1;

  listStamp = [];
  totalPage = 0;
  currentPage = 1;
  pageSize = 3;

  logos = [];



  validExpiredAt = true;
  showListRelatedBusiness = false;


  listBusiness = [];


  currentPackage: any;

  clickSubmit = false;
  isLoadingBtn = false;

  templateId;

  viewCurrentTemp;
  isNewQr: true;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // let mapElement: any = (document.querySelector('.img-template') as HTMLElement);
    // this.heightEl = mapElement.offsetWidth ? mapElement.offsetWidth * 2 : 500;
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_form_checkin',
    //   contentType: 'qrcode',
    // })
    this.getListSurvey();
    this.getListPackageActive();
    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    this.getListDomainByUser();
    this.getListBusiness();


    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== 'null') {
      this.isUpdate = true;
      this.getStampV2Detail(this.id);
    } else {
      this.isNewQr = true;
    }
    this.getListRole();
    this.changeValueForm();
  }

  getStampV2Detail(id) {
    this.stampService.getStampById(id).subscribe(async res => {
      if (res.code === 1 && res.data) {
        // const data = res.data;
        const data = {
          ...res.data,
          newQr: true
        };
        this.isNewQr = data.newQr;
        const activatedAt = data.activatedAt.split('/');
        // tslint:disable-next-line:variable-name
        const activatedAtConverted = [activatedAt[1], activatedAt[0], activatedAt[2]].join(
          '/'
        );
        const expiredAt = data.expiredAt.split('/');
        // tslint:disable-next-line:variable-name
        const expiredAtConverted = [expiredAt[1], expiredAt[0], expiredAt[2]].join(
          '/'
        );
        if (data.loyaltyCampaign) {
          this.form.patchValue({
            loyaltyCampaignType: data.loyaltyCampaign.type,
            loyaltyCampaignObject: data.loyaltyCampaign.code,
            loyaltyGiftCode: data.loyaltyCampaign.giftCode,
          });
        }
        if (data.packageInfo && data.packageInfo.id) {
          this.listPackage = [{...data.packageInfo}];
          this.form.patchValue({
            packageId: data.packageInfo.id
          });
        }

        this.form.patchValue({
          name: data.name,
          roleId: data.roleId,
          targetId: !this.isChangeStampTypeQRCode ? data.targetId : null,
          message: data.message,
          activatedAt: activatedAtConverted,
          expiredAt: expiredAtConverted,
          setting: data.setting,
          relatedTargetType: data.relatedTargetType,
          templateId: data.templateId,
          surveyId: data.surveyId || null,
          packageId: data.packageInfo.id
        });
        this.templateId = data.templateId;
        this.listRelatedBusiness = data.relatedTargetData ? data.relatedTargetData.map(item => {
          return {
            ...item,
            businessId: item.id,
            taxCode: item.code
          };
        }) : [];
        if (data.newQr) {
          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType, data.config, data.frameConfig, data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(data.qrCode);
        } else {
          if (data.config && data.config.color) {
            this.form.get('config.color').setValue(data.config.color);
          }
          if (data.config && data.config.backgroundColor) {
            this.form
              .get('config.backgroundColor')
              .setValue(data.config.backgroundColor);
          }
          if (data.config && data.config.logo) {
            this.form.get('config.logo').setValue(data.config.logo);
            this.logos = [{ url: data.config.logo }];
          }
          if (data.config && data.config.shape) {
            this.form.get('config.shape').setValue(data.config.shape);
          }
        }
      }
    });
  }

  getListSurvey() {

      const query = {
        page: 1,
        pageSize: 200,
        type: 'BASIC'
      };

      this.surveyService
        .getListSurveys(query)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            if (res.code === 1) {
              this.listSurveys = res && res.data ? [...res.data] : [];
            } else {
              this.notification.warning(
                'Có lỗi xảy ra',
                res && res.message ? res.message : 'Vui lòng thử lại'
              );
            }
          },
          (err: any) => {
            this.notification.error(
              'Có lỗi xảy ra',
              err && err.message ? err.message : 'Vui lòng thử lại'
            );
          }
        );
  }


  getListPackageActive() {
    // this.stampService.getListPackageActive({type: 0}).subscribe(res => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }
  getListDomainByUser() {

    const query: any = {
      page: 1,
      pageSize: 100
    };

    this.stampService
      .getListDomain(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }


  selectedObject(value) {
    if (value) {
      this.getDetailCompanyById(value);
    } else {
      this.storeDetailSubject.next(null);
    }
  }
  getDetailCompanyById(id?: string) {
    this.companiesService.getDetailCompanyById(id).subscribe(
      (res: any) => {
        if (res.code === 1) {
          this.storeDetail = res.data;
          this.storeDetailSubject.next(this.storeDetail);
        }
      },
      (err: any) => {
        this.notification.create(
          'error',
          'Có lỗi xảy ra',
          err.message || 'Lấy dữ liệu thất bại'
        );
      }
    );
  }
  nextPage() {
    if (this.page === this.totalPage) { return; }
    this.page += 1;
    this.getListTemplate();
    this.templateId = null;
  }

  getListRole() {
    this.rootService
      .getRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.listRole = res.data;
      });
  }
  getListTemplate() {
    const body = this.templateId ? {
      page: this.page,
      pageSize: this.pageSize,
      type: 2,
      toTop: this.templateId
    } : {
      page: this.page,
      pageSize: this.pageSize,
      type: 2,
    };
    this.stampService.getAllTemplate(body).subscribe(res => {
      if (res.code === 1) {
        this.listStamp = res.data;
        this.totalPage = Math.ceil(res.totalElement / res.pageSize);
        this.currentPage = res.currentPage;
        this.viewCurrentTemp = this.listStamp.filter(item => item.id === this.templateId)[0];
      }

    });
  }


  prevPage() {
    if (this.page === 1) { return; }
    this.page -= 1;
    this.getListTemplate();
    this.templateId = null;
  }

  deleteSetting(id) {
    const data = {
      label: 'Xác nhận xóa doanh nghiệp liên quan',
      value: ' <p class="mrb-10">Hệ thống xóa doanh nghiệp liên quan này.</p>\n' +
        '          <p>Bạn có chắc chắn muốn xoá?</p>'
    };
    this.deleteBusinessRelateds.showModal(data, id);
  }


  handleDeleteRelateds(event) {
    this.listRelatedBusiness = this.listRelatedBusiness.filter(item => item.businessId !== event[0]);
    this.deleteBusinessRelateds.success();
    this.deleteBusinessRelateds.handleCancel();
    this.templateData.listRelatedTarget = [...this.listRelatedBusiness];
    this.templateConfigSubject.next(this.templateData);
    this.notification.success(
             'Thành công',
             'Xóa doanh nghiệp liên quan thành công'
           );
  }

  onRemoveFile(field: string) {
    this.logos = [];
    this.form.get('config.logo').setValue('');
  }
  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp,mp4,pdf,docx';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logos') {
            this.logos = [{ url: changeParam.file.response.url }];
            this.form
              .get('config.logo')
              .setValue(this.logos[0].url);
            this.form.updateValueAndValidity();

          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }


  showAddBusinessRelated() {
    const listTargetId = this.listRelatedBusiness.map(item => item.businessId);

    if (this.form.value.targetId) {
      listTargetId.push(this.form.value.targetId);
    }
    this.addBusinessRelated.showModal(listTargetId);
  }

  successAddBusinessRelated(event) {
    this.listRelatedBusiness = [
      ...this.listRelatedBusiness,
      ...event.map(item => {
        return {
          ...item,
          image: item.avatar
        };
      })
    ];
    this.templateData.listRelatedTarget = [...this.listRelatedBusiness];
    this.templateConfigSubject.next(this.templateData);
    this.addBusinessRelated.handleCancel();
  }
  changeValueForm() {
    this.form.get('config').valueChanges.subscribe((val: any) => {
      this.templateData = {
        ...this.form.value,
        setting: { ...val.setting },
        config: { ...this.form.get('config').value },
      };
      this.templateConfigSubject.next(this.templateData);
    });
  }


  selectedQrCode(value) {
    if (this.form.get('config.shape').value !== value) {
      this.form.get('config.shape').setValue(value);
    } else {
      this.form.get('config.shape').setValue('');
    }
  }

  getListBusiness() {
    const body = {
      page: 1,
      pageSize: 100,
      hidden: false
    };
    this.productsDistributorsService
          .listBusiness(body)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            this.listBusiness = res.data;
          });
  }


  getDate(date, tmpNumber) {
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const minute = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (tmpNumber === 1) {
      return dd + '/' + mm + '/' + yyyy;
    }
    return hh + ':' + minute;
  }
  selectedPackage(event) {
    if (event) {
      this.currentPackage = this.listPackage.find(item => item.id === event);
    }
  }



  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }
  async submit() {
    this.clickSubmit = true;
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };
    recursive(this.form);
    if (this.form.invalid) {
      this.templateQRCodeViewRef.onChangeTabActive();
      return;
    }
    this.isLoadingBtn = true;
    const newForm = {
      ...this.form.value,
      activatedAt: this.form.value.activatedAt ? this.getDate(this.form.value.activatedAt, 1) : '',
      expiredAt: this.form.value.expiredAt ? this.getDate(this.form.value.expiredAt, 1) : this.currentPackage.expiredAt,
      type: 8,
      relatedTargetId: this.form.value.relatedTargetType === 2 ? this.listRelatedBusiness.map(item => item.businessId) : []
    };
    if (this.isNewQr) {
      const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
      newForm.config = {...this.qrcodeDesignComponentRef.getQrcodeDesignConfig()};
      newForm.qrcodeFrameId = this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
      const formData = new FormData();
      formData.append('file', base64QrcodeSvg);
      const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
      if (qrImageResponse.status === 200) {
        newForm.qrImage = qrImageResponse.data || '';
      }
    }
    // track("manualTrackInteractionContent", {
    //   interaction: "click",
    //   contentName: "save",
    //   regionName: "portal_stamp_form_checkin",
    //   target: `${window.location.origin}/portal/stamp`,
    //   payload: JSON.stringify(newForm),
    // })

    if (this.isUpdate) {
      if (this.isNewQr) {
        this.stampService
          .update(this.id, newForm)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            if (res.code === 1) {
              // track("alert", {
              //   alertType: "success",
              //   alertMessage: "Thêm mới QR Code thành công"
              // });
              this.openMessage('success', 'Thành công', 'Cập nhật thành công');
              let delayTimer: any;
              clearTimeout(delayTimer);
              delayTimer = setTimeout(() => {
                this.router.navigate(['/portal/stamp']);
                return;
              }, 500);
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
            this.isLoadingBtn = false;
          }, error => {
            this.isLoadingBtn = false;
            this.openMessage('error', 'Lỗi !!!', `${error}`);
          });
      } else {
        const qrcodeData = `${environment.redirectScanUrl}/${this.id}`;
        const tmpQrcodeConfig = {
          ...this.qrcodeConfig,
          backgroundOptions: {
            color: this.form.get('config.backgroundColor').value
          },
          dotsOptions: {
            type: this.form.get('config.shape').value,
            color: this.form.get('config.color').value,
          },
          image: this.form.get('config.logo').value,
          data: qrcodeData,
        };
        const qrcodeInstance = new QRCodeStyling(tmpQrcodeConfig as any);
        const rawQrcodeImage = await qrcodeInstance.getRawData();
        const formData = new FormData();
        formData.append('content', rawQrcodeImage, 'qrcode.png');
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.uploadService.uploadFile(formData, currentUser).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (result) => {
            if (result.status === 200 && result.data && result.data.url) {
              newForm.qrImage = result.data.url;
              this.stampService
                .update(this.id, newForm)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  if (res.code === 1) {
                    this.openMessage('success', 'Thành công', 'Cập nhật thành công');
                    let delayTimer: any;
                    clearTimeout(delayTimer);
                    delayTimer = setTimeout(() => {
                      this.router.navigate(['/portal/stamp']);
                      return;
                    }, 500);
                  } else {
                    this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                  }
                  this.isLoadingBtn = false;
                }, error => {
                  this.isLoadingBtn = false;
                  this.openMessage('error', 'Lỗi !!!', `${error}`);
                });
            }
          },
          (err) => {
            this.openMessage('error', 'Cập nhật không thành công', `Vui lòng thử lại`);
          }
        );
      }
    } else {
      newForm.qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
      this.stampService
        .create(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track("alert", {
            //   alertType: "success",
            //   alertMessage: "Thêm mới QR Code thành công"
            // });
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            let delayTimer: any;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
              this.router.navigate(['/portal/stamp']);
              return;
            }, 500);
          } else {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
          this.isLoadingBtn = false;
        }, error => {
          this.isLoadingBtn = false;
          this.openMessage('error', 'Lỗi !!!', `${error}`);
        });
    }
  }
  selectTemplate(stamp) {
    this.templateId = stamp.id;
    this.form.controls.templateId.setValue(stamp.id);
  }
  viewTemplate(stamp) {
    this.viewCurrentTemp = stamp;
  }
  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }
  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }
  back() {
    this.router.navigate(['/portal/stamp']);
  }

}
