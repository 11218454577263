import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-survey-copy',
  templateUrl: './survey-copy.component.html',
  styleUrls: ['./survey-copy.component.scss']
})
export class SurveyCopyComponent implements OnInit {
  isVisible: boolean = false
  title: string = "Sao chép khảo sát"
  formCopy: FormGroup = this.fb.group({
    idExisted: [undefined, [Validators.required]],
    name: ["", [Validators.required]],
    count: [1, [Validators.required]]
  })
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  handleCancel() {
    this.isVisible = false
  }

  handleOk() {
    this.isVisible = false
  }

  showModal() {
    this.isVisible = true
  }

}
