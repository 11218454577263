import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UploaderComponent} from '../../uploader';
import {debounceTime, switchMap, takeUntil} from 'rxjs/operators';
import {NzModalService, TransferItem} from 'ng-zorro-antd';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {$e} from 'codelyzer/angular/styles/chars';
import {DistributorsService, RootService} from '../../../services';
import {capitalizeFirstLetter, removeAllSpacingBetweenWords} from '../../../helpers';

@Component({
  selector: 'add-distributors',
  templateUrl: './add-distributors.component.html',
  styleUrls: ['./add-distributors.component.scss']
})
export class AddDistributorsComponent implements OnInit, OnDestroy {
  isVisible = false;
  actions = [
    {label: 'Gán sản phẩm', value: 0},
    {label: 'Gỡ sản phẩm', value: 1},
    {label: 'Export', value: 2},
    {label: 'Export tất cả', value: 3},
  ];
  index = 1;
  title;
  list: TransferItem[] = [];
  searchPlaceholder = 'Tên hoặc mã sản phẩm';
  itemsUnit = 'Sản phẩm';
  pageIndex = 1;
  pageSize = 5;
  changeTabEvent = 1;
  countries: any;
  // country_id: any;
  // tslint:disable-next-line:variable-name
  country_code: any;
  city = null;
  districts = null;
  street = null;
  unsubscribe$ = new Subject<void>();
  avatar: any[] = [];
  background: any[] = [];
  // tslint:disable-next-line:variable-name
  form_step: FormGroup = this.fb.group({
    id: [null, [Validators.required]],
  });

  // tslint:disable-next-line:variable-name
  form_step1: FormGroup = this.fb.group({
    name: [null, [Validators.required, Validators.maxLength(255)]],
    tax_code: [null, [
      Validators.required,
      Validators.maxLength(255)
    ]],
    address: [null, [Validators.required, Validators.maxLength(255)]],
    country_id: [null, [Validators.required]],
    province_id: [null, [Validators.required]],
    district_id: [null, [Validators.required]],
    streetId: [null, [Validators.required]],
    phone: [null, [Validators.pattern('^[0-9]*$')]],
    email: [null, [Validators.pattern('^[a-z0-9_\\.]{1,32}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$')]],
    website: [null],
    avatar: [null],
    background: [null],
  });

  @Output() loadDataParent = new EventEmitter();
  optionList: any;
  isLoading = false;
  searchChange$ = new BehaviorSubject('');
  viewDetail;
  isLoadingBtn = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private distributorsService: DistributorsService,
    private rootService: RootService,
  ) {
    const optionList$: Observable<string> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500));

    optionList$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {

      if (value && value.trim().length > 0) {
        const query = {
          name: value.trim()
        };
        this.distributorsService.searchDistributor(query).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
          this.optionList = res.items;
          this.isLoading = false;
        });
      } else {
        this.optionList = [];
        this.isLoading = false;

      }

    });
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
    this.getCities();
    this.rootService.listCities().pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.city = res.data.items;
    });
    this.form_step1.controls.province_id.clearValidators();
    this.form_step1.controls.province_id.disable();
    this.form_step1.controls.district_id.clearValidators();
    this.form_step1.controls.district_id.disable();
    this.form_step1.controls.streetId.clearValidators();
    this.form_step1.controls.streetId.disable();

  }

  onSearch(value: string): void {
    this.isLoading = true;
    this.searchChange$.next(value);

  }

  selectId($id) {
    if ($id) {
      this.distributorsService.detailDistributor($id).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        this.viewDetail = res.data;
      });
    }
  }

  // searchDistributors($event: any) {
  //
  //
  // }

  // convertItems(items: TransferItem[]): TransferItem[] {
  //   return items.filter(i => !i.hide);
  // }

  select(ret: {}): void {
  }

  change(ret: {}): void {
  }

  handleCancel(): void {
    this.isVisible = false;
    this.avatar = [];
    this.background = [];
    this.viewDetail = null;

    if (this.changeTabEvent === 1) {
      this.form_step.reset();

    } else {
      this.form_step1.reset();
    }
  }

  showModal(): void {
    this.isVisible = true;
    this.changeTabEvent = 1;
  }

  changeTab(event) {
    this.changeTabEvent = event;
    if (this.changeTabEvent === 1) {
      this.form_step.reset();
    } else {
      this.viewDetail = null;
      this.form_step1.reset();
    }
  }

  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    if (this.changeTabEvent === 1) {
      const form = this.form_step;
      recursive(form);

      if (form.invalid) {
        return;
      }
      this.isLoadingBtn = true;
      this.distributorsService.addIdDistributor(this.form_step.value).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {

        if (res.code === 1) {
          this.notification.create(
            'success',
            'Thành công',
            'Chọn nhà phân phối thành công'
          );
          this.viewDetail = null;
          this.form_step.reset();
          this.loadDataParent.emit(true);
          this.isVisible = false;

        } else {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message
          );
        }
      }, (err) => {
        this.notification.create(
          'error',
          'Thất bại',
          err
        );
      });
      this.isLoadingBtn = false;

    } else {

      const form = this.form_step1;
      recursive(form);

      if (form.invalid) {
        return;
      }
      this.isLoadingBtn = true;
      let newForm;
      newForm = {...this.form_step1.value};
      // tslint:disable-next-line:radix
      newForm.country_id =  parseInt(this.form_step1.value.country_id.split('-')[0]);
      if (this.form_step1.value.country_id.split('-')[1] !== 'VN') {
        newForm.province_id = 0;
        newForm.district_id = 0;
        newForm.streetId = 0;
      }
      newForm.name = capitalizeFirstLetter(removeAllSpacingBetweenWords(this.form_step1.value.name));
      newForm.country_id = undefined;
      // tslint:disable-next-line:radix
      newForm.countryId = parseInt(this.form_step1.value.country_id.split('-')[0]);
      this.distributorsService.addDistributor(newForm).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        if (res.code === 1) {
          this.notification.create(
            'success',
            'Thành công',
            'Tạo nhà phân phối thành công'
          );
          this.avatar = [];
          this.background = [];
          this.form_step1.reset();
          this.loadDataParent.emit(true);
          this.isVisible = false;

        } else {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message
          );
        }
        this.isLoadingBtn = false;

      }, (err) => {
        this.notification.create(
          'error',
          'Thất bại',
          err
        );
        this.isLoadingBtn = false;

      });

    }

  }

  getCities() {
    this.rootService.listCountries().pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        if (res.code === 1) {
          this.countries = res.data.items;
        }
      }
    );
  }

  citiChange(event) {
    if (event) {
      // this.country_id = event.split('-')[0];
      this.country_code = event.split('-')[1];

      if ( this.country_code  !== 'VN') {
        this.form_step1.controls.province_id.clearValidators();
        this.form_step1.controls.province_id.disable();
        this.form_step1.controls.district_id.clearValidators();
        this.form_step1.controls.district_id.disable();
        this.form_step1.controls.streetId.clearValidators();
        this.form_step1.controls.streetId.disable();
        return;
      }
      this.form_step1.controls.province_id.setValidators(Validators.required);
      this.form_step1.controls.province_id.enable();
      this.form_step1.controls.district_id.setValidators(Validators.required);
      this.form_step1.controls.district_id.enable();
      this.form_step1.controls.streetId.setValidators(Validators.required);
      this.form_step1.controls.streetId.enable();
    }

  }

  getDistricts($event: any) {
    this.districts = null;
    this.street = null;
    this.form_step1.get('district_id').setValue(null);
    this.form_step1.get('streetId').setValue(null);
    if ($event !== null) {
      this.rootService.listDistricts($event).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        this.districts = res.data.items;
      });
    }

  }

  getStreet($event: any) {
    this.street = null;
    this.form_step1.get('streetId').setValue(null);
    if ($event !== null) {
      this.rootService.listWards($event).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
        this.street = res.data.items;
      });
    }
  }

  openUploadDialog(field: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe(changeParam => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          // this[field].push(data);
          // this.form_step.get('avatar').value.push(changeParam.file.response.id);
          if (field === 'avatar') {
            this.form_step1.get('avatar').setValue(changeParam.file.response.id);
            this.avatar = [{url: changeParam.file.response.url}];
          } else if (field === 'background') {
            this.form_step1.get('background').setValue(changeParam.file.response.id);
            this.background = [{url: changeParam.file.response.url}];
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(changeParam => {
        modal.close();
      });
    });

  }


  onRemoveFileAll(field: string) {
    // dùng cho 1 ảnh
    if (field === 'avatar') {
      this.avatar = [];
      this.form_step1.get('avatar').setValue(null);
    } else if (field === 'background') {
      this.background = [];
      this.form_step1.get('background').setValue(null);
    }
  }

  onRemoveFile(field: string, index: number) {

    // dùng cho nhiều ảnh
    this[field].splice(index, 1);
    this.form_step1.get(field).value.splice(index, 1);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
