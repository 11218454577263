import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {CompaniesService, ProductsService} from 'src/app/services';
import {
  embedYoutubeUrl,
  numberWithCommas,
  isValidYoutubeUrl,
  checkTypePDF,
  goToSocialLink,
  onOpenDNSHSocial,
} from 'src/app/utility/common/commonFunction';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-template-twenty-v2',
  templateUrl: './template-twenty-v2.component.html',
  styleUrls: ['./template-twenty-v2.component.scss']
})
export class TemplateTwentyV2Component implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;

  targetDetail: any = null;
  listProduct: any = [];
  listRelatedTarget: any = [];
  limitDescription = 150;
  limitMax = 150;
  job = '';
  renderListSocial: any = [];
  stores: any = [];
  settingStamp: any = null;

  numberWithCommas = numberWithCommas;
  isValidYoutubeUrl = isValidYoutubeUrl;
  checkTypePDF = checkTypePDF;
  onOpenDNSHSocial = onOpenDNSHSocial;
  goToSocialLink = goToSocialLink;

  constructor(
    private companiesService: CompaniesService,
    private productService: ProductsService,
    private sanitizer: DomSanitizer,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      console.log('chh_log ---> this.templateData.subscribe ---> val', val);
      this.data = { ...val };
      this.settingStamp = { ...val.setting };
      if (val.targetId) {
        this.getDetailTargetById(val.targetId);
        this.getListProducts({
          businessId: val.targetId,
          page: 1,
          pageSize: 10
        });
        // this.getListProduct(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 1) {
        this.getRelatedTarget(val.targetId);
      }
      if (val.targetId && val.relatedTargetType === 2) {
        this.listRelatedTarget = val.listRelatedTarget
          ? [...val.listRelatedTarget]
          : [];
      }
    });
  }

  products: any;
  getListProducts(params: any) {
    this.companiesService.getListProducts(params).subscribe((res: any) => {
      // console.log("res", res)
      if (res && res.data && res.data.length) {
        this.products = res.data;
      };
    });
  }

  getDetailTargetById(id: any) {
    this.companiesService
      .getDetailCompanyById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.targetDetail = res.data ? { ...res.data } : null;
          console.log("this.targetDetail", this.targetDetail)
          this.targetDetail.fullAddress = [
            res.data.address,
            res.data.wardName,
            res.data.districtName,
            res.data.provinceName,
            res.data.countryName,
          ]
            .filter((e) => typeof e === 'string' && e !== '')
            .join(', ');
          this.job =
            res.data.career && res.data.career.length
              ? res.data.career.find((el) => el.type === 'job')
                ? res.data.career.find((el) => el.type === 'job').value
                : null
              : '';
          // if (res.data.linkPages && res.data.linkPages.length) {
          //   this.renderListSocial = res.data.linkPages.filter(
          //     (el, index) => index < 6
          //   );
          // } else {
          //   this.renderListSocial = res.data.linkPages || [];
          // }
          this.renderListSocial = res.data.linkPages || [];

          // tslint:disable-next-line:max-line-length
          this.stores = res.data.linkPages && res.data.linkPages.length ? [...res.data.linkPages].filter(l => l.type === 'lazada' || l.type === 'sendo' || l.type === 'tiki' || l.type === 'shopee') : [];
        }
      });
  }

  getListProduct(id: any) {
    const query = {
      businessId: id,
      page: 1,
      pageSize: 4,
    };
    this.companiesService
      .getListProducts(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listProduct = res.data
            ? res.data.map((gg: any) => {
              const tmp = { ...gg };
              tmp._price = this.numberWithCommas(gg.price);
              return tmp;
            })
            : [];
        }
      });
  }

  getRelatedTarget(id: any) {
    const query = {
      page: 1,
      page_size: 7,
    };
    this.companiesService
      .getList(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.code === 1) {
          if (res.data) {
            res.data.forEach((gg: any) => {
              if (gg.businessId !== id) {
                gg.fullAddress = [gg.address, gg.provinceName]
                  .filter((e) => typeof e === 'string' && e !== '')
                  .join(', ');

                this.listRelatedTarget.push(gg);
              }
            });
          }
        }
      });
  }

  renderVideo(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  renderPDF(url: string) {
    return this.sanitizer.bypassSecurityTrustHtml(`<object
    data="${url}"
    style="width: 100%;height:100%;"
    ></object>`);
  }
}
