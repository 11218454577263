import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({providedIn: 'root'})

export class DistributorsService extends BaseService {
  list(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/distributor', httpOptions);
  }

  deleteDistributor(body): Observable<any> {
    return this.delete<any>(`/distributor`, body);
  }

  addDistributor(body): Observable<any> {
    return this.post<any>(`/distributor`, body);
  }

  addIdDistributor(body): Observable<any> {
    return this.post<any>(`/distributor/add`, body);
  }

  detailDistributor(id): Observable<any> {
    return this.get<any>(`/distributor/${id}`);
  }

  editDistributor(id, body): Observable<any> {
    return this.put<any>(`/distributor/${id}`, body);
  }

  assginProduct(id): Observable<any> {
    return this.post<any>(`/distributor/${id}/assgin-product`);
  }

  removeProduct(body): Observable<any> {
    return this.delete<any>(`/distributor/product`, body);
  }

  searchDistributor(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`/distributor/search?`, httpOptions);
  }

  listProduct(id, query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`/distributor/product?id=${id}`, httpOptions);
  }

  listProductTranfer(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`/distributor/product/search`, httpOptions);
  }

  listRole() {
    return this.get<any>(`/role`);
  }

  listTitle(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`/title/search`, httpOptions);
  }

  addProduct(body): Observable<any> {
    return this.post<any>(`/distributor/product/assign`, body);
  }

  detailProduct(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`/distributor/product/detail`, httpOptions);
  }

  updateProduct(body): Observable<any> {
    return this.post<any>(`/distributor/product/update`, body);
  }

  exportExcel(query?: any) {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>(`/distributor/export`, httpOptions);
  }

  distributorsDetailExport(query?: any) {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>(`/distributor/product/export`, httpOptions);
  }

  listBusiness(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`/business`, httpOptions);
  }

  detailBusiness(id: any) {
    // const httpOptions = {
    //   params: query
    // };
    return this.get<any>(`/business/${id}`);
  }

}
