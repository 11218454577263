import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nz-empty-customize',
  templateUrl: './nz-empty-customize.component.html',
  styleUrls: ['./nz-empty-customize.component.scss']
})
export class NzEmptyCustomizeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onClick(): void {
  }

}
