import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShopsService, ProductsStoresService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-stores',
  templateUrl: './form-stores.component.html',
  styleUrls: ['./form-stores.component.scss']
})
export class FormStoresComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject<void>();

  isVisible = false;
  type: string;
  showModal(type?: string) {
    this.type = type;
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  constructor(
    private shopService2: ProductsStoresService,
  ) { }

  ngOnInit() {
    this.shopService2.currentActive.pipe(takeUntil(this.unsubscribe$)).subscribe(status => {
      if (status) {
        this.handleCancel()
        return;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
