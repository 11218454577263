import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-product-document-assign',
  templateUrl: './product-document-assign.component.html',
  styleUrls: ['./product-document-assign.component.scss']
})
export class ProductDocumentAssignComponent implements OnInit {

  current: number = 1;
  isVisible = false;
  check: string = 'product';
  str: string = 'isShow';
  @Input() str2: string;
  showModal() {
    this.radioValue = 'all';
    this.current = 1;
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  radioValue = 'all';
  options = [
    { label: 'Áp dụng cho toàn bộ sản phẩm khác', value: 'all' },
    { label: 'Chọn sản phẩm cùng áp dụng', value: 'more' },
    { label: 'Áp dụng cho sản phẩm hiện tại', value: 'only' },
  ];

  log(data: any) {
    if (data === 'only') {
      const path_url = window.location.href.split('/');
      this.productId = [path_url[5]];
      return;
    }
  }

  constructor() { }

  ngOnInit() { }

  documentId: any = [];
  productId: any = [];
  isRequired: Boolean = false;
  listProducts(data: any) {
    this.productId = data;
    if (data.length > 0) {
      this.isRequired = false;
    } else {
      this.isRequired = true;
    }
  }

  selectDocuments(data: any) {
    if (data === 'isShowProduct') {
      this.handleCancel();
      return;
    }
    this.documentId = data.ids;
    this.current = data.current;
  }

  @Output() assignProduct = new EventEmitter<any>();
  isLoadingBtn: Boolean = false;
  onSubmit() {
    if (this.radioValue === 'all') {
      this.isRequired = false;
      let body: any = {
        listDocumentId: this.documentId,
        assignAll: true
      }
      this.assignProduct.emit(body);
      this.isVisible = false;
      return;
    } else {
      this.isRequired = false;

      if (!this.productId.length) {
        this.isRequired = true;
        return;
      }
      let body: any = {
        listDocumentId: this.documentId,
        listProductId: this.productId,
        assignAll: false
      }
      this.isLoadingBtn = true;
      this.assignProduct.emit(body);
      this.isVisible = false;
      return;
    }
  }

  success() {
    this.isLoadingBtn = false;
  }

}
