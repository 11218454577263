import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base.service";

@Injectable({ providedIn: "root" })
export class ServicePackageService extends BaseService {
  getList(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/package/package", httpOptions);
  }

  getListChildPlansById(query: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/package/plans", httpOptions);
  }

  buyPackage(body?: any) {
    return this.post('/package', body)
  }


  
  update(id, body?: any) {
    return this.put(`/package/${id}`, body)
  }
}
