import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {NzModalService, TransferItem} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent implements OnInit {
  isVisible = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzNotificationService
  ) {
  }

  ngOnInit() {
  }


  handleCancel(): void {
    this.isVisible = false;
  }

  showModal() {
    this.isVisible = true;
  }


}
