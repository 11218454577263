import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-survey-setting-preview',
  templateUrl: './survey-setting-preview.component.html',
  styleUrls: ['./survey-setting-preview.component.scss']
})
export class SurveySettingPreviewComponent implements OnInit {
  @Input() formData: any
  @Input() currentSetting: any
  data: any = null;
  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngOnChanges() {


    this.data = {...this.formData}
    // page start
    if(this.formData.introducePage.description) {
      this.data.introducePage.description = this._sanitizer.bypassSecurityTrustHtml(this.data.introducePage.description)
    }
    // page end
    if(this.formData.theEndPage.description) {
      this.data.theEndPage.description = this._sanitizer.bypassSecurityTrustHtml(this.data.theEndPage.description)
    }



  }

}
