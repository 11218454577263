import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-products-update',
  templateUrl: './products-update.component.html',
  styleUrls: ['./products-update.component.scss']
})
export class ProductsUpdateComponent implements OnInit {
  isVisible = false;
  isLoadingBtn = false;
  file;
  isName;
  isProductDetail;
  isImage;
  isVerifyAuth;
  isPrice;
  isBusinessInfo;
  isVideos;
  isCategory;
  isDocument;
  @Output() updateFromExcel = new EventEmitter<string>();
  constructor(
    private notification: NzNotificationService,
  ) { }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }
  updateProductFromExcel(event) {
    if (event !== undefined) {
      this.file = event;
    }
  }
  onSubmit() {
    if (this.file === undefined) {
      this.notification.error(
        'Có lỗi xảy ra',
        'Chưa chọn file'
      );
      return;
    }
    // tslint:disable-next-line:max-line-length
    if (!this.isName && !this.isProductDetail && !this.isImage && !this.isVerifyAuth && !this.isPrice && !this.isBusinessInfo && !this.isVideos && !this.isCategory && !this.isDocument) {
      this.notification.error(
        'Có lỗi xảy ra',
        'Chưa chọn cột dữ liệu cần cập nhật'
      );
      return;
    }
    this.isLoadingBtn = true;
    const body: any = {
      file: this.file,
      params: {
        isName: this.isName,
        isProductDetail: this.isProductDetail,
        isImage: this.isImage,
        isVerifyAuth: this.isVerifyAuth,
        isPrice: this.isPrice,
        isBusinessInfo: this.isBusinessInfo,
        isVideos: this.isVideos,
        isCategory: this.isCategory,
      }
    };
    this.updateFromExcel.emit(body);
  }
  removeFile() {
    this.file = undefined;
  }
  showModal() {
    this.isVisible = true;
    this.file = undefined;
    this.isLoadingBtn = false;
    this.isName = false;
    this.isProductDetail = false;
    this.isImage = false;
    this.isVerifyAuth = false;
    this.isPrice = false;
    this.isBusinessInfo = false;
    this.isVideos = false;
    this.isCategory = false;
    this.isDocument = false;
  }
  cancelLoadingBtn() {
    this.isLoadingBtn = false;
  }
  handleCancel() {
    this.isVisible = false;
  }
}
