import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { ProductsService } from "../../services";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { removeEmptyQuery } from 'src/app/helpers';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductComponent implements OnInit, OnChanges, OnDestroy {

  unsubscribe$ = new Subject<void>();


  @Input() titleId: any;
  @Input() storesId: any;
  @Input() check: string;

  constructor(
    private productService: ProductsService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.loadData();
  }
  // Left
  pageIndex = 1;
  pageSize = 5;
  total: number;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any = [];
  isShow: Boolean;
  arr: any = [];

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.productId]);
    this.isIndeterminate = this.listOfDisplayData.some(item => this.mapOfCheckedId[item.productId]) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (let [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.productId] = value));
    this.refreshStatus();
  }

  loadData() {
    const query: any = {
      keyword: this.keyword ? this.keyword.trim() : null,
      not_in: this.arr ? this.arr.toString() : null,
      title_id: this.titleId ? this.titleId : null,
      store_id: this.storesId ? this.storesId : null,
      page: this.pageIndex,
      page_size: this.pageSize,
    };

    removeEmptyQuery(query);

    if (this.check === 'product') {
      this.productService.getAllProduct(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {
          this.total = res.data.totalElement;
          this.listOfAllData = res.data.items;
          if (!this.listOfAllData && this.pageIndex > 1) {
            this.pageIndex = this.pageIndex - 1;
            this.loadData();
            return;
          }
        }
      );
      return;
    }

    this.productService.getAllProductByShop(query).subscribe(
      (res) => {
        this.total = res.data.data.totalElement;
        this.listOfAllData = res.data.data.items;
        if (!this.listOfAllData && this.pageIndex > 1) {
          this.pageIndex = this.pageIndex - 1;
          this.loadData();
          return;
        }
      }
    );
  }

  // Right
  totalRight: number;
  pageIndexRight = 1;
  pageSizeRight = 5;
  isAllDisplayDataCheckedRight = false;
  isIndeterminateRight = false;
  listOfDisplayDataRight: any[] = [];
  listOfAllDataRight: any[] = [];
  mapOfCheckedIdRight: { [key: string]: boolean } = {};
  idsRight: any = [];
  isShowRight: Boolean;

  currentPageDataChangeRight($event: any[]): void {
    this.listOfDisplayDataRight = $event;
    this.refreshStatusRight();
  }

  refreshStatusRight(): void {
    this.isAllDisplayDataCheckedRight = this.listOfDisplayDataRight.every(item => this.mapOfCheckedIdRight[item.productId]);
    this.isIndeterminateRight = this.listOfDisplayDataRight.some(item => this.mapOfCheckedIdRight[item.productId]) && !this.isAllDisplayDataCheckedRight;
    this.idsRight = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedIdRight)) {
      if (value == true) {
        this.idsRight.push(key);
      }
    }
    if (this.idsRight.length > 0) {
      this.isShowRight = false;
    } else {
      this.isShowRight = true;
    }
  }

  checkAllRight(value: boolean): void {
    this.listOfDisplayDataRight.forEach(item => (this.mapOfCheckedIdRight[item.productId] = value));
    this.refreshStatusRight();
  }

  loadDataRight() {
    const query: any = {
      keyword: this.keywordRight ? this.keywordRight.trim() : null,
      ids: this.arr ? this.arr.toString() : null,
      title_id: this.titleId ? this.titleId : null,
      store_id: this.storesId ? this.storesId : null,
      page: this.pageIndexRight,
      page_size: this.pageSizeRight,
    };

    removeEmptyQuery(query);

    if (this.check === 'product') {
      this.productService.getAllProduct(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {
          this.totalRight = res.data.totalElement;
          this.listOfAllDataRight = res.data.items;
          if (!this.listOfAllDataRight && this.pageIndexRight > 1) {
            this.pageIndexRight = this.pageIndexRight - 1;
            this.loadData();
            return;
          }
        }
      );
      return;
    }

    this.productService.getAllProductByShop(query).subscribe(
      (res) => {
        this.totalRight = res.data.data.totalElement;
        this.listOfAllDataRight = res.data.data.items;
        if (!this.listOfAllDataRight && this.pageIndexRight > 1) {
          this.pageIndexRight = this.pageIndexRight - 1;
          this.loadData();
          return;
        }
      }
    );
  }

  //=================//
  @Output() listProducts = new EventEmitter<any>();

  btnRight() {
    this.arr = this.ids;
    this.loadData();
    this.loadDataRight();
    this.listProducts.emit(this.ids);
    for (const value of this.ids) {
      delete this.mapOfCheckedIdRight[value];
    }
  }

  newArr: any = [];
  btnLeft() {
    for (var i = 0; i < this.idsRight.length; i++) {
      for (var j = 0; j < this.arr.length; j++) {
        if (this.idsRight[i] == this.arr[j]) {
          this.newArr = this.arr.filter((val: any) => !this.idsRight.includes(val));
        }
      }
    }
    if (this.newArr.length > 0) {
      this.arr = this.newArr;
      this.listProducts.emit(this.newArr);
      this.loadData();
      this.loadDataRight();
      for (const value of this.idsRight) {
        delete this.mapOfCheckedId[value];
      }
    } else {
      this.arr = null;
      this.loadData();
      this.listOfAllDataRight = [];
      this.newArr = [];
      this.listProducts.emit(this.newArr);
      this.mapOfCheckedId = {};
      this.mapOfCheckedIdRight = {};
    }
    this.newArr = [];
  }
  //=================//
  keyword: string;
  keywordRight: string;
  barcode: string;
  barcodeRight: string;

  delayTimer: any;
  search() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.pageIndex = 1;
      this.loadData();
    }, 300);
  }

  searchRight() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.pageIndexRight = 1;
      this.loadDataRight();
    }, 300);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
