import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd';
import { UploaderComponent } from 'src/app/shared/uploader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ShopsService, GoongService, RootService, ProductsStoresService } from 'src/app/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MouseEvent } from '@agm/core';

import { capitalizeFirstLetter, removeAllSpacingBetweenWords } from 'src/app/helpers';

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

@Component({
  selector: 'app-create-stores',
  templateUrl: './create-stores.component.html',
  styleUrls: ['./create-stores.component.scss']
})
export class CreateStoresComponent implements OnInit, OnDestroy {

  isVisible = false;
  shopId: string;
  productShopId: string;
  @Input() type: string;
  showModal(type: string, data?: any, productId?: any) {
    this.isVisible = true;
    this.type = type;
    this.setNull();
    if (type === 'updateShopProduct') {
      this.form.addControl('titleId', this.fb.control(null, [Validators.required]));
      this.loadTitle();
      this.productShopId = data.shopId;
      this.shopService2.getById(data.shopId, productId).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {
          if (res.code === 1) {
            this.setFormValue(res);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
        }
      );
      return;
    }
    if (type === 'updateShop') {
      this.shopId = data.id;
      this.shopService.getById(data.id).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {
          if (res.code === 1) {
            this.setFormValue(res);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
        }
      );
      return;
    }
  }

  setFormValue(data: any) {
    if (data.data.logo) {
      this.logos = [{ url: data.data.logo_org }];
      this.form.get('logo').setValue(data.data.logo);
    }
    if (data.data.cover) {
      this.covers = [{ url: data.data.cover_org }];
      this.form.get('cover').setValue(data.data.cover);
    }
    this.form.get('shopName').setValue(data.data.shopName);
    this.form.get('phoneNumber').setValue(data.data.phoneNumber);
    this.form.get('email').setValue(data.data.email);
    this.form.get('country_id').setValue(1);
    this.form.get('province_id').setValue(data.data.province_id);
    this.form.get('district_id').setValue(data.data.district_id);
    this.form.get('village_id').setValue(data.data.village_id);
    // this.form.get('address').setValue(data.data.address.split(',').slice(0, -3).join(','));
    this.form.get('address').setValue(data.data.address);
    this.form.get('lat').setValue(data.data.lat);
    this.form.get('lon').setValue(data.data.lon);

    if (this.type === 'createShopProduct' || this.type === 'updateShopProduct') {
      this.form.get('titleId').setValue(data.data.titleId);
    }

    this.lat = data.data.lat;
    this.lng = data.data.lon;
    this.getDistrictByCityId(data.data.province_id);
    this.getWardByDistrictId(data.data.district_id);
    this.getWardId(data.data.village_id);
    this.markers.push({
      lat: this.lat,
      lng: this.lng,
      draggable: true
    });
  }

  handleCancel() {
    this.isVisible = false;
  }

  @Output() createStores = new EventEmitter<any>();
  @Output() updateStores = new EventEmitter<any>();

  unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private shopService: ShopsService,
    private goong: GoongService,
    private rootService: RootService,
    private notification: NzNotificationService,
    private shopService2: ProductsStoresService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  form = this.fb.group({
    logo: [null],
    cover: [null],
    shopName: [null, [Validators.required, Validators.maxLength(255)]],
    phoneNumber: [null, [Validators.required, Validators.maxLength(255), Validators.pattern("[0-9]*")]],
    email: [null, [Validators.maxLength(255), Validators.pattern('^[a-z0-9_\\.]{1,32}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$')]],
    country_id: [1],
    province_id: [null, [Validators.required]],
    district_id: [null, [Validators.required]],
    village_id: [null, [Validators.required]],
    address: [null, [Validators.required, Validators.maxLength(255)]],
    lat: [null],
    lon: [null],
  })

  titles: any = [];
  loadTitle() {
    this.shopService.getAllTitle().subscribe(
      (res) => {
        if (res.code === 1) {
          this.titles = res.data.items;
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  productId: string;
  ngOnInit() {
    if (this.type === 'createShopProduct') {
      this.form.addControl('titleId', this.fb.control(null, [Validators.required]));
      this.loadTitle();
      const path_url = window.location.href.split('/');
      this.productId = path_url[5];
      this.isVisible = true;
    }
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
    this.loadCities();
  }

  cityName: string = null;
  districtName: string = null;
  wardName: string = null;
  cities: any[] = [];
  districts: any[] = [];
  wards: any[] = [];

  loadCities() {
    this.rootService.listCities().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        if (res.code === 1) {
          this.cities = res.data.items;
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
      },
    );
  }

  getDistrictByCityId(id: number) {
    if (id) {
      this.rootService.getCityById(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.cityName = res.data.name;
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        },
      );

      this.rootService.listDistricts(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res: any) => {
          this.districts = res.data.items;
        }
      );
    }
  }

  getWardByDistrictId(id: number) {
    if (id) {
      this.rootService.getDistrictById(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.districtName = res.data.name;
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        },
      );

      this.rootService.listWards(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res: any) => {
          this.wards = res.data.items;
        }
      );
    }
  }

  getWardId(id: number) {
    if (id) {
      this.rootService.getWardById(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.wardName = res.data.name.slice(7, res.data.name.length);
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          return;
        },
      );
    }
  }

  setValueChangeCity() {
    this.form.get('district_id').setValue(null);
    this.form.get('village_id').setValue(null);
    this.form.get('address').setValue(null);
    this.showSearch = false;
  }

  setValueChangeDistrict() {
    this.form.get('village_id').setValue(null);
    this.form.get('address').setValue(null);
    this.showSearch = false;
  }

  setValueChangeWard() {
    this.form.get('address').setValue(null);
    this.showSearch = false;
  }

  //============================== Map ==============================//
  lat: number = 0;
  lng: number = 0;
  zoom: number = 15;
  fullAddress: string;
  fullAddress2: string;
  nameAddress: string;
  lengthAddress: number;
  placeId: string;
  showSearch: Boolean = true;
  isRequired: Boolean = false;
  delayTimer: any;

  searchResult($event) {
    this.placeId = $event.place_id;
    if ($event.place_id) {
      this.searchMap();
      if ($event.structured_formatting.secondary_text.split(',').length === 3) {
        this.form.get('address').setValue($event.description.split(',').slice(0, -3).join(','));
      } else {
        this.form.get('address').setValue($event.structured_formatting.secondary_text.split(',').slice(0, -3).join(','));
      }
      this.showSearch = false;
    }
  }

  searchMap() {
    this.showSearch = true;
    this.fullAddress = removeAllSpacingBetweenWords(this.form.value.address) + ' ' + this.wardName + ' ' + this.districtName + ' ' + this.cityName;
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      if (this.form.value.address.length > 0 && this.wardName != null && this.districtName != null && this.cityName != null) {
        this.isRequired = false;
        this.getAddress(this.fullAddress);
      } else {
        this.isRequired = true;
        this.showSearch = false;
      }
    }, 300);
  }

  getAddress(fullAddress: string) {
    this.goong.getAddress(fullAddress.trim()).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.lengthAddress = res.predictions.length;
        this.nameAddress = res.predictions;
        if (res.status === 'ZERO_RESULTS') {
          this.showSearch = false;
          // this.notification.create(
          //   'error',
          //   'Có lỗi xảy ra !!!',
          //   `Địa chỉ không tồn tại vui lòng nhập địa chỉ gần đúng.`
          // );
        } else {
          if (this.placeId) {
            this.getLocation(this.placeId);
          } else {
            this.getLocation(res.predictions[0].place_id);
          }
        }
      },
      (err) => {
      },
    )
  }

  getLocation(placeId: string) {
    this.goong.getLocation(placeId).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        if (res.status === 'OK') {
          this.lat = res.result.geometry.location.lat;
          this.lng = res.result.geometry.location.lng;
          return;
        }
        if (res.result.geometry.location.lat === 0 && res.result.geometry.location.lng === 0) {
          this.openMessage('error', 'Lỗi !!!', `Địa chỉ của bạn không có location vui lòng nhập địa chỉ khác.`);
        }
      },
      (err) => {
      },
      () => {
        this.form.get('lat').setValue(this.lat);
        this.form.get('lon').setValue(this.lng);
        this.markers = [];
        this.markers.push({
          lat: this.lat,
          lng: this.lng,
          draggable: true
        });
      },
    )
  }

  mapClicked($event?: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
    this.form.get('lat').setValue($event.coords.lat);
    this.form.get('lon').setValue($event.coords.lng);
  }

  markerDragEnd(m?: marker, $event?: MouseEvent) {
    this.form.get('lat').setValue($event.coords.lat);
    this.form.get('lon').setValue($event.coords.lng);
  }

  markers: marker[] = [
    {
      lat: this.lat,
      lng: this.lng,
      label: 'A',
      draggable: false,
    },
  ]
  //============================== END Map ==============================//

  //======================================================upload=====================================================//
  logos = [];
  covers = [];
  openUploadDialog(field: string) {
    let fileType = 'jpg,jpeg,png,gif,bmp';

    const modal = this.modalService.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      }
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'logo') {
            this.form.get('logo').setValue(changeParam.file.response.id);
            this.logos = [{ url: changeParam.file.response.url }];
          } else if (field === 'cover') {
            this.form.get('cover').setValue(changeParam.file.response.id);
            this.covers = [{ url: changeParam.file.response.url }];
          }
        }
      });
      modal.getContentComponent().done.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        modal.close();
      });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'logo') {
      this.logos = [];
      this.form.get('logo').setValue(null);
    } else if (field === 'cover') {
      this.covers = [];
      this.form.get('cover').setValue(null);
    }
  }
  //======================================================end upload=====================================================//

  isLoadingBtn: Boolean = false;
  onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }


    if ((this.form.value.lat === null && this.form.value.lon === null) || (this.form.value.lat === 0 && this.form.value.lon === 0)) {
      this.openMessage('error', 'Lỗi !!!', `Địa chỉ của bạn không có location vui lòng nhập địa chỉ khác.`);
      return;
    }
    this.isLoadingBtn = true;
    // let fullAddress2 = removeAllSpacingBetweenWords(this.form.value.address) + ', ' + this.wardName + ', ' + this.districtName + ', ' + this.cityName;
    let fullAddress2 = removeAllSpacingBetweenWords(this.form.value.address);

    let newForm = { ...this.form.value };
    newForm.shopName = capitalizeFirstLetter(removeAllSpacingBetweenWords(this.form.value.shopName));
    newForm.address = fullAddress2;
    newForm.country_id = 1
    if (this.type === 'updateShopProduct') {
      this.form.addControl('shopId', this.fb.control(this.productShopId));
      newForm.shopId = this.form.controls.shopId.value;
      this.updateStores.emit(newForm);
      this.form.removeControl('shopId');
      return;
    }

    if (this.type === 'updateShop') {
      let body: any = {
        shopId: this.shopId,
        body: newForm
      }
      this.updateStores.emit(body);
      return;
    }

    if (this.type === 'createShop') {
      this.createStores.emit(newForm);
      return;
    }
  }

  success() {
    this.isLoadingBtn = false;
  }

  setNull() {
    this.logos = [];
    this.covers = [];
    this.lat = null;
    this.lng = null;
    this.markers.push({
      lat: 0,
      lng: 0,
      draggable: true
    });
    this.form.reset();
  }

  onSubmit2() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      return;
    }

    this.isLoadingBtn = true;

    if ((this.form.value.lat === null && this.form.value.lon === null) || (this.form.value.lat === 0 && this.form.value.lon === 0)) {
      this.openMessage('error', 'Lỗi !!!', `Địa chỉ của bạn không có location vui lòng nhập địa chỉ khác.`);
    }

    let fullAddress2 = removeAllSpacingBetweenWords(this.form.value.address) + ', ' + this.wardName + ', ' + this.districtName + ', ' + this.cityName;

    let newForm = { ...this.form.value };
    newForm.shopName = capitalizeFirstLetter(removeAllSpacingBetweenWords(this.form.value.shopName));
    newForm.address = fullAddress2;
    newForm.country_id = 1

    if (this.type === 'createShopProduct') {
      this.shopService2.create(this.productId, newForm).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {
          this.isLoadingBtn = false;
          if (res.code === 1) {
            this.openMessage('success', 'Thành công', `Thêm điểm bán thành công`);
            this.shopService2.getActive(true);
            this.form.reset();
            return;
          }
          if (res.code !== 1) {
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          }
        },
        (err) => {
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.isLoadingBtn = false;
          return;
        },
      );
      return;
    }
  }

  handleCancel2() {
    this.shopService2.getActive(true);
    this.form.reset();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
