import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({providedIn: 'root'})
export class CustomerService extends BaseService {
  list(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/customer', httpOptions);
  }
  exportCustomer(query?: any) {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>('/customer/export', httpOptions);
  }
//  đối tượng
  listGroup(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/customer/group', httpOptions);
  }
  getGroupById(id?: any) {
    return this.get<any>(`/customer/group/${id}`);
  }
  updateGroup(id?: string, body?: any) {
    return this.put<any>(`/customer/group/${id}` ,body);
  }
  createGroup(body?: any) {
    return this.post<any>('/customer/group', body);
  }
  deleteGroup(body?: any) {
    return this.delete<any>('/customer/group', body);
  }
  getTotalCustomerOfGroup(body?: any) {
    return this.post<any>('/customer/group/number_cus', body);
  }
}
