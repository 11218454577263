import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {
  getFoodCategory(query?: any): Observable<any> {
    return this.get<any>('/food-category', { params: query });
  }

  createFoodCategory(body?: any): Observable<any> {
    return this.post<any>('/food-category', body);
  }

}
