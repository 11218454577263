import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseV2Service } from '../../base-v2.service';

@Injectable({
  providedIn: 'root',
})
export class StampV2Service extends BaseV2Service {
  getListQrcodeDesignExist(): Observable<any> {
    return this.get<any>(`/api/qrcode-configs`);
  }

  getListQrcodeDesignFrame(): Observable<any> {
    return this.get<any>(`/api/qrcode-frames`);
  }

  getAllTemplate(query?: any): Observable<any> {
    return this.get<any>('/api/templates', {
      params: query,
    });
  }
  genSvgToPng(formData: any): Observable<any> {
    return this.post<any>(`/api/system/svg-to-png`, formData);
  }

  createQrcode(payload: any) {
    return this.post<any>(`/api/qrcodes`, payload);
  }

  updateQrcode(id: string, payload: any) {
    return this.patch<any>(`/api/qrcodes/${id}`, payload);
  }

  getListStamp(query?): Observable<any> {
    return this.get<any>(`/api/qrcodes`, {
      params: query,
    });
  }

  getStampDetail(id: string): Observable<any> {
    return this.get<any>(`/api/qrcodes/${id}`);
  }

  downloadQrcodePdf(link): Observable<any> {
    return this.post(`/api/system/png-to-pdf`, {link});
  }
}
