import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsDistributorsService extends BaseService {

  listDistributor(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>('product/business/search', httpOptions);
  }

  detailDistributor(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>(`product/business/detail/${query.businessProductId}`);
  }

  addDistributor(body): Observable<any> {
    return this.post<any>(`product/business`, body);
  }

  searchDistributor(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>('product/distributor/search', httpOptions);
  }

  addIdDistributor(body): Observable<any> {
    return this.post<any>(`product/business`, body);
  }

  editDistributor(body): Observable<any> {
    return this.put<any>(`product/business`, body);
  }

  deleteDistributor(body): Observable<any> {
    return this.delete<any>(`product/business`, body);
  }

  exportExcel(query?: any) {
    const httpOptions = {
      params: query,
      responseType: 'blob'
    };
    return this.get<any>(`/product/distributor/export`, httpOptions);
  }

  listBusiness(query?: any): Observable<any> {
    const httpOptions = {
      params: query
    };
    return this.get<any>('business', httpOptions);
  }

  updateStatus(body): Observable<any> {
    return this.post<any>('/product/business/status', body);
  }


}
