import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CompaniesService, ProductRelatedsService, ProductsService } from 'src/app/services';

@Component({
  selector: 'app-add-related-business',
  templateUrl: './add-related-business.component.html',
  styleUrls: ['./add-related-business.component.scss']
})
export class AddRelatedBusinessComponent implements OnInit {
  listTargetId;

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private  listBusinessService: ProductsService,
    private productRelatedsService: ProductRelatedsService,
    private notification: NzNotificationService,
    private companiesService: CompaniesService
  ) {
    const optionList$: Observable<string> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500));
    optionList$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
    });
  }
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();
  loading = false;
  unsubscribe$ = new Subject<void>();
  listBusiness = [];
  searchChange$ = new BehaviorSubject('');
  isLoading = false;
  isVisible = false;


  form = this.fb.group({
    business: [[], [Validators.required]]
  });

  loadListRelatedBusiness(listTargetId?) {
    const queryProduct = listTargetId.length ?  {
      page: 1,
      page_size: 100,
      excludes: listTargetId.length > 1 ? listTargetId.join(',') : listTargetId[0]
    } : {
      page: 1,
      page_size: 100
    };
    this.isLoading = true;
    this.listBusiness = [];
    this.companiesService.getList(queryProduct).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.code === 1) {
          const data = res.data.items;
          this.listBusiness = res.data;
          return;
        }
        this.listBusiness = [];
      },
      (error) => {
        this.isLoading = false;
        this.listBusiness = [];
      }
    );
  }

  showModal(listTargetId?: any) {
    this.listTargetId = listTargetId;
    this.loadListRelatedBusiness(listTargetId)
    this.form.reset();
    this.isVisible = true;
    this.searchChange$.next('');
  }

  handleCancel() {
    this.isVisible = false;
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  searchProduct(event) {
    this.searchChange$.next(event);
  }

  onSubmit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    // this.loading = true;
    const ids = this.form.controls.business.value;
    const listBusinessSelected = []
    ids.map(id => {
      this.listBusiness.map(item => {
        if (item.businessId === id) {
          listBusinessSelected.push(item)
        }
      })
    })
    this.notification.success(
      'Thành công',
      'Thêm mới doanh nghiệp liên quan thành công'
    );
    this.emitData.emit(listBusinessSelected);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
