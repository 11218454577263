import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerService, DashboardService, ProductsAnalyticsService, ProductsService, RootService } from 'src/app/services';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
declare var $: any;

@Component({
  selector: 'app-stamp-statistic',
  templateUrl: './stamp-statistic.component.html',
  styleUrls: ['./stamp-statistic.component.scss']
})
export class StampStatisticComponent implements OnInit {
  loadingChart = false;
  unsubscribe$ = new Subject<void>();
  tabValue;
  socket: any;
  listRealtime = [];
  pageSize = 8;
  pageIndex = 1;
  disconnectIo = true;
  action = 'scan';
  dateRange = [];
  dataTimeLine = [];
  categoryLine = [];
  timeLabel;
  startTime;
  customers;
  endTime;
  dataTimePie = [];
  queryChartLine;
  isVisible = false;
  // statusPhone = false;
  actions = [
    {
      label: 'Scan',
      value: 'scan',
    },
    {
      label: 'Đánh giá',
      value: 'review',
    },
    {
      label: 'Hỏi đáp',
      value: 'question',
    },
  ];
  bg = 0;
  summary;
  loading = true;
  listSupcription;
  actionText = 'scan';
  paddingTopBox: string;
  paddingBottomBox: string;
  stampId: any;
  // @ts-ignore
  constructor(
    // private translate: TranslateService,
    private customerService: CustomerService,
    private dashboardService: DashboardService,
    private rootService: RootService,
    private stampService: StampService,
    private notification: NzNotificationService,
  ) {
    // translate.setDefaultLang('vi');
    // test


    this.rootService.scanCurrentMessage
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((base64) => {
        if (base64 !== false) {
          this.bg += 1;
          setTimeout(() => {
            this.bg -= 1;
          }, 3000);

          if (typeof base64 === 'string') {
            this.listRealtime.unshift(JSON.parse(this.b64_to_utf8(base64)));
            if (this.listRealtime.length > this.pageSize) {
              this.listRealtime.length = this.pageSize;
            }
            this.listRealtime = this.listRealtime.map((item) => ({...item}));
          }
        }
      });
    this.rootService.disconnetStatusMessage
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mes) => {
        if (mes !== 'none') {
          this.disconnectIo = mes;
        }
      });
  }

  resetData() {
    this.startTime = '';
    this.endTime = '';
    this.dateRange = [];

    this.changeTab('month')
  }

  ngOnInit() {
    this.stampId = window.location.href.split("/")[5];
    this.changeTab('month');

    // show baner
    if (sessionStorage.getItem('showBanner') !== 'true') {
      this.isVisible = true;
    }
    sessionStorage.setItem('showBanner', 'true');
    this.loadReportUser();
  }

  currentReportUser: any;
  productPercent = 0;
  businessPercent = 0;
  stampPercent = 0;
  currentDataSummary: any;
  loadReportUser() {
    this.dashboardService.loadReportUser().pipe(takeUntil(this.unsubscribe$))
    .subscribe((res) => {
      this.currentReportUser = res.data.packageInfo;
      this.currentDataSummary = res.data;
      this.productPercent = Math.floor((res.data.totalProductAdded / res.data.productQuota) * 100);
      this.businessPercent = Math.floor((res.data.totalEnterpriseAdded / res.data.enterpriseQuota) * 100);
      this.stampPercent = Math.floor((res.data.totalStampAdded / res.data.stampQuota) * 100);
    });
  }

  getListScanHistory() {

    const query: any = {
      page: 1,
      pageSize: 5
    };
    this.stampService
      .getListScanStamp(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loading = false
          this.listRealtime = res.data;
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          return;
        }
      );
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  // hideModal(): void {
  //   // this.statusPhone = false;
  //   this.contactGroup.handleCancel();
  //
  // }

  showStatusPhone() {
    // this.contactGroup.showModal();
  }

  reloadPage() {
    location.reload();
  }

  // test() {
  //   this.bg += 1;
  //   setTimeout(() => {
  //     this.bg -= 1;
  //   }, 3000);
  //   this.listRealtime.unshift(JSON.parse(this.b64_to_utf8('eyJwcm9kdWN0SWQiOiJCbGFkQVJRciIsIm5hbWUiOiJGYWNlIFdoaXRlIFBlcmZlY3QgIiwiYmFyQ29kZSI6Ijg5Mzg1MTI3OTUxMzMiLCJpbWFnZSI6Imh0dHBzOi8vcGhvdG9zLmljaGVja2Nkbi5uZXQvaW1hZ2VzL29yaWdpbmFsLy1UaGVIdWxrL2MxZTkxZTA5NDcvMTQ3NjI5MDYxOV9hMDUzOWFlOGE1MDc0OWEzOWI0ZTZiZGQxNzlmNWQwZl9hZTVkMjguanBnIiwiYWRkcmVzcyI6bnVsbCwiYWN0aW9uVHlwZSI6InNjYW4ifQ==')));
  //   if (this.listRealtime.length > this.pageSize) {
  //     this.listRealtime.length = this.pageSize;
  //   }
  //   // this.listRealtime = this.listRealtime.map(item => ({...item}));
  // }

  /*Char*/
  changeTab(value: string) {
    this.dateRange = [];
    this.tabValue = value;
    if (this.tabValue === 'today') {
      this.startTime = this.formatDate(Date.now()).split(' ')[1];
      this.endTime = this.formatDate(Date.now()).split(' ')[1];
      this.timeLabel = 'hour';
    }

    if (this.tabValue === 'week') {
      const startDayInWeed =
        new Date().getDate() -
        new Date().getDay() +
        (new Date().getDay() === 0 ? -6 : 1);
      this.startTime = this.formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeed)).setHours(0, 0, 0, 1)
        ).getTime()
      ).split(' ')[1];
      // tslint:disable-next-line:max-line-length
      this.endTime = this.formatDate(
        new Date(
          new Date(new Date().setDate(startDayInWeed + 6)).setHours(
            23,
            59,
            59,
            999
          )
        ).getTime()
      ).split(' ')[1];
      this.timeLabel = 'day';
    }

    if (this.tabValue === 'month') {
      // tslint:disable-next-line:max-line-length
      this.startTime = this.formatDate(
        new Date(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
            0,
            0,
            0,
            1
          )
        ).getTime()
      ).split(' ')[1];
      // tslint:disable-next-line:max-line-length
      this.endTime = this.formatDate(
        new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ).setHours(23, 59, 59, 999)
        ).getTime()
      ).split(' ')[1];
      this.timeLabel = 'day';
    }

    setTimeout(() => {


      this.loadChart();
    }, 300);
  }

  actionSelect(event) {
    this.action = event;
    if (event === 'review') {
      this.actionText = 'đánh giá';
    } else if (event === 'question') {
      this.actionText = 'hỏi đáp';
    } else {
      this.actionText = event;
    }
    setTimeout(() => {
      this.loadChart();
    }, 300);
  }

  search() {
    this.loadingChart = true;
    if (this.dateRange.length > 0) {
      this.startTime = this.formatDate(this.dateRange[0].getTime()).split(
        ' '
      )[1];
      this.endTime = this.formatDate(this.dateRange[1].getTime()).split(' ')[1];
      // if (this.startTime === this.endTime) {
      //   this.timeLabel = 'hour';
      // } else {
      //   this.timeLabel = 'day';
      // }
      this.tabValue = 'day';
      setTimeout(() => {
        this.loadChart();
      }, 300);
    } else {
      this.changeTab(this.tabValue);
    }
  }

  loadChart() {
    this.categoryLine = [];
    this.dataTimeLine = [];
    this.dataTimePie = [];
    this.queryChartLine = {
      from: this.startTime,
      to: this.endTime,
      stampCode: this.stampId,
      type: this.timeLabel === 'hour' ? 1 : 2
      // action_type: this.action,
      // time_label: this.timeLabel,
    };

    this.loadingChart = true;
    this.dashboardService
      .charList(this.queryChartLine)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res) {
            res.data.chartScan.map((data) => {
              if (this.timeLabel === 'hour') {
                // tslint:disable-next-line:radix
                const hour =
                  parseInt(data.label.split(' ')[1].split(':')[0]) + 'h';
                this.categoryLine.push(hour);
              } else {


                // const day = data.time.toString().split('')[6] + '' + data.time.toString().split('')[7] + '/' + data.time.toString().split('')[4] + '' + data.time.toString().split('')[5];

                const day = data.label.split('/')[0] + '/' + data.label.split('/')[1];


                this.categoryLine.push(day);
              }

              this.dataTimeLine.push({y: data.value});
            });
            // tslint:disable-next-line:no-unused-expression
            res.data.locationReports.map((data) => {
              this.dataTimePie.push([data.provinceName, data.value]);
            });
            this.loadingChart = false;
          }
        },
        (err) => {
          this.highchartsLine();
          this.loadingChart = false;
          this.loadChartPie();
        },
        () => {
          this.highchartsLine();
          this.loadingChart = false;
          this.loadChartPie();
        }
      );
  }

  highchartsLine() {
    Highcharts.chart('chartLine', {
      chart: {
        type: 'area',
      },
      title: {
        align: 'left',
        text: '',
      },
      xAxis: {
        categories: this.categoryLine,
      },

      yAxis: {
        title: {
          text: '',
        },
      },
      // tooltip: {
      //   pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      // },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: `Số lượng ${this.actionText}`,
          colorByPoint: true,
          data: this.dataTimeLine,
          type: undefined,
        },
      ],
    });
  }

  loadChartPie() {
    Highcharts.chart('pie', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: `Tỉ lệ ${this.actionText}: <b>{point.percentage:.1f}%</b>`,
      },

      series: [
        {
          type: 'pie',
          allowPointSelect: true,
          keys: ['name', 'y', 'selected', 'sliced'],
          data: this.dataTimePie,
          showInLegend: true,
        },
      ],
    });
  }

  formatDate(timestamp) {
    const now = new Date(timestamp);
    const year = '' + now.getFullYear();
    let month = '' + (now.getMonth() + 1);
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = '' + now.getDate();
    if (day.length === 1) {
      day = '0' + day;
    }
    let hour = '' + now.getHours();
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    let minute = '' + now.getMinutes();
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    let second = '' + now.getSeconds();
    if (second.length === 1) {
      second = '0' + second;
    }
    return (
      hour + ':' + minute + ':' + second + ' ' + day + '/' + month + '/' + year
    );
  }

  /*End Char*/

  /*Socket*/
  $jquery() {
    // $(window).on('load', () => {

    setTimeout(() => {



      // tinh toan height phan tu de lay so luong hien thi
      let totalIndex = 0;
      let totalBox = 0;
      const arr = [];
      const lengthData = this.listRealtime.length > 8 ? 8 : this.listRealtime.length;
      for (let i = 0; i < lengthData; i++) {
        const checkIndex = `.checkIndex${i}`;
        if (totalBox + $(checkIndex).height() < 615) {
          totalBox += $(checkIndex).height();
          totalIndex += 1;
        }



      }
      if (lengthData >= 5) {
        this.paddingTopBox = ((615 - totalBox) / totalIndex) / 2 + 'px';
        this.paddingBottomBox = ((615 - totalBox) / totalIndex) / 2 + 'px';
      } else {
        this.paddingTopBox = '10px';
        this.paddingBottomBox = '10px';
      }

      this.listRealtime.length = totalIndex;

      // tinh toan hien thi nut Scan
      for (let i = 0; i < lengthData; i++) {
        const className = `.mydiv${i}`;
        arr.push($(className).width());
      }

      const status = $('.setBoxRealtimeLeft').width() - (82 + Math.max(...arr));
      $('.setBoxRealtimeProduct').css('width', Math.max(...arr));
      $('.setBoxRealtimeContent').css('width', status);
      $('.setBoxRealtimeContent').css('opacity', 1);

      this.loading = false;

    }, 1000);


    // });
  }

  // socketRealtime() {
  //   // tslint:disable-next-line:no-unused-expression
  //   // this.$jquery();
  //
  //   this.rootService.scanCurrentMessage
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe((base64) => {
  //       if (base64 !== false) {
  //         this.bg += 1;
  //         setTimeout(() => {
  //           this.bg -= 1;
  //         }, 3000);
  //
  //         if (typeof base64 === 'string') {
  //
  //
  //           this.listRealtime.unshift(JSON.parse(this.b64_to_utf8(base64)));
  //
  //           if (this.listRealtime.length > this.pageSize) {
  //             this.listRealtime.length = this.pageSize;
  //           }
  //
  //
  //           this.listRealtime = this.listRealtime.map((item) => ({...item}));
  //           this.$jquery();
  //         }
  //       }
  //     });
  //   this.rootService.disconnetStatusMessage
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe((mes) => {
  //       if (mes !== 'none') {
  //         this.disconnectIo = mes;
  //       }
  //     });
  // }

  // connectIo() {
  //   this.rootService.connectIo(true);
  //   // this.socket.connect();
  // }

  b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  /*End Socket*/

  // useLanguage(language: string) {
  //   this.translate.use(language);
  // }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // this.socket.close();
  }

}
