import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { track } from 'src/app/helpers/track';
import {StampV2Service} from '../../../../services';
@Component({
  selector: 'app-loyalty-campaign',
  templateUrl: './loyalty-campaign.component.html',
  styleUrls: ['./loyalty-campaign.component.scss']
})
export class LoyaltyCampaignComponent implements OnInit {

  radioValue = 1;
  currentStamp: any;
  onlyFree = false;

  constructor(private stampService: StampService,
              private route: ActivatedRoute, private stampV2Service: StampV2Service) { }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_loyalty_setting',
    //   contentType: 'qrcode',
    // })
    const id = window.location.href.split('/')[5];
    // this.getListPackageActive();
    if (id !== 'null') {
      this.stampService
        .getStampById(id)
        .subscribe(
          (res) => {
            if (res.code === 1 && res.data) {
              this.currentStamp = res.data;
              this.onlyFree = res.data.type === 8;
              // if (res.data.type === 8) {
              //   this.radioValue = 8;
              // } else {
              //   this.radioValue = res.data && res.data.loyaltyCampaign && res.data.loyaltyCampaign.type ? res.data.loyaltyCampaign.type : 1;
              // }
              // if (this.currentStamp && this.currentStamp.loyaltyCampaignAll && this.currentStamp.loyaltyCampaignAll.code) {
              //   this.radioValue = 3;
              // } else {
              //   this.radioValue = res.data && res.data.loyaltyCampaign && res.data.loyaltyCampaign.type ? res.data.loyaltyCampaign.type : 1;
              // }
              this.radioValue = res.data && res.data.loyaltyCampaign && res.data.loyaltyCampaign.type ? res.data.loyaltyCampaign.type : 1;
              // console.log("res.data", res.data)
            }
          }
        );
    }
  }

  changeAssigeType() {
  }

}
