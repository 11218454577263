import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/business/user/user.service';

@Component({
  selector: 'app-user-export-share',
  templateUrl: './user-export-share.component.html',
  styleUrls: ['./user-export-share.component.scss']
})
export class UserExportShareComponent implements OnInit {
  isVisible: boolean = false;
  unsubscribe$ = new Subject<void>();
  title: string = "";
  loading: boolean = false;
  userInfo: any;
  form: FormGroup;

  indeterminate = true;

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private notification: NzNotificationService) { }

  ngOnInit() {
  }


  listField = []
  currentQuery: any;
  showModal(query) {
    this.currentQuery = query;
    let group = {}
    this.userService.getFieldsExport().subscribe(res => {
      this.listField = res.data;
      res.data.map(item => {

        if (item.id < 0) {
          group[item.id] = new FormControl(true);
        } else {
          group[item.id] = new FormControl(false);
        }

      })

      // group['checkAll'] = new FormControl(false);
      this.form = new FormGroup(group);
      })
    this.isVisible = true;
    this.title = "Xuất excel danh sách khách hàng";
  }

  checkValue(event) {
    // for (const property in this.form.value) {
    //   if (!this.form.value[property]) {
    //     this.listField.map(item => {
    //       this.form.controls[item.id].setValue(true);
    //     })
    //     this.form.controls['checkAll'].setValue(true);
    //     this.indeterminate = false
    //     return;
    //   }else {
    //     this.listField.map(item => {
    //       this.form.controls[item.id].setValue(false);
    //     })
    //     this.indeterminate = false
    //     return;
    //   }
    // }
  }

  changeStatusField() {
    // let result = {}, key;

    // for (key in this.form.value) {
    //     if (this.form.value.hasOwnProperty(key) && !this.form.value[key] && key !== 'checkAll') {
    //         result[key] = this.form.value[key];
    //     }
    // }
    // if (!Object.keys(result).length) {
    //   this.indeterminate = false;
    //   this.form.controls['checkAll'].setValue(true);
    // } else {
    //   if (Object.keys(result).length === Object.keys(this.form.value).length) {
    //     this.indeterminate = false;
    //     this.form.controls['checkAll'].setValue(false);
    //   } else{
    //     this.indeterminate = true;
    //     this.form.controls['checkAll'].setValue(false);
    //   }
    // }
  }

  handleOk() {
    let listKey = [], key;
    for (key in this.form.value) {
      if (this.form.value.hasOwnProperty(key) && this.form.value[key]) {
        listKey.push(key)
      }
    }
    const params = {
      ...this.currentQuery,
      fieldRequest: listKey.reverse().join(','),
    }
    this.userService.exportExport(params).pipe(
    ).subscribe((x: any) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = 'danh_sach_khach_hang' + new Date().getTime();
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);

        this.isVisible = false
      }, err => {
        this.openMessage("error", "Thao tác không hợp lệ !!!", `${err}`);
      });


  }


  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }


  handleCancel() {
    this.changePositionBody();
    this.isVisible = false;
  }

  changePositionBody() {
    document.body.style.position = 'unset';
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    document.body.style.position = 'unset';

  }

}
