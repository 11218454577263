import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  @Input() img = null;
  @ViewChild('bigImage', { read: ElementRef, static: false })  bigImage: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
     if (this.bigImage && !this.bigImage.nativeElement.contains(event.target)) {
      this.showImage = false;
     }
  }

  constructor(private modalService: NzModalService,
    private elementRef: ElementRef) { }

  ngOnInit() {
  }

  tplModal: NzModalRef;
  showImage = false;
  createTplModal(img) {
    if (img === null) return;
    this.showImage = true;
  }

}
