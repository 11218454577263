import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { capitalizeFirstLetter, removeAllSpacingBetweenWords } from 'src/app/helpers';
import {BusinessCodeService, CustomerService, DistributorsService, MediasService, ProductsService, StampV2Service} from 'src/app/services';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { UploaderComponent } from 'src/app/shared';
import { track } from 'src/app/helpers/track';
declare var $: any;

@Component({
  selector: 'app-stamp-modal',
  templateUrl: './stamp-modal.component.html',
  styleUrls: ['./stamp-modal.component.scss']
})



export class StampModalComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private stampService: StampService,
    private stampV2Service: StampV2Service,
  ) {
    // @ts-ignore

  }

  radioValue = 1;
  typeQRcode: any;
  typeQRcodeOld: any;
  id;
  isUpdate = false;
  currentId = 1;
  STAMP_CODE = {
    PRODUCT: 'Sản phẩm',
    LINK: 'Link',
    OBJECT: 'Doanh nghiệp, cá nhân',
    VNPAY: 'QR Code',
    SURVEY: 'Khảo sát',
    PDF: 'PDF',
    IMAGE: 'Hình ảnh',
    VIDEO: 'Video',
    MP3: 'Mp3',
    SOCIAL_NETWORK: 'Mạng xã hội',
    ECOMMERCE: 'Trang bán hàng',
    CHECKIN: 'Checkin',
    MENU: 'Trang thực đơn',
    LOYALTY: 'Loyalty',
    APP: 'app'
  };
  listSidebar = [
    {
      id: 1,
      label: 'Sản phẩm',
      labelStamp: 'QR Code Sản phẩm',
      src: '/assets/ic-qrcode/ic-product.png',
      stampCode: 'PRODUCT',
      index: 1,
    },
    {
      id: 2,
      label: 'Link',
      labelStamp: 'QR Code Link',
      src: '/assets/ic-qrcode/ic-link.png',
      stampCode: 'LINK',
      index: 2,
    },
    {
      id: 3,
      label: 'Doanh nghiệp, cá nhân',
      labelStamp: 'QR Code Doanh nghiệp, cá nhân',
      src: '/assets/ic-qrcode/ic-object.png',
      stampCode: 'OBJECT',
      index: 3,
    },
    {
      id: 12,
      label: 'QR Code',
      labelStamp: 'QR Code VNPay',
      src: '/assets/ic-qrcode/ic-qrcode.png',
      stampCode: 'VNPAY',
      index: 4,
    },
    {
      id: 4,
      label: 'Khảo sát',
      labelStamp: 'QR Code Khảo sát',
      src: '/assets/ic-qrcode/ic-survey.png',
      stampCode: 'SURVEY',
      index: 5,
    },
    {
      id: 6,
      label: 'PDF',
      labelStamp: 'QR Code PDF',
      src: '/assets/ic-qrcode/ic-pdf.png',
      stampCode: 'PDF',
      index: 6,
    },
    {
      id: 6,
      label: 'Hình ảnh',
      labelStamp: 'QR Code Hình ảnh',
      src: '/assets/ic-qrcode/ic-image.png',
      stampCode: 'IMAGE',
      index: 7,
    },
    {
      id: 6,
      label: 'Video',
      labelStamp: 'QR Code Video',
      src: '/assets/ic-qrcode/ic-video.png',
      stampCode: 'VIDEO',
      index: 8,
    },
    {
      id: 6,
      label: 'MP3',
      labelStamp: 'QR Code MP3',
      src: '/assets/ic-qrcode/ic-mp3.png',
      stampCode: 'MP3',
      index: 9,
    },
    {
      id: 6,
      label: 'Mạng xã hội',
      labelStamp: 'QR Code Mạng xã hội',
      src: '/assets/ic-qrcode/ic-social.png',
      stampCode: 'SOCIAL_NETWORK',
      index: 10,
    },
    {
      id: 6,
      label: 'Trang bán hàng',
      labelStamp: 'QR Code Trang bán hàng',
      src: '/assets/ic-qrcode/ic-ecomerce.png',
      stampCode: 'ECOMMERCE',
      index: 11,
    },
    {
      id: 8,
      label: 'Checkin',
      labelStamp: 'QR Code Checkin',
      src: '/assets/ic-qrcode/ic-checkin.png',
      stampCode: 'CHECKIN',
      index: 12,
    },
    {
      id: 9,
      label: 'Trang thực đơn',
      labelStamp: 'QR Code Trang thực đơn',
      src: '/assets/ic-qrcode/menu-board.png',
      stampCode: 'MENU',
      index: 13,
    },
    {
      id: 10,
      label: 'Loyalty',
      labelStamp: 'QR Code Loyalty',
      src: '/assets/ic-qrcode/ic-gift.png',
      stampCode: 'LOYALTY',
      index: 14,
    },
    {
      id: 11,
      label: 'App',
      labelStamp: 'QR Code App',
      src: '/assets/ic-qrcode/ic-app.png',
      stampCode: 'APP',
      index: 15,
    },
  ];
  listTypeQRCode = [];
  stampCode = 'PRODUCT';
  TYPE = {
    9: 'MENU'
  };

  CURRENT_ID = {
    9: 9,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    6: 6,
    8: 8,
    10: 10,
    11: 11,
  };

  onlyFree = false;
  isShowPreview = false;
  isLoading = false;
  isVisible = false;
  STAMP_TYPE = {
    PRODUCT: 1,
    LINK: 2,
    OBJECT: 3,
    SURVEY: 4,
    ORDER: 7,
    IMAGE: 6,
    PDF: 6,
    VIDEO: 6,
    MP3: 6,
    ECOMMERCE: 6,
    SOCIAL_NETWORK: 6,
    MENU: 9,
    CHECKIN: 8,
    LOYALTY: 10,
    APP: 11,
  };
  listPackage = [];

  isShowAlert = false;
  isChangeStampTypeQRCode = false;

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_stamp_form',
    //   contentType: 'qrcode',
    // })
    this.getListPackageActive();
    this.route.queryParams.subscribe(params => {
      if (params.type) {
        this.currentId = +params.type;
        switch (+params.type) {
          case 1:
            this.stampCode = 'PRODUCT';
            break;
          case 2:
            this.stampCode = 'LINK';
            break;
          case 3:
            this.stampCode = 'OBJECT';
            break;
          case 4:
            this.stampCode = 'SURVEY';
            break;
          case 8:
            this.stampCode = 'CHECKIN';
            break;
          case 9:
            this.stampCode = 'MENU';
            break;
          case 10:
            this.stampCode = 'LOYALTY';
            break;
          case 11:
            this.stampCode = 'APP';
            break;
          default:
            break;
        }
      }

    });
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id !== 'null') {
      this.isUpdate = true;
      this.stampService.getStampById(this.id).subscribe((response) => {
        const stampType = response.data.packageInfo.stampType;
        console.log("stampType", stampType);
        console.log("response", response);
        if (stampType && stampType.length) {
          const listSidebar = [...this.listSidebar];
          this.listTypeQRCode = listSidebar.filter(bar => stampType.find(stamp => bar.id == stamp)).filter(b => b.id !== 12);
          console.log("this.listTypeQRCode", this.listTypeQRCode)
        } else {
          this.listTypeQRCode = [...this.listSidebar].filter(b => b.id !== 12);
        }
        this.stampCode = response.data && response.data.stampCode ? response.data.stampCode : null;
        this.currentId = this.CURRENT_ID[response.data.type];
        const stamps = this.listSidebar.filter(item => item.id === this.currentId && this.stampCode === item.stampCode);
        if (stamps && stamps.length) {
          this.typeQRcode = stamps[0].index;
          this.typeQRcodeOld = stamps[0].index;
        } else {
          const items = this.listSidebar.filter(item => item.id === this.currentId);
          this.typeQRcode = items && items.length ? items[0].index : null;
          this.typeQRcodeOld = items && items.length ? items[0].index : null;
        }
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      });
      // this.stampV2Service.getStampDetail(this.id).subscribe(
      //     (res) => {
      //       this.currentId = res.data.linkType;
      //       if (res.data.stampData && res.data.stampData.code) {
      //         this.stampCode = res.data.stampData.code;
      //       }
      //     },
      //   )
    } else {
      this.isUpdate = false;
      setTimeout(() => {
        this.isLoading = true;
      }, 500);
    }
  }
  getListPackageActive() {
    this.stampService.getListPackageActive({ type: 1, stampType: this.STAMP_TYPE[this.stampCode] }).subscribe(res => {
      if (res.code === 1) {
        if (res.data.length) {
          this.onlyFree = true;
          this.radioValue = 8;
        } else {
          this.onlyFree = false;
          if (this.id === 'null') {
            this.isShowPreview = true;
          }
        }
        this.listPackage = res.data;
      }
    });
  }
  onChangeSidebar(index) {
    if (this.listSidebar[index].id === 12) {
      const originalHost = location.origin;
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = originalHost + '/qrcode-free/generate/QRCODE_VNPAY';
      a.click();
      return;
    }
    this.currentId = this.listSidebar[index].id;
    this.stampCode = this.listSidebar[index].stampCode;
    this.getListPackageActive();
  }

  onChangeTypeQRCode(ev) {
    const stamps = this.listSidebar.filter(item => item.index === ev);
    if (stamps && stamps.length) {
      this.currentId = stamps[0].id;
      this.stampCode = stamps[0].stampCode;
    }
    this.isShowAlert = true;
    this.typeQRcode = ev;
    this.isChangeStampTypeQRCode = this.typeQRcode !== this.typeQRcodeOld;
  }

  onCancel() {
    this.typeQRcode = this.typeQRcodeOld;
    const sideBar = this.listTypeQRCode.filter(item => item.index === this.typeQRcode);
    if (sideBar && sideBar.length) {
      this.currentId = sideBar[0].id;
      this.stampCode = sideBar[0].stampCode;
    }
    this.isShowAlert = false;
    this.isChangeStampTypeQRCode = false;
  }

}
