import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {Subject} from 'rxjs';
import {ProductRelatedsService, ProductsService, StampV2Service} from 'src/app/services';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {QrcodeDesignComponent} from '../../../shared/qrcode-design/qrcode-design.component';
import {StampService} from '../../../services/business/stamp/stamp.service';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import JSZipUtils from 'jszip-utils';
import {jsPDF} from 'jspdf';

@Component({
  selector: 'app-type-image-modal',
  templateUrl: './type-image-modal.component.html',
  styleUrls: ['./type-image-modal.component.scss']
})
export class TypeImageModalComponent implements OnInit, OnDestroy {
  @Input() listSvg;

  constructor(
    private modal: NzModalService,
    private fb: FormBuilder,
    private productsService: ProductsService,
    private productRelatedsService: ProductRelatedsService,
    private notification: NzNotificationService,
    private stampService: StampService,
    private stampV2Service: StampV2Service,
  ) {
  }

  @ViewChild('qrcodeDesignComponentRef', {static: true}) qrcodeDesignComponentRef: QrcodeDesignComponent;
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();
  loading = false;
  unsubscribe$ = new Subject<void>();
  products = [];
  isLoading = false;
  isVisible = false;
  selectedStamps;
  currentStamp: any;
  isMultiple;
  qrCode: any;
  form = this.fb.group({
    type: [[], [Validators.required]]
  });
  isNewQr;
  setCheckName = new Set();
  docPdf = null;

  async showModal(data?: any) {
    this.isMultiple = Array.isArray(data);
    this.selectedStamps = this.isMultiple ? [...data] : [{...data}];
    await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
    await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
    this.form.reset();
    this.isVisible = true;
  }

  testImage(url, timeout) {
    const self = this;
    timeout = timeout || 15000;
    let timedOut = false;
    let timer;
    const img = new Image();
    img.onerror = img.onabort = () => {
      if (!timedOut) {
        clearTimeout(timer);
        return false;
      }
    };
    img.onload = () => {
      if (!timedOut) {
        clearTimeout(timer);
        self.qrCode.update({
          image: url || '',
        });
      }
    };
    img.src = url;
    timer = setTimeout(() => {
      timedOut = true;
      img.src = '//!!!!/test.jpg';
      return false;
    }, timeout);
  }

  handleCancel() {
    this.isVisible = false;
    this.setCheckName = new Set<any>();
  }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
  }

  async onSubmit() {
    this.setCheckName = new Set<any>();
    const zip = new JSZip();
    const length = this.selectedStamps.length;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < length; i++) {
      const data = this.selectedStamps[i];
      if (!data.isNewQr) {
        if (this.form.value.type === 'pdf') {
          if (length > 1) {
            if (!data.qrImage) {
              return this.notification.create('warning', 'Tem không tải được định dạng PDF', 'Xin vui lòng thử lại');
            }
            this.isLoading = true;
            try {
              this.promiseDownloadPdfQrcode(data, i, length, this.selectedStamps[i + 1]);
              this.isLoading = false;
            } catch (e) {
              this.notification.error('Có lỗi xảy ra', 'Vui lòng thử lại');
              this.isLoading = false;
            }
          } else {
            if (data.qrImage) {
              this.downloadPdfQrcode(data);
            } else {
              this.notification.create('warning', 'Tem không tải được định dạng PDF', 'Xin vui lòng thử lại');
            }
          }
        } else {
          if (data.qrImage && (this.form.value.type === 'png' || this.form.value.type === 'jpeg')) {
            if (length > 1) {
              this.exportZipFileFromUrl(zip, data.name.trim().replaceAll(',', ''), data.qrImage, this.form.value.type, i);
            } else {
              await this.downloadImage(data);
            }
          } else {
            if (data.config && data.config.logo) {
              this.testImage(data.config.logo, 5000);
            }
            const qrcodeInstance = new QRCodeStyling({
              qrOptions: {
                mode: 'Byte',
                errorCorrectionLevel: 'H',
              },
              width: 1000,
              height: 1000,
              margin: 4,
              type: 'svg',
              data: data && data.url ? data.url : 'Type something...',
              dotsOptions: {
                color: data &&
                data.config &&
                data.config.color ? data.config.color : '#000000',
                type: data && data.config.shape ? data.config.shape : 'square'
              },
              backgroundOptions: {
                color: data &&
                data.config &&
                data.config.backgroundColor ? data.config.backgroundColor : '#ffffff',
              },
              imageOptions: {
                crossOrigin: 'anonymous',
                margin: 4,
                imageSize: 0.45,
              },
              image: data.config.logo || ''
            });
            if (length > 1) {
              const blobUrl = await qrcodeInstance.getRawData(this.form.value.type);
              this.exportZipFileFromUrl(zip, data.name.trim().replaceAll(',', ''), URL.createObjectURL(blobUrl), this.form.value.type, i);
            } else {
              await qrcodeInstance.download({name: data.name.trim().replaceAll(',', ''), extension: this.form.value.type});
            }
          }
        }
      } else {
        if (this.form.value.type === 'pdf') {
          if (length === 1) {
            this.downloadPdfQrcode(data);
          } else {
            this.isLoading = true;
            try {
              this.promiseDownloadPdfQrcode(data, i, length, this.selectedStamps[i + 1]);
              this.isLoading = false;
            } catch (e) {
              this.notification.error('Có lỗi xảy ra', 'Vui lòng thử lại');
              this.isLoading = false;
            }
          }
        } else if (this.form.value.type === 'svg') {
          if (length > 1) {
            this.isLoading = true;
            const blobSvg = await this.promiseGetBlobUrlCoreQrcodeSvg(data);
            this.exportZipFileFromUrl(zip, data.name.trim().replaceAll('.', ''), blobSvg, this.form.value.type, i);
            this.isLoading = false;
          } else {
            this.isLoading = true;
            await this.promiseDownloadQrcodeSvg(data);
            this.isLoading = false;
          }
        } else {
          if (length > 1) {
            this.exportZipFileFromUrl(zip, data.name.trim().replaceAll(',', ''), data.qrImage, this.form.value.type, i);
          } else {
            await this.downloadImage(data);
          }
        }
      }
    }
  }

  exportZipFileFromUrl(zip, name, url, ext, index) {
    this.isLoading = true;
    const length = this.selectedStamps.length;
    JSZipUtils.getBinaryContent(url, async (err, tmpData) => {
      if (err) {
        this.isLoading = false;
        throw err;
      }
      const qrFile: any = await this.urlToPromise(url);
      if (this.setCheckName.has(name)) {
        const tmpName = name.replace(/[\/\\~\-.]/g, '');
        zip.file(`${tmpName}-${index}.${ext}`, qrFile, {binary: true});
      } else {
        const tmpName = name.replace(/[\/\\~\-.]/g, '');
        zip.file(`${tmpName}.${ext}`, qrFile, {binary: true});
        this.setCheckName.add(name);
      }
      if (index === length - 1) {
        zip.generateAsync({type: 'blob'}).then((content) => {
          FileSaver.saveAs(content, 'qrcode-marketing.zip');
        });
        this.isLoading = false;
      }
    });
  }

  urlToPromise(url) {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  promiseDownloadQrcodeSvg(data) {
    return new Promise((resolve) => {
      this.qrcodeDesignComponentRef.updateStampCode(data.code);
      this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType, data.config, data.frameConfig, data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
      setTimeout(() => {
        resolve();
      }, 2000);
    }).then(() => {
      this.qrcodeDesignComponentRef.downloadCoreQrcodeSvg(data.name.trim().replaceAll('.', ''));
    });
  }

  promiseGetBlobUrlCoreQrcodeSvg(data) {
    return new Promise((resolve) => {
      this.qrcodeDesignComponentRef.updateStampCode(data.code);
      this.qrcodeDesignComponentRef.updateQrcodeConfig(data.exportType, data.config, data.frameConfig, data.qrcodeFrames && data.qrcodeFrames.id ? data.qrcodeFrames.id : null);
      setTimeout(() => {
        resolve();
      }, 2000);
    }).then(() => {
      return this.qrcodeDesignComponentRef.getBlobUrlCoreQrcodeSvg(data.name.trim().replaceAll('.', ''));
    });
  }

  downloadPdfQrcode(data) {
    this.isLoading = true;
    let doc;
    if (data.qrcodeFrameId) {
      doc = new jsPDF({
        format: 'a4'
      });
    } else {
      doc = new jsPDF({
        unit: 'px',
        format: [794, 794]
      });
    }
    const widthDoc = doc.internal.pageSize.getWidth();
    const heightDoc = doc.internal.pageSize.getHeight();
    doc.addImage(data.qrImage, 0, 0, widthDoc, data.qrcodeFrameId ? heightDoc : widthDoc);
    // this.stampV2Service.downloadQrcodePdf(data.qrImage).subscribe(res => {
    //   if (res.status === 200) {
    //     this.downloadImage({
    //       name: data.name.replaceAll('.', ''),
    //       qrImage: res.data
    //     }).catch(err => console.log(err));
    //   }
    // }, error => {
    //   this.notification.create(
    //     'error',
    //     'Có lỗi xảy ra',
    //     'Vui lòng thử lại sau'
    //   );
    doc.save(data.name.trim().replaceAll('.', ''));
    this.isLoading = false;
    // });
  }

  promiseDownloadPdfQrcode(data, index, length, nextData?) {
    if (!this.docPdf) {
      if (data.qrcodeFrameId) {
        this.docPdf = new jsPDF({
          format: 'a4'
        });
      } else {
        this.docPdf = new jsPDF({
          unit: 'px',
          format: [794, 794]
        });
      }
    }
    const widthDoc = this.docPdf.internal.pageSize.getWidth();
    const heightDoc = this.docPdf.internal.pageSize.getHeight();
    this.docPdf.addImage(data.qrImage, 0, 0, widthDoc, data.qrcodeFrameId ? heightDoc : widthDoc);
    if (index !== length - 1) {
      if (nextData.qrcodeFrameId) {
        this.docPdf.addPage('a4');
      } else {
        this.docPdf.addPage([794, 794]);
      }
    } else {
      this.docPdf.save('qrcode-marketing.pdf');
    }
  }

  async downloadImage(data) {
    this.isLoading = true;
    const fileName = data.name ? data.name.trim().replaceAll('.', '') : 'qrcode-image';
    const a = document.createElement('a');
    a.href = await this.toDataURL(data.qrImage);
    a.download = `${fileName}.${this.form.value.type}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.isLoading = false;
  }

  toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
