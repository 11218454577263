import {Injectable} from '@angular/core';
import {BaseService} from '../../base.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends BaseService {

  listNotify(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/notification', httpOptions);
  }

  countNotify() {
    return this.get<any>('/notification/stats-new');
  }

  seenNotify(id: any): Observable<any> {
    return this.put<any>(`/notification/${id}/seen`);
  }

  seenAll(): Observable<any> {
    return this.put<any>(`/notification/seenAll`);
  }

}
