import { Component, OnInit } from '@angular/core';
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {BehaviorSubject, Subject} from "rxjs";
import {HomeService} from "../services/home/home.service";
import {NzNotificationService} from "ng-zorro-antd";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../auth";

@Component({
  selector: 'app-support-business',
  templateUrl: './support-business.component.html',
  styleUrls: ['./support-business.component.scss']
})
export class SupportBusinessComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  page = 1;
  pageSize = 50;
  query;
  loading;
  businesses = [];
  form = this.fb.group({
    key: ''
  });
  searchChange$ = new BehaviorSubject('');

  constructor(
    private authService: AuthService,
    private router: Router,
    private homeService: HomeService,
    private notification: NzNotificationService,
    private fb: FormBuilder,
  ) {
    this.loading = true;
    this.searchChange$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      switchMap(value => {
        const query = {
          page: this.page,
          page_size: this.pageSize,
          keyword: value.trim()
        };
        return this.homeService.list(query);
      }),
    ).subscribe(
      res => {
        this.loading = false;
        if (res.code === 1) {
          this.businesses = res.data;
          console.log(this.businesses, "businesses")
          return;
        } else {
          this.notification.create(
            'error',
            'Lấy danh sách danh nghiệp thất bại',
            res.message
          );
        }
      },
      error => {
        this.loading = false;
        this.notification.create(
          'error',
          'Lấy danh sách doanh nghiệp thất bại',
          'Hệ thống đang nâng cấp.Vui lòng thử lại sau'
        );
      }
    );
  }

  async ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight',
      nzDuration: 1500
    });
    const currentUser = this.authService.currentUserValue;
    if (currentUser.userInfo.userType !== 5) {
      this.notification.error(
        'Thất bại',
        'Tài khoản của bạn không có quyền truy cập vào trang này'
      );
      await this.router.navigate(['/portal/dashboard']);
      return;
    }
  }

  searchBusiness(event) {
    this.loading = true;
    this.searchChange$.next(event);
  }

  selectedBusiness(id) {
    this.homeService.select(id).subscribe(
      async res => {
        if (res.code === 1) {
          await this.router.navigate(['/portal/dashboard']);
          return;
        }
        this.notification.create(
          'error',
          'Chọn tài khoản thất bại',
          res.message
        );
      },
      error => {
        this.notification.create(
          'error',
          'Chọn tài khoản thất bại',
          'Hệ thống đang nâng cấp.Vui lòng thử lại sau'
        );
      }
    );
  }

  logOut() {
    this.authService.logout();
    // this.notification.create(
    //   'success',
    //   'Thành công',
    //   'Đăng xuất thành công'
    // );
    const originalHost = location.origin;
    const a = document.createElement('a');
    a.href = originalHost + '/qrcode-free/generate/TEXT';
    a.click();
    this.notification.create('success', 'Thành công', 'Đăng xuất thành công');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
