import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-qrcode-mp3-preview',
  templateUrl: './qrcode-mp3-preview.component.html',
  styleUrls: ['./qrcode-mp3-preview.component.scss']
})
export class QrcodeMp3PreviewComponent implements OnInit {
  @Input() templateData: Observable<any>;
  unsubscribe$ = new Subject<void>();
  data: any = null;
  timeout: any;
  loading = false;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.templateData.subscribe((val: any) => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loading = true;
        setTimeout(() => {
          if (val.pdf) {
            this.data = { ...val, mp3_link: this.sanitizer.bypassSecurityTrustResourceUrl(val.mp3[0].link) };
          } else {
            this.data = { ...val };
          }
          this.loading = false;
        }, 800);
      }, 800);
      // get detail product
    });
  }

}
