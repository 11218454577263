import { Injectable } from "@angular/core";
import { Subject, EMPTY } from "rxjs";
import { catchError, switchAll, tap } from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from "src/environments/environment";
import { BaseService } from "../../base.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService extends BaseService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject();
  messages$ = this.messagesSubject$.pipe(
    switchAll(),
    catchError((e) => {
      throw e;
    })
  );

  listAction(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/action-his", httpOptions);
  }

  listSupcription() {
    return this.get<any>("/subscription/stats");
  }

  charList(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/report/stamp", httpOptions);
  }

  summary() {
    return this.get<any>("/customer/summary");
  }

  loadReportUser() {
    return this.get<any>("/report/user");
  }

  connect(): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      const messages = this.socket$.pipe(
        tap({
          error: (error) => {
            throw error;
          },
        }),
        catchError((_) => EMPTY)
      );
      this.messagesSubject$.next(messages);
    }
  }

  getNewWebSocket() {
    return webSocket(environment.url_socketio_qrmkt);
  }

  sendMessage(msg: any) {
    this.socket$.next(msg);
  }
  close() {
    this.socket$.complete();
  }
}
