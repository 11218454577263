import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoongService {

  constructor(private http: HttpClient) { }

  getAddress(address): Observable<any> {
    return this.http.get<any>(`${environment.goong}/Place/AutoComplete?input=${address}&api_key=${environment.api_key_goong}`);
  }

  getLocation(location): Observable<any> {
    return this.http.get<any>(`${environment.goong}/Place/Detail?placeid=${location}&api_key=${environment.api_key_goong}`);
  }

}
