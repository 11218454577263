import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import QRCodeStyling from 'thanglv-qrcode-styling';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-qr-code-scan',
  templateUrl: './qr-code-scan.component.html',
  styleUrls: ['./qr-code-scan.component.scss']
})
export class QrCodeScanComponent implements OnInit {
  @Input() templateData: Observable<any>;
  @Input() qrcodeConfigPreview;
  @Input() isNewQr;
  @Input() loading;
  unsubscribe$ = new Subject<void>();
  data: any = null;
  @ViewChild('canvas', {static: true}) canvas: ElementRef;
  constructor(private sanitizer: DomSanitizer) {
  }

  qrCode = new QRCodeStyling({
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    width: 250,
    height: 250,
    backgroundOptions: {
      color: '#fff'
    },
    dotsOptions: {
      type: 'rounded',
      color: '#000000',
    },
    image: '',

    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
      imageSize: 0.45,
    },
    data: 'Nhập nội dung của bạn...'
  });

  ngOnInit() {
    if (!QRCodeStyling) {
      return;
    }
    console.log("isNewQr", this.isNewQr)
    if (!this.isNewQr) {
      this.templateData.subscribe((val: any) => {
        if (val.pdf) {
          this.data = {...val, pdf: this.sanitizer.bypassSecurityTrustResourceUrl(val.pdf), };
        } else {
          this.data = {...val};
        }
        if (this.data.config) {
          this.testImage(this.data.config.logo, 5000);
        }
        this.qrCode.update(
          {
            // image: this.data.config && this.data.config.logo ? this.data.config.logo : "",
            dotsOptions: {
              color: val.config && val.config.color ? val.config.color : '#000000',
              type: val.config && val.config.shape ? val.config.shape : ''
            },
            data: val.url ? val.url : 'Nhập nội dung của bạn...',
            backgroundOptions: {
              color: val.config && val.config.backgroundColor ? val.config.backgroundColor : '#fff'
            },
          }
        );
        // get detail product
        this.qrCode.append(this.canvas.nativeElement);
      });
    }
  }

  testImage(url, timeout) {
    const self = this;
    // if (!url) return;
    timeout = timeout || 15000;
    // tslint:disable-next-line:one-variable-per-declaration
    let timedOut = false, timer;
    const img = new Image();
    img.onerror = img.onabort = () => {
      if (!timedOut) {
        clearTimeout(timer);
        return false;
      }
    };
    img.onload = () => {
      if (!timedOut) {
        clearTimeout(timer);
        self.qrCode.update({
          image: url || '',
        });
      }
    };
    img.src = url;
    timer = setTimeout(() => {
      timedOut = true;
      img.src = '//!!!!/test.jpg';
      return false;
    }, timeout);
  }

}
