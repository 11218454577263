import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {StampService} from '../../../../services/business/stamp/stamp.service';
import {ActivatedRoute, Router} from '@angular/router';
import {base64toBlob, configCommon, isIncludeBase64Image} from '../../../../helpers';
import {UploaderComponent} from '../../../../shared';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {QrcodeDesignComponent} from '../../../../shared/qrcode-design/qrcode-design.component';
import {StampV2Service, UploadService} from '../../../../services';
import {MenuService} from '../../../../services/business/menu/menu.service';
import {AddCategoryComponent} from '../../../../shared/add-category/add-category.component';
import moment from 'moment';
import _ from 'lodash';
import {regexBase64Image} from "../../../../helpers/constant";
import {TemplateQrcodeViewComponent} from '../../template-qrcode-view/template-qrcode-view.component';

@Component({
  selector: 'app-qr-code-menu',
  templateUrl: './qr-code-menu.component.html',
  styleUrls: ['./qr-code-menu.component.scss']
})
export class QrCodeMenuComponent implements OnInit {
  constructor(private fb: FormBuilder,
              private stampService: StampService,
              private menuService: MenuService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: NzModalService,
              private stampV2Service: StampV2Service,
              private uploadService: UploadService,
              private notification: NzNotificationService) {
  }
  form: FormGroup;
  // listPackage: any = [];
  @Input() listPackage: any = [];
  @Input() isChangeStampTypeQRCode = false;
  isLoadingBtn = false;
  isConfiguration = true;
  isPromotion = true;
  isTem = false;
  isMenu = true;
  public config = configCommon;
  avatar: Array<any> = [];
  wallpaper: Array<any> = [];
  productImages = [];
  productImages2 = [];
  foodCategories = [];
  templates = [];
  isLoading = false;
    @ViewChild('qrcodeDesignComponentRef', {static: false}) qrcodeDesignComponentRef!: QrcodeDesignComponent;
  @ViewChild('templateQRCodeView', {static: false}) templateQRCodeViewRef!: TemplateQrcodeViewComponent;
  units = [
    {
      label: 'đ',
      value: 'đ'
    },
    {
      label: '$',
      value: '$'
    }
  ];
  menuId: string;

  listLinkSocial = [
    {
      image: '/assets/svg/icon-globe.svg',
      type: 'website',
      name: 'Website',
      placeholder: 'Nhập địa chỉ liên kết...',
      isActive: false
    },
    {
      image: '/assets/svg/icon-facebook.svg',
      type: 'facebook',
      name: 'Facebook',
      placeholder: 'Nhập địa chỉ liên kết...',
      isActive: false
    },
    {
      image: '/assets/svg/icon-zalo.svg',
      type: 'zalo',
      name: 'Zalo',
      placeholder: 'Nhập địa chỉ liên kết...',
      isActive: false
    },
    {
      image: '/assets/svg/icon-ig.svg',
      type: 'instagram',
      name: 'Instagram',
      placeholder: 'Nhập địa chỉ liên kết...',
      isActive: false
    },
    {
      image: '/assets/svg/icon-tiktok.svg',
      type: 'tiktok',
      name: 'Tiktok',
      placeholder: 'Nhập địa chỉ liên kết...',
      isActive: false
    }
  ];

  qrcodeConfigPreview: any;
  loadingDrawQrcode: any;
  templateData: any;
  listFoodSales: any = [];

  menu_id: any;

  ngOnInit() {
    this.menuId = this.route.snapshot.paramMap.get('id');

    this.initForm();
    this.getListPackageActive();
    this.getTemplate();
    this.getFoodCategory();

    this.form.valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.templateData = val;
    });

    this.form.get('isShowPhone').valueChanges.subscribe(val => {
      if (val) {
        this.form.get('phoneNumber').setValidators([Validators.required]);
        this.form.get('phoneNumber').updateValueAndValidity();
      } else {
        this.form.get('phoneNumber').clearValidators();
        this.form.get('phoneNumber').updateValueAndValidity();
      }
    });

    this.updateFormMenu();
    this.getListDomainByUser();
  }
  updateFormMenu() {
    if (this.menuId !== 'null') {
      this.stampService.getStampById(this.menuId).subscribe(async (res) => {
        if (res && res.code === 1) {

          this.qrcodeDesignComponentRef.onChangeIsUpdate();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignExist();
          await this.qrcodeDesignComponentRef.onGetListQrcodeDesignFrame();
          this.qrcodeDesignComponentRef.updateQrcodeConfig(res.data.exportType, res.data.config, res.data.frameConfig, res.data.qrcodeFrames && res.data.qrcodeFrames.id ? res.data.qrcodeFrames.id : null);
          this.qrcodeDesignComponentRef.updateStampCode(res.data.qrCode);
          this.form.get('ten_qr_code').setValue(res.data.name);
          if (res.data.packageInfo && res.data.packageInfo.id) {
            this.listPackage = _.uniqBy([res.data.packageInfo, ...this.listPackage ], 'id')
          }
          this.form.get('packageId').setValue(res.data.packageInfo.id);
          this.form.get('name').setValue(res.data && res.data.menuData && res.data.menuData.name ? res.data.menuData.name : null);
          this.form.get('description').setValue(res.data.menuData && res.data.menuData.description ? res.data.menuData.description : null);
          this.form.get('startTime').setValue(res.data.menuData && res.data.menuData.startTime ? new Date(moment(res.data.menuData.startTime, 'HH:mm').toISOString()) : null);
          this.form.get('endTime').setValue(res.data.menuData && res.data.menuData.endTime ? new Date(moment(res.data.menuData.endTime, 'HH:mm').toISOString()) : null);
          this.form.get('phoneNumber').setValue(res.data.menuData && res.data.menuData.phoneNumber ? res.data.menuData.phoneNumber : null);
          this.form.get('address').setValue(res.data.menuData && res.data.menuData.address ? res.data.menuData.address : null);
          this.form.get('website').setValue(res.data.menuData && res.data.menuData.website ? res.data.menuData.website : null);
          this.form.get('avatar').setValue(res.data.menuData && res.data.menuData.avatar ? res.data.menuData.avatar : null);
          if (res.data.menuData && res.data.menuData.avatar) {
            this.avatar = [res.data.menuData.avatar];
          }

          if (res.data.menuData && res.data.menuData.wallpaper) {
            this.wallpaper = [res.data.menuData.wallpaper];
          }
          this.form.get('wallpaper').setValue(res.data && res.data.menuData && res.data.menuData.wallpaper ? res.data.menuData.wallpaper : null);
          this.form.get('domainId').setValue(res.data && res.data.domainId ? res.data.domainId : null);
          this.form.get('isShowPhone').setValue(res.data && res.data.menuData && res.data.menuData.config && res.data.menuData.config.isShowPhone ? res.data.menuData.config.isShowPhone : false);
          this.form.get('templateId').setValue(res.data && res.data.templateId ? res.data.templateId : null);

          let activatedAt;
          if (res.data.activatedAt) {
            activatedAt = res.data.activatedAt.split('/').reverse().join('-');
          }
          let expiredAt;
          if (res.data.expiredAt) {
            expiredAt = res.data.expiredAt.split('/').reverse().join('-');
          }
          this.form.get('activatedAt').setValue(res.data && res.data.activatedAt ? moment(activatedAt).toDate() : null);
          this.form.get('expiredAt').setValue(res.data && res.data.expiredAt ? moment(expiredAt).toDate() : null);
          this.form.get('requiredCustomerInfo').setValue(res.data && res.data.setting && res.data.setting.requiredCustomerInfo ? res.data.setting.requiredCustomerInfo : false);
          this.form.get('customerInfoForm').setValue(res.data && res.data.setting && res.data.setting.customerInfoForm ? res.data.setting.customerInfoForm : false);
          this.form.get('allowedScan').setValue(res.data && res.data.setting && res.data.setting.allowedScan ? res.data.setting.allowedScan : false);
          if (res.data && res.data.menuData && res.data.menuData.foodSales && res.data.menuData.foodSales.length) {
            const foodSales = [];
            // tslint:disable-next-line:variable-name
            res.data.menuData.foodSales.forEach((food, _index) => {
              foodSales.push(food.foodId);
              if (_index === 0 && food.startTime && food.endTime) {
                this.form.get('timeSale').setValue([moment(food.startTime, 'DD/MM/YYYY HH:mm:ss').toDate(), moment(food.endTime, 'DD/MM/YYYY HH:mm:ss').toDate()]);
              }
            });
            this.form.get('foodSales').setValue(foodSales);
          }
          // foods
          // linkPages
          const linkPages = this.form.get('linkPages') as FormArray;
          if (res.data && res.data.menuData && res.data.menuData.linkPages && res.data.menuData.linkPages.length) {
            res.data.menuData.linkPages.forEach(item => {
              if (item.type === 'website') {
                this.listLinkSocial[0].isActive = true;
              }
              if (item.type === 'facebook') {
                this.listLinkSocial[1].isActive = true;
              }
              if (item.type === 'zalo') {
                this.listLinkSocial[2].isActive = true;
              }
              if (item.type === 'instagram') {
                this.listLinkSocial[3].isActive = true;
              }
              if (item.type === 'tiktok') {
                this.listLinkSocial[4].isActive = true;
              }
              linkPages.push(this.fb.group({
                code: [item.code],
                value: [
                  item.value,
                  [
                    Validators.pattern(
                      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
                    ),
                    Validators.required,
                  ],
                ],
                type: [item.type],
                name: [item.name],
                image: [item.image],
                placeholder: [item.placeholder],
              }));
            });
          }

          if (!this.isChangeStampTypeQRCode) {
            const foods = this.form.get('foods') as FormArray;
            foods.removeAt(0);
            this.listFoodSales = res.data && res.data.menuData && res.data.menuData.foods ? res.data.menuData.foods : [];
            this.menu_id = res.data && res.data.menuData && res.data.menuData.id ? res.data.menuData.id : null;
            [...this.listFoodSales].forEach((item, _index) => {
              // this.listFoodSales.push(item);
              foods.push(this.fb.group({
                categoryId: [item.categoryId, [Validators.required]],
                name: [item.name, [Validators.required]],
                price: [item.price, [Validators.required]],
                ccy: [item.ccy, [Validators.required]],
                priceSale: [item.priceSale || null],
                description: [item.description || null],
                photos: [item.photos || null],
                menuId: [item.menuId || null],
                id: [item.id || null],
                active: _index === 0
              }));
            });
          }
        }
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
      });
    } else  {
      this.isLoading = true;
    }
  }

  convertBase64ToUrl(base64): any {
    const formData = new FormData();
    formData.append('content', base64toBlob(base64), 'image-product-description.png');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.uploadService.uploadFile(formData, currentUser).toPromise().then(
      (result) => {
        if (result.status === 200 && result.data && result.data.url) {
          return result.data.url;
        }
      }
    );
  }

  async onSubmit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
      if (i === 'linkPages') {
        const control = this.form.get('linkPages') as FormArray;
        // tslint:disable-next-line:forin
        for (const j in control.controls) {
          const controlTwo = control.controls[j] as FormGroup;
          // tslint:disable-next-line:forin
          for (const k in controlTwo.controls) {
            controlTwo.controls[k].markAsDirty();
            controlTwo.controls[k].updateValueAndValidity();
          }
        }
      }

      if (i === 'foods') {
        const control = this.form.get('foods') as FormArray;
        // tslint:disable-next-line:forin
        for (const j in control.controls) {
          const controlTwo = control.controls[j] as FormGroup;
          // tslint:disable-next-line:forin
          for (const k in controlTwo.controls) {
            controlTwo.controls[k].markAsDirty();
            controlTwo.controls[k].updateValueAndValidity();
          }
        }
      }
    }
    if (this.form.valid) {
      const newForm = {
        // ...this.form.value
      } as any;
      this.isLoadingBtn = true;
      const base64QrcodeSvg = this.qrcodeDesignComponentRef.svgToDataUrl();
      newForm.config = {...this.qrcodeDesignComponentRef.getQrcodeDesignConfig()};
      newForm.qrcodeFrameId = this.qrcodeDesignComponentRef.getQrcodeFrameConfig().id;
      newForm.frameConfig = {
        title: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().title,
        content: this.qrcodeDesignComponentRef.getQrcodeFrameConfig().content,
      };
      newForm.exportType = this.qrcodeDesignComponentRef.getQrcodeShape();
      const formData = new FormData();
      formData.append('file', base64QrcodeSvg);
      const qrImageResponse = await this.stampV2Service.genSvgToPng(formData).toPromise();
      if (qrImageResponse.status === 200) {
        newForm.qrImage = qrImageResponse.data || '';
      }
      newForm.name = this.form.get('ten_qr_code').value;
      newForm.packageId = this.form.get('packageId').value;
      newForm.menuData = this.form.value;
      newForm.menuData = {
        ...this.form.value,
        id: this.menu_id,
        config: {
          isShowPhone: this.form.get('isShowPhone').value
        }
      };
      if (newForm.menuData.description) {
        if (isIncludeBase64Image(newForm.menuData.description)) {
          const matchedValues = newForm.menuData.description.match(regexBase64Image);
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < matchedValues.length; j++) {
            try {
              const url = await this.convertBase64ToUrl(matchedValues[j]);
              newForm.menuData.description = newForm.menuData.description.replace(matchedValues[j], url);
            } catch (e) {
              newForm.menuData.description = newForm.menuData.description.replace(matchedValues[j], '');
            }
          }
        }
      }
      if (newForm.menuData.foods && newForm.menuData.foods.length) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < newForm.menuData.foods.length; i++) {
          let tmpValue = newForm.menuData.foods[i].description;
          if (isIncludeBase64Image(newForm.menuData.foods[i].description)) {
            const matchedValues = newForm.menuData.foods[i].description.match(regexBase64Image);
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < matchedValues.length; j++) {
              try {
                const url = await this.convertBase64ToUrl(matchedValues[j]);
                tmpValue = tmpValue.replace(matchedValues[j], url);
              } catch (e) {
                tmpValue = tmpValue.replace(matchedValues[j], '');
              }
            }
            newForm.menuData.foods[i].description = tmpValue;
          }
        }
      }
      delete newForm.menuData.foodSales;
      newForm.templateId = this.form.get('templateId').value;
      newForm.type = 9;
      newForm.menuData.startTime = this.form.get('startTime').value ? moment(this.form.get('startTime').value).format('HH:mm') : null;
      newForm.menuData.endTime = this.form.get('endTime').value ? moment(this.form.get('endTime').value).format('HH:mm') : null;
      newForm.setting = {
        customerInfoForm: this.form.get('customerInfoForm').value,
        requiredCustomerInfo: ! this.form.get('customerInfoForm').value ? false : this.form.get('requiredCustomerInfo').value,
        allowedScan: this.form.get('allowedScan').value,
        hideNotification: false,
        hideRelatedShops: false
      };
      const foodSales = [];
      if (newForm !== 'null' && this.form.get('foodSales').value && this.form.get('foodSales').value.length) {
        this.form.get('foodSales').value.forEach(id => {
          foodSales.push({
            foodId: id,
            startTime: this.form.get('timeSale').value && this.form.get('timeSale').value.length ? moment(this.form.get('timeSale').value[0]).format('DD/MM/YYYY HH:mm:ss') : null,
            endTime: this.form.get('timeSale').value && this.form.get('timeSale').value.length ? moment(this.form.get('timeSale').value[1]).format('DD/MM/YYYY HH:mm:ss') : null
          });
        });
      }

      newForm.menuData.foodSales = foodSales;
      newForm.domainId = this.form.get('domainId').value;
      if (this.menuId === 'null') {
        newForm.qrCode = this.qrcodeDesignComponentRef.getQrcodeCode();
      }

      newForm.activatedAt = this.form.value.activatedAt ? moment(this.form.value.activatedAt).format('DD/MM/YYYY') : '';
      // newForm.expiredAt = this.form.get('expiredAt').value;
      const currentPackage = this.listPackage.find(item => item.id === this.form.get('packageId').value);
      newForm.expiredAt = this.form.get('expiredAt').value ? moment(this.form.get('expiredAt').value).format('DD/MM/YYYY') : currentPackage.expiredAt;
      if (moment(this.form.get('endTime').value).isSameOrBefore(moment(this.form.get('startTime').value))) {
        this.notification.error('Thông báo', 'Thời gian đóng cửa không hợp lệ');
        this.isLoadingBtn = false;
        return;
      }

      if (this.menuId !== 'null') {
        this.stampService.update(this.menuId, newForm).subscribe(async (response) => {
          if (response && response.code === 1) {
            this.notification.success('Thông báo', `Cập nhật thực đơn thành công`);
            await this.router.navigate([`portal/stamp`]);
          } else {
            this.notification.error('Thông báo', response.message || 'Lỗi');
          }
          this.isLoadingBtn = false;
        }, error => {
          this.notification.error('Thông báo', error || 'Lỗi');
          this.isLoadingBtn = false;
        });
      } else {
        this.stampService.create(newForm).subscribe(async (response) => {
          if (response && response.code === 1) {
            this.notification.success('Thông báo', `Tạo thực đơn thành công`);
            await this.router.navigate([`portal/stamp`]);
          } else {
            this.notification.error('Thông báo', response.message || 'Lỗi');
          }
          this.isLoadingBtn = false;
        }, error => {
          this.notification.error('Thông báo', error || 'Lỗi');
          this.isLoadingBtn = false;
        });
      }
    } else {
      this.templateQRCodeViewRef.onChangeTabActive();
      this.notification.error('Thông báo', 'Vui lòng điền đầy đủ thông tin');
      this.isLoadingBtn = false;
    }

  }

  initForm() {
    this.form = this.fb.group({
      ten_qr_code: [null, [Validators.required]],
      packageId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      description: [null],
      startTime: [null],
      endTime: [null],
      phoneNumber: [null],
      address: [null],
      website: [null],
      linkPages: this.fb.array([]),
      avatar: [null],
      wallpaper: [null],
      domainId: [null],
      activatedAt: [null],
      expiredAt: [null],
      requiredCustomerInfo: [false],
      customerInfoForm: [false],
      allowedScan: [true],
      isShowPhone: [false],
      foodSales: [null],
      timeSale: [null],
      foods: this.fb.array([
        this.fb.group({
          categoryId: [null, [Validators.required]],
          name: [null, [Validators.required]],
          price: [null, [Validators.required]],
          ccy: ['đ', [Validators.required]],
          priceSale: [null],
          description: [null],
          photos: [null],
          menuId: [null],
          id: [null],
          active: true
        })
      ]),
      templateId: [null],
    });
  }

  addMenu() {
    const foods = this.form.get('foods') as FormArray;
    for (let i = 0 ; i < foods.value.length; i++) {
      (this.form.get('foods') as FormArray).at(i).get('active').setValue(false);
    }
    foods.push(this.fb.group({
      categoryId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      price: [null, [Validators.required]],
      ccy: ['đ', [Validators.required]],
      priceSale: [null],
      description: [null],
      photos: [null],
      active: true
    }));
  }



  isActiveLinkSocial(index) {
    return this.listLinkSocial[index].isActive;
  }

  onRemoveCategory(index) {
    const foods = this.form.get('foods') as FormArray;
    foods.removeAt(index);
  }

  addLinkSocial(index, type) {
    this.listLinkSocial[index].isActive = !this.listLinkSocial[index].isActive;
    const linkPages = this.form.get('linkPages') as FormArray;
    if (this.listLinkSocial[index].isActive) {
      linkPages.push(this.fb.group({
        code: [''],
        value: [
          '',
          [
            Validators.pattern(
              /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
            ),
            Validators.required,
          ],
        ],
        type: [this.listLinkSocial[index].type],
        name: [this.listLinkSocial[index].name],
        image: [this.listLinkSocial[index].image],
        placeholder: [this.listLinkSocial[index].placeholder],
      }));
    } else {
      const indexRemove = this.form.get('linkPages').value.findIndex(object => {
        return object.type === type;
      });
      linkPages.removeAt(indexRemove);
    }
  }

  removeSocial(i, type) {
    const linkPages = this.form.get('linkPages') as FormArray;
    const index = this.form.get('linkPages').value.findIndex(object => {
      return object.type === type;
    });
    if (type === 'website') {
      this.listLinkSocial[0].isActive = false;
    }
    if (type === 'facebook') {
      this.listLinkSocial[1].isActive = false;
    }
    if (type === 'zalo') {
      this.listLinkSocial[2].isActive = false;
    }
    if (type === 'instagram') {
      this.listLinkSocial[3].isActive = false;
    }
    if (type === 'tiktok') {
      this.listLinkSocial[4].isActive = false;
    }
    linkPages.removeAt(index);
  }

  getListPackageActive() {
    // this.stampService.getListPackageActive({ type: 1 }).subscribe((res) => {
    //   if (res.code === 1) {
    //     this.listPackage = res.data;
    //   }
    // });
  }

  async back() {
    await this.router.navigate(['/portal/stamp']);
  }

  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp';
    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: field === 'photos',
      },
    });
    modal.afterOpen.subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          const body = {
            title: changeParam.file.name,
            type: changeParam.file.type,
            listUrl: [changeParam.file.response.url],
          };

          if (field === 'photos') {
            this.productImages.push({ url: changeParam.file.response.url });
            this.productImages2.push(changeParam.file.response.id);

            let photos = (this.form.get('foods') as FormArray).at(index).get('photos').value;
            if (photos && photos.length) {
              photos.push(changeParam.file.response.url);
            } else {
              photos = [changeParam.file.response.url];
            }

            (this.form.get('foods') as FormArray).at(index).get('photos').setValue(photos.map((image) => image));
            // this.form.get(field).updateValueAndValidity();
          }

          if (field === 'avatar') {
            this.avatar[0] = data;
            this.form.get('avatar').setValue(data.url);
          } else if (field === 'wallpaper') {
            this.wallpaper[0] = data;
            this.form.get('wallpaper').setValue(data.url);
          } else if (field === 'images') {
          }
        }
      });
      modal
        .getContentComponent()
        .done.subscribe((changeParam) => {
          modal.close();
        });
    });
  }

  onRemoveFile(field: string, indexRemove?: number, index?: number) {
    if (field === 'photos') {
      const photos = (this.form.get('foods') as FormArray).at(index).get('photos').value;
      photos.splice(indexRemove, 1);
    }
    if (field === 'avatar') {
      this.avatar = [];
      this.form.get('avatar').setValue('');
    } else if (field === 'wallpaper') {
      this.wallpaper = [];
      this.form.get('wallpaper').setValue('');
    }
  }

  getTemplate() {
    const body = {
      page: 1,
      pageSize: 10,
      type: 3,
    };
    this.stampService.getAllTemplate(body).subscribe(res => {
      if (res.code === 1) {
        this.templates = res.data;
        this.form.get('templateId').setValue(this.templates[0].id);
      }

    });
  }
  onChangeQrcodeConfigPreview(event) {
    this.qrcodeConfigPreview = {...event};
  }

  onChangeLoadingDrawQrcode(event) {
    this.loadingDrawQrcode = event;
  }

  onChangeTemplate(template) {
    this.form.get('templateId').setValue(template.id);
  }

  getFoodCategory() {
    this.menuService.getFoodCategory({
      page: 1,
      pageSize: 1000
    }).subscribe(res => {
      if (res && res.code) {
        this.foodCategories = res.items;
      }
    });
  }

  addNewCategory(i: any) {
    const modal = this.modalService.create({
      nzVisible: true,
      nzTitle: `Thêm danh mục`,
      nzContent: AddCategoryComponent,
      nzClosable: true,
      nzWidth: 700,
      nzFooter: null,
      nzMaskClosable: false,
    });

    modal.afterClose.pipe(debounceTime(1000)).subscribe(res => {
      this.foodCategories.unshift(res.data);
      (this.form.get('foods') as FormArray).at(i).get('categoryId').setValue(res.data.id);
      this.getFoodCategory();
    });
  }

  listDomain= [];
  getListDomainByUser() {

    const query: any = {
      page: 1,
      pageSize: 100
    };

    this.stampService
      .getListDomain(query).subscribe(
        (res) => {
          this.listDomain = res.data;
        },
        (err) => {
          // @ts-ignore
          this.notification.error('Thông báo', 'Lỗi !!!', `${err}`);
          return;
        }
      );
  }

  listSetting = [
    {value: 1, label: 'Mẫu thông tin khách hàng', key: 'customerInfoForm'},
    // {value: 2, label: 'Cho App khác quét', key: 'allowedScan'},
  ];

  isShowMenu = true;
  isShowFood = true;

}
