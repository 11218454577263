import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import {StampV2Service} from '../../../services';

@Component({
  selector: 'app-stamp-detail',
  templateUrl: './stamp-detail.component.html',
  styleUrls: ['./stamp-detail.component.scss']
})
export class StampDetailComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private stampService: StampService,
              private stampV2Service: StampV2Service
  ) {}

  isDisabled;
  isShow = 1;
  currentUser: any;
  isShowLoyalty = false;
  id: any;
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== 'null') {
      this.stampService
          .getStampById(this.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(res => {
            this.isShowLoyalty = res.data.type !== 2 && res.data.type !== 10 && res.data.type !== 11;
            // console.log("resresresresresresres", res)
          });
      this.isShow = 2;
    } else { this.isShow = 1; }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
