import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/services/base.service';

@Injectable({providedIn: 'root'})
export class HomeService extends BaseService {
  list(query?: any) {
    const httpOptions = {
      params: query
    };
    return this.get<any>('/support/users', httpOptions);
  }
  select(userId?: any) {
    return this.put<any>(`/support/users?userId=${userId}`);
  }
}
