import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { removeEmptyQuery } from 'src/app/helpers';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {


  @Input() configs;
  @Output('changeFilter') changeFilter = new EventEmitter<any>();
  dateFormat = 'dd/MM/yyyy';

  filterForm: any;
  unsubscribe$ = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    let group={};
    this.configs.forEach(config=>{

      if (config.key === 'business_id') {
        this.loadBusiness();
      }
      if (config.key === 'store_icheck_id') {
        this.loadStore('first');
      }
      if (config.key === 'gift_id') {
        this.loadGift('first');
      }

      if (config.type === 'select-box' || config.type === 'select-box-custom') {
        group[config.key]=new FormControl(null);
      } else {
        group[config.key]=new FormControl('');
      }

    })
    this.filterForm = new FormGroup(group);


    this.listenChangeFormValue();
  }

  listenChangeFormValue() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f) {
        if (typeof f[i] === 'string') {
          f[i] = f[i].trim()
          if (f[i].trim().length === 0) {
            f[i] = null;
          }
        }
      }
    };
    this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      const output = {
        ...value
      };

      recursive(output)
      // this.configs.forEach(element => {
      //   if (element.type === 'daterange' && value[element.key]?.length) {
      //     output['from'] = value[element.key][0].toISOString();
      //     output['to'] = value[element.key][1].toISOString();
      //     delete output[element.key];
      //   }
      // });
      removeEmptyQuery(output);
      this.changeFilter.emit(output);
    })
  }

  stores = [];
  offsetStore = 0;
  isLoadingStore = false;
  limitStore = 10;

  loadStore(type) {
    // if (!this.isLoadingStore) {
    //   const query = {
    //     offset: type === 'first' ? 0 : this.offsetStore += this.limitStore,
    //     limit: this.limitStore,
    //   };
    //   this.isLoadingStore = true;

    //   this.distributorService.allDistributor(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
    //     {
    //       next: x => {
    //         if (x.statusCode === 200) {
    //           this.isLoadingStore = false;
    //           this.stores = [...this.stores, ...x.data.rows];
    //         }
    //       },

    //     }
    //   );
    // }
  }

  gifts = [];
  offsetGift = 0;
  isLoadingGift = false;
  limitGift= 10;

  loadGift(type) {
    // let query = {};
    // if (!this.isLoadingGift) {
    //   query = {
    //     offset: type === 'first' ? 0 : this.offsetGift += this.limitGift,
    //     limit: this.limitGift,
    //   };
    //   this.isLoadingGift = true;
    //   const endpoint = '/loyalty/gift';

    //   this.crudCodeService.getAnyThing(endpoint, query).pipe(takeUntil(this.unsubscribe$)).subscribe(
    //     {
    //       next: x => {
    //         if (x.statusCode === 200) {
    //           this.isLoadingGift = false;
    //           this.gifts = [...this.gifts, ...x.data.rows];
    //         }
    //       },

    //     }
    //   );
    // }
  }

  businesses = [];
  loadBusiness(data?: any) {
    const query: any = {
      name: data ? data.trim() : null,
      offset: 0,
      limit: 100
    };

    removeEmptyQuery(query);

    // this.rootService.getAllBusiness(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
    //   (res: any) => {
    //     this.businesses = res.data.rows;
    //   }
    // );
  }

  search() {
    const output = {
      ...this.filterForm.value
    };
    // this.configs.forEach(element => {
    //   if (element.type === 'daterange' && this.filterForm.value[element.key]?.length) {
    //     output['from'] = this.filterForm.value[element.key][0].toISOString();
    //     output['to'] = this.filterForm.value[element.key][1].toISOString();
    //     delete output[element.key];
    //   }
    // });
    removeEmptyQuery(output);
    this.changeFilter.emit(output);
  }

  reset() {
    this.filterForm.reset();
  }

  onFormSubmit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
