import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StampModalComponent } from './stamp-add/stamp-modal.component';
import { StampListComponent } from './stamp-list.component';
import { StampDetailComponent } from './stamp-detail/stamp-detail.component';
import { StampCustomerComponent } from './stamp-detail/stamp-customer/stamp-customer.component'
import { StampInteractiveHistoryComponent } from './stamp-detail/stamp-interactive-history/stamp-interactive-history.component'
import { StampStatisticComponent } from './stamp-detail/stamp-statistic/stamp-statistic.component';
import { LoyaltyCampaignComponent } from './stamp-detail/loyalty-campaign/loyalty-campaign.component';

const routes: Routes = [
  {
    path: '',
    component: StampListComponent,
  },
  {
    path: ':id',
    component: StampDetailComponent,
    children: [
      {
        path: '',
        component: StampModalComponent
      },
      {
        path: 'customer',
        component: StampCustomerComponent
      },
      {
        path: 'interactive-history',
        component: StampInteractiveHistoryComponent
      },
      {
        path: 'statistic',
        component: StampStatisticComponent
      },
      {
        path: 'loyalty',
        component: LoyaltyCampaignComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StampRoutingModule { }
