import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NzFormatEmitEvent,
  NzNotificationService,
  NzTreeNodeOptions,
} from 'ng-zorro-antd';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SurveyService } from 'src/app/services';
@Component({
  selector: 'app-convert-stamp-popup',
  templateUrl: './convert-stamp-popup.component.html',
  styleUrls: ['./convert-stamp-popup.component.scss']
})
export class ConvertStampPopupComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private notification: NzNotificationService,
    private stampService: StampService
  ) {}

  @Output() handleRefresh = new EventEmitter<boolean>();
  unsubscribe$ = new Subject<void>();
  isVisible = false;
  title = 'Chuyển gói';
  form: FormGroup = this.fb.group({
    packageId: [null, [Validators.required]],
  });
  value: '';
  listSurveyGroup: Array<any> = [];
  loading = false;
  listPackage: any = [];


  listStampId: any;

  ngOnInit() {
  }

  handleCancel() {
    this.isVisible = false;
    this.form.reset();
  }

  getListPackageActive() {
    this.stampService.getListPackageActive({type: 1}).subscribe(res => {
      if (res.code === 1) {
        this.listPackage = res.data;
      }
    });
  }

  handleOk() {
    const recursive = (f: FormGroup | FormArray) => {
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);
    if (this.form.invalid) { return; }

    const body = {
      ...this.form.value,
      stampIds: this.listStampId,
    };
    this.loading = true;
    this.stampService
      .moveStamp(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.notification.create(
              'success',
              'Thành công',
              'Chuyển tem thành công!',
            );
            this.isVisible = false;
            this.handleRefresh.emit();
          } else {
            this.notification.create(
              'error',
              'Có lỗi xảy ra',
              res.message || 'Lấy dữ liệu thất bại'
            );
          }
          this.loading = false;
        },
        (err: any) => {
          this.notification.create(
            'error',
            'Có lỗi xảy ra',
            err.message || 'Lấy dữ liệu thất bại'
          );
          this.loading = false;
        }
      );
  }
  showModal(data) {

    this.getListPackageActive();
    this.form.reset();
    this.listStampId = data;
    this.isVisible = true;
  }
}
