import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { StampService } from 'src/app/services/business/stamp/stamp.service';
import { environment } from 'src/environments/environment';
import { track } from 'src/app/helpers/track';
import {StampV2Service} from '../../../../../services';
@Component({
  selector: 'app-select-campaign',
  templateUrl: './select-campaign.component.html',
  styleUrls: ['./select-campaign.component.scss'],
})
export class SelectCampaignComponent implements OnInit {
  constructor(
    private router: Router,
    private stampService: StampService,
    private notification: NzNotificationService,
    private stampV2Service: StampV2Service,
  ) {}
  currentStamp;
  giftCode: any;
  page = 1;
  pageSize = 9;
  totalElement: any;

  listCampaign = [];

  selectedItem: any;

  ngOnInit() {
    const id = window.location.href.split('/')[5];
    if (id !== 'null') {
      this.stampService.getStampById(id).subscribe((res) => {
        if (res.code === 1 && res.data) {
          this.currentStamp = res.data;
          if (res.data.loyaltyCampaign && res.data.loyaltyCampaign.type === 1) {
            if (res.data.loyaltyCampaign.code) {
              this.selectedItem = res.data.loyaltyCampaign.code;
            }
            if (res.data.loyaltyCampaign.giftCode) {
              this.giftCode = res.data.loyaltyCampaign.giftCode;
            }
          }
        }
      });
    }
    this.loadListCampaign();
  }

  loadListCampaign() {
    const body = {
      page: this.page,
      page_size: this.pageSize,
    };
    this.stampService.getListLoyalty(body).subscribe((res) => {
      this.listCampaign = res.items;
      this.totalElement = res.totalElement;
    });
  }
  listClick(event, newValue) {
    if (this.selectedItem === newValue) {
      this.selectedItem = '';
    } else {
      this.selectedItem = newValue;
    }
  }

  back() {
    this.router.navigate(['/portal/stamp']);
  }

  submit() {
    const body = {
      ...this.currentStamp,
      loyaltyCampaignType: 1,
      loyaltyCampaignObject: this.selectedItem ? this.selectedItem : '',
      loyaltyGiftCode: this.giftCode ? this.giftCode.trim() : '',
      data: this.currentStamp.stampData,
    };
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_stamp_loyalty_setting',
    //   contentName: 'confirm_setting',
    //   payload: JSON.stringify(body),
    //   target: `${window.location.origin}/portal/stamp`,
    // })
    // this.stampService.update(this.currentStamp.id, body).subscribe((res) => {
    this.stampService.update2(this.currentStamp.id, body).subscribe((res) => {
      if (res.code === 1) {
        // track("alert", {
        //   alertType: "success",
        //   alertMessage: "Cập nhật thành công"
        // });
        this.openMessage('success', 'Thành công', 'Cập nhật thành công');
        let delayTimer: any;
        delayTimer = setTimeout(() => {
          this.router.navigate(['/portal/stamp']);
          return;
        }, 500);
      }
      if (res.code !== 1) {
        // track("alert", {
        //   alertType: "error",
        //   alertMessage: res.message
        // });
        // track('error', {
        //   apiCall: `${window.location.origin}/stamp/${this.currentStamp.id}`,
        //   httpResponseCode: 500,
        //   errorMessage: res.message,
        //   errorCode: 500
        // })
        this.openMessage('warning', 'Cảnh báo', `${res.message}`);
      }
    }),
      (err) => {
        // track("alert", {
        //   alertType: "error",
        //   alertMessage: err
        // });
        // track('error', {
        //   apiCall: `${window.location.origin}/stamp/${this.currentStamp.id}`,
        //   httpResponseCode: 500,
        //   errorMessage: err,
        //   errorCode: 500
        // })
        this.openMessage('err', 'Lỗi !!!', `${err}`);
      };
  }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  changePagination(event) {
    this.page = event;
    this.loadListCampaign();
  }

  goToLoyalty() {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = environment.loyaltyUrl;
    a.click();
  }
}
