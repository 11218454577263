import { Injectable } from '@angular/core';
import {BaseService} from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class CallService extends BaseService {
  getClientToken() {
    return this.get<any>('/stringee/client_token');
  }
  saveHistory(body) {
    return this.post<any>('/stringee/history', body);
  }
  saveTicket(body) {
    return this.post<any>('/stringee/ticket', body);
  }
}
