import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-business-extra-info',
  templateUrl: './business-extra-info.component.html',
  styleUrls: ['./business-extra-info.component.scss']
})
export class BusinessExtraInfoComponent implements OnInit {
  businessExtraInfoList;
  colorDefault;
  limitDescription;
  isViewContent;
  contentView;

  @Input() extraInfo;
  @Input() color;
  @Input() trans;
  constructor() {
  }

  ngOnInit() {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges() {
    this.businessExtraInfoList = this.extraInfo || [];
    this.colorDefault = this.color || '#4265ed';
    this.limitDescription = 190;
    this.isViewContent = false;
    this.contentView = '';
  }

}
