import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private baseUrl = environment.uploadBaseUrl;
  private baseUrlImage = environment.uploadImageBaseUrl;
  private baseUrl3 = environment.uploadStream;
  private urlPdf =
    'https://upload.icheck.vn/v1/file?uploadType=simple&responseType=json';
  private urlVideo = 'https://upload.icheck.com.vn/upload/stream';

  constructor(private http: HttpClient) {}

  public create(size: number, metadata: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, { size, metadata });
  }

  public createFlash(body): Observable<any> {
    return this.http.post(`${this.baseUrlImage}`, body);
  }

  public uploadPdf(body): Observable<any> {
    return this.http.post(`${this.urlPdf}`, body);
  }

  public postImg2(body: any): Observable<any> {
    return this.http.post(`${this.baseUrl3}`, body);
  }

  public getFile(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/files/${id}`, {
      params: { fields: ['source', 'metadata'] },
    });
  }

  public uploadVideo(body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        // appid: 'app01'
        'Content-Type': 'multipart/form-data'
      }),
    };
    return this.http.post(`${environment.uploadStream}`, body, httpOptions);
  }

  public uploadFile(body, currentUser?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + currentUser && currentUser.access_token ? currentUser.access_token : '',
      }),
    };
    return this.http.post(`${environment.uploadStream}`, body, httpOptions);
  }
}
