import { Component, OnInit, OnDestroy, Directive } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { UploaderComponent } from 'src/app/shared/uploader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {CoreDatahubService, ProductsService, UploadService} from 'src/app/services';
import {
  base64toBlob,
  capitalizeFirstLetter, configCommon, isIncludeBase64Image,
  removeAllSpacingBetweenWords,
} from 'src/app/helpers';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { ofType } from '@ngrx/effects';
declare var $: any;
import { track } from 'src/app/helpers/track';
import {regexBase64Image} from '../../../../helpers/constant';
@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.scss'],
})
export class ProductsFormComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private modal: NzModalService,
    private router: Router,
    private productService: ProductsService,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer,
    private coreDatahubService: CoreDatahubService,
    private uploadService: UploadService,
  ) {}

  unsubscribe$ = new Subject<void>();

  form: FormGroup = this.fb.group({
    productImages: [null],
    mainImage: [null],
    video: [null],
    name: [null, [Validators.required]],
    sku: [null],
    categoryId: [null],
    price: [null],
    attributes: this.fb.group({}),
    mediaFiles: [''],
    ccy: ['đ'],
    toPrice: [null],
    showQuotation: [true]
    // description: this.fb.group({
    //   image: [""],
    //   detail: [null],
    //   description: [null],
    // }),
    // enterpriseInfo: this.fb.group({
    //   image: [""],
    //   detail: [null],
    //   description: [null],
    // }),
  });

  isUpdate = false;

  packages = [{ value: 'Hộp' }, { value: 'Thùng' }];

  productId: string;
  managementStatus: number;
  requestInfoStatus: number;
  reason: string;
  roleCode: string;
  categoryStr: string;
  categoryValue: any;
  delayTimer: any;

  productImages = [];
  productImages2 = [];
  descriptionImages: any = [];
  verifyAuthImages: any = [];
  enterpriseInfoImages: any = [];

  isScroll = false;
  isRequired;
  isLoadingBtn = false;
  checkLoad = 0;
  public config: any = configCommon;
  avatar = [];
  avatarId;
  validAvatar = false;
  listCategory = [];

  attributes = [{ key: '', value: '', isValid: true }];
  videoIntro: any = [];

  mediaFiles: Array<any> = [];

  isLoading = false;
  page = 1;
  pageSize = 20;
  search = '';
  priceType = 1;
  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content);
  }

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_products_form',
    //   contentType: 'product',
    // })
    const attributesGroup = this.fb.group({});
    this.attributes.forEach((group, index) => {
      attributesGroup.addControl(
        `${index}`,
        this.fb.control(group.value || '')
      );
    });

    this.form.setControl('attributes', attributesGroup);

    // this.form.controls.description
    //                   .get("detail").setValue('trung')
    if (window.location.href.split('/')[5] !== 'null') {
      this.isUpdate = true;
      this.productId = window.location.href.split('/')[5];
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.productService
          .getById(this.productId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res) => {
              if (res.code === 1) {
                // config summernote
                $('.note-insert').append(
                  '<button type="button" class="note-btn uploadImg" ><i class="note-icon-picture"></i></button>'
                );
                $('.description1 .uploadImg').on('click', () => {
                  this.openUploadDialog('description');
                });
                $('.verifyAuth1 .uploadImg').on('click', () => {
                  this.openUploadDialog('verifyAuth');
                });
                $('.enterpriseInfo1 .uploadImg').on('click', () => {
                  this.openUploadDialog('enterpriseInfo');
                });
                // end config summernote
                if (res.data) {
                  // this.managementStatus = res.data.managementStatus;
                  // this.requestInfoStatus = res.data.requestInfoStatus;
                  // this.reason = res.data.reason;
                  // this.roleCode = res.data.roleCode;
                  // const body: any = {
                  //   managementStatus: this.managementStatus,
                  //   roleCode: this.roleCode,
                  // };
                  // this.productService.getManagementStatus(body);
                  if (res.data.categories) {
                    let arr = [];
                    arr = res.data.categories.map((data) => {
                      return data.name;
                    });
                    this.categoryStr = arr.join(' / ');
                    let arrCategories = [];
                    arrCategories = res.data.categories.map((data) => {
                      return data.id;
                    });
                    this.categoryValue = arrCategories;

                    if (arrCategories[arrCategories.length - 1]) {
                      this.coreDatahubService
                        .getDetailCategory(
                          arrCategories[arrCategories.length - 1]
                        )
                        .pipe(takeUntil(this.unsubscribe$))
                        // tslint:disable-next-line:no-shadowed-variable
                        .subscribe((res: any) => {
                          if (res.code === 1) {
                            this.listCategory = _.uniqBy(
                              [res.data, ...this.listCategory],
                              'id'
                            ).map((item: any) => {
                              item.display_name = item.ancestors.map(
                                (anc) => anc.name
                              ).length
                                ? item.ancestors
                                    .map((anc) => anc.name)
                                    .join(' > ') +
                                  ' > ' +
                                  item.name
                                : item.name;
                              return item;
                            });
                          }
                        });
                    }

                    this.form
                      .get('categoryId')
                      .setValue(arrCategories[arrCategories.length - 1]);
                  }

                  if (res.data.productImages) {
                    res.data.productImages.forEach((file) => {
                      const data = {
                        url: file,
                      };
                      this.productImages.push(data);
                    });
                    this.productImages2 = res.data.productImages;
                    this.form
                      .get('productImages')
                      .setValue(res.data.productImages);
                    if (this.productImages2.length > 5) {
                      this.isScroll = true;
                    }
                  }
                  // set ảnh đại diện
                  if (res.data.mainImage) {
                    this.avatar = [{ url: res.data.mainImage }];
                    this.avatarId = res.data.mainImage;
                    this.form.get('mainImage').setValue(res.data.mainImage);
                  }

                  if (res.data.video) {
                    let tmpVideoUrl = '';
                    if (res.data.video.indexOf('watch?v=')) {
                      tmpVideoUrl = res.data.video.replace(
                        'watch?v=',
                        'embed/'
                      );
                    } else {
                      tmpVideoUrl = res.data.video;
                    }
                    this.videoIntro = [
                      this.sanitizer.bypassSecurityTrustResourceUrl(
                        tmpVideoUrl
                      ),
                    ];
                    const urlVideos = this.videoIntro.map(
                      (v) => v.changingThisBreaksApplicationSecurity
                    );

                    this.form.get('video').setValue(urlVideos);
                  }

                  // this.form.get("video").setValue(res.data.video);

                  this.form.get('name').setValue(res.data.name);
                  this.form.get('sku').setValue(res.data.sku);
                  this.form.get('price').setValue(res.data.price);
                  this.form.get('showQuotation').setValue(res.data.showQuotation);
                  this.form.get('ccy').setValue(res.data.ccy || 'đ');

                  if (res.data.toPrice) {
                    this.form.get('toPrice').setValue(res.data.toPrice);
                    this.priceType = 2;
                  }

                  this.form
                    .get('mediaFiles')
                    .setValue(res.data.mediaFiles || undefined);
                  this.mediaFiles = res.data.mediaFiles
                    ? res.data.mediaFiles
                    : [];

                  // tslint:disable-next-line:no-shadowed-variable
                  const attributesGroup = this.fb.group({});
                  this.attributes = res.data.attributes ? res.data.attributes.map(item => ({...item, isValid: true})) : [];
                  this.attributes.forEach((group, index) => {
                    attributesGroup.addControl(
                      `${index}`,
                      this.fb.control(group.value || '')
                    );
                  });
                  // this.insertUploadToSummerNote();

                  this.form.setControl('attributes', attributesGroup);

                  // if (res.data.description) {
                  //   this.form.controls.description
                  //     .get("description")
                  //     .setValue(res.data.description.description);

                  //   this.form.controls.description
                  //     .get("detail")
                  //     .setValue(res.data.description.detail);
                  //   if (res.data.description.image) {
                  //     this.form.controls.description
                  //       .get("image")
                  //       .setValue(res.data.description.image);
                  //     this.descriptionImages[0] = {
                  //       url: res.data.description.image,
                  //     };
                  //   }
                  // }

                  // if (res.data.enterpriseInfo) {
                  //   if (res.data.enterpriseInfo.image) {
                  //     this.form.controls.enterpriseInfo
                  //       .get("image")
                  //       .setValue(res.data.enterpriseInfo.image);
                  //     this.enterpriseInfoImages[0] = {
                  //       url: res.data.enterpriseInfo.image,
                  //     };
                  //   }
                  //   this.form.controls.enterpriseInfo
                  //     .get("description")
                  //     .setValue(res.data.enterpriseInfo.description);
                  //   this.form.controls.enterpriseInfo
                  //     .get("detail")
                  //     .setValue(res.data.enterpriseInfo.detail);
                  // }
                }
                return;
              }
              if (res.code !== 1) {
                this.openMessage('warning', 'Cảnh báo', `${res.message}`);
                return;
              }
            },
            (err) => {
              this.openMessage('error', 'Lỗi !!!', `${err}`);
            }
          );
      }, 300);
    } else {
      this.isUpdate = false;
    }

    this.onChanges();
    this.getListCategory();
  }

  insertUploadToSummerNote() {
    const that = this;
    $(document).ready(() => {
      that.attributes.map((item, index) => {
        if (!$(`.${index} button.uploadImg`).length) {
          $(`.${index} .note-insert`).append(
            '<button type="button" class="note-btn uploadImg" ><i class="note-icon-picture"></i></button>'
          );
        }
        $(`.${index} .note-insert button.uploadImg`)
          .unbind()
          .click(() => {
            that.openUploadDialog('description', index);
          });
      });
    });
  }

  searchCategory(value) {
    this.search = value;
    this.page = 1;

    this.getListCategory();
  }

  loadMoreCategory() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.page += 1;
      this.getListCategory();
    }
  }
  getListCategory() {
    let query: any = {
      page: this.page,
      pageSize: this.pageSize,
    };
    if (this.search) {
      query = {
        ...query,
        name: this.search,
      };
    }

    // this.isLoading = true;
    this.coreDatahubService.listCategoryNoParent(query).subscribe((data) => {
      this.listCategory = _.uniqBy(
        [...this.listCategory, ...data.data],
        'id'
      ).map((item: any) => {
        item.display_name = item.ancestors.map((anc) => anc.name).length
          ? item.ancestors.map((anc) => anc.name).join(' > ') +
            ' > ' +
            item.name
          : item.name;
        return item;
      });
      this.isLoading = false;
    });
  }

  onChanges(): void {
    this.form.valueChanges.subscribe((val) => {});
  }

  addAttributes() {
    this.attributes.push({
      key: '',
      value: '',
      isValid: true,
    });

    const attributesGroup = this.fb.group({});
    this.attributes.forEach((group, index) => {
      attributesGroup.addControl(
        `${index}`,
        this.fb.control(group.value || '')
      );
    });

    this.form.setControl('attributes', attributesGroup);

    // this.insertUploadToSummerNote();
  }

  removeAttribute(index) {
    this.attributes.splice(index, 1);

    // this.form.get('attributes').removeControl(index);
  }

  changeKeyAttribute(event, index) {
    this.attributes[index].key = event;
    this.attributes[index].isValid = !!this.attributes[index].key.trim();
  }

  async modelChanged(value, index) {
    this.attributes[index].value = value;
    this.form.updateValueAndValidity();
  }

  convertBase64ToUrl(base64): any {
    const formData = new FormData();
    formData.append('content', base64toBlob(base64), 'image-product-description.png');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.uploadService.uploadFile(formData, currentUser).toPromise().then(
      (result) => {
        if (result.status === 200 && result.data && result.data.url) {
          return result.data.url;
        }
      }
    );
  }

  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp,mp4';

    const modal = this.modal.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'productImages') {
            this.productImages.push({ url: changeParam.file.response.url });
            this.productImages2.push(changeParam.file.response.id);

            this.form
              .get('productImages')
              .setValue(this.productImages.map((image) => image.url));
            this.form.get(field).updateValueAndValidity();
            if (this.form.value.productImages.length > 5) {
              this.isScroll = true;
            }
          }

          if (field === 'htmleditor') {
            let image = '';
            changeParam.fileList.forEach((e) => {
              image += `<div style="text-align: center"><img src="${e.response.url}" class='imgSummernote'></div><br>`;
            });

            this.modelChanged(image, index);
            return;
          }

          if (field === 'avatar') {
            this.avatar = [{ url: changeParam.file.response.url }];
            this.avatarId = changeParam.file.response.id;
            this.validAvatar = false;

            // if (this.productImages.length === 0) {
            this.form.get('mainImage').setValue(changeParam.file.response.url);
            // }
          }

          if (field === 'description') {
            if (
              changeParam.type === 'success' &&
              (changeParam.file.response.url.indexOf('youtube.com') !== -1 ||
                changeParam.file.response.url.indexOf('.mp4') !== -1)
            ) {
              // tslint:disable-next-line:variable-name
              const _tmpUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl(
                changeParam.file.response.url
              );

              const description = [];
              description.push(
                `<iframe src="${_tmpUrl.changingThisBreaksApplicationSecurity}" class='imgSummernote'></iframe><br>`
              );

              let value = '';
              if (index) {
                if (
                  this.attributes[index].value &&
                  this.attributes[index].value !== null
                ) {
                  value = this.attributes[index].value;
                } else {
                  value = '';
                }
                this.form.controls.attributes
                  .get('' + index)
                  .setValue(value + '<br>' + description);

                this.form.updateValueAndValidity();
              } else {
                if (
                  this.attributes[0].value &&
                  this.attributes[0].value !== null
                ) {
                  value = this.attributes[0].value;
                } else {
                  value = '';
                }

                this.form.controls.attributes
                  .get('' + 0)
                  .setValue(value + '<br>' + description);

                this.form.updateValueAndValidity();
              }

              // this.videoIntro.push(_tmpUrl);

              // let urlVideos = this.videoIntro.map(
              //   (gg) => gg.changingThisBreaksApplicationSecurity
              // );
              // if (this.videoIntro && this.videoIntro.length) {
              //   this.form.get("video").setValue(urlVideos[0]);
              // }
              setTimeout(() => {
                modal.close();
              }, 500);
            } else {
              const description = [];
              description.push(
                `<img src="${changeParam.file.response.url}" class='imgSummernote'><br>`
              );

              let value = '';
              if (index) {
                if (
                  this.attributes[index].value &&
                  this.attributes[index].value !== null
                ) {
                  value = this.attributes[index].value;
                } else {
                  value = '';
                }
                this.form.controls.attributes
                  .get('' + index)
                  .setValue(value + '<br>' + description);
              } else {
                if (
                  this.attributes[0].value &&
                  this.attributes[0].value !== null
                ) {
                  value = this.attributes[0].value;
                } else {
                  value = '';
                }
                this.form.controls.attributes
                  .get('' + 0)
                  .setValue(value + '<br>' + description);
              }

              this.form.updateValueAndValidity();
            }
          }

          if (field === 'verifyAuth') {
            const verifyAuth = [];
            verifyAuth.push(
              `<img src="${changeParam.file.response.url}" class='imgSummernote'><br>`
            );

            let value = '';
            if (
              this.form.get('verifyAuth').value.detail &&
              this.form.get('verifyAuth').value.detail !== null
            ) {
              value = this.form.get('verifyAuth').value.detail;
            } else {
              value = '';
            }

            // const value = this.form.get('verifyAuth').value.detail;
            this.form.controls.verifyAuth
              .get('detail')
              .setValue(value + verifyAuth);

            this.form.get(field).updateValueAndValidity();
          }

          if (field === 'enterpriseInfo') {
            const enterpriseInfo = [];
            enterpriseInfo.push(
              `<img src="${changeParam.file.response.url}" class='imgSummernote'><br>`
            );
            // let value = '';
            // const value = this.form.get('description').value.detail;

            let value = '';
            if (
              this.form.get('enterpriseInfo').value.detail &&
              this.form.get('enterpriseInfo').value.detail !== null
            ) {
              value = this.form.get('enterpriseInfo').value.detail;
            } else {
              value = '';
            }

            this.form.controls.enterpriseInfo
              .get('detail')
              .setValue(value + enterpriseInfo);
            // this.form.get('enterpriseInfo').setValue(value + enterpriseInfo);
            this.form.get(field).updateValueAndValidity();
          }

          if (field === 'vid') {
            // tslint:disable-next-line:variable-name
            const _tmpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              changeParam.file.response.url
            );
            this.videoIntro.push(_tmpUrl);
            const urlVideos = this.videoIntro.map(
              (gg) => gg.changingThisBreaksApplicationSecurity
            );
            if (this.videoIntro && this.videoIntro.length) {
              this.form.get('video').setValue(urlVideos);
            }
          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  openUploadDialogChild(field: string, arrayImages: any, controlName: string) {
    const fileType = 'jpg,jpeg,png,gif,bmp';
    const modal = this.modal.create({
      nzTitle: `Tải ảnh lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          if (field === 'productImages') {
            arrayImages[0] = { url: changeParam.file.response.url };
            this.form.controls[controlName]
              .get('image')
              .setValue(changeParam.file.response.url);
          }
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  openUploadDialogVid() {
    const fileType = 'mp4';
    const modal = this.modal.create({
      nzTitle: `Tải Video lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          // tslint:disable-next-line:variable-name
          const _tmpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            changeParam.file.response.url
          );
          this.videoIntro = [_tmpUrl];

          const urlVideos = this.videoIntro.map(
            (gg) => gg.changingThisBreaksApplicationSecurity
          );

          if (this.videoIntro && this.videoIntro.length) {
            this.form.get('video').setValue(urlVideos[0]);
          }
          setTimeout(() => {
            modal.close();
          }, 500);
        } else if (
          changeParam.type === 'success' &&
          changeParam.file.response.url.indexOf('youtube.com') !== -1
        ) {
          // tslint:disable-next-line:variable-name
          const _tmpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            changeParam.file.response.url
          );
          this.videoIntro = [_tmpUrl];

          const urlVideos = this.videoIntro.map(
            (gg) => gg.changingThisBreaksApplicationSecurity
          );
          if (this.videoIntro && this.videoIntro.length) {
            this.form.get('video').setValue(urlVideos[0]);
          }
          setTimeout(() => {
            modal.close();
          }, 500);
        }
      });
      // modal
      //   .getContentComponent()
      //   .done.pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(() => {
      //     modal.close();
      //   });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'productImages') {
      this.productImages.splice(index, 1);
      this.productImages2.splice(index, 1);
      this.form
        .get('productImages')
        .setValue(this.productImages2.map((image) => image));
      this.form.get(field).updateValueAndValidity();
    }
    if (field === 'avatar') {
      this.form.get('mainImage').setValue(null);
      this.avatarId = undefined;
      this.avatar = [];
      this.validAvatar = false;
    }
  }

  onRemoveFileChild(
    field: string,
    index: number,
    arrayImages: any,
    controlName: string
  ) {
    if (field === 'productImages') {
      arrayImages.splice(index, 1);
      this.form.controls[controlName].get('image').setValue('');
    }
  }

  clearVideoUrl(index: number) {
    this.videoIntro.splice(index, 1);
    const urlVideos = this.videoIntro.map(
      (gg) => gg.changingThisBreaksApplicationSecurity
    );
    this.form.get('video').setValue(urlVideos);
  }

  getAttibuteCategory(event) {
    this.form.get('categoryId').setValue(event);
    this.isRequired = event.length === 0;
  }
  onlyNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57));

    // return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCheckPriceValue(event) {
    if (event.target.value > 999999999999) {
      this.form.patchValue({
        price: 999999999999
      });
    }
    if (event.target.value < 0) {
      this.form.patchValue({
        price: null
      });
    }
  }

  onChangePriceType() {
    this.form.patchValue({
      price: null,
      toPrice: null
    });
  }

  onCheckToPriceValue(event) {
    if (event.target.value > 999999999999) {
      this.form.patchValue({
        toPrice: 999999999999
      });
    }
    if (event.target.value < 0) {
      this.form.patchValue({
        toPrice: null
      });
    }
    if (event.target.value && event.target.value < this.form.get('price').value) {
      this.form.patchValue({
        toPrice: this.form.get('price').value + 1
      });
    }
  }

  async onSubmit() {
    const recursive = (f: FormGroup | FormArray) => {
      // tslint:disable-next-line:forin
      for (const i in f.controls) {
        if (typeof f.controls[i].value === 'string') {
          if (f.controls[i].value.trim().length === 0) {
            f.controls[i].value = null;
          }
        }
        if (f.controls[i] instanceof FormControl) {
          f.controls[i].markAsDirty();
          f.controls[i].updateValueAndValidity();
        } else {
          recursive(f.controls[i] as any);
        }
      }
    };

    recursive(this.form);

    if (this.form.invalid) {
      // this.isRequired = true;
      return;
    }

    const categorySelected: any = this.listCategory.filter((item) => {
      return item.id === this.form.value.categoryId;
    })[0];

    const listCategoryId = [];
    if (categorySelected) {
      categorySelected.ancestors.map((it) => {
        if (it && it.id) {
          listCategoryId.push(it.id);
        }
      });
    }

    if (this.form.value.categoryId) {
      listCategoryId.push(this.form.value.categoryId);
    }
    const newForm = {
      ...this.form.value,
      categoryId: listCategoryId,
    };
    newForm.name = capitalizeFirstLetter(
      removeAllSpacingBetweenWords(this.form.value.name)
    );
    if (this.attributes && this.attributes.length) {
      this.attributes.forEach(item => {
        if (!item.key.trim()) {
          item.isValid = false;
        }
      });
      if (this.attributes.filter(item => !item.isValid).length) {
        return;
      }
    }

    this.isLoadingBtn = true;

    newForm.attributes =
      this.attributes && this.attributes.length ? [...this.attributes].map(item => ({key: item.key, value: item.value})) : [];

    if (newForm.attributes && newForm.attributes.length) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < newForm.attributes.length; i++) {
        let tmpValue = newForm.attributes[i].value;
        if (isIncludeBase64Image(newForm.attributes[i].value)) {
          const matchedValues = newForm.attributes[i].value.match(regexBase64Image);
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < matchedValues.length; j++) {
            try {
              const url = await this.convertBase64ToUrl(matchedValues[j]);
              tmpValue = tmpValue.replace(matchedValues[j], url);
            } catch (e) {
              tmpValue = tmpValue.replace(matchedValues[j], '');
            }
          }
          newForm.attributes[i].value = tmpValue;
        }
      }
    }

    if (this.priceType === 2 && ((!newForm.toPrice && newForm.price) || (newForm.toPrice && !newForm.price))) {
      return this.notification.warning('Vui lòng thử lại', 'Khoảng giá chưa nhập đầy đủ');
    }

    if (newForm.price && newForm.toPrice && newForm.price > newForm.toPrice) {
      return this.notification.warning('Vui lòng thử lại', 'Khoảng giá chưa phù hợp');
    }

    if (Array.isArray(newForm.video)) {
      newForm.video = newForm.video[0];
    }

    // return

    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_products_form',
    //   contentName: 'save',
    //   target: `${window.location.origin}/portal/products`,
    //   payload: JSON.stringify(newForm)
    // })

    if (this.isUpdate) {
      this.productService
        .update(this.productId, newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track('alert', {
            //   alertType: 'success',
            //   alertMessage: 'Cập nhật thành công',
            // })
            this.openMessage('success', 'Thành công', 'Cập nhật thành công');
            // tslint:disable-next-line:prefer-const
            let delayTimer: any;
            clearTimeout(delayTimer);
            this.delayTimer = setTimeout(() => {
              this.isLoadingBtn = false;
              this.router.navigate(['/portal/products']);
              return;
            }, 1500);
          }
          if (res.code !== 1) {
            // track('alert', {
            //   alertType: 'failed',
            //   alertMessage: res.message,
            // })
            // track('error', {
            //   apiCall: `${window.location.origin}/product/${this.isUpdate}`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500,
            //   apiPayload: JSON.stringify(newForm)
            // })
            this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            this.isLoadingBtn = false;
          }
        }),
        // tslint:disable-next-line:no-unused-expression
        (err) => {
          // track('alert', {
          //   alertType: 'failed',
          //   alertMessage: err,
          // })
          // track('error', {
          //   apiCall: `${window.location.origin}/product/${this.isUpdate}`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500,
          //   apiPayload: JSON.stringify(newForm)
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.isLoadingBtn = false;
        };
    } else {
      // create product
      this.productService
        .create(newForm)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res.code === 1) {
            // track('alert', {
            //   alertType: 'success',
            //   alertMessage: 'Thêm mới thành công',
            // })
            this.openMessage('success', 'Thành công', 'Thêm mới thành công');
            // tslint:disable-next-line:prefer-const
            let delayTimer: any;
            clearTimeout(delayTimer);
            this.delayTimer = setTimeout(() => {
              this.isLoadingBtn = false;
              this.router.navigate(['/portal/products']);
              return;
            }, 1500);
          }
          if (res.code !== 1) {
            // track('alert', {
            //   alertType: 'failed',
            //   alertMessage: res.message,
            // })
            // track('error', {
            //   apiCall: `${window.location.origin}/product`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500,
            //   apiPayload: JSON.stringify(newForm)
            // })
            this.openMessage(
              'warning',
              'Cảnh báo',
              `${res.message ? res.message : ''}`
            );
            this.isLoadingBtn = false;
          }
        }),
        // tslint:disable-next-line:no-unused-expression
        (err) => {
          // track('alert', {
          //   alertType: 'failed',
          //   alertMessage: err,
          // })
          // track('error', {
          //   apiCall: `${window.location.origin}/product`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500,
          //   apiPayload: JSON.stringify(newForm)
          // })
          this.openMessage('error', 'Lỗi !!!', `${err}`);
          this.isLoadingBtn = false;
        };
    }
  }

  back() {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_products_form',
    //   contentName: 'cancel',
    //   target: `${window.location.origin}/portal/products`,
    // })
    this.router.navigate(['/portal/products']);
  }

  uploadFile() {
    const fileType = 'jpg,jpeg,png,pdf';
    const modal = this.modal.create({
      nzTitle: `Tải file lên (jpg,jpeg,png,pdf)`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name:
            changeParam.file.type === 'upload'
              ? changeParam.file.response.url
              : changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.mediaFiles.push(data);
          this.form.get('mediaFiles').setValue(this.mediaFiles);
        }
      });
      console.log(' this.mediaFiles', this.mediaFiles);
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }

  removeCertificateFile(index: number) {
    const tempCer = this.mediaFiles;
    tempCer.splice(index, 1);
    this.mediaFiles = tempCer;
    this.form
      .get('mediaFiles')
      .setValue(
        tempCer.map((cer) => ({ name: cer.name, type: cer.type, url: cer.url }))
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
