import { RootService } from './../../services/root.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { convertDateToStringQuery } from 'src/app/helpers';
import { BusinessInfoService } from 'src/app/services';
import { CheckInHistoryService } from 'src/app/services/business/checkin-history/checkin-history.services';

@Component({
  selector: 'app-checkin-history',
  templateUrl: './checkin-history.component.html',
  styleUrls: ['./checkin-history.component.scss']
})
export class CheckinHistoryComponent implements OnInit {

  isVisiblePackageList: boolean = false;
  unsubscribe$ = new Subject<void>();
  listHistory: any = [];
  total: any = 0;
  loading: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  search: any = {
    businessName: "",
    phoneNumber: undefined,
    provinceId: null,
    actionTime: [],
  };


  constructor(
    private checkInHistoryService: CheckInHistoryService,
    private notification: NzNotificationService,
    private router: Router,
    private rootService: RootService
  ) {}

  ngOnInit() {
    this.getListHistory();
    this.getCities();
  }

  showPaging = false;
  getListHistory() {
    this.loading = true;
    const body = {
      businessName: this.search.businessName ? this.search.businessName.trim() : '',
      provinceId: this.search.provinceId || '',
      phoneNumber: this.search.phoneNumber ? this.search.phoneNumber.trim() : '',
      createdFrom: this.search.actionTime && this.search.actionTime[0] ? convertDateToStringQuery(this.search.actionTime[0]) : '',
      createdTo: this.search.actionTime && this.search.actionTime[1] ? convertDateToStringQuery(this.search.actionTime[1]) : '',
      page: this.page,
      pageSize: this.pageSize
    }
    this.checkInHistoryService
      .getHistory(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            if (res.data) {
              this.listHistory = [...res.data];
            } else {
              this.listHistory = [];
            }
            this.total = res.totalElement;

            if (res.totalElement <= 10) {
              this.showPaging = false;
            } else {
              this.showPaging = true;
            }
          } else {
            this.notification.create(
              "error",
              "Có lỗi xảy ra",
              res.message || "Lấy dữ liệu thất bại"
            );
          }
          this.loading = false;
        },
        (err: any) => {
          this.notification.create(
            "error",
            "Có lỗi xảy ra",
            err.message || "Lấy dữ liệu thất bại"
          );
          this.loading = false;
        }
      );
  }

  loadData() {
    this.page = 1;
    this.getListHistory();
  }


  export() {
    const params = {
      businessName: this.search.businessName ? this.search.businessName.trim() : '',
      provinceId: this.search.provinceId || '',
      phoneNumber: this.search.phoneNumber ? this.search.phoneNumber.trim() : '',
      createdFrom: this.search.actionTime && this.search.actionTime[0] ? convertDateToStringQuery(this.search.actionTime[0]) : '',
      createdTo: this.search.actionTime && this.search.actionTime[1] ? convertDateToStringQuery(this.search.actionTime[1]) : '',
    };
    this.checkInHistoryService.export(params).subscribe((x: any) => {


        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);


        var link = document.createElement('a');
        link.href = data;
        link.download = 'danh_sach_check_in' + new Date().getTime();
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);

      }, err => {

        this.openMessage("error", "Thao tác không hợp lệ !!!", `${err}`);
      });
  }



  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }


  listCity = []
  getCities() {
    let query = {
      type: 1, // 1: tỉnh thành, 2: quận huyện, 3: phường xã
      parentId : 0
    }
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.listCity = res.data;
      },
      (error) => {
        this.listCity = [];
      }
    );
  }

  resetSearch() {
    this.search.businessName = '';
    this.search.provinceId = null;
    this.search.phoneNumber = '';
    this.search.actionTime = [];
    this.getListHistory();
  }


  changePage(event) {

    this.page = event;
    this.getListHistory();
  }

  buyPackage() {
    this.router.navigate(['/portal/service-package'])
  }

}
