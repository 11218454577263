import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountInfoService extends BaseService {
  name = new BehaviorSubject<string>('');
  currentName = this.name.asObservable();
  changeName(name) {
    this.name.next(name);
  }
  info(): Observable<any> {
    return this.get<any>('/user/info');
  }
  update(body?: any) {
    return this.put<any>('/user', body);
  }
  changePassword(body?: any) {
    return this.put<any>('/user/password', body);
  }
  getInfoUserChat(query?: any) {
    if (query.length) {
      return this.get<any>(`/user/chat/user-info?icheckIds=${query.toString()}`);
    }
  }
}
