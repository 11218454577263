import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-businesses-feature-product',
  templateUrl: './businesses-feature-product.component.html',
  styleUrls: ['./businesses-feature-product.component.scss']
})
export class BusinessesFeatureProductComponent implements OnInit {
  @Input() relatedProduct;
  constructor() { }

  ngOnInit() {
  }

}
