import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { CompaniesService } from "src/app/services";

@Component({
  selector: "app-create-update-company-products",
  templateUrl: "./create-update.component.html",
  styleUrls: ["./create-update.component.scss"],
})
export class CreateUpdateCompanyProductsComponent implements OnInit {
  @Input("businessId") businessId: string;
  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  isVisible: boolean = false;
  unsubscribe$ = new Subject<void>();
  avatar: Array<any> = [];
  title: string = "";
  form = this.fb.group({
    id: [""],
    name: [undefined, Validators.required],
    role: [undefined, Validators.required],
    title: [undefined, Validators.required],
  });
  listRoles: any = [];
  listTitle: any = [];
  listProducts: any = [];
  timer: any = null;
  loadingSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private companiesService: CompaniesService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {}

  openModal(type?: string, productId?: string) {
    this.isVisible = true;
    this.getListRole();
    this.getListProducts("");
    if (type === "edit" && productId) {
      this.getDetailProductById(productId);
      this.title = 'Cập nhật sản phẩm'
    } else {
      this.form.reset();
      this.avatar = [];
      this.title = 'Thêm sản phẩm mới'
    }
  }

  getDetailProductById(id?: string) {
    this.companiesService.getDetailProductById(id).subscribe(
      (res: any) => {
        if (res.code === 1) {
          let data = res.data;
          this.form.get("name").setValue(data.productId || undefined);
          this.form.get("role").setValue(data.role || undefined);
          this.form.get("title").setValue(data.title || undefined);
          this.form.get("id").setValue(data.businessProductId);
        } else {
          this.notification.create(
            "error",
            "Có lỗi xảy ra",
            res.message || "Lấy dữ liệu thất bại"
          );
        }
      },
      (err: any) => {
        this.notification.create(
          "error",
          "Có lỗi xảy ra",
          err.message || "Lấy dữ liệu thất bại"
        );
      }
    );
  }

  handleCancel() {
    this.isVisible = false;
  }

  submitForm(data: any) {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }
    
    this.loadingSubmit = true;

    if (data.id) {
      let body = {
        businessProductId: data.id,
        role: data.role,
        title: data.title,
      };
      this.companiesService.editProduct(body).subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.notification.create(
              "success",
              "Thành công",
              "Cập nhật sản phẩm thành công"
            );
            this.isVisible = false;
            this.refreshData.emit(true);
            this.form.reset()
          } else {
            this.notification.create(
              "error",
              "Có lỗi xảy ra",
              res.message || "Cập nhật sản phẩm thất bại"
            );
          }
          this.loadingSubmit = false;
        },
        (err: any) => {
          this.notification.create(
            "error",
            "Có lỗi xảy ra",
            err.message || "Cập nhật sản phẩm thất bại"
          );
          this.loadingSubmit = false;
        }
      );
    } else {
      let body = {
        businessId: this.businessId,
        productId: data.name,
        role: data.role,
        title: data.title,
      };
      this.companiesService.createNewProduct(body).subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.notification.create(
              "success",
              "Thành công",
              "Thêm mới sản phẩm thành công"
            );
            this.isVisible = false;
            this.refreshData.emit(true);
            this.form.reset();
          } else {
            this.notification.create(
              "error",
              "Có lỗi xảy ra",
              res.message || "Thêm mới sản phẩm thất bại"
            );
          }
          this.loadingSubmit = false;
        },
        (err: any) => {
          this.notification.create(
            "error",
            "Có lỗi xảy ra",
            err.message || "Thêm mới sản phẩm thất bại"
          );
          this.loadingSubmit = false;
        }
      );
    }
  }

  getListRole() {
    this.companiesService.getListRole().subscribe((res: any) => {
      if (res.code === 1) {
        this.listRoles = [...res.data];
      }
    });
  }

  getListTitle(idRole: any) {
    let query = {
      roleId: idRole,
    };
    if (idRole) {
      this.companiesService.getListTitle(query).subscribe((res: any) => {
        if (res.code === 1) {
          this.listTitle = [...res.data];
        }
      });
    } else {
      this.listTitle = [];
      this.form.get("title").setValue(undefined);
    }
    this.form.get("title").setValue(undefined);
  }

  onSearchProducts(value: string) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.getListProducts(value);
    }, 800);
  }

  getListProducts(e: string) {
    let query = {
      query: e,
      page: 1,
      pageSize: 150,
    };
    this.companiesService
      .getListProductsForCreate(query)
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listProducts = [...res.data];
        }
      });
  }
}
