import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { RootService } from 'src/app/services';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { CompaniesService } from '../../services';
import { CompaniesImportComponent } from './companies-import/companies-import.component';
import { ActivatedRoute, Router } from '@angular/router';
import { track } from 'src/app/helpers/track';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {

  constructor(
    private rootService: RootService,
    private companiesService: CompaniesService,
    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  unsubscribe$ = new Subject<void>();
  cities: Array<any> = [];
  imageFalse: any[] = [];
  listCompanies: any = [];
  @ViewChild('createUpdateModal', { static: true })
  createUpdateModal: CreateUpdateComponent;
  @ViewChild('deleteCompanies', { static: true })
  deleteCompanies: any;
  @ViewChild('showCompanies', { static: true })
  showCompanies: any;
  @ViewChild('hideCompanies', { static: true })
  hideCompanies: any;
  @ViewChild('modalImportCompany', { static: true })
  modalImportCompany: CompaniesImportComponent;
  delayTimer: any;
  total: any = 0;
  loading = false;
  page = 1;
  pageSize = 10;
  search: any = {
    query: '',
    provinceId: undefined,
    status: undefined,
  };
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow;
  dataDelete = {
    label: 'Xác nhận xóa đối tượng',
    value: '<p>Bạn có chắc chắn muốn xoá đối tượng?</p>',
  };
  dataShow = {
    label: 'Xác nhận hiện đối tượng',
    value: '<p>Bạn có chắc chắn muốn hiện đối tượng?</p>',
  };
  dataHide = {
    label: 'Xác nhận ẩn đối tượng',
    value: '<p>Bạn có chắc chắn muốn ẩn đối tượng?</p>',
  };
  selectedProducts = [];
  isForce: any = 0;

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_companies',
    //   contentType: 'product',
    // })
    const type = this.route.snapshot.queryParams.type;

    if (type && type === 'add') {
      this.addNewCompany();
    }
    this.notification.config({
      nzPlacement: 'bottomRight',
    });
    this.getListCities();
    this.getListCompanies();
  }

  getListCities() {
    const query = {
      type: 1,
      parentId: 0,
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.cities = res.data;
      },
      (error) => {
        this.cities = [];
      }
    );
  }

  getListCompanies() {
    const query = {
      page: this.page,
      pageSize: this.pageSize,
      query: this.search.query ? this.search.query.trim() : '',
      provinceId: this.search.provinceId ? this.search.provinceId : '',
      status:
        this.search.status !== undefined || this.search.status != null
          ? this.search.status
          : '',
    };
    this.loading = true;
    this.companiesService
      .getList(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.loading = false;
            this.total = res.totalElement;
            this.listCompanies = [...res.data];
          } else {
            // track('error', {
            //   apiCall: `${window.location.origin}/business`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500
            // })
            this.notification.create(
              'error',
              'Có lỗi xảy ra',
              res.message || 'Lấy dữ liệu thất bại'
            );
          }
          this.loading = false;
        },
        (err: any) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/business`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.notification.create(
            'error',
            'Có lỗi xảy ra',
            err.message || 'Lấy dữ liệu thất bại'
          );
          this.loading = false;
        }
      );
  }

  async addNewCompany() {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_companies',
    //   contentName: 'create',
    //   target: `${window.location.origin}/portal/companies`,
    // })
    await this.router.navigate([`/portal/companies/create/null`]);
    // this.createUpdateModal.showModal();
  }

  resetData() {
    this.search = {
      query: '',
      provinceId: undefined,
      status: undefined,
    };
    this.getListCompanies();
  }

  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, '_blank');
    }
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  onChangeItemCheckbox(e, data) {
    this.mapOfCheckedId[data.businessId] = e;
    this.mapOfCheckedId = {...this.mapOfCheckedId};
    const indexItem = this.selectedProducts.findIndex(item => item.businessId === data.businessId);
    if (e) {
      if (indexItem === -1) {
        this.selectedProducts.push({...data});
      }
    } else {
      if (indexItem !== -1) {
        this.selectedProducts.splice(indexItem, 1);
      }
    }
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.businessId]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item.businessId]
      ) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value) {
        this.ids.push(key);
      }
    }
    this.isShow = this.ids.length <= 0;
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => {
        this.mapOfCheckedId[item.businessId] = value;
        const indexItem = this.selectedProducts.findIndex(gg => gg.businessId === item.businessId);
        if (value) {
          if (indexItem === -1) {
            this.selectedProducts.push({...item});
          }
        } else {
          if (indexItem !== -1) {
            this.selectedProducts.splice(indexItem, 1);
          }
        }
      }
    );
    this.refreshStatus();
  }
  delete(id: any, force?: any) {
    let body: any = {};
    if (force) {
      body = {
        body: {
          ids: id,
          force: true,
        },
      };
    } else {
      body = {
        body: {
          ids: id,
        },
      };
    }
    this.companiesService.deleteCompanies(body).subscribe(
      (res: any) => {
        if (res.code === 1) {
          // this.mapOfCheckedId = {};
          this.notification.create(
            'success',
            'Thành công',
            'Xóa đối tượng thành công'
          );
          this.mapOfCheckedId = {};
          this.deleteCompanies.handleCancel();
          this.getListCompanies();
        } else if (res.code === 107) {
          this.dataDelete.value =
            'Xóa đối tượng sẽ đồng thời gỡ bỏ đối tượng này khỏi các sản phẩm liên quan, bạn có muốn tiếp tục?';
          this.dataDelete = { ...this.dataDelete };
          this.isForce = 1;
        } else {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message || 'Có lỗi xảy ra'
          );
        }
        this.deleteCompanies.success();
      },
      (err) => {
        this.notification.create(
          'error',
          'Lỗi !!!',
          err.message || 'Xóa đối tượng thất bại'
        );
        this.deleteCompanies.success();
        return;
      }
    );
  }

  resetDataDelete() {
    this.isForce = 0;
    this.dataDelete.value = '<p>Bạn có chắc chắn muốn xoá đối tượng?</p>';
  }

  createStamp(data) {
    this.router.navigate(['/portal/stamp/null'], {
      queryParams: { type: 3, objectId: data.businessId },
    });
  }

  changeHiddenStatus(id: any, status: number) {
    const body = {
      ids: id,
      status,
    };
    const successMessage =
      status === 1 ? 'Hiện đối tượng thành công' : 'Ẩn đối tượng thành công';
    this.companiesService.showHiddenStatus(body).subscribe(
      (res: any) => {
        if (res.code === 1) {
          // this.mapOfCheckedId = {};
          this.notification.create('success', 'Thành công', successMessage);
          this.mapOfCheckedId = {};
          this.showCompanies.handleCancel();
          this.hideCompanies.handleCancel();
          this.getListCompanies();
        }
        if (res.code !== 1) {
          this.notification.create(
            'warning',
            'Cảnh báo',
            res.message || 'Có lỗi xảy ra'
          );
        }
        this.showCompanies.success();
        this.hideCompanies.success();
      },
      (err) => {
        this.notification.create(
          'error',
          'Lỗi !!!',
          err.message || 'Có lỗi xảy ra, vui lòng thử lại'
        );
        this.showCompanies.success();
        this.hideCompanies.success();
        return;
      }
    );
  }

  async editCompany(data: any) {
    // this.createUpdateModal.showModal('edit', data.businessId);
    await this.router.navigate([`/portal/companies/update/${data.businessId}`]);
  }

  searchData() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.page = 1;
      this.getListCompanies();
    }, 800);
  }

  async onCreateMultiQrcode() {
    if (this.selectedProducts.length > 5) {
      return this.notification.warning('Vui lòng thử lại', 'Chỉ được tạo tối đa 5 QR Code');
    }
    await this.router.navigate(['/portal/stamp/null'], {
      queryParams: {type: 3, objectId: this.selectedProducts.map(item => item.businessId).join(',')}
    });
  }

  showModalImport() {
    this.modalImportCompany.openModal();
  }
}
