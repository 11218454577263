import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { id } from "date-fns/locale";
import { NzNotificationService } from "ng-zorro-antd";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { convertDateToStringQuery } from "src/app/helpers";
import { SurveyService } from "src/app/services";

@Component({
  selector: "app-survey-answer-detail",
  templateUrl: "./survey-answer-detail.component.html",
  styleUrls: ["./survey-answer-detail.component.scss"],
})
export class SurveyAnswerDetailComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  isVisible = false;
  listAnswers: Array<any> = [];
  loading: boolean = false;
  type: string = "";
  reviewType: any = null;
  page: number = 1;
  pageSize: number = 10;
  total: any = 0;
  idQuestion: any = null

  constructor(
    private surveyService: SurveyService,
    private notification: NzNotificationService,
    private router: Router
  ) {}

  ngOnInit() {}

  openModal(idQuestion: any) {
    this.isVisible = true;
    this.idQuestion = idQuestion
    this.getDetailAnswer(idQuestion);
  }

  getDetailAnswer(id: any) {
    let query = {
      id,
      page: this.page,
      pageSize: this.pageSize,
    };
    this.surveyService
      .getDetailAnswer(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.type =
          res.data && res.data.question.type ? res.data.question.type : "";
        this.reviewType =
          this.type && res.data && res.data.question.reviewType
            ? res.data.question.reviewType
            : null;

        this.listAnswers =
          res.data && res.data.listAnswers && res.data.listAnswers.data
            ? res.data.listAnswers.data.map((ans: any) => {
                let tmp = { ...ans };

                if (this.type == "OPTIONAL") {
                  tmp._answers =
                    ans.answers.answers && ans.answers.answers.length
                      ? ans.answers.answers.join(", ")
                      : "";
                  if (ans.answers.existedOtherValue) {
                    let otherVal = "Khác ";
                    if (ans.answers.otherValue) {
                      otherVal += `(${ans.answers.otherValue})`;
                    }
                    ans._answers += otherVal;
                  }
                  tmp._answers = tmp._answers.trim();
                  tmp._comment = ans.answers.comment;
                  return tmp;
                }

                if (this.type == "OPTIONAL_IMAGE") {
                  tmp._answers =
                    ans.answers.answersReport &&
                    ans.answers.answersReport.length
                      ? ans.answers.answersReport
                      : "";
                  tmp._comment = ans.answers.comment;
                  return tmp;
                }

                if (this.type == "DROPDOWN") {
                  tmp._answers =
                    ans.answers && ans.answers.content
                      ? ans.answers.content
                      : "";
                  tmp._answers = tmp._answers.trim();
                  tmp._comment = ans.answers.comment;
                  return tmp;
                }

                if (this.type == "REVIEW") {
                  if (this.reviewType == 1 || this.reviewType == 2) {
                    tmp._answers = ans.answers.numberValue
                      ? ans.answers.numberValue
                      : null;
                    if (tmp._answers) {
                      if (this.reviewType == 1) {
                        tmp._answers += ` - ${ans.answers.content}`;
                      }
                      if (this.reviewType == 2) {
                        tmp._answers += ` sao - ${ans.answers.content}`;
                      }
                    }
                  }
                  if (this.reviewType == 3) {
                    tmp._answers = {
                      icon:
                        ans.answers && ans.answers.icon ? ans.answers.icon : "",
                      content:
                        ans.answers && ans.answers.content
                          ? ans.answers.content
                          : "",
                    };

                  }
                  return tmp;
                }

                if (this.type == "FILE") {
                  tmp._answers =
                    ans.answers.content && ans.answers.content.length
                      ? ans.answers.content
                      : [];
                  return tmp;
                }

                if (this.type == "CONTACT_INFO" || this.type == "BOX") {
                  tmp._answers =
                    ans.answers.fields && ans.answers.fields.length
                      ? ans.answers.fields
                      : [];
                  return tmp;
                }
              })
            : [];

        this.total =
          res.data && res.data.listAnswers && res.data.listAnswers.totalElement
            ? res.data.listAnswers.totalElement
            : 0;
      });
  }

  changePage(event) {
    this.page = event;
    this.getDetailAnswer(this.idQuestion);
  }

  handleCancel() {
    this.isVisible = false;
  }
}
