import {regexBase64Image} from "./constant";
import {environment} from '../../environments/environment';

export function sleep(ms: any) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeAllSpacingBetweenWords(str: string) {
  return str.replace(/\s+/g, ' ').trim();
}

// [ 'a', 'b', 'c', 'd', 'e', 'f', 'c', 'e', 'g' ]
export function getArrayNotDuplicate(arr: any[]) {
  return arr.filter((e, i) => i === arr.indexOf(e));
}

export function getArrayDuplicate(arr: any[]) {
  return arr.filter((e, i) => i !== arr.indexOf(e));
}

// arr = [{ id: 10, name: 'someName1' },{ id: 10, name: 'someName2' },{ id: 11, name: 'someName3' },{ id: 12, name: 'someName4' }];
export function getObjectArrayNotDuplicate(arr: any[], key: string) {
  return arr
    .map(e => e[key])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(obj => arr[obj])
    .map(e => arr[e])
    .sort((a, b) => a.id - b.id);
}

export function getObjectArrayDuplicate(arr: any[], key: string) {
  const duplicateIds = arr
    .map(e => e[key])
    .map((e, i, final) => final.indexOf(e) !== i && i)
    .filter(obj => arr[obj])
    .map(e => arr[e][key])
    .sort((a, b) => a.id - b.id);
  if (key === 'id') { return arr.filter(obj => duplicateIds.includes(obj.id)); }
}

export function getObjectArrayNotDuplicateAllKey(arr: any) {
  return Array.from(new Set(arr.map(JSON.stringify))).map((i: any) => JSON.parse(i));
}

// arr1 = ["a", "b", "c", "d", "e", "f"];
// rr2 = ["c", "e"];
export function getArrayNotDuplicateAfterCompareTwoArray(arr1: any[], arr2: any[], type?: string) {
  if (type === 'id') { return arr1.filter(e => !arr2.includes(e.id)); }
  return arr1.filter(e => !arr2.includes(e));
}

export function getArrayDuplicateAfterCompareTwoArray(arr1: any[], arr2: any[], type?: string) {
  if (type === 'id') { return arr1.filter(e => arr2.includes(e.id)); }
  return arr1.filter(e => arr2.includes(e));
}

export function getArrayNotNull(arr: any[], type: string) {
  if (type === 'id') { return arr.filter(e => e.id); }
}

export function operationJson(obj: {}) {
  const arr: any = Object.values(obj);
  let subtract = arr[0];
  for (let i = 1; i < arr.length; i++) {
    subtract -= arr[i];
  }
  const max = Math.max(...arr);
  const min = Math.min(...arr);
  const sum = arr.reduce((a, b) => a + b, 0);
  const average = arr.reduce((a, b) => a + b, 0) / arr.length;
  return {
    max,
    min,
    subtract,
    sum,
    average
  };
}

export function operationArray(arr: any[]) {
  let subtract = arr[0];
  for (let i = 1; i < arr.length; i++) {
    subtract -= arr[i];
  }
  const max = Math.max(...arr);
  const min = Math.min(...arr);
  const sum = arr.reduce((a, b) => a + b, 0);
  const average = arr.reduce((a, b) => a + b, 0) / arr.length;
  return {
    max,
    min,
    subtract,
    sum,
    average
  };
}
export function randomString() {
  return Math.random().toString(36).substring(3);
}

export function removeEmptyQuery(query: any) {
  Object.keys(query).forEach(key => {
    if (query[key] === undefined || query[key] === null || query[key] === '') {
      delete query[key];
    }
  });
  return query;
}

export function randomStringWithLength(length) {
  let result = '';
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
  return result;
}


export function convertDateToStringQuery(time: any) {
  console.log('convertDateToStringQuery time', time);
  let dd: any = time.getDate();
  let mm: any = time.getMonth() + 1;
  const yyyy = time.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return [dd, mm, yyyy].join('/');
}

export function convertTimeToStringQuery(time: any) {
  const hh: any = time.getHours();
  let mm: any = time.getMinutes();
  let ss = time.getMilliseconds();
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (ss < 10) {
    ss = '0' + ss.toString();
  }

  return [hh, mm, ss].join(':');
  // var from = time.split(" ");
  // // var time = from[4].split(":");
  // return time;
}

export function convertStringToDate(time: any) {
  const from = time.split(' ');
  const date = from[0].split('/');
  const formattedTime = from[1].split(':');
  return new Date(date[2], date[1] - 1, date[0], formattedTime[0], formattedTime[1], formattedTime[2]);
}

export function convertDateToString(time: any) {
  const year = time.getFullYear();
  let date = time.getDate();
  let month = time.getMonth() + 1;
  let hours = time.getHours();
  let minutes = time.getMinutes();
  let seconds = time.getSeconds();
  if (date < 10) {
    date = '0' + date;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${date}\/${month}\/${year} ${hours}:${minutes}:${seconds}`;
}

export function convertStringToTime(time: any) {
  const from = time.split(':');
  // tslint:disable-next-line:radix
  const hours = parseInt(from[0]);
  // tslint:disable-next-line:radix
  const minutes = parseInt(from[1]);
  return new Date(new Date(new Date().setHours(hours)).setMinutes(minutes));
}

export function convertTimeToString(time: any) {
  let hours = time.getHours();
  let minutes = time.getMinutes();
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  return [hours, minutes].join(':');
}

// search
export function convertWords(text: string) {
  text.toLowerCase();
  text.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  text.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  text.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  text.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  text.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  text.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  text.replace(/đ/g, 'd');
  text.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  text.replace(/ + /g, ' ');
  text.trim();
  return text;
}

export function searchKeyName(arr: any[], text: string) {
  arr.filter((e: any) => {
    const itemData = this.convertWords(e.name) ? removeAllSpacingBetweenWords(this.convertWords(e.name).toLowerCase()) : null;
    const textData = removeAllSpacingBetweenWords(this.convertWords(text).toLowerCase());
    return itemData.indexOf(textData) > -1;
  });
}

export function isNumberKey(event) {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function formatDate(timestamp, symbol = '/') {
  const now = new Date(timestamp);
  const year = '' + now.getFullYear();
  let month = '' + (now.getMonth() + 1);
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = '' + now.getDate();
  if (day.length === 1) {
    day = '0' + day;
  }
  let hour = '' + now.getHours();
  if (hour.length === 1) {
    hour = '0' + hour;
  }
  let minute = '' + now.getMinutes();
  if (minute.length === 1) {
    minute = '0' + minute;
  }
  let second = '' + now.getSeconds();
  if (second.length === 1) {
    second = '0' + second;
  }
  return (
    hour + ':' + minute + ':' + second + ' ' + day + symbol + month + symbol + year
  );
}

// export const configCommon = {
//   editable: true,
//   spellcheck: true,
//   height: 'auto',
//   minHeight: '0',
//   maxHeight: 'auto',
//   width: 'auto',
//   minWidth: '0',
//   translate: 'yes',
//   enableToolbar: true,
//   showToolbar: true,
//   placeholder: 'Enter text here...',
//   defaultParagraphSeparator: '',
//   defaultFontName: '',
//   defaultFontSize: '',
//   fonts: [
//     {class: 'arial', name: 'Arial'},
//     {class: 'times-new-roman', name: 'Times New Roman'},
//     {class: 'calibri', name: 'Calibri'},
//     {class: 'comic-sans-ms', name: 'Comic Sans MS'}
//   ],
//   customClasses: [
//     {
//       name: 'quote',
//       class: 'quote',
//     },
//     {
//       name: 'redText',
//       class: 'redText'
//     },
//     {
//       name: 'titleText',
//       class: 'titleText',
//       tag: 'h1',
//     },
//   ],
//   uploadUrl: 'v1/image',
//   sanitize: false,
//   toolbarPosition: 'top',
//   toolbarHiddenButtons: [
//     ['bold', 'italic'],
//     ['fontSize']
//   ]
// };
export const configCommon = {
  placeholder: '',
  tabsize: 2,
  height: '200px',
  uploadImagePath: environment.uploadStream,
  toolbar: [
    ['misc', ['codeview', 'undo', 'redo']],
    ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
    ['fontsize', ['fontname', 'fontsize', 'color']],
    ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
    ['insert', ['table', 'picture', 'link', 'hr']]
  ],
  fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
};

export const configSummerNote = {
  placeholder: '',
  tabsize: 2,
  height: '200px',
  uploadImagePath: environment.uploadStream,
  toolbar: [
    ['misc', ['codeview', 'undo', 'redo']],
    ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
    ['fontsize', ['fontname', 'fontsize', 'color']],
    ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
    ['insert', ['table', 'picture', 'link', 'hr']]
  ],
  fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
};


export const base64toBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
};

export const isIncludeBase64Image = (base64Image: string) => {
  return base64Image && regexBase64Image.test(base64Image);
}
