import { Component, OnInit, ViewChild } from "@angular/core";
import { SurveyCopyComponent } from "./survey-copy/survey-copy.component";
import { convertDateToStringQuery } from "src/app/helpers";
import { SurveyService } from "src/app/services/business/survey/survey.service";
import {
  NzFormatEmitEvent,
  NzNotificationService,
  NzTreeNodeOptions,
} from "ng-zorro-antd";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { SurveyGroupFormComponent } from "../servey-group-list/survey-group-form/survey-group-form.component";
import { Router } from "@angular/router";
import { track } from 'src/app/helpers/track';
@Component({
  selector: "app-servey-list",
  templateUrl: "./servey-list.component.html",
  styleUrls: ["./servey-list.component.scss"],
})
export class ServeyListComponent implements OnInit {
  @ViewChild("modalSurveyCopy", { static: true }) modalSurveyCopy: SurveyCopyComponent;
  @ViewChild("surveyGroupForm", { static: true }) surveyGroupForm: SurveyGroupFormComponent;
  @ViewChild("deleteProducts", { static: true }) deleteProducts;


  dataDelete = {
    label: "Xác nhận xóa khảo sát",
    value: "<p>Bạn có chắc chắn muốn xoá khảo sát này không?</p>",
  };

  unsubscribe$ = new Subject<void>();
  listSurveyGroup: Array<any> = [];
  listStatus: Array<any> = [
    {
      value: 1,
      label: "Kích hoạt",
    },
    {
      value: 2,
      label: "Bị chặn",
    },
  ];
  listActions: Array<any> = [
    {
      value: 1,
      label: "Ẩn",
    },
    {
      value: 2,
      label: "Hiện",
    },
  ];
  listSurveys: Array<any> = [];
  search: any = {
    name: "",
    groupId: undefined,
    status: undefined,
    createdTime: [],
  };
  total: number = 0;
  loading: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  isIndeterminate: boolean = false;
  isAllDisplayDataChecked: boolean = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  listCheckedItem: any = [];
  ids: any[] = [];
  isShow: Boolean;

  constructor(
    private surveyService: SurveyService,
    private notification: NzNotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_survey',
    //   contentType: 'product',
    // })
    this.getListSurveys();
    this.getListSurveyGroups();
  }

  showModalCopy() {
    this.modalSurveyCopy.showModal();
  }

  getListSurveys() {
    let query = {
      name:
        this.search.name && this.search.name.trim() != ""
          ? this.search.name.trim()
          : "",
      groupId: this.search.groupId || "",
      status: this.search.status || "",
      createdFrom:
        this.search.createdTime && this.search.createdTime[0]
          ? convertDateToStringQuery(this.search.createdTime[0])
          : "",
      createdTo:
        this.search.createdTime && this.search.createdTime[1]
          ? convertDateToStringQuery(this.search.createdTime[1])
          : "",
      page: this.page,
      pageSize: this.pageSize,
      type: 'BASIC'
    };
    this.loading = true;

    this.surveyService
      .getListSurveys(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.code === 1) {
            this.listSurveys = res && res.data ? [...res.data] : [];
            this.total = res.totalElement;
          } else {
            // track('error', {
            //   apiCall: `${window.location.origin}/survey`,
            //   httpResponseCode: 500,
            //   errorMessage: res.message,
            //   errorCode: 500
            // })
            this.notification.warning(
              "Có lỗi xảy ra",
              res && res.message ? res.message : "Vui lòng thử lại"
            );
          }
          this.loading = false;
        },
        (err: any) => {
          // track('error', {
          //   apiCall: `${window.location.origin}/survey`,
          //   httpResponseCode: 500,
          //   errorMessage: err,
          //   errorCode: 500
          // })
          this.notification.error(
            "Có lỗi xảy ra",
            err && err.message ? err.message : "Vui lòng thử lại"
          );
          this.loading = false;
        }
      );
  }

  getListSurveyGroups() {
    let query = {
      parentId: "",
      page: 1,
      pageSize: 10,
    };
    this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.code === 1) {
          this.listSurveyGroup = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
        }
      });
  }

  createSurvey() {
    // track('manualTrackInteractionContent', {
    //   interaction: "click",
    //   regionName: 'portal_survey',
    //   contentName: 'create',
    //   target: `${window.location.origin}/portal/survey/null/form`,
    // })
    this.router.navigate(['/portal/survey/null/form']);
  }

  resetSearch() {
    this.search.name = "";
    this.search.groupId = undefined;
    this.search.status = undefined;
    this.search.createdTime = [];
    this.getListSurveys();
  }

  onExpandChange(e: NzFormatEmitEvent): void {
    const node = e.node;
    if (
      node &&
      node.getChildren().length === 0 &&
      node.isExpanded &&
      node.origin &&
      node.origin.id
    ) {
      this.loadChildSurveyGroup(node.origin.id).subscribe((res: any) => {
        if (res.code === 1) {
          let childData = res.data
            ? res.data.map((gg: any) => {
                let tmp = { ...gg };
                tmp.title = gg.name;
                tmp.key = gg.id;
                return tmp;
              })
            : [];
          if (childData && childData.length) {
            node.addChildren(childData);
          } else {
            node.isLeaf = true;
          }
        }
      });
    }
  }

  loadChildSurveyGroup(parentId: string): Observable<NzTreeNodeOptions[]> {
    let query = {
      parentId,
      page: 1,
      pageSize: 10,
    };
    return this.surveyService
      .getListSurveyGroups(query)
      .pipe(takeUntil(this.unsubscribe$));
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item.id] = value)
    );
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some((item) => this.mapOfCheckedId[item.id]) &&
      !this.isAllDisplayDataChecked;

    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
        let itemChecked = this.listOfDisplayData.find(
          (gg: any) => gg.id === key
        );
        if (
          itemChecked &&
          this.listCheckedItem.findIndex(
            (kk: any) => kk.id === itemChecked.id
          ) === -1
        ) {
          this.listCheckedItem.push(itemChecked);
        }
      } else {
        let indexRemoved = this.listCheckedItem.findIndex(
          (kk: any) => kk.id === key
        );
        if (indexRemoved > -1) {
          this.listCheckedItem.splice(indexRemoved, 1);
        }
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  onShowModalFormSurveyGroup(type: string, listSurvey: any = null) {
    this.surveyGroupForm.showModal(type, listSurvey);
  }

  createStamp(data) {
    this.router.navigate(['/portal/stamp/null'], { queryParams: { type: 4, surveyId: data.id }})
  }

  confirmDelete = {
    label: "Xác nhận xóa khảo sát",
    value: "<p>Xóa khảo sát, bạn có muốn tiếp tục?</p>",
    type: 'confirm'
  };

  delete(output: any) {

    const id = output[0]
    this.surveyService
      .deleteSurvey(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {


          if (res.code === 1) {
            this.mapOfCheckedId = {};
            this.openMessage(
              "success",
              "Thành công",
              "Xóa khảo sát thành công"
            );
            this.deleteProducts.handleCancel();
            this.getListSurveys();
          } else {
            this.openMessage("warning", "Cảnh báo", `${res.message}`);
          }
          this.deleteProducts.success();
        },
        (err) => {
          this.openMessage("error", "Lỗi !!!", `${err}`);
          this.deleteProducts.success();
          return;
        }
      );
  }


  viewDetail(id) {

    this.router.navigate([`/portal/survey/${id}/form`]);
  }


  openMessage(type: string, title: string, content: any) {
    return this.notification.create(type, title, content, {
      nzPauseOnHover: true,
    });
  }

  onReloadData(e: any) {
    if (e) {
      this.mapOfCheckedId = {};
      this.listCheckedItem = [];
      this.ids = [];
      this.getListSurveys()
    }
  }

  onSearchFilter() {
    this.page = 1
    this.getListSurveys()
  }
}
