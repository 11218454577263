import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NzConfigService, NzModalService} from 'ng-zorro-antd';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupLoyaltyComponent implements OnInit {
  isVisible = false;
  index = 1;
  isCheckAccountVietel = false;
  name: any;
  title: any;
  data: any;
  id: any;
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzConfigService,
    public commonService: CommonService

  ) {
  }

  ngOnInit() {
  }


  handleCancel(): void {
    this.isVisible = false;
  }

  showModal(data: any, id?: any) {
    this.isVisible = true;
    this.data = data;
    if (typeof id === 'string') {
      const a = [];
      a.push(id);
      this.id = a;
    } else {
      this.id = id;
    }
  }

  onSubmit() {
    this.emitData.emit(this.id);
    this.isVisible = false;
  }

}
