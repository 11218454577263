import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { takeUntil } from 'rxjs/operators';
import { DashboardService, ProductsAnalyticsService, ProductsService } from '../../../../services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-products-analytics',
  templateUrl: './products-analytics.component.html',
  styleUrls: ['./products-analytics.component.scss']
})
export class ProductsAnalyticsComponent implements OnInit, OnDestroy {
  dateRange = [];
  dataTimeLine = [];
  categoryLine = [];
  action = 'scan';
  unsubscribe$ = new Subject<void>();
  tabValue;
  startTime;
  timeLabel;
  endTime;
  dataTimePie = [];
  queryChartLine;
  actions = [
    {
      label: 'Scan',
      value: 'scan'
    },
    {
      label: 'Đánh giá',
      value: 'review'
    },
    {
      label: 'Hỏi đáp',
      value: 'question'
    }
  ];
  productId;
  listAnalytics;
  actionText = 'scan';

  constructor(
    private dashboardService: DashboardService,
    private productsAnalyticsService: ProductsAnalyticsService,
    private productService: ProductsService,
  ) {

  }

  managementStatus: number;
  roleCode: string = null;
  ngOnInit() {
    this.productId = window.location.href.split('/')[5];
    this.changeTab('today');

    const query = {
      product_id: this.productId
    };
    this.productsAnalyticsService.listAnalytics(query).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.listAnalytics = res.data;
    });

    this.productService.getById(this.productId).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        if (res.code === 1) {
          this.managementStatus = res.data.managementStatus;
          this.roleCode = res.data.roleCode;
          let body: any = {
            managementStatus: this.managementStatus,
            roleCode: this.roleCode
          }
          this.productService.getManagementStatus(body);
        }
      }
    );
  }

  /*Char*/
  changeTab(value: string) {
    this.dateRange = [];
    this.tabValue = value;
    if (this.tabValue === 'today') {
      this.startTime = this.formatDate(Date.now()).split(' ')[1];
      this.endTime = this.formatDate(Date.now()).split(' ')[1];
      this.timeLabel = 'hour';
    }

    if (this.tabValue === 'week') {
      const startDayInWeed = new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1);
      this.startTime = this.formatDate(new Date(new Date(new Date().setDate(startDayInWeed)).setHours(0, 0, 0, 1)).getTime()).split(' ')[1];
      this.endTime = this.formatDate(new Date(new Date(new Date().setDate(startDayInWeed + 6)).setHours(23, 59, 59, 999)).getTime()).split(' ')[1];
      this.timeLabel = 'day';

    }

    if (this.tabValue === 'month') {
      this.startTime = this.formatDate(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 1)).getTime()).split(' ')[1];
      this.endTime = this.formatDate(new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 999)).getTime()).split(' ')[1];
      this.timeLabel = 'day';

    }

    setTimeout(() => {
      this.loadChart();
    }, 300);
  }

  actionSelect(event) {
    this.action = event;
    if (event === 'review') {
      this.actionText = 'đánh giá';
    } else if (event === 'question') {
      this.actionText = 'hỏi đáp';
    } else {
      this.actionText = event;
    }
    setTimeout(() => {
      this.loadChart();
    }, 300);

  }

  search() {
    if (this.dateRange.length > 0) {
      this.startTime = this.formatDate(this.dateRange[0].getTime()).split(' ')[1];
      this.endTime = this.formatDate(this.dateRange[1].getTime()).split(' ')[1];
      if (this.startTime === this.endTime) {
        this.timeLabel = 'hour';
      } else {
        this.timeLabel = 'day';
      }

      setTimeout(() => {
        this.loadChart();
      }, 300);

    } else {
      this.changeTab(this.tabValue);
    }
  }

  loadChart() {
    this.categoryLine = [];
    this.dataTimeLine = [];
    this.dataTimePie = [];
    this.queryChartLine = {
      start_time: this.startTime,
      end_time: this.endTime,
      action_type: this.action,
      time_label: this.timeLabel,
      product_id: this.productId,
    };
    this.dashboardService.charList(this.queryChartLine).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res) {
        res.data.timeStats.map((data) => {

          if (this.timeLabel === 'hour') {
            const hour = parseInt(data.timeLabel.split(' ')[1].split(':')[0]) + 'h';
            this.categoryLine.push(hour);
          } else {
            const day = data.timeLabel.split(' ')[0].split('-');
            this.categoryLine.push(day[0] + '/' + day[1]);
          }

          this.dataTimeLine.push({ y: data.value });
        });
        res.data.provinceStats.map((data) => {
          this.dataTimePie.push([data.provinceName, data.count]);
        });
      }
    }, (err) => {
      this.highchartsLine();
      this.loadChartPie();
    }, () => {
      this.highchartsLine();
      this.loadChartPie();
    });


  }

  highchartsLine() {
    Highcharts.chart('chartLine', {
      chart: {
        type: 'area',
      },
      title: {
        align: 'left',
        text: ''
      },
      xAxis: {
        categories: this.categoryLine
      },

      yAxis: {
        title: {
          text: ''
        }
      },
      // tooltip: {
      //   pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      // },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{
        name: `Số lượng ${this.actionText}`,
        colorByPoint: true,
        data: this.dataTimeLine,
        type: undefined
      }]
    });

  }

  loadChartPie() {
    Highcharts.chart('pie', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        type: 'pie',
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: `Tỉ lệ ${this.actionText}: <b>{point.percentage:.1f}%</b>`
      },

      series: [{
        type: 'pie',
        allowPointSelect: true,
        keys: ['name', 'y', 'selected', 'sliced'],
        data: this.dataTimePie,
        showInLegend: true
      }]
    });
  }

  formatDate(timestamp) {
    const now = new Date(timestamp);
    const year = '' + now.getFullYear();
    let month = '' + (now.getMonth() + 1);
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = '' + now.getDate();
    if (day.length === 1) {
      day = '0' + day;
    }
    let hour = '' + now.getHours();
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    let minute = '' + now.getMinutes();
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    let second = '' + now.getSeconds();
    if (second.length === 1) {
      second = '0' + second;
    }
    return hour + ':' + minute + ':' + second + ' ' + day + '-' + month + '-' + year;
  }

  /*End Char*/

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
