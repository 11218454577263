import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "src/app/services/base.service";

@Injectable({ providedIn: "root" })
export class CheckInHistoryService extends BaseService {
  getHistory(query?: any) {
    const httpOptions = {
      params: query,
    };
    return this.get<any>("/checkin/history", httpOptions);
  }

  
  export(params): Observable<any> {
    const httpOptions = {
      params: params,
      responseType: 'blob'
    };
    return this.get<any>(`/checkin/history/export`, httpOptions);
  }

}
