import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-content-store',
  templateUrl: './no-content-store.component.html',
  styleUrls: ['./no-content-store.component.scss']
})
export class NoContentStoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
