import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import { RootService } from 'src/app/services';
import { UploaderComponent } from '../../../shared/uploader/uploader.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CompaniesService } from '../../../services';
import { MapsAPILoader } from '@agm/core';
import { MouseEvent } from '@agm/core';
import { track } from 'src/app/helpers/track';
import { DomSanitizer } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {configCommon, configSummerNote} from '../../../helpers';
import {URL_LINK} from '../../../helpers/constant';
interface marker {
  lat: number;
  lon: number;
  label?: string;
  draggable: boolean;
}

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss'],
})
export class CreateUpdateComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private rootService: RootService,
    private companiesService: CompaniesService,
    private notification: NzNotificationService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
  ) {}
  isVisible = false;
  unsubscribe$ = new Subject<void>();
  idVietnam = 241;
  listCountries: any = [];
  listCities: any = [];
  listDistricts: any = [];
  listWards: any = [];
  avatar: Array<any> = [];
  wallpaper: Array<any> = [];
  images: Array<any> = [];
  title = '';
  certificates: Array<any> = [];
  form: any = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    tax: [''],
    address: ['', Validators.required],
    idCountry: [undefined, Validators.required],
    idProvince: [undefined, Validators.required],
    idDistrict: [undefined, Validators.required],
    idWard: [undefined, Validators.required],
    phone: [''],
    email: ['', [Validators.email]],
    website: [
      '',
      [
        Validators.pattern(
          /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
        ),
      ],
    ],
    lat: [''],
    lon: [''],
    description: [''],
    shortDescription: ['', Validators.maxLength(100)],
    avatar: [''],
    wallpaper: [''],
    images: [''],
    video: [''],
    certificates: [''],
    bank: this.fb.array([]),
    wallet: this.fb.array([]),
    linkPages: this.fb.array([]),
    career: this.fb.array([]),
    // extraInfo: this.fb.array([]),
  });
  public config: any = configSummerNote;

  initExtraInfo = {name: '', image: '', value: '', isValid: true};
  extraInfos: any = [{...this.initExtraInfo}];

  removeExtraInfo(index) {
    this.extraInfos.splice(index, 1);

    // this.form.get('attributes').removeControl(index);
  }

  changeKeyExtraInfo(event, index) {
    this.extraInfos[index].name = event && event.trim() && event.trim().length ? event.trim() : null;
    // this.extraInfos[index].isValid = !!this.extraInfos[index].name.trim();
    this.extraInfos[index].isValid = !!this.extraInfos[index].name;
  }

  // async modelChanged(value, index) {
  //   console.log("value", value);
  //   this.extraInfos[index].value = value;
  //   this.form.updateValueAndValidity();
  // }

  addExtraInfos() {
    // this.extraInfos.push(this.initExtraInfo);
    this.extraInfos.push({ ...this.initExtraInfo });
  }

  // createExtraInfo(): FormGroup {
  //   return this.fb.group({
  //     turn: [null, [Validators.min(1), Validators.max(999999999)]],
  //     gift_id: null
  //   });
  // }

  loading = false;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  videoIntro: any = [];
  listField: Array<any> = [
    {
      image: '/assets/svg/icon-job.svg',
      type: 'job',
      name: 'Nghề nghiệp',
      placeholder: 'Nhập nghề nghiệp...',
    },
    {
      image: '/assets/svg/icon-company.svg',
      type: 'company',
      name: 'Công ty',
      placeholder: 'Nhập công ty...',
    },
    {
      image: '/assets/svg/icon-hobby.svg',
      type: 'hobby',
      name: 'Sở thích',
      placeholder: 'Nhập sở thích...',
    },
    {
      image: '/assets/svg/icon-education.svg',
      type: 'education',
      name: 'Học vấn',
      placeholder: 'Nhập học vấn...',
    },
    {
      image: '/assets/svg/icon-wallet.svg',
      type: 'wallet',
      name: 'Ví điện tử',
      placeholder: '',
    },
    {
      image: '/assets/svg/icon-bank.svg',
      type: 'bank',
      name: 'Ngân hàng',
      placeholder: '',
    },
  ];
  selectedListField: Array<any> = [];
  listLinkSocial: Array<any> = [
    {
      image: '/assets/svg/icon-facebook.svg',
      type: 'facebook',
      name: 'Facebook',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-shopee.svg',
      type: 'shopee',
      name: 'Shopee',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-zalo.svg',
      type: 'zalo',
      name: 'Zalo',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-ig.svg',
      type: 'instagram',
      name: 'Instagram',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-tiktok.svg',
      type: 'tiktok',
      name: 'Tiktok',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-tiki.svg',
      type: 'tiki',
      name: 'Tiki',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-youtube.svg',
      type: 'youtube',
      name: 'Youtube',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-linkedin.svg',
      type: 'linkedin',
      name: 'Linkedin',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-lazada.svg',
      type: 'lazada',
      name: 'Lazada',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-sendo.svg',
      type: 'sendo',
      name: 'Sendo',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-wechat.svg',
      type: 'wechat',
      name: 'Wechat',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-whatsapp.svg',
      type: 'whatsapp',
      name: 'Whatsapp',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-snapchat.svg',
      type: 'snapchat',
      name: 'Snapchat',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-twitter.svg',
      type: 'twitter',
      name: 'Twitter',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-skype.svg',
      type: 'skype',
      name: 'Skype',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-telegram.svg',
      type: 'telegram',
      name: 'Telegram',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-messenger.svg',
      type: 'messenger',
      name: 'Messenger',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-slack.svg',
      type: 'slack',
      name: 'Slack',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-viber.svg',
      type: 'viber',
      name: 'Viber',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-amazon.svg',
      type: 'amazon',
      name: 'Amazon',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-etsy.svg',
      type: 'etsy',
      name: 'Etsy',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-line.svg',
      type: 'line',
      name: 'Line',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-hangouts.svg',
      type: 'hangouts',
      name: 'Google Hangouts',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-microsoftteam.svg',
      type: 'Microsoft Teams',
      name: 'Microsoft Teams',
      placeholder: 'Nhập địa chỉ liên kết...',
    },
    {
      image: '/assets/svg/icon-kakaotalk.svg',
      type: 'kakaotalk',
      name: 'KakaoTalk',
      placeholder: 'Nhập địa chỉ liên kết...',
    }
  ];
  listWallet: Array<string> = [
    'Ví Momo',
    'Ví Zalo Pay',
    'Ví Viettel Pay',
    'Ví Shopee Pay',
    'Ví Ngân lượng',
    'Ví Payoo',
    'Ví VTC Pay',
    'Ví Việt',
    'Ví Vimo',
    'Ví Moca',
  ];
  listBanking: Array<string> = [
    'ABBANK - NH An Bình',
    'ACB - NH Á Châu',
    'AGRIBANK - NH NN & PT Nông thôn Việt Nam',
    'ANZVL - NH TNHH MTV ANZ Việt Nam',
    'BAC A BANK - NH Bắc Á',
    'BAOVIET BANK - NH Bảo Việt',
    'BIDV - NH Đầu tư và Phát triển Việt Nam',
    'CB - NH Xây dựng',
    'CIMB - NH MTV CIMB Việt Nam',
    'CO OP BANK - NH Hợp tác xã Việt Nam',
    'DONGA BANK - NH Đông Á',
    'EXIMBANK - NH Xuất nhập khẩu',
    'GPBANK - NH Dầu Khí Toàn Cầu',
    'HDBANK - NH Phát triển Thành phố Hồ Chí Minh',
    'HLBVN - NH MTV Hong Leong Việt Nam',
    'HSBC',
    'IVB - NH Indovina',
    'KIENLONGBANK',
    'LIENVIETPOST BANK',
    'MB - NH Quân Đội',
    'MSB - NH Hàng hải',
    'NAM A BANK - NH Nam Á',
    'NCB - NH Quốc dân',
    'OCB - NH Phương Đông',
    'OCEANBANK - NH Đại Dương',
    'PBVN - NH MTV Public Bank Việt Nam',
    'PG BANK - NH Xăng dầu Petrolimex',
    'PVCOMBANK - NH Đại Chúng Việt Nam',
    'SACOMBANK - NH Sài Gòn Thương Tín',
    'SAIGONBANK - NH Sài Gòn Công Thương',
    'SCB - NH Sài Gòn',
    'SCBVL - Standard Chartered Việt Nam',
    'SEABANK - NH Đông Nam Á',
    'SHB - NH Sài Gòn – Hà Nội',
    'SHBVN -  NH Shinhan Việt Nam',
    'TECHCOMBANK - NH Kỹ Thương',
    'TPBANK - NH Tiên Phong',
    'UOB - NH UOB Việt Nam',
    'VBSP - NH Chính sách xã hội Việt Nam',
    'VDB - NH Phát triển Việt Nam',
    'VIB - NH Quốc tế',
    'VIET CAPIPTAL BANK - NH Bản Việt',
    'VIETA BANK - NH Việt Á',
    'VIETBANK - NH Việt Nam Thương tín',
    'VIETCOMBANK - NH Ngoại thương Việt Nam',
    'VIETINBANK - NH Công thương Việt Nam',
    'VPBANK - NH Việt Nam Thịnh Vượng',
    'VRB - NH Liên doanh Việt Nga',
    'WOORI - NH Woori Việt Nam',
  ];

  selectedListLinkSocial: Array<any> = [];

  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();

  lat = 0;
  lon = 0;
  // zoom: number = 15;
  fullAddress: string;
  fullAddress2: string;
  nameAddress: string;
  lengthAddress: number;
  placeId: string;
  showSearch: Boolean = true;
  isRequired: Boolean = false;
  delayTimer: any;
  indexWebsite: number;

  markers: marker[] = [
    {
      lat: this.lat,
      lon: this.lon,
      label: 'A',
      draggable: false,
    },
  ];

  idCompany: any;
  type: any;

  ngOnInit() {
    // track('trackLoadPageView', {
    //   screenName: 'portal_companies_form',
    //   contentType: 'product',
    // });
    this.type = this.route.snapshot.paramMap.get('type');
    this.idCompany = this.route.snapshot.paramMap.get('idCompany');
    this.getListCountries();
    this.getListCities();

    if (this.type === 'update' && this.idCompany) {
      this.getDetailCompanyById(this.idCompany);
      this.title = 'Cập nhật doanh nghiệp';
    } else {
      this.setNull();
      this.form.reset();
      this.avatar = [];
      this.wallpaper = [];
      this.images = [];
      this.videoIntro = +[];
      this.certificates = [];
      this.selectedListLinkSocial = [this.listLinkSocial[0].type];
      this.title = 'Thêm doanh nghiệp, cá nhân mới';
      // khởi tạo trường mặc định hiển thị
      this.selectedListField = [this.listField[0].type];
      const itemCareer = this.itemField(this.listField[0].type);
      this.formArrayCareer.clear();
      this.formArrayWallet.clear();
      this.formArrayBank.clear();
      this.formArrayCareer.push(
        this.fb.group({
          code: [''],
          value: [
            '',
            itemCareer.type === 'bank' || itemCareer.type === 'wallet'
              ? undefined
              : Validators.required,
          ],
          type: [itemCareer.type],
          name: [itemCareer.name],
          image: [itemCareer.image],
          placeholder: [itemCareer.placeholder],
        })
      );
      const itemLinkPage = this.itemLinkSocial(this.listLinkSocial[0].type);
      this.formArrayLinkPages.clear();
      this.formArrayLinkPages.push(
        this.fb.group({
          code: [''],
          value: [
            '',
            [
              Validators.pattern(
                /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
              ),
              Validators.required,
            ],
          ],
          type: [itemLinkPage.type],
          name: [itemLinkPage.name],
          image: [itemLinkPage.image],
          placeholder: [itemLinkPage.placeholder],
        })
      );
      this.indexWebsite = 0;
    }
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.form.get('address').setValue(place.formatted_address);
          this.form.get('lat').setValue(place.geometry.location.lat());
          this.form.get('lon').setValue(place.geometry.location.lng());
          this.markers.length = 0;
          this.markers.push({
            lat: this.latitude,
            lon: this.longitude,
            draggable: true,
          });
          this.zoom = 15;
        });
      });
    });
  }

  // showModal(type?: string, businessId?: string) {
  //   document.body.style.position = 'fixed';
  //   this.isVisible = true;
  //   this.getListCountries();
  //   this.getListCities();
  //
  //   if (type === 'edit' && businessId) {
  //     this.getDetailCompanyById(businessId);
  //     this.title = 'Cập nhật doanh nghiệp';
  //   } else {
  //     this.setNull();
  //     this.form.reset();
  //     this.avatar = [];
  //     this.wallpaper = [];
  //     this.images = [];
  //     this.videoIntro = +[];
  //     this.certificates = [];
  //     this.selectedListLinkSocial = [this.listLinkSocial[0].type];
  //     this.title = 'Thêm doanh nghiệp, cá nhân mới';
  //     // khởi tạo trường mặc định hiển thị
  //     this.selectedListField = [this.listField[0].type];
  //     const itemCareer = this.itemField(this.listField[0].type);
  //     this.formArrayCareer.clear();
  //     this.formArrayWallet.clear();
  //     this.formArrayBank.clear();
  //     this.formArrayCareer.push(
  //       this.fb.group({
  //         code: [''],
  //         value: [
  //           '',
  //           itemCareer.type === 'bank' || itemCareer.type === 'wallet'
  //             ? undefined
  //             : Validators.required,
  //         ],
  //         type: [itemCareer.type],
  //         name: [itemCareer.name],
  //         image: [itemCareer.image],
  //         placeholder: [itemCareer.placeholder],
  //       })
  //     );
  //     const itemLinkPage = this.itemLinkSocial(this.listLinkSocial[0].type);
  //     this.formArrayLinkPages.clear();
  //     this.formArrayLinkPages.push(
  //       this.fb.group({
  //         code: [''],
  //         value: [
  //           '',
  //           [
  //             Validators.pattern(
  //               /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  //             ),
  //             Validators.required,
  //           ],
  //         ],
  //         type: [itemLinkPage.type],
  //         name: [itemLinkPage.name],
  //         image: [itemLinkPage.image],
  //         placeholder: [itemLinkPage.placeholder],
  //       })
  //     );
  //     this.indexWebsite = 0;
  //   }
  // }

  setNull() {
    this.latitude = 0;
    this.longitude = 0;
    this.markers.length = 0;
    this.markers.push({
      lat: 0,
      lon: 0,
      draggable: true,
    });
    this.zoom = 16;
    // this.form.reset();
  }

  getDetailCompanyById(id?: string) {
    this.companiesService.getDetailCompanyById(id).subscribe(
      (res: any) => {
        if (res.code === 1) {
          const data = res.data;
          this.avatar = data.avatar
            ? [
                {
                  name: data.avatar,
                  type: 'image/*',
                  url: data.avatar,
                },
              ]
            : [];
          this.wallpaper = [
            {
              name: data.wallpaper,
              type: 'image/*',
              url: data.wallpaper,
            },
          ];
          this.images = [];
          if (data && data.photos && data.photos.length) {
            for (const photo of data.photos) {
              this.images.push({
                name: photo,
                type: 'image/*',
                url: photo,
              });
            }
          }
          this.wallpaper = data.wallpaper
            ? [
                {
                  name: data.wallpaper,
                  type: 'image/*',
                  url: data.wallpaper,
                },
              ]
            : [];

          if (data.career && data.career.length) {
            this.formArrayCareer.clear();
            for (const careerKey of data.career) {
              this.formArrayCareer.push(
                this.fb.group({
                  code: [careerKey.code],
                  value: [
                    careerKey.value,
                    careerKey.type === 'bank' || careerKey.type === 'wallet'
                      ? undefined
                      : Validators.required,
                  ],
                  type: [careerKey.type],
                  name: [careerKey.name],
                  image: [careerKey.image],
                  placeholder: [careerKey.placeholder],
                })
              );
              this.selectedListField.push(careerKey.type);
            }
          } else {
            this.formArrayCareer.clear();
            this.selectedListField = [];
          }
          if (data.linkPages && data.linkPages.length) {
            this.formArrayLinkPages.clear();
            this.indexWebsite = 0;
            for (const linkPage of data.linkPages) {
              this.formArrayLinkPages.push(
                this.fb.group({
                  code: [linkPage.code],
                  value: [
                    linkPage.value,
                    [
                      Validators.pattern(
                        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
                      ),
                      Validators.required,
                    ],
                  ],
                  type: [linkPage.type],
                  name: [linkPage.name],
                  image: [linkPage.image],
                  placeholder: [linkPage.placeholder],
                })
              );
              if (linkPage.type === 'website' && Number(linkPage.code) > this.indexWebsite) {
                this.indexWebsite = linkPage.code;
              }
              this.selectedListLinkSocial.push(linkPage.type);
            }
          } else {
            this.formArrayLinkPages.clear();
            this.selectedListLinkSocial = [];
          }
          if (data.bank && data.bank.length) {
            this.formArrayBank.clear();
            for (const bankItem of data.bank) {
              this.formArrayBank.push(
                this.fb.group({
                  name: [bankItem.name, Validators.required],
                  account: [bankItem.account, Validators.required],
                  username: [bankItem.username, Validators.required],
                })
              );
            }
          } else {
            this.formArrayBank.clear();
          }
          if (data.wallet && data.wallet.length) {
            this.formArrayWallet.clear();
            for (const walletItem of data.wallet) {
              this.formArrayWallet.push(
                this.fb.group({
                  name: [walletItem.name, Validators.required],
                  account: [walletItem.account, Validators.required],
                  username: [walletItem.username, Validators.required],
                })
              );
            }
          } else {
            this.formArrayWallet.clear();
          }
          this.form.get('name').setValue(data.name || '');
          this.form.get('tax').setValue(data.taxCode || '');
          this.form.get('address').setValue(data.address || '');
          this.form.get('idCountry').setValue(data.countryId || undefined);
          this.form.get('idProvince').setValue(data.provinceId || undefined);
          this.form.get('idDistrict').setValue(data.districtId || undefined);
          this.form.get('idWard').setValue(data.wardId || undefined);
          this.form.get('phone').setValue(data.phone || '');
          this.form.get('email').setValue(data.email || '');
          this.form.get('website').setValue(data.website || '');
          this.form.get('description').setValue(data.description || '');
          this.form.get('shortDescription').setValue(data.shortDescription || '');
          this.form.get('avatar').setValue(data.avatar || null);
          this.form.get('id').setValue(data.id);
          this.form.get('wallpaper').setValue(data.wallpaper || '');
          this.form.get('images').setValue(data.photos || '');
          this.form.get('video').setValue([data.video] || []);
          this.extraInfos = data.extraInfo && data.extraInfo.length ? data.extraInfo.map(item => {
            return {
              ...item,
              isValid: true
            };
          }) : [];
          if (data.video) {
            const tmpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              data.video
            );
            this.videoIntro = [];
            this.videoIntro.push(tmpUrl);
          } else {
            this.videoIntro = [];
          }
          this.form.get('certificates').setValue(data.mediaFiles || undefined);
          this.certificates = data.mediaFiles ? data.mediaFiles : [];
          if (data.lat && data.lon) {
            this.latitude = data.lat;
            this.longitude = data.lon;
            this.form.get('lat').setValue(data.lat);
            this.form.get('lon').setValue(data.lon);
            this.markers = [];
            this.markers.push({
              lat: this.latitude,
              lon: this.longitude,
              draggable: true,
            });
            this.zoom = 15;
          } else {
            this.setNull();
          }
        } else {
          this.notification.create(
            'error',
            'Có lỗi xảy ra',
            res.message || 'Lấy dữ liệu thất bại'
          );
          this.changePositionBody();
          this.isVisible = false;
        }
      },
      (err: any) => {
        this.notification.create(
          'error',
          'Có lỗi xảy ra',
          err.message || 'Lấy dữ liệu thất bại'
        );
        this.changePositionBody();
        this.isVisible = false;
      }
    );
  }

  handleCancel() {
    // track('manualTrackInteractionContent', {
    //   interaction: 'click',
    //   regionName: 'portal_companies_form',
    //   contentName: 'cancel',
    //   target: `${window.location.origin}/portal/companies`,
    // });
    this.changePositionBody();
    this.isVisible = false;
  }

  changePositionBody() {
    document.body.style.position = 'unset';
  }

  submitForm(data: any) {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      // this.form.controls[i].controls[0].controls ~ this.form.controls
      if (
        i === 'bank' ||
        i === 'wallet' ||
        i === 'career' ||
        i === 'linkPages'
      ) {
        if (
          this.form.controls[i].controls &&
          this.form.controls[i].controls.length
        ) {
          this.form.controls[i].controls.forEach((groupControl) => {
            // tslint:disable-next-line:forin
            for (const z in groupControl.controls) {
              // chọc vào fb.group bên trong formArray
              groupControl.controls[z].markAsDirty();
              groupControl.controls[z].updateValueAndValidity();
            }
          });
        }
      }
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid || this.extraInfos && this.extraInfos.length !== this.extraInfos.filter(item => item.name).length) {
      console.log('chh_log ---> submitForm ---> this.form', this.form);
      console.log('this.extraInfos', this.extraInfos);
      this.extraInfos = this.extraInfos.map(item => {
        return {
          ...item,
          value: item.value && item.value.trim() && item.value.length ? item.value.trim() : '',
          isValid: !!item.name
        };
      });
      return;
    }
    this.loading = true;
    const body = {
      ...this.form.value,
      name: data.name.trim(),
      taxCode: data.tax ? data.tax.trim() : '',
      address: data.address.trim(),
      countryId: data.idCountry || null,
      provinceId: data.idProvince || null,
      districtId: data.idDistrict || null,
      wardId: data.idWard || null,
      phone: data.phone ? data.phone.trim() : null,
      email: data.email ? data.email.trim() : null,
      website: data.website ? data.website.trim() : null,
      description: data.description ? data.description.trim() : null,
      avatar: data.avatar ? data.avatar.trim() : null,
      wallpaper: data.wallpaper ? data.wallpaper.trim() : null,
      photos: data.images ? data.images : null,
      video: data.video ? data.video[0] : null,
      mediaFiles:
        data.certificates && data.certificates.length
          ? data.certificates
          : null,
      bank: data.bank ? data.bank : null,
      wallet: data.wallet ? data.wallet : null,
      linkPages: data.linkPages ? data.linkPages : null,
      career: data.career ? data.career : null,
      shortDescription: data.shortDescription ? data.shortDescription.trim() : null,
      extraInfo: this.extraInfos
    };
    console.log('body', body);
    // track('manualTrackInteractionContent', {
    //   interaction: 'click',
    //   regionName: 'portal_companies_form',
    //   contentName: 'save',
    //   target: `${window.location.origin}/portal/companies`,
    //   payload: JSON.stringify(body),
    // });
    if (data.id) {
      this.companiesService
        .editCompany(data.id, body)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          async (res: any) => {
            if (res.code === 1) {
              this.notification.create(
                'success',
                'Thành công',
                'Cập nhật đối tượng thành công'
              );
              // track('alert', {
              //   alertType: 'success',
              //   alertMessage: 'Cập nhật đối tượng thành công',
              // });
              await this.router.navigate([`/portal/companies`]);
              // this.changePositionBody();
              // this.isVisible = false;
              // this.refreshData.emit(true);
            } else {
              // track('alert', {
              //   alertType: 'failed',
              //   alertMessage: res.message,
              // });
              // track('error', {
              //   apiCall: `${window.location.origin}/business/${data.id}`,
              //   httpResponseCode: 500,
              //   errorMessage: res.message,
              //   errorCode: 500,
              //   apiPayload: JSON.stringify(body)
              // });
              this.notification.create(
                'error',
                'Có lỗi xảy ra',
                res.message || 'Cập nhật đối tượng thất bại'
              );
            }

            this.loading = false;
          },
          (err: any) => {
            // track('alert', {
            //   alertType: 'failed',
            //   alertMessage: err,
            // });
            // track('error', {
            //   apiCall: `${window.location.origin}/business/${data.id}`,
            //   httpResponseCode: 500,
            //   errorMessage: err,
            //   errorCode: 500,
            //   apiPayload: JSON.stringify(body)
            // });
            this.notification.create(
              'error',
              'Có lỗi xảy ra',
              err.message || 'Cập nhật đối tượng thất bại'
            );
            this.loading = false;
          }
        );
    } else {
      this.companiesService
        .createCompany(body)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          async (res: any) => {
            if (res.code === 1) {
              this.notification.create(
                'success',
                'Thành công',
                'Thêm mới đối tượng thành công'
              );
              await this.router.navigate([`/portal/companies/`]);
              // track('alert', {
              //   alertType: 'success',
              //   alertMessage: 'Thêm mới đối tượng thành công',
              // });
              // this.changePositionBody();
              // this.isVisible = false;
              // this.refreshData.emit(true);
            } else {
              // track('alert', {
              //   alertType: 'failed',
              //   alertMessage: res.message,
              // });
              // track('error', {
              //   apiCall: `${window.location.origin}/business`,
              //   httpResponseCode: 500,
              //   errorMessage: res.message,
              //   errorCode: 500,
              //   apiPayload: JSON.stringify(body)
              // });
              this.notification.create(
                'error',
                'Có lỗi xảy ra',
                res.message || 'Thêm mới đối tượng thất bại'
              );
            }
            this.loading = false;
          },
          (err: any) => {
            // track('alert', {
            //   alertType: 'failed',
            //   alertMessage: err,
            // });
            // track('error', {
            //   apiCall: `${window.location.origin}/business`,
            //   httpResponseCode: 500,
            //   errorMessage: err,
            //   errorCode: 500,
            //   apiPayload: JSON.stringify(body)
            // });
            this.notification.create(
              'error',
              'Có lỗi xảy ra',
              err.message || 'Thêm mới đối tượng thất bại'
            );
            this.loading = false;
          }
        );
    }
  }

  openUploadDialog(field: string, index?) {
    const fileType = 'jpg,jpeg,png,gif,bmp';
    const modal = this.modalService.create({
      nzTitle: `Upload ${field}`,
      nzContent: UploaderComponent,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name: changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          const body = {
            title: changeParam.file.name,
            type: changeParam.file.type,
            listUrl: [changeParam.file.response.url],
          };
          if (field === 'avatar') {
            this.avatar[0] = data;
            this.form.get('avatar').setValue(data.url);
          } else if (field === 'wallpaper') {
            this.wallpaper[0] = data;
            this.form.get('wallpaper').setValue(data.url);
          } else if (field === 'images') {
            this.images.push(data);
            this.form
              .get('images')
              .setValue(this.images.map((image) => image.url));
          } else if (field === 'image') {
            this.extraInfos[index].image = data.url;
          }
          this.form.get(field).updateValueAndValidity();
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe((changeParam) => {
          modal.close();
        });
    });
  }

  onRemoveFile(field: string, index: number) {
    if (field === 'avatar') {
      this.avatar = [];
      this.form.get('avatar').setValue('');
    } else if (field === 'wallpaper') {
      this.wallpaper = [];
      this.form.get('wallpaper').setValue('');
    } else if (field === 'images') {
      const tempImages = this.images;
      tempImages.splice(index, 1);
      this.images = tempImages;
      this.form.get('images').setValue(tempImages.map((image) => image.url));
    } else if (field === 'image') {
      this.extraInfos[index].image = '';
    }
  }
  removeCertificateFile(index: number) {
    const tempCer = this.certificates;
    tempCer.splice(index, 1);
    this.certificates = tempCer;
    this.form
      .get('certificates')
      .setValue(
        tempCer.map((cer) => ({ name: cer.name, type: cer.type, url: cer.url }))
      );
  }

  mapClicked($event?: MouseEvent) {
    if (!this.latitude && !this.longitude) {
      return;
    }

    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lon: $event.coords.lng,
      draggable: true,
    });
    this.form.get('lat').setValue($event.coords.lat);
    this.form.get('lon').setValue($event.coords.lng);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  markerDragEnd(m?: marker, $event?: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.form.get('lat').setValue($event.coords.lat);
    this.form.get('lon').setValue($event.coords.lng);
    this.getAddress(this.latitude, this.longitude);
  }

  getListCountries() {
    const query = {
      type: 0,
      parentId: 0,
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.listCountries = res.data;
      },
      (error) => {
        this.listCountries = [];
      }
    );
  }

  getListCities() {
    const query = {
      type: 1,
      parentId: 0,
    };
    const response = this.rootService.getLocations(query);
    response.subscribe(
      (res: any) => {
        this.listCities = res.data;
      },
      (error) => {
        this.listCities = [];
      }
    );
  }

  getListDistricts(idProvince: number) {
    const query = {
      type: 2,
      parentId: idProvince,
    };
    if (idProvince) {
      const response = this.rootService.getLocations(query);
      response.subscribe(
        (res: any) => {
          this.listDistricts = res.data;
        },
        (error) => {
          this.listDistricts = [];
          this.listWards = [];
        }
      );
    } else {
      this.listDistricts = [];
      this.listWards = [];
    }
    this.form.get('idDistrict').setValue(undefined);
    this.form.get('idWard').setValue(undefined);
  }

  getListWards(idDistrict: number) {
    const query = {
      type: 3,
      parentId: idDistrict,
    };
    if (idDistrict) {
      const response = this.rootService.getLocations(query);
      response.subscribe(
        (res: any) => {
          this.listWards = res.data;
        },
        (error) => {
          this.listWards = [];
        }
      );
    } else {
      this.listWards = [];
    }
    this.form.get('idWard').setValue(undefined);
  }

  onChangeCountry(idCountry: any) {
    if (idCountry !== this.idVietnam && idCountry) {
      this.form.get('idProvince').setValidators([]); // or clearValidators()
      this.form.get('idWard').setValidators([]); // or clearValidators()
      this.form.get('idDistrict').setValidators([]); // or clearValidators()
      this.form.updateValueAndValidity();
      this.form.get('idProvince').reset();
      this.form.get('idWard').reset();
      this.form.get('idDistrict').reset();
    } else {
      this.form.get('idProvince').setValidators([Validators.required]); // or clearValidators()
      this.form.get('idWard').setValidators([Validators.required]); // or clearValidators()
      this.form.get('idDistrict').setValidators([Validators.required]); // or clearValidators()
      this.form.updateValueAndValidity();
    }
  }

  isNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  openUploadDialogVid() {
    const fileType = 'mp4';
    const modal = this.modalService.create({
      nzTitle: `Tải Video lên`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: true,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const tmpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            changeParam.file.response.url
          );
          this.videoIntro = [tmpUrl];
          const urlVideos = this.videoIntro.map(
            (gg) => gg.changingThisBreaksApplicationSecurity
          );
          if (this.videoIntro && this.videoIntro.length) {
            this.form.get('video').setValue(urlVideos);
          }
          setTimeout(() => {
            modal.close();
          }, 500);
        }
      });
    });
  }

  uploadFile() {
    const fileType = 'jpg,jpeg,png,pdf';
    const modal = this.modalService.create({
      nzTitle: `Tải file lên (jpg,jpeg,png,pdf)`,
      nzContent: UploaderComponent,
      nzFooter: null,
      nzComponentParams: {
        fileExt: fileType,
        multiple: false,
      },
    });
    modal.afterOpen.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      modal.getContentComponent().change.subscribe((changeParam) => {
        if (changeParam.type === 'success') {
          const data = {
            name:
              changeParam.file.type === 'upload'
                ? changeParam.file.response.url
                : changeParam.file.name,
            type: changeParam.file.type,
            url: changeParam.file.response.url,
          };
          this.certificates.push(data);
          this.form.get('certificates').setValue(this.certificates);
        }
      });
      modal
        .getContentComponent()
        .done.pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          modal.close();
        });
    });
  }
  clearVideoUrl(index: number) {
    const tempVideoIntro = this.videoIntro;
    tempVideoIntro.splice(index, 1);
    this.videoIntro = tempVideoIntro;
    const urlVideos = tempVideoIntro.map(
      (gg) => gg.changingThisBreaksApplicationSecurity
    );
    this.form.get('video').setValue(urlVideos);
  }

  addField(type: string) {
    const found = this.selectedListField.find((el) => el === type);
    if (found) {
      this.selectedListField = this.selectedListField.filter(
        (el) => el !== type
      );
      this.formArrayCareer.removeAt(
        this.formArrayCareer.value.findIndex((el) => el.type === type)
      );
      if (type === 'bank') {
        this.formArrayBank.removeAt(0);
      }
      if (type === 'wallet') {
        this.formArrayWallet.removeAt(0);
      }
    } else {
      this.selectedListField.push(type);
      const itemCareer = this.itemField(type);
      this.formArrayCareer.push(
        this.fb.group({
          code: [''],
          value: [
            '',
            itemCareer.type === 'bank' || itemCareer.type === 'wallet'
              ? undefined
              : Validators.required,
          ],
          type: [itemCareer.type],
          name: [itemCareer.name],
          image: [itemCareer.image],
          placeholder: [itemCareer.placeholder],
        })
      );
      if (type === 'bank') {
        this.formArrayBank.clear();
        this.formArrayBank.push(
          this.fb.group({
            name: [undefined, Validators.required],
            account: [undefined, Validators.required],
            username: [undefined, Validators.required],
          })
        );
      }
      if (type === 'wallet') {
        this.formArrayWallet.clear();
        this.formArrayWallet.push(
          this.fb.group({
            name: [undefined, Validators.required],
            account: [undefined, Validators.required],
            username: [undefined, Validators.required],
          })
        );
      }
    }
  }
  isActiveField(type: string) {
    const found = this.selectedListField.find((el) => el === type);
    return !!found;
  }
  itemField(type: string) {
    return this.listField.find((el) => el.type === type);
  }
  addLinkSocial(type: string) {
    const found = this.selectedListLinkSocial.find((el) => el === type);
    if (found) {
      this.selectedListLinkSocial = this.selectedListLinkSocial.filter(
        (el) => el !== type
      );
      this.formArrayLinkPages.removeAt(
        this.formArrayLinkPages.value.findIndex((el) => el.type === type)
      );
    } else {
      this.selectedListLinkSocial.push(type);
      const itemLinkPage = this.itemLinkSocial(type);
      this.formArrayLinkPages.push(
        this.fb.group({
          code: [''],
          value: [
            '',
            [
              Validators.pattern(
                /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
              ),
              Validators.required,
            ],
          ],
          type: [itemLinkPage.type],
          name: [itemLinkPage.name],
          image: [itemLinkPage.image],
          placeholder: [itemLinkPage.placeholder],
        })
      );
    }
  }
  addLinkWebsite() {
    this.indexWebsite = Number(this.indexWebsite) + 1;
    this.formArrayLinkPages.push(
      this.fb.group({
        code: this.indexWebsite,
        value: [
          '',
          [
            Validators.pattern(
              /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
            ),
            Validators.required,
          ],
        ],
        type: 'website',
        name: 'Website',
        image: 'Website',
        placeholder: 'Nhập địa chỉ liên kết...',
      })
    );

    console.log('chh_log ---> addLinkWebsite ---> this.formArrayLinkPages.value', this.formArrayLinkPages.value)
  }
  checkExistLinkWebsite() {
    if(this.formArrayLinkPages.value && this.formArrayLinkPages.value.length){
      const checkExistTypeWebsite = obj => obj.type === 'website'
      return this.formArrayLinkPages.value.some(checkExistTypeWebsite)
    }else return false
  }
  removeLinkWebsite(indexWebsite) {
    this.formArrayLinkPages.removeAt(
      this.formArrayLinkPages.value.findIndex((el) => Number(el.code) === Number(indexWebsite))
    );
  }
  isActiveLinkSocial(type: string) {
    const found = this.selectedListLinkSocial.find((el) => el === type);
    return !!found;
  }
  itemLinkSocial(type: string) {
    return this.listLinkSocial.find((el) => el.type === type);
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    document.body.style.position = 'unset';
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;

        this.longitude = position.coords.longitude;

        this.zoom = 15;

        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 15;
            this.form.get('address').setValue(results[0].formatted_address);
            // this.form.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  get formArrayCareer(): FormArray {
    return this.form.get('career') as FormArray;
  }
  get formArrayLinkPages(): FormArray {
    return this.form.get('linkPages') as FormArray;
  }
  get formArrayBank(): FormArray {
    return this.form.get('bank') as FormArray;
  }
  get formArrayWallet(): FormArray {
    return this.form.get('wallet') as FormArray;
  }
  isShowBankField() {
    return !!this.selectedListField.find((el) => el === 'bank');
  }
  isShowWalletField() {
    return !!this.selectedListField.find((el) => el === 'wallet');
  }
}
