import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SettingMessageService } from 'src/app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd';
import { removeEmptyQuery, sleep } from 'src/app/helpers';

@Component({
  selector: 'app-setting-message',
  templateUrl: './setting-message.component.html',
  styleUrls: ['./setting-message.component.scss']
})
export class SettingMessageComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject<void>();

  constructor(
    private settingMessage: SettingMessageService,
    private notification: NzNotificationService,
  ) { }

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }

  productId: string;
  roleCode: string = null;
  ngOnInit() {
    this.productId = window.location.href.split('/')[5];
    this.loadData();
  }

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  total: number;
  loading: Boolean = true;
  page = 1;
  pageSize = 10;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: Boolean;
  delayTimer: any;

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate = this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (let [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value == true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async loadData(ms = 0) {
    const query: any = {
      type: 0,
      productId: this.productId,
      page: this.page,
      page_size: this.pageSize,
    }

    removeEmptyQuery(query);
    await sleep(ms);

    this.loading = true;
    this.settingMessage.getAll(query).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.loading = false;
        this.total = res.data.totalElement;
        this.listOfAllData = res.data.items;
        if (this.listOfAllData.length > 0) {
          this.selectMessage(res.data.items[0]);
        }
        if (!res.data.items && this.page > 1) {
          this.page = this.page - 1;
          this.loadData();
          return;
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        return;
      },
    );
  }

  /*Check link image error*/
  imageFalse: any[] = [];
  checkImageErr(imageError: any) {
    this.imageFalse.push(imageError);
  }

  showImage(id: any, imageLink: any) {
    if (!this.imageFalse.includes(id)) {
      window.open(imageLink, '_blank');
    }
  }

  messageDetail: any;
  selectMessage(message: any) {
    this.settingMessage.getById(message.id).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.messageDetail = res.data;
    })
  }

  @ViewChild('addMessage', { static: true }) addMessage;
  create(data: any) {
    this.settingMessage.create(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.addMessage.success();
        if (res.code === 1) {
          this.openMessage('success', 'Thành công', 'Tạo tin nhắn thành công');
          this.addMessage.handleCancel();
          this.loadData(500);
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          return;
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        this.addMessage.success();
        return;
      },
    );
  }

  @ViewChild('updateMessage', { static: true }) updateMessage;
  update(data: any) {
    this.settingMessage.update(data.messageId, data.form).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.updateMessage.success();
        if (res.code === 1) {
          this.openMessage('success', 'Thành công', 'Cập nhật tin nhắn thành công');
          this.updateMessage.handleCancel();
          this.loadData(500);
          return;
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
          return;
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        this.updateMessage.success();
        return;
      },
    );
  }

  @ViewChild('deleteMessage', { static: true }) deleteMessage;
  dataDelete = {
    label: 'Xác nhận xóa tin nhắn',
    value: '<p>Bạn có chắc chắn muốn xoá tin nhắn?</p>'
  };
  delete(body: string) {
    this.settingMessage.destroy(body).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.deleteMessage.success();
        if (res.code === 1) {
          this.mapOfCheckedId = {};
          this.openMessage('success', 'Thành công', 'Xóa tin nhắn thành công');
          this.deleteMessage.handleCancel();
          this.loadData(500);
        }
        if (res.code !== 1) {
          this.openMessage('warning', 'Cảnh báo', `${res.message}`);
        }
      },
      (err) => {
        this.openMessage('error', 'Lỗi !!!', `${err}`);
        this.deleteMessage.success();
        return;
      },
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
