import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BaseService } from "src/app/services";

@Injectable({
  providedIn: "root",
})
export class ProductsService extends BaseService {
  managementStatus = new Subject<any>();
  currentManagementStatus = this.managementStatus.asObservable();

  getManagementStatus(status: any) {
    this.managementStatus.next(status);
  }

  getAll(query?: any): Observable<any> {
    return this.get<any>("/product", { params: query });
  }

  getAllProduct(query?: any): Observable<any> {
    return this.get<any>("/product/short", { params: query });
  }

  getAllProductManaged(query?: any): Observable<any> {
    return this.get<any>("/product/search/managed", { params: query });
  }

  getAllProductByShop(query?: any): Observable<any> {
    return this.get<any>("/shop/product/search", { params: query });
  }

  getAllManagementStatus(query?: any): Observable<any> {
    return this.get<any>("/product/management-status", { params: query });
  }

  getById(id: string): Observable<any> {
    return this.get<any>(`/product/${id}`);
  }

  getAllSubscription(query?: any): Observable<any> {
    return this.get<any>("/subscription/active", { params: query });
  }

  getAllSubscription2(query?: any): Observable<any> {
    return this.get<any>("/subscription/short", { params: query });
  }

  create(body: any): Observable<any> {
    return this.post<any>(`/product`, body);
  }

  update(id: string, body: any): Observable<any> {
    return this.put<any>(`/product/${id}`, body);
  }

  // tslint:disable-next-line:variable-name
  importFile(
    id: string,
    body: any,
    is_check_gln: boolean,
    is_validate_gtin: boolean
  ): Observable<any> {
    return this.post<any>(
      `/product/add/upload?sub_id=${id}&is_check_gln=${is_check_gln}&is_validate_gtin=${is_validate_gtin}`,
      body
    );
  }

  destroy(body: any): Observable<any> {
    return this.delete<any>(`/product`, body);
  }

  hidden(isHidden: Boolean, product_ids: any): Observable<any> {
    return this.put<any>(
      `/product/hidden?isHidden=${isHidden}&product_ids=${product_ids}`
    );
  }

  exportExcel(query?: any): Observable<any> {
    const httpOptions = {
      params: query,
      responseType: "blob",
    };
    return this.get<any>("/product/export", httpOptions);
  }
  getProductRelated(query?: any): Observable<any> {
    return this.get<any>("/product/search/managed", { params: query });
  }
  updateProductFromExcel(query?: any, body?: any) {
    return this.post<any>("/product/info/upload", body, { params: query });
  }
  deleteFlashProductByBarcode(body?: any) {

    return this.delete<any>("/product/barcode", body);
  }

  updateStatus(body): Observable<any> {
    return this.post<any>('/product/hide/batch', body);
  }

  getDetailById(id: any) {
    return this.get('/product/' + id)
  }

  importProductFile(body: any) : Observable<any>  {
    return this.post<any>('/product/import', body);
  }


}
